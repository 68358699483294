const Maipu = {
    commune: "Maipú",
    polygons: [
        {
            idService: 33,
            nameService: "Maipú 1 / Hasta La Plaza de Maipú",
            coordinates: [

                { "lat": -33.463866472234166, "lng": -70.76332547383626 }, // Vespucion con marta ossa / los mares
                { "lat": -33.46832898355792,  "lng":-70.73781772856805  }, // Marta ossa con tte cruz
                { "lat": -33.46946492318601,  "lng":-70.73419230093309  },
                { "lat": -33.47288782393221,  "lng":-70.7225393636125   },
                { "lat": -33.47323371166348,  "lng":-70.72198435549782  }, // Santa corina con lo errazuriz
                { "lat": -33.47363701120594,  "lng": -70.72226259968099 },
                { "lat": -33.474120274641,    "lng": -70.72239671012647 },
                { "lat": -33.47837112959606,  "lng": -70.72320250401688 },
                { "lat": -33.479592640242764, "lng": -70.72344390282043 },
                { "lat": -33.480263792593966, "lng": -70.72352436908773 },
                { "lat": -33.480612789778235, "lng": -70.72371212372173 },
                { "lat": -33.48140921394697,  "lng": -70.72427002317495 },
                { "lat": -33.48234433188237,  "lng": -70.7244524134052  },
                { "lat": -33.48199086744824,  "lng": -70.72585789087391 },
                { "lat": -33.48193270227658,  "lng": -70.72644261241622 },
                { "lat": -33.482093774963964, "lng": -70.72691468118435 },
                { "lat": -33.482451713206295, "lng": -70.72722581744713 },
                { "lat": -33.48279622735866,  "lng": -70.7273545634748  },
                { "lat": -33.483194430326016, "lng": -70.72799829370004 },
                { "lat": -33.483234697939395, "lng": -70.72897461774319 },
                { "lat": -33.48302888549653,  "lng": -70.7294466865113  },
                { "lat": -33.482514352246326, "lng": -70.72992948414556 },
                { "lat": -33.4825501459624,   "lng": -70.73069659589375 },
                { "lat": -33.48253672331866,  "lng": -70.73136714818935 },
                { "lat": -33.49469786722045,  "lng": -70.73146732256569 },
                { "lat": -33.49475664356103,  "lng": -70.73153169798049 }, // del ferrocaril
                { "lat": -33.49497106702974,  "lng": -70.73181588592249 },
                { "lat": -33.495411197757846, "lng": -70.73233238625097 },
                { "lat": -33.49632230721037,  "lng": -70.73321952424267 },
                { "lat": -33.496766192800095, "lng": -70.73357814237525 },
                { "lat": -33.49732386786926,  "lng": -70.73395480418287 },
                { "lat": -33.49804799371417,  "lng": -70.73437206427333 },
                { "lat": -33.50071354623591,  "lng": -70.73559526734557 },
                { "lat": -33.501632040073474, "lng": -70.73600661780185 },
                { "lat": -33.510101403543864, "lng": -70.73987715008265 }, // esquina 5 de abril
                { "lat": -33.51046050331022,  "lng": -70.75724812705312 }, // 5 de abril con pajaritos
                { "lat": -33.51069069468827,  "lng": -70.76300716355537 }, // 5 de abril con el carmen
                { "lat": -33.510282141399365, "lng": -70.76303402516369 },
                { "lat": -33.50999670265736,  "lng": -70.7632383246309  },// camino a rinconada
                { "lat": -33.50876285982648,  "lng": -70.77009063940761 },
                { "lat": -33.50877667159557,  "lng": -70.7703998495294  },
                { "lat": -33.50924166659077,  "lng": -70.77627483977142 },
                { "lat": -33.5068522102688,   "lng": -70.78404926303739 }, // rinconada con las naciones
                { "lat": -33.506718693405155, "lng": -70.78432534349356 },
                { "lat": -33.50533286847293,  "lng": -70.78833955198903 },
                { "lat": -33.505199349278094, "lng": -70.78938865746416 },
                { "lat": -33.50523618217822,  "lng": -70.78999603425856 },
                { "lat": -33.50539272183127,  "lng": -70.79127704717298 },
                { "lat": -33.50690285453557,  "lng": -70.79658331191287 },
                { "lat": -33.50888672439817,  "lng": -70.8041203264151  },
                { "lat": -33.49649762468847,  "lng": -70.82328905757247 },
                { "lat": -33.465719795116925, "lng": -70.81378850547391 },
                { "lat": -33.462253095324144, "lng": -70.8090538972422  },
                { "lat": -33.463105575340855, "lng": -70.79883531832482 },
                { "lat": -33.46040602640682,  "lng": -70.79880125625222 },
                { "lat": -33.46046285987964,  "lng": -70.79328322363683 },
                { "lat": -33.46219626293587,  "lng": -70.78074843289312 },
                { "lat": -33.45810061649453,  "lng": -70.77999733938097 },
                { "lat": -33.459063387311694, "lng": -70.76481086364996 },
                { "lat": -33.463854384839436, "lng": -70.76333349349545 },
            ]
        },
        {
            idService: 32,
            nameService: "Maipú 2 / Hasta 3 Poniente",
            coordinates: [


                { "lat": -33.510101403543864, "lng": -70.73987715008265 }, // esquina 5 de abril
                { "lat": -33.51046050331022,  "lng": -70.75724812705312 }, // 5 de abril con pajaritos
                { "lat": -33.51069069468827,  "lng": -70.76300716355537 }, // 5 de abril con el carmen
                { "lat": -33.510282141399365, "lng": -70.76303402516369 },
                { "lat": -33.50999670265736,  "lng": -70.7632383246309  },// camino a rinconada
                { "lat": -33.50876285982648,  "lng": -70.77009063940761 },
                { "lat": -33.50877667159557,  "lng": -70.7703998495294  },
                { "lat": -33.50924166659077,  "lng": -70.77627483977142 },
                { "lat": -33.50884693612336,  "lng": -70.77862781942856 }, // rinconada con 3 poniente
                { "lat": -33.52439492870946,  "lng": -70.77870424447455 }, // 3 poniente con san martin
                { "lat": -33.5290517070468,   "lng": -70.77666052857906 }, // 3 poniente con pdte edu frei montalva
                { "lat": -33.535467318530905, "lng": -70.77075108057848 }, // 3 poniente con ferrocarril
                { "lat": -33.535610567392496, "lng": -70.770228353957   },
                { "lat": -33.5391917929833,   "lng": -70.76682978800488 },
                { "lat": -33.540015125001766, "lng": -70.7657398062537  },
                { "lat": -33.548480744155285, "lng": -70.74741500262499 }, //3 poniente con lonquen
                { "lat": -33.5415560827012,   "lng": -70.7412568054686  }, // vespucio con lonquen
                { "lat": -33.53673357209823,  "lng": -70.73700338592155 },
                { "lat": -33.529857448175825, "lng": -70.7311889471201  },
                { "lat": -33.51395999243678,  "lng": -70.71850234501643 },
                { "lat": -33.51023603153906,  "lng": -70.7227927136638  }, // vespucion con camino melipilla
                { "lat": -33.50853884585033,  "lng": -70.72419260291905 },
                { "lat": -33.509327723272804, "lng": -70.72597354103169 }, // vespucion con la union
                { "lat": -33.509252316182916, "lng": -70.72687792366195 }, // la union con san jose
                { "lat": -33.509890374097786, "lng": -70.72701010265706 },

            ]
        },
        {
            idService: 130,
            nameService: "Maipú 3 / Hasta el Abrazo",
            coordinates: [
                //Limite 4 poniente

                { "lat": -33.50884693612336,  "lng": -70.77862781942856 }, // rinconada con 3 poniente
                { "lat": -33.52439492870946,  "lng": -70.77870424447455 }, // 3 poniente con san martin
                { "lat": -33.5290517070468,   "lng": -70.77666052857906 }, // 3 poniente con pdte edu frei montalva
                { "lat": -33.535467318530905, "lng": -70.77075108057848 }, // 3 poniente con ferrocarril
                { "lat": -33.535610567392496, "lng": -70.770228353957   },
                { "lat": -33.5391917929833,   "lng": -70.76682978800488 },
                { "lat": -33.540015125001766, "lng": -70.7657398062537  },
                { "lat": -33.548480744155285, "lng": -70.74741500262499 }, //3 poniente con lonquen
                { "lat":-33.55530985232287,   "lng":-70.75354465165155  }, // 4 poniente
                { "lat": -33.55427994618063,  "lng": -70.75689074251709 },
                { "lat": -33.55275625677685,  "lng":-70.75872736256912  },
                { "lat": -33.55154969630577,  "lng":-70.76132510457884  },
                { "lat": -33.55144683415752,  "lng":-70.76134523054483  },
                { "lat": -33.54937000066411,  "lng": -70.76592565258454 }, // 4 poniente con lautaro
                { "lat": -33.548439439544985, "lng": -70.76790485915573 }, // 4 poniente con longovilo
                { "lat": -33.54687688601504,  "lng": -70.7712649885008  },// 4 poniente con caronte
                { "lat": -33.54610284624758,  "lng": -70.77280939577132 }, // 4 poniente con tarot
                { "lat": -33.54498381021347,  "lng": -70.77520948813886 }, // 4 poniente con luxor
                { "lat": -33.54431122199503,  "lng": -70.77676433058026 }, // 4 poniento con rey salomon
                { "lat": -33.54411408307178,  "lng": -70.77715391081902 },
                { "lat": -33.54274859381828,  "lng": -70.78061491360796 }, // 4 poniente con del ferrocarril
                { "lat": -33.5420180047183,   "lng": -70.7824619412011  },
                { "lat": -33.541817961380644, "lng": -70.7827367343818  },
                { "lat": -33.54089891577805,  "lng": -70.78375938245237 },
                { "lat": -33.53795906392089,  "lng": -70.78724125556128 }, // 4 poniente con puerto cisne
                { "lat": -33.537097964113016, "lng": -70.78815607337938 }, // 4 poniente con gabriel gonzalez videla
                { "lat": -33.536845721125324, "lng": -70.78851086965825 },
                { "lat": -33.53638762280328,  "lng": -70.789098718347   },
                { "lat": -33.534998816195305, "lng": -70.7918466501891  },
                { "lat": -33.53218054296898,  "lng": -70.7933493167308  },
                { "lat": -33.53186739582857,  "lng": -70.79338062228311 }, // 4 poniente con  alfredo silva carvallo
                { "lat": -33.52939117387213,  "lng": -70.79248667486573 },
                { "lat": -33.52770069122187,  "lng": -70.7918396934419  },// 4 poniente con alcalde alberto krumm
                { "lat": -33.52729183882393,  "lng": -70.7917979527065  },
                { "lat": -33.52483288434378,  "lng": -70.7918953477592  },
                { "lat": -33.522147672588744, "lng": -70.7916031625959  },// 4 poniente con av sur
                { "lat": -33.51915208421308,  "lng": -70.7912901070646  },// 4 poniente con av portales
                { "lat": -33.51722493090226,  "lng": -70.79128808565028 }, // 4 poniente con san jose
                { "lat": -33.51598951919321,  "lng": -70.79125678009098 }, // 4 poniente con las tinajas
                { "lat": -33.51566761324311,  "lng": -70.79124982330082 },
                { "lat": -33.513219928854845, "lng": -70.7908011103761  },// 4 poniente con agua santa
                { "lat": -33.510882383624825, "lng": -70.79062023385362 }, // con cadelaria
                { "lat": -33.5079560128062,   "lng": -70.79031761351226 },
                { "lat": -33.507486160101095, "lng": -70.7903036999305  },
                { "lat": -33.507199026637046, "lng": -70.79036978942791 },
                { "lat": -33.506906091522694, "lng": -70.79048805484459 },
                { "lat": -33.506610255043114, "lng": -70.79063762582409 },
                { "lat": -33.505528413054336, "lng": -70.79133330477124 },
                { "lat": -33.50529554865591,  "lng":-70.78937693401484  },
                { "lat": -33.50552599107401,  "lng":-70.78803354243404  },
            ]
        },
        {
            idService: 131,
            nameService: "Maipú 4 / Hasta Ciudad Satelite",
            coordinates: [


                { "lat": -33.50888672439817,  "lng": -70.8041203264151  },
                { "lat": -33.49649762468847,  "lng": -70.82328905757247 },
                { "lat":-33.50123289154186,   "lng":-70.8436325768736   },
                { "lat":-33.53748262118188,   "lng":-70.85386618309737  },
                { "lat":-33.53479098969987,   "lng":-70.83968593068661  },
                { "lat":-33.538028739088055,  "lng":-70.82012373330433  },
                { "lat":-33.5280420379555,    "lng":-70.8101086375423   },
                { "lat":-33.52999265617072,   "lng":-70.80584988186777  },
                { "lat":-33.534829999457145,  "lng":-70.80533508722579  },
                { "lat":-33.542709608118095,  "lng":-70.81778375765899  },
                { "lat": -33.553749972158286, "lng":-70.80068355815665  },
                { "lat": -33.555500818574906, "lng":-70.79804693495387  },
                { "lat": -33.567732150180255, "lng":-70.78362001926881  },
                { "lat":-33.572074875116336,  "lng":-70.76753718391402  },
                { "lat":-33.56614665642156,   "lng":-70.76315246030696  },
                { "lat":-33.55530985232287,   "lng":-70.75354465165155  }, // 4 poniente
                { "lat": -33.55427994618063,  "lng": -70.75689074251709 },
                { "lat": -33.55275625677685,  "lng":-70.75872736256912  },
                { "lat": -33.55154969630577,  "lng":-70.76132510457884  },
                { "lat": -33.55144683415752,  "lng":-70.76134523054483  },
                { "lat": -33.54937000066411,  "lng": -70.76592565258454 }, // 4 poniente con lautaro
                { "lat": -33.548439439544985, "lng": -70.76790485915573 }, // 4 poniente con longovilo
                { "lat": -33.54687688601504,  "lng": -70.7712649885008  },// 4 poniente con caronte
                { "lat": -33.54610284624758,  "lng": -70.77280939577132 }, // 4 poniente con tarot
                { "lat": -33.54498381021347,  "lng": -70.77520948813886 }, // 4 poniente con luxor
                { "lat": -33.54431122199503,  "lng": -70.77676433058026 }, // 4 poniento con rey salomon
                { "lat": -33.54411408307178,  "lng": -70.77715391081902 },
                { "lat": -33.54274859381828,  "lng": -70.78061491360796 }, // 4 poniente con del ferrocarril
                { "lat": -33.5420180047183,   "lng": -70.7824619412011  },
                { "lat": -33.541817961380644, "lng": -70.7827367343818  },
                { "lat": -33.54089891577805,  "lng": -70.78375938245237 },
                { "lat": -33.53795906392089,  "lng": -70.78724125556128 }, // 4 poniente con puerto cisne
                { "lat": -33.537097964113016, "lng": -70.78815607337938 }, // 4 poniente con gabriel gonzalez videla
                { "lat": -33.536845721125324, "lng": -70.78851086965825 },
                { "lat": -33.53638762280328,  "lng": -70.789098718347   },
                { "lat": -33.534998816195305, "lng": -70.7918466501891  },
                { "lat": -33.53218054296898,  "lng": -70.7933493167308  },
                { "lat": -33.53186739582857,  "lng": -70.79338062228311 }, // 4 poniente con  alfredo silva carvallo
                { "lat": -33.52939117387213,  "lng": -70.79248667486573 },
                { "lat": -33.52770069122187,  "lng": -70.7918396934419  },// 4 poniente con alcalde alberto krumm
                { "lat": -33.52729183882393,  "lng": -70.7917979527065  },
                { "lat": -33.52483288434378,  "lng": -70.7918953477592  },
                { "lat": -33.522147672588744, "lng": -70.7916031625959  },// 4 poniente con av sur
                { "lat": -33.51915208421308,  "lng": -70.7912901070646  },// 4 poniente con av portales
                { "lat": -33.51722493090226,  "lng": -70.79128808565028 }, // 4 poniente con san jose
                { "lat": -33.51598951919321,  "lng": -70.79125678009098 }, // 4 poniente con las tinajas
                { "lat": -33.51566761324311,  "lng": -70.79124982330082 },
                { "lat": -33.513219928854845, "lng": -70.7908011103761  },// 4 poniente con agua santa
                { "lat": -33.510882383624825, "lng": -70.79062023385362 }, // con cadelaria
                { "lat": -33.5079560128062,   "lng": -70.79031761351226 },
                { "lat": -33.507486160101095, "lng": -70.7903036999305  },
                { "lat": -33.507199026637046, "lng": -70.79036978942791 },
                { "lat": -33.506906091522694, "lng": -70.79048805484459 },
                { "lat": -33.506610255043114, "lng": -70.79063762582409 },
                { "lat": -33.505528413054336, "lng": -70.79133330477124 },

            ]
        }
    ]
};

export default Maipu;
