import React, { useState } from 'react';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import axios from 'axios';

import { getDateTimeNow } from '../genscripts';
import CountersSelectNew from './CountersSelectNew';

const ModalOpenBox = (props) => {
	const modalIsOpen = props.modalIsOpen;
	const setModalIsOpen = props.setModalIsOpen;

	const [counterBox, setCounterBox] = useState(0);
	//const [turnoBox, setTurnoBox] = useState(0);
	const turnoBox = 1; //se setero así para sacar el select

	const [initCLP, setInitCLP] = useState(0);
	const [initUSD, setInitUSD] = useState(0);
	const [initEURO, setInitEURO] = useState(0);

	const openMoneyBox = () => {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/openMoneyBox`,
			data: JSON.stringify({
				idcounter: counterBox,
				idturno: turnoBox,
				rutsupervisor: '1-0',
				iniciodate: getDateTimeNow(),
				iniciomontopesos: initCLP,
				iniciomontodolar: initUSD,
				iniciomontoeuro: initEURO,
				findate: getDateTimeNow(),
				finmontopesos: 0,
				finmontodolar: 0,
				finmontoeuro: 0,
				finmontodebito: 0,
				finmontocredito: 0,
				rutusuario: cookies.get('user'),
				isopen: true,
				cuadratura: false,
				resumemontopesos: 0,
				resumemontodolar: 0,
				resumemontoeuro: 0,
				resumemontodebito: 0,
				resumemontocredito: 0,
				comantariocuadratura: '',
				supervisorcheck: false,
				checksupervisordate: getDateTimeNow(),
				comentariosupervisor: '',
			}),
			headers: headers,
		})
			.then((response) => {
				const cookies = new Cookies();
				var now = new Date();
				now.setTime(Date.parse(cookies.get('expdat')));
				cookies.set('copen', response.data.isopen, { path: '/', expires: now });
				cookies.set('copenid', response.data.idcajasiniciofin, {
					path: '/',
					expires: now,
				});
				cookies.set('idcounter', counterBox, { path: '/', expires: now });
				cookies.set('idturno', turnoBox, { path: '/', expires: now });
				setModalIsOpen(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			overlayClassName="Overlay"
			className="Modal"
			contentLabel="Imprimir ticket"
		>
			<div className="headermodal"></div>
			<div className="containerModalForm">
				<h4>Favor, inicia tu caja:</h4>
				<div className="form-group">
					<CountersSelectNew setCounterBox={setCounterBox} />
				</div>
				{/* <div className="form-group">
					<TurnosSelectNew setTurnoBox={setTurnoBox} />
				</div> */}
				<div className="form-group">
					<label>Monto pesos:</label>
					<input
						type="text"
						className="form-control"
						id="montoPesosIniBox"
						//value={initCLP}
						onChange={(e) => setInitCLP(e.target.value)}
					/>
				</div>
				<div className="form-group">
					<label>Monto USD:</label>
					<input
						type="text"
						className="form-control"
						id="montoUSDIniBox"
						//value={initUSD}
						onChange={(e) => setInitUSD(e.target.value)}
					/>
				</div>
				<div className="form-group">
					<label>Monto Euro:</label>
					<input
						type="text"
						className="form-control"
						id="montoEuroIniBox"
						//value={initEURO}
						onChange={(e) => setInitEURO(e.target.value)}
					/>
				</div>

				<div className="form-group buttonModalForm">
					<input
						type="button"
						className="btn btn-inactivo btn-round"
						onClick={openMoneyBox}
						value="Iniciar"
					/>
				</div>
			</div>
		</Modal>
	);
};

Modal.setAppElement('body');
export default ModalOpenBox;
