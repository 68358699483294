const PuenteAlto = {
    commune: "Puente Alto",
    polygons: [
        {
            idService: 46,
            nameService: "Las Vizcachas",
            coordinates: [
                {"lat":-33.59860381885346, "lng":-70.53007515806097}, //Subcomiseria
                {"lat":-33.59921949192181, "lng":-70.53115326527463}, // Recinto mop las vizcachas
                {"lat":-33.60139675138239, "lng":-70.53226011065382}, // av eyzaguirre
                {"lat":-33.605383203219375, "lng":-70.53406910482725}, // deconrock
                {"lat":-33.606434716889744, "lng":-70.53471921218436}, // av eyzaguirre cn parque casas viejas.
                {"lat":-33.60840692434618, "lng":-70.53832777866943}, // Los acacios
                {"lat":-33.612092268018586, "lng":-70.53652820634923}, // los acacios
                {"lat":-33.612620959770645, "lng":-70.53546967413712}, // El sauce
                {"lat":-33.61279846966963, "lng":-70.53432308126999}, // los espinos
                {"lat":-33.613080036345806, "lng":-70.53312503872293}, // 3sept
                {"lat":-33.614112692762426, "lng":-70.53187772578269},// abajo costanera norte
                {"lat":-33.616216187403424, "lng":-70.52440818854521}, //Maipo , limite pirque
                {"lat":-33.61599704510839, "lng":-70.52078761594431},
                {"lat":-33.614576617217075, "lng":-70.51814999878167},
                {"lat":-33.613829784915474, "lng":-70.51834342404025},
                {"lat":-33.61336118095163, "lng":-70.5183961763835},
                {"lat":-33.61300972629277, "lng":-70.51663776460427},
                {"lat":-33.61328796132261, "lng":-70.5151255305244},
                {"lat":-33.61350761993447, "lng":-70.51475626412163},
                {"lat":-33.613961579291754, "lng":-70.51465075943511},
                {"lat":-33.61415194863324, "lng":-70.5146331753207},
                {"lat":-33.614195879960036, "lng":-70.51361329668447},
                {"lat":-33.614195879960036, "lng":-70.51264617039149},
                {"lat":-33.61443017999139, "lng":-70.51210106284455},
                {"lat":-33.6138590725491, "lng":-70.51187246935712},
                {"lat":-33.61349297604443, "lng":-70.51160870764085},
                {"lat":-33.613200097721126, "lng":-70.5110636000939},
                {"lat":-33.61317080983406, "lng":-70.51041298786045},
                {"lat":-33.613141521937045, "lng":-70.50977995974141},
                {"lat":-33.612891936852584, "lng":-70.50903594665147},
                {"lat":-33.609711039770886,"lng": -70.50858533504261},
                {"lat":-33.60894260322591, "lng":-70.50839221600904},
                {"lat":-33.60788822565674, "lng":-70.50804889328269},
                {"lat":-33.607941838379695, "lng":-70.50555980333407},
                {"lat":-33.60795970928228, "lng":-70.5039933933951},
                {"lat":-33.608549446989, "lng":-70.50176179567384},
                {"lat":-33.60846009305032, "lng":-70.50101077720997},
                {"lat":-33.60831712655582, "lng":-70.50008809738291},
                {"lat":-33.60787035473235, "lng":-70.49940145193023},
                {"lat":-33.60745932249827, "lng":-70.49897229814331},
                {"lat":-33.605707946273625, "lng":-70.49912250183608},
                {"lat":-33.604885859450256, "lng":-70.4996160282552},
                {"lat":-33.6023480636751, "lng":-70.50073182709002},
                {"lat":-33.60009615417435, "lng":-70.49890792486518},
                {"lat":-33.596539447164425, "lng":-70.4927924883612},// Camino el volcan
                {"lat":-33.5927729779197, "lng":-70.49853112628226},
                {"lat":-33.58770505284489, "lng":-70.49771214915897},
                {"lat":-33.579127886059254, "lng":-70.50590192039198},
                {"lat":-33.57464402776885, "lng":-70.52017552168381}, //Audax italiano
                {"lat":-33.58419631493359, "lng":-70.52731232232972},
            ]
        },
    ]
};

export default PuenteAlto;
