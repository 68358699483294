const VillaAlemana = {
    commune: "Villa Alemana",
    polygons: [
        {
            idService: 113,
            nameService: "V.Alemana / Quilpue/ Olmué",
            coordinates: [
                {"lat":-33.03210480539101, "lng":-71.40164074950914},
                {"lat":-33.03210480539101, "lng":-71.40129742676496},
                {"lat":-33.03375981156091, "lng":-71.4009541040208},
                {"lat":-33.03340003025516, "lng":-71.3996666437302},
                {"lat":-33.033687855417284, "lng":-71.39554677080024},
                {"lat":-33.03282437711039, "lng":-71.3946026332538},
                {"lat":-33.02965821759328, "lng":-71.39451680256775},
                {"lat":-33.02994605497733, "lng":-71.39177022061446},
                {"lat":-33.028650779345696, "lng":-71.39159855924237},
                {"lat":-33.027427445991314, "lng":-71.3927143581609},
                {"lat":-33.026995677223866, "lng":-71.39151272855634},
                {"lat":-33.02598820854081, "lng":-71.39177022061446},
                {"lat":-33.025412506980125, "lng":-71.38928113071927},
                {"lat":-33.026132133343445, "lng":-71.38730702494036},
                {"lat":-33.02728352330386, "lng":-71.38713536356825},
                {"lat":-33.03030585038534, "lng":-71.38713536356825},
                {"lat":-33.03059368565411, "lng":-71.38988194552157},
                {"lat":-33.03361589921491, "lng":-71.3899677762076},
                {"lat":-33.03635019359616, "lng":-71.38662037945203},
                {"lat":-33.03642214756558, "lng":-71.38155636897562},
                {"lat":-33.03348668035024, "lng":-71.38143942722336},
                {"lat":-33.03109301055873, "lng":-71.37803959853042},
                {"lat":-33.031927346408466, "lng":-71.37260434640095},
                {"lat":-33.033681565249765, "lng":-71.3727064168635},
                {"lat":-33.03406663300396, "lng":-71.37398229764506},
                {"lat":-33.03468701640168, "lng":-71.37405885049193},
                {"lat":-33.03500790265204, "lng":-71.37135398323502},
                {"lat":-33.033938277272874, "lng":-71.37069052522861},
                {"lat":-33.03246217292645, "lng":-71.36522975548353},
                {"lat":-33.03357460165767, "lng":-71.35877379822844},
                {"lat":-33.02933874015794, "lng":-71.35805930499076},
                {"lat":-33.03479397858658, "lng":-71.34808191727893},
                {"lat":-33.035435749139594, "lng":-71.34731638881},
                {"lat":-33.035435749139594, "lng":-71.34734190642563},
                {"lat":-33.036826235968114, "lng":-71.34670396603485},
                {"lat":-33.03618447554334, "lng":-71.34522394432824},
                {"lat":-33.038965403732725, "lng":-71.34351426353419},
                {"lat":-33.039735491372944, "lng":-71.34211079467447},
                {"lat":-33.04112591033971, "lng":-71.34211079467447},
                {"lat":-33.04277299364102, "lng":-71.33902316318309},
                {"lat":-33.04305106960118, "lng":-71.33721141247327},
                {"lat":-33.04399224326001, "lng":-71.33644588400433},
                {"lat":-33.049596296355425, "lng":-71.32835679984922},
                {"lat":-33.05017379300941, "lng":-71.3285864583899},
                {"lat":-33.05182070731817, "lng":-71.33157201951221},
                {"lat":-33.05727455357663, "lng":-71.33894661042964},
                {"lat":-33.06020451970067, "lng":-71.34116664298956},
                {"lat":-33.05984095256167, "lng":-71.34236597092423},
                {"lat":-33.06135937006389, "lng":-71.34415220401841},
                {"lat":-33.06345517107296, "lng":-71.34917917464477},
                {"lat":-33.064118118094335, "lng":-71.34940883318545},
                {"lat":-33.064973526227135, "lng":-71.35589030755581},
                {"lat":-33.06653462463726, "lng":-71.35790619919068},
                {"lat":-33.06732585572247, "lng":-71.36272902854499},
                {"lat":-33.068480612646596, "lng":-71.36165728868846},
                {"lat":-33.06892968069146, "lng":-71.36229522907925},
                {"lat":-33.06815984835434, "lng":-71.36367318032333},
                {"lat":-33.06809569544888, "lng":-71.36505113207002},
                {"lat":-33.06888691249827, "lng":-71.36469388545119},
                {"lat":-33.068395075709624, "lng":-71.37663612956662},
                {"lat":-33.06670570232476, "lng":-71.3817651703085},
                {"lat":-33.06200094730648, "lng":-71.38219896977422},
                {"lat":-33.061936789796356, "lng":-71.38674110576227},
                {"lat":-33.06775354531054, "lng":-71.38671558814663},
                {"lat":-33.06728308672807, "lng":-71.39238049881678},
                {"lat":-33.06662016354748, "lng":-71.39245705166367},
                {"lat":-33.065761129454955, "lng":-71.40250624133057},
                {"lat":-33.04768902693792, "lng":-71.4006179377376},
                {"lat":-33.047410965620585, "lng":-71.40189381851917},
            ]
        },
    ]
};

export default VillaAlemana;
