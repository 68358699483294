const ValleNevado = {
    commune: "Valle Nevado",
    polygons: [
        {
            idService: 111,
            nameService: "Valle Nevado",
            coordinates: [
                {"lat":-33.36051749450083, "lng":-70.2619355968124},
                {"lat":-33.35300225249031, "lng":-70.26235812924446},
                {"lat":-33.34233032423355, "lng":-70.25962409582134},
                {"lat":-33.33341610562785, "lng":-70.25813808764549},
                {"lat":-33.328198466254115, "lng":-70.25813808764549},
                {"lat":-33.31683406742275, "lng":-70.25186856395922},
                {"lat":-33.311904221397064, "lng":-70.2420446646376},
                {"lat":-33.30969704511852, "lng":-70.23101129024154},
                {"lat":-33.3115741798807, "lng":-70.21918805448644},
                {"lat":-33.32300113910504, "lng":-70.21721340135647},
                {"lat":-33.35667513805123, "lng":-70.23118407232168},
                {"lat":-33.36564305160943, "lng":-70.26053235448775},
            ]
        },
    ]
};

export default ValleNevado;
