import React from "react";

class Footer extends React.Component {

    render() {
        
        return(
            <footer className="footer footer-black  footer-white ">
                <div className="container-fluid">
                    <div className="row">
                        
                    </div>
                </div>
            </footer>
        )
    }
    
}

export default Footer;