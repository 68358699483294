const Nunoa = {
    commune: "Ñuñoa",
    polygons: [
        {
            idService: 27,
            nameService : "Nuñoa 1 / Hasta Los Leones",
            coordinates: [
                { "lat": -33.44466429621362 , "lng": -70.59921930228423 }, // gral jose artigas
                { "lat": -33.44866492805583 , "lng": -70.59861238765673 }, // gral jose artigas
                { "lat": -33.449259942986465, "lng": -70.59786891727698 }, // chile españa
                { "lat": -33.45407055207174 , "lng": -70.5986427333805  }, // chile españa con irarrazabal
                { "lat": -33.455507353284425, "lng": -70.59783098510461 }, // jose pedro alessandri
                { "lat": -33.46451381479216 , "lng": -70.59833355218333 }, // jose pedro alessandri con Grecia
                { "lat": -33.46446951310578 , "lng": -70.60051085829203 }, // grecia con Exequiel fernandez
                { "lat": -33.46421833354207 , "lng": -70.60153168611926 }, // Grecia con Cordillera
                { "lat": -33.46285621997097 , "lng": -70.60404420866948 }, // Grecia con los tres antonios
                { "lat": -33.46241762211928 , "lng": -70.60500090973824 }, // grecia -> wARRIOR TI
                { "lat": -33.46218699822227 , "lng": -70.60612551962708 }, // Grecia con predro de valdivia
                { "lat": -33.46169476541267 , "lng":-70.61500677283261  }, // Grecia con maraton
                { "lat": -33.46268379900636 , "lng":-70.61508187467786  }, // Maraton con pericles
                { "lat": -33.46560608115352 , "lng":-70.6148994844802   },
                { "lat": -33.46748848686427 , "lng":-70.61406638240777  },
                { "lat": -33.46768416288512 , "lng":-70.61402127320068  },
                { "lat": -33.469896776174835, "lng":-70.61385888009626  }, // Maraton con guillermo man
                { "lat": -33.47444976520444 , "lng":-70.61361529043451  }, // Rodrigo de araya con maraton
                { "lat": -33.474948004109514, "lng":-70.62312162799037  }, // Vicuña con rodrigo de araya
                { "lat": -33.470485350241944, "lng":-70.6242493579636   }, // Vicuña
                { "lat": -33.47010153623325 , "lng":-70.62442077291274  }, // Vicuña
                { "lat": -33.46959730737981 , "lng":-70.62516958557718  }, // Vicuña
                { "lat": -33.46918860187568 , "lng":-70.62579495830616  }, // Vicuña
                { "lat": -33.46870159692864 , "lng":-70.62612850085257  },
                { "lat": -33.44984140533306 , "lng": -70.63155879038291 }, // Vicuña con malaquias concha
                { "lat": -33.44952838330592 , "lng": -70.62989389102046 }, // malaquias concha con Gral Bustamante
                { "lat": -33.448921156577605, "lng": -70.62676838972413 }, // malaquias concha con emilio vaisse
                { "lat": -33.44858070082646 , "lng": -70.62538620343419 }, // caupolican con Condell
                { "lat": -33.44726711595107 , "lng": -70.61878221672181 }, // Caupolican con Jose Manuel Infante
                { "lat": -33.44838994759786 , "lng": -70.61845218447844 }, // Rengo con Jose Manuel Infante
                { "lat": -33.447571981282245, "lng": -70.61376633307509 }, // Rengo con Manuel Montt
                { "lat": -33.44684406969847 , "lng": -70.61391146450484 }, // Santa Isabel con Manuel Montt
                { "lat": -33.446452292648   , "lng": -70.61125862398538 }, // Diagonal Oriente con Antonio Varas
                { "lat": -33.445739341396745, "lng": -70.60875405401904 }, // Diag Oriente con dr. Alejandro del Rio
                { "lat": -33.44564519523322 , "lng": -70.60807908224493 }, // Diag. Oriente con Marchant Pereira
                { "lat": -33.445433604431024, "lng": -70.60616207132756 }, // Diag. Oriente con Pedro de Valdivia
                { "lat": -33.44524197457926 , "lng": -70.60483515524939 }, // Dr. Pedro Lautaro Ferrer
                { "lat": -33.444676397664374, "lng": -70.59921106701573 }, // gral jose artigas
            ]
        },
        {
            idService: 121,
            nameService : "Ñuñoa 2 / Desde Los Leones",
            coordinates: [
                { "lat": -33.47360941056774 , "lng": -70.59679446242907 },  // Rodrigo de araya con poconchile 1
                { "lat": -33.47350659172262 , "lng": -70.59651954809992 },  // Rodrigo de araya con poconchile 2
                { "lat": -33.473481594765545, "lng": -70.59594886656495 },  // Rodrigo de Araya 3
                { "lat": -33.47360440580576 , "lng": -70.59347070154388 },  // Rodrigo de araya con quilogo
                { "lat": -33.47367939653265 , "lng": -70.59210393230386 },  // Rodrigo de araya con Juan moya morales
                { "lat": -33.4739782719724  , "lng": -70.58947071911683 },  // Rodrigo de Araya 4
                { "lat": -33.474418991196224, "lng": -70.58807137673148 },  // Rotonda Rodrigo de Araya
                { "lat": -33.474434206567906, "lng": -70.58768049898448 },
                { "lat": -33.47434943517386 , "lng": -70.58732870897435 },
                { "lat": -33.474055995104834, "lng": -70.58696649559548 },
                { "lat": -33.47389297241488 , "lng": -70.58687789663949 },
                { "lat": -33.47372342847848 , "lng": -70.58687007907443 },
                { "lat": -33.473471285620604, "lng": -70.58690134929418 },  // Fin Rotonda
                { "lat": -33.47353649505516 , "lng": -70.58642187256083 },  // Continua Rodrigo de Araya
                { "lat": -33.473484327514974, "lng": -70.58580167986894 },  // Rodrigo de Araya con psje 29
                { "lat": -33.47337781868589 , "lng": -70.58471503967763 },  // Rodrigo de Araya con C. Nueva
                { "lat": -33.47321262105736 , "lng": -70.58403491238441 },
                { "lat": -33.47294743474025 , "lng": -70.5834251430622  },
                { "lat": -33.46989338628152 , "lng": -70.57740301967489 },  // Inicio Rotonda Grecia
                { "lat": -33.470123802223405, "lng": -70.57717370470151 },
                { "lat": -33.47023031504833 , "lng": -70.57696784242142 },
                { "lat": -33.47029987437353 , "lng": -70.57690790783356 },
                { "lat": -33.470291179460936, "lng": -70.57670986310842 },
                { "lat": -33.47024770488486 , "lng": -70.57625905077356 },
                { "lat": -33.47012380221975 , "lng": -70.57601149483139 },
                { "lat": -33.469717313297075, "lng": -70.57572224529862 },
                { "lat": -33.46949993875294 , "lng": -70.57569358092776 },
                { "lat": -33.46922604605896 , "lng": -70.57576654477387 },
                { "lat": -33.46901301780187 , "lng": -70.57593592513918 },
                { "lat": -33.46889563466523 , "lng": -70.57609227623799 },
                { "lat": -33.46884951676772 , "lng": -70.57621972800882 },  // Fin Rotonda Grecia
                { "lat": -33.46576706581035 , "lng": -70.5750058630851  },
                { "lat": -33.46517626036804 , "lng": -70.57478031708486 },
                { "lat": -33.4639344269312  , "lng": -70.57441042165398 },
                { "lat": -33.462173250814644, "lng": -70.57371573998135 },
                { "lat": -33.46010344683348 , "lng": -70.57263311920839 },
                { "lat": -33.458541652916914, "lng": -70.57211436341227 },
                { "lat": -33.458018539764105, "lng": -70.57191137202511 },
                { "lat": -33.456392729063104, "lng": -70.57148734555284 },
                { "lat": -33.45338188801721 , "lng": -70.5706708690424  }, // Vespucio con larrain
                { "lat": -33.45162562305374 , "lng": -70.57081526622109 },
                { "lat": -33.4502703161533  , "lng": -70.57118880123024 },
                { "lat": -33.44031091073945 , "lng": -70.57296442359372 },
                { "lat": -33.43769218062982 , "lng": -70.57361888257358 },
                { "lat": -33.43753997847154 , "lng": -70.57420896857414 },
                { "lat": -33.43644382940488 , "lng": -70.57686205638903 },
                { "lat": -33.43582647355662 , "lng": -70.57852207484389 },
                { "lat": -33.43379368545517 , "lng": -70.58227516004618 },
                { "lat": -33.43943437629437 , "lng": -70.58720358852986 },  // Eliecer Parada
                { "lat": -33.44658874919246 , "lng": -70.59179675947227 },
                { "lat": -33.447772618362144, "lng": -70.59282485847635 },
                { "lat": -33.445964291816   , "lng": -70.5961668909289  }, // jaime Guzman con Chile España
                { "lat": -33.4448273543315  , "lng": -70.59803907279232 },
                { "lat": -33.44492582981816 , "lng": -70.59913341405314 }, // Gral Jose Artigas
                { "lat": -33.44868571944402 , "lng": -70.59856210354751 },
                { "lat": -33.448999036216534, "lng": -70.59817586546451 },
                { "lat": -33.449240736964406, "lng": -70.5978084028394  },
                { "lat": -33.45066974124385 , "lng": -70.59801702973606 },
                { "lat": -33.454040305657784, "lng": -70.59856704609918 },
                { "lat": -33.45522575247237 , "lng": -70.59773318153135 }, // Chile españa con irarrazabal
                { "lat": -33.464737285550854, "lng": -70.59824957285707 }, // Jose pedro alessandri con av grecia
                { "lat": -33.46462363352432 , "lng": -70.60063694943157 }, // gracia con excequiel fernandes
                { "lat": -33.46441631469372 , "lng": -70.60147808034075 }, // Grecia con Cordillera
                { "lat": -33.46302074023059 , "lng": -70.60421341319412 }, // Grecia con los tres antonios
                { "lat": -33.46259327905258 , "lng": -70.60507543992394 }, // grecia -> wARRIOR TI
                { "lat": -33.462340153988826, "lng": -70.60615648977061 }, // Grecia con predro de valdivia
                { "lat": -33.46185541748111 , "lng": -70.6140298808468  }, // Grecia con Eduardo donoso
                { "lat": -33.46169476541267 , "lng":-70.61500677283261  }, // Grecia con maraton
                { "lat": -33.46268379900636 , "lng":-70.61508187467786  }, // Maraton con pericles
                { "lat": -33.46560608115352 , "lng":-70.6148994844802   },
                { "lat": -33.46748848686427 , "lng":-70.61406638240777  },
                { "lat": -33.46768416288512 , "lng":-70.61402127320068  },
                { "lat": -33.469896776174835, "lng":-70.61385888009626  }, // Maraton con guillermo man
                { "lat": -33.47444976520444 , "lng":-70.61361529043451  }, // Rodrigo de araya con maraton
            ]
        }
    ]
};

export default Nunoa;
