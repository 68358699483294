const VinaDelMar = {
    commune : "Viña del Mar",
    polygons: [
        {
            idService: 114,
            nameService: "Viña del Mar",
            coordinates: [
                { "lat": -33.0285292, "lng": -71.5870673 },
                { "lat": -33.0283183, "lng": -71.5869949 },
                { "lat": -33.0275401, "lng": -71.5867218 },
                { "lat": -33.0270415, "lng": -71.5864379 },
                { "lat": -33.026848, "lng": -71.5863371 },
                { "lat": -33.0267572, "lng": -71.5862058 },
                { "lat": -33.0266509, "lng": -71.5861249 },
                { "lat": -33.0264909, "lng": -71.5861379 },
                { "lat": -33.0263817, "lng": -71.5861305 },
                { "lat": -33.0262571, "lng": -71.5861406 },
                { "lat": -33.0261043, "lng": -71.5863754 },
                { "lat": -33.0258995, "lng": -71.5865241 },
                { "lat": -33.0258546, "lng": -71.586411 },
                { "lat": -33.0257752, "lng": -71.5863834 },
                { "lat": -33.0258439, "lng": -71.586271 },
                { "lat": -33.0258232, "lng": -71.5861208 },
                { "lat": -33.0258422, "lng": -71.5859495 },
                { "lat": -33.0257409, "lng": -71.5858571 },
                { "lat": -33.0257061, "lng": -71.586027 },
                { "lat": -33.0256029, "lng": -71.5860814 },
                { "lat": -33.0255727, "lng": -71.5860053 },
                { "lat": -33.0255171, "lng": -71.5857477 },
                { "lat": -33.0254677, "lng": -71.5858015 },
                { "lat": -33.0254035, "lng": -71.585774 },
                { "lat": -33.0254041, "lng": -71.5856432 },
                { "lat": -33.0255637, "lng": -71.5854998 },
                { "lat": -33.0253815, "lng": -71.585398 },
                { "lat": -33.0254747, "lng": -71.5851501 },
                { "lat": -33.0256178, "lng": -71.5851868 },
                { "lat": -33.025614, "lng": -71.5850478 },
                { "lat": -33.0255082, "lng": -71.5848293 },
                { "lat": -33.0256162, "lng": -71.5846482 },
                { "lat": -33.0254408, "lng": -71.5841104 },
                { "lat": -33.0253682, "lng": -71.5841134 },
                { "lat": -33.0253197, "lng": -71.5838391 },
                { "lat": -33.0252058, "lng": -71.5834359 },
                { "lat": -33.0251862, "lng": -71.5833241 },
                { "lat": -33.0251743, "lng": -71.5831911 },
                { "lat": -33.0251993, "lng": -71.5830919 },
                { "lat": -33.0252315, "lng": -71.5830876 },
                { "lat": -33.0252324, "lng": -71.5830035 },
                { "lat": -33.0253713, "lng": -71.5826649 },
                { "lat": -33.0254471, "lng": -71.5824029 },
                { "lat": -33.0254706, "lng": -71.5821989 },
                { "lat": -33.0255292, "lng": -71.5819516 },
                { "lat": -33.0255648, "lng": -71.581814 },
                { "lat": -33.0255971, "lng": -71.5815184 },
                { "lat": -33.0256344, "lng": -71.5813548 },
                { "lat": -33.0257747, "lng": -71.5810373 },
                { "lat": -33.0258462, "lng": -71.5808802 },
                { "lat": -33.0260586, "lng": -71.5806093 },
                { "lat": -33.0263409, "lng": -71.5802867 },
                { "lat": -33.0263219, "lng": -71.5801843 },
                { "lat": -33.0263237, "lng": -71.5801316 },
                { "lat": -33.0263367, "lng": -71.5800786 },
                { "lat": -33.0263701, "lng": -71.5800545 },
                { "lat": -33.0264259, "lng": -71.5800571 },
                { "lat": -33.0264652, "lng": -71.5800935 },
                { "lat": -33.0265342, "lng": -71.580277 },
                { "lat": -33.025882, "lng": -71.581073 },
                { "lat": -33.0261614, "lng": -71.5813986 },
                { "lat": -33.0264463, "lng": -71.5810666 },
                { "lat": -33.0267639, "lng": -71.5805714 },
                { "lat": -33.0268991, "lng": -71.5803216 },
                { "lat": -33.0267372, "lng": -71.5800991 },
                { "lat": -33.0266753, "lng": -71.5801393 },
                { "lat": -33.0266596, "lng": -71.5801045 },
                { "lat": -33.0267157, "lng": -71.5800636 },
                { "lat": -33.0267043, "lng": -71.5800073 },
                { "lat": -33.0267037, "lng": -71.5799429 },
                { "lat": -33.0267196, "lng": -71.5798696 },
                { "lat": -33.0267408, "lng": -71.5798171 },
                { "lat": -33.0267852, "lng": -71.5797464 },
                { "lat": -33.0268342, "lng": -71.5796668 },
                { "lat": -33.0268832, "lng": -71.5795695 },
                { "lat": -33.0268989, "lng": -71.5794832 },
                { "lat": -33.0268642, "lng": -71.5793407 },
                { "lat": -33.0269195, "lng": -71.5793037 },
                { "lat": -33.0269534, "lng": -71.5793874 },
                { "lat": -33.0270339, "lng": -71.5793579 },
                { "lat": -33.0272891, "lng": -71.5781334 },
                { "lat": -33.0273278, "lng": -71.5774668 },
                { "lat": -33.0274403, "lng": -71.5771727 },
                { "lat": -33.0274547, "lng": -71.5771353 },
                { "lat": -33.027497, "lng": -71.5767963 },
                { "lat": -33.0273827, "lng": -71.5764178 },
                { "lat": -33.0270877, "lng": -71.5759204 },
                { "lat": -33.0267079, "lng": -71.5754902 },
                { "lat": -33.0266567, "lng": -71.5755462 },
                { "lat": -33.0266517, "lng": -71.5757667 },
                { "lat": -33.0265736, "lng": -71.575774 },
                { "lat": -33.0265609, "lng": -71.5756028 },
                { "lat": -33.0262433, "lng": -71.5755602 },
                { "lat": -33.0261113, "lng": -71.575451 },
                { "lat": -33.0259876, "lng": -71.5752065 },
                { "lat": -33.0259075, "lng": -71.5745156 },
                { "lat": -33.0259611, "lng": -71.574376 },
                { "lat": -33.025829, "lng": -71.5740465 },
                { "lat": -33.0256562, "lng": -71.5737746 },
                { "lat": -33.0250876, "lng": -71.5731752 },
                { "lat": -33.0250846, "lng": -71.5729992 },
                { "lat": -33.0249343, "lng": -71.5727591 },
                { "lat": -33.0249214, "lng": -71.5722497 },
                { "lat": -33.0248772, "lng": -71.5719968 },
                { "lat": -33.0247384, "lng": -71.5717296 },
                { "lat": -33.0247318, "lng": -71.571513 },
                { "lat": -33.0246088, "lng": -71.5711709 },
                { "lat": -33.0241665, "lng": -71.5706703 },
                { "lat": -33.0231405, "lng": -71.5695105 },
                { "lat": -33.0223245, "lng": -71.5687655 },
                { "lat": -33.0218204, "lng": -71.5684465 },
                { "lat": -33.0215879, "lng": -71.5683831 },
                { "lat": -33.0214081, "lng": -71.5686604 },
                { "lat": -33.0211929, "lng": -71.5687082 },
                { "lat": -33.020938, "lng": -71.5686829 },
                { "lat": -33.0208131, "lng": -71.568602 },
                { "lat": -33.0206472, "lng": -71.56847 },
                { "lat": -33.0204555, "lng": -71.5681845 },
                { "lat": -33.0206872, "lng": -71.5679829 },
                { "lat": -33.0206769, "lng": -71.5677437 },
                { "lat": -33.0207946, "lng": -71.5676587 },
                { "lat": -33.0209127, "lng": -71.5674713 },
                { "lat": -33.0208975, "lng": -71.5671066 },
                { "lat": -33.0209632, "lng": -71.5668214 },
                { "lat": -33.0209378, "lng": -71.5666506 },
                { "lat": -33.0208889, "lng": -71.5665515 },
                { "lat": -33.020865, "lng": -71.5664582 },
                { "lat": -33.0207829, "lng": -71.5663697 },
                { "lat": -33.0207402, "lng": -71.5662919 },
                { "lat": -33.0207099, "lng": -71.5661739 },
                { "lat": -33.0206749, "lng": -71.5660345 },
                { "lat": -33.0205794, "lng": -71.5658641 },
                { "lat": -33.0205142, "lng": -71.5657528 },
                { "lat": -33.0204231, "lng": -71.5656482 },
                { "lat": -33.0203377, "lng": -71.5655825 },
                { "lat": -33.0202414, "lng": -71.565548 },
                { "lat": -33.0201681, "lng": -71.5655264 },
                { "lat": -33.0198878, "lng": -71.5655968 },
                { "lat": -33.0197973, "lng": -71.5655331 },
                { "lat": -33.019721, "lng": -71.565668 },
                { "lat": -33.0196224, "lng": -71.565717 },
                { "lat": -33.0195659, "lng": -71.565658 },
                { "lat": -33.0195777, "lng": -71.5655588 },
                { "lat": -33.019726, "lng": -71.5653928 },
                { "lat": -33.0195601, "lng": -71.5652789 },
                { "lat": -33.0194349, "lng": -71.5653458 },
                { "lat": -33.0193547, "lng": -71.5655167 },
                { "lat": -33.0192719, "lng": -71.5654215 },
                { "lat": -33.0193671, "lng": -71.5652867 },
                { "lat": -33.0191213, "lng": -71.5652535 },
                { "lat": -33.0190236, "lng": -71.5650966 },
                { "lat": -33.0189515, "lng": -71.5651593 },
                { "lat": -33.0188683, "lng": -71.5651407 },
                { "lat": -33.0188917, "lng": -71.5649829 },
                { "lat": -33.0187904, "lng": -71.5647883 },
                { "lat": -33.018825, "lng": -71.5646802 },
                { "lat": -33.0189009, "lng": -71.5646265 },
                { "lat": -33.0190183, "lng": -71.5646002 },
                { "lat": -33.0191429, "lng": -71.5646551 },
                { "lat": -33.019219, "lng": -71.5645563 },
                { "lat": -33.0191489, "lng": -71.5641543 },
                { "lat": -33.0193546, "lng": -71.5638433 },
                { "lat": -33.0192798, "lng": -71.5636578 },
                { "lat": -33.0192952, "lng": -71.5635857 },
                { "lat": -33.0195157, "lng": -71.5633615 },
                { "lat": -33.0195054, "lng": -71.5632165 },
                { "lat": -33.0195086, "lng": -71.5629068 },
                { "lat": -33.0195252, "lng": -71.562833 },
                { "lat": -33.0195196, "lng": -71.5626044 },
                { "lat": -33.0194393, "lng": -71.5624146 },
                { "lat": -33.0195691, "lng": -71.5623186 },
                { "lat": -33.0193612, "lng": -71.5620787 },
                { "lat": -33.0191539, "lng": -71.5618716 },
                { "lat": -33.0189446, "lng": -71.5620203 },
                { "lat": -33.0185856, "lng": -71.5618906 },
                { "lat": -33.0184288, "lng": -71.5617826 },
                { "lat": -33.0183436, "lng": -71.5617153 },
                { "lat": -33.0180885, "lng": -71.5613331 },
                { "lat": -33.0177034, "lng": -71.5608157 },
                { "lat": -33.0174007, "lng": -71.5604064 },
                { "lat": -33.0170352, "lng": -71.5598587 },
                { "lat": -33.0168892, "lng": -71.5596823 },
                { "lat": -33.0161437, "lng": -71.5587815 },
                { "lat": -33.0157186, "lng": -71.5582911 },
                { "lat": -33.0148283, "lng": -71.5573118 },
                { "lat": -33.0146013, "lng": -71.557027 },
                { "lat": -33.0144017, "lng": -71.5568545 },
                { "lat": -33.014243, "lng": -71.5567191 },
                { "lat": -33.0141504, "lng": -71.5564825 },
                { "lat": -33.0141639, "lng": -71.556129 },
                { "lat": -33.0138232, "lng": -71.5558407 },
                { "lat": -33.0131361, "lng": -71.5553498 },
                { "lat": -33.0127188, "lng": -71.5550441 },
                { "lat": -33.0122105, "lng": -71.5546873 },
                { "lat": -33.0119305, "lng": -71.5544943 },
                { "lat": -33.0116685, "lng": -71.5543038 },
                { "lat": -33.0115932, "lng": -71.5542288 },
                { "lat": -33.0115201, "lng": -71.5541657 },
                { "lat": -33.0114223, "lng": -71.5540691 },
                { "lat": -33.0113289, "lng": -71.5539658 },
                { "lat": -33.0112154, "lng": -71.5538774 },
                { "lat": -33.0109781, "lng": -71.5537071 },
                { "lat": -33.0103167, "lng": -71.5533383 },
                { "lat": -33.0095744, "lng": -71.55296 },
                { "lat": -33.0089852, "lng": -71.5526811 },
                { "lat": -33.0083643, "lng": -71.552378 },
                { "lat": -33.0075006, "lng": -71.5520212 },
                { "lat": -33.0064457, "lng": -71.5515922 },
                { "lat": -33.0048284, "lng": -71.5510395 },
                { "lat": -33.0037802, "lng": -71.5506722 },
                { "lat": -33.0025115, "lng": -71.5502536 },
                { "lat": -33.0019762, "lng": -71.5501062 },
                { "lat": -33.001659, "lng": -71.5500525 },
                { "lat": -33.0012271, "lng": -71.5499506 },
                { "lat": -33.0009707, "lng": -71.5498728 },
                { "lat": -33.0004465, "lng": -71.5497682 },
                { "lat": -32.9997785, "lng": -71.5496233 },
                { "lat": -32.9991553, "lng": -71.549457 },
                { "lat": -32.9986267, "lng": -71.549331 },
                { "lat": -32.9980891, "lng": -71.5491888 },
                { "lat": -32.9974862, "lng": -71.5491003 },
                { "lat": -32.9970544, "lng": -71.5490332 },
                { "lat": -32.9953536, "lng": -71.548859 },
                { "lat": -32.9942671, "lng": -71.548714 },
                { "lat": -32.9935292, "lng": -71.5486202 },
                { "lat": -32.9930433, "lng": -71.548529 },
                { "lat": -32.9924785, "lng": -71.5484405 },
                { "lat": -32.9921501, "lng": -71.5484136 },
                { "lat": -32.9918486, "lng": -71.548411 },
                { "lat": -32.9915877, "lng": -71.5484244 },
                { "lat": -32.9914617, "lng": -71.5484888 },
                { "lat": -32.9912974, "lng": -71.5485908 },
                { "lat": -32.9912086, "lng": -71.5486685 },
                { "lat": -32.9911641, "lng": -71.5488848 },
                { "lat": -32.9911861, "lng": -71.5489352 },
                { "lat": -32.9911894, "lng": -71.5489583 },
                { "lat": -32.9911797, "lng": -71.5490142 },
                { "lat": -32.9911624, "lng": -71.5490548 },
                { "lat": -32.9911736, "lng": -71.549079 },
                { "lat": -32.9912096, "lng": -71.5490844 },
                { "lat": -32.9912434, "lng": -71.5490441 },
                { "lat": -32.9912794, "lng": -71.5490334 },
                { "lat": -32.9913019, "lng": -71.5490334 },
                { "lat": -32.9913401, "lng": -71.5490575 },
                { "lat": -32.9913424, "lng": -71.549087 },
                { "lat": -32.9913446, "lng": -71.5491353 },
                { "lat": -32.9913604, "lng": -71.5491595 },
                { "lat": -32.9913912, "lng": -71.5491135 },
                { "lat": -32.9914211, "lng": -71.5491112 },
                { "lat": -32.9914526, "lng": -71.5491273 },
                { "lat": -32.9914728, "lng": -71.5491568 },
                { "lat": -32.9914661, "lng": -71.5491916 },
                { "lat": -32.9914503, "lng": -71.5492024 },
                { "lat": -32.9914002, "lng": -71.5492422 },
                { "lat": -32.9913604, "lng": -71.5492936 },
                { "lat": -32.9913536, "lng": -71.5493123 },
                { "lat": -32.9913102, "lng": -71.5493468 },
                { "lat": -32.9912574, "lng": -71.5493603 },
                { "lat": -32.9912168, "lng": -71.5493842 },
                { "lat": -32.991182, "lng": -71.549387 },
                { "lat": -32.9911534, "lng": -71.5494035 },
                { "lat": -32.9911399, "lng": -71.5494411 },
                { "lat": -32.9911241, "lng": -71.549476 },
                { "lat": -32.9911084, "lng": -71.5495028 },
                { "lat": -32.9910897, "lng": -71.5495265 },
                { "lat": -32.9910627, "lng": -71.5495587 },
                { "lat": -32.9910431, "lng": -71.5495859 },
                { "lat": -32.9909975, "lng": -71.5495882 },
                { "lat": -32.9909255, "lng": -71.5496016 },
                { "lat": -32.9909007, "lng": -71.5496177 },
                { "lat": -32.9908519, "lng": -71.5496208 },
                { "lat": -32.9908072, "lng": -71.5496252 },
                { "lat": -32.9907522, "lng": -71.5496575 },
                { "lat": -32.9907253, "lng": -71.5496338 },
                { "lat": -32.9907034, "lng": -71.5495993 },
                { "lat": -32.9906533, "lng": -71.5495587 },
                { "lat": -32.9906359, "lng": -71.5495725 },
                { "lat": -32.9906, "lng": -71.549602 },
                { "lat": -32.9905655, "lng": -71.5495614 },
                { "lat": -32.9905325, "lng": -71.5495457 },
                { "lat": -32.990501, "lng": -71.5495323 },
                { "lat": -32.990489, "lng": -71.5494863 },
                { "lat": -32.9905167, "lng": -71.5494464 },
                { "lat": -32.9904897, "lng": -71.5494196 },
                { "lat": -32.9905273, "lng": -71.5493844 },
                { "lat": -32.990483, "lng": -71.549366 },
                { "lat": -32.9904582, "lng": -71.5493794 },
                { "lat": -32.9904155, "lng": -71.5493982 },
                { "lat": -32.9903772, "lng": -71.5493982 },
                { "lat": -32.9903367, "lng": -71.5493928 },
                { "lat": -32.9903052, "lng": -71.5493848 },
                { "lat": -32.9902933, "lng": -71.5493388 },
                { "lat": -32.990291, "lng": -71.5493053 },
                { "lat": -32.990285, "lng": -71.5492533 },
                { "lat": -32.990312, "lng": -71.5492131 },
                { "lat": -32.9903351, "lng": -71.5491905 },
                { "lat": -32.990348, "lng": -71.5491568 },
                { "lat": -32.9903577, "lng": -71.5491315 },
                { "lat": -32.9903592, "lng": -71.549087 },
                { "lat": -32.990348, "lng": -71.5490495 },
                { "lat": -32.9903165, "lng": -71.5490522 },
                { "lat": -32.9902805, "lng": -71.5490897 },
                { "lat": -32.990249, "lng": -71.5491246 },
                { "lat": -32.9902019, "lng": -71.549146 },
                { "lat": -32.9901726, "lng": -71.5491809 },
                { "lat": -32.9901321, "lng": -71.5491809 },
                { "lat": -32.9900984, "lng": -71.5491621 },
                { "lat": -32.9900804, "lng": -71.5491031 },
                { "lat": -32.9900691, "lng": -71.5490656 },
                { "lat": -32.9900781, "lng": -71.5490334 },
                { "lat": -32.9901178, "lng": -71.5490062 },
                { "lat": -32.9901501, "lng": -71.5489932 },
                { "lat": -32.9901741, "lng": -71.548974 },
                { "lat": -32.9901966, "lng": -71.5489472 },
                { "lat": -32.9902191, "lng": -71.5489284 },
                { "lat": -32.9902438, "lng": -71.5489123 },
                { "lat": -32.9902512, "lng": -71.5488832 },
                { "lat": -32.9902289, "lng": -71.5488295 },
                { "lat": -32.9902064, "lng": -71.5488134 },
                { "lat": -32.9902041, "lng": -71.5487839 },
                { "lat": -32.9901951, "lng": -71.5487357 },
                { "lat": -32.9901704, "lng": -71.5486954 },
                { "lat": -32.9901591, "lng": -71.5486552 },
                { "lat": -32.9901614, "lng": -71.5485747 },
                { "lat": -32.9901381, "lng": -71.5484912 },
                { "lat": -32.9901336, "lng": -71.5484456 },
                { "lat": -32.9901591, "lng": -71.5484004 },
                { "lat": -32.9901921, "lng": -71.5483598 },
                { "lat": -32.9901974, "lng": -71.548336 },
                { "lat": -32.9901853, "lng": -71.548282 },
                { "lat": -32.9902191, "lng": -71.5482417 },
                { "lat": -32.9902371, "lng": -71.548231 },
                { "lat": -32.9902422, "lng": -71.5481939 },
                { "lat": -32.9902348, "lng": -71.5481613 },
                { "lat": -32.9902168, "lng": -71.5481291 },
                { "lat": -32.9901921, "lng": -71.5480808 },
                { "lat": -32.9901704, "lng": -71.5480356 },
                { "lat": -32.9901381, "lng": -71.5480057 },
                { "lat": -32.9901156, "lng": -71.5479681 },
                { "lat": -32.9901186, "lng": -71.5479149 },
                { "lat": -32.9901344, "lng": -71.5478774 },
                { "lat": -32.9901366, "lng": -71.5478398 },
                { "lat": -32.9901489, "lng": -71.5474749 },
                { "lat": -32.9899588, "lng": -71.5473099 },
                { "lat": -32.9896574, "lng": -71.5471008 },
                { "lat": -32.9893279, "lng": -71.5469411 },
                { "lat": -32.9891727, "lng": -71.5468607 },
                { "lat": -32.9890466, "lng": -71.5468044 },
                { "lat": -32.9889015, "lng": -71.5467534 },
                { "lat": -32.9887743, "lng": -71.5467172 },
                { "lat": -32.9886978, "lng": -71.5470284 },
                { "lat": -32.9886754, "lng": -71.547023 },
                { "lat": -32.9886506, "lng": -71.5470257 },
                { "lat": -32.9886203, "lng": -71.5470189 },
                { "lat": -32.9885741, "lng": -71.5470163 },
                { "lat": -32.988555, "lng": -71.5470096 },
                { "lat": -32.9885246, "lng": -71.5469948 },
                { "lat": -32.9884942, "lng": -71.5469707 },
                { "lat": -32.9884268, "lng": -71.546956 },
                { "lat": -32.9883784, "lng": -71.5469211 },
                { "lat": -32.9883311, "lng": -71.5468916 },
                { "lat": -32.988273, "lng": -71.5468765 },
                { "lat": -32.9882288, "lng": -71.5468688 },
                { "lat": -32.9881771, "lng": -71.5468769 },
                { "lat": -32.9881433, "lng": -71.5469064 },
                { "lat": -32.9881268, "lng": -71.546906 },
                { "lat": -32.9880961, "lng": -71.5468849 },
                { "lat": -32.9880623, "lng": -71.5468823 },
                { "lat": -32.9879581, "lng": -71.5468953 },
                { "lat": -32.9877654, "lng": -71.5468823 },
                { "lat": -32.9873604, "lng": -71.546834 },
                { "lat": -32.9870742, "lng": -71.5468628 },
                { "lat": -32.9870409, "lng": -71.5468581 },
                { "lat": -32.9869904, "lng": -71.5468773 },
                { "lat": -32.9869644, "lng": -71.5468769 },
                { "lat": -32.9867747, "lng": -71.5469409 },
                { "lat": -32.9867364, "lng": -71.5469489 },
                { "lat": -32.9866922, "lng": -71.5469708 },
                { "lat": -32.9866599, "lng": -71.5469811 },
                { "lat": -32.9866127, "lng": -71.5469945 },
                { "lat": -32.9865834, "lng": -71.5470213 },
                { "lat": -32.9865564, "lng": -71.5470562 },
                { "lat": -32.9865227, "lng": -71.5470937 },
                { "lat": -32.9864876, "lng": -71.5471317 },
                { "lat": -32.986465, "lng": -71.5471505 },
                { "lat": -32.9864357, "lng": -71.5472068 },
                { "lat": -32.986411, "lng": -71.5472202 },
                { "lat": -32.9863862, "lng": -71.5472202 },
                { "lat": -32.9863412, "lng": -71.5472256 },
                { "lat": -32.9862864, "lng": -71.5472413 },
                { "lat": -32.986258, "lng": -71.5472819 },
                { "lat": -32.9862392, "lng": -71.5473325 },
                { "lat": -32.9862144, "lng": -71.5473834 },
                { "lat": -32.9861657, "lng": -71.5473865 },
                { "lat": -32.9861252, "lng": -71.5473865 },
                { "lat": -32.986087, "lng": -71.5473999 },
                { "lat": -32.9860352, "lng": -71.5473999 },
                { "lat": -32.985997, "lng": -71.5473838 },
                { "lat": -32.9859579, "lng": -71.5474026 },
                { "lat": -32.985922, "lng": -71.5474237 },
                { "lat": -32.9858823, "lng": -71.5474214 },
                { "lat": -32.9858463, "lng": -71.5473946 },
                { "lat": -32.9858117, "lng": -71.54737 },
                { "lat": -32.9857653, "lng": -71.5473624 },
                { "lat": -32.985709, "lng": -71.5473919 },
                { "lat": -32.985688, "lng": -71.5474183 },
                { "lat": -32.9856258, "lng": -71.5474294 },
                { "lat": -32.9856078, "lng": -71.5474616 },
                { "lat": -32.9855785, "lng": -71.5474938 },
                { "lat": -32.9855755, "lng": -71.5475712 },
                { "lat": -32.985556, "lng": -71.5476199 },
                { "lat": -32.9855493, "lng": -71.5476708 },
                { "lat": -32.98558, "lng": -71.5477107 },
                { "lat": -32.985592, "lng": -71.5477701 },
                { "lat": -32.9855808, "lng": -71.5478076 },
                { "lat": -32.985574, "lng": -71.5478371 },
                { "lat": -32.985544, "lng": -71.547885 },
                { "lat": -32.985511, "lng": -71.5479176 },
                { "lat": -32.9854975, "lng": -71.5479525 },
                { "lat": -32.985448, "lng": -71.5480302 },
                { "lat": -32.985409, "lng": -71.5480674 },
                { "lat": -32.9854098, "lng": -71.5481053 },
                { "lat": -32.9854247, "lng": -71.5481371 },
                { "lat": -32.985454, "lng": -71.5481398 },
                { "lat": -32.985463, "lng": -71.5481747 },
                { "lat": -32.9854413, "lng": -71.5482019 },
                { "lat": -32.9854188, "lng": -71.5482502 },
                { "lat": -32.9853828, "lng": -71.5482475 },
                { "lat": -32.9853085, "lng": -71.5482716 },
                { "lat": -32.9852793, "lng": -71.5482663 },
                { "lat": -32.985268, "lng": -71.548218 },
                { "lat": -32.9852635, "lng": -71.5481804 },
                { "lat": -32.9852298, "lng": -71.5481724 },
                { "lat": -32.9852051, "lng": -71.5481429 },
                { "lat": -32.9851871, "lng": -71.5480973 },
                { "lat": -32.9851736, "lng": -71.5480732 },
                { "lat": -32.9851511, "lng": -71.5480383 },
                { "lat": -32.9851196, "lng": -71.5480463 },
                { "lat": -32.9851098, "lng": -71.5481023 },
                { "lat": -32.9850715, "lng": -71.5481049 },
                { "lat": -32.9850386, "lng": -71.5481053 },
                { "lat": -32.9850183, "lng": -71.5480758 },
                { "lat": -32.9849725, "lng": -71.5480325 },
                { "lat": -32.9849508, "lng": -71.5479846 },
                { "lat": -32.9848923, "lng": -71.5479712 },
                { "lat": -32.9848586, "lng": -71.5479712 },
                { "lat": -32.9848248, "lng": -71.5479498 },
                { "lat": -32.9847686, "lng": -71.5479444 },
                { "lat": -32.984734, "lng": -71.5478528 },
                { "lat": -32.9847393, "lng": -71.5478183 },
                { "lat": -32.9847835, "lng": -71.5477938 },
                { "lat": -32.9847866, "lng": -71.5477513 },
                { "lat": -32.9847888, "lng": -71.5476896 },
                { "lat": -32.9847754, "lng": -71.547647 },
                { "lat": -32.9847348, "lng": -71.5476521 },
                { "lat": -32.9846853, "lng": -71.5476574 },
                { "lat": -32.9846358, "lng": -71.5476735 },
                { "lat": -32.9845886, "lng": -71.5477003 },
                { "lat": -32.9844978, "lng": -71.5477241 },
                { "lat": -32.9843793, "lng": -71.547762 },
                { "lat": -32.9843088, "lng": -71.5477992 },
                { "lat": -32.9842436, "lng": -71.5478421 },
                { "lat": -32.9841918, "lng": -71.5478743 },
                { "lat": -32.9841288, "lng": -71.5479065 },
                { "lat": -32.9840621, "lng": -71.5479766 },
                { "lat": -32.9840261, "lng": -71.5480276 },
                { "lat": -32.9839898, "lng": -71.5480764 },
                { "lat": -32.9839924, "lng": -71.5481483 },
                { "lat": -32.9839871, "lng": -71.5481935 },
                { "lat": -32.9839631, "lng": -71.5482421 },
                { "lat": -32.9839316, "lng": -71.5482824 },
                { "lat": -32.9839173, "lng": -71.5483249 },
                { "lat": -32.9838971, "lng": -71.5483785 },
                { "lat": -32.9838484, "lng": -71.5484245 },
                { "lat": -32.9838191, "lng": -71.5484513 },
                { "lat": -32.9837944, "lng": -71.5484916 },
                { "lat": -32.9837584, "lng": -71.5484782 },
                { "lat": -32.9837089, "lng": -71.5484809 },
                { "lat": -32.9837103, "lng": -71.5485475 },
                { "lat": -32.9837291, "lng": -71.5485935 },
                { "lat": -32.9837381, "lng": -71.548623 },
                { "lat": -32.9837201, "lng": -71.5486686 },
                { "lat": -32.9836864, "lng": -71.5487008 },
                { "lat": -32.9836639, "lng": -71.5487357 },
                { "lat": -32.9836076, "lng": -71.5487974 },
                { "lat": -32.9835708, "lng": -71.5488184 },
                { "lat": -32.9835379, "lng": -71.548859 },
                { "lat": -32.9835266, "lng": -71.5489073 },
                { "lat": -32.9834794, "lng": -71.5489368 },
                { "lat": -32.9834561, "lng": -71.5488962 },
                { "lat": -32.9834726, "lng": -71.5488403 },
                { "lat": -32.9834816, "lng": -71.5487866 },
                { "lat": -32.9834524, "lng": -71.5487625 },
                { "lat": -32.9834156, "lng": -71.5487809 },
                { "lat": -32.9833826, "lng": -71.5488242 },
                { "lat": -32.9833391, "lng": -71.5488506 },
                { "lat": -32.9832971, "lng": -71.5488859 },
                { "lat": -32.9832589, "lng": -71.5489315 },
                { "lat": -32.9832184, "lng": -71.5489824 },
                { "lat": -32.9831756, "lng": -71.5490227 },
                { "lat": -32.9831261, "lng": -71.5490146 },
                { "lat": -32.9831014, "lng": -71.548969 },
                { "lat": -32.9830857, "lng": -71.5489341 },
                { "lat": -32.9830542, "lng": -71.54891 },
                { "lat": -32.9830182, "lng": -71.5488832 },
                { "lat": -32.9830047, "lng": -71.5488483 },
                { "lat": -32.9829822, "lng": -71.5488134 },
                { "lat": -32.9829687, "lng": -71.5487759 },
                { "lat": -32.9829484, "lng": -71.5486954 },
                { "lat": -32.9829642, "lng": -71.5486391 },
                { "lat": -32.9829049, "lng": -71.5486923 },
                { "lat": -32.9828742, "lng": -71.5487115 },
                { "lat": -32.9828382, "lng": -71.5486901 },
                { "lat": -32.9828239, "lng": -71.5486682 },
                { "lat": -32.9828247, "lng": -71.5486337 },
                { "lat": -32.9828375, "lng": -71.5486012 },
                { "lat": -32.9828112, "lng": -71.5485774 },
                { "lat": -32.9827752, "lng": -71.548556 },
                { "lat": -32.9827594, "lng": -71.5485318 },
                { "lat": -32.9827429, "lng": -71.5484992 },
                { "lat": -32.9827181, "lng": -71.5484778 },
                { "lat": -32.9826844, "lng": -71.5484617 },
                { "lat": -32.982651, "lng": -71.5484308 },
                { "lat": -32.9826244, "lng": -71.5484084 },
                { "lat": -32.9826042, "lng": -71.5483736 },
                { "lat": -32.9825817, "lng": -71.5483387 },
                { "lat": -32.9825629, "lng": -71.5483115 },
                { "lat": -32.9825269, "lng": -71.5483302 },
                { "lat": -32.9825074, "lng": -71.5483736 },
                { "lat": -32.9824864, "lng": -71.5483812 },
                { "lat": -32.9824534, "lng": -71.5484004 },
                { "lat": -32.9824039, "lng": -71.548395 },
                { "lat": -32.9823904, "lng": -71.5484353 },
                { "lat": -32.9824054, "lng": -71.5484617 },
                { "lat": -32.9824039, "lng": -71.5484996 },
                { "lat": -32.9823941, "lng": -71.5485314 },
                { "lat": -32.9824399, "lng": -71.5485747 },
                { "lat": -32.9824639, "lng": -71.5486065 },
                { "lat": -32.9824827, "lng": -71.5486418 },
                { "lat": -32.9825314, "lng": -71.5486467 },
                { "lat": -32.9825696, "lng": -71.5486736 },
                { "lat": -32.9825704, "lng": -71.5487115 },
                { "lat": -32.9825367, "lng": -71.5487491 },
                { "lat": -32.9825074, "lng": -71.5487464 },
                { "lat": -32.9824714, "lng": -71.5487786 },
                { "lat": -32.9824909, "lng": -71.5488265 },
                { "lat": -32.9824886, "lng": -71.5488586 },
                { "lat": -32.9824549, "lng": -71.5488828 },
                { "lat": -32.9824129, "lng": -71.5488832 },
                { "lat": -32.9823724, "lng": -71.5488564 },
                { "lat": -32.9823356, "lng": -71.5488721 },
                { "lat": -32.9822914, "lng": -71.548902 },
                { "lat": -32.9822667, "lng": -71.5488966 },
                { "lat": -32.9822974, "lng": -71.5489659 },
                { "lat": -32.9822892, "lng": -71.5490146 },
                { "lat": -32.9822937, "lng": -71.5490548 },
                { "lat": -32.9822794, "lng": -71.5490974 },
                { "lat": -32.9822299, "lng": -71.5491295 },
                { "lat": -32.9821879, "lng": -71.5491192 },
                { "lat": -32.9821399, "lng": -71.5491295 },
                { "lat": -32.9821272, "lng": -71.5490709 },
                { "lat": -32.9821384, "lng": -71.5490334 },
                { "lat": -32.9821264, "lng": -71.5489981 },
                { "lat": -32.9820979, "lng": -71.5489583 },
                { "lat": -32.9820904, "lng": -71.548923 },
                { "lat": -32.9820372, "lng": -71.548902 },
                { "lat": -32.9820184, "lng": -71.5488747 },
                { "lat": -32.9820237, "lng": -71.5488456 },
                { "lat": -32.9820349, "lng": -71.5488081 },
                { "lat": -32.9820349, "lng": -71.5487866 },
                { "lat": -32.9820034, "lng": -71.5487625 },
                { "lat": -32.981981, "lng": -71.5487705 },
                { "lat": -32.9819441, "lng": -71.5487916 },
                { "lat": -32.9819351, "lng": -71.5488479 },
                { "lat": -32.9819134, "lng": -71.5488966 },
                { "lat": -32.9818774, "lng": -71.5489315 },
                { "lat": -32.9818549, "lng": -71.5489583 },
                { "lat": -32.9818077, "lng": -71.5489583 },
                { "lat": -32.9817919, "lng": -71.5490066 },
                { "lat": -32.9817979, "lng": -71.5490625 },
                { "lat": -32.9818167, "lng": -71.5490844 },
                { "lat": -32.9818234, "lng": -71.5491219 },
                { "lat": -32.9817964, "lng": -71.5491595 },
                { "lat": -32.9817551, "lng": -71.5491912 },
                { "lat": -32.9817282, "lng": -71.5492288 },
                { "lat": -32.9817222, "lng": -71.5492721 },
                { "lat": -32.9816727, "lng": -71.5492989 },
                { "lat": -32.9816187, "lng": -71.5492989 },
                { "lat": -32.9816029, "lng": -71.5492506 },
                { "lat": -32.9815872, "lng": -71.5491568 },
                { "lat": -32.9815752, "lng": -71.5491376 },
                { "lat": -32.9815594, "lng": -71.5491054 },
                { "lat": -32.9815257, "lng": -71.5490759 },
                { "lat": -32.9814972, "lng": -71.5490495 },
                { "lat": -32.9814837, "lng": -71.5490253 },
                { "lat": -32.9814739, "lng": -71.548982 },
                { "lat": -32.9814769, "lng": -71.5489341 },
                { "lat": -32.9814859, "lng": -71.5488939 },
                { "lat": -32.9814994, "lng": -71.5488698 },
                { "lat": -32.9815242, "lng": -71.5488403 },
                { "lat": -32.9815242, "lng": -71.5488188 },
                { "lat": -32.9814964, "lng": -71.5487728 },
                { "lat": -32.9814747, "lng": -71.5487571 },
                { "lat": -32.9814267, "lng": -71.5487648 },
                { "lat": -32.9813959, "lng": -71.5487598 },
                { "lat": -32.9813869, "lng": -71.5487115 },
                { "lat": -32.9813959, "lng": -71.5486847 },
                { "lat": -32.9813577, "lng": -71.5486311 },
                { "lat": -32.9813622, "lng": -71.5485935 },
                { "lat": -32.9814064, "lng": -71.5485797 },
                { "lat": -32.9814298, "lng": -71.548564 },
                { "lat": -32.9814319, "lng": -71.5485184 },
                { "lat": -32.9813974, "lng": -71.5484751 },
                { "lat": -32.9813712, "lng": -71.5484648 },
                { "lat": -32.9813284, "lng": -71.548454 },
                { "lat": -32.9812924, "lng": -71.5484379 },
                { "lat": -32.9812534, "lng": -71.5484027 },
                { "lat": -32.9812384, "lng": -71.5483628 },
                { "lat": -32.9811904, "lng": -71.5483249 },
                { "lat": -32.9811372, "lng": -71.5483253 },
                { "lat": -32.9811012, "lng": -71.5483011 },
                { "lat": -32.9810967, "lng": -71.5482636 },
                { "lat": -32.9810764, "lng": -71.5482421 },
                { "lat": -32.9810419, "lng": -71.5482176 },
                { "lat": -32.9810134, "lng": -71.5482019 },
                { "lat": -32.9809594, "lng": -71.5481983 },
                { "lat": -32.9809235, "lng": -71.5481965 },
                { "lat": -32.980901, "lng": -71.5481617 },
                { "lat": -32.9808844, "lng": -71.5480969 },
                { "lat": -32.9809002, "lng": -71.5480513 },
                { "lat": -32.9808942, "lng": -71.5479686 },
                { "lat": -32.9808515, "lng": -71.5479337 },
                { "lat": -32.9808184, "lng": -71.5479054 },
                { "lat": -32.9807975, "lng": -71.5478264 },
                { "lat": -32.9807682, "lng": -71.5477862 },
                { "lat": -32.980721, "lng": -71.5477701 },
                { "lat": -32.9806625, "lng": -71.5477593 },
                { "lat": -32.9806062, "lng": -71.5477406 },
                { "lat": -32.980568, "lng": -71.5477137 },
                { "lat": -32.9805342, "lng": -71.5476762 },
                { "lat": -32.9804983, "lng": -71.5476601 },
                { "lat": -32.980451, "lng": -71.5476521 },
                { "lat": -32.980343, "lng": -71.5475099 },
                { "lat": -32.980307, "lng": -71.547475 },
                { "lat": -32.9802687, "lng": -71.5474509 },
                { "lat": -32.9802462, "lng": -71.5473597 },
                { "lat": -32.980208, "lng": -71.5472792 },
                { "lat": -32.9801765, "lng": -71.547239 },
                { "lat": -32.9801225, "lng": -71.5472309 },
                { "lat": -32.9800865, "lng": -71.5472819 },
                { "lat": -32.9800707, "lng": -71.5473114 },
                { "lat": -32.9800145, "lng": -71.5472926 },
                { "lat": -32.9799844, "lng": -71.5472466 },
                { "lat": -32.9799417, "lng": -71.5472144 },
                { "lat": -32.979893, "lng": -71.5472014 },
                { "lat": -32.9798525, "lng": -71.5471853 },
                { "lat": -32.979812, "lng": -71.5471988 },
                { "lat": -32.9797842, "lng": -71.5472466 },
                { "lat": -32.9797535, "lng": -71.5472846 },
                { "lat": -32.9797234, "lng": -71.5472869 },
                { "lat": -32.9796897, "lng": -71.5472627 },
                { "lat": -32.9796469, "lng": -71.5472359 },
                { "lat": -32.9796005, "lng": -71.5472336 },
                { "lat": -32.9795555, "lng": -71.5471827 },
                { "lat": -32.9794947, "lng": -71.5471693 },
                { "lat": -32.979488, "lng": -71.5471156 },
                { "lat": -32.9794812, "lng": -71.5470754 },
                { "lat": -32.9795262, "lng": -71.5470915 },
                { "lat": -32.9795839, "lng": -71.547083 },
                { "lat": -32.9796005, "lng": -71.5470432 },
                { "lat": -32.9795982, "lng": -71.5470056 },
                { "lat": -32.9795487, "lng": -71.5469493 },
                { "lat": -32.979515, "lng": -71.5469413 },
                { "lat": -32.9794362, "lng": -71.5469252 },
                { "lat": -32.9794047, "lng": -71.5469171 },
                { "lat": -32.9793507, "lng": -71.5469064 },
                { "lat": -32.9793035, "lng": -71.5469225 },
                { "lat": -32.9792585, "lng": -71.5468849 },
                { "lat": -32.9792329, "lng": -71.5468497 },
                { "lat": -32.979209, "lng": -71.5468072 },
                { "lat": -32.9791744, "lng": -71.5467638 },
                { "lat": -32.9791497, "lng": -71.546729 },
                { "lat": -32.9791339, "lng": -71.5466968 },
                { "lat": -32.979119, "lng": -71.5466704 },
                { "lat": -32.9790785, "lng": -71.5466274 },
                { "lat": -32.9790402, "lng": -71.5466194 },
                { "lat": -32.9790042, "lng": -71.5466462 },
                { "lat": -32.9790132, "lng": -71.5466865 },
                { "lat": -32.9789517, "lng": -71.5467129 },
                { "lat": -32.9789052, "lng": -71.5467562 },
                { "lat": -32.9788534, "lng": -71.5467642 },
                { "lat": -32.9788309, "lng": -71.5467508 },
                { "lat": -32.9787634, "lng": -71.5467213 },
                { "lat": -32.9787229, "lng": -71.5466543 },
                { "lat": -32.9786959, "lng": -71.5466274 },
                { "lat": -32.9786749, "lng": -71.5465868 },
                { "lat": -32.9786982, "lng": -71.5465255 },
                { "lat": -32.9787365, "lng": -71.5464994 },
                { "lat": -32.9787387, "lng": -71.5464424 },
                { "lat": -32.9787364, "lng": -71.5464156 },
                { "lat": -32.9787094, "lng": -71.5463485 },
                { "lat": -32.9787184, "lng": -71.5462788 },
                { "lat": -32.9787207, "lng": -71.5462251 },
                { "lat": -32.9787072, "lng": -71.5461768 },
                { "lat": -32.9786569, "lng": -71.5461442 },
                { "lat": -32.9786029, "lng": -71.5460933 },
                { "lat": -32.9785564, "lng": -71.5460883 },
                { "lat": -32.9785384, "lng": -71.5460535 },
                { "lat": -32.9784979, "lng": -71.5460132 },
                { "lat": -32.9784417, "lng": -71.5459435 },
                { "lat": -32.9783944, "lng": -71.5459113 },
                { "lat": -32.9783337, "lng": -71.5459033 },
                { "lat": -32.9782947, "lng": -71.5459109 },
                { "lat": -32.9782279, "lng": -71.5459033 },
                { "lat": -32.9781942, "lng": -71.5458657 },
                { "lat": -32.9781559, "lng": -71.5458389 },
                { "lat": -32.9780817, "lng": -71.5458362 },
                { "lat": -32.9780569, "lng": -71.5458416 },
                { "lat": -32.9780021, "lng": -71.5458626 },
                { "lat": -32.9779661, "lng": -71.5458841 },
                { "lat": -32.9779377, "lng": -71.5459274 },
                { "lat": -32.9778769, "lng": -71.5459193 },
                { "lat": -32.9778522, "lng": -71.5458872 },
                { "lat": -32.9778004, "lng": -71.545863 },
                { "lat": -32.9777622, "lng": -71.5458496 },
                { "lat": -32.9777164, "lng": -71.5458653 },
                { "lat": -32.9776961, "lng": -71.5459297 },
                { "lat": -32.9777186, "lng": -71.5459753 },
                { "lat": -32.9777231, "lng": -71.5460289 },
                { "lat": -32.9777231, "lng": -71.546104 },
                { "lat": -32.9776834, "lng": -71.5460991 },
                { "lat": -32.9776497, "lng": -71.5460642 },
                { "lat": -32.9776309, "lng": -71.5460128 },
                { "lat": -32.9775934, "lng": -71.5459408 },
                { "lat": -32.9775507, "lng": -71.5459086 },
                { "lat": -32.9775012, "lng": -71.5458898 },
                { "lat": -32.9774067, "lng": -71.5458684 },
                { "lat": -32.9773369, "lng": -71.545863 },
                { "lat": -32.9772807, "lng": -71.5458925 },
                { "lat": -32.9772154, "lng": -71.5459247 },
                { "lat": -32.9771974, "lng": -71.5459596 },
                { "lat": -32.9771944, "lng": -71.5460021 },
                { "lat": -32.9772304, "lng": -71.5460155 },
                { "lat": -32.9772641, "lng": -71.5460665 },
                { "lat": -32.9772641, "lng": -71.5461013 },
                { "lat": -32.9772379, "lng": -71.5461554 },
                { "lat": -32.9772146, "lng": -71.5461952 },
                { "lat": -32.9772101, "lng": -71.5462381 },
                { "lat": -32.9771614, "lng": -71.546268 },
                { "lat": -32.9771862, "lng": -71.5463431 },
                { "lat": -32.9771164, "lng": -71.5463458 },
                { "lat": -32.9770805, "lng": -71.5463431 },
                { "lat": -32.9770647, "lng": -71.5462949 },
                { "lat": -32.9770624, "lng": -71.5462439 },
                { "lat": -32.9770039, "lng": -71.5462117 },
                { "lat": -32.9769567, "lng": -71.5461983 },
                { "lat": -32.9769229, "lng": -71.5461393 },
                { "lat": -32.9768141, "lng": -71.5461684 },
                { "lat": -32.9767361, "lng": -71.5461527 },
                { "lat": -32.9766904, "lng": -71.5461657 },
                { "lat": -32.9766641, "lng": -71.5462063 },
                { "lat": -32.9766281, "lng": -71.5462305 },
                { "lat": -32.9765674, "lng": -71.5462224 },
                { "lat": -32.9765269, "lng": -71.5462037 },
                { "lat": -32.9764819, "lng": -71.5461902 },
                { "lat": -32.9764459, "lng": -71.5461929 },
                { "lat": -32.9763806, "lng": -71.5462224 },
                { "lat": -32.9763289, "lng": -71.5462117 },
                { "lat": -32.9763078, "lng": -71.5461979 },
                { "lat": -32.9762659, "lng": -71.5461956 },
                { "lat": -32.9762186, "lng": -71.5461876 },
                { "lat": -32.9761616, "lng": -71.5461979 },
                { "lat": -32.9761804, "lng": -71.5462144 },
                { "lat": -32.9762051, "lng": -71.5462251 },
                { "lat": -32.9762231, "lng": -71.5462707 },
                { "lat": -32.9762074, "lng": -71.5463297 },
                { "lat": -32.9761781, "lng": -71.5463565 },
                { "lat": -32.9761016, "lng": -71.5463565 },
                { "lat": -32.9760558, "lng": -71.5463803 },
                { "lat": -32.9760176, "lng": -71.5464017 },
                { "lat": -32.9759734, "lng": -71.5463914 },
                { "lat": -32.9759576, "lng": -71.5463646 },
                { "lat": -32.9759261, "lng": -71.5463619 },
                { "lat": -32.9758848, "lng": -71.5463937 },
                { "lat": -32.9758474, "lng": -71.5464316 },
                { "lat": -32.9758308, "lng": -71.5464634 },
                { "lat": -32.9757484, "lng": -71.546496 },
                { "lat": -32.9757169, "lng": -71.5465067 },
                { "lat": -32.9756651, "lng": -71.5465067 },
                { "lat": -32.9756223, "lng": -71.5464987 },
                { "lat": -32.9755796, "lng": -71.5465014 },
                { "lat": -32.9755301, "lng": -71.5465765 },
                { "lat": -32.9754896, "lng": -71.5465631 },
                { "lat": -32.9754401, "lng": -71.5465148 },
                { "lat": -32.975379, "lng": -71.5465057 },
                { "lat": -32.9753141, "lng": -71.5464879 },
                { "lat": -32.9752758, "lng": -71.5464584 },
                { "lat": -32.975239, "lng": -71.546407 },
                { "lat": -32.9751993, "lng": -71.5463806 },
                { "lat": -32.975176, "lng": -71.546356 },
                { "lat": -32.9751701, "lng": -71.5463189 },
                { "lat": -32.9751588, "lng": -71.5462787 },
                { "lat": -32.97514, "lng": -71.5462514 },
                { "lat": -32.9751296, "lng": -71.5462357 },
                { "lat": -32.9751175, "lng": -71.5461924 },
                { "lat": -32.9751093, "lng": -71.546276 },
                { "lat": -32.975122, "lng": -71.5463319 },
                { "lat": -32.9751228, "lng": -71.5463833 },
                { "lat": -32.9751048, "lng": -71.5464289 },
                { "lat": -32.9750958, "lng": -71.5464718 },
                { "lat": -32.9750868, "lng": -71.5465093 },
                { "lat": -32.9750103, "lng": -71.5465362 },
                { "lat": -32.9749811, "lng": -71.5465147 },
                { "lat": -32.9749608, "lng": -71.5465093 },
                { "lat": -32.9749203, "lng": -71.5464959 },
                { "lat": -32.9748933, "lng": -71.5464718 },
                { "lat": -32.9748768, "lng": -71.5464311 },
                { "lat": -32.9748776, "lng": -71.5463833 },
                { "lat": -32.9748708, "lng": -71.5463404 },
                { "lat": -32.9748588, "lng": -71.546297 },
                { "lat": -32.9748408, "lng": -71.5462675 },
                { "lat": -32.974825, "lng": -71.5462219 },
                { "lat": -32.9747853, "lng": -71.5462036 },
                { "lat": -32.9747561, "lng": -71.5462036 },
                { "lat": -32.974708, "lng": -71.546238 },
                { "lat": -32.974702, "lng": -71.5462787 },
                { "lat": -32.97469, "lng": -71.5463212 },
                { "lat": -32.974663, "lng": -71.5463426 },
                { "lat": -32.97463, "lng": -71.5463511 },
                { "lat": -32.9745963, "lng": -71.5463377 },
                { "lat": -32.9745895, "lng": -71.5463108 },
                { "lat": -32.9745445, "lng": -71.5463189 },
                { "lat": -32.9744942, "lng": -71.5462809 },
                { "lat": -32.9744725, "lng": -71.5462357 },
                { "lat": -32.9744515, "lng": -71.5461951 },
                { "lat": -32.9744312, "lng": -71.5461656 },
                { "lat": -32.9743952, "lng": -71.5461441 },
                { "lat": -32.9743533, "lng": -71.5461392 },
                { "lat": -32.9743105, "lng": -71.5461311 },
                { "lat": -32.974279, "lng": -71.546115 },
                { "lat": -32.974243, "lng": -71.5460909 },
                { "lat": -32.974225, "lng": -71.546056 },
                { "lat": -32.9741905, "lng": -71.5460288 },
                { "lat": -32.9741725, "lng": -71.5459993 },
                { "lat": -32.9741635, "lng": -71.5459537 },
                { "lat": -32.9741373, "lng": -71.545938 },
                { "lat": -32.9740945, "lng": -71.5459032 },
                { "lat": -32.9740285, "lng": -71.545825 },
                { "lat": -32.9740056, "lng": -71.5457885 },
                { "lat": -32.9738981, "lng": -71.5457386 },
                { "lat": -32.973294, "lng": -71.5456169 },
                { "lat": -32.9727397, "lng": -71.5455912 },
                { "lat": -32.967946, "lng": -71.546225 },
                { "lat": -32.9627274, "lng": -71.5475747 },
                { "lat": -32.9626892, "lng": -71.5475939 },
                { "lat": -32.9626553, "lng": -71.5476152 },
                { "lat": -32.9626201, "lng": -71.5476493 },
                { "lat": -32.9626036, "lng": -71.5477225 },
                { "lat": -32.9626202, "lng": -71.5477674 },
                { "lat": -32.9626381, "lng": -71.5478129 },
                { "lat": -32.9626373, "lng": -71.5478539 },
                { "lat": -32.9626201, "lng": -71.5478666 },
                { "lat": -32.9625841, "lng": -71.5478382 },
                { "lat": -32.9625503, "lng": -71.5478531 },
                { "lat": -32.9625076, "lng": -71.5478344 },
                { "lat": -32.9624828, "lng": -71.5478719 },
                { "lat": -32.9624828, "lng": -71.5479148 },
                { "lat": -32.9624558, "lng": -71.5479417 },
                { "lat": -32.9624145, "lng": -71.5479612 },
                { "lat": -32.9623861, "lng": -71.5479524 },
                { "lat": -32.9623771, "lng": -71.5479336 },
                { "lat": -32.9623433, "lng": -71.5479229 },
                { "lat": -32.9623096, "lng": -71.5478987 },
                { "lat": -32.962269, "lng": -71.5478558 },
                { "lat": -32.962242, "lng": -71.5478424 },
                { "lat": -32.9622105, "lng": -71.5478022 },
                { "lat": -32.9621805, "lng": -71.5477788 },
                { "lat": -32.9621512, "lng": -71.5477788 },
                { "lat": -32.962125, "lng": -71.5477914 },
                { "lat": -32.9620665, "lng": -71.5478049 },
                { "lat": -32.9620237, "lng": -71.5477673 },
                { "lat": -32.9620057, "lng": -71.54777 },
                { "lat": -32.9619742, "lng": -71.5477432 },
                { "lat": -32.9619382, "lng": -71.5477324 },
                { "lat": -32.9618978, "lng": -71.5477218 },
                { "lat": -32.9618753, "lng": -71.5476869 },
                { "lat": -32.9618236, "lng": -71.5476628 },
                { "lat": -32.9617733, "lng": -71.5476636 },
                { "lat": -32.9617372, "lng": -71.5476851 },
                { "lat": -32.9616832, "lng": -71.5476663 },
                { "lat": -32.9616472, "lng": -71.5476877 },
                { "lat": -32.9615738, "lng": -71.5476977 },
                { "lat": -32.96154, "lng": -71.5477057 },
                { "lat": -32.9614972, "lng": -71.5477191 },
                { "lat": -32.9614514, "lng": -71.5477065 },
                { "lat": -32.961414, "lng": -71.5477057 },
                { "lat": -32.9613735, "lng": -71.5477272 },
                { "lat": -32.9612947, "lng": -71.5477433 },
                { "lat": -32.9612182, "lng": -71.5477567 },
                { "lat": -32.9611912, "lng": -71.547746 },
                { "lat": -32.9611507, "lng": -71.5476977 },
                { "lat": -32.9611147, "lng": -71.5477004 },
                { "lat": -32.9610786, "lng": -71.5477272 },
                { "lat": -32.9610359, "lng": -71.5477352 },
                { "lat": -32.9609909, "lng": -71.5477674 },
                { "lat": -32.9609751, "lng": -71.5477862 },
                { "lat": -32.9609391, "lng": -71.5477889 },
                { "lat": -32.9609046, "lng": -71.547787 },
                { "lat": -32.9608603, "lng": -71.5478076 },
                { "lat": -32.9608176, "lng": -71.5478506 },
                { "lat": -32.9607838, "lng": -71.5478559 },
                { "lat": -32.9607523, "lng": -71.5478854 },
                { "lat": -32.960738, "lng": -71.5479104 },
                { "lat": -32.960702, "lng": -71.5479265 },
                { "lat": -32.9606466, "lng": -71.547923 },
                { "lat": -32.9606053, "lng": -71.5479372 },
                { "lat": -32.9605017, "lng": -71.5479774 },
                { "lat": -32.96045, "lng": -71.5480096 },
                { "lat": -32.9603945, "lng": -71.5480303 },
                { "lat": -32.9603652, "lng": -71.5480571 },
                { "lat": -32.9603374, "lng": -71.5481008 },
                { "lat": -32.9603, "lng": -71.5481134 },
                { "lat": -32.9602587, "lng": -71.5481303 },
                { "lat": -32.9602249, "lng": -71.548184 },
                { "lat": -32.9601889, "lng": -71.5482483 },
                { "lat": -32.9601942, "lng": -71.5483146 },
                { "lat": -32.9602325, "lng": -71.5483334 },
                { "lat": -32.9603157, "lng": -71.548269 },
                { "lat": -32.9603337, "lng": -71.5482663 },
                { "lat": -32.9603967, "lng": -71.5482583 },
                { "lat": -32.9604238, "lng": -71.5482663 },
                { "lat": -32.9604463, "lng": -71.5482824 },
                { "lat": -32.9604747, "lng": -71.5483395 },
                { "lat": -32.9604688, "lng": -71.5483763 },
                { "lat": -32.960432, "lng": -71.5484039 },
                { "lat": -32.9603855, "lng": -71.5484755 },
                { "lat": -32.9603517, "lng": -71.5485131 },
                { "lat": -32.960363, "lng": -71.5485533 },
                { "lat": -32.9603529, "lng": -71.5485801 },
                { "lat": -32.9603352, "lng": -71.5486104 },
                { "lat": -32.960309, "lng": -71.5486311 },
                { "lat": -32.9602811, "lng": -71.5486668 },
                { "lat": -32.960264, "lng": -71.5486901 },
                { "lat": -32.9602347, "lng": -71.5487115 },
                { "lat": -32.9602032, "lng": -71.548725 },
                { "lat": -32.9601829, "lng": -71.5487598 },
                { "lat": -32.9601462, "lng": -71.5488062 },
                { "lat": -32.9601064, "lng": -71.5488027 },
                { "lat": -32.9600614, "lng": -71.5487974 },
                { "lat": -32.9600389, "lng": -71.5488135 },
                { "lat": -32.9600201, "lng": -71.5488465 },
                { "lat": -32.9599714, "lng": -71.5488537 },
                { "lat": -32.9599353, "lng": -71.5488591 },
                { "lat": -32.9598873, "lng": -71.5489108 },
                { "lat": -32.9598476, "lng": -71.5489637 },
                { "lat": -32.9598094, "lng": -71.548961 },
                { "lat": -32.9597869, "lng": -71.5489744 },
                { "lat": -32.9597816, "lng": -71.5490196 },
                { "lat": -32.9598064, "lng": -71.5490437 },
                { "lat": -32.9598432, "lng": -71.5490629 },
                { "lat": -32.9598423, "lng": -71.549104 },
                { "lat": -32.9598094, "lng": -71.5491273 },
                { "lat": -32.9597846, "lng": -71.5491166 },
                { "lat": -32.9597546, "lng": -71.5490905 },
                { "lat": -32.9597283, "lng": -71.5491058 },
                { "lat": -32.9596968, "lng": -71.5490871 },
                { "lat": -32.9596645, "lng": -71.5490637 },
                { "lat": -32.9596263, "lng": -71.549061 },
                { "lat": -32.9595875, "lng": -71.5490652 },
                { "lat": -32.959579, "lng": -71.5491066 },
                { "lat": -32.959543, "lng": -71.5491576 },
                { "lat": -32.9594718, "lng": -71.549189 },
                { "lat": -32.9594695, "lng": -71.5492185 },
                { "lat": -32.959447, "lng": -71.5492855 },
                { "lat": -32.9594335, "lng": -71.5493445 },
                { "lat": -32.9593908, "lng": -71.5493901 },
                { "lat": -32.9593495, "lng": -71.5494124 },
                { "lat": -32.95933, "lng": -71.5494679 },
                { "lat": -32.9592977, "lng": -71.5494741 },
                { "lat": -32.9592535, "lng": -71.549484 },
                { "lat": -32.9592175, "lng": -71.5494733 },
                { "lat": -32.9592085, "lng": -71.5494277 },
                { "lat": -32.9591739, "lng": -71.5494044 },
                { "lat": -32.9591627, "lng": -71.5494258 },
                { "lat": -32.9591342, "lng": -71.5494599 },
                { "lat": -32.9591117, "lng": -71.5494277 },
                { "lat": -32.9590869, "lng": -71.549425 },
                { "lat": -32.9590524, "lng": -71.5494661 },
                { "lat": -32.9590217, "lng": -71.5494894 },
                { "lat": -32.9589812, "lng": -71.5495082 },
                { "lat": -32.9589421, "lng": -71.5495331 },
                { "lat": -32.9589264, "lng": -71.5495653 },
                { "lat": -32.9589174, "lng": -71.5496189 },
                { "lat": -32.9588791, "lng": -71.5496511 },
                { "lat": -32.9588304, "lng": -71.5496557 },
                { "lat": -32.9588273, "lng": -71.5497075 },
                { "lat": -32.9588596, "lng": -71.5497335 },
                { "lat": -32.9588889, "lng": -71.5497442 },
                { "lat": -32.9589174, "lng": -71.5497665 },
                { "lat": -32.9589196, "lng": -71.5497933 },
                { "lat": -32.9589151, "lng": -71.5498282 },
                { "lat": -32.9589137, "lng": -71.5498756 },
                { "lat": -32.9589294, "lng": -71.5499078 },
                { "lat": -32.9589804, "lng": -71.5499193 },
                { "lat": -32.9589961, "lng": -71.5499354 },
                { "lat": -32.9590217, "lng": -71.5499695 },
                { "lat": -32.9590509, "lng": -71.5499722 },
                { "lat": -32.9590862, "lng": -71.5499408 },
                { "lat": -32.9590622, "lng": -71.5499024 },
                { "lat": -32.9590464, "lng": -71.5498354 },
                { "lat": -32.9590749, "lng": -71.5497986 },
                { "lat": -32.959105, "lng": -71.5497952 },
                { "lat": -32.9591432, "lng": -71.5497925 },
                { "lat": -32.9591807, "lng": -71.5497665 },
                { "lat": -32.9592287, "lng": -71.5496959 },
                { "lat": -32.959309, "lng": -71.5496994 },
                { "lat": -32.9593255, "lng": -71.5497281 },
                { "lat": -32.95933, "lng": -71.5497925 },
                { "lat": -32.9593278, "lng": -71.5498461 },
                { "lat": -32.9593278, "lng": -71.549889 },
                { "lat": -32.959327, "lng": -71.5499408 },
                { "lat": -32.9593, "lng": -71.5499757 },
                { "lat": -32.9592737, "lng": -71.549991 },
                { "lat": -32.9592459, "lng": -71.5500186 },
                { "lat": -32.9591905, "lng": -71.5500419 },
                { "lat": -32.9591559, "lng": -71.550091 },
                { "lat": -32.9591477, "lng": -71.5501224 },
                { "lat": -32.9591154, "lng": -71.55015 },
                { "lat": -32.9590704, "lng": -71.5501607 },
                { "lat": -32.9590321, "lng": -71.5501742 },
                { "lat": -32.9589812, "lng": -71.5501465 },
                { "lat": -32.9589677, "lng": -71.5500768 },
                { "lat": -32.9589002, "lng": -71.5500473 },
                { "lat": -32.9588641, "lng": -71.55005 },
                { "lat": -32.9588394, "lng": -71.5500714 },
                { "lat": -32.9587944, "lng": -71.5501117 },
                { "lat": -32.9587404, "lng": -71.5500553 },
                { "lat": -32.9587058, "lng": -71.5500615 },
                { "lat": -32.9586886, "lng": -71.5501063 },
                { "lat": -32.9586976, "lng": -71.5501331 },
                { "lat": -32.9587359, "lng": -71.5501573 },
                { "lat": -32.9587584, "lng": -71.5502029 },
                { "lat": -32.9587463, "lng": -71.5502439 },
                { "lat": -32.9587179, "lng": -71.5502887 },
                { "lat": -32.9586833, "lng": -71.5503217 },
                { "lat": -32.9586518, "lng": -71.5503512 },
                { "lat": -32.9586391, "lng": -71.5503799 },
                { "lat": -32.9586225, "lng": -71.5504719 },
                { "lat": -32.9586113, "lng": -71.5504933 },
                { "lat": -32.9586045, "lng": -71.550547 },
                { "lat": -32.958582, "lng": -71.5505872 },
                { "lat": -32.9585527, "lng": -71.5505899 },
                { "lat": -32.95851, "lng": -71.5505631 },
                { "lat": -32.9584748, "lng": -71.5505703 },
                { "lat": -32.9584492, "lng": -71.5505658 },
                { "lat": -32.9584229, "lng": -71.5505059 },
                { "lat": -32.9584207, "lng": -71.5504577 },
                { "lat": -32.9584499, "lng": -71.5503799 },
                { "lat": -32.9584201, "lng": -71.5503453 },
                { "lat": -32.9583771, "lng": -71.5503109 },
                { "lat": -32.9583434, "lng": -71.5503244 },
                { "lat": -32.9582984, "lng": -71.550327 },
                { "lat": -32.9582069, "lng": -71.5502994 },
                { "lat": -32.9581723, "lng": -71.5503378 },
                { "lat": -32.9581191, "lng": -71.5503665 },
                { "lat": -32.9580854, "lng": -71.5503692 },
                { "lat": -32.9580493, "lng": -71.5503423 },
                { "lat": -32.9580013, "lng": -71.5503244 },
                { "lat": -32.957963, "lng": -71.5503244 },
                { "lat": -32.9579248, "lng": -71.5503244 },
                { "lat": -32.9579031, "lng": -71.5503262 },
                { "lat": -32.9578783, "lng": -71.5502994 },
                { "lat": -32.9578693, "lng": -71.550235 },
                { "lat": -32.9578334, "lng": -71.5501921 },
                { "lat": -32.9577929, "lng": -71.550176 },
                { "lat": -32.9577681, "lng": -71.550176 },
                { "lat": -32.9577366, "lng": -71.5501868 },
                { "lat": -32.9577043, "lng": -71.5502278 },
                { "lat": -32.9576886, "lng": -71.5502546 },
                { "lat": -32.9576781, "lng": -71.5502753 },
                { "lat": -32.9576511, "lng": -71.5503182 },
                { "lat": -32.9576241, "lng": -71.550345 },
                { "lat": -32.9576083, "lng": -71.5503933 },
                { "lat": -32.9576331, "lng": -71.5504416 },
                { "lat": -32.9576398, "lng": -71.5504845 },
                { "lat": -32.9576481, "lng": -71.5505121 },
                { "lat": -32.9576601, "lng": -71.5505569 },
                { "lat": -32.9576511, "lng": -71.5505971 },
                { "lat": -32.9576218, "lng": -71.5506642 },
                { "lat": -32.9576443, "lng": -71.5507017 },
                { "lat": -32.9576984, "lng": -71.5507527 },
                { "lat": -32.9577254, "lng": -71.5508037 },
                { "lat": -32.9576751, "lng": -71.5508474 },
                { "lat": -32.9576804, "lng": -71.5508949 },
                { "lat": -32.9577209, "lng": -71.5509297 },
                { "lat": -32.9577614, "lng": -71.5509431 },
                { "lat": -32.9578019, "lng": -71.5509431 },
                { "lat": -32.9578394, "lng": -71.5509627 },
                { "lat": -32.9578686, "lng": -71.5509949 },
                { "lat": -32.9578717, "lng": -71.5510209 },
                { "lat": -32.9578987, "lng": -71.5511094 },
                { "lat": -32.9579189, "lng": -71.5511255 },
                { "lat": -32.9579504, "lng": -71.5512167 },
                { "lat": -32.9579609, "lng": -71.5513007 },
                { "lat": -32.9579541, "lng": -71.551357 },
                { "lat": -32.9579572, "lng": -71.5513911 },
                { "lat": -32.9579549, "lng": -71.551442 },
                { "lat": -32.9579361, "lng": -71.551475 },
                { "lat": -32.9579257, "lng": -71.5515386 },
                { "lat": -32.9579414, "lng": -71.5515601 },
                { "lat": -32.9579752, "lng": -71.5515681 },
                { "lat": -32.9580022, "lng": -71.5515896 },
                { "lat": -32.9580022, "lng": -71.5516298 },
                { "lat": -32.9579946, "lng": -71.5516789 },
                { "lat": -32.9579707, "lng": -71.5517398 },
                { "lat": -32.9579414, "lng": -71.5517612 },
                { "lat": -32.9579279, "lng": -71.5517129 },
                { "lat": -32.9579009, "lng": -71.5517022 },
                { "lat": -32.9578754, "lng": -71.5517352 },
                { "lat": -32.9578469, "lng": -71.5517317 },
                { "lat": -32.9578191, "lng": -71.5517218 },
                { "lat": -32.9577831, "lng": -71.5517352 },
                { "lat": -32.9577696, "lng": -71.5517674 },
                { "lat": -32.9577561, "lng": -71.5518076 },
                { "lat": -32.9577201, "lng": -71.5518237 },
                { "lat": -32.9576998, "lng": -71.5518532 },
                { "lat": -32.9576661, "lng": -71.5518747 },
                { "lat": -32.9576016, "lng": -71.5518792 },
                { "lat": -32.9575678, "lng": -71.5518819 },
                { "lat": -32.9575265, "lng": -71.5518774 },
                { "lat": -32.9575085, "lng": -71.5518505 },
                { "lat": -32.9574815, "lng": -71.5518371 },
                { "lat": -32.9574485, "lng": -71.5518605 },
                { "lat": -32.9574305, "lng": -71.5518497 },
                { "lat": -32.9573945, "lng": -71.551839 },
                { "lat": -32.957354, "lng": -71.5518256 },
                { "lat": -32.9573338, "lng": -71.5517961 },
                { "lat": -32.9573113, "lng": -71.5517719 },
                { "lat": -32.9572888, "lng": -71.5517424 },
                { "lat": -32.9572933, "lng": -71.5517129 },
                { "lat": -32.9573023, "lng": -71.5516727 },
                { "lat": -32.9572992, "lng": -71.5516413 },
                { "lat": -32.9572685, "lng": -71.551611 },
                { "lat": -32.9572595, "lng": -71.5515681 },
                { "lat": -32.9572527, "lng": -71.5515305 },
                { "lat": -32.9572707, "lng": -71.5514823 },
                { "lat": -32.9572933, "lng": -71.551434 },
                { "lat": -32.9573068, "lng": -71.5514045 },
                { "lat": -32.9573045, "lng": -71.5513428 },
                { "lat": -32.957274, "lng": -71.551314 },
                { "lat": -32.9572632, "lng": -71.5512631 },
                { "lat": -32.9572347, "lng": -71.5512301 },
                { "lat": -32.9571852, "lng": -71.5512194 },
                { "lat": -32.9571695, "lng": -71.5512087 },
                { "lat": -32.9571605, "lng": -71.5511792 },
                { "lat": -32.9571875, "lng": -71.551155 },
                { "lat": -32.9572257, "lng": -71.5511094 },
                { "lat": -32.9572325, "lng": -71.5510612 },
                { "lat": -32.9572325, "lng": -71.551029 },
                { "lat": -32.9572136, "lng": -71.5509975 },
                { "lat": -32.9571762, "lng": -71.5509941 },
                { "lat": -32.9571537, "lng": -71.5510182 },
                { "lat": -32.9571281, "lng": -71.5510485 },
                { "lat": -32.9571011, "lng": -71.5510538 },
                { "lat": -32.9570726, "lng": -71.551053 },
                { "lat": -32.9570502, "lng": -71.5510746 },
                { "lat": -32.9570231, "lng": -71.5511013 },
                { "lat": -32.9569803, "lng": -71.5511013 },
                { "lat": -32.9569466, "lng": -71.5510879 },
                { "lat": -32.9569197, "lng": -71.5510826 },
                { "lat": -32.9569038, "lng": -71.5510825 },
                { "lat": -32.9568745, "lng": -71.5510557 },
                { "lat": -32.9568723, "lng": -71.5510316 },
                { "lat": -32.956852, "lng": -71.550994 },
                { "lat": -32.9568116, "lng": -71.5509861 },
                { "lat": -32.9568001, "lng": -71.5509826 },
                { "lat": -32.9567846, "lng": -71.550978 },
                { "lat": -32.9567665, "lng": -71.5509645 },
                { "lat": -32.9567702, "lng": -71.5509278 },
                { "lat": -32.9567792, "lng": -71.5509036 },
                { "lat": -32.956789, "lng": -71.550876 },
                { "lat": -32.9567845, "lng": -71.5508438 },
                { "lat": -32.9567598, "lng": -71.5508197 },
                { "lat": -32.956726, "lng": -71.5508304 },
                { "lat": -32.9566892, "lng": -71.5508124 },
                { "lat": -32.9566644, "lng": -71.5507749 },
                { "lat": -32.9566337, "lng": -71.5507392 },
                { "lat": -32.9566135, "lng": -71.5507151 },
                { "lat": -32.9566022, "lng": -71.5506721 },
                { "lat": -32.9565976, "lng": -71.550651 },
                { "lat": -32.956609, "lng": -71.5506131 },
                { "lat": -32.9565685, "lng": -71.5506239 },
                { "lat": -32.956519, "lng": -71.5506051 },
                { "lat": -32.9565114, "lng": -71.5505791 },
                { "lat": -32.9565032, "lng": -71.5505622 },
                { "lat": -32.9564717, "lng": -71.5505461 },
                { "lat": -32.9564514, "lng": -71.5505702 },
                { "lat": -32.9564402, "lng": -71.5506292 },
                { "lat": -32.9564357, "lng": -71.5506614 },
                { "lat": -32.9564199, "lng": -71.5507016 },
                { "lat": -32.9563952, "lng": -71.5507499 },
                { "lat": -32.9563704, "lng": -71.5507848 },
                { "lat": -32.9563314, "lng": -71.5507936 },
                { "lat": -32.9563089, "lng": -71.5507722 },
                { "lat": -32.9562894, "lng": -71.5507553 },
                { "lat": -32.9562781, "lng": -71.5507177 },
                { "lat": -32.9562646, "lng": -71.5506695 },
                { "lat": -32.9562445, "lng": -71.550656 },
                { "lat": -32.9562175, "lng": -71.5506373 },
                { "lat": -32.956204, "lng": -71.5505944 },
                { "lat": -32.9561747, "lng": -71.5505622 },
                { "lat": -32.9561567, "lng": -71.5505568 },
                { "lat": -32.956123, "lng": -71.5505702 },
                { "lat": -32.9560982, "lng": -71.550538 },
                { "lat": -32.9560915, "lng": -71.5505293 },
                { "lat": -32.9560464, "lng": -71.5504576 },
                { "lat": -32.9559879, "lng": -71.5504817 },
                { "lat": -32.9559497, "lng": -71.5505032 },
                { "lat": -32.9558911, "lng": -71.5504978 },
                { "lat": -32.9558596, "lng": -71.5504978 },
                { "lat": -32.9558191, "lng": -71.5504737 },
                { "lat": -32.9557831, "lng": -71.5505058 },
                { "lat": -32.9557629, "lng": -71.5505568 },
                { "lat": -32.9557359, "lng": -71.5505434 },
                { "lat": -32.9557066, "lng": -71.5505675 },
                { "lat": -32.9556593, "lng": -71.5506105 },
                { "lat": -32.9556188, "lng": -71.5505622 },
                { "lat": -32.9555716, "lng": -71.5505568 },
                { "lat": -32.9555378, "lng": -71.5505622 },
                { "lat": -32.955504, "lng": -71.5505032 },
                { "lat": -32.955459, "lng": -71.5505488 },
                { "lat": -32.9554185, "lng": -71.5505595 },
                { "lat": -32.9553758, "lng": -71.5505514 },
                { "lat": -32.955351, "lng": -71.5505219 },
                { "lat": -32.9553172, "lng": -71.5504978 },
                { "lat": -32.9552902, "lng": -71.5504978 },
                { "lat": -32.9552587, "lng": -71.5504817 },
                { "lat": -32.9552902, "lng": -71.5503959 },
                { "lat": -32.9553253, "lng": -71.5503859 },
                { "lat": -32.9553668, "lng": -71.5503959 },
                { "lat": -32.9554005, "lng": -71.5504093 },
                { "lat": -32.9554163, "lng": -71.5503717 },
                { "lat": -32.955387, "lng": -71.5503395 },
                { "lat": -32.9553532, "lng": -71.5503261 },
                { "lat": -32.955333, "lng": -71.5503047 },
                { "lat": -32.9553217, "lng": -71.5502537 },
                { "lat": -32.955333, "lng": -71.5502028 },
                { "lat": -32.9553659, "lng": -71.5501821 },
                { "lat": -32.9554095, "lng": -71.5501706 },
                { "lat": -32.9554343, "lng": -71.550133 },
                { "lat": -32.9554298, "lng": -71.5500821 },
                { "lat": -32.955405, "lng": -71.5500526 },
                { "lat": -32.955423, "lng": -71.5500043 },
                { "lat": -32.9554658, "lng": -71.5499694 },
                { "lat": -32.9555031, "lng": -71.5499166 },
                { "lat": -32.9555279, "lng": -71.5498951 },
                { "lat": -32.9555752, "lng": -71.5498897 },
                { "lat": -32.9556098, "lng": -71.5498889 },
                { "lat": -32.9556292, "lng": -71.5498736 },
                { "lat": -32.9556742, "lng": -71.5498549 },
                { "lat": -32.9557034, "lng": -71.5498576 },
                { "lat": -32.9557719, "lng": -71.5499104 },
                { "lat": -32.9558259, "lng": -71.5499158 },
                { "lat": -32.9558722, "lng": -71.5499032 },
                { "lat": -32.9559249, "lng": -71.5498916 },
                { "lat": -32.9559587, "lng": -71.5498997 },
                { "lat": -32.9559894, "lng": -71.5499484 },
                { "lat": -32.9560374, "lng": -71.5500043 },
                { "lat": -32.9560915, "lng": -71.5500123 },
                { "lat": -32.9561221, "lng": -71.5500131 },
                { "lat": -32.9561671, "lng": -71.5499675 },
                { "lat": -32.9561963, "lng": -71.5499675 },
                { "lat": -32.956231, "lng": -71.5499667 },
                { "lat": -32.9562669, "lng": -71.5499989 },
                { "lat": -32.9562871, "lng": -71.5500123 },
                { "lat": -32.9563269, "lng": -71.5500131 },
                { "lat": -32.9563651, "lng": -71.5499997 },
                { "lat": -32.9564034, "lng": -71.5499997 },
                { "lat": -32.9564694, "lng": -71.550023 },
                { "lat": -32.9565212, "lng": -71.5500096 },
                { "lat": -32.9565474, "lng": -71.549997 },
                { "lat": -32.9565887, "lng": -71.5500499 },
                { "lat": -32.9566067, "lng": -71.5500579 },
                { "lat": -32.9566442, "lng": -71.5500641 },
                { "lat": -32.9566982, "lng": -71.5500212 },
                { "lat": -32.9567275, "lng": -71.5500078 },
                { "lat": -32.9567395, "lng": -71.549956 },
                { "lat": -32.9567328, "lng": -71.5498702 },
                { "lat": -32.956717, "lng": -71.5498058 },
                { "lat": -32.9566855, "lng": -71.5497602 },
                { "lat": -32.9566532, "lng": -71.5497342 },
                { "lat": -32.9566059, "lng": -71.5497476 },
                { "lat": -32.956573, "lng": -71.5497575 },
                { "lat": -32.9565347, "lng": -71.5497602 },
                { "lat": -32.9564739, "lng": -71.5497495 },
                { "lat": -32.9564379, "lng": -71.5497521 },
                { "lat": -32.9564019, "lng": -71.5497843 },
                { "lat": -32.9563727, "lng": -71.5498112 },
                { "lat": -32.9563344, "lng": -71.5498299 },
                { "lat": -32.9562871, "lng": -71.5498219 },
                { "lat": -32.9562691, "lng": -71.5497843 },
                { "lat": -32.9562377, "lng": -71.5497575 },
                { "lat": -32.9561995, "lng": -71.549779 },
                { "lat": -32.9561792, "lng": -71.5497951 },
                { "lat": -32.956141, "lng": -71.5497763 },
                { "lat": -32.9560915, "lng": -71.5497334 },
                { "lat": -32.9560532, "lng": -71.5497307 },
                { "lat": -32.9559879, "lng": -71.5497307 },
                { "lat": -32.9559474, "lng": -71.5497361 },
                { "lat": -32.9559137, "lng": -71.5497521 },
                { "lat": -32.9558776, "lng": -71.5497441 },
                { "lat": -32.9558056, "lng": -71.5497119 },
                { "lat": -32.9557629, "lng": -71.549728 },
                { "lat": -32.9557066, "lng": -71.5497012 },
                { "lat": -32.9557124, "lng": -71.5496671 },
                { "lat": -32.9557561, "lng": -71.5496583 },
                { "lat": -32.9557899, "lng": -71.5496556 },
                { "lat": -32.9558146, "lng": -71.549618 },
                { "lat": -32.9558227, "lng": -71.5495974 },
                { "lat": -32.9558259, "lng": -71.5495751 },
                { "lat": -32.9558686, "lng": -71.5495644 },
                { "lat": -32.9558767, "lng": -71.5495384 },
                { "lat": -32.9558619, "lng": -71.5495054 },
                { "lat": -32.9558731, "lng": -71.5494759 },
                { "lat": -32.9559069, "lng": -71.5494625 },
                { "lat": -32.9559182, "lng": -71.5494437 },
                { "lat": -32.9559173, "lng": -71.5493989 },
                { "lat": -32.9559339, "lng": -71.549374 },
                { "lat": -32.9559848, "lng": -71.5493426 },
                { "lat": -32.9560155, "lng": -71.5493316 },
                { "lat": -32.956036, "lng": -71.5493105 },
                { "lat": -32.9560467, "lng": -71.5492612 },
                { "lat": -32.9560439, "lng": -71.5492139 },
                { "lat": -32.9560391, "lng": -71.5491848 },
                { "lat": -32.9560335, "lng": -71.5491533 },
                { "lat": -32.9560301, "lng": -71.5491395 },
                { "lat": -32.9560247, "lng": -71.5491254 },
                { "lat": -32.9560104, "lng": -71.5491026 },
                { "lat": -32.955998, "lng": -71.5490882 },
                { "lat": -32.9559541, "lng": -71.549046 },
                { "lat": -32.9559176, "lng": -71.5490071 },
                { "lat": -32.9558951, "lng": -71.5489782 },
                { "lat": -32.9558866, "lng": -71.5489341 },
                { "lat": -32.955897, "lng": -71.5489027 },
                { "lat": -32.9559339, "lng": -71.5488381 },
                { "lat": -32.9559575, "lng": -71.54882 },
                { "lat": -32.956016, "lng": -71.5487737 },
                { "lat": -32.9560458, "lng": -71.5487241 },
                { "lat": -32.9560515, "lng": -71.5486839 },
                { "lat": -32.956043, "lng": -71.548598 },
                { "lat": -32.9560267, "lng": -71.5484961 },
                { "lat": -32.9559925, "lng": -71.5484371 },
                { "lat": -32.9559621, "lng": -71.5484089 },
                { "lat": -32.9559306, "lng": -71.5483996 },
                { "lat": -32.9559128, "lng": -71.5483999 },
                { "lat": -32.9558934, "lng": -71.5484002 },
                { "lat": -32.9558692, "lng": -71.5483992 },
                { "lat": -32.9558444, "lng": -71.5483825 },
                { "lat": -32.9558225, "lng": -71.5483519 },
                { "lat": -32.9558073, "lng": -71.5482762 },
                { "lat": -32.9558129, "lng": -71.5482111 },
                { "lat": -32.9558292, "lng": -71.5481736 },
                { "lat": -32.9558371, "lng": -71.548136 },
                { "lat": -32.9558394, "lng": -71.5481132 },
                { "lat": -32.9558417, "lng": -71.5480549 },
                { "lat": -32.9558354, "lng": -71.548016 },
                { "lat": -32.9557763, "lng": -71.5479476 },
                { "lat": -32.9557144, "lng": -71.547896 },
                { "lat": -32.9556132, "lng": -71.5478042 },
                { "lat": -32.9554821, "lng": -71.5477009 },
                { "lat": -32.9554264, "lng": -71.5476626 },
                { "lat": -32.9553043, "lng": -71.547593 },
                { "lat": -32.9552424, "lng": -71.5475581 },
                { "lat": -32.9552078, "lng": -71.5475456 },
                { "lat": -32.955171, "lng": -71.5475345 },
                { "lat": -32.955137, "lng": -71.5475248 },
                { "lat": -32.9551133, "lng": -71.5475241 },
                { "lat": -32.9550865, "lng": -71.5475303 },
                { "lat": -32.9550623, "lng": -71.547545 },
                { "lat": -32.9550401, "lng": -71.5475655 },
                { "lat": -32.9550263, "lng": -71.5475916 },
                { "lat": -32.9550176, "lng": -71.5476054 },
                { "lat": -32.9550075, "lng": -71.5476107 },
                { "lat": -32.9549954, "lng": -71.5476114 },
                { "lat": -32.9549852, "lng": -71.5476101 },
                { "lat": -32.9549743, "lng": -71.5476057 },
                { "lat": -32.9549594, "lng": -71.5475936 },
                { "lat": -32.9549464, "lng": -71.5475829 },
                { "lat": -32.95492, "lng": -71.5475568 },
                { "lat": -32.954889, "lng": -71.5475313 },
                { "lat": -32.9548721, "lng": -71.5475125 },
                { "lat": -32.9548482, "lng": -71.5474803 },
                { "lat": -32.9548297, "lng": -71.5474323 },
                { "lat": -32.954826, "lng": -71.5473776 },
                { "lat": -32.9548283, "lng": -71.547318 },
                { "lat": -32.9548396, "lng": -71.5472442 },
                { "lat": -32.9548677, "lng": -71.5471162 },
                { "lat": -32.9547591, "lng": -71.5470759 },
                { "lat": -32.9547101, "lng": -71.5470591 },
                { "lat": -32.9546747, "lng": -71.5470511 },
                { "lat": -32.9545313, "lng": -71.547035 },
                { "lat": -32.9543518, "lng": -71.5469652 },
                { "lat": -32.9542099, "lng": -71.546925 },
                { "lat": -32.9540602, "lng": -71.5468861 },
                { "lat": -32.9539826, "lng": -71.5468794 },
                { "lat": -32.953833, "lng": -71.5468875 },
                { "lat": -32.9537148, "lng": -71.546925 },
                { "lat": -32.9536045, "lng": -71.5469357 },
                { "lat": -32.9535381, "lng": -71.5469344 },
                { "lat": -32.9533907, "lng": -71.5469585 },
                { "lat": -32.9533085, "lng": -71.5469693 },
                { "lat": -32.9532186, "lng": -71.5469921 },
                { "lat": -32.9531118, "lng": -71.5471587 },
                { "lat": -32.9530263, "lng": -71.5471908 },
                { "lat": -32.9529318, "lng": -71.5472498 },
                { "lat": -32.9529408, "lng": -71.5473303 },
                { "lat": -32.9528687, "lng": -71.5473947 },
                { "lat": -32.9527786, "lng": -71.5474321 },
                { "lat": -32.9527561, "lng": -71.5475233 },
                { "lat": -32.9526796, "lng": -71.547636 },
                { "lat": -32.9526256, "lng": -71.5476038 },
                { "lat": -32.9525041, "lng": -71.5476039 },
                { "lat": -32.9524366, "lng": -71.5475503 },
                { "lat": -32.952378, "lng": -71.5474912 },
                { "lat": -32.952279, "lng": -71.5473892 },
                { "lat": -32.952125, "lng": -71.5474195 },
                { "lat": -32.9520061, "lng": -71.5475495 },
                { "lat": -32.9519015, "lng": -71.5474741 },
                { "lat": -32.9517478, "lng": -71.5474643 },
                { "lat": -32.9516668, "lng": -71.5475019 },
                { "lat": -32.9515542, "lng": -71.5476306 },
                { "lat": -32.9515183, "lng": -71.5476628 },
                { "lat": -32.9514463, "lng": -71.5477004 },
                { "lat": -32.9514579, "lng": -71.5478358 },
                { "lat": -32.9513823, "lng": -71.547885 },
                { "lat": -32.9513885, "lng": -71.5479372 },
                { "lat": -32.9513294, "lng": -71.5479755 },
                { "lat": -32.951237, "lng": -71.5479183 },
                { "lat": -32.9512122, "lng": -71.547872 },
                { "lat": -32.9511296, "lng": -71.5478671 },
                { "lat": -32.9510427, "lng": -71.5478535 },
                { "lat": -32.9509762, "lng": -71.5479026 },
                { "lat": -32.9508925, "lng": -71.5479579 },
                { "lat": -32.9508295, "lng": -71.5480491 },
                { "lat": -32.9508251, "lng": -71.5481028 },
                { "lat": -32.9508385, "lng": -71.5481993 },
                { "lat": -32.9508205, "lng": -71.5483924 },
                { "lat": -32.9507755, "lng": -71.5484353 },
                { "lat": -32.9506315, "lng": -71.5484299 },
                { "lat": -32.9506179, "lng": -71.5485262 },
                { "lat": -32.9504919, "lng": -71.5486657 },
                { "lat": -32.950429, "lng": -71.5485799 },
                { "lat": -32.9503793, "lng": -71.548714 },
                { "lat": -32.9503253, "lng": -71.5488213 },
                { "lat": -32.9502174, "lng": -71.5488375 },
                { "lat": -32.9501363, "lng": -71.548891 },
                { "lat": -32.9500552, "lng": -71.5489768 },
                { "lat": -32.9499424, "lng": -71.5490196 },
                { "lat": -32.9498506, "lng": -71.5490517 },
                { "lat": -32.9497741, "lng": -71.5490786 },
                { "lat": -32.9497111, "lng": -71.5489445 },
                { "lat": -32.9497246, "lng": -71.5488694 },
                { "lat": -32.9496706, "lng": -71.5488264 },
                { "lat": -32.949612, "lng": -71.5488479 },
                { "lat": -32.949531, "lng": -71.5487835 },
                { "lat": -32.9494905, "lng": -71.5487567 },
                { "lat": -32.9494788, "lng": -71.5488801 },
                { "lat": -32.949396, "lng": -71.5488103 },
                { "lat": -32.9493978, "lng": -71.5487674 },
                { "lat": -32.9493212, "lng": -71.5487192 },
                { "lat": -32.9492582, "lng": -71.5487352 },
                { "lat": -32.9492267, "lng": -71.5487889 },
                { "lat": -32.9491142, "lng": -71.5488908 },
                { "lat": -32.9490331, "lng": -71.5488372 },
                { "lat": -32.9489926, "lng": -71.5487782 },
                { "lat": -32.9489521, "lng": -71.5488264 },
                { "lat": -32.9488531, "lng": -71.5488157 },
                { "lat": -32.9488783, "lng": -71.5487245 },
                { "lat": -32.9488441, "lng": -71.5486709 },
                { "lat": -32.9487546, "lng": -71.5487205 },
                { "lat": -32.9487229, "lng": -71.5488052 },
                { "lat": -32.9486914, "lng": -71.5488588 },
                { "lat": -32.9485967, "lng": -71.5488695 },
                { "lat": -32.9485472, "lng": -71.548773 },
                { "lat": -32.9484707, "lng": -71.5487623 },
                { "lat": -32.9484213, "lng": -71.5486657 },
                { "lat": -32.9482861, "lng": -71.5486281 },
                { "lat": -32.9482592, "lng": -71.5486068 },
                { "lat": -32.9481601, "lng": -71.5486121 },
                { "lat": -32.9480835, "lng": -71.5486872 },
                { "lat": -32.9480205, "lng": -71.5487569 },
                { "lat": -32.9479818, "lng": -71.548939 },
                { "lat": -32.9480115, "lng": -71.5489715 },
                { "lat": -32.9480655, "lng": -71.5490197 },
                { "lat": -32.948043, "lng": -71.5491485 },
                { "lat": -32.947971, "lng": -71.5491968 },
                { "lat": -32.947962, "lng": -71.5493523 },
                { "lat": -32.9478765, "lng": -71.5493845 },
                { "lat": -32.9477639, "lng": -71.5493738 },
                { "lat": -32.9477279, "lng": -71.5492129 },
                { "lat": -32.9476335, "lng": -71.5491646 },
                { "lat": -32.947593, "lng": -71.5492451 },
                { "lat": -32.947575, "lng": -71.5492934 },
                { "lat": -32.9474984, "lng": -71.5494061 },
                { "lat": -32.9473769, "lng": -71.5494274 },
                { "lat": -32.9472914, "lng": -71.5493953 },
                { "lat": -32.9472778, "lng": -71.5492558 },
                { "lat": -32.9472192, "lng": -71.5492343 },
                { "lat": -32.9471922, "lng": -71.5491968 },
                { "lat": -32.9471293, "lng": -71.5492236 },
                { "lat": -32.9469942, "lng": -71.5493202 },
                { "lat": -32.9468727, "lng": -71.549347 },
                { "lat": -32.9467647, "lng": -71.5494758 },
                { "lat": -32.946706, "lng": -71.549583 },
                { "lat": -32.9464766, "lng": -71.5498084 },
                { "lat": -32.9464045, "lng": -71.5498781 },
                { "lat": -32.9462829, "lng": -71.5499049 },
                { "lat": -32.9461929, "lng": -71.5499585 },
                { "lat": -32.9461163, "lng": -71.5500765 },
                { "lat": -32.9460758, "lng": -71.5501463 },
                { "lat": -32.9460398, "lng": -71.5502375 },
                { "lat": -32.9459858, "lng": -71.550393 },
                { "lat": -32.9459317, "lng": -71.5504789 },
                { "lat": -32.9458777, "lng": -71.5506076 },
                { "lat": -32.9457563, "lng": -71.5507149 },
                { "lat": -32.9456752, "lng": -71.5507685 },
                { "lat": -32.9456077, "lng": -71.5506934 },
                { "lat": -32.9455582, "lng": -71.5506613 },
                { "lat": -32.9454951, "lng": -71.5506881 },
                { "lat": -32.9454275, "lng": -71.5507739 },
                { "lat": -32.9464989, "lng": -71.5458387 },
                { "lat": -32.9488325, "lng": -71.5426419 },
                { "lat": -32.9489232, "lng": -71.5426408 },
                { "lat": -32.9489987, "lng": -71.5426397 },
                { "lat": -32.9491238, "lng": -71.5426347 },
                { "lat": -32.9491127, "lng": -71.5424122 },
                { "lat": -32.9490996, "lng": -71.5420997 },
                { "lat": -32.9495655, "lng": -71.542097 },
                { "lat": -32.9495802, "lng": -71.5416136 },
                { "lat": -32.9496565, "lng": -71.5415968 },
                { "lat": -32.949705, "lng": -71.541574 },
                { "lat": -32.949742, "lng": -71.5415566 },
                { "lat": -32.9498253, "lng": -71.5415029 },
                { "lat": -32.9499086, "lng": -71.5414332 },
                { "lat": -32.9499806, "lng": -71.541342 },
                { "lat": -32.9500369, "lng": -71.5412347 },
                { "lat": -32.9500752, "lng": -71.5411301 },
                { "lat": -32.9501022, "lng": -71.5410148 },
                { "lat": -32.9501079, "lng": -71.5409619 },
                { "lat": -32.9501135, "lng": -71.540902 },
                { "lat": -32.9508307, "lng": -71.5401084 },
                { "lat": -32.9512101, "lng": -71.5388489 },
                { "lat": -32.9512755, "lng": -71.5386351 },
                { "lat": -32.9513014, "lng": -71.5385448 },
                { "lat": -32.9515061, "lng": -71.5378243 },
                { "lat": -32.9518069, "lng": -71.536845 },
                { "lat": -32.9526067, "lng": -71.5340362 },
                { "lat": -32.9526231, "lng": -71.5339716 },
                { "lat": -32.9527839, "lng": -71.5334231 },
                { "lat": -32.9529249, "lng": -71.5328918 },
                { "lat": -32.953955, "lng": -71.529011 },
                { "lat": -32.954001, "lng": -71.5288644 },
                { "lat": -32.9540681, "lng": -71.528744 },
                { "lat": -32.9559721, "lng": -71.5218044 },
                { "lat": -32.9560076, "lng": -71.5217129 },
                { "lat": -32.9560501, "lng": -71.5216266 },
                { "lat": -32.9560967, "lng": -71.5214885 },
                { "lat": -32.9561469, "lng": -71.5213019 },
                { "lat": -32.9562208, "lng": -71.5210873 },
                { "lat": -32.9563514, "lng": -71.5207668 },
                { "lat": -32.956376, "lng": -71.5207033 },
                { "lat": -32.9563975, "lng": -71.5206381 },
                { "lat": -32.9564188, "lng": -71.5205598 },
                { "lat": -32.9564358, "lng": -71.5204798 },
                { "lat": -32.9564949, "lng": -71.5201647 },
                { "lat": -32.9565382, "lng": -71.5199957 },
                { "lat": -32.9568232, "lng": -71.5191378 },
                { "lat": -32.9573223, "lng": -71.5177168 },
                { "lat": -32.9576364, "lng": -71.5166848 },
                { "lat": -32.9577673, "lng": -71.5162541 },
                { "lat": -32.9587255, "lng": -71.5132837 },
                { "lat": -32.958772, "lng": -71.5131272 },
                { "lat": -32.9588675, "lng": -71.5128695 },
                { "lat": -32.9589793, "lng": -71.5125797 },
                { "lat": -32.958996, "lng": -71.5125362 },
                { "lat": -32.9591918, "lng": -71.5121109 },
                { "lat": -32.9594403, "lng": -71.5116063 },
                { "lat": -32.9599916, "lng": -71.5104993 },
                { "lat": -32.9603038, "lng": -71.5098302 },
                { "lat": -32.960406, "lng": -71.5096108 },
                { "lat": -32.9604729, "lng": -71.5094673 },
                { "lat": -32.9605126, "lng": -71.5093825 },
                { "lat": -32.9607074, "lng": -71.5088859 },
                { "lat": -32.9610517, "lng": -71.5081161 },
                { "lat": -32.962834, "lng": -71.505375 },
                { "lat": -32.9641707, "lng": -71.5032668 },
                { "lat": -32.9656785, "lng": -71.5008314 },
                { "lat": -32.9665135, "lng": -71.4994741 },
                { "lat": -32.9669724, "lng": -71.49875 },
                { "lat": -32.967714, "lng": -71.4975549 },
                { "lat": -32.9689551, "lng": -71.4955848 },
                { "lat": -32.9690653, "lng": -71.4954266 },
                { "lat": -32.969216, "lng": -71.4952416 },
                { "lat": -32.969504, "lng": -71.494968 },
                { "lat": -32.9698348, "lng": -71.4946703 },
                { "lat": -32.9700306, "lng": -71.4943672 },
                { "lat": -32.9707192, "lng": -71.4931388 },
                { "lat": -32.9724608, "lng": -71.4900033 },
                { "lat": -32.9733137, "lng": -71.4884261 },
                { "lat": -32.974486, "lng": -71.4860604 },
                { "lat": -32.9754063, "lng": -71.4842365 },
                { "lat": -32.9759555, "lng": -71.4832539 },
                { "lat": -32.9771504, "lng": -71.4802828 },
                { "lat": -32.9784104, "lng": -71.4786091 },
                { "lat": -32.9793931, "lng": -71.477245 },
                { "lat": -32.9801027, "lng": -71.4759758 },
                { "lat": -32.9804483, "lng": -71.4756804 },
                { "lat": -32.9810599, "lng": -71.4756775 },
                { "lat": -32.9819112, "lng": -71.4760602 },
                { "lat": -32.9825399, "lng": -71.4761256 },
                { "lat": -32.9831847, "lng": -71.4757662 },
                { "lat": -32.9834444, "lng": -71.4747792 },
                { "lat": -32.9831969, "lng": -71.4738402 },
                { "lat": -32.9834504, "lng": -71.4707556 },
                { "lat": -32.9843143, "lng": -71.4697686 },
                { "lat": -32.9848771, "lng": -71.4686099 },
                { "lat": -32.9848447, "lng": -71.4665387 },
                { "lat": -32.9853482, "lng": -71.4653596 },
                { "lat": -32.9853423, "lng": -71.463275 },
                { "lat": -32.9858387, "lng": -71.4611442 },
                { "lat": -32.9870568, "lng": -71.4595842 },
                { "lat": -32.9883594, "lng": -71.4586063 },
                { "lat": -32.989289, "lng": -71.4568527 },
                { "lat": -32.9899305, "lng": -71.4558522 },
                { "lat": -32.9904922, "lng": -71.4545549 },
                { "lat": -32.9907702, "lng": -71.4539951 },
                { "lat": -32.9912647, "lng": -71.4534468 },
                { "lat": -32.9920602, "lng": -71.4523873 },
                { "lat": -32.9925488, "lng": -71.4506981 },
                { "lat": -32.9933718, "lng": -71.4497942 },
                { "lat": -32.9938339, "lng": -71.4491467 },
                { "lat": -32.9947351, "lng": -71.448753 },
                { "lat": -32.9957339, "lng": -71.4479166 },
                { "lat": -32.9969248, "lng": -71.4474709 },
                { "lat": -32.9980366, "lng": -71.4468566 },
                { "lat": -32.998684, "lng": -71.4462327 },
                { "lat": -32.9991928, "lng": -71.4449297 },
                { "lat": -32.9995703, "lng": -71.4443262 },
                { "lat": -33.0006275, "lng": -71.4436439 },
                { "lat": -33.0017617, "lng": -71.4426332 },
                { "lat": -33.0034443, "lng": -71.4437887 },
                { "lat": -33.0042001, "lng": -71.4446497 },
                { "lat": -33.0060186, "lng": -71.445736 },
                { "lat": -33.0066982, "lng": -71.4455364 },
                { "lat": -33.008315, "lng": -71.4445832 },
                { "lat": -33.0093542, "lng": -71.4446309 },
                { "lat": -33.0102569, "lng": -71.4449769 },
                { "lat": -33.0111963, "lng": -71.4450429 },
                { "lat": -33.0125578, "lng": -71.4448294 },
                { "lat": -33.0129411, "lng": -71.4452478 },
                { "lat": -33.0132192, "lng": -71.446891 },
                { "lat": -33.0140869, "lng": -71.4484751 },
                { "lat": -33.0152816, "lng": -71.4498618 },
                { "lat": -33.0154755, "lng": -71.4515226 },
                { "lat": -33.0173905, "lng": -71.4521733 },
                { "lat": -33.0187546, "lng": -71.452353 },
                { "lat": -33.019571, "lng": -71.452588 },
                { "lat": -33.0191711, "lng": -71.4545277 },
                { "lat": -33.0189018, "lng": -71.4556494 },
                { "lat": -33.0179369, "lng": -71.4567695 },
                { "lat": -33.0179859, "lng": -71.4581589 },
                { "lat": -33.0175724, "lng": -71.4598906 },
                { "lat": -33.0170535, "lng": -71.460847 },
                { "lat": -33.017303, "lng": -71.4617483 },
                { "lat": -33.0171524, "lng": -71.4633511 },
                { "lat": -33.0166423, "lng": -71.4642164 },
                { "lat": -33.0157724, "lng": -71.4657823 },
                { "lat": -33.016098, "lng": -71.4680219 },
                { "lat": -33.0157712, "lng": -71.4689103 },
                { "lat": -33.0146556, "lng": -71.4707127 },
                { "lat": -33.0205571, "lng": -71.4715281 },
                { "lat": -33.024875, "lng": -71.4740601 },
                { "lat": -33.0279694, "lng": -71.4775362 },
                { "lat": -33.0289336, "lng": -71.4787379 },
                { "lat": -33.0291659, "lng": -71.4791568 },
                { "lat": -33.0295501, "lng": -71.4794911 },
                { "lat": -33.0300252, "lng": -71.4798666 },
                { "lat": -33.0304887, "lng": -71.4799942 },
                { "lat": -33.0309765, "lng": -71.4799727 },
                { "lat": -33.0313587, "lng": -71.4799738 },
                { "lat": -33.0317142, "lng": -71.4801159 },
                { "lat": -33.0324336, "lng": -71.4804035 },
                { "lat": -33.0324964, "lng": -71.4807143 },
                { "lat": -33.0326897, "lng": -71.481105 },
                { "lat": -33.0329012, "lng": -71.4813232 },
                { "lat": -33.0332688, "lng": -71.4815249 },
                { "lat": -33.0335158, "lng": -71.4815843 },
                { "lat": -33.0339889, "lng": -71.4817674 },
                { "lat": -33.033655, "lng": -71.4826566 },
                { "lat": -33.0331453, "lng": -71.48282 },
                { "lat": -33.0335549, "lng": -71.48451 },
                { "lat": -33.0345874, "lng": -71.4854365 },
                { "lat": -33.0352777, "lng": -71.4854874 },
                { "lat": -33.0356028, "lng": -71.4853549 },
                { "lat": -33.0358352, "lng": -71.485342 },
                { "lat": -33.0362022, "lng": -71.485335 },
                { "lat": -33.0365924, "lng": -71.4853034 },
                { "lat": -33.0369045, "lng": -71.4852953 },
                { "lat": -33.0371538, "lng": -71.4852819 },
                { "lat": -33.0379595, "lng": -71.4852347 },
                { "lat": -33.0382364, "lng": -71.4852134 },
                { "lat": -33.0388224, "lng": -71.4851825 },
                { "lat": -33.0391439, "lng": -71.485163 },
                { "lat": -33.039317, "lng": -71.4851525 },
                { "lat": -33.039806, "lng": -71.4851229 },
                { "lat": -33.0399905, "lng": -71.4851122 },
                { "lat": -33.040081, "lng": -71.485107 },
                { "lat": -33.0406782, "lng": -71.4848408 },
                { "lat": -33.0407641, "lng": -71.4848041 },
                { "lat": -33.0411327, "lng": -71.4846467 },
                { "lat": -33.0417845, "lng": -71.4843705 },
                { "lat": -33.0424584, "lng": -71.4839553 },
                { "lat": -33.0441213, "lng": -71.4843726 },
                { "lat": -33.0451762, "lng": -71.4850164 },
                { "lat": -33.0454352, "lng": -71.4851151 },
                { "lat": -33.0461538, "lng": -71.4847246 },
                { "lat": -33.0468118, "lng": -71.4848025 },
                { "lat": -33.0486899, "lng": -71.4846795 },
                { "lat": -33.0489614, "lng": -71.4848051 },
                { "lat": -33.0489793, "lng": -71.4852782 },
                { "lat": -33.0490514, "lng": -71.4858275 },
                { "lat": -33.0491593, "lng": -71.4861139 },
                { "lat": -33.0511423, "lng": -71.4867738 },
                { "lat": -33.0517088, "lng": -71.4862481 },
                { "lat": -33.0527564, "lng": -71.4868596 },
                { "lat": -33.0533049, "lng": -71.4880398 },
                { "lat": -33.0534219, "lng": -71.4893487 },
                { "lat": -33.0542671, "lng": -71.4887372 },
                { "lat": -33.0561735, "lng": -71.4874926 },
                { "lat": -33.0598423, "lng": -71.4857331 },
                { "lat": -33.0610292, "lng": -71.4835444 },
                { "lat": -33.063331, "lng": -71.4784804 },
                { "lat": -33.0636907, "lng": -71.4779225 },
                { "lat": -33.0657767, "lng": -71.47612 },
                { "lat": -33.0660284, "lng": -71.4755192 },
                { "lat": -33.0659925, "lng": -71.4743176 },
                { "lat": -33.0657047, "lng": -71.4715281 },
                { "lat": -33.065417, "lng": -71.470584 },
                { "lat": -33.0652012, "lng": -71.4697686 },
                { "lat": -33.065489, "lng": -71.4690819 },
                { "lat": -33.0660284, "lng": -71.4686528 },
                { "lat": -33.0665679, "lng": -71.4687386 },
                { "lat": -33.0672872, "lng": -71.4694682 },
                { "lat": -33.0689056, "lng": -71.4706698 },
                { "lat": -33.0697075, "lng": -71.4708702 },
                { "lat": -33.0710813, "lng": -71.4708552 },
                { "lat": -33.0725989, "lng": -71.4708144 },
                { "lat": -33.0741273, "lng": -71.4706599 },
                { "lat": -33.076578, "lng": -71.4709947 },
                { "lat": -33.0791187, "lng": -71.4721289 },
                { "lat": -33.0800122, "lng": -71.4733207 },
                { "lat": -33.0820007, "lng": -71.4757197 },
                { "lat": -33.0854976, "lng": -71.4769449 },
                { "lat": -33.0883238, "lng": -71.4784804 },
                { "lat": -33.0885396, "lng": -71.4804545 },
                { "lat": -33.0883238, "lng": -71.4832869 },
                { "lat": -33.0880362, "lng": -71.4856902 },
                { "lat": -33.0885233, "lng": -71.4877188 },
                { "lat": -33.0917574, "lng": -71.4881909 },
                { "lat": -33.0929116, "lng": -71.4893882 },
                { "lat": -33.0944796, "lng": -71.4900774 },
                { "lat": -33.0965824, "lng": -71.4897852 },
                { "lat": -33.0969528, "lng": -71.4911833 },
                { "lat": -33.0981033, "lng": -71.4934149 },
                { "lat": -33.0998289, "lng": -71.4939299 },
                { "lat": -33.102705, "lng": -71.4937582 },
                { "lat": -33.1022736, "lng": -71.4963332 },
                { "lat": -33.1006917, "lng": -71.4992514 },
                { "lat": -33.0988223, "lng": -71.500968 },
                { "lat": -33.0962337, "lng": -71.5035429 },
                { "lat": -33.0949394, "lng": -71.5049162 },
                { "lat": -33.0950833, "lng": -71.5064612 },
                { "lat": -33.0960936, "lng": -71.5072963 },
                { "lat": -33.0969528, "lng": -71.5080061 },
                { "lat": -33.0979594, "lng": -71.5098944 },
                { "lat": -33.0981074, "lng": -71.5104249 },
                { "lat": -33.0986785, "lng": -71.5124693 },
                { "lat": -33.0985347, "lng": -71.5145293 },
                { "lat": -33.0974202, "lng": -71.5171471 },
                { "lat": -33.0975999, "lng": -71.5193358 },
                { "lat": -33.0986785, "lng": -71.5225974 },
                { "lat": -33.1001165, "lng": -71.523799 },
                { "lat": -33.1018422, "lng": -71.524314 },
                { "lat": -33.1028488, "lng": -71.5267172 },
                { "lat": -33.103424, "lng": -71.5301505 },
                { "lat": -33.1029926, "lng": -71.5323821 },
                { "lat": -33.101986, "lng": -71.535472 },
                { "lat": -33.1025252, "lng": -71.5366307 },
                { "lat": -33.1035318, "lng": -71.5373173 },
                { "lat": -33.1042508, "lng": -71.5388623 },
                { "lat": -33.1041429, "lng": -71.5397206 },
                { "lat": -33.1033161, "lng": -71.5407505 },
                { "lat": -33.1024533, "lng": -71.5408793 },
                { "lat": -33.1010872, "lng": -71.5408793 },
                { "lat": -33.1000087, "lng": -71.5411368 },
                { "lat": -33.0988582, "lng": -71.5418663 },
                { "lat": -33.0976718, "lng": -71.5431109 },
                { "lat": -33.0956425, "lng": -71.5446425 },
                { "lat": -33.0945424, "lng": -71.5429173 },
                { "lat": -33.0942834, "lng": -71.5426598 },
                { "lat": -33.0940031, "lng": -71.5424109 },
                { "lat": -33.0936148, "lng": -71.5422221 },
                { "lat": -33.0932192, "lng": -71.5420676 },
                { "lat": -33.0927591, "lng": -71.5419732 },
                { "lat": -33.0916516, "lng": -71.5418272 },
                { "lat": -33.0907528, "lng": -71.5417157 },
                { "lat": -33.0902134, "lng": -71.5416213 },
                { "lat": -33.0898251, "lng": -71.541647 },
                { "lat": -33.0895231, "lng": -71.5416728 },
                { "lat": -33.0891779, "lng": -71.5417586 },
                { "lat": -33.088955, "lng": -71.541853 },
                { "lat": -33.0886529, "lng": -71.5419817 },
                { "lat": -33.0883366, "lng": -71.5421534 },
                { "lat": -33.0879626, "lng": -71.5424023 },
                { "lat": -33.0874161, "lng": -71.5427714 },
                { "lat": -33.0869343, "lng": -71.543089 },
                { "lat": -33.0865459, "lng": -71.5434237 },
                { "lat": -33.0861792, "lng": -71.543767 },
                { "lat": -33.0856686, "lng": -71.5443936 },
                { "lat": -33.0849783, "lng": -71.5451661 },
                { "lat": -33.0845971, "lng": -71.5455266 },
                { "lat": -33.0841953, "lng": -71.5457864 },
                { "lat": -33.083638, "lng": -71.5460117 },
                { "lat": -33.083139, "lng": -71.5461136 },
                { "lat": -33.0816963, "lng": -71.5463979 },
                { "lat": -33.0802669, "lng": -71.5466393 },
                { "lat": -33.079296, "lng": -71.5468539 },
                { "lat": -33.0784733, "lng": -71.5470631 },
                { "lat": -33.0780239, "lng": -71.5471659 },
                { "lat": -33.0774125, "lng": -71.5475264 },
                { "lat": -33.0767581, "lng": -71.5481015 },
                { "lat": -33.074809, "lng": -71.5501528 },
                { "lat": -33.072227, "lng": -71.5527535 },
                { "lat": -33.0701627, "lng": -71.5548993 },
                { "lat": -33.0692636, "lng": -71.5558777 },
                { "lat": -33.0686954, "lng": -71.5563498 },
                { "lat": -33.0681559, "lng": -71.5566674 },
                { "lat": -33.0675014, "lng": -71.5567704 },
                { "lat": -33.0673324, "lng": -71.5567596 },
                { "lat": -33.0669259, "lng": -71.5566931 },
                { "lat": -33.0663074, "lng": -71.55647 },
                { "lat": -33.0651133, "lng": -71.555852 },
                { "lat": -33.0644803, "lng": -71.5555859 },
                { "lat": -33.0640775, "lng": -71.5555344 },
                { "lat": -33.0636531, "lng": -71.5555687 },
                { "lat": -33.0631064, "lng": -71.5557061 },
                { "lat": -33.0625741, "lng": -71.5559636 },
                { "lat": -33.0619885, "lng": -71.5563914 },
                { "lat": -33.0608118, "lng": -71.557251 },
                { "lat": -33.0588623, "lng": -71.5586071 },
                { "lat": -33.0584451, "lng": -71.558796 },
                { "lat": -33.0580279, "lng": -71.5587788 },
                { "lat": -33.057438, "lng": -71.5587016 },
                { "lat": -33.055323, "lng": -71.5580922 },
                { "lat": -33.0523303, "lng": -71.5572682 },
                { "lat": -33.0519419, "lng": -71.5572167 },
                { "lat": -33.0515462, "lng": -71.5572768 },
                { "lat": -33.051021, "lng": -71.5574828 },
                { "lat": -33.0506253, "lng": -71.5577317 },
                { "lat": -33.0502153, "lng": -71.5582295 },
                { "lat": -33.0505261, "lng": -71.5590411 },
                { "lat": -33.0510369, "lng": -71.5604315 },
                { "lat": -33.0514685, "lng": -71.560998 },
                { "lat": -33.0511952, "lng": -71.5628005 },
                { "lat": -33.050857, "lng": -71.5639334 },
                { "lat": -33.0508427, "lng": -71.564869 },
                { "lat": -33.0508427, "lng": -71.5666113 },
                { "lat": -33.0500718, "lng": -71.5674496 },
                { "lat": -33.0491736, "lng": -71.5704051 },
                { "lat": -33.0489074, "lng": -71.5714093 },
                { "lat": -33.0483606, "lng": -71.5724478 },
                { "lat": -33.0475548, "lng": -71.5732375 },
                { "lat": -33.0469433, "lng": -71.5738383 },
                { "lat": -33.0464613, "lng": -71.5740614 },
                { "lat": -33.0457562, "lng": -71.5741816 },
                { "lat": -33.0453965, "lng": -71.5743275 },
                { "lat": -33.0446569, "lng": -71.57512 },
                { "lat": -33.0445446, "lng": -71.5749145 },
                { "lat": -33.0442314, "lng": -71.5745845 },
                { "lat": -33.0439791, "lng": -71.5743189 },
                { "lat": -33.0436697, "lng": -71.5741129 },
                { "lat": -33.0435114, "lng": -71.5740786 },
                { "lat": -33.0433316, "lng": -71.5755806 },
                { "lat": -33.043238, "lng": -71.5758639 },
                { "lat": -33.0429063, "lng": -71.5761065 },
                { "lat": -33.0414909, "lng": -71.5773007 },
                { "lat": -33.0411582, "lng": -71.5776595 },
                { "lat": -33.0407532, "lng": -71.578376 },
                { "lat": -33.0403789, "lng": -71.5790017 },
                { "lat": -33.0398871, "lng": -71.5796452 },
                { "lat": -33.0386206, "lng": -71.5811236 },
                { "lat": -33.0378682, "lng": -71.5820275 },
                { "lat": -33.0377906, "lng": -71.5821152 },
                { "lat": -33.0376386, "lng": -71.582138 },
                { "lat": -33.0376305, "lng": -71.5819586 },
                { "lat": -33.0375273, "lng": -71.5818722 },
                { "lat": -33.0372678, "lng": -71.5817968 },
                { "lat": -33.0369605, "lng": -71.5817537 },
                { "lat": -33.0364386, "lng": -71.5817075 },
                { "lat": -33.0363352, "lng": -71.581796 },
                { "lat": -33.0361016, "lng": -71.5817031 },
                { "lat": -33.0360356, "lng": -71.5816383 },
                { "lat": -33.0359004, "lng": -71.5815232 },
                { "lat": -33.0356313, "lng": -71.5814591 },
                { "lat": -33.0352622, "lng": -71.5814471 },
                { "lat": -33.035171, "lng": -71.5816258 },
                { "lat": -33.0349263, "lng": -71.5817891 },
                { "lat": -33.0345906, "lng": -71.5819883 },
                { "lat": -33.0344488, "lng": -71.5820841 },
                { "lat": -33.0343751, "lng": -71.5822517 },
                { "lat": -33.0343045, "lng": -71.582661 },
                { "lat": -33.0328094, "lng": -71.5819533 },
                { "lat": -33.0326846, "lng": -71.5821275 },
                { "lat": -33.0323131, "lng": -71.5824434 },
                { "lat": -33.0322123, "lng": -71.5825743 },
                { "lat": -33.0318179, "lng": -71.5828812 },
                { "lat": -33.0315585, "lng": -71.5832412 },
                { "lat": -33.031259, "lng": -71.5834765 },
                { "lat": -33.0306498, "lng": -71.5840106 },
                { "lat": -33.0307777, "lng": -71.5843412 },
                { "lat": -33.0307493, "lng": -71.5844815 },
                { "lat": -33.0293105, "lng": -71.5853138 },
                { "lat": -33.0291412, "lng": -71.5854594 },
                { "lat": -33.02901, "lng": -71.5856934 },
                { "lat": -33.0288039, "lng": -71.5863545 },
                { "lat": -33.0285292, "lng": -71.5870673 }
            ]
        }
    ]
};

export default VinaDelMar;
