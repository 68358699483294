import React from "react";

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ActiveItemMenu: props.activeitem };
    this.MenuItems = props.listitemsmenu;
  }

  activeMenuItem(lidata) {
    this.setState({ ActiveItemMenu: lidata }, function () {});
  }

  render() {
    const ActiveItemMenuData = this.state.ActiveItemMenu;
    const MenuItems = this.MenuItems;
    let menu = [];
    for (const MenuItem of MenuItems) {
      if (ActiveItemMenuData === MenuItem.pagepath) {
        //document.getElementById('navtitle').innerHTML = MenuItem.title;
        menu.push(
          <li className="active" key={MenuItem.pagetitle}>
            <a href={MenuItem.pagepath}>
              <i className={MenuItem.pageicon}></i>
              <p>{MenuItem.pagetitle}</p>
            </a>
          </li>
        );
      } else {
        menu.push(
          <li
            key={MenuItem.pagetitle}
            onClick={() => this.activeMenuItem(MenuItem.pagepath)}
          >
            <a href={MenuItem.pagepath}>
              <i className={MenuItem.pageicon}></i>
              <p>{MenuItem.pagetitle}</p>
            </a>
          </li>
        );
      }
    }

    return <ul className="nav">{menu}</ul>;
  }
}

export default SideMenu;
