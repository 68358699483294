import Algarrobo from "./Algarrobo";
import Buin from "./Buin";
import Cartagena from "./Cartagena";
import Colina from "./Colina";
import ElQuisco from "./ElQuisco";
import ElTabo from "./ElTabo";
import Huechuraba from "./Huechuraba";
import LaFlorida from "./LaFlorida";
import Lampa from "./Lampa"
import LaReina from "./LaReina";
import LasCondes from "./LasCondes";
import LasVizcachas from "./LasVizcachas"
import Limache from "./Limache";
import Llaillay from "./Llaillay";
import LoBarnechea from "./LoBarnechea";
import Lonquen from "./Lonquen";
import Maipu from "./Maipu";
import Nunoa from "./Nunoa";
import Olmue from "./Olmue";
import Papudo from "./Papudo";
import Penalolen from "./Penalolen";
import Providencia from "./Providencia";
import Puchuncavi from "./Puchuncavi";
import PuenteAlto from "./PuenteAlto";
import Pudahuel from "./Pudahuel";
import Quillota from "./Quillota";
import Quintero from "./Quintero"
import Quilpue from "./Quilpue";
import Quilicura from "./Quilicura"
import Recoleta from "./Recoleta";
import SanAntonio from "./SanAntonio";
import SanBernardo from "./SanBernardo"
import SanFelipe from "./SanFelipe";
import SanJoseDelMaipo from "./SanJoseDelMaipo";
import Santiago from "./Santiago";
import SantoDomingo from "./SantoDomingo";
import ValleNevado from "./ValleNevado";
import Valparaiso from "./Valparaiso";
import VinaDelMar from "./VinaDelMar";
import VillaAlemana from "./VillaAlemana";
import Vitacura from "./Vitacura";
import Zapallar from "./Zapallar";
import Curacavi from "./Curacavi";


const communes = [
    Algarrobo,
    Buin,
    Cartagena,
    Colina,
    ElQuisco,
    ElTabo,
    Huechuraba,
    LaFlorida,
    LaReina,
    LasCondes,
    Lampa,
    LasVizcachas,
    Limache,
    Llaillay,
    LoBarnechea,
    Lonquen,
    Maipu,
    Nunoa,
    Olmue,
    Papudo,
    Penalolen,
    Providencia,
    Puchuncavi,
    PuenteAlto,
    Pudahuel,
    Quillota,
    Quilpue,
    Quintero,
    Quilicura,
    Recoleta,
    SanAntonio,
    SanBernardo,
    SanFelipe,
    SanJoseDelMaipo,
    Santiago,
    SantoDomingo,
    ValleNevado,
    Valparaiso,
    VillaAlemana,
    VinaDelMar,
    Vitacura,
    Zapallar,
    Curacavi
]

export default communes;
