import React from 'react';

import { Redirect } from 'react-router-dom';
import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import BtnAnular from './components/Venta/BtnAnular';
import AddTransbankCode from './components/Venta/AddTransbankCode';
import axios from 'axios';

import {
	checkSession,
	verifyAccess,
	getDateTimeNow,
} from './components/genscripts';

//https://www.ag-grid.com/react-grid/
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Cookies from 'universal-cookie';
import './misventas.css';
import { numberFormat } from './components/genscripts';

// otra opción de tabla: https://mdbootstrap.com/docs/react/tables/pagination/

const thispage = 'misventas';
const headTitle = 'Ventas del último turno (desde última apertura de caja)';

class MisVentas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			columnDefs: [
				{
					headerName: 'Nro. Ticket',
					field: 'nroticket',
					sortable: true,
					filter: true,
					width: 102,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Destino',
					field: 'destino',
					width: 350,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Valor',
					field: 'valor',
					width: 92,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Forma de pago',
					field: 'fpago',
					width: 108,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Código Transbank',
					field: 'transbankcode',
					width: 126,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Moneda',
					field: 'moneda',
					width: 85,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Fecha',
					field: 'fecha',
					width: 150,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Estado ',
					field: 'estado',
					filter: true,
					width: 150,
					suppressSizeToFit: true,
				},
				{
					headerName: '',
					field: 'tools',
					filter: true,
					width: 90,
					cellRenderer: 'btnAnular',
					suppressSizeToFit: true,
				},
				{
					headerName: '',
					field: 'tools',
					filter: true,
					width: 90,
					cellRenderer: 'addTransbankCode',
					suppressSizeToFit: true,
				},
			],

			defaultColDef: { resizable: true },
			rowSelection: 'single',
			rowData: [],
			context: { componentParent: this },
			frameworkComponents: {
				btnAnular: BtnAnular,
				addTransbankCode: AddTransbankCode,
				getData: this.getTicketsData,
			},
		};
		this.getTicketsData = this.getTicketsData.bind(this);
	}

	componentDidMount() {
		this.getTicketsData();
		this.getSellsResume();
	}

	getSellsResume() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const copenid = cookies.get('copenid');
		const rutvendedor = cookies.get('user');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getInicioCajaById`,
			data: JSON.stringify({
				idcajasiniciofin: copenid,
			}),
			headers: headers,
		})
			.then((response) => {
				let iniciodate = response.data.iniciodate;

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_BACKEND_URL}/data/getcuadraturaparcial`,
					data: JSON.stringify({
						fechaini: iniciodate,
						fechaend: getDateTimeNow(),
						rutvendedor: rutvendedor,
					}),
					headers: headers,
				})
					.then((responsecuadratura) => {
						let cuadrap = [];
						const cuadraturaparcial = responsecuadratura.data;
						for (let cp in cuadraturaparcial) {
							cuadrap.push({
								fpago: cuadraturaparcial[cp][0],
								moneda: cuadraturaparcial[cp][1],
								total: cuadraturaparcial[cp][2],
							});
						}

						const cuadrapEF = cuadrap.filter((x) => x.fpago === 'EF');
						const cuadrapDB = cuadrap.filter((x) => x.fpago === 'DB');
						const cuadrapTC = cuadrap.filter((x) => x.fpago === 'TC');

						const cuadrapEFindx = cuadrapEF.reduce(
							(acc, el) => ({
								...acc,
								[el.moneda]: el,
							}),
							{}
						);

						const cuadrapDBindx = cuadrapDB.reduce(
							(acc, el) => ({
								...acc,
								[el.fpago]: el,
							}),
							{}
						);

						const cuadrapTCindx = cuadrapTC.reduce(
							(acc, el) => ({
								...acc,
								[el.fpago]: el,
							}),
							{}
						);

						try {
							document.getElementById('resumeCLEF').innerHTML =
								'$ ' + numberFormat(cuadrapEFindx['CL'].total.toString());
						} catch (error) {
							document.getElementById('resumeCLEF').innerHTML = '$ 0';
						}

						try {
							document.getElementById('resumeUSEF').innerHTML =
								'$ ' + numberFormat(cuadrapEFindx['US'].total.toString());
						} catch (error) {
							document.getElementById('resumeUSEF').innerHTML = '$ 0';
						}

						try {
							document.getElementById('resumeEUEF').innerHTML =
								'$ ' + numberFormat(cuadrapEFindx['EU'].total.toString());
						} catch (error) {
							document.getElementById('resumeEUEF').innerHTML = '$ 0';
						}

						try {
							document.getElementById('resumeDB').innerHTML =
								'$ ' + numberFormat(cuadrapDBindx['DB'].total.toString());
						} catch (error) {
							document.getElementById('resumeDB').innerHTML = '$ 0';
						}

						try {
							document.getElementById('resumeTC').innerHTML =
								'$ ' + numberFormat(cuadrapTCindx['TC'].total.toString());
						} catch (error) {
							document.getElementById('resumeTC').innerHTML = '$ 0';
						}
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getTicketsData() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const copenid = cookies.get('copenid');
		const rutvendedor = cookies.get('user');
		//let cuadraparcial = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getInicioCajaById`,
			data: JSON.stringify({
				idcajasiniciofin: copenid,
			}),
			headers: headers,
		})
			.then((response) => {
				let iniciodate = response.data.iniciodate;
				//console.log(iniciodate);
				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_BACKEND_URL}/data/getListTicketsCuadratura`,
					data: JSON.stringify({
						fechaini: iniciodate,
						fechaend: getDateTimeNow(),
						rutvendedor: rutvendedor,
					}),
					headers: headers,
				})
					.then((responselisttickets) => {
						let cuadrap = [];
						const ticketslist = responselisttickets.data;
						let nroTicketsActivos = 0;
						//console.log(ticketslist);
						for (let cp in ticketslist) {
							let fechahora = new Date(
								ticketslist[cp][6].replace('.000+0000', '')
							);

							let mes = fechahora.getMonth() + 1;
							let mesStr = mes.toString();
							if (mes < 10) {
								mesStr = '0' + mes.toString();
							}

							let formatted_fechahora =
								fechahora.getFullYear() +
								'-' +
								mesStr +
								'-' +
								fechahora.getDate() +
								' ' +
								fechahora.getHours() +
								':' +
								fechahora.getMinutes() +
								':' +
								fechahora.getSeconds();

							let transbankcode =
								ticketslist[cp][4] === '1' ? '-' : ticketslist[cp][4];

							cuadrap.push({
								nroticket: ticketslist[cp][0],
								destino: ticketslist[cp][1],
								valor: '$ ' + numberFormat(ticketslist[cp][2].toString()),
								fpago: ticketslist[cp][3],
								transbankcode: transbankcode,
								moneda: ticketslist[cp][5],
								fecha: formatted_fechahora,
								estado: ticketslist[cp][7],
							});

							if (
								ticketslist[cp][7] === 'ACTIVO' ||
								ticketslist[cp][7] === 'ESCANEADO'
							) {
								nroTicketsActivos++;
							}
						}
						document.getElementById(
							'nroTicketsVendidos'
						).innerHTML = nroTicketsActivos;
						//cuadrap.length;
						this.setState({ rowData: cuadrap });
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	onGridReady = (params) => {
		//this.api = params.api;
		//this.columnApi = params.columnApi;
		//this.api.sizeColumnsToFit();
	};

	render() {
		const session = checkSession();

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-2">
											<div className="card bordesredondeados">
												<div
													id="nroTicketsVendidos"
													className="resumeSellNumber"
												>
													{/* contenido */}
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats statsResume">
														<i className="nc-icon nc-single-copy-04"></i> Número
														de tickets vendidos
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-2">
											<div className="card bordesredondeados">
												<div id="resumeCLEF" className="resumeSellNumber">
													{/* contenido */}
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats statsResume">
														<i className="nc-icon nc-single-copy-04"></i> Ventas
														en CLP (Efectivo)
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-2">
											<div className="card bordesredondeados">
												<div id="resumeUSEF" className="resumeSellNumber">
													{/* contenido */}
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats statsResume">
														<i className="nc-icon nc-single-copy-04"></i> Ventas
														en US (Efectivo)
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-2">
											<div className="card bordesredondeados">
												<div id="resumeEUEF" className="resumeSellNumber">
													{/* contenido */}
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats statsResume">
														<i className="nc-icon nc-single-copy-04"></i> Ventas
														en EU (Efectivo)
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-2">
											<div className="card bordesredondeados">
												<div id="resumeDB" className="resumeSellNumber">
													{/* contenido */}
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats statsResume">
														<i className="nc-icon nc-single-copy-04"></i> Ventas
														en Débito (Transbank)
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-2">
											<div className="card bordesredondeados">
												<div id="resumeTC" className="resumeSellNumber">
													{/* contenido */}
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats statsResume">
														<i className="nc-icon nc-single-copy-04"></i> Ventas
														en Crédito (Transbank)
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-header">
													<h5 className="card-title">Últimas ventas:</h5>
												</div>
												<div
													className="ag-theme-balham tableContainer"
													style={{
														height: '400px',
														width: '1350px',
														margin: '40px auto 20px auto',
													}}
												>
													{/*onSelectionChanged={this.onSelectionChanged.bind(
                              this
                            )}*/}
													<AgGridReact
														onGridReady={this.onGridReady}
														rowSelection={this.state.rowSelection}
														frameworkComponents={this.state.frameworkComponents}
														paginationAutoPageSize={true}
														pagination={true}
														columnDefs={this.state.columnDefs}
														rowData={this.state.rowData}
													></AgGridReact>
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i> Venta
														de tickets desde la última apertura de caja
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

export default MisVentas;
