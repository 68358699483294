import React from "react";
import Checkbox from "./Checkbox";


const MediosDePago = ({ setMediosDePago, mediosDePago }) => {

  return (
    <div className="form-checkbox">
      <Checkbox label='Efectivo' setter={setMediosDePago} list={mediosDePago}>{"EF"}</Checkbox>
      <Checkbox label='Tarjeta de Credito' setter={setMediosDePago} list={mediosDePago}>{"TC"}</Checkbox>
      <Checkbox label='Tarjeta de Debito' setter={setMediosDePago} list={mediosDePago}>{"DB"}</Checkbox>
    </div>
  )
}

export default MediosDePago;