import React from 'react';
import { Redirect } from 'react-router-dom';
import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import axios from 'axios';
import Modal from 'react-modal';
import { checkSession, verifyAccess } from './components/genscripts';
import error from '../pages/images/error.png';
import success from '../pages/images/success.png';

import './settings.css';
import Cookies from 'universal-cookie';

const thispage = 'settings';
const headTitle = 'Configuraciones';

class Settings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			iddolar: 0,
			ideuro: 0,
			dolar: 0,
			euro: 0,
			idadmincomi: 0,
			iddebitocomi: 0,
			idcreditocomi: 0,
			admincomi: 0,
			debitocomi: 0,
			creditocomi: 0,
			valoractualizado: false,
			modalIsOpen: false,
			descuentos: [],
		};
		this.handleChangeDolar = this.handleChangeDolar.bind(this);
		this.handleChangeEuro = this.handleChangeEuro.bind(this);
		this.handleChangeAdmincomi = this.handleChangeAdmincomi.bind(this);
		this.handleChangeDebitocomi = this.handleChangeDebitocomi.bind(this);
		this.handleChangeCreditocomi = this.handleChangeCreditocomi.bind(this);
		this.updateMoneda = this.updateMoneda.bind(this);
		this.updateValorMsg = this.updateValorMsg.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {
		this.getDivisas();
		this.getComisiones();
		//this.getDescuentos();
	}

	updateValorMsg() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	getDivisas() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'Post',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getMonedas`,
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				const divisasIndx = response.data.reduce(
					(acc, el) => ({
						...acc,
						[el.nombremoneda]: el,
					}),
					{}
				);

				this.setState({
					iddolar: divisasIndx['dolar'].idmoneda,
					ideuro: divisasIndx['euro'].idmoneda,
					dolar: divisasIndx['dolar'].valorpesos,
					euro: divisasIndx['euro'].valorpesos,
				});
			})
			.catch((error) => {
				console.log('getMonedas: ' + error);
			});
	}

	getComisiones() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getComisiones`,
			headers: headers,
		})
			.then((response) => {
				//this.setState({ comisiones: response.data });
				console.log(response.data);
				const comiIndx = response.data.reduce(
					(acc, el) => ({
						...acc,
						[el.comisionnickname]: el,
					}),
					{}
				);

				this.setState({
					idadmincomi: comiIndx['ADM'].idcomision,
					iddebitocomi: comiIndx['DB'].idcomision,
					idcreditocomi: comiIndx['TC'].idcomision,
					admincomi: comiIndx['ADM'].comisionvalue,
					debitocomi: comiIndx['DB'].comisionvalue,
					creditocomi: comiIndx['TC'].comisionvalue,
				});
			})
			.catch((error) => {
				console.log('getComisiones: ' + error);
			});
	}

	/*
  
  getDescuentos() {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND_URL}/data/getDescuentos`,
      headers: headers,
    })
      .then((response) => {
        //console.log(response.data);
        const HtmlDescuentos = [];
        for (const desc of response.data) {
          let descuento = desc.descuento;
          
          HtmlDescuentos.push(
            <div className="form-group row">
              <label for="tramodesc" className="col-sm-1 col-form-label blackLabel">
                Tramo {desc.iddesc}:
              </label>

              <label className="paddingLabelDesc">Mínimo: </label>
              <div className="col-sm-1">
                <input
                  type="text"
                  className="form-control"
                  id="mintramodesc"
                  value={desc.montomin}
                />
              </div>
              <label className="paddingLabelDesc">Máximo: </label>
              <div className="col-sm-1">
                <input
                  type="text"
                  className="form-control"
                  id="maxtramodesc"
                  value={desc.montomax}
                />
              </div>

              <label className="paddingLabelDesc">Descuento: </label>
              <div className="col-sm-1">
                <input
                  type="text"
                  className={
                    descuento === -1
                      ? "form-control colorWhite"
                      : "form-control"
                  } 
                  id="maxtramodesc"
                  value={descuento}
                />
              </div>
              <div className="col-sm-1 paddingSuperior">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={descuento === -1 ? true : false}
                />
                <label className="form-check-label paddingLabelSinLimit">
                  Sin limite
                </label>
              </div>

              <div className="col-sm-2">
                <button
                  className="btn btn-inactivo btn-round btnonRow"
                  onClick=""
                >
                  Actualizar
                </button>
              </div>
            </div>
          );
        }
        console.log(HtmlDescuentos);
        this.setState({ descuentos: HtmlDescuentos });
      })
      .catch((error) => {
        console.log(error);
      });
  }
*/
	handleChangeDolar(event) {
		this.setState({ dolar: event.target.value });
	}

	handleChangeEuro(event) {
		this.setState({ euro: event.target.value });
	}

	handleChangeAdmincomi(event) {
		this.setState({ admincomi: event.target.value });
	}

	handleChangeDebitocomi(event) {
		this.setState({ debitocomi: event.target.value });
	}

	handleChangeCreditocomi(event) {
		this.setState({ creditocomi: event.target.value });
	}

	updateMoneda(idmoneda, valorpesos) {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateMoneda`,
			data: JSON.stringify({
				idmoneda: idmoneda,
				valorpesos: valorpesos,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				//valoractualizado
				if (parseInt(response.data) > 0) {
					this.setState({ valoractualizado: true, modalIsOpen: true });
				} else {
					this.setState({ valoractualizado: false, modalIsOpen: true });
				}

				setTimeout(() => {
					this.getDivisas();
					this.closeModal();
				}, 2000);
			})
			.catch((error) => {
				console.log('updateMoneda: ' + error);
			});
	}

	updateComision(idcomision, comisionvalue) {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateComision`,
			data: JSON.stringify({
				idcomision: idcomision,
				comisionvalue: comisionvalue,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				//valoractualizado
				/* */
				if (parseInt(response.data) > 0) {
					this.setState({ valoractualizado: true, modalIsOpen: true });
				} else {
					this.setState({ valoractualizado: false, modalIsOpen: true });
				}

				setTimeout(() => {
					this.getComisiones();
					this.closeModal();
				}, 2000);
				/* */
			})
			.catch((error) => {
				console.log('updateMoneda: ' + error);
			});
	}

	render() {
		const session = checkSession();
		const iddolar = this.state.iddolar;
		const dolar = this.state.dolar;
		const ideuro = this.state.ideuro;
		const euro = this.state.euro;
		const idadmincomi = this.state.idadmincomi;
		const admincomi = this.state.admincomi;
		const iddebitocomi = this.state.iddebitocomi;
		const debitocomi = this.state.debitocomi;
		const idcreditocomi = this.state.idcreditocomi;
		const creditocomi = this.state.creditocomi;

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-header">
													<h4>Configuraciones:</h4>
												</div>
												<div className="card-body">
													<h4>Divisas:</h4>
													<div className="form-group row">
														<label className="col-sm-1 col-form-label">
															Dolar:
														</label>
														<div className="col-sm-4">
															<input
																type="text"
																className="form-control"
																id="dolar"
																value={this.state.dolar}
																onChange={this.handleChangeDolar}
															/>
														</div>
														<div className="col-sm-2">
															<button
																className="btn btn-inactivo btn-round btnonRow"
																onClick={() =>
																	this.updateMoneda(iddolar, dolar)
																}
															>
																Actualizar
															</button>
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-1 col-form-label">
															Euro:
														</label>
														<div className="col-sm-4">
															<input
																type="text"
																className="form-control"
																id="euro"
																value={this.state.euro}
																onChange={this.handleChangeEuro}
															/>
														</div>
														<div className="col-sm-2">
															<button
																className="btn btn-inactivo btn-round btnonRow"
																onClick={() => this.updateMoneda(ideuro, euro)}
															>
																Actualizar
															</button>
														</div>
													</div>

													<br />

													<h4>Comisiones: (utilizar "." para los decimales)</h4>
													<div className="form-group row">
														<label className="col-sm-1 col-form-label">
															Admin.:
														</label>
														<div className="col-sm-4">
															<input
																type="text"
																className="form-control"
																id="admincomi"
																value={this.state.admincomi}
																onChange={this.handleChangeAdmincomi}
															/>
														</div>
														<div className="col-sm-2">
															<button
																className="btn btn-inactivo btn-round btnonRow"
																onClick={() =>
																	this.updateComision(idadmincomi, admincomi)
																}
															>
																Actualizar
															</button>
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-1 col-form-label">
															Débito:
														</label>
														<div className="col-sm-4">
															<input
																type="text"
																className="form-control"
																id="debitocomi"
																value={this.state.debitocomi}
																onChange={this.handleChangeDebitocomi}
															/>
														</div>
														<div className="col-sm-2">
															<button
																className="btn btn-inactivo btn-round btnonRow"
																onClick={() =>
																	this.updateComision(iddebitocomi, debitocomi)
																}
															>
																Actualizar
															</button>
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-1 col-form-label">
															Crédito:
														</label>
														<div className="col-sm-4">
															<input
																type="text"
																className="form-control"
																id="creditocomi"
																value={this.state.creditocomi}
																onChange={this.handleChangeCreditocomi}
															/>
														</div>
														<div className="col-sm-2">
															<button
																className="btn btn-inactivo btn-round btnonRow"
																onClick={() =>
																	this.updateComision(
																		idcreditocomi,
																		creditocomi
																	)
																}
															>
																Actualizar
															</button>
														</div>
													</div>
													<br />
													{/*
                          <h4>Rangos de descuento</h4>
                          {this.state.descuentos}
                           */}
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i>{' '}
														Configuración de Divisas y Comisiones
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* MODAL */}

							<Modal
								isOpen={this.state.modalIsOpen}
								onRequestClose={this.closeModal}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel="Imprimir ticket"
							>
								<div>
									{this.state.valoractualizado ? (
										<div className="cuadraturaResponse">
											<img src={success} alt="success" />
											<p>Valor actualizado con éxito.</p>
										</div>
									) : (
										<div className="cuadraturaResponse">
											<img src={error} alt="error" />
											<p>Error al actualizar el valor.</p>
										</div>
									)}
								</div>
							</Modal>

							{/* FIN MODAL */}
							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

Modal.setAppElement('body');

export default Settings;
