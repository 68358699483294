const LaFlorida = {
    commune: "La Florida",
    polygons: [
        {
            idService: 58,
            nameService: "La Florida 1 / Hasta Vicuña Mackenna Paradero 14",
            coordinates: [
                { "lat": -33.50819454149609,  "lng": -70.61387252326035 }, // Departamental con Vicuña Mackena
                { "lat": -33.51037728361306,  "lng": -70.61162483216725 }, // Vicuña Mackenna con Punta Arenas
                { "lat": -33.511607174940686, "lng": -70.61140140827716 }, // Punta Arenas con Gerona
                { "lat": -33.51309647686455,  "lng": -70.61126425109018 }, // Punta Arenas con Lo Ovalle
                { "lat": -33.51519209401436,  "lng": -70.61105373075524 }, // Punta Arenas con Sebastopol
                { "lat": -33.5184683886357,   "lng": -70.61067096651159 }, // Punta Arenas con Atahualpa
                { "lat": -33.520811865299976, "lng": -70.61040781608766 }, // Punta Arenas con Rupanco
                { "lat": -33.52162405951238,  "lng": -70.61016619927203 }, // Punta Arenas con Guanaqueros
                { "lat": -33.522766697623766, "lng": -70.61008305079328 }, // Punta Arenas con Yungay
                { "lat": -33.524206713206446, "lng": -70.60994894033811 }, // Punta Arenas con Hoechst
                { "lat": -33.526181105523044, "lng": -70.60968876606051 }, // Punta Arenas con Geronimo de alderete
                { "lat": -33.52788043314822,  "lng": -70.60973972803016 }, // Punta Arenas con Sta. julia
                { "lat": -33.531055403156046, "lng": -70.60987652068764 }, // Punta Arenas con Tome
                { "lat": -33.537160250174466, "lng": -70.61017806270041 }, // Punta Arenas con San Gregorio
                { "lat": -33.54666413133756,  "lng": -70.61066207807055 }, // Isla Adelaida con Trinidad
                { "lat": -33.54717068997341,  "lng": -70.58787096172945 }, // Trinidad con Vicuña Mackenna
                { "lat": -33.547547037228455, "lng": -70.5684408311809  }, // Trinidad con Av. La Florida
                { "lat": -33.54677788479868,  "lng": -70.56885247857862 },
                { "lat": -33.54579315984156,  "lng": -70.56917398038811 },
                { "lat": -33.54477492900469,  "lng": -70.56960800784893 },
                { "lat": -33.543656201065964, "lng": -70.57021886128697 }, // la florida con sta amalia
                { "lat": -33.53970369291096,  "lng": -70.57190674583337 }, // la florida con enrique olivares
                { "lat": -33.53810924014129,  "lng": -70.57244526137917 },
                { "lat": -33.535663908822315, "lng": -70.5736348181039  }, // La florida con rojas magallanes
                { "lat": -33.53187853351361,  "lng": -70.5750012008308  }, // la florida con sta julia
                { "lat": -33.52866920940186,  "lng": -70.57633543336479 }, // la florida con geronimo de alderete
                { "lat": -33.5225383322419,   "lng": -70.57894763565879 }, // la florida con walker martinez
                { "lat": -33.520755941055434, "lng": -70.57968708986031 },
                { "lat": -33.5196503040002,   "lng": -70.58065963288126 },
                { "lat": -33.51806888342569,  "lng": -70.5824921932386  },
                { "lat": -33.50994689510441,  "lng": -70.58862484056719 }, // la florida con departamental
            ]
        },
        {
            idService: 57,
            nameService: "La Florida 2 / Hasta Tobalaba",
            coordinates: [
                { "lat": -33.546413190973034, "lng": -70.61486612183779}, // Trinidad Con coronel
                { "lat": -33.54656194824486,  "lng": -70.61412875931276},
                { "lat": -33.54677260159727,  "lng": -70.60359702623816}, // trinidad con santa raquel
                { "lat": -33.54679789278383,  "lng": -70.6021783633141 },
                { "lat": -33.546848475140436, "lng": -70.6017838688275 },// trinidad con las nieves
                { "lat": -33.547145645883965, "lng": -70.58790069706136}, // trinidad con vicuña mackenna
                { "lat": -33.54761352966891,  "lng": -70.56841115139886}, // trinidad con camilo henriquez
                { "lat": -33.55391457199988,  "lng": -70.56302551064688},
                { "lat": -33.56087032650584,  "lng": -70.557415557529  },// Camilo henriquez con Bahia inglesa
                { "lat": -33.56193096237877,  "lng": -70.56315497556581},
                { "lat": -33.56187240000258,  "lng": -70.56326429781129},
                { "lat": -33.56563332539494,  "lng": -70.58452747536955}, // la Herradura con Vicuña Mackenna
                { "lat": -33.568867451245175, "lng": -70.58389883363036}, // Vicuña con elisa correa
                { "lat": -33.569969893710386, "lng": -70.59221509671   }, //Elisa Correa con troncal sur
                { "lat": -33.57009025878243,  "lng": -70.59220853037542},
                { "lat": -33.57019147473587,  "lng": -70.59241536991514},
                { "lat": -33.56995621585067,  "lng": -70.59280278365623},
                { "lat": -33.56918759530645,  "lng": -70.59469874663806},
                { "lat": -33.56850642908094,  "lng": -70.59640271056047},
                { "lat": -33.56780063671874,  "lng": -70.59798848039753},
                { "lat": -33.567475016204895, "lng": -70.5983907346226 },
                { "lat": -33.56733002659209,  "lng": -70.59870591868314},
                { "lat": -33.567272577804324, "lng": -70.59972698376701},
                { "lat": -33.57016411908266,  "lng": -70.61137566178648}, // Troncal sur con la serena
                { "lat": -33.56219231286318,  "lng": -70.61255760205945}, // la serena con san miguel
                { "lat": -33.561981654597545, "lng": -70.61046950762794}, // San miguel con bahia catalina
                { "lat": -33.5464068186659,   "lng": -70.61493496960824}, // bahia catalina con Trinidad
            ]
        },
        {
            idService: 56,
            nameService: "La Florida 3 / Hasta las Perdices",
            coordinates: [
                { "lat": -33.509965677906216, "lng": -70.58838435362917 }, // departamental con av la florida
                { "lat": -33.51783331693322,  "lng": -70.58248175530802 },
                { "lat": -33.51949514329457,  "lng": -70.5805547143351  },
                { "lat": -33.52060914755826,  "lng": -70.57972647320383 },
                { "lat": -33.54757271945985,  "lng": -70.56816959671801 }, // av la florida con trinidad oriente
                { "lat": -33.54761528599503,  "lng": -70.56642379113889 },
                { "lat": -33.54772750675964,  "lng": -70.56378651046136 }, // trinidad orte con rip tolten
                { "lat": -33.547821125623166, "lng": -70.55695045609849 }, // trinidad orte con rio huemules
                { "lat": -33.54789774124099,  "lng": -70.5546062745449  },// trinidad ote con canal san carlos
                { "lat": -33.546280007505445, "lng": -70.55109055522489 },
                { "lat": -33.54573736147706,  "lng": -70.55096983461509 }, // central hidroelectrica
                { "lat": -33.5411554344408,   "lng": -70.55262277816968 },
                { "lat": -33.539978954543514, "lng": -70.55283636075674 },
                { "lat": -33.53955325061527,  "lng": -70.55296636755214 },
                { "lat": -33.53590759121577,  "lng": -70.55442430090872 }, //canal san carlos con rojas magallanes
                { "lat": -33.534398196776756, "lng": -70.5546936007008  },
                { "lat": -33.53264107364971,  "lng": -70.55467502830486 },
                { "lat": -33.53085295134127,  "lng": -70.55513933828881 },
                { "lat": -33.530117565617495, "lng": -70.55511147968976 },
                { "lat": -33.527140747649135, "lng": -70.5556011251942  },
                { "lat": -33.52621179848447,  "lng": -70.55570327338803 },
                { "lat": -33.52549185598668,  "lng": -70.55576827678227 },
                { "lat": -33.52424548978459,  "lng": -70.55613043856259 }, // canal san carlos con lo cañas
                { "lat": -33.52260428454166,  "lng": -70.55607472136778 },
                { "lat": -33.52131916811448,  "lng": -70.5563904521585  },
                { "lat": -33.520320480138466, "lng": -70.55672475533828 },
                { "lat": -33.51956952106949,  "lng": -70.55723549633178 },
                { "lat": -33.51792822711635,  "lng": -70.55774623730089 }, // canal san carlos con palena
                { "lat": -33.51614754274516,  "lng": -70.55840555746906 },
                { "lat": -33.513422250520875, "lng": -70.55972419783156 },
                { "lat": -33.511726641835786, "lng": -70.56006778723152 }, // canal san carlos con departamental
            ]
        },
        {
            idService: 44,
            nameService: "La Florida 4 / Lo Caña",
            coordinates: [
                { "lat": -33.511726641835786, "lng": -70.56006778723152 }, // canal san carlos con departamental
                { "lat": -33.513422250520875, "lng": -70.55972419783156 },
                { "lat": -33.51614754274516,  "lng": -70.55840555746906 },
                { "lat": -33.51792822711635,  "lng": -70.55774623730089 }, // canal san carlos con palena
                { "lat": -33.51956952106949,  "lng": -70.55723549633178 },
                { "lat": -33.520320480138466, "lng": -70.55672475533828 },
                { "lat": -33.52131916811448,  "lng": -70.5563904521585  },
                { "lat": -33.52260428454166,  "lng": -70.55607472136778 },
                { "lat": -33.52424548978459,  "lng": -70.55613043856259 }, // canal san carlos con lo cañas
                { "lat": -33.52549185598668,  "lng": -70.55576827678227 },
                { "lat": -33.52621179848447,  "lng": -70.55570327338803 },
                { "lat": -33.527140747649135, "lng": -70.5556011251942  },
                { "lat": -33.530117565617495, "lng": -70.55511147968976 },
                { "lat": -33.53085295134127,  "lng": -70.55513933828881 },
                { "lat": -33.53264107364971,  "lng": -70.55467502830486 },
                { "lat": -33.534398196776756, "lng": -70.5546936007008  },
                { "lat": -33.53590759121577,  "lng": -70.55442430090872 }, //canal san carlos con rojas magallanes
                { "lat": -33.53955325061527,  "lng": -70.55296636755214 },
                { "lat": -33.539978954543514, "lng": -70.55283636075674 },
                { "lat": -33.5411554344408,   "lng": -70.55262277816968 },
                { "lat": -33.54573736147706,  "lng": -70.55096983461509 }, // central hidroelectrica
                { "lat": -33.546280007505445, "lng": -70.55109055522489 },
                { "lat": -33.54789774124099,  "lng": -70.5546062745449  },// trinidad ote con canal san carlos
                { "lat": -33.54808455741392,  "lng":-70.55395246064019  },
                { "lat": -33.54715891057924,  "lng":-70.54477728136209  },
                { "lat": -33.54673491036591,  "lng": -70.54093130107819 },
                { "lat": -33.5444601395158,   "lng": -70.53837767834742 },
                { "lat": -33.54037629743154,  "lng": -70.53817317442066 },
                { "lat": -33.53742111056668,  "lng": -70.54024474519204 },
                { "lat": -33.53706287376041,  "lng": -70.53728555301821 },
                { "lat": -33.534595143213856, "lng": -70.53197710281259 },
                { "lat": -33.53037204706422,  "lng": -70.51171762999378 },
                { "lat": -33.52718273242716,  "lng": -70.50592304154414 },
                { "lat": -33.52294045203568,  "lng": -70.51205193317357 },
                { "lat": -33.506781641326974, "lng": -70.51941903585539 },
                { "lat": -33.50972096873043,  "lng": -70.53082310877248 },
                { "lat": -33.51222570588008,  "lng": -70.53786122495157 },
                { "lat": -33.51238083787374,  "lng":-70.54394488515135  },
                { "lat": -33.51234892426657,  "lng":-70.54398316157437  },
                { "lat": -33.51244907139572,  "lng":-70.54500059604375  },
                { "lat": -33.51235880612337,  "lng":-70.54684105130559  },
                { "lat": -33.51172125165149,  "lng":-70.5599030335232   },
            ]
        }
    ]
};

export default LaFlorida;
