import React from 'react';
import { cleanAllCookies } from '../../pages/components/genscripts';
import Cookies from 'universal-cookie';
import './HeadMenu.css';
import Modal from 'react-modal';

class HeadMenu extends React.Component {
	constructor(props) {
		super(props);
		this.pagetitle = props.pagetitle;
		this.state = {
			modalBlockDisplayIsOpen: false,
			msgblock: '',
			modalIsOpenCloseSession: false,
		};
		this.openBlockWinModal = this.openBlockWinModal.bind(this);
		this.closeBlockWinModal = this.closeBlockWinModal.bind(this);
		this.unlockWin = this.unlockWin.bind(this);
		this.openCloseSesionModal = this.openCloseSesionModal.bind(this);
		this.closeModalCloseSession = this.closeModalCloseSession.bind(this);
	}

	componentDidMount() {
		const cookie = new Cookies();
		const bw = cookie.get('bw');
		if (bw === 'true') {
			this.setState({ modalBlockDisplayIsOpen: true });
		}
	}

	unlockWin() {
		const cookie = new Cookies();
		let rut = cookie.get('user');
		let password = document.getElementById('claveblock').value;

		fetch(`${process.env.REACT_APP_BACKEND_URL}/authenticate`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: rut,
				password: password,
			}),
		})
			.then((response) => {
				response.json().then((result) => {
					if (response.status === 200) {
						if (result.token != null) {
							this.closeBlockWinModal();
							this.setState({
								msgblock: '',
							});
							cookie.remove('bw', { path: '/' });
						}
					} // status 200
					else if (response.status === 401) {
						this.setState({
							msgblock: 'Clave incorrecta',
						});
					} else {
						this.setState({
							msgblock: 'Error de conexión',
						});
					}
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	openBlockWinModal(event) {
		event.preventDefault();
		const cookies = new Cookies();
		cookies.set('bw', 'true', { path: '/' });
		this.setState({ modalBlockDisplayIsOpen: true });
	}

	closeBlockWinModal() {
		this.setState({ modalBlockDisplayIsOpen: false });
	}

	logout() {
		cleanAllCookies();
		window.location.reload();
	}

	openCloseSesionModal(event) {
		event.preventDefault();
		this.setState({ modalIsOpenCloseSession: true });
	}

	closeModalCloseSession() {
		this.setState({ modalIsOpenCloseSession: false });
	}

	render() {
		const cookies = new Cookies();
		const name = cookies.get('name');
		const pid = parseInt(cookies.get('pid'));

		return (
			<div id="headbar">
				<nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
					<div className="container-fluid">
						<div className="navbar-wrapper">
							<div className="navbar-toggle">
								<button type="button" className="navbar-toggler">
									<span className="navbar-toggler-bar bar1"></span>
									<span className="navbar-toggler-bar bar2"></span>
									<span className="navbar-toggler-bar bar3"></span>
								</button>
							</div>
							<span className="navbar-brand" id="navtitle">
								{this.pagetitle}
							</span>
						</div>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navigation"
							aria-controls="navigation-index"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-bar navbar-kebab"></span>
							<span className="navbar-toggler-bar navbar-kebab"></span>
							<span className="navbar-toggler-bar navbar-kebab"></span>
						</button>
						<div
							className="collapse navbar-collapse justify-content-end"
							id="navigation"
						>
							<ul className="navbar-nav">
								<li className="nav-item">
									<a className="nav-link btn-rotate" href="/">
										<i className="nc-icon nc-single-02"></i>
										<p className="personnameHeader">{name}</p>
									</a>
								</li>

								<li className="nav-item">
									<a className="nav-link btn-rotate" href="/">
										<i className="nc-icon nc-key-25"></i>
										<p>
											<span
												className="d-md-block"
												onClick={(e) => this.openBlockWinModal(e)}
											>
												Bloquear pantalla
											</span>
										</p>
									</a>
								</li>

								<li className="nav-item marginLeft100">
									<a className="nav-link btn-rotate" href="/">
										<i className="nc-icon nc-simple-remove"></i>
										<p>
											<span
												className="d-md-block btn-logout"
												onClick={(e) => this.openCloseSesionModal(e)}
											>
												Cerrar sesión
											</span>
										</p>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>

				{/*modal block display */}
				<Modal
					isOpen={this.state.modalBlockDisplayIsOpen}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Imprimir ticket"
					style={{
						overlay: {
							backgroundColor: 'rgba(163, 163, 163, 0.6)',
							zIndex: 10000,
						},
						content: {
							color: 'black',
							zIndex: 10001,
						},
					}}
				>
					<div className="modalBlockHeader">Sesión bloqueada</div>
					<div className="modalBlockContent">
						<form>
							<div className="msgBlockLogin">{this.state.msgblock}</div>
							<div className="form-group row">
								<label
									for="claveblock"
									className="col-sm-2 col-form-label boldLabel"
								>
									Clave:
								</label>
								<div className="col-sm-9">
									<input
										type="password"
										className="form-control"
										id="claveblock"
										placeholder="clave"
									/>
								</div>
							</div>
						</form>
						<div className="modalFooter">
							<button
								className="btnsel btn btn-inactivo btn-round"
								onClick={this.unlockWin}
							>
								Entrar
							</button>
						</div>
					</div>
				</Modal>
				{/*fin modal block display */}

				{/*modal cerrar sesion */}
				<Modal
					isOpen={this.state.modalIsOpenCloseSession}
					onAfterOpen={this.afterOpenModal}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Cerrar sesion"
					style={{
						overlay: {
							backgroundColor: 'rgba(163, 163, 163, 0.6)',
							zIndex: 10002,
						},
						content: {
							color: 'black',
							zIndex: 10003,
						},
					}}
				>
					<div className="contentModalCloseSession">
						<div className="principalContentCloseSession">
							Estás seguro que quieres cerrar esta sesión?
						</div>
						{pid === 2 ? (
							<div className="explainCloseSession">
								El cierre significa que no podrás cerrar la caja.
							</div>
						) : (
							<div></div>
						)}
						<div className="botoneraCloseSession">
							<button
								className="btnsel btn btn-inactivo btn-round"
								onClick={this.logout}
							>
								Si
							</button>

							<button
								className="btnsel btn btn-inactivo btn-round"
								onClick={this.closeModalCloseSession}
							>
								No
							</button>
						</div>
					</div>
				</Modal>
				{/*fin modal cerrar sesion */}
			</div>
		);
	}
}

export default HeadMenu;
