import React from 'react';
import './BtnAnular.css';
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import error from '../../images/error.png';
import success from '../../images/success.png';

class BtnAnular extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ticketnro: 0,
			ticketstate: this.props.data.estado,
			modalIsOpen: false,
			petanulresult: false,
		};
		this.peticionAnulacionModal = this.peticionAnulacionModal.bind(this);
		this.peticionAnulacion = this.peticionAnulacion.bind(this);
		this.cancelAnulacion = this.cancelAnulacion.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	peticionAnulacionModal() {
		//console.log(this.props);
		//alert(this.props.data.nroticket);
		this.setState({ modalIsOpen: true, ticketnro: this.props.data.nroticket });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		document.getElementById('msgErrorPetAnul').innerHTML = '';
		this.setState({ modalIsOpen: false });
	}

	checkTicketState(estado) {
		let disable = '';

		if (estado === 'SOLICITUD ANULACION' || estado === 'ANULADO') {
			disable = '';
		} else {
			disable = 'notdisable';
		}
		return disable;
	}

	peticionAnulacion() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketState`,
			data: JSON.stringify({
				ticketstate: 'SOLICITUD ANULACION',
				idticket: this.state.ticketnro,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				if (parseInt(response.data) > 0) {
					this.setState({ petanulresult: true });
				} else {
					this.setState({ petanulresult: false });
				}
				document.getElementById('PetAnulPaso1').style.display = 'none';
				document.getElementById('PetAnulFinal').style.display = 'block';
				setTimeout(function () {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	cancelAnulacion() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketState`,
			data: JSON.stringify({
				ticketstate: 'ACTIVO',
				idticket: this.props.data.nroticket,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response);
				if (response.data === 1) {
					window.location.reload();
				} else {
					console.log('Error al intentar cancelar anulación');
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		const disablebtnstate = this.state.ticketstate;
		let state = this.checkTicketState(disablebtnstate);
		let textBtn = !state ? 'Cancelar' : 'Anular';
		//console.log(state);
		return (
			<div>
				<button
					className={!state ? 'btnAnularClassDisable' : 'btnAnularClass'}
					onClick={!state ? this.cancelAnulacion : this.peticionAnulacionModal}
					//disabled={!state}
				>
					{textBtn}
				</button>
				{/* MODAL */}

				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Imprimir ticket"
				>
					<div id="PetAnulPaso1">
						<div className="headermodal">
							<button onClick={this.closeModal}>
								<i class="nc-icon nc-simple-remove"></i>
							</button>
						</div>
						<div className="containerAnulacionModal">
							<h4>
								Estás seguro que quieres pedir anular el ticket nro.{' '}
								<span className="spanBlack">{this.state.ticketnro}</span> ?
							</h4>
							<div id="msgErrorPetAnul"></div>
							<br />
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								onClick={this.peticionAnulacion}
								value="Si"
							/>
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								onClick={this.closeModal}
								value="No"
							/>
						</div>
					</div>
					<div id="PetAnulFinal">
						{this.state.petanulresult ? (
							<div className="cuadraturaResponse">
								<img src={success} alt="success" />
								<p>Solicitud ingresada.</p>
							</div>
						) : (
							<div className="cuadraturaResponse">
								<img src={error} alt="error" />
								<p>Error al ingresar la solicitud.</p>
							</div>
						)}
					</div>
				</Modal>

				{/* FIN MODAL */}
			</div>
		);
	}
}

Modal.setAppElement('body');

export default BtnAnular;
