const Colina = {
    commune: "Colina",
    polygons: [
        {
            idService: 77,
            nameService: "Colina 1 / Chicureo",
            coordinates: [

                //Colina Chicureo

                { "lat": -33.24160264038618,  "lng": -70.6973184456448  }, //lo pinto con san martin        
                { "lat": -33.24182673193276,  "lng": -70.70191161652666 }, 
                { "lat": -33.24204551094132,  "lng": -70.70275364168394 }, 
                { "lat": -33.242956301871025, "lng": -70.70463440017829 }, 
                { "lat": -33.25146510343037,  "lng": -70.7172610659613  }, // lo pinto con santa marta liray        
                { "lat": -33.264438,  "lng": -70.745828         }, 
                { "lat": -33.3048427285678,   "lng": -70.72765077007783 }, //PanemericanaNorte        
                { "lat": -33.29545090654489,  "lng": -70.70242291553289 }, 
                { "lat": -33.30294585273924,  "lng": -70.69980452674933 }, 
                { "lat": -33.305304517363766, "lng": -70.69925830361404 }, 
                { "lat": -33.31222797101261,  "lng": -70.70362808884624 },
                { "lat": -33.31694473442642,  "lng": -70.70305151994667 },
                { "lat": -33.32077374714888,  "lng": -70.6961326933046  }, 
                { "lat": -33.32290372109386,  "lng": -70.69646649633171 },
                { "lat": -33.3241715380127,   "lng": -70.6930070831416  }, 
                { "lat": -33.323512275516755, "lng": -70.6921270569792  }, 
                { "lat": -33.3242222503058,   "lng": -70.68915317546487 }, 
                { "lat": -33.328963719460376, "lng": -70.68945663267776 }, 
                { "lat": -33.329496167782445, "lng": -70.68481373602785 }, 
                { "lat": -33.32323335539611,  "lng": -70.68196123743249 }, 
                { "lat": -33.31798441680394,  "lng": -70.67458722441481 }, 
                { "lat": -33.31945516572953,  "lng": -70.66988363630543 }, 
                { "lat": -33.327553557539915, "lng": -70.64804625769175 }, 
                { "lat": -33.32775639866246,  "lng": -70.62698632125361 },
                { "lat": -33.31925933348858,  "lng": -70.61005340336736 },
                { "lat": -33.28184184290073,  "lng": -70.62139115843621 }, 
                { "lat": -33.28092857335846,  "lng": -70.61896349972994 },
                { "lat": -33.27192220983712,  "lng": -70.6186296966659  }, 
                { "lat": -33.261340630261635, "lng": -70.61339000423595 }, 
                { "lat": -33.242564918587185, "lng": -70.61793849255592 },
                { "lat": -33.24619591091804,  "lng": -70.62772372871343 },   
                { "lat": -33.24494324514018,  "lng": -70.6654053721843  }, 
                { "lat": -33.24333874570176,  "lng": -70.67805835555578 },  // lo arcaya        
                { "lat": -33.24227094492244,  "lng": -70.69728442968409 },  // lo arcaya son san martin        
                { "lat": -33.24157103483323,  "lng": -70.69725224317718 },

            ]
        },
        {
            idService: 118,
            nameService: "Colina 2 / Pueblo",
            coordinates: [

                // Colina Pueblo

                { "lat": -33.243360565774914, "lng": -70.67781254945064 },
                { "lat": -33.24223982738338,  "lng": -70.69721171803549 },
                { "lat": -33.24148265362321,  "lng": -70.69726336135928 },
                { "lat": -33.24180278466432,  "lng": -70.70203485332108 },
                { "lat": -33.242197611339535, "lng": -70.70325331955964 },
                { "lat": -33.24280585434107,  "lng": -70.70445264748132 },
                { "lat": -33.25141672228222,  "lng": -70.71733365859171 }, // lo pinto con santa marta liray        
                { "lat": -33.264438,  "lng": -70.745828         }, 
                { "lat": -33.240289,  "lng": -70.756630         },
                { "lat": -33.22401075284192,  "lng": -70.74371933995958 },
                { "lat": -33.21320413187531,  "lng": -70.74260354091179 },
                { "lat": -33.20275526607256,  "lng": -70.73427796421204 },
                { "lat": -33.202252540056534, "lng": -70.72513699588764 },
                { "lat": -33.17660016016924,  "lng": -70.71368276212597 },
                { "lat": -33.164895956878325, "lng": -70.73375174987525 },
                { "lat": -33.171223768802164, "lng": -70.65697575212971 },
                { "lat": -33.17622953043159,  "lng": -70.63980030999761 },
                { "lat": -33.194077591308016, "lng": -70.63924510314227 },
                { "lat": -33.20240443352294,  "lng": -70.64286430764362 },
                { "lat": -33.220488215174846, "lng": -70.64900693544101 },
                { "lat": -33.225323728066975, "lng": -70.63785871295019 },
                { "lat": -33.230884763800574, "lng": -70.64154714665744 },
                { "lat": -33.245044,  "lng": -70.648553         },//
                {"lat": -33.24498911942201,  "lng": -70.67537524160467 },
            ]
        },
        {
            idService: 125,
            nameService: "Colina 3 / Chacabuco", //VALIDAR SI VA?
            coordinates: [

                //Colina Chacabuco

                { "lat": -33.171223768802164, "lng": -70.65697575212971 },
                { "lat": -33.16450045346753, "lng": -70.71934148568302 },
                { "lat": -33.164895956878325, "lng": -70.73375174987525 },
                { "lat": -33.16054532123495, "lng": -70.73564162058898 },
                { "lat": -33.15579892706753, "lng": -70.73894889433802 },
                { "lat": -33.1518434023671, "lng": -70.74060253121255 },
                { "lat": -33.14195381023947, "lng": -70.74201993424784 },
                { "lat": -33.14136039926304, "lng": -70.74556344183611 },
                { "lat": -33.14037137205157, "lng": -70.7495794171028 },
                { "lat": -33.13997575804593, "lng": -70.75359539236948 },
                { "lat": -33.13305222426119, "lng": -70.75359539236948 },
                { "lat": -33.12573246615078, "lng": -70.75997370602833 },
                { "lat": -33.096645237163855, "lng": -70.75052435245966 },
                { "lat": -33.09229121802061, "lng": -70.74532720799688 },
                { "lat": -33.0873432072431, "lng": -70.74627214335375 },
                { "lat": -33.08279079141065, "lng": -70.74627214335375 },
                { "lat": -33.07229954473469, "lng": -70.73446045139289 },
                { "lat": -33.070121959392566, "lng": -70.7287908392517 },
                { "lat": -33.06418281637254, "lng": -70.72194005791441 },
                { "lat": -33.05340620571108, "lng": -70.71740773193022 },
                { "lat": -33.046392404628435, "lng": -70.7257210467981 },
                { "lat": -33.03878105388524, "lng": -70.71926955267415 },
                { "lat": -33.024157403358736, "lng": -70.7094728393748 },
                { "lat": -33.0105331898318, "lng": -70.70541078751897 },
                { "lat": -33.00695623865208, "lng": -70.70908148797326 },
                { "lat": -32.98210700783993, "lng": -70.70784700983945 },
                { "lat": -32.97563470572255, "lng": -70.717105595843 },
                { "lat": -32.95802764404501, "lng": -70.71216768330777 },
                { "lat": -32.96217079779286, "lng": -70.68377468623025 },
                { "lat": -32.9606171379029, "lng": -70.65846788448725 },
                { "lat": -32.98133035663545, "lng": -70.59736121686387 },
                { "lat": -33.05585770843588, "lng": -70.57143717551857 },
                { "lat": -33.10240529662139, "lng": -70.55940101371398 },
                { "lat": -33.13264801634606, "lng": -70.53502007057132 },
                { "lat": -33.192957009842615, "lng": -70.54275424778209 },
                { "lat":-33.20955767369397,  "lng": -70.5849766552905},
                { "lat": -33.17622953043159, "lng": -70.63980030999761 }


            ]
        },
        {
            idService: 159,
            nameService: "Colina Limite con Valle Grande",    
            coordinates: [        
                { "lat":-33.3051590323577  , "lng":-70.72766048054069},
                { "lat":-33.29545090654489 , "lng":-70.70242291553289},
                { "lat":-33.30341060700818 , "lng":-70.70007720174078},
                { "lat":-33.306180656406774, "lng":-70.70022677772266},
                { "lat":-33.312269         , "lng":-70.703786        },
                { "lat":-33.316685         , "lng":-70.703470        },
                { "lat":-33.32139616130589 , "lng":-70.70690283847917},
                { "lat":-33.325697297529835, "lng":-70.71836953430673},
            ]
        },
    ]
};

export default Colina;