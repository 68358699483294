import React from 'react';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import {
	checkSession,
	verifyAccess,
	numberFormat,
} from './components/genscripts';
import './buscartickets.css';
import Cookies from 'universal-cookie';

import error from '../pages/images/error.png';
import success from '../pages/images/success.png';

const thispage = 'buscartickets';
const headTitle = 'Buscar Ticket';

class BuscarTickets extends React.Component {
	constructor() {
		super();
		this.state = {
			idticket: 0,
			destino: '',
			valorticket: '',
			fechahora: '',
			moneda: '',
			fpago: '',
			transbankcode: '',
			rut_vendedor: '',
			ticketstate: '',
			venta_supervisor: false,
			rut_supervisor: '',
			venta_reemplazo: false,
			rut_reemplazo: '',
			modalIsOpen: false,
			modalIsOpenStateTicket: false,
			modalIsOpenFPago: false,
			modalIsOpenCondutor: false,
			ticketNewFpago: '',
			ticketnrochange: 0,
			newticketstate: '',
			ingresocode: false,
			cambioestado: false,
			driverdata: [],
		};
		this.getDataFromTicket = this.getDataFromTicket.bind(this);
		this.addCode = this.addCode.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.checkFormaPago = this.checkFormaPago.bind(this);
		this.peticionCambioEstadoModal = this.peticionCambioEstadoModal.bind(this);
		this.closeModalCambioEstado = this.closeModalCambioEstado.bind(this);
		this.cambiarEstadoTicket = this.cambiarEstadoTicket.bind(this);
		this.cambiarEstadoTicketAPagado = this.cambiarEstadoTicketAPagado.bind(
			this
		);
		this.openFpagoModal = this.openFpagoModal.bind(this);
		this.closeFpagoModal = this.closeFpagoModal.bind(this);
		this.HandleFPago = this.HandleFPago.bind(this);
		this.updateFPago = this.updateFPago.bind(this);
		this.openModalConductor = this.openModalConductor.bind(this);
		this.closeModalConductor = this.closeModalConductor.bind(this);
	}

	openModalConductor() {
		this.setState({
			modalIsOpenCondutor: true,
		});
	}

	closeModalConductor() {
		this.setState({
			modalIsOpenCondutor: false,
		});
	}

	getInfoDriverOwner() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getDriverTicketOwner`,
			data: JSON.stringify({
				idticket: this.state.idticket,
			}),
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				this.setState({ driverdata: response.data });
				this.openModalConductor();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	openFpagoModal() {
		this.setState({
			modalIsOpenFPago: true,
		});
	}

	closeFpagoModal() {
		this.setState({
			modalIsOpenFPago: false,
		});
	}

	HandleFPago(event) {
		this.setState({
			ticketNewFpago: event.target.value,
		});
	}

	updateFPago() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateFPagoByIdTicket`,
			data: JSON.stringify({
				fpago: this.state.ticketNewFpago,
				idticket: this.state.idticket,
			}),
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				if (parseInt(response.data) > 0) {
					document.getElementById('msgErrorFPagoChange').innerHTML =
						'<p>Se actualizó con éxito</p>';
					this.getDataFromTicket();
				} else {
					document.getElementById('msgErrorFPagoChange').innerHTML =
						'<p>Ocurrió un error al actualizar</p>';
				}
				setTimeout(() => {
					this.closeFpagoModal();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	peticionCambioEstadoModal(ticketnro, newticketstate) {
		this.setState({
			modalIsOpenStateTicket: true,
			ticketnrochange: ticketnro,
			newticketstate: newticketstate,
		});
	}

	closeModalCambioEstado() {
		this.setState({ modalIsOpenStateTicket: false });
	}

	cambiarEstadoTicket() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketState`,
			data: JSON.stringify({
				ticketstate: this.state.newticketstate,
				idticket: this.state.ticketnrochange,
			}),
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				if (parseInt(response.data) > 0) {
					this.setState({ cambioestado: true });
					this.getDataFromTicket();
				} else {
					this.setState({ cambioestado: false });
				}
				document.getElementById('CambioEstadoPaso1').style.display = 'none';
				document.getElementById('CambioEstadoFinal').style.display = 'block';
				setTimeout(() => {
					this.closeModalCambioEstado();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	cambiarEstadoTicketAPagado() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketToPagado`,
			data: JSON.stringify({
				ticketstate: this.state.newticketstate,
				idticket: this.state.ticketnrochange,
			}),
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				if (parseInt(response.data) > 0) {
					this.setState({ cambioestado: true });
					this.getDataFromTicket();
				} else {
					this.setState({ cambioestado: false });
				}
				document.getElementById('CambioEstadoPaso1').style.display = 'none';
				document.getElementById('CambioEstadoFinal').style.display = 'block';
				setTimeout(() => {
					this.closeModalCambioEstado();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getDataFromTicket() {
		document.getElementById('showResultSearchTicket').style.display = 'block';
		const idticket = document.getElementById('searchticketinput').value;
		const cookies = new Cookies();
		const token = cookies.get('token');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getTicketById`,
			data: JSON.stringify({
				idticket: idticket,
			}),
			headers: headers,
		})
			.then((response) => {
				//console.log(response);

				this.setState({
					idticket: response.data.idticket,
					destino: response.data.destino,
					valorticket:
						'$ ' + numberFormat(response.data.valorticket.toString()),
					fechahora: response.data.fechahora,
					moneda: response.data.moneda,
					fpago: response.data.formapago,
					transbankcode: response.data.transbankcode,
					rut_vendedor: response.data.rut_vendedor,
					ticketstate: response.data.ticketstate,
					venta_supervisor: response.data.venta_supervisor,
					rut_supervisor: response.data.rut_supervisor,
					venta_reemplazo: response.data.venta_reemplazo,
					rut_reemplazo: response.data.rut_reemplazo,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	onGridReady = (params) => {
		//this.api = params.api;
		//this.columnApi = params.columnApi;
		//this.api.sizeColumnsToFit();
	};

	/*boucher*/
	checkFormaPago() {
		let disable = false;
		if (this.state.fpago === 'EF') {
			disable = false;
		} else {
			disable = true;
		}
		return disable;
	}

	addCode() {
		const tcode = document.getElementById('newTransbankCode').value;

		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketTransbankCode`,
			data: JSON.stringify({
				idticket: this.state.idticket,
				transbankcode: tcode,
			}),
			headers: headers,
		})
			.then((response) => {
				if (parseInt(response.data) > 0) {
					this.setState({ ingresocode: true });
					this.getDataFromTicket();
				} else {
					this.setState({ ingresocode: false });
				}
				document.getElementById('ingresoTransbank').style.display = 'none';
				document.getElementById('finalStepTranbank').style.display = 'block';
				setTimeout(() => {
					this.closeModal();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}
	/* fin boucher */

	render() {
		const session = checkSession();
		let btnstate = this.checkFormaPago();
		const nroticket = this.state.idticket;
		const fpago = this.state.fpago;
		const transbankcode = this.state.transbankcode;

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-header"></div>
												<div className="card-body">
													<div className="form-group col-md-6 pull-left">
														<label>Ingresa número de ticket</label>
														<input
															type="text"
															className="form-control"
															id="searchticketinput"
															placeholder="Nro. Ticket"
														/>
													</div>
													<div className="form-group col-md-6 pull-left paddinTop-13">
														<button
															className="btn btn-inactivo btn-round"
															onClick={this.getDataFromTicket}
														>
															Buscar
														</button>
													</div>

													<div id="showResultSearchTicket">
														<table className="tableResult">
															<thead>
																<tr>
																	<th>
																		<div className="border-left">
																			Nro. Ticket
																		</div>
																	</th>
																	<th>
																		<div className="border-left">Destino</div>
																	</th>
																	<th>
																		<div className="border-left">Valor</div>
																	</th>
																	<th>
																		<div className="border-left">Fecha</div>
																	</th>
																	<th>
																		<div className="border-left">Moneda</div>
																	</th>
																	<th>
																		<div className="border-left">
																			Forma de pago
																		</div>
																	</th>
																	<th>
																		<div className="border-left">
																			Código transbank
																		</div>
																	</th>
																	<th>
																		<div className="border-left">
																			Rut vendedor
																		</div>
																	</th>
																	<th>
																		<div className="border-left">
																			Estado del ticket
																		</div>
																	</th>
																	{this.state.venta_supervisor && (
																		<th>
																			<div className="border-left">
																				Reemplazo supervisor
																			</div>
																		</th>
																	)}

																	{this.state.venta_reemplazo && (
																		<th>
																			<div className="border-left">
																				Reemplazo venta
																			</div>
																		</th>
																	)}
																	{fpago !== 'EF' && (
																		<th>
																			<div className="border-left"></div>
																		</th>
																	)}
																	<th>
																		<div className="border-left"></div>
																	</th>
																	<th>
																		<div className="border-left"></div>
																	</th>
																	<th>
																		<div className="border-left"></div>
																	</th>
																	{(this.state.ticketstate === 'PAGADO' ||
																		this.state.ticketstate === 'ESCANEADO') && (
																		<th>
																			<div className="border-left"></div>
																		</th>
																	)}
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<div className="border-left">
																			{nroticket}
																		</div>
																	</td>
																	<td>
																		<div className="border-left">
																			{this.state.destino}
																		</div>
																	</td>
																	<td>
																		<div className="border-left">
																			{this.state.valorticket}
																		</div>
																	</td>
																	<td>
																		<div className="border-left">
																			{this.state.fechahora}
																		</div>
																	</td>
																	<td>
																		<div className="border-left">
																			{this.state.moneda}
																		</div>
																	</td>
																	<td>
																		<div className="border-left">{fpago}</div>
																	</td>
																	<td>
																		<div className="border-left">
																			{parseInt(transbankcode) === 1
																				? '-'
																				: transbankcode}
																		</div>
																	</td>
																	<td>
																		<div className="border-left">
																			{this.state.rut_vendedor}
																		</div>
																	</td>
																	<td>
																		<div className="border-left">
																			{this.state.ticketstate}
																		</div>
																	</td>
																	{this.state.venta_supervisor && (
																		<td>
																			<div className="border-left">
																				{this.state.rut_supervisor}
																			</div>
																		</td>
																	)}
																	{this.state.venta_reemplazo && (
																		<td>
																			<div className="border-left">
																				{this.state.rut_reemplazo}
																			</div>
																		</td>
																	)}
																	{fpago !== 'EF' && (
																		<td>
																			<div className="border-left">
																				{/* boton  boucher */}
																				<div>
																					{btnstate ? (
																						<button
																							onClick={this.openModal}
																							className="btnBoucherClass"
																						>
																							Boucher
																						</button>
																					) : (
																						<button
																							className="btnBoucherClassDisable"
																							disabled
																						>
																							Boucher
																						</button>
																					)}
																					<Modal
																						isOpen={this.state.modalIsOpen}
																						onRequestClose={this.closeModal}
																						overlayClassName="Overlay"
																						className="Modal"
																						contentLabel="Imprimir ticket"
																					>
																						<div id="ingresoTransbank">
																							<div className="headermodal">
																								<button
																									onClick={this.closeModal}
																								>
																									<i className="nc-icon nc-simple-remove"></i>
																								</button>
																							</div>
																							<div className="containerAnulacionModal">
																								<h4
																									style={{ textAlign: 'left' }}
																								>
																									Agregar código de transbank al
																									ticket nro.{' '}
																									<span className="spanBlack">
																										{this.state.idticket}
																									</span>
																								</h4>
																								<div id="msgErrorPetAnul"></div>
																								<br />
																								<div
																									className="form-group"
																									style={{ textAlign: 'left' }}
																								>
																									<label>
																										Código transbank:
																									</label>
																									<input
																										type="text"
																										className="form-control"
																										id="newTransbankCode"
																										placeholder=""
																									/>
																								</div>
																								<input
																									type="button"
																									className="btn btn-inactivo btn-round"
																									onClick={this.addCode}
																									value="Guardar"
																								/>
																							</div>
																						</div>
																						<div id="finalStepTranbank">
																							{this.state.ingresocode ? (
																								<div className="cuadraturaResponse">
																									<img
																										src={success}
																										alt="success"
																									/>
																									<p>Código ingresado.</p>
																								</div>
																							) : (
																								<div className="cuadraturaResponse">
																									<img
																										src={error}
																										alt="error"
																									/>
																									<p>
																										Error al ingresar el código
																									</p>
																								</div>
																							)}
																						</div>
																					</Modal>
																				</div>

																				{/* fin boton boucher */}
																			</div>
																		</td>
																	)}

																	<td>
																		{this.state.ticketstate === 'ACTIVO' ? (
																			<button
																				onClick={() =>
																					this.peticionCambioEstadoModal(
																						nroticket,
																						'ANULADO'
																					)
																				}
																				className="btnBoucherClass"
																			>
																				Anular
																			</button>
																		) : (
																			<button
																				onClick={() =>
																					this.peticionCambioEstadoModal(
																						nroticket,
																						'ACTIVO'
																					)
																				}
																				className="btnBoucherClass"
																				disabled={
																					this.state.ticketstate === 'PAGADO' ||
																					this.state.ticketstate === 'ESCANEADO'
																						? true
																						: false
																				}
																			>
																				Activar
																			</button>
																		)}
																	</td>
																	<td>
																		{
																			<button
																				onClick={() =>
																					this.peticionCambioEstadoModal(
																						nroticket,
																						'PAGADO'
																					)
																				}
																				className="btnBoucherClass"
																				disabled={
																					this.state.ticketstate === 'ESCANEADO'
																						? false
																						: true
																				}
																			>
																				PAGADO
																			</button>
																		}
																	</td>
																	<td>
																		<button
																			className="btnBoucherClass"
																			onClick={() => this.openFpagoModal()}
																			disabled={
																				this.state.ticketstate === 'PAGADO'
																					? true
																					: false
																			}
																		>
																			Forma pago
																		</button>
																	</td>
																	{(this.state.ticketstate === 'PAGADO' ||
																		this.state.ticketstate === 'ESCANEADO') && (
																		<td>
																			<button
																				className="btnBoucherClass"
																				onClick={() =>
																					this.getInfoDriverOwner()
																				}
																			>
																				Conductor
																			</button>
																		</td>
																	)}
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i> Buscar
														tickets
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* MODAL */}

							<Modal
								isOpen={this.state.modalIsOpenStateTicket}
								onRequestClose={this.closeModalCambioEstado}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel="Imprimir ticket"
							>
								<div id="CambioEstadoPaso1">
									<div className="headermodal">
										<button onClick={this.closeModalCambioEstado}>
											<i className="nc-icon nc-simple-remove"></i>
										</button>
									</div>
									<div className="containerAnulacionModal">
										<h4>
											Estás seguro que quieres cambia el estado del ticket nro.{' '}
											<span className="spanBlack">
												{this.state.ticketnrochange}
											</span>{' '}
											?
										</h4>
										<div id="msgErrorPetAnul"></div>
										<br />
										<input
											type="button"
											className="btn btn-inactivo btn-round"
											onClick={
												this.state.newticketstate === 'PAGADO'
													? this.cambiarEstadoTicketAPagado
													: this.cambiarEstadoTicket
											}
											value="Si"
										/>
										<input
											type="button"
											className="btn btn-inactivo btn-round"
											onClick={this.closeModalCambioEstado}
											value="No"
										/>
									</div>
								</div>
								<div id="CambioEstadoFinal">
									{this.state.cambioestado ? (
										<div className="cuadraturaResponse">
											<img src={success} alt="success" />
											<p>Estado actualizado.</p>
										</div>
									) : (
										<div className="cuadraturaResponse">
											<img src={error} alt="error" />
											<p>Error al actualizar el estado</p>
										</div>
									)}
								</div>
							</Modal>

							{/* FIN MODAL */}
							{/* MODAL F PAGO */}
							<Modal
								isOpen={this.state.modalIsOpenFPago}
								onRequestClose={this.closeFpagoModal}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel="Cambio FPago"
							>
								<div id="CambioEstadoPaso1">
									<div className="headermodal">
										<button onClick={this.closeFpagoModal}>
											<i className="nc-icon nc-simple-remove"></i>
										</button>
									</div>
									<div className="containerAnulacionModal form-group col-md-12">
										<h4>Selecciona una opción</h4>
										<div id="msgErrorFPagoChange"></div>
										<br />
										<select
											onChange={(e) => this.HandleFPago(e)}
											className="form-control"
										>
											<option>Selecciona</option>
											<option value="EF">Efectivo</option>
											<option value="DB">Débito</option>
											<option value="TC">Crédito</option>
										</select>
										<br />
										<button
											onClick={this.updateFPago}
											className="btn btn-inactivo btn-round"
										>
											Guardar
										</button>
									</div>
								</div>
							</Modal>
							{/* FIN MODAL F PAGO */}
							{/* MODAL CONDUCTOR */}
							<Modal
								isOpen={this.state.modalIsOpenCondutor}
								onRequestClose={this.closeModalConductor}
								overlayClassName="Overlay"
								className="ModalConductor"
								contentLabel="Imprimir ticket"
							>
								<div className="headermodal">
									<button onClick={this.closeModalConductor}>
										<i className="nc-icon nc-simple-remove"></i>
									</button>
								</div>
								<div className="containerConductorModal">
									<h4>Conductor</h4>
									<div id="infoconductor">
										<table border="0">
											<thead>
												<tr>
													<th>Nombre: </th>
													<th>Rut: </th>
													<th>e-Mail:</th>
													<th>Teléfono: </th>
													<th>Fecha de escaneo: </th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														{this.state.driverdata[0] +
															' ' +
															this.state.driverdata[1]}
													</td>
													<td>{this.state.driverdata[2]}</td>
													<td>{this.state.driverdata[3]}</td>
													<td>{this.state.driverdata[4]}</td>
													<td>
														{new Date(
															this.state.driverdata[5]
														).toLocaleDateString('es-CL', {
															year: 'numeric',
															month: 'numeric',
															day: 'numeric',
															hour: 'numeric',
															minute: 'numeric',
															second: 'numeric',
														})}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</Modal>
							{/* FIN MODAL CONDUCTOR */}
							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

Modal.setAppElement('body');
export default BuscarTickets;
