import React from 'react';

import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import error from '../../images/error.png';
import success from '../../images/success.png';

import './BtnUser.css';

class BtnDelPermiso extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			idpermiso: this.props.data.idpermiso,
			nombrepagina: this.props.data.pagetitle,
			nombreperfil: this.props.data.nombreperfil,
			modalIsOpen: false,
			eliminarPermisoAction: false,
		};
		this.eliminarPermisoModal = this.eliminarPermisoModal.bind(this);
		this.eliminarPermiso = this.eliminarPermiso.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	eliminarPermisoModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	eliminarPermiso() {
		//alert(this.state.idpermiso);

		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/deletePermiso`,
			data: JSON.stringify({
				idpermiso: this.state.idpermiso,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);

				if (parseInt(response.data) > 0) {
					this.setState({ eliminarPermisoAction: true });
				} else {
					this.setState({ eliminarPermisoAction: false });
				}
				document.getElementById('DeletePermisoPaso1').style.display = 'none';
				document.getElementById('DeletePermisoPasoFinal').style.display =
					'block';
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		return (
			<div>
				<button className="btnClass" onClick={this.eliminarPermisoModal}>
					Eliminar
				</button>

				{/* MODAL */}

				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Imprimir ticket"
				>
					<div id="DeletePermisoPaso1">
						<div className="headermodal">
							<button onClick={this.closeModal}>
								<i class="nc-icon nc-simple-remove"></i>
							</button>
						</div>
						<div className="containerAnulacionModal">
							<h4>
								Estás seguro que deseas eliminar la página{' '}
								<span className="spanBlack">{this.state.nombrepagina}</span> del
								perfil{' '}
								<span className="spanBlack">{this.state.nombreperfil}</span>?
							</h4>
							<br />
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								onClick={this.eliminarPermiso}
								value="Si"
							/>
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								value="No"
								onClick={this.closeModal}
							/>
						</div>
					</div>
					<div id="DeletePermisoPasoFinal">
						{this.state.eliminarPermisoAction ? (
							<div className="cuadraturaResponse">
								<img src={success} alt="success" />
								<p>Permiso eliminado con éxito.</p>
							</div>
						) : (
							<div className="cuadraturaResponse">
								<img src={error} alt="error" />
								<p>Error al eliminar el permiso.</p>
							</div>
						)}
					</div>
				</Modal>

				{/* FIN MODAL */}
			</div>
		);
	}
}

Modal.setAppElement('body');

export default BtnDelPermiso;
