import React from 'react';

import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import error from '../../images/error.png';
import success from '../../images/success.png';

import './BtnUser.css';

class BtnDelUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			idusuario: this.props.data.idusuario,
			active: this.props.data.active,
			clave: '',
			modalIsOpen: false,
			desactivarAction: false,
		};
		this.desactivarUsuarioModal = this.desactivarUsuarioModal.bind(this);
		this.desactivarUsuario = this.desactivarUsuario.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	desactivarUsuarioModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	desactivarUsuario() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/inactivateUser`,
			data: JSON.stringify({
				idusuario: this.state.idusuario,
				active: !this.state.active,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				if (parseInt(response.data) > 0) {
					this.setState({ desactivarAction: true });
				} else {
					this.setState({ desactivarAction: false });
				}
				document.getElementById('DeleteUserPaso1').style.display = 'none';
				document.getElementById('DeleteUserPasoFinal').style.display = 'block';
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		return (
			<div>
				<button
					className={this.state.active ? 'btnClass' : 'btnDisable'}
					onClick={this.desactivarUsuarioModal}
				>
					{this.state.active ? 'Desactivar' : 'Activar'}
				</button>

				{/* MODAL */}

				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Imprimir ticket"
				>
					<div id="DeleteUserPaso1">
						<div className="headermodal">
							<button onClick={this.closeModal}>
								<i class="nc-icon nc-simple-remove"></i>
							</button>
						</div>
						<div className="containerAnulacionModal">
							<h4>
								Estás seguro que deseas desactivar de usuario de{' '}
								{this.state.nombre} {this.state.apellido}?
							</h4>
							<div id="msgErrorPetAnul"></div>
							<br />
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								onClick={this.desactivarUsuario}
								value="Si"
							/>
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								value="No"
								onClick={this.closeModal}
							/>
						</div>
					</div>
					<div id="DeleteUserPasoFinal">
						{this.state.desactivarAction ? (
							<div className="cuadraturaResponse">
								<img src={success} alt="success" />
								<p>Usuario desactivado con éxito.</p>
							</div>
						) : (
							<div className="cuadraturaResponse">
								<img src={error} alt="error" />
								<p>Error al desactivar el usuario.</p>
							</div>
						)}
					</div>
				</Modal>

				{/* FIN MODAL */}
			</div>
		);
	}
}

Modal.setAppElement('body');

export default BtnDelUser;
