const Limache = {
    commune: "Limache",
        polygons: [
        {
            idService: 89,
            nameService: "Quillota / Limache / Olmué",
            coordinates: [
                {"lat":-32.97631808233867, "lng":-71.28162781900858},
                {"lat":-32.97722628346585, "lng":-71.28119477069437},
                {"lat":-32.97625753526476, "lng":-71.27982345103274},
                {"lat":-32.97886102195629, "lng":-71.27823560721401},
                {"lat":-32.97583370458495, "lng":-71.27505991957655},
                {"lat":-32.97692355079464, "lng":-71.2721007560962},
                {"lat":-32.975409871871, "lng":-71.27022421340133},
                {"lat":-32.976802457435554, "lng":-71.26813114654936},
                {"lat":-32.97904265769817, "lng":-71.2688528937397},
                {"lat":-32.97825556678487, "lng":-71.2666876521687},
                {"lat":-32.97940592806104, "lng":-71.26509980834997},
                {"lat":-32.98067736256142, "lng":-71.26524415778805},
                {"lat":-32.984733721671226, "lng":-71.26069715048895},
                {"lat":-32.984612639023325, "lng":-71.25528404656146},
                {"lat":-32.987034260426675, "lng":-71.25629449262793},
                {"lat":-32.987881812223314, "lng":-71.26113019880316},
                {"lat":-32.98842666265233, "lng":-71.26163542183639},
                {"lat":-32.98897150971813, "lng":-71.26221281958864},
                {"lat":-32.989819042913275, "lng":-71.2577379870086},
                {"lat":-32.99502513970603, "lng":-71.26040845161282},
                {"lat":-32.994843536848144, "lng":-71.26141889767929},
                {"lat":-32.995206742190184, "lng":-71.26163542183639},
                {"lat":-32.9944803300112, "lng":-71.26445023587868},
                {"lat":-32.996296349246734, "lng":-71.26517198306901},
                {"lat":-32.997446475432184, "lng":-71.26221281958864},
                {"lat":-33.000715173266514, "lng":-71.26394501284544},
                {"lat":-33.00156259367818, "lng":-71.26394501284544},
                {"lat":-33.00253106418154, "lng":-71.26495545891191},
                {"lat":-33.003075824167524, "lng":-71.26343978981221},
                {"lat":-32.99823339523724, "lng":-71.25983105386055},
                {"lat":-33.000775703565864, "lng":-71.25189183476691},
                {"lat":-33.003438995622645, "lng":-71.25189183476691},
                {"lat":-33.00440744552685, "lng":-71.25145878645272},
                {"lat":-33.006646945208395, "lng":-71.25275793139531},
                {"lat":-33.007312731446426, "lng":-71.25095356341949},
                {"lat":-33.005194303245325, "lng":-71.24720047802975},
                {"lat":-33.00676799762548, "lng":-71.2381786381506},
                {"lat":-33.0081224108442, "lng":-71.23757021567606},
                {"lat":-33.009346012090596, "lng":-71.23860018394883},
                {"lat":-33.006970770634936, "lng":-71.24357836393389},
                {"lat":-33.00905810744218, "lng":-71.24357836393389},
                {"lat":-33.011289343885, "lng":-71.23911516808522},
                {"lat":-33.016111500768076, "lng":-71.2403167977368},
                {"lat":-33.01539179260201, "lng":-71.24177591945654},
                {"lat":-33.01769483805506, "lng":-71.24134676600957},
                {"lat":-33.01827059001895, "lng":-71.2438358560021},
                {"lat":-33.01049762128601, "lng":-71.24829905185076},
                {"lat":-33.009346012090596, "lng":-71.2505306497751},
                {"lat":-33.01157724124968, "lng":-71.25181811011606},
                {"lat":-33.01200908553449, "lng":-71.25113146460089},
                {"lat":-33.01330460570072, "lng":-71.25156061804788},
                {"lat":-33.014096303099564, "lng":-71.2504448190857},
                {"lat":-33.01582361820659, "lng":-71.25121729529027},
                {"lat":-33.01668726307129, "lng":-71.25078814184329},
                {"lat":-33.020573560276134, "lng":-71.25113146460087},
                {"lat":-33.019997823351666, "lng":-71.25336306252521},
                {"lat":-33.021437158612905, "lng":-71.25284807838882},
                {"lat":-33.02158109084655, "lng":-71.25181811011605},
                {"lat":-33.023308259320004, "lng":-71.25164644873725},
                {"lat":-33.02460361346703, "lng":-71.25250475563122},
                {"lat":-33.02503539395252, "lng":-71.25576632182835},
                {"lat":-33.0234521884986, "lng":-71.2566246287223},
                {"lat":-33.0230204002578, "lng":-71.26194613146497},
                {"lat":-33.02129322614428, "lng":-71.2672676342076},
                {"lat":-33.0234521884986, "lng":-71.2672676342076},
                {"lat":-33.027626032447586, "lng":-71.27602236452616},
                {"lat":-33.024344245395305, "lng":-71.2787539470463},
                {"lat":-33.02232923590122, "lng":-71.27544062233594},
                {"lat":-33.01927594767826, "lng":-71.2743481760108},
                {"lat":-33.01897061303983, "lng":-71.27507647356089},
                {"lat":-33.01825816143785, "lng":-71.27459094186082},
                {"lat":-33.017342143775736, "lng":-71.27398402723576},
                {"lat":-33.016120772088904, "lng":-71.27507647356089},
                {"lat":-33.00970829317001, "lng":-71.27143498581047},
                {"lat":-33.0071635294495, "lng":-71.27252743213559},
                {"lat":-33.006858152869086, "lng":-71.2753192394109},
                {"lat":-33.00472048720319, "lng":-71.27483370771085},
                {"lat":-33.003193551439296, "lng":-71.2777468979112},
                {"lat":-33.008792186688936, "lng":-71.28175253443663},
                {"lat":-33.007672488067385, "lng":-71.28369466123686},
                {"lat":-33.00869039649253, "lng":-71.28418019293692},
                {"lat":-33.0071635294495, "lng":-71.28600093681213},
                {"lat":-33.006552775231405, "lng":-71.28551540511208},
                {"lat":-33.00237917489158, "lng":-71.2839374270869},
                {"lat":-32.998034042067914, "lng":-71.2771104664133},
                {"lat":-32.9925859795287, "lng":-71.2762443697849},
                {"lat":-32.98992235987323,"lng":-71.2820905220266},
                {"lat":-32.99307026537466, "lng":-71.28382271528339},
                {"lat":-32.99204115477545, "lng":-71.28591578213536},
                {"lat":-32.99040666033534,"lng": -71.28497751078793},
                {"lat":-32.98883267411736, "lng":-71.28735927651601},
                {"lat":-32.986047860511135, "lng":-71.28562708325923},
                {"lat":-32.985563536133,"lng": -71.28663752932569},
                {"lat":-32.98187047537384, "lng":-71.2849053360689},
                {"lat":-32.980659602151526, "lng":-71.28367836584533},
                {"lat":-32.97829835160396, "lng":-71.28894712033475},
                {"lat":-32.97623977396585, "lng":-71.28757580067312},
                {"lat":-32.978540534053366, "lng":-71.2833896669692},
            ]
        },
    ]
};

export default Limache;
