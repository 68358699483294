const Huechuraba = {
    commune: "Huechuraba",
    polygons: [
        {
            idService: 64,
            nameService: "Huechuraba 1 /Hasta Pedro Fontova",
            coordinates: [
                //Limites con quilicura
                {"lat": -33.366085578748724, "lng": -70.68925310816654},
                {"lat": -33.363059954147595, "lng": -70.68964339598475},
                {"lat": -33.362201922052904, "lng": -70.69016446686278},
                {"lat": -33.3562434617338, "lng": -70.69220152046911},
                {"lat": -33.352376827934364, "lng": -70.6925907422067},
                //Limites chicureo
                {"lat": -33.35212368554968, "lng": -70.68900864656872},
                {"lat": -33.35234951043757, "lng": -70.68884643673402},
                {"lat": -33.35179681496046, "lng": -70.6843023344891},
                {"lat": -33.34989985565138, "lng": -70.68457268421362},
                {"lat": -33.34944819258062, "lng": -70.6840860547095},
                {"lat": -33.34913202703689, "lng": -70.68435640443401},
                {"lat": -33.3480480221703, "lng": -70.6837616350401},
                {"lat": -33.34728017722391, "lng": -70.68262616619718},
                {"lat": -33.34678333276783, "lng": -70.68003080884193},
                {"lat": -33.346692997107574, "lng": -70.67981452906233},
                {"lat": -33.34655749344144, "lng": -70.678895339999},
                {"lat": -33.34637682155859, "lng": -70.6789494099439},
                {"lat": -33.34615098117782, "lng": -70.67921975966841},
                {"lat": -33.34583480366066, "lng": -70.67921975966841},
                {"lat": -33.3451121078816, "lng": -70.67840871049489},
                {"lat": -33.34506693919625, "lng": -70.67873313016429},
                {"lat": -33.34484109541803, "lng": -70.67851685038471},
                {"lat": -33.34466041997373, "lng": -70.67911161977861},
                {"lat": -33.34363202641081, "lng": -70.6778128981769},
                {"lat": -33.34314029601793, "lng": -70.67685638623064},
                {"lat": -33.34271002964639, "lng": -70.67531125154821},
                {"lat": -33.342304410166676, "lng": -70.67454589273109},
                {"lat": -33.34176236691943, "lng": -70.67595171129851},
                {"lat": -33.34072344126529, "lng": -70.6753028719597},
                {"lat": -33.34081378311852, "lng": -70.6744918227862},
                {"lat": -33.34040724384853, "lng": -70.67395112299467},
                {"lat": -33.339503816881766, "lng": -70.67368077327015},
                {"lat": -33.339142443471054, "lng": -70.67389705304976},
                {"lat": -33.3388262405067, "lng": -70.67330228365584},
                {"lat": -33.337742107342194, "lng": -70.67368077327015},
                {"lat": -33.337064517261716, "lng": -70.67368077327015},
                {"lat": -33.33710969009773, "lng": -70.67319414376604},
                {"lat": -33.337922797140074, "lng": -70.67286972409664},
                {"lat": -33.338555208480685, "lng": -70.67232902464764},
                {"lat": -33.338510036394354, "lng": -70.67157204541903},
                {"lat": -33.338781068560934, "lng": -70.67043657657611},
                {"lat": -33.337742107342194, "lng": -70.66811156894536},
                {"lat": -33.33645496022307, "lng": -70.66745576670765},
                {"lat": -33.33588489233689, "lng": -70.66750116774661},
                {"lat": -33.335556301945935, "lng": -70.6667801133149},
                {"lat": -33.336158716715815, "lng": -70.66625571009185},
                {"lat": -33.336520837753504, "lng": -70.66596942616287},
                {"lat": -33.33578318089498, "lng": -70.6641667690334},
                //Limites con pedro fontova
                {"lat": -33.33609230476097, "lng": -70.66330474344524},
                {"lat": -33.337702085083684, "lng": -70.66503889379123},
                {"lat": -33.33820912798577, "lng": -70.66707205780826},
                {"lat": -33.33871740054387, "lng": -70.66747517751915},
                {"lat": -33.33914062338492, "lng": -70.66764722704805},
                {"lat": -33.340042959556676, "lng": -70.66799132610589},
                {"lat": -33.34152820050707, "lng": -70.6685648245356},
                {"lat": -33.34320936902595, "lng": -70.66920912567063},
                {"lat": -33.34360626271064, "lng": -70.66931341573378},
                {"lat": -33.344835653619675, "lng": -70.669637873708},
                {"lat": -33.34612310738256, "lng": -70.6701824995933},
                {"lat": -33.34625862772392, "lng": -70.67019408737809},
                {"lat": -33.34635542793593, "lng": -70.67024043859921},
                {"lat": -33.34642318795218, "lng": -70.67029837752317},
                {"lat": -33.347923574800696, "lng": -70.67021726302963},
                {"lat": -33.34943361545817, "lng": -70.67029837752317},
                {"lat": -33.35147600064051, "lng": -70.67020567526555},
                {"lat": -33.35211484112364, "lng": -70.67018249969597},
                {"lat": -33.35218259665746, "lng": -70.67027520197432},
                {"lat": -33.352211634727254, "lng": -70.67040266760705},
                {"lat": -33.35267624252647, "lng": -70.67042584317663},
                {"lat": -33.355654954901055, "lng": -70.67043090019708},
                {"lat": -33.35803594946929, "lng": -70.6703613734883},
                {"lat": -33.36140407454737, "lng": -70.67037296131265},
                {"lat": -33.36446237403097, "lng": -70.67037296129668},
                {"lat": -33.366862483088696, "lng": -70.67032661015749},
                {"lat": -33.367481855378664, "lng": -70.67041931252714},
                {"lat": -33.36814961112999, "lng": -70.67076694607094},
                {"lat": -33.37032703993043, "lng": -70.67089441170369},
                {"lat": -33.37105372286769, "lng": -70.67092773914867},
                //Limites con conchali
                {"lat": -33.36895805249728, "lng": -70.6765336139535},
                {"lat": -33.3667630481999, "lng": -70.68279136357559},
                {"lat": -33.36617770436763, "lng": -70.6887237102173},
            ]
        },
        {
            idService: 65,
            nameService: "Huechuraba 2 / Hasta Recoleta",
            coordinates: [
                //Limites con pedro fontova
                {"lat": -33.337702085083684, "lng": -70.66503889379123},
                {"lat": -33.33820912798577, "lng": -70.66707205780826},
                {"lat": -33.33871740054387, "lng": -70.66747517751915},
                {"lat": -33.33914062338492, "lng": -70.66764722704805},
                {"lat": -33.340042959556676, "lng": -70.66799132610589},
                {"lat": -33.34152820050707, "lng": -70.6685648245356},
                {"lat": -33.34320936902595, "lng": -70.66920912567063},
                {"lat": -33.34360626271064, "lng": -70.66931341573378},
                {"lat": -33.344835653619675, "lng": -70.669637873708},
                {"lat": -33.34612310738256, "lng": -70.6701824995933},
                {"lat": -33.34625862772392, "lng": -70.67019408737809},
                {"lat": -33.34635542793593, "lng": -70.67024043859921},
                {"lat": -33.34642318795218, "lng": -70.67029837752317},
                {"lat": -33.347923574800696, "lng": -70.67021726302963},
                {"lat": -33.34943361545817, "lng": -70.67029837752317},
                {"lat": -33.35147600064051, "lng": -70.67020567526555},
                {"lat": -33.35211484112364, "lng": -70.67018249969597},
                {"lat": -33.35218259665746, "lng": -70.67027520197432},
                {"lat": -33.352211634727254, "lng": -70.67040266760705},
                {"lat": -33.35267624252647, "lng": -70.67042584317663},
                {"lat": -33.355654954901055, "lng": -70.67043090019708},
                {"lat": -33.35803594946929, "lng": -70.6703613734883},
                {"lat": -33.36140407454737, "lng": -70.67037296131265},
                {"lat": -33.36446237403097, "lng": -70.67037296129668},
                {"lat": -33.366862483088696, "lng": -70.67032661015749},
                {"lat": -33.367481855378664, "lng": -70.67041931252714},
                {"lat": -33.36814961112999, "lng": -70.67076694607094},
                {"lat": -33.37032703993043, "lng": -70.67089441170369},
                {"lat": -33.37105372286769, "lng": -70.67092773914867},
                //Limite con conchali y recoleta
                {"lat": -33.38151334313955, "lng": -70.64376234792692},
                {"lat": -33.38330509476764, "lng": -70.6406724433125},
                {"lat": -33.39411564654168, "lng": -70.62507560079854},
                {"lat": -33.39604397718701, "lng": -70.62037165929213},
                {"lat": -33.4033418513705, "lng": -70.6101202275324},
                //Limites con vitacura
                {"lat": -33.40327395525945, "lng": -70.6101689218196},
                {"lat": -33.395522001316884, "lng": -70.60748257831595},
                {"lat": -33.395522001316884, "lng": -70.60748257831595},
                {"lat": -33.39362047303867, "lng": -70.60555541884594},
                {"lat": -33.39215773066888, "lng": -70.60502982989958},
                {"lat": -33.391922792117505, "lng":-70.6038989986302},
                {"lat": -33.38907204918524, "lng": -70.60197283654928},
                {"lat": -33.38670748561816, "lng": -70.60176044150597},
                {"lat": -33.38558596281644, "lng": -70.6026188221276},
                {"lat": -33.38558596281644, "lng": -70.6019764356808},
                {"lat": -33.38263601289884, "lng": -70.60560339908777},
                {"lat": -33.38274548393051, "lng": -70.60730770967733},
                {"lat": -33.380534665533915, "lng": -70.60966267724038},
                {"lat": -33.380096769465034, "lng": -70.60926937481288},
                {"lat": -33.3799325578707, "lng": -70.60756506429374},
                {"lat": -33.38069887599124, "lng": -70.60599185458375},
                {"lat": -33.37779777892629, "lng": -70.60481194730127},
                {"lat": -33.37752408552812, "lng": -70.60717176186624},
                {"lat": -33.37681247866184, "lng": -70.60789281631666},
                {"lat": -33.37610086597299, "lng": -70.60671290903417},
                {"lat": -33.375717687498074, "lng": -70.60645070741583},
                {"lat": -33.37549872761161, "lng": -70.60553300175168},
                {"lat": -33.374130215832274, "lng": -70.60363204001878},
                {"lat": -33.37276168252069, "lng": -70.6031731871867},
                {"lat": -33.37276168252069, "lng": -70.60572965296542},
                {"lat": -33.370571984436175, "lng": -70.60926937481288},
                {"lat": -33.370517241277845, "lng": -70.61051483249994},
                {"lat": -33.370134038204874, "lng": -70.61143253816411},
                {"lat": -33.370955185579966, "lng": -70.61130143735495},
                {"lat": -33.372652198925586, "lng": -70.6145134071795},
                {"lat": -33.37374702867547, "lng": -70.61569331446199},
                {"lat": -33.3736375463206, "lng": -70.61713542336281},
                {"lat": -33.37522502697835, "lng": -70.62047849399653},
                {"lat": -33.37538924746166, "lng": -70.62218280451567},
                {"lat": -33.375881907051195, "lng": -70.62251055653859},
                {"lat": -33.3755534676349, "lng": -70.62296940937067},
                {"lat": -33.3755534676349, "lng": -70.62349381260732},
                {"lat": -33.37544398755386, "lng": -70.6239526654394},
                {"lat": -33.3755534676349, "lng": -70.62421486705773},
                {"lat": -33.375006065851586, "lng": -70.62559142555398},
                {"lat": -33.37440391991073, "lng": -70.62559142555398},
                {"lat": -33.37380176980121, "lng": -70.62723018566854},
                {"lat": -33.37336383983053, "lng": -70.62703353445478},
                {"lat": -33.372597457076346, "lng": -70.62841009295103},
                {"lat": -33.371721582803396, "lng": -70.62886894578311},
                {"lat": -33.37084569971114, "lng": -70.62873784497394},
                {"lat": -33.369750833443874, "lng": -70.62834454254644},
                {"lat": -33.36969608976879, "lng": -70.62768903850062},
                {"lat": -33.36865595339688, "lng": -70.62690243364563},
                {"lat": -33.368272742125015, "lng": -70.6277545889052},
                {"lat": -33.36776642952476, "lng": -70.62810196949958},
                {"lat": -33.364379594852984, "lng": -70.62637173121786},
                {"lat": -33.36257322912192, "lng": -70.62658801100308},
                {"lat": -33.358740772416375, "lng": -70.63013921332347},
                {"lat": -33.3582823495373, "lng": -70.63086989931006},
                {"lat": -33.358237187686846, "lng": -70.63232978786026},
                {"lat": -33.359366226917665, "lng": -70.63438444581979},
                {"lat": -33.35895977448191, "lng": -70.63427630592719},
                {"lat": -33.35850815843805, "lng": -70.63460072560501},
                {"lat": -33.358914612982986, "lng": -70.63541177479956},
                {"lat": -33.3590049359574, "lng": -70.63633096388672},
                {"lat": -33.36022428693707, "lng": -70.6360606141552},
                {"lat": -33.363611283348774, "lng": -70.63984551039644},
                {"lat": -33.3669078332496, "lng": -70.6407646994836},
                {"lat": -33.3668107289922, "lng": -70.64141359564077},
                {"lat": -33.36699135845567, "lng": -70.64146766558707},
                {"lat": -33.36708167304677, "lng": -70.64119731585556},
                {"lat": -33.36708167304677, "lng": -70.64087289617774},
                {"lat": -33.36798481380136, "lng": -70.64103510601664},
                {"lat": -33.368029970592985, "lng": -70.64265720440577},
                {"lat": -33.37064902447002, "lng": -70.64422523313925},
                {"lat": -33.37137150819686, "lng": -70.64509035228009},
                {"lat": -33.367126830373785, "lng": -70.65109211631979},
                {"lat": -33.36541083800191, "lng": -70.65882411864119},
                {"lat": -33.36396576554384, "lng": -70.65828341917816},
                {"lat": -33.36364965274445, "lng": -70.6558502715945},
                {"lat": -33.36274646698966, "lng": -70.6543903830443},
                {"lat": -33.36148199118353, "lng": -70.65471480272213},
                {"lat": -33.36112070900695, "lng": -70.65795899950034},
                {"lat": -33.35850136837047, "lng": -70.65758050987621},
                {"lat": -33.35854653008384, "lng": -70.65455259288322},
                {"lat": -33.356212897507206, "lng": -70.65079526699853},
                {"lat": -33.356212897507206, "lng": -70.64983185011465},
                {"lat": -33.35573006921358, "lng": -70.65021721686821},
                {"lat": -33.35404014909389, "lng": -70.65069892531014},
                {"lat": -33.3565580315945, "lng": -70.66015028951632},
                {"lat": -33.3561200148249, "lng": -70.659888087898},
                {"lat": -33.35622951922396, "lng": -70.65962588627967},
                {"lat": -33.355791500801125, "lng": -70.659101483043},
                {"lat": -33.35431317235024, "lng": -70.65923258385217},
                {"lat": -33.3535466217873, "lng": -70.66264120489048},
                {"lat": -33.35127878788881, "lng": -70.66349484357305},
                {"lat": -33.35109812581076, "lng": -70.66344077362675},
                {"lat": -33.34951731663517, "lng": -70.66419775287501},
                {"lat": -33.34825264862762, "lng": -70.66371112335827},
                {"lat": -33.34811714739439, "lng": -70.66322449384154},
                {"lat": -33.34775580974142, "lng": -70.66300821405632},
                {"lat": -33.34739447058885, "lng": -70.66306228400263},
                {"lat": -33.34671695563587, "lng": -70.66235937470069},
                {"lat": -33.3458135941644, "lng": -70.66160239545242},
                {"lat": -33.34554258389535, "lng": -70.66111576593569},
                {"lat": -33.343238962552405, "lng": -70.66084541620418},
                {"lat": -33.34256141526545, "lng": -70.66295414411002},
                {"lat": -33.33845084872567, "lng": -70.66344077362675},
            ]
        },
    ]
};

export default Huechuraba;
