const LoBarnechea = {
    commune: "Lo Barnechea",
    polygons: [
        {
            idService: 59,
            nameService: "Lo Barnechea",
            coordinates: [

                {"lat":-33.33891834471685, "lng":-70.4919735442668},
                {"lat":-33.34115673641141, "lng":-70.49202457950001},
                {"lat":-33.34268094172201, "lng":-70.48958764712229},
                {"lat":-33.343448363627246, "lng":-70.48981730567165},  
                {"lat":-33.344546191539486, "lng":-70.49067214582763},
                {"lat":-33.345580055656356, "lng":-70.49068490463594},
                {"lat":-33.35051935961234, "lng": -70.49097980849007},
                {"lat":-33.35362672295346, "lng":-70.48949833546119},
                {"lat":-33.358305171505165, "lng":-70.49104215126525}, // pie andino con valle escondido
                {"lat":-33.35942412543809, "lng":-70.49111870411144},
                {"lat":-33.36113982672376, "lng":-70.49222872043339}, // ini pie andino
                {"lat":-33.361246390905286, "lng":-70.49244562017445},
                {"lat":-33.36183249157106, "lng":-70.49318563105574},
                {"lat":-33.363121899140445, "lng":-70.49345356603}, // Plaza
                {"lat":-33.36356435084084, "lng":-70.49401758574903},
                {"lat":-33.369714906686866, "lng":-70.5048156636182},
                {"lat":-33.37176426316132, "lng":-70.51730188999476},
                {"lat":-33.36983545840416, "lng":-70.52011670403338},
                {"lat":-33.36881076348219, "lng":-70.52257064447734},
                {"lat":-33.3678463337066, "lng":-70.52444718716976},
                {"lat":-33.36772577923293, "lng":-70.52581850682961},
                {"lat":-33.36887103998818, "lng":-70.5279837483978},
                {"lat":-33.368750486934445, "lng":-70.53202553265842},
                {"lat":-33.36790661088081, "lng":-70.532530755691},
                {"lat":-33.366218834217186, "lng":-70.53534556972963},
                {"lat":-33.365616048902574, "lng":-70.53476817197811},
                {"lat":-33.36471186310044, "lng":-70.53520122029175},
                {"lat":-33.36501325941184, "lng":-70.54184129443419},
                {"lat":-33.3641090673456, "lng":-70.54443958431602},
                {"lat":-33.366701059462045, "lng":-70.54480045791071},
                {"lat":-33.3664599471737, "lng":-70.5458830786948},
                {"lat":-33.3641090673456, "lng":-70.54465610847284},
                {"lat":-33.366701059339995, "lng":-70.54516133106408},
                {"lat":-33.366399668875175, "lng":-70.54602742769136},
                {"lat":-33.36368710771041, "lng":-70.54898659116787},
                {"lat":-33.36133615296458, "lng":-70.55086313386032},
                {"lat":-33.36025107550578, "lng":-70.55281185127168},
                {"lat":-33.35868371751319, "lng":-70.55151270633077},
                {"lat":-33.36061276949535, "lng":-70.54819266925955},
                {"lat":-33.36000994534425, "lng":-70.54725439791332},
                {"lat":-33.35458434007925, "lng":-70.54783179566485},
                {"lat":-33.35446376723187, "lng":-70.54689352431863},
                {"lat":-33.349158396604366, "lng":-70.54826484397849},
                {"lat":-33.34734967362642, "lng":-70.55115183273607},
                {"lat":-33.34807316732663, "lng":-70.55201792936334},
                {"lat":-33.34813345819694, "lng":-70.55519361699668},
                {"lat":-33.34523944932853, "lng":-70.5557710147482},
                {"lat":-33.34391299645063, "lng":-70.55707015968912},
                {"lat":-33.34361152706949, "lng":-70.56255543832852},
                {"lat":-33.342104164508605, "lng":-70.56573112596186},
                {"lat":-33.34198357437657, "lng":-70.56681374674595},
                {"lat":-33.339812923445734, "lng":-70.56717462034065},
                {"lat":-33.34005410955421, "lng":-70.56573112596186},
                {"lat":-33.339330549225025, "lng":-70.56537025236716},
                {"lat":-33.33776281455665, "lng":-70.5665250478702},
                {"lat":-33.33498906101615, "lng":-70.56565895124292},
                {"lat":-33.33480816097804, "lng":-70.56306066136109},
                {"lat":-33.33565235794174, "lng":-70.56183369113911},
                {"lat":-33.33082826509516, "lng":-70.55432752036941},
                {"lat":-33.328777944743294, "lng":-70.54869789229213},
                {"lat":-33.32419470077182, "lng":-70.55216227870453},
                {"lat":-33.324677158806715, "lng":-70.55338924892649},
                {"lat":-33.32684818690888, "lng":-70.55223445342347},
                {"lat":-33.32721001966711, "lng":-70.55353359836438},
                {"lat":-33.3249183868225, "lng":-70.55642058712196},
                {"lat":-33.324255008172265, "lng":-70.55634841240303},
                {"lat":-33.32244576800458, "lng":-70.5593797505985},
                {"lat":-33.32111896800963, "lng":-70.55995714835},
                {"lat":-33.32117927753852, "lng":-70.56327718542121},
                {"lat":-33.32479777289808, "lng":-70.56565895114623},
                {"lat":-33.324315315530974, "lng":-70.56869028934169},
                {"lat":-33.32292823572194, "lng":-70.57049465731518},
                {"lat":-33.32039525040786, "lng":-70.57107205506668},
                {"lat":-33.318284373077695, "lng":-70.56977291012579},
                {"lat":-33.31641469617961, "lng":-70.573092947197},
                {"lat":-33.31454497917866, "lng":-70.57121640450457},
                {"lat":-33.313941836107716, "lng":-70.57533036348413},
                {"lat":-33.311710170462305, "lng":-70.57431991741898},
                {"lat":-33.31345931864647, "lng":-70.5692676870932},
                {"lat":-33.31496717684547, "lng":-70.56919551237426},
                {"lat":-33.31635438335392, "lng":-70.56601982474093},
                {"lat":-33.31786219147709, "lng":-70.56241108879394},
                {"lat":-33.31991276866805, "lng":-70.56140064272878},
                {"lat":-33.32009369963348, "lng":-70.55930757587954},
                {"lat":-33.318284373077695, "lng":-70.54768744613027},
                {"lat":-33.31689719728286, "lng":-70.55115183263938},
                {"lat":-33.318163749971895, "lng":-70.55194575454772},
                {"lat":-33.318043126699166, "lng":-70.55346142364543},
                {"lat":-33.31683688479101, "lng":-70.55439969499166},
                {"lat":-33.31599250552341, "lng":-70.55201792926665},
                {"lat":-33.31249427571215, "lng":-70.55216227870453},
                {"lat":-33.31177048650111, "lng":-70.54768744613027},
                {"lat":-33.31303711367715, "lng":-70.54321261355602},
                {"lat":-33.31635438335392, "lng":-70.54176911917723},
                {"lat":-33.32087772947677, "lng":-70.5398925764848},
                {"lat":-33.31979214787779, "lng":-70.5389543054477},
                {"lat":-33.32036483164826, "lng":-70.53751081090203},
                {"lat":-33.31921893275525, "lng":-70.53686123843158},
                {"lat":-33.31897768896335, "lng":-70.53577861764751},
                {"lat":-33.31903799997392, "lng":-70.53592296708537},
                {"lat":-33.319249088182296, "lng":-70.53548991877173},
                {"lat":-33.32157102473354, "lng":-70.53628384068007},
                {"lat":-33.32458643441231, "lng":-70.52841679631567},
                {"lat":-33.32579256906925, "lng":-70.52737026289104},
                {"lat":-33.32585287536387, "lng":-70.52552980755809},
                {"lat":-33.32775250228045, "lng":-70.52408631317928},
                {"lat":-33.32790326458392, "lng":-70.51953930601276},
                {"lat":-33.323229513004776, "lng":-70.5197558301696},
                {"lat":-33.32208365177611, "lng":-70.51860103466656},
                {"lat":-33.32087746578551, "lng":-70.51780711275822},
                {"lat":-33.32338028309746, "lng":-70.51170834900783},
                {"lat":-33.3254005771732, "lng":-70.51077007766162},
                {"lat":-33.329380720997406, "lng":-70.50784700154458},
                {"lat":-33.32941087293685, "lng":-70.50763047760022},
                {"lat":-33.32778265481964, "lng":-70.50503218771838},
                {"lat":-33.324676895155555, "lng":-70.50366086805853},
                {"lat":-33.32407382219277, "lng":-70.5021451989608},
                {"lat":-33.32515935052083, "lng":-70.49269031077972},
                {"lat":-33.325671956419704, "lng":-70.4917520394335},
                {"lat":-33.326455936079455, "lng":-70.4913911658388},
                {"lat":-33.32696853435102, "lng":-70.4915355152767},
                {"lat":-33.32576241597119, "lng":-70.49994387003315},
                {"lat":-33.32612425323807, "lng":-70.50099040345778},
                {"lat":-33.3281444836974, "lng":-70.50167606328769},
                {"lat":-33.32901889728203, "lng":-70.50102649081725},
                {"lat":-33.329139505363635, "lng":-70.50088214137936}, 
                {"lat":-33.33010436400621, "lng":-70.5004851804252},
                {"lat":-33.3303455769973, "lng":-70.50099040345778},
                {"lat":-33.3318531430611, "lng":-70.50127910233353},
                {"lat":-33.332546614690315, "lng":-70.50207302424187},
                {"lat":-33.33333053249354, "lng":-70.50192867480399},
                {"lat":-33.335471195176055, "lng":-70.5025060725555},
                {"lat":-33.33619478756663, "lng":-70.50077387930095},
                {"lat":-33.33532044600477, "lng":-70.49886124924906},
                {"lat":-33.33728016511218, "lng":-70.49514425137522},
                {"lat":-33.337099269832194, "lng":-70.49388119379378},
                {"lat":-33.337129419071616, "lng":-70.49312335924492},
                {"lat":-33.33821478474049, "lng":-70.49287074772863},


            ]
        },

        {
            idService: 72,
            nameService: "El Arrayan",
            coordinates: [

                { "lat": -33.368237796245744, "lng": -70.50166108840678 }, //las condes con quinchamali
                { "lat": -33.36357449951662,  "lng": -70.49415052023419 }, // Plaza san enrique
                { "lat": -33.36220015961394,  "lng": -70.49334649053843 },
                { "lat": -33.361028437694515, "lng": -70.49240821919524 }, // cerro 1
                { "lat": -33.360662977788735, "lng": -70.49174962489256 },
                { "lat": -33.36034272815833,  "lng": -70.49165940650111 },
                { "lat": -33.3599358210385,   "lng": -70.49134364210148 },
                { "lat": -33.359268941388486, "lng": -70.49098276852435 },
                { "lat": -33.358831887534365, "lng": -70.49100983403764 },
                { "lat": -33.3584626506702,   "lng": -70.49083390815626 },
                { "lat": -33.35760737151692,  "lng": -70.49056776387812 },
                { "lat": -33.35685758243986,  "lng": -70.49059482940012 },
                { "lat": -33.356107786902996, "lng": -70.48998134430887 },
                { "lat": -33.35273996429692,  "lng": -70.48883419145243 },
                { "lat": -33.34972560449563,  "lng": -70.49100993433798 },
                { "lat": -33.33866564032407,  "lng": -70.49038766183558 },
                { "lat": -33.32632607131169,  "lng": -70.48032989714798 },
                { "lat": -33.32808536518135,  "lng": -70.47137441299978 },
                { "lat": -33.334567882339336, "lng": -70.47101353942263 },
                { "lat": -33.33113070072197,  "lng": -70.45971819603356 },
                { "lat": -33.34456352240545,  "lng": -70.46124120625288 },
                { "lat": -33.34848250025667,  "lng": -70.46925259986081 },
                { "lat": -33.370998130042686, "lng": -70.46369514684643 }, // clinica monteblanco
                { "lat": -33.373028991594005, "lng": -70.49346556527823 },
                { "lat": -33.3729845037856,   "lng": -70.49384169466398 }, // con las añañucas
                { "lat": -33.37294683275605,  "lng": -70.49434466222229 },
                { "lat": -33.37294306565246,  "lng": -70.49464689385994 },
                { "lat": -33.37295625051553,  "lng": -70.4950889640069  },// con el amancai
                { "lat": -33.373037243203015, "lng": -70.49555809966937 },
                { "lat": -33.373044777402484, "lng": -70.49565959536294 },
                { "lat": -33.373034417878046, "lng": -70.49576334651638 },
                { "lat": -33.3730052228479,   "lng": -70.49586596994979 },
                { "lat": -33.37296001761981,  "lng": -70.49599678662648 },
                { "lat": -33.3729053946035,   "lng": -70.49608926048673 },
                { "lat": -33.37284512089291,  "lng": -70.49616932931164 },
                { "lat": -33.37276130269148,  "lng": -70.49624601495228 },
                { "lat": -33.37259302395135,  "lng": -70.49633397788851 },
                { "lat": -33.37234439372904,  "lng": -70.49649186008455 },
                { "lat": -33.37203172138206,  "lng": -70.49690686470781 },
                { "lat": -33.37173788368828,  "lng": -70.49733991302655 },
                { "lat": -33.37141390764979,  "lng": -70.4976692101768  },
                { "lat": -33.37105225855257,  "lng": -70.49786317973249 },
                { "lat": -33.37075088314737,  "lng": -70.49789926709765 },
                { "lat": -33.37027998200874,  "lng": -70.4977549176668  },
                { "lat": -33.3700200434741,   "lng": -70.49778649411473 },
                { "lat": -33.36979024210628,  "lng": -70.49801655102016 },
                { "lat": -33.369673457565824, "lng": -70.49836840278942 }, // con puangue
                { "lat": -33.36951146585202,  "lng": -70.49959537298163 }, // quinchamali con pomaire
                { "lat": -33.36862992426898,  "lng": -70.5014313173475  },

            ]
        },
        {
            idService: 160,
            nameService: "Camino Farellones",
            coordinates: [
                { "lat":-33.39076114212342   , "lng": -70.34159439296707 },
                { "lat":-33.34902950899811   , "lng": -70.36286931750003 },
                { "lat":-33.34646254233941   , "lng": -70.36347388526907 },
                { "lat":-33.343522290375994  , "lng": -70.3657453721585  },
                { "lat": -33.370998130042686 , "lng": -70.46369514684643 }, // clinica monteblanco
                { "lat": -33.40659253225213  , "lng": -70.43504302967204 },
            ]
        },
        {
            idService: 67,
            nameService: "Farellones",
            coordinates: [
                { "lat": -33.379514425881716 , "lng": -70.20102097303078 },
                { "lat": -33.299778148292624 , "lng": -70.21149231540944 },
                { "lat": -33.370998130042686 , "lng": -70.46369514684643 }, // clinica monteblanco
                { "lat": -33.40659253225213  , "lng": -70.43504302967204 },
            ]
        },
    ]
};

export default LoBarnechea;