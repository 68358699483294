const Algarrobo = {
    commune: "Curacaví",
    polygons: [
        {
            idService: 152,
            nameService: "CURACAVI Pueblo",
            coordinates: [
                { "lat": -33.40162227274098,  "lng": -71.17788130148051 },
                { "lat": -33.39677916228889,  "lng": -71.1703943655584  },
                { "lat": -33.39688265036259,  "lng": -71.15851939083555 },
                { "lat": -33.3811615741075,   "lng": -71.12870867630177 },
                { "lat": -33.38500165714394,  "lng": -71.12268590219448 },
                { "lat": -33.392536288794,    "lng": -71.11663685462827 },
                { "lat": -33.40205714217945,  "lng": -71.11743017272684 },
                { "lat": -33.40327172708635,  "lng": -71.11961136898559 },
                { "lat": -33.40792532289118,  "lng": -71.12339785124632 },
                { "lat": -33.41897792385381,  "lng": -71.12441688153719 },
                { "lat": -33.42986650759422,  "lng": -71.15334182241962 },
                { "lat": -33.42112709460605,  "lng": -71.1633840125776  },
                { "lat": -33.41579080828188,  "lng": -71.15989970724671 },
                { "lat": -33.40949261095485,  "lng": -71.17132893536042 },
                { "lat": -33.40161088344654,  "lng": -71.1779124804382  },
            ]
        },
        {
            idService: 153,
            nameService: "CURACAVI 2 SECTOR PARCELA /LOS NARANJOS/LA RESERVA/EL TORO",
            coordinates: [
                { "lat": -33.392536288794,    "lng": -71.11663685462827 },
                { "lat": -33.38500165714394,  "lng": -71.12268590219448 },
                { "lat": -33.3811615741075,   "lng": -71.12870867630177 },
                { "lat": -33.39688265036259,  "lng": -71.15851939083555 },
                { "lat": -33.39677916228889,  "lng": -71.1703943655584  },
                { "lat": -33.40162227274098,  "lng": -71.17788130148051 },
                { "lat": -33.37466940665818,  "lng": -71.19537695552533 },
                { "lat": -33.3682171540554,   "lng": -71.19166247534204 },
                { "lat": -33.36772080714485,  "lng": -71.17353581403803 },
                { "lat": -33.345784721383296, "lng": -71.1478515604228  },
                { "lat": -33.34551155054688,  "lng": -71.14572416955542 },
                { "lat": -33.35152773319625,  "lng": -71.12884836050654 },
                { "lat": -33.37301170522478,  "lng": -71.08011384309656 },
            ]
        },
    ]
};

export default Algarrobo;
