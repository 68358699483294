import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';

import error from '../../../pages/images/error.png';
import success from '../../../pages/images/success.png';
import Cookies from 'universal-cookie';

class EditBackAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalBankAccountIsOpen: false,
			bankAccTypes: [],
			banksChile: [],
			optionsStateTypeAccount: '',
			optionsStateBanco: '',
			nroAccountState: '',
			updateAccountstate: false,
			rut: this.props.data.rut,
			nombre: this.props.data.nombre,
			apellido: this.props.data.apellido,
			bankaccount_nro: this.props.data.bankaccount_nro,
			bankaccount_type: this.props.data.bankaccount_type,
			bankaccount_bankcode: this.props.data.bankaccount_bankcode,
			banconombre: this.props.data.banconombre,
			tipocuenta: this.props.data.tipocuenta,
		};
		this.openModalEdit = this.openModalEdit.bind(this);
		this.closeModalEdit = this.closeModalEdit.bind(this);
		this.getTiposCuentaBanco = this.getTiposCuentaBanco.bind(this);
		this.updateBankAccount = this.updateBankAccount.bind(this);
		this.handleChangeTiposCuenta = this.handleChangeTiposCuenta.bind(this);
		this.handleChangeBanco = this.handleChangeBanco.bind(this);
		this.handleChangeNroCuenta = this.handleChangeNroCuenta.bind(this);
		this.handleChangePPU = this.handleChangePPU.bind(this);
	}

	getBancosChile() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const banksChileData = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getBancosChile`,
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				for (const dato of response.data) {
					banksChileData.push(
						<option value={dato.bancocode_bestado}>{dato.banconombre}</option>
					);
				}
				this.setState({ banksChile: banksChileData });
				//console.log(banksChileData);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getTiposCuentaBanco() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const bankAccTypesSelect = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getTiposCuentaBanco`,
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				for (const dato of response.data) {
					bankAccTypesSelect.push(
						<option value={dato.tipocuentacodigo}>{dato.tipocuenta}</option>
					);
				}
				this.setState({ bankAccTypes: bankAccTypesSelect });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleChangeTiposCuenta(event) {
		this.setState({ optionsStateTypeAccount: event.target.value });
	}

	handleChangeBanco(event) {
		this.setState({ optionsStateBanco: event.target.value });
	}

	handleChangeNroCuenta(event) {
		this.setState({ nroAccountState: event.target.value });
	}

	handleChangePPU(event) {
		this.setState({ ppu: event.target.value });
	}

	updateBankAccount() {
		const bancocode = this.state.optionsStateBanco;
		const tipocuentacode = this.state.optionsStateTypeAccount;
		const nrocuentaedit = this.state.nroAccountState;

		const cookies = new Cookies();
		const token = cookies.get('token');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateBankAccount`,
			data: JSON.stringify({
				bankaccount_nro: nrocuentaedit,
				bankaccount_type: tipocuentacode,
				bankaccount_bankcode: bancocode,
				rut: this.state.rut,
				ppu: this.state.ppu,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				if (parseInt(response.data) > 0) {
					this.setState({ updateAccountstate: true });
				} else {
					this.setState({ updateAccountstate: false });
				}
				document.getElementById('EditBankAccountModal').style.display = 'none';
				document.getElementById('EditBankAccountModalFinal').style.display =
					'block';
				setTimeout(() => {
					//this.closeModalEdit();
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	openModalEdit() {
		const tipocuentacode =
			this.state.bankaccount_type !== '' ? this.state.bankaccount_type : '00';
		const bancocode =
			this.state.bankaccount_bankcode !== ''
				? this.state.bankaccount_bankcode
				: '';
		const nroaccount = this.state.bankaccount_nro;
		this.getBancosChile();
		this.getTiposCuentaBanco();

		this.setState({
			modalBankAccountIsOpen: true,
			optionsStateTypeAccount: tipocuentacode,
			optionsStateBanco: bancocode,
			nroAccountState: nroaccount,
		});
	}

	closeModalEdit() {
		this.setState({ modalBankAccountIsOpen: false });
	}

	render() {
		return (
			<div>
				<button className="btnAccountToolsClass" onClick={this.openModalEdit}>
					Editar
				</button>
				{/* MODAL */}

				<Modal
					isOpen={this.state.modalBankAccountIsOpen}
					onRequestClose={this.closeModalEdit}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Imprimir ticket"
				>
					<div id="EditBankAccountModal">
						<div className="headermodal">
							<button onClick={this.closeModalEdit}>
								<i class="nc-icon nc-simple-remove"></i>
							</button>
						</div>
						<div className="containerEditBankAccountModal">
							<h4>
								Cuenta de {this.state.nombre} {this.state.apellido}
							</h4>
							<div id="msgErrorPetAnul"></div>
							<br />
							<div className="form-group">
								<label class="my-1 mr-2">Banco:</label>
								<select
									class="custom-select my-1 mr-sm-2"
									id="SelectBancosChileModal"
									onChange={this.handleChangeBanco}
									value={this.state.optionsStateBanco}
								>
									<option value="00" selected>
										Selecciona...
									</option>
									{this.state.banksChile}
								</select>
							</div>
							<div className="form-group">
								<label class="my-1 mr-2">Tipo de cuenta:</label>
								<select
									class="custom-select my-1 mr-sm-2"
									id="SelectAccountTypeModal"
									onChange={this.handleChangeTiposCuenta}
									value={this.state.optionsStateTypeAccount}
								>
									<option value="00" selected>
										Selecciona...
									</option>
									{this.state.bankAccTypes}
								</select>
							</div>
							<div class="form-group">
								<label>Nro. de cuenta</label>
								<input
									type="text"
									class="form-control"
									id="nroCuentaEdit"
									placeholder=""
									onChange={this.handleChangeNroCuenta}
									value={this.state.nroAccountState}
								/>
							</div>
							<div class="form-group">
								<label>PPU</label>
								<input
									type="text"
									class="form-control"
									id="ppuAsociadoEdit"
									placeholder=""
									onChange={this.handleChangePPU}
									value={this.state.ppu}
								/>
							</div>
							<div className="centerButtonContainer">
								<input
									type="button"
									className="btn btn-inactivo btn-round"
									onClick={this.updateBankAccount}
									value="Guardar"
								/>
								<input
									type="button"
									className="btn btn-inactivo btn-round"
									onClick={this.closeModalEdit}
									value="Cancelar"
								/>
							</div>
						</div>
					</div>
					<div id="EditBankAccountModalFinal">
						{this.state.updateAccountstate ? (
							<div className="cuadraturaResponse">
								<img src={success} alt="success" />
								<p>Cuenta actualizada.</p>
							</div>
						) : (
							<div className="cuadraturaResponse">
								<img src={error} alt="error" />
								<p>Error al actualizar la cuenta</p>
							</div>
						)}
					</div>
				</Modal>

				{/* FIN MODAL */}
			</div>
		);
	}
}

Modal.setAppElement('body');

export default EditBackAccount;
