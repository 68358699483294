const Olmue = {
    commune: "Olmue",
        polygons: [
        {
            idService: 89,
            nameService: "Quillota / Limache / Olmué",
            coordinates: [
                {"lat":-32.980350278986606, "lng":-71.2843328556236},
                {"lat":-32.98047136748034, "lng":-71.28361110845015},
                {"lat":-32.985474381764384, "lng":-71.28663589621907},
                {"lat":-32.98588164898685, "lng":-71.28578621576384},
                {"lat":-32.98852884013269, "lng":-71.28724281082995},
                {"lat":-32.99046327576471, "lng":-71.28517930115297},
                {"lat":-32.991888622258976, "lng":-71.28578621576384},
                {"lat":-32.99280490427823, "lng":-71.28420823777554},
                {"lat":-32.989954217866746, "lng":-71.28202334517638},
                {"lat":-32.99260128687386, "lng":-71.27643973075628},
                {"lat":-32.99813678958438, "lng":-71.2769827181938},
                {"lat":-33.002591794147136, "lng":-71.28385133138087},
                {"lat":-33.00644375409038, "lng":-71.2858927406635},
                {"lat":-33.0067861423895, "lng":-71.28558652927111},
                {"lat":-33.007385318714896, "lng":-71.2858927406635},
                {"lat":-33.00832687328835, "lng":-71.28385133138087},
                {"lat":-33.00336219959194, "lng":-71.27793124446129},
                {"lat":-33.00473179265917, "lng":-71.2746649896091},
                {"lat":-33.00652935128975, "lng":-71.27558362378628},
                {"lat":-33.007128529359555, "lng":-71.27221529846996},
                {"lat":-33.00961079514475, "lng":-71.27150080522104},
                {"lat":-33.01654365018496, "lng":-71.27507327146563},
                {"lat":-33.0174851069811, "lng":-71.27405256682431},
                {"lat":-33.0190256509636, "lng":-71.27446084868085},
                {"lat":-33.022277822082394, "lng":-71.27578776471452},
                {"lat":-33.02480332576763, "lng":-71.27875311043086},
                {"lat":-33.02732700832685, "lng":-71.2758135170958},
                {"lat":-33.025887769236256, "lng":-71.27306693509928},
                {"lat":-33.02603169420289, "lng":-71.27220862822537},
                {"lat":-33.02372886653504, "lng":-71.2670587869819},
                {"lat":-33.02121007988668, "lng":-71.26723044835668},
                {"lat":-33.02329707964953, "lng":-71.26096480817714},
                {"lat":-33.02351297335667, "lng":-71.25650161243281},
                {"lat":-33.0246643975311, "lng":-71.25615828968324},
                {"lat":-33.02423261522825, "lng":-71.25221007806326},
                {"lat":-33.02149794486046, "lng":-71.25195258600108},
                {"lat":-33.02135401249106, "lng":-71.25255340081283},
                {"lat":-33.019698773352644, "lng":-71.25306838493717},
                {"lat":-33.02077828066352, "lng":-71.25118010981457},
                {"lat":-33.01686417224777, "lng":-71.25049351218568},
                {"lat":-33.015928558473064, "lng":-71.2511216389375},
                {"lat":-33.01420124542174, "lng":-71.25060665481317},
                {"lat":-33.01340954896507, "lng":-71.25180828443665},
                {"lat":-33.011970082651914, "lng":-71.25060665481317},
                {"lat":-33.011466263891215, "lng":-71.25180828443665},
                {"lat":-33.00937898407733, "lng":-71.25026333206361},
                {"lat":-33.01067454288052, "lng":-71.24751675006708},
                {"lat":-33.01801568326453, "lng":-71.24365436913449},
                {"lat":-33.0176971217845, "lng":-71.24145281972211},
                {"lat":-33.015521188279344, "lng":-71.24163651033486},
                {"lat":-33.01602498387309, "lng":-71.24069237277357},
                {"lat":-33.01170664252448, "lng":-71.23880409765096},
                {"lat":-33.008971583626966, "lng":-71.24361061614485},
                {"lat":-33.00702820076175, "lng":-71.24343895477007},
                {"lat":-33.009115536209876, "lng":-71.23888992833834},
                {"lat":-33.00715657180909, "lng":-71.23736590447339},
                {"lat":-33.00492523081433, "lng":-71.24431819015206},
                {"lat":-33.00492523081433, "lng":-71.2486097245216},
                {"lat":-33.007516460231635, "lng":-71.25135630651812},
                {"lat":-33.00629283360379, "lng":-71.25281542820377},
                {"lat":-33.004421371810174, "lng":-71.25135630651812},
                {"lat":-33.00305374000582, "lng":-71.25178545995507},
                {"lat":-33.00053436275845, "lng":-71.25169962926769},
                {"lat":-32.998086898826834, "lng":-71.25985354456982},
                {"lat":-33.003125721155705, "lng":-71.2630292800033},
                {"lat":-33.00252442753634, "lng":-71.26521925805974},
                {"lat":-32.99739761683692, "lng":-71.262133666523},
                {"lat":-32.99624583661543, "lng":-71.2650519098943},
                {"lat":-32.9943741616907, "lng":-71.26427943370777},
                {"lat":-32.99502205365718, "lng":-71.26187617446082},
                {"lat":-32.99523801658895, "lng":-71.26058871414996},
                {"lat":-32.98976679274974, "lng":-71.25775630146605},
                {"lat":-32.988758898731625, "lng":-71.26196200514822},
                {"lat":-32.9878949804129, "lng":-71.26058871414996},
                {"lat":-32.98763367880183, "lng":-71.25827738538956},
                {"lat":-32.99120691866684, "lng":-71.25330394180838},
                {"lat":-32.99260090853523, "lng":-71.24491205664994},
                {"lat":-32.99017943985259, "lng":-71.24000417587041},
                {"lat":-32.986304951775196, "lng":-71.24130332078263},
                {"lat":-32.9848519752388, "lng":-71.23538499346384},
                {"lat":-32.98436764429953, "lng":-71.21965090508243},
                {"lat":-32.986547112600604, "lng":-71.20983514352339},
                {"lat":-32.984488727283455, "lng":-71.19684369440114},
                {"lat":-32.98509413971199, "lng":-71.17504692976269},
                {"lat":-32.983035720515495, "lng":-71.16797380746279},
                {"lat":-32.988484372583436, "lng":-71.15483800890584},
                {"lat":-32.9935694777438, "lng":-71.15007447756102},
                {"lat":-32.996354054032004, "lng":-71.14964142925695},
                {"lat":-33.00010703956367, "lng":-71.15411626173238},
                {"lat":-33.001317646004665, "lng":-71.15960154025068},
                {"lat":-33.00870198555479, "lng":-71.16176678177104},
                {"lat":-33.01802231835764, "lng":-71.153827562863},
                {"lat":-33.02092715577282, "lng":-71.14300135526112},
                {"lat":-33.027946784368964, "lng":-71.13708302843875},
                {"lat":-33.03617598180958, "lng":-71.13491778691838},
                {"lat":-33.039322236553886, "lng":-71.1382378239163},
                {"lat":-33.03895921289465, "lng":-71.14256830695705},
                {"lat":-33.030972313934996, "lng":-71.14993012812633},
                {"lat":-33.03194046145869, "lng":-71.15310581568954},
                {"lat":-33.032424531231825, "lng":-71.1572919492956},
                {"lat":-33.02939905152351, "lng":-71.16537551763832},
                {"lat":-33.032666565121204, "lng":-71.16725206028933},
                {"lat":-33.04041129855588, "lng":-71.16840685576688},
                {"lat":-33.0433153980713, "lng":-71.17172689276477},
                {"lat":-33.044041407989965, "lng":-71.17591302637085},
                {"lat":-33.0413793423659, "lng":-71.17880001506467},
                {"lat":-33.037386093085075, "lng":-71.1785113161953},
                {"lat":-33.0346028123034, "lng":-71.1764904241096},
                {"lat":-33.03278758181659, "lng":-71.17721217128307},
                {"lat":-33.03194046145869, "lng":-71.17908871393405},
                {"lat":-33.033029614708774, "lng":-71.18139830488913},
                {"lat":-33.03653901693743, "lng":-71.18428529358295},
                {"lat":-33.03593395755997, "lng":-71.18832707775432},
                {"lat":-33.031214351813084, "lng":-71.19381235627262},
                {"lat":-33.031214351813084, "lng":-71.19684369440114},
                {"lat":-33.04452541127785, "lng":-71.19713239327052},
                {"lat":-33.04779236390311, "lng":-71.2008854785725},
                {"lat":-33.045009411906065, "lng":-71.20896904691526},
                {"lat":-33.04827634657805, "lng":-71.21113428843562},
                {"lat":-33.05372096838788, "lng":-71.20983514352339},
                {"lat":-33.05904427307885, "lng":-71.21329952995599},
                {"lat":-33.05904427307885, "lng":-71.21661956695391},
                {"lat":-33.056382660968225, "lng":-71.21835176017021},
                {"lat":-33.05626167668756, "lng":-71.22253789377626},
                {"lat":-33.05783445936801, "lng":-71.22224919490688},
                {"lat":-33.05892329245596, "lng":-71.21936220621305},
                {"lat":-33.06122189585896, "lng":-71.21763001299675},
                {"lat":-33.065093092226896, "lng":-71.2184961096049},
                {"lat":-33.067996377761766, "lng":-71.22297094208034},
                {"lat":-33.065093092226896, "lng":-71.22759012399048},
                {"lat":-33.0585603495897, "lng":-71.23004406438024},
                {"lat":-33.05420491847861, "lng":-71.23466324629038},
                {"lat":-33.05601970762743, "lng":-71.24563380332695},
                {"lat":-33.04960728521934, "lng":-71.25357302223499},
                {"lat":-33.0433153980713, "lng":-71.2570374086676},
                {"lat":-33.04004827938451, "lng":-71.26151224114304},
                {"lat":-33.04029029233164, "lng":-71.2710393038327},
                {"lat":-33.04113733241072, "lng":-71.27926722161011},
                {"lat":-33.04016928594118, "lng":-71.2834533552162},
                {"lat":-33.034360783732446, "lng":-71.28547424730186},
                {"lat":-33.02806780754589, "lng":-71.2851855484325},
                {"lat":-33.021169221237095, "lng":-71.2814324631305},
                {"lat":-33.0175381694833, "lng":-71.28172116199988},
                {"lat":-33.0139069681857, "lng":-71.2864846933447},
                {"lat":-33.00567569164433, "lng":-71.28879428429978},
                {"lat":-32.993690548105164, "lng":-71.28879428429978},
                {"lat":-32.98884760409685, "lng":-71.2883612359957},
                {"lat":-32.98597717390646, "lng":-71.28765700134527},
                {"lat":-32.98229556197988, "lng":-71.28694250809635},
                {"lat":-32.98101124262927, "lng":-71.28602387391918},
            ]
        },
    ]
};

export default Olmue;
