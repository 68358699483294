import React from 'react';

import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import error from '../../images/error.png';
import success from '../../images/success.png';

import './BtnUser.css';

class BtnEditUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			idusuario: this.props.data.idusuario,
			nombre: this.props.data.nombre,
			apellido: this.props.data.apellido,
			rut: this.props.data.rut,
			email: this.props.data.email,
			telefono: this.props.data.telefono,
			idperfil: this.props.data.idperfil,
			nombreperfil: this.props.data.nombreperfil,
			active: this.props.data.active,
			modalIsOpen: false,
			editarAction: false,
			perfilesData: [],
		};
		this.editarUsuarioModal = this.editarUsuarioModal.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.editarUsuario = this.editarUsuario.bind(this);

		this.handleNameForm = this.handleNameForm.bind(this);
		this.handleLastNameForm = this.handleLastNameForm.bind(this);
		this.handleRutForm = this.handleRutForm.bind(this);
		this.handleEmailForm = this.handleEmailForm.bind(this);
		this.handleTelefonoForm = this.handleTelefonoForm.bind(this);
		this.handlePerfilForm = this.handlePerfilForm.bind(this);
	}

	getPerfiles() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const perfilesData = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getPerfiles`,
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				for (const perfil of response.data) {
					perfilesData.push(
						<option value={perfil.idperfil}>{perfil.nombreperfil}</option>
					);
				}
				this.setState({ perfilesData: perfilesData });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	editarUsuario() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateUserData`,
			data: JSON.stringify({
				nombre: this.state.nombre,
				apellido: this.state.apellido,
				rut: this.state.rut,
				email: this.state.email,
				telefono: this.state.telefono,
				idperfil: this.state.idperfil,
				idusuario: this.state.idusuario,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				if (parseInt(response.data) > 0) {
					this.setState({ editarAction: true });
				} else {
					this.setState({ editarAction: false });
				}
				document.getElementById('EditUserPaso1').style.display = 'none';
				document.getElementById('EditUserPasoFinal').style.display = 'block';
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	editarUsuarioModal() {
		this.getPerfiles();
		this.setState({ modalIsOpen: true });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	handleNameForm(event) {
		this.setState({ nombre: event.target.value });
	}

	handleLastNameForm(event) {
		this.setState({ apellido: event.target.value });
	}

	handleRutForm(event) {
		this.setState({ rut: event.target.value });
	}

	handleEmailForm(event) {
		this.setState({ email: event.target.value });
	}

	handleTelefonoForm(event) {
		this.setState({ telefono: event.target.value });
	}

	handlePerfilForm(event) {
		this.setState({ idperfil: event.target.value });
	}

	render() {
		return (
			<div>
				<button className="btnClass" onClick={this.editarUsuarioModal}>
					Editar
				</button>
				{/* MODAL */}

				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Imprimir ticket"
				>
					<div id="EditUserPaso1">
						<div className="headermodal">
							<button onClick={this.closeModal}>
								<i class="nc-icon nc-simple-remove"></i>
							</button>
						</div>
						<div className="containerModalUsuarios">
							<h4>Edición de usuario</h4>
							<br />
							<div class="form-group">
								<label for="modalNombre">Nombre</label>
								<input
									type="text"
									class="form-control"
									id="modalNombre"
									onChange={this.handleNameForm}
									value={this.state.nombre}
								/>
							</div>
							<div class="form-group">
								<label for="modalApellido">Apellido</label>
								<input
									type="text"
									class="form-control"
									id="modalApellido"
									onChange={this.handleLastNameForm}
									value={this.state.apellido}
								/>
							</div>
							<div class="form-group">
								<label for="modalRut">Rut</label>
								<input
									type="text"
									class="form-control"
									id="modalRut"
									onChange={this.handleRutForm}
									value={this.state.rut}
								/>
							</div>
							<div class="form-group">
								<label for="modalEmail">E-Mail</label>
								<input
									type="email"
									class="form-control"
									id="modalEmail"
									onChange={this.handleEmailForm}
									value={this.state.email}
								/>
							</div>
							<div class="form-group">
								<label for="modalTelefono">Teléfono</label>
								<input
									type="text"
									class="form-control"
									id="modalTelefono"
									onChange={this.handleTelefonoForm}
									value={this.state.telefono}
								/>
							</div>

							<div class="form-group">
								<label for="modalProfile">Perfil</label>
								<select
									id="modalProfile"
									value={this.state.idperfil}
									onChange={this.handlePerfilForm}
									class="form-control"
								>
									<option value="0">Selecciona.</option>
									{this.state.perfilesData}
								</select>
							</div>
							<div className="containerButtonsModalUsuarios">
								<input
									type="button"
									onClick={this.editarUsuario}
									className="btn btn-inactivo btn-round"
									value="Guardar"
								/>
							</div>
						</div>
					</div>
					<div id="EditUserPasoFinal">
						{this.state.editarAction ? (
							<div className="cuadraturaResponse">
								<img src={success} alt="success" />
								<p>Usuario creado con éxito.</p>
							</div>
						) : (
							<div className="cuadraturaResponse">
								<img src={error} alt="error" />
								<p>Error al crear el usuario.</p>
							</div>
						)}
					</div>
				</Modal>

				{/* FIN MODAL */}
			</div>
		);
	}
}

Modal.setAppElement('body');

export default BtnEditUser;
