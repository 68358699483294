import React, { useState, useEffect } from 'react';
import './FastAccess.css';
import axios from 'axios';
import Cookies from 'universal-cookie';

const FastAccessNew = (props) => {
	const [listRankingDest, setListRankingDest] = useState([]);
	const setVentaData = props.setVentaData;
	const setPayType = props.setPayType;
	const setMoneyType = props.setMoneyType;
	const setDiscToPay = props.setDiscToPay;
	const setAddToPay = props.setAddToPay;
	const selecttarifa = props.selecttarifa;
	const setSelectedOptionService = props.setSelectedOptionService;
	const refDirection = props.refDirection;
	const setCoordinatesPolygon = props.setCoordinatesPolygon;

	useEffect(() => {
		const cookies = new Cookies();
		const headers = { Authorization: 'Bearer ' + cookies.get('token') };
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/rankingCarreras`,
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				setListRankingDest(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const clickFastAccessBTN = (idservice, servicename, valor) => {
		refDirection.current.value = '';
		setCoordinatesPolygon([]);
		setVentaData({
			idservice: idservice,
			servicename: servicename,
			valor: valor,
			valororig: valor,
			valorpesos: valor,
		});
		setSelectedOptionService(selecttarifa.find( option => option.value === idservice));

		document.getElementById('step-pago').style.display = 'block';
		document.getElementById('step-previewventa').style.display = 'block';
		//vuelvo a cero el descuento y el aumento
		setPayType('EF');
		setMoneyType('CL');
		setDiscToPay(0);
		setAddToPay(0);
		document.getElementById('debitobtn').disabled = false;
		document.getElementById('creditobtn').disabled = false;
	};

	return (
		<div className="FastAccessContainer">
			<div className="card-body fastAccessBTNS">
				{listRankingDest.map((dato, count) => (
					<button
						key={count}
						className={
							count % 2 !== 0
								? 'btnFastAccessblueBack'
								: 'btnFastAccessorangeBack'
						}
						onClick={() => clickFastAccessBTN(dato[1], dato[2], dato[5])}
					>
						{dato[2]}
					</button>
				))}
			</div>
		</div>
	);
};

export default FastAccessNew;
