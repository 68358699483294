import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Footer from './components/Footer';
import Cookies from 'universal-cookie';
import { checkSession, verifyAccess } from './components/genscripts';
import HeadMenu from './components/HeadMenu';
import SideBar from './components/SideBar';
import ModalOpenBox from './components/Venta/ModalOpenBox';
import VentaDirNew from './components/Venta/VentaDirNew';

const thispage = 'ventaticket';
const headTitle = 'Ingresar nueva carrera';

const VentaTicket = () => {
	const session = checkSession();

	const [modalIsOpen, setModalIsOpen] = useState(true);

	useEffect(() => {
		const cookies = new Cookies();
		const copen = cookies.get('copen') === 'true';
		if (copen) {
			setModalIsOpen(false);
		} else {
			setModalIsOpen(true);
		}
	}, []);

	return session ? (
		[
			verifyAccess(thispage) ? (
				<div className="wrapper ">
					<div className="sidebar" data-color="white" data-active-color="info">
						<SideBar activeitem={thispage} />
					</div>
					<div className="main-panel">
						<HeadMenu pagetitle={headTitle} />
						<VentaDirNew inicio="aeropuerto" />
						<Footer />
					</div>
					{modalIsOpen ? (
						<ModalOpenBox setModalIsOpen={setModalIsOpen} modalIsOpen={true} />
					) : null}
				</div>
			) : (
				<Redirect to="/noaccess" />
			),
		]
	) : (
		<Redirect to="/" />
	);
};

export default VentaTicket;
