const Providencia = {
    commune: "Providencia",
    polygons: [
        {
            idService: 16,
            nameService: "Providencia",
            coordinates: [
                { "lng": -70.6353964, "lat": -33.4356977 },
                { "lng": -70.6355384, "lat": -33.4347976 },
                { "lng": -70.635832, "lat": -33.4333525 },
                { "lng": -70.6360122, "lat": -33.4321921 },
                { "lng": -70.6362611, "lat": -33.4309171 },
                { "lng": -70.636242, "lat": -33.4308096 },
                { "lng": -70.6361238, "lat": -33.4301435 },
                { "lng": -70.6359779, "lat": -33.4298601 },
                { "lng": -70.6353342, "lat": -33.4291049 },
                { "lng": -70.6335429, "lat": -33.4265625 },
                { "lng": -70.6333483, "lat": -33.425752 },
                { "lng": -70.6332938, "lat": -33.4255746 },
                { "lng": -70.6328418, "lat": -33.4253275 },
                { "lng": -70.6326275, "lat": -33.4251154 },
                { "lng": -70.6325703, "lat": -33.4248431 },
                { "lng": -70.632571, "lat": -33.4244695 },
                { "lng": -70.6325704, "lat": -33.4244129 },
                { "lng": -70.6324502, "lat": -33.4232166 },
                { "lng": -70.6323957, "lat": -33.42278 },
                { "lng": -70.6323103, "lat": -33.4225363 },
                { "lng": -70.6321812, "lat": -33.4222356 },
                { "lng": -70.6320438, "lat": -33.4220397 },
                { "lng": -70.6319494, "lat": -33.4219203 },
                { "lng": -70.631652, "lat": -33.4216405 },
                { "lng": -70.6314344, "lat": -33.4214762 },
                { "lng": -70.6311427, "lat": -33.4212757 },
                { "lng": -70.6298067, "lat": -33.4204084 },
                { "lng": -70.629289, "lat": -33.4202538 },
                { "lng": -70.6278497, "lat": -33.4197206 },
                { "lng": -70.6260301, "lat": -33.418775 },
                { "lng": -70.6243736, "lat": -33.4174855 },
                { "lng": -70.623129, "lat": -33.4162819 },
                { "lng": -70.6219274, "lat": -33.4150711 },
                { "lng": -70.6212665, "lat": -33.4141541 },
                { "lng": -70.6204425, "lat": -33.4132084 },
                { "lng": -70.6201738, "lat": -33.4126866 },
                { "lng": -70.6197216, "lat": -33.4120907 },
                { "lng": -70.6189662, "lat": -33.4117181 },
                { "lng": -70.6179191, "lat": -33.4116823 },
                { "lng": -70.6163484, "lat": -33.4112166 },
                { "lng": -70.6147605, "lat": -33.4099986 },
                { "lng": -70.6134731, "lat": -33.4088379 },
                { "lng": -70.6124174, "lat": -33.4086015 },
                { "lng": -70.6116706, "lat": -33.4083722 },
                { "lng": -70.610792, "lat": -33.4082 },
                { "lng": -70.609954, "lat": -33.4081358 },
                { "lng": -70.6087781, "lat": -33.4082074 },
                { "lng": -70.6072074, "lat": -33.408623 },
                { "lng": -70.6053755, "lat": -33.4092505 },
                { "lng": -70.6062461, "lat": -33.411797 },
                { "lng": -70.606598, "lat": -33.4128645 },
                { "lng": -70.6070516, "lat": -33.4142366 },
                { "lng": -70.6074788, "lat": -33.4155626 },
                { "lng": -70.6075818, "lat": -33.4157994 },
                { "lng": -70.6077874, "lat": -33.4161662 },
                { "lng": -70.6067575, "lat": -33.4162583 },
                { "lng": -70.6066456, "lat": -33.4162699 },
                { "lng": -70.6063605, "lat": -33.4163574 },
                { "lng": -70.6052126, "lat": -33.4169792 },
                { "lng": -70.6047619, "lat": -33.4171793 },
                { "lng": -70.6044261, "lat": -33.4172397 },
                { "lng": -70.6042192, "lat": -33.4172892 },
                { "lng": -70.6038846, "lat": -33.4173159 },
                { "lng": -70.6033324, "lat": -33.4173714 },
                { "lng": -70.6024181, "lat": -33.4176001 },
                { "lng": -70.6019864, "lat": -33.4177934 },
                { "lng": -70.601843, "lat": -33.4178652 },
                { "lng": -70.6012851, "lat": -33.4182305 },
                { "lng": -70.5999785, "lat": -33.4191997 },
                { "lng": -70.5994001, "lat": -33.4196179 },
                { "lng": -70.5990204, "lat": -33.4198943 },
                { "lng": -70.5988646, "lat": -33.4200078 },
                { "lng": -70.5972688, "lat": -33.4211689 },
                { "lng": -70.5957972, "lat": -33.4222553 },
                { "lng": -70.5932802, "lat": -33.4242294 },
                { "lng": -70.592787, "lat": -33.4246344 },
                { "lng": -70.5927242, "lat": -33.4246878 },
                { "lng": -70.5924636, "lat": -33.424888 },
                { "lng": -70.5923661, "lat": -33.4249623 },
                { "lng": -70.5918402, "lat": -33.4253758 },
                { "lng": -70.5916377, "lat": -33.4255424 },
                { "lng": -70.5912936, "lat": -33.4258104 },
                { "lng": -70.5910507, "lat": -33.4259902 },
                { "lng": -70.5907293, "lat": -33.4262432 },
                { "lng": -70.5904245, "lat": -33.4264764 },
                { "lng": -70.5902023, "lat": -33.4266546 },
                { "lng": -70.589751, "lat": -33.4270024 },
                { "lng": -70.5895885, "lat": -33.4271354 },
                { "lng": -70.5893984, "lat": -33.4272829 },
                { "lng": -70.5888229, "lat": -33.4277459 },
                { "lng": -70.5878449, "lat": -33.428571 },
                { "lng": -70.5855044, "lat": -33.4305458 },
                { "lng": -70.5849457, "lat": -33.4311117 },
                { "lng": -70.5844386, "lat": -33.4317235 },
                { "lng": -70.5841483, "lat": -33.4321465 },
                { "lng": -70.5838035, "lat": -33.4325169 },
                { "lng": -70.5835315, "lat": -33.4328417 },
                { "lng": -70.5830036, "lat": -33.4332792 },
                { "lng": -70.5822316, "lat": -33.4337495 },
                { "lng": -70.5822855, "lat": -33.4338172 },
                { "lng": -70.5823485, "lat": -33.4339036 },
                { "lng": -70.5824078, "lat": -33.433985 },
                { "lng": -70.5824566, "lat": -33.4340442 },
                { "lng": -70.5826352, "lat": -33.434261 },
                { "lng": -70.5831, "lat": -33.4348249 },
                { "lng": -70.5834648, "lat": -33.4352355 },
                { "lng": -70.5837913, "lat": -33.435603 },
                { "lng": -70.5838476, "lat": -33.4356669 },
                { "lng": -70.5839009, "lat": -33.4357272 },
                { "lng": -70.5842336, "lat": -33.4361032 },
                { "lng": -70.5845934, "lat": -33.4365093 },
                { "lng": -70.584957, "lat": -33.4369246 },
                { "lng": -70.5850663, "lat": -33.4370478 },
                { "lng": -70.585301, "lat": -33.4373041 },
                { "lng": -70.5853653, "lat": -33.4373723 },
                { "lng": -70.5854219, "lat": -33.4374369 },
                { "lng": -70.585985, "lat": -33.4380796 },
                { "lng": -70.5860255, "lat": -33.4381258 },
                { "lng": -70.586069, "lat": -33.4381732 },
                { "lng": -70.5864153, "lat": -33.4385507 },
                { "lng": -70.586911, "lat": -33.4391252 },
                { "lng": -70.5869886, "lat": -33.439214 },
                { "lng": -70.5871731, "lat": -33.4394412 },
                { "lng": -70.5872197, "lat": -33.4395015 },
                { "lng": -70.5876614, "lat": -33.4401908 },
                { "lng": -70.5881786, "lat": -33.4409816 },
                { "lng": -70.5885457, "lat": -33.4415512 },
                { "lng": -70.5885929, "lat": -33.4416243 },
                { "lng": -70.5886415, "lat": -33.4417009 },
                { "lng": -70.5894885, "lat": -33.4430311 },
                { "lng": -70.5898438, "lat": -33.4435459 },
                { "lng": -70.5903617, "lat": -33.444388 },
                { "lng": -70.5903895, "lat": -33.4444332 },
                { "lng": -70.59043, "lat": -33.4444939 },
                { "lng": -70.5906955, "lat": -33.4448915 },
                { "lng": -70.5909925, "lat": -33.4453546 },
                { "lng": -70.5911499, "lat": -33.4456184 },
                { "lng": -70.5913559, "lat": -33.4459556 },
                { "lng": -70.5917337, "lat": -33.4464966 },
                { "lng": -70.5920609, "lat": -33.446895 },
                { "lng": -70.5927478, "lat": -33.4476872 },
                { "lng": -70.5928161, "lat": -33.4477658 },
                { "lng": -70.5929035, "lat": -33.4477187 },
                { "lng": -70.593799, "lat": -33.4472363 },
                { "lng": -70.5943905, "lat": -33.446918 },
                { "lng": -70.5944101, "lat": -33.4469074 },
                { "lng": -70.5945152, "lat": -33.4468532 },
                { "lng": -70.5945552, "lat": -33.4468314 },
                { "lng": -70.595639, "lat": -33.4462414 },
                { "lng": -70.5959847, "lat": -33.4460313 },
                { "lng": -70.5960835, "lat": -33.4459713 },
                { "lng": -70.596188, "lat": -33.4459059 },
                { "lng": -70.5972515, "lat": -33.4452412 },
                { "lng": -70.5973699, "lat": -33.4451762 },
                { "lng": -70.5977384, "lat": -33.4449519 },
                { "lng": -70.5978398, "lat": -33.4448608 },
                { "lng": -70.5979095, "lat": -33.4448069 },
                { "lng": -70.5980425, "lat": -33.4447195 },
                { "lng": -70.5980958, "lat": -33.4446729 },
                { "lng": -70.5981294, "lat": -33.4446325 },
                { "lng": -70.5981977, "lat": -33.4445389 },
                { "lng": -70.5982446, "lat": -33.4444526 },
                { "lng": -70.5982656, "lat": -33.444423 },
                { "lng": -70.59829, "lat": -33.4443953 },
                { "lng": -70.5983368, "lat": -33.4443545 },
                { "lng": -70.5983692, "lat": -33.4443347 },
                { "lng": -70.598406, "lat": -33.4443211 },
                { "lng": -70.5984396, "lat": -33.4443149 },
                { "lng": -70.5984739, "lat": -33.4443139 },
                { "lng": -70.59861, "lat": -33.4443209 },
                { "lng": -70.5990995, "lat": -33.4443633 },
                { "lng": -70.5991739, "lat": -33.444369 },
                { "lng": -70.5992178, "lat": -33.4443723 },
                { "lng": -70.5991842, "lat": -33.4446478 },
                { "lng": -70.5992344, "lat": -33.4446506 },
                { "lng": -70.599319, "lat": -33.4446588 },
                { "lng": -70.5996197, "lat": -33.444688 },
                { "lng": -70.6001359, "lat": -33.444738 },
                { "lng": -70.6003586, "lat": -33.4447596 },
                { "lng": -70.6010078, "lat": -33.4448226 },
                { "lng": -70.6010984, "lat": -33.4448315 },
                { "lng": -70.6011914, "lat": -33.444841 },
                { "lng": -70.6017466, "lat": -33.4448989 },
                { "lng": -70.6022138, "lat": -33.4449478 },
                { "lng": -70.6028105, "lat": -33.4450065 },
                { "lng": -70.6029107, "lat": -33.4450177 },
                { "lng": -70.6029975, "lat": -33.4450262 },
                { "lng": -70.6042839, "lat": -33.4451517 },
                { "lng": -70.6043606, "lat": -33.4451596 },
                { "lng": -70.6044582, "lat": -33.4451702 },
                { "lng": -70.6060324, "lat": -33.445389 },
                { "lng": -70.6060496, "lat": -33.4453907 },
                { "lng": -70.6061646, "lat": -33.4454057 },
                { "lng": -70.6062958, "lat": -33.4454215 },
                { "lng": -70.6065331, "lat": -33.4454502 },
                { "lng": -70.607178, "lat": -33.4455238 },
                { "lng": -70.6080888, "lat": -33.4456345 },
                { "lng": -70.6084441, "lat": -33.4456765 },
                { "lng": -70.6085895, "lat": -33.4456964 },
                { "lng": -70.6087715, "lat": -33.4457286 },
                { "lng": -70.6089373, "lat": -33.4457643 },
                { "lng": -70.6090989, "lat": -33.4458045 },
                { "lng": -70.6094559, "lat": -33.4459125 },
                { "lng": -70.6103441, "lat": -33.446172 },
                { "lng": -70.6111523, "lat": -33.4464093 },
                { "lng": -70.6111876, "lat": -33.4464184 },
                { "lng": -70.6112924, "lat": -33.4464355 },
                { "lng": -70.6113841, "lat": -33.4464477 },
                { "lng": -70.6137831, "lat": -33.4468159 },
                { "lng": -70.6138872, "lat": -33.4468299 },
                { "lng": -70.6138658, "lat": -33.4469274 },
                { "lng": -70.613813, "lat": -33.4471679 },
                { "lng": -70.6137578, "lat": -33.4474194 },
                { "lng": -70.6137276, "lat": -33.4475567 },
                { "lng": -70.6138095, "lat": -33.4475698 },
                { "lng": -70.6138282, "lat": -33.4475729 },
                { "lng": -70.6158933, "lat": -33.4479006 },
                { "lng": -70.6159431, "lat": -33.4479086 },
                { "lng": -70.6159959, "lat": -33.4479181 },
                { "lng": -70.6164025, "lat": -33.447992 },
                { "lng": -70.6164966, "lat": -33.4480092 },
                { "lng": -70.6165893, "lat": -33.4480471 },
                { "lng": -70.6170983, "lat": -33.4481499 },
                { "lng": -70.6171659, "lat": -33.4481395 },
                { "lng": -70.6178192, "lat": -33.4482509 },
                { "lng": -70.618352, "lat": -33.4483536 },
                { "lng": -70.618432, "lat": -33.448369 },
                { "lng": -70.618449, "lat": -33.4483091 },
                { "lng": -70.6186356, "lat": -33.4476535 },
                { "lng": -70.6187467, "lat": -33.4472628 },
                { "lng": -70.6188286, "lat": -33.4472782 },
                { "lng": -70.6198046, "lat": -33.447463 },
                { "lng": -70.6198401, "lat": -33.4474697 },
                { "lng": -70.6199136, "lat": -33.4474844 },
                { "lng": -70.6208342, "lat": -33.4476693 },
                { "lng": -70.6209332, "lat": -33.4476891 },
                { "lng": -70.6209936, "lat": -33.4477021 },
                { "lng": -70.6221599, "lat": -33.4479295 },
                { "lng": -70.6221817, "lat": -33.4479336 },
                { "lng": -70.6222589, "lat": -33.447948 },
                { "lng": -70.6223559, "lat": -33.4479657 },
                { "lng": -70.6231868, "lat": -33.4481359 },
                { "lng": -70.6231913, "lat": -33.4481367 },
                { "lng": -70.6232561, "lat": -33.4481498 },
                { "lng": -70.6233091, "lat": -33.4481605 },
                { "lng": -70.6236564, "lat": -33.4482313 },
                { "lng": -70.6236681, "lat": -33.4482337 },
                { "lng": -70.623717, "lat": -33.4482444 },
                { "lng": -70.6237808, "lat": -33.4482569 },
                { "lng": -70.6244062, "lat": -33.4483864 },
                { "lng": -70.6244977, "lat": -33.4484009 },
                { "lng": -70.6245849, "lat": -33.4484173 },
                { "lng": -70.6253476, "lat": -33.4485668 },
                { "lng": -70.6254145, "lat": -33.4485815 },
                { "lng": -70.6254791, "lat": -33.4485953 },
                { "lng": -70.6262448, "lat": -33.4487946 },
                { "lng": -70.6267696, "lat": -33.4489137 },
                { "lng": -70.6268597, "lat": -33.4489313 },
                { "lng": -70.6278866, "lat": -33.4491299 },
                { "lng": -70.6280148, "lat": -33.4491552 },
                { "lng": -70.628074, "lat": -33.4491664 },
                { "lng": -70.6280855, "lat": -33.4491687 },
                { "lng": -70.6280923, "lat": -33.4491701 },
                { "lng": -70.6289997, "lat": -33.4493406 },
                { "lng": -70.6295516, "lat": -33.4494538 },
                { "lng": -70.629563, "lat": -33.4494552 },
                { "lng": -70.6296609, "lat": -33.4494753 },
                { "lng": -70.6297055, "lat": -33.4494842 },
                { "lng": -70.6297646, "lat": -33.4494958 },
                { "lng": -70.6299094, "lat": -33.4495235 },
                { "lng": -70.6299323, "lat": -33.4495278 },
                { "lng": -70.6314363, "lat": -33.4498169 },
                { "lng": -70.6315053, "lat": -33.4498298 },
                { "lng": -70.6315882, "lat": -33.4495335 },
                { "lng": -70.6323864, "lat": -33.4469339 },
                { "lng": -70.6328387, "lat": -33.4453963 },
                { "lng": -70.633466, "lat": -33.443239 },
                { "lng": -70.6335881, "lat": -33.4428015 },
                { "lng": -70.6342747, "lat": -33.4402804 },
                { "lng": -70.634853, "lat": -33.4384495 },
                { "lng": -70.6350673, "lat": -33.4376824 },
                { "lng": -70.6352344, "lat": -33.4371106 },
                { "lng": -70.6353329, "lat": -33.4368273 },
                { "lng": -70.6353569, "lat": -33.4367323 },
                { "lng": -70.635375, "lat": -33.4366584 },
                { "lng": -70.6354297, "lat": -33.4364262 },
                { "lng": -70.6354157, "lat": -33.4362626 },
                { "lng": -70.6353964, "lat": -33.4356977 },
              ]
              
              
        }
    ]
};

export default Providencia;
