import React from 'react';
import ReactDOM from 'react-dom';
import QRCode from 'qrcode.react';
import axios from 'axios';
import Cookies from 'universal-cookie';

export function numberFormat(numero) {
	return new Intl.NumberFormat('es-CL').format(numero);
}

export function getDateTimeNow() {
	var now = new Date();
	var year = now.getFullYear();
	var month = now.getMonth() + 1;
	var day = now.getDate();
	var hour = now.getHours();
	var minute = now.getMinutes();
	var second = now.getSeconds();
	if (month.toString().length === 1) {
		month = '0' + month;
	}
	if (day.toString().length === 1) {
		day = '0' + day;
	}
	if (hour.toString().length === 1) {
		hour = '0' + hour;
	}
	if (minute.toString().length === 1) {
		minute = '0' + minute;
	}
	if (second.toString().length === 1) {
		second = '0' + second;
	}
	var dateTime =
		year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second;
	return dateTime;
}

export function getDateNow() {
	var now = new Date();
	var year = now.getFullYear();
	var month = now.getMonth() + 1;
	var day = now.getDate();

	if (month.toString().length === 1) {
		month = '0' + month;
	}
	if (day.toString().length === 1) {
		day = '0' + day;
	}
	var dateTime = year + '/' + month + '/' + day;
	return dateTime;
}

function getServiceByLatLng(lat, lng, token) {
	const headers = {
		Authorization: 'Bearer ' + token,
		'Content-Type': 'application/json',
	};
	axios({
		method: 'POST',
		url: `${process.env.REACT_APP_BACKEND_URL}/data/servicelatlong`,
		data: JSON.stringify({ latitude: lat, longitude: lng }),
		headers: headers,
	})
		.then((response) => {
			document.getElementById('montocontainer').innerHTML =
				'<p class="montotext">$ <span id="montoapago">' +
				numberFormat(String(response.data.valor)) +
				'</span></p>';
			document.getElementById('montocarreraorig').value = response.data.valor;
			document.getElementById('montoapagomem').value = response.data.valor;
			//document.getElementById("efectivoMonto").style.display = "block";
			//document.getElementById("efectivoVuelto").style.display = "block";
			//document.getElementById("efectivoradio").checked = true;
			//document.getElementById("clpesosradio").checked = true;
			//fpagoClass("EF");
			//monedaClass("CL");

			fpagoClassBTN('EF');
			document.getElementById('FPagoPreview').innerHTML = 'EF';
			monedaClassBTN('CL');
			document.getElementById('TipomonedaPreview').innerHTML = 'CL';

			const cookies = new Cookies();
			/*
      cookies.set("servicevalue", response.data.valor, {
        path: "/",
      });
      */
			//Preview
			document.getElementById('MontoPreview').innerHTML =
				'$ ' + numberFormat(String(response.data.valor));
			document.getElementById('DestinoPreview').innerHTML =
				document.getElementById('nc-dir').value +
				', ' +
				document.getElementById('nc-comuna').value +
				', ' +
				document.getElementById('nc-region').value;
			//generateQR();
			document.getElementById(
				'FechaEmisionPreview'
			).innerHTML = getDateTimeNow();
			//const cookies = new Cookies();
			document.getElementById('SellerNamePreview').innerHTML = cookies.get(
				'name'
			);
		})
		.catch((error) => {
			console.log(error);
		});
}

export function getLatLong(direccion, token) {
	//let coordinates = [];
	let address =
		'https://maps.googleapis.com/maps/api/geocode/json?address=' +
		direccion +
		'&key=AIzaSyDEkRAJ9lT4R9Kur1VZ_aPv3_jx1ubLH8M';
	fetch(address)
		.then((res) => res.json())
		.then((data) => {
			const latitude = data.results[0].geometry.location.lat + '';
			const longitude = data.results[0].geometry.location.lng + '';
			document.getElementById('mapcontainer').innerHTML =
				'<img width="600" src="https://maps.googleapis.com/maps/api/staticmap?center=' +
				latitude +
				',' +
				longitude +
				'&amp;markers=' +
				latitude +
				',' +
				longitude +
				'&amp;zoom=13&amp;scale=1&amp;size=600x300&amp;maptype=roadmap&amp;key=AIzaSyDEkRAJ9lT4R9Kur1VZ_aPv3_jx1ubLH8M&amp;format=png&amp;visual_refresh=true" alt="Google Map">';
			//coordinates.push(latitude);
			//coordinates.push(longitude);
			getServiceByLatLng(latitude, longitude, token);
		})
		.catch(console.log);
}

export function cleanAllCookies() {
	const cookies = new Cookies();
	var cookiesdata = cookies.getAll({ path: '/' });
	var keys = Object.keys(cookiesdata);
	for (const nkey in keys) {
		//console.log(keys[nkey]);
		cookies.remove(keys[nkey], { path: '/' });
	}
}

/* function checkIsTokenLive() {
	var salida = false;
	const cookies = new Cookies();
	var token = cookies.get('token');
	console.log(token);
	if (token) {
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		fetch(`${process.env.REACT_APP_BACKEND_URL}/data/checktoken`, {
			//desarrollo
			method: 'GET',
			headers: headers,
		})
			.then((response) => {
				console.log(response.ok);
				salida = response.ok;
			})
			.catch((error) => {
				console.log(error);
				salida = false;
			});
	} else {
		salida = false;
	}
	console.log('salida: ' + salida);
	return salida;
} */

export function checkSession() {
	// esta funcion además debe checkear si el token está vivo para luego hacer el resto
	const cookies = new Cookies();
	let session = null;

	if (cookies.get('session')) {
		session = cookies.get('session');
	} else {
		session = false;
	}

	return session;
}

export function verifyAccess(path) {
	const rutas = Array.from(new Cookies().get('pages'), (x) => x.pagepath);
	let flag = null;

	if (rutas.indexOf(path) >= 0) {
		flag = true;
	} else {
		flag = false;
	}

	return flag;
}

export function generateSideMenu() {
	return new Cookies().get('pages');
}

export function generateQR() {
	var contenido = btoa(
		document.getElementById('TicketNumberPreview').innerHTML +
			'_&_' +
			document.getElementById('MontoPreview').innerHTML +
			'_&_' +
			document.getElementById('TipomonedaPreview').innerHTML +
			'_&_' +
			document.getElementById('FPagoPreview').innerHTML +
			'_&_' +
			document.getElementById('DestinoPreview').innerHTML +
			'_&_' +
			document.getElementById('FechaEmisionPreview').innerHTML
	);

	ReactDOM.render(
		<QRCode value={contenido} />,
		document.getElementById('QRView')
	);
}

export function openPaso2CierreCaja() {
	document.getElementById('paso1cierrecaja').style.display = 'none';
	document.getElementById('paso3cierrecaja').style.display = 'none';
	document.getElementById('paso2cierrecaja').style.display = 'block';
}

export function openPaso1CierreCaja() {
	document.getElementById('paso2cierrecaja').style.display = 'none';
	document.getElementById('paso3cierrecaja').style.display = 'none';
	document.getElementById('paso1cierrecaja').style.display = 'block';
}

export function openPaso3CierreCaja() {
	document.getElementById('paso1cierrecaja').style.display = 'none';
	document.getElementById('paso2cierrecaja').style.display = 'none';
	document.getElementById('paso3cierrecaja').style.display = 'block';
}

//paso3cierrecaja
export function setVenta(idservicio, comuna, latitude, longitude, valor) {
	/* let valorCarrearaStr = valor.toString(); */
	/* document.getElementById('nc-codetarifa').value = idservicio; */
	/* document.getElementById('mapcontainer').innerHTML =
		'<img width="600" src="https://maps.googleapis.com/maps/api/staticmap?center=' +
		latitude +
		',' +
		longitude +
		'&amp;markers=' +
		latitude +
		',' +
		longitude +
		'&amp;zoom=13&amp;scale=1&amp;size=600x300&amp;maptype=roadmap&amp;key=AIzaSyDEkRAJ9lT4R9Kur1VZ_aPv3_jx1ubLH8M&amp;format=png&amp;visual_refresh=true" alt="Google Map">'; */
	/* document.getElementById('montocontainer').innerHTML =
		'<p class="montotext">$ <span id="montoapago">' +
		numberFormat(valorCarrearaStr) +
		'</span></p>';
	document.getElementById('montocarreraorig').value = valor;
	document.getElementById('montoapagomem').value = valor; */

	/*document.getElementById("step-monto").style.display = "block";*/
	document.getElementById('step-pago').style.display = 'block';
	document.getElementById('step-previewventa').style.display = 'block';

	/* fpagoClassBTN('EF');
	document.getElementById('FPagoPreview').innerHTML = 'EF';
	//document.getElementById("clpesosradio").checked = true;
	//monedaClass("CL");
	monedaClassBTN('CL');
	document.getElementById('TipomonedaPreview').innerHTML = 'CL';
	//Preview
	document.getElementById('MontoPreview').innerHTML =
		'$ ' + numberFormat(valorCarrearaStr);

	document.getElementById('DestinoPreview').innerHTML = comuna;
	document.getElementById('FechaEmisionPreview').innerHTML = getDateTimeNow();

	const cookies = new Cookies();
	document.getElementById('SellerNamePreview').innerHTML = cookies.get('name'); */
}

//Radio Buttons
/*
export function fpagoClass(target) {
  var divEF = document.getElementById("labelFpagoEF");
  var divDB = document.getElementById("labelFpagoDB");
  var divTC = document.getElementById("labelFpagoTC");

  //if (target === "EF") {
  if (document.getElementById("efectivoradio").checked === true) {
    divEF.classList.remove("radioBTNInactive");
    divEF.classList.add("radioBTNSelected");

    divDB.classList.remove("radioBTNSelected");
    divDB.classList.add("radioBTNInactive");

    divTC.classList.remove("radioBTNSelected");
    divTC.classList.add("radioBTNInactive");
  }


  //if (target === "DB") {
  if (document.getElementById("debitoradio").checked === true) {
    divDB.classList.remove("radioBTNInactive");
    divDB.classList.add("radioBTNSelected");

    divEF.classList.remove("radioBTNSelected");
    divEF.classList.add("radioBTNInactive");

    divTC.classList.remove("radioBTNSelected");
    divTC.classList.add("radioBTNInactive");
  }

  //if (target === "TC") {
  if (document.getElementById("creditoradio").checked === true) {
    divTC.classList.remove("radioBTNInactive");
    divTC.classList.add("radioBTNSelected");

    divEF.classList.remove("radioBTNSelected");
    divEF.classList.add("radioBTNInactive");

    divDB.classList.remove("radioBTNSelected");
    divDB.classList.add("radioBTNInactive");
  }
}
  */

export function fpagoClassBTN(target) {
	var divEF = document.getElementById('efectivobtn');
	var divDB = document.getElementById('debitobtn');
	var divTC = document.getElementById('creditobtn');

	if (target === 'EF') {
		//if (document.getElementById("efectivoradio").checked === true) {
		divEF.classList.remove('radioBTNInactive');
		divEF.classList.add('radioBTNSelected');

		divDB.classList.remove('radioBTNSelected');
		divDB.classList.add('radioBTNInactive');

		divTC.classList.remove('radioBTNSelected');
		divTC.classList.add('radioBTNInactive');
	}

	if (target === 'DB') {
		//if (document.getElementById("debitoradio").checked === true) {
		divDB.classList.remove('radioBTNInactive');
		divDB.classList.add('radioBTNSelected');

		divEF.classList.remove('radioBTNSelected');
		divEF.classList.add('radioBTNInactive');

		divTC.classList.remove('radioBTNSelected');
		divTC.classList.add('radioBTNInactive');
	}

	if (target === 'TC') {
		//if (document.getElementById("creditoradio").checked === true) {
		divTC.classList.remove('radioBTNInactive');
		divTC.classList.add('radioBTNSelected');

		divEF.classList.remove('radioBTNSelected');
		divEF.classList.add('radioBTNInactive');

		divDB.classList.remove('radioBTNSelected');
		divDB.classList.add('radioBTNInactive');
	}
}

export function monedaClassBTN(target) {
	var divCL = document.getElementById('clpesosbtn');
	var divUS = document.getElementById('usdbtn');
	var divEU = document.getElementById('eurobtn');

	if (target === 'CL') {
		//if (document.getElementById("clpesosradio").checked === true) {
		divCL.classList.remove('radioBTNInactive');
		divCL.classList.add('radioBTNSelected');

		divUS.classList.remove('radioBTNSelected');
		divUS.classList.add('radioBTNInactive');

		divEU.classList.remove('radioBTNSelected');
		divEU.classList.add('radioBTNInactive');
	}

	if (target === 'US') {
		//if (document.getElementById("usdradio").checked === true) {
		divUS.classList.remove('radioBTNInactive');
		divUS.classList.add('radioBTNSelected');

		divCL.classList.remove('radioBTNSelected');
		divCL.classList.add('radioBTNInactive');

		divEU.classList.remove('radioBTNSelected');
		divEU.classList.add('radioBTNInactive');
	}

	if (target === 'EU') {
		//if (document.getElementById("euroradio").checked === true) {
		divEU.classList.remove('radioBTNInactive');
		divEU.classList.add('radioBTNSelected');

		divUS.classList.remove('radioBTNSelected');
		divUS.classList.add('radioBTNInactive');

		divCL.classList.remove('radioBTNSelected');
		divCL.classList.add('radioBTNInactive');
	}
}

function convertDateComp(number) {
	var w_number = '';
	if (parseInt(number) < 10) {
		w_number = '0' + number;
	} else {
		w_number = '' + number;
	}
	return w_number;
}

export function setInitDate() {
	var date = new Date();
	var month = date.getMonth() + 1;
	var rdate =
		date.getFullYear().toString() +
		'-' +
		convertDateComp(month).toString() +
		'-' +
		convertDateComp(date.getDate()).toString() +
		' 00:00:00';
	return rdate;
}

export function setEndDate() {
	var date = new Date();
	var month = date.getMonth() + 1;
	var rdate =
		date.getFullYear() +
		'-' +
		convertDateComp(month) +
		'-' +
		convertDateComp(date.getDate()) +
		' 00:00:00';
	return rdate;
}

export function setDateFromCalIni(date) {
	var month = date.getMonth() + 1;
	var rdate =
		date.getFullYear() +
		'-' +
		convertDateComp(month) +
		'-' +
		convertDateComp(date.getDate()) +
		' ' +
		document.getElementById('timeini').value;
	return rdate;
}

export function setDateFromCalEnd(date) {
	var month = date.getMonth() + 1;
	var rdate =
		date.getFullYear() +
		'-' +
		convertDateComp(month) +
		'-' +
		convertDateComp(date.getDate()) +
		' ' +
		document.getElementById('timeend').value;
	return rdate;
}

export function setDateFormat(date) {
	var month = date.getMonth() + 1;
	var rdate =
		date.getFullYear() +
		'-' +
		convertDateComp(month) +
		'-' +
		convertDateComp(date.getDate()) +
		' ' +
		convertDateComp(date.getHours()) +
		':' +
		convertDateComp(date.getMinutes()) +
		':' +
		convertDateComp(date.getSeconds());
	return rdate;
}

export function searchOnTable(idinput, idtable) {
	// Declare variables
	var input, filter, table, tr, td, i, txtValue;
	input = document.getElementById(idinput);
	filter = input.value.toUpperCase();
	table = document.getElementById(idtable);
	tr = table.getElementsByTagName('tr');

	// Loop through all table rows, and hide those who don't match the search query
	for (i = 0; i < tr.length; i++) {
		td = tr[i].getElementsByTagName('td')[0];
		if (td) {
			txtValue = td.textContent || td.innerText;
			if (txtValue.toUpperCase().indexOf(filter) > -1) {
				tr[i].style.display = 'block';
			} else {
				tr[i].style.display = 'none';
			}
		}
	}
}

export function checkRut(Objeto) {
	var tmpstr = '';
	var intlargo = Objeto;
	if (intlargo.length > 0) {
		var crut = Objeto;
		var largo = crut.length;
		if (largo < 2) {
			alert('rut inválido');
			Objeto.focus();
			return false;
		}
		for (var i = 0; i < crut.length; i++)
			if (
				crut.charAt(i) !== ' ' &&
				crut.charAt(i) !== '.' &&
				crut.charAt(i) !== '-'
			) {
				tmpstr = tmpstr + crut.charAt(i);
			}
		var rut = tmpstr;
		crut = tmpstr;
		largo = crut.length;

		if (largo > 2) rut = crut.substring(0, largo - 1);
		else rut = crut.charAt(0);

		var dv = crut.charAt(largo - 1);

		if (rut == null || dv == null) return 0;

		var dvr = '0';
		var suma = 0;
		var mul = 2;

		for (i = rut.length - 1; i >= 0; i--) {
			suma = suma + rut.charAt(i) * mul;
			if (mul === 7) mul = 2;
			else mul++;
		}

		var res = suma % 11;
		var dvi;
		if (res === 1) dvr = 'k';
		else if (res === 0) dvr = '0';
		else {
			dvi = 11 - res;
			dvr = dvi + '';
		}

		if (dvr !== dv.toLowerCase()) {
			//alert("El Rut Ingreso es Invalido");
			//Objeto.focus();
			return false;
		}
		//alert("El Rut Ingresado es Correcto!");
		//Objeto.focus();
		return true;
	}
}

export function PrintCierre() {
	var mywindow = window.open('', 'PRINT', 'height=400,width=600');

	mywindow.document.write('<html><head><title>' + document.title + '</title>');
	mywindow.document.write('</head><body >');
	mywindow.document.write('<h1>' + document.title + '</h1>');
	mywindow.document.write(document.getElementById('tablacierrecaja').innerHTML);
	mywindow.document.write('</body></html>');

	mywindow.document.close(); // necessary for IE >= 10
	mywindow.focus(); // necessary for IE >= 10*/

	mywindow.print();
	mywindow.close();

	return true;
}
