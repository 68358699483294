const SantoDomingo = {
    commune: "Santo Domingo",
    polygons: [
        {
            idService: 105,
            nameService: "San Antonio / Algarrobo",
            coordinates: [
                { "lat": -33.9043893, "lng": -71.8322883 },
                { "lat": -33.9037701, "lng": -71.831333 },
                { "lat": -33.9035442, "lng": -71.8312137 },
                { "lat": -33.9029687, "lng": -71.8309736 },
                { "lat": -33.9022319, "lng": -71.8306142 },
                { "lat": -33.9012779, "lng": -71.8300925 },
                { "lat": -33.9006946, "lng": -71.8297344 },
                { "lat": -33.8995002, "lng": -71.8289352 },
                { "lat": -33.8988847, "lng": -71.8284979 },
                { "lat": -33.8983503, "lng": -71.8281882 },
                { "lat": -33.8979218, "lng": -71.8280245 },
                { "lat": -33.8966282, "lng": -71.8275619 },
                { "lat": -33.8957422, "lng": -71.8273526 },
                { "lat": -33.8946467, "lng": -71.8272185 },
                { "lat": -33.8934556, "lng": -71.8272293 },
                { "lat": -33.8930961, "lng": -71.827244 },
                { "lat": -33.8928178, "lng": -71.8272293 },
                { "lat": -33.892593, "lng": -71.8271595 },
                { "lat": -33.8922044, "lng": -71.8270308 },
                { "lat": -33.891846, "lng": -71.8269879 },
                { "lat": -33.8912781, "lng": -71.8269449 },
                { "lat": -33.8905479, "lng": -71.8267894 },
                { "lat": -33.8903241, "lng": -71.8266928 },
                { "lat": -33.8900436, "lng": -71.8264849 },
                { "lat": -33.8898354, "lng": -71.8263817 },
                { "lat": -33.8894291, "lng": -71.8262905 },
                { "lat": -33.8890071, "lng": -71.8261578 },
                { "lat": -33.8884116, "lng": -71.825923 },
                { "lat": -33.888062, "lng": -71.8257768 },
                { "lat": -33.887503, "lng": -71.8256374 },
                { "lat": -33.8868874, "lng": -71.8255718 },
                { "lat": -33.8857207, "lng": -71.8256253 },
                { "lat": -33.8835051, "lng": -71.8258024 },
                { "lat": -33.8821602, "lng": -71.8260303 },
                { "lat": -33.881147, "lng": -71.8261592 },
                { "lat": -33.8802497, "lng": -71.8263602 },
                { "lat": -33.8800082, "lng": -71.8264438 },
                { "lat": -33.8796907, "lng": -71.8265534 },
                { "lat": -33.8790672, "lng": -71.8268243 },
                { "lat": -33.8785951, "lng": -71.8270469 },
                { "lat": -33.8783546, "lng": -71.8270953 },
                { "lat": -33.8777401, "lng": -71.8270871 },
                { "lat": -33.8773861, "lng": -71.8271458 },
                { "lat": -33.8770564, "lng": -71.8272373 },
                { "lat": -33.876829, "lng": -71.8273293 },
                { "lat": -33.8765854, "lng": -71.8274277 },
                { "lat": -33.8763772, "lng": -71.8275484 },
                { "lat": -33.8762943, "lng": -71.8276142 },
                { "lat": -33.8762725, "lng": -71.827621 },
                { "lat": -33.8762397, "lng": -71.8276088 },
                { "lat": -33.8761712, "lng": -71.8275578 },
                { "lat": -33.8760454, "lng": -71.8275136 },
                { "lat": -33.8758805, "lng": -71.8275256 },
                { "lat": -33.8756468, "lng": -71.8276048 },
                { "lat": -33.8755165, "lng": -71.8276383 },
                { "lat": -33.8754329, "lng": -71.8276357 },
                { "lat": -33.8753896, "lng": -71.8276101 },
                { "lat": -33.8753723, "lng": -71.8275759 },
                { "lat": -33.8753239, "lng": -71.8274358 },
                { "lat": -33.8752571, "lng": -71.8273231 },
                { "lat": -33.8751824, "lng": -71.8272669 },
                { "lat": -33.8750566, "lng": -71.8271998 },
                { "lat": -33.8749598, "lng": -71.827122 },
                { "lat": -33.8749398, "lng": -71.8270858 },
                { "lat": -33.8749136, "lng": -71.8269912 },
                { "lat": -33.8748796, "lng": -71.8269503 },
                { "lat": -33.8747248, "lng": -71.8268995 },
                { "lat": -33.8745399, "lng": -71.8269035 },
                { "lat": -33.8743719, "lng": -71.8269195 },
                { "lat": -33.8743051, "lng": -71.826898 },
                { "lat": -33.8742054, "lng": -71.8267693 },
                { "lat": -33.8741809, "lng": -71.8267605 },
                { "lat": -33.8741474, "lng": -71.8267633 },
                { "lat": -33.8738162, "lng": -71.8268793 },
                { "lat": -33.8731035, "lng": -71.8271838 },
                { "lat": -33.8725224, "lng": -71.8274559 },
                { "lat": -33.8720591, "lng": -71.8276062 },
                { "lat": -33.8712373, "lng": -71.8277631 },
                { "lat": -33.870331, "lng": -71.8278971 },
                { "lat": -33.8701439, "lng": -71.8279294 },
                { "lat": -33.8696896, "lng": -71.8280447 },
                { "lat": -33.8692775, "lng": -71.8281333 },
                { "lat": -33.8685182, "lng": -71.828337 },
                { "lat": -33.8680482, "lng": -71.8284337 },
                { "lat": -33.8678111, "lng": -71.8284671 },
                { "lat": -33.86737, "lng": -71.8285101 },
                { "lat": -33.8669758, "lng": -71.8286052 },
                { "lat": -33.8659357, "lng": -71.8288922 },
                { "lat": -33.8650159, "lng": -71.8292221 },
                { "lat": -33.86434, "lng": -71.829402 },
                { "lat": -33.863821, "lng": -71.8294823 },
                { "lat": -33.863517, "lng": -71.8295428 },
                { "lat": -33.8628366, "lng": -71.8297961 },
                { "lat": -33.8624413, "lng": -71.8299411 },
                { "lat": -33.8620738, "lng": -71.8300281 },
                { "lat": -33.861919, "lng": -71.8300242 },
                { "lat": -33.8613069, "lng": -71.8299197 },
                { "lat": -33.860665, "lng": -71.8298552 },
                { "lat": -33.8603666, "lng": -71.8298056 },
                { "lat": -33.8600414, "lng": -71.8297935 },
                { "lat": -33.8596238, "lng": -71.8297694 },
                { "lat": -33.8592473, "lng": -71.8296714 },
                { "lat": -33.8590892, "lng": -71.8295964 },
                { "lat": -33.8585903, "lng": -71.8292369 },
                { "lat": -33.8580925, "lng": -71.828789 },
                { "lat": -33.8579933, "lng": -71.8286724 },
                { "lat": -33.8574142, "lng": -71.8278369 },
                { "lat": -33.8571558, "lng": -71.8275632 },
                { "lat": -33.8569431, "lng": -71.8273661 },
                { "lat": -33.8566803, "lng": -71.8270644 },
                { "lat": -33.8564553, "lng": -71.826839 },
                { "lat": -33.8559931, "lng": -71.8264849 },
                { "lat": -33.855629, "lng": -71.8261632 },
                { "lat": -33.8551334, "lng": -71.8257782 },
                { "lat": -33.8548226, "lng": -71.8256039 },
                { "lat": -33.8544473, "lng": -71.8254537 },
                { "lat": -33.8541811, "lng": -71.8254134 },
                { "lat": -33.8537924, "lng": -71.8253518 },
                { "lat": -33.8532912, "lng": -71.8253088 },
                { "lat": -33.8526074, "lng": -71.8252257 },
                { "lat": -33.8520761, "lng": -71.8251734 },
                { "lat": -33.8519347, "lng": -71.8251304 },
                { "lat": -33.8515995, "lng": -71.8248917 },
                { "lat": -33.8510983, "lng": -71.8245605 },
                { "lat": -33.8507017, "lng": -71.8243069 },
                { "lat": -33.850381, "lng": -71.8241743 },
                { "lat": -33.8500123, "lng": -71.8241314 },
                { "lat": -33.8492596, "lng": -71.8241708 },
                { "lat": -33.8489921, "lng": -71.8241395 },
                { "lat": -33.8488161, "lng": -71.8240429 },
                { "lat": -33.8485399, "lng": -71.8237921 },
                { "lat": -33.8481222, "lng": -71.8233482 },
                { "lat": -33.8478894, "lng": -71.8231765 },
                { "lat": -33.8471565, "lng": -71.8227568 },
                { "lat": -33.8469382, "lng": -71.8226763 },
                { "lat": -33.845987, "lng": -71.8224254 },
                { "lat": -33.8455225, "lng": -71.8223423 },
                { "lat": -33.8450391, "lng": -71.8223236 },
                { "lat": -33.8443975, "lng": -71.822294 },
                { "lat": -33.8438406, "lng": -71.8222913 },
                { "lat": -33.8435711, "lng": -71.8222244 },
                { "lat": -33.8433595, "lng": -71.8221049 },
                { "lat": -33.8432213, "lng": -71.8219575 },
                { "lat": -33.8431333, "lng": -71.8217577 },
                { "lat": -33.8430921, "lng": -71.8215243 },
                { "lat": -33.8430787, "lng": -71.8211019 },
                { "lat": -33.8430397, "lng": -71.8208309 },
                { "lat": -33.8429685, "lng": -71.8206499 },
                { "lat": -33.8424071, "lng": -71.8197956 },
                { "lat": -33.8421999, "lng": -71.8194697 },
                { "lat": -33.8419014, "lng": -71.8191935 },
                { "lat": -33.8415505, "lng": -71.8189546 },
                { "lat": -33.8404744, "lng": -71.8182373 },
                { "lat": -33.8400411, "lng": -71.8179409 },
                { "lat": -33.8397025, "lng": -71.817784 },
                { "lat": -33.8391355, "lng": -71.8175976 },
                { "lat": -33.8388036, "lng": -71.8174299 },
                { "lat": -33.8385396, "lng": -71.8171817 },
                { "lat": -33.8376607, "lng": -71.8162939 },
                { "lat": -33.8370781, "lng": -71.8157495 },
                { "lat": -33.8364375, "lng": -71.8154196 },
                { "lat": -33.8359173, "lng": -71.8152009 },
                { "lat": -33.8354573, "lng": -71.8150816 },
                { "lat": -33.8349114, "lng": -71.8150065 },
                { "lat": -33.8338643, "lng": -71.814816 },
                { "lat": -33.8325387, "lng": -71.8146551 },
                { "lat": -33.8314914, "lng": -71.8144701 },
                { "lat": -33.8299965, "lng": -71.8141991 },
                { "lat": -33.8291363, "lng": -71.8140248 },
                { "lat": -33.828742, "lng": -71.8139738 },
                { "lat": -33.8284112, "lng": -71.813947 },
                { "lat": -33.8271901, "lng": -71.8138558 },
                { "lat": -33.8264871, "lng": -71.8137968 },
                { "lat": -33.8261351, "lng": -71.8137123 },
                { "lat": -33.82589, "lng": -71.8136868 },
                { "lat": -33.8255112, "lng": -71.813613 },
                { "lat": -33.8250711, "lng": -71.8135487 },
                { "lat": -33.8243112, "lng": -71.8133891 },
                { "lat": -33.8235949, "lng": -71.8133006 },
                { "lat": -33.8232261, "lng": -71.8132563 },
                { "lat": -33.822737, "lng": -71.8131329 },
                { "lat": -33.8223581, "lng": -71.8130672 },
                { "lat": -33.8221777, "lng": -71.813082 },
                { "lat": -33.8218101, "lng": -71.8131235 },
                { "lat": -33.8215259, "lng": -71.8130955 },
                { "lat": -33.821234, "lng": -71.8130417 },
                { "lat": -33.8206012, "lng": -71.8130189 },
                { "lat": -33.8196886, "lng": -71.8129706 },
                { "lat": -33.8189199, "lng": -71.8129492 },
                { "lat": -33.8184074, "lng": -71.8129143 },
                { "lat": -33.8174647, "lng": -71.8126997 },
                { "lat": -33.8169856, "lng": -71.81263 },
                { "lat": -33.8166402, "lng": -71.8125495 },
                { "lat": -33.815849, "lng": -71.812319 },
                { "lat": -33.8145989, "lng": -71.8120212 },
                { "lat": -33.8133688, "lng": -71.8117341 },
                { "lat": -33.8125665, "lng": -71.811576 },
                { "lat": -33.811557, "lng": -71.8112621 },
                { "lat": -33.8109552, "lng": -71.8110449 },
                { "lat": -33.8103468, "lng": -71.8108517 },
                { "lat": -33.8098655, "lng": -71.8105915 },
                { "lat": -33.8094376, "lng": -71.8102804 },
                { "lat": -33.8093506, "lng": -71.8102509 },
                { "lat": -33.8092013, "lng": -71.8102469 },
                { "lat": -33.8091267, "lng": -71.8102227 },
                { "lat": -33.8090721, "lng": -71.8101597 },
                { "lat": -33.8090209, "lng": -71.8100604 },
                { "lat": -33.8088692, "lng": -71.8097587 },
                { "lat": -33.808613, "lng": -71.8093148 },
                { "lat": -33.8083009, "lng": -71.8088616 },
                { "lat": -33.808067, "lng": -71.8085262 },
                { "lat": -33.8077527, "lng": -71.8079228 },
                { "lat": -33.8075142, "lng": -71.807558 },
                { "lat": -33.8072401, "lng": -71.8072227 },
                { "lat": -33.8069036, "lng": -71.806984 },
                { "lat": -33.8065248, "lng": -71.8068203 },
                { "lat": -33.8063909, "lng": -71.8067345 },
                { "lat": -33.8062605, "lng": -71.8066165 },
                { "lat": -33.8061525, "lng": -71.8064287 },
                { "lat": -33.8060255, "lng": -71.8061337 },
                { "lat": -33.8058673, "lng": -71.8057394 },
                { "lat": -33.8056298, "lng": -71.8054537 },
                { "lat": -33.8053981, "lng": -71.8052512 },
                { "lat": -33.8050916, "lng": -71.8050863 },
                { "lat": -33.8045623, "lng": -71.8049308 },
                { "lat": -33.8042202, "lng": -71.8049012 },
                { "lat": -33.8039304, "lng": -71.8048436 },
                { "lat": -33.8037477, "lng": -71.8047524 },
                { "lat": -33.8036473, "lng": -71.8046532 },
                { "lat": -33.803546, "lng": -71.8044788 },
                { "lat": -33.8035314, "lng": -71.8043689 },
                { "lat": -33.8035627, "lng": -71.8042092 },
                { "lat": -33.8036518, "lng": -71.8040094 },
                { "lat": -33.8037844, "lng": -71.803768 },
                { "lat": -33.8038536, "lng": -71.8035588 },
                { "lat": -33.8038659, "lng": -71.8033201 },
                { "lat": -33.8038223, "lng": -71.8030586 },
                { "lat": -33.8036685, "lng": -71.8026374 },
                { "lat": -33.8036207, "lng": -71.8024161 },
                { "lat": -33.8035271, "lng": -71.8020675 },
                { "lat": -33.8034335, "lng": -71.8018945 },
                { "lat": -33.8033442, "lng": -71.8017618 },
                { "lat": -33.8028896, "lng": -71.8012924 },
                { "lat": -33.8024383, "lng": -71.8007881 },
                { "lat": -33.802037, "lng": -71.8003053 },
                { "lat": -33.8016672, "lng": -71.7999887 },
                { "lat": -33.8013205, "lng": -71.7997394 },
                { "lat": -33.8006786, "lng": -71.79949 },
                { "lat": -33.799952, "lng": -71.7992538 },
                { "lat": -33.799534, "lng": -71.7991319 },
                { "lat": -33.7988687, "lng": -71.7990098 },
                { "lat": -33.7985299, "lng": -71.7989534 },
                { "lat": -33.7983048, "lng": -71.7988811 },
                { "lat": -33.7980307, "lng": -71.7987013 },
                { "lat": -33.7977609, "lng": -71.798539 },
                { "lat": -33.7976619, "lng": -71.7984371 },
                { "lat": -33.7975872, "lng": -71.7982936 },
                { "lat": -33.7974589, "lng": -71.7980375 },
                { "lat": -33.7972717, "lng": -71.797768 },
                { "lat": -33.797021, "lng": -71.7975506 },
                { "lat": -33.7968238, "lng": -71.7974366 },
                { "lat": -33.7965317, "lng": -71.7972597 },
                { "lat": -33.7963188, "lng": -71.7971189 },
                { "lat": -33.7962041, "lng": -71.7969981 },
                { "lat": -33.7960803, "lng": -71.7968265 },
                { "lat": -33.7959829, "lng": -71.7967252 },
                { "lat": -33.7958062, "lng": -71.7966226 },
                { "lat": -33.795289, "lng": -71.7963705 },
                { "lat": -33.7947652, "lng": -71.7961908 },
                { "lat": -33.7943485, "lng": -71.7960459 },
                { "lat": -33.7940987, "lng": -71.7960245 },
                { "lat": -33.7936753, "lng": -71.7960459 },
                { "lat": -33.793274, "lng": -71.7961104 },
                { "lat": -33.7930802, "lng": -71.7960888 },
                { "lat": -33.792835, "lng": -71.7959869 },
                { "lat": -33.792592, "lng": -71.7959091 },
                { "lat": -33.7923758, "lng": -71.7958903 },
                { "lat": -33.791591, "lng": -71.796054 },
                { "lat": -33.7911185, "lng": -71.7961131 },
                { "lat": -33.7908555, "lng": -71.7960942 },
                { "lat": -33.7905969, "lng": -71.7960218 },
                { "lat": -33.7902135, "lng": -71.7959064 },
                { "lat": -33.789723, "lng": -71.7958099 },
                { "lat": -33.789431, "lng": -71.7957 },
                { "lat": -33.7891859, "lng": -71.7955443 },
                { "lat": -33.788954, "lng": -71.7953807 },
                { "lat": -33.7886331, "lng": -71.7952627 },
                { "lat": -33.7881671, "lng": -71.7950724 },
                { "lat": -33.7878417, "lng": -71.7949086 },
                { "lat": -33.7876099, "lng": -71.7948711 },
                { "lat": -33.7873334, "lng": -71.7949006 },
                { "lat": -33.7867448, "lng": -71.795067 },
                { "lat": -33.7862366, "lng": -71.7951582 },
                { "lat": -33.7859089, "lng": -71.79515 },
                { "lat": -33.7856871, "lng": -71.7950991 },
                { "lat": -33.7853973, "lng": -71.7949315 },
                { "lat": -33.7849236, "lng": -71.7945815 },
                { "lat": -33.7846684, "lng": -71.7944286 },
                { "lat": -33.784432, "lng": -71.7943588 },
                { "lat": -33.7842481, "lng": -71.7943454 },
                { "lat": -33.7838981, "lng": -71.7943401 },
                { "lat": -33.7833185, "lng": -71.7942677 },
                { "lat": -33.7829105, "lng": -71.7942355 },
                { "lat": -33.782235, "lng": -71.7942166 },
                { "lat": -33.7817579, "lng": -71.7942248 },
                { "lat": -33.7804003, "lng": -71.7941844 },
                { "lat": -33.7793279, "lng": -71.7942221 },
                { "lat": -33.7777004, "lng": -71.7942704 },
                { "lat": -33.7767975, "lng": -71.7943105 },
                { "lat": -33.7760774, "lng": -71.7943776 },
                { "lat": -33.7753595, "lng": -71.7944124 },
                { "lat": -33.7748311, "lng": -71.7943829 },
                { "lat": -33.7743874, "lng": -71.7943051 },
                { "lat": -33.7739036, "lng": -71.7941469 },
                { "lat": -33.7734309, "lng": -71.7938788 },
                { "lat": -33.7731478, "lng": -71.7936079 },
                { "lat": -33.7729404, "lng": -71.7932619 },
                { "lat": -33.7727197, "lng": -71.79283 },
                { "lat": -33.7723384, "lng": -71.7921595 },
                { "lat": -33.7719081, "lng": -71.791336 },
                { "lat": -33.7716093, "lng": -71.7907996 },
                { "lat": -33.7715469, "lng": -71.7906092 },
                { "lat": -33.7714622, "lng": -71.7901075 },
                { "lat": -33.7714287, "lng": -71.7895737 },
                { "lat": -33.7713819, "lng": -71.7893083 },
                { "lat": -33.7711322, "lng": -71.7886189 },
                { "lat": -33.7709561, "lng": -71.7882649 },
                { "lat": -33.7706952, "lng": -71.7879108 },
                { "lat": -33.7703384, "lng": -71.7874254 },
                { "lat": -33.7697922, "lng": -71.7865322 },
                { "lat": -33.7690965, "lng": -71.7853144 },
                { "lat": -33.7686439, "lng": -71.7841504 },
                { "lat": -33.7683317, "lng": -71.7831955 },
                { "lat": -33.768033, "lng": -71.7824793 },
                { "lat": -33.7676896, "lng": -71.7816585 },
                { "lat": -33.7676049, "lng": -71.7815003 },
                { "lat": -33.767451, "lng": -71.781275 },
                { "lat": -33.7672481, "lng": -71.7809157 },
                { "lat": -33.7669359, "lng": -71.7803738 },
                { "lat": -33.7666673, "lng": -71.7799849 },
                { "lat": -33.7664788, "lng": -71.7797568 },
                { "lat": -33.7663618, "lng": -71.7797019 },
                { "lat": -33.7663151, "lng": -71.7795933 },
                { "lat": -33.7662939, "lng": -71.7795129 },
                { "lat": -33.7663167, "lng": -71.7794264 },
                { "lat": -33.7663764, "lng": -71.7793519 },
                { "lat": -33.7665201, "lng": -71.7793223 },
                { "lat": -33.7665849, "lng": -71.7792594 },
                { "lat": -33.7666171, "lng": -71.7791319 },
                { "lat": -33.7666193, "lng": -71.7789441 },
                { "lat": -33.7665903, "lng": -71.7787497 },
                { "lat": -33.7664856, "lng": -71.7783086 },
                { "lat": -33.7664053, "lng": -71.7780511 },
                { "lat": -33.7663318, "lng": -71.7778767 },
                { "lat": -33.7662291, "lng": -71.7777452 },
                { "lat": -33.7661422, "lng": -71.7776836 },
                { "lat": -33.7661099, "lng": -71.777638 },
                { "lat": -33.7660977, "lng": -71.7775857 },
                { "lat": -33.7661254, "lng": -71.777528 },
                { "lat": -33.766217, "lng": -71.7773939 },
                { "lat": -33.7662581, "lng": -71.7772987 },
                { "lat": -33.7662593, "lng": -71.7771619 },
                { "lat": -33.7660597, "lng": -71.7763223 },
                { "lat": -33.7660407, "lng": -71.7761346 },
                { "lat": -33.7660742, "lng": -71.7759844 },
                { "lat": -33.7661478, "lng": -71.7757041 },
                { "lat": -33.7661801, "lng": -71.7754842 },
                { "lat": -33.7661477, "lng": -71.774815 },
                { "lat": -33.7661456, "lng": -71.7744274 },
                { "lat": -33.7660676, "lng": -71.7741122 },
                { "lat": -33.765762, "lng": -71.7731654 },
                { "lat": -33.7656985, "lng": -71.7729857 },
                { "lat": -33.7656148, "lng": -71.7728731 },
                { "lat": -33.7655915, "lng": -71.7727671 },
                { "lat": -33.765606, "lng": -71.7726893 },
                { "lat": -33.765645, "lng": -71.7725458 },
                { "lat": -33.7656539, "lng": -71.7723487 },
                { "lat": -33.7656527, "lng": -71.7720335 },
                { "lat": -33.7656227, "lng": -71.7719034 },
                { "lat": -33.765606, "lng": -71.7718243 },
                { "lat": -33.7656105, "lng": -71.7716996 },
                { "lat": -33.7656851, "lng": -71.771552 },
                { "lat": -33.7658802, "lng": -71.7712489 },
                { "lat": -33.7660954, "lng": -71.7708922 },
                { "lat": -33.7662001, "lng": -71.7706777 },
                { "lat": -33.7664277, "lng": -71.7699776 },
                { "lat": -33.7666104, "lng": -71.769248 },
                { "lat": -33.7668246, "lng": -71.7686633 },
                { "lat": -33.7674153, "lng": -71.7674697 },
                { "lat": -33.767694, "lng": -71.766767 },
                { "lat": -33.768024, "lng": -71.7652086 },
                { "lat": -33.7682292, "lng": -71.7636771 },
                { "lat": -33.7681913, "lng": -71.7622742 },
                { "lat": -33.7681334, "lng": -71.7610727 },
                { "lat": -33.768024, "lng": -71.7602331 },
                { "lat": -33.7677409, "lng": -71.7591603 },
                { "lat": -33.7667999, "lng": -71.7569367 },
                { "lat": -33.7662336, "lng": -71.755196 },
                { "lat": -33.7659415, "lng": -71.7538173 },
                { "lat": -33.765868, "lng": -71.7527525 },
                { "lat": -33.7658568, "lng": -71.7515373 },
                { "lat": -33.7659098, "lng": -71.7504131 },
                { "lat": -33.7658362, "lng": -71.7498526 },
                { "lat": -33.7657237, "lng": -71.7493464 },
                { "lat": -33.7656138, "lng": -71.7489585 },
                { "lat": -33.7654087, "lng": -71.7483429 },
                { "lat": -33.7651901, "lng": -71.7477233 },
                { "lat": -33.7645969, "lng": -71.7462628 },
                { "lat": -33.7639927, "lng": -71.7448771 },
                { "lat": -33.7629447, "lng": -71.7424099 },
                { "lat": -33.762026, "lng": -71.7403741 },
                { "lat": -33.7615622, "lng": -71.7394648 },
                { "lat": -33.7611876, "lng": -71.7387916 },
                { "lat": -33.7605365, "lng": -71.7375873 },
                { "lat": -33.759087, "lng": -71.7348085 },
                { "lat": -33.7580702, "lng": -71.7327405 },
                { "lat": -33.7573989, "lng": -71.7313082 },
                { "lat": -33.7563999, "lng": -71.7291195 },
                { "lat": -33.7540316, "lng": -71.7247261 },
                { "lat": -33.7531797, "lng": -71.7231489 },
                { "lat": -33.7522297, "lng": -71.7214913 },
                { "lat": -33.7512529, "lng": -71.7196835 },
                { "lat": -33.7500709, "lng": -71.7175968 },
                { "lat": -33.7487997, "lng": -71.7151399 },
                { "lat": -33.746962, "lng": -71.7116476 },
                { "lat": -33.7452625, "lng": -71.708547 },
                { "lat": -33.7438842, "lng": -71.7060579 },
                { "lat": -33.7426307, "lng": -71.704014 },
                { "lat": -33.740775, "lng": -71.7010636 },
                { "lat": -33.7386427, "lng": -71.6976411 },
                { "lat": -33.7367602, "lng": -71.6945512 },
                { "lat": -33.7337279, "lng": -71.6893705 },
                { "lat": -33.7310878, "lng": -71.6853407 },
                { "lat": -33.7294727, "lng": -71.6829074 },
                { "lat": -33.7276101, "lng": -71.6803087 },
                { "lat": -33.7254502, "lng": -71.6775923 },
                { "lat": -33.7237196, "lng": -71.6754861 },
                { "lat": -33.7210786, "lng": -71.6725798 },
                { "lat": -33.718404, "lng": -71.6698247 },
                { "lat": -33.7155676, "lng": -71.667182 },
                { "lat": -33.7128778, "lng": -71.6648819 },
                { "lat": -33.7104239, "lng": -71.6629459 },
                { "lat": -33.7076068, "lng": -71.6609578 },
                { "lat": -33.7045767, "lng": -71.6591192 },
                { "lat": -33.7022025, "lng": -71.657703 },
                { "lat": -33.695442, "lng": -71.6539865 },
                { "lat": -33.6938198, "lng": -71.6531714 },
                { "lat": -33.6934203, "lng": -71.6529193 },
                { "lat": -33.6930699, "lng": -71.6525787 },
                { "lat": -33.6928077, "lng": -71.6523332 },
                { "lat": -33.6922052, "lng": -71.6518102 },
                { "lat": -33.6909487, "lng": -71.6505308 },
                { "lat": -33.6900359, "lng": -71.6497422 },
                { "lat": -33.6889826, "lng": -71.6491092 },
                { "lat": -33.6879805, "lng": -71.648605 },
                { "lat": -33.6867519, "lng": -71.6480042 },
                { "lat": -33.6851795, "lng": -71.6472666 },
                { "lat": -33.6840718, "lng": -71.6467837 },
                { "lat": -33.6820415, "lng": -71.6459979 },
                { "lat": -33.6800751, "lng": -71.6453863 },
                { "lat": -33.6785998, "lng": -71.6448231 },
                { "lat": -33.678227, "lng": -71.6446809 },
                { "lat": -33.6778677, "lng": -71.6444073 },
                { "lat": -33.6769324, "lng": -71.6437073 },
                { "lat": -33.6767081, "lng": -71.6436013 },
                { "lat": -33.6766222, "lng": -71.6435329 },
                { "lat": -33.6764983, "lng": -71.643368 },
                { "lat": -33.6762784, "lng": -71.6430984 },
                { "lat": -33.6759124, "lng": -71.6427417 },
                { "lat": -33.6753901, "lng": -71.6421301 },
                { "lat": -33.6748789, "lng": -71.6417761 },
                { "lat": -33.6742337, "lng": -71.6415265 },
                { "lat": -33.6733521, "lng": -71.6412717 },
                { "lat": -33.6718542, "lng": -71.6408291 },
                { "lat": -33.670582, "lng": -71.6405465 },
                { "lat": -33.668861, "lng": -71.6401718 },
                { "lat": -33.6670527, "lng": -71.63981 },
                { "lat": -33.6635768, "lng": -71.6390188 },
                { "lat": -33.6621347, "lng": -71.6387291 },
                { "lat": -33.6599111, "lng": -71.6382758 },
                { "lat": -33.6590226, "lng": -71.638088 },
                { "lat": -33.6586855, "lng": -71.6379781 },
                { "lat": -33.6583505, "lng": -71.6377715 },
                { "lat": -33.6580202, "lng": -71.6374979 },
                { "lat": -33.6576562, "lng": -71.6372458 },
                { "lat": -33.6570156, "lng": -71.6370098 },
                { "lat": -33.6565801, "lng": -71.6368918 },
                { "lat": -33.6555889, "lng": -71.6366423 },
                { "lat": -33.6551134, "lng": -71.6365619 },
                { "lat": -33.6544971, "lng": -71.6364814 },
                { "lat": -33.653958, "lng": -71.6363741 },
                { "lat": -33.6535059, "lng": -71.6363687 },
                { "lat": -33.6532268, "lng": -71.6363607 },
                { "lat": -33.6527445, "lng": -71.6362695 },
                { "lat": -33.6520412, "lng": -71.6362211 },
                { "lat": -33.6509382, "lng": -71.6361139 },
                { "lat": -33.649181, "lng": -71.635961 },
                { "lat": -33.6474908, "lng": -71.6358617 },
                { "lat": -33.6450613, "lng": -71.6356981 },
                { "lat": -33.643523, "lng": -71.6356123 },
                { "lat": -33.6418326, "lng": -71.6354486 },
                { "lat": -33.6411672, "lng": -71.6354165 },
                { "lat": -33.6392243, "lng": -71.635371 },
                { "lat": -33.6376009, "lng": -71.6353495 },
                { "lat": -33.6363571, "lng": -71.6353897 },
                { "lat": -33.6353478, "lng": -71.6354005 },
                { "lat": -33.634182, "lng": -71.6354595 },
                { "lat": -33.6334785, "lng": -71.6354326 },
                { "lat": -33.6328823, "lng": -71.6354662 },
                { "lat": -33.6321296, "lng": -71.6354903 },
                { "lat": -33.6319331, "lng": -71.635489 },
                { "lat": -33.6314787, "lng": -71.6355279 },
                { "lat": -33.6312754, "lng": -71.6355614 },
                { "lat": -33.6311058, "lng": -71.6355908 },
                { "lat": -33.6307518, "lng": -71.6356163 },
                { "lat": -33.6304369, "lng": -71.6356498 },
                { "lat": -33.630189, "lng": -71.6356659 },
                { "lat": -33.6300595, "lng": -71.6356699 },
                { "lat": -33.6299289, "lng": -71.6356735 },
                { "lat": -33.6298424, "lng": -71.6356795 },
                { "lat": -33.629768, "lng": -71.6357022 },
                { "lat": -33.6297055, "lng": -71.635741 },
                { "lat": -33.629625, "lng": -71.6357532 },
                { "lat": -33.6294877, "lng": -71.6357505 },
                { "lat": -33.6293594, "lng": -71.6357249 },
                { "lat": -33.6291986, "lng": -71.6357397 },
                { "lat": -33.6291115, "lng": -71.6358 },
                { "lat": -33.6290589, "lng": -71.6358979 },
                { "lat": -33.6290544, "lng": -71.6360348 },
                { "lat": -33.6290656, "lng": -71.6361863 },
                { "lat": -33.6290444, "lng": -71.6362829 },
                { "lat": -33.628993, "lng": -71.6363164 },
                { "lat": -33.6289562, "lng": -71.6363272 },
                { "lat": -33.628926, "lng": -71.6363057 },
                { "lat": -33.6289204, "lng": -71.6362761 },
                { "lat": -33.628926, "lng": -71.6362346 },
                { "lat": -33.6289227, "lng": -71.6361837 },
                { "lat": -33.628907, "lng": -71.636146 },
                { "lat": -33.6288601, "lng": -71.6360776 },
                { "lat": -33.6288222, "lng": -71.6359355 },
                { "lat": -33.6287674, "lng": -71.6358202 },
                { "lat": -33.6287038, "lng": -71.6357411 },
                { "lat": -33.6285956, "lng": -71.6356619 },
                { "lat": -33.6285463, "lng": -71.6355882 },
                { "lat": -33.6284269, "lng": -71.6354822 },
                { "lat": -33.6283667, "lng": -71.6354352 },
                { "lat": -33.6282951, "lng": -71.6353978 },
                { "lat": -33.6281444, "lng": -71.6353829 },
                { "lat": -33.6280438, "lng": -71.63545 },
                { "lat": -33.6279567, "lng": -71.6354567 },
                { "lat": -33.6278853, "lng": -71.6354367 },
                { "lat": -33.6278239, "lng": -71.6353817 },
                { "lat": -33.6277602, "lng": -71.6353641 },
                { "lat": -33.62772, "lng": -71.6353575 },
                { "lat": -33.627691, "lng": -71.6353388 },
                { "lat": -33.627682, "lng": -71.6352824 },
                { "lat": -33.6277167, "lng": -71.6351993 },
                { "lat": -33.6277323, "lng": -71.6351416 },
                { "lat": -33.6277245, "lng": -71.6350746 },
                { "lat": -33.6276832, "lng": -71.6349753 },
                { "lat": -33.6276441, "lng": -71.6349096 },
                { "lat": -33.6276206, "lng": -71.6348506 },
                { "lat": -33.6276095, "lng": -71.6348171 },
                { "lat": -33.6275794, "lng": -71.6347472 },
                { "lat": -33.6275469, "lng": -71.6347245 },
                { "lat": -33.6275034, "lng": -71.634711 },
                { "lat": -33.62747, "lng": -71.6347016 },
                { "lat": -33.6274219, "lng": -71.6346896 },
                { "lat": -33.6273973, "lng": -71.6346788 },
                { "lat": -33.6273593, "lng": -71.6346681 },
                { "lat": -33.6273225, "lng": -71.6346507 },
                { "lat": -33.6272968, "lng": -71.6346253 },
                { "lat": -33.62727, "lng": -71.6346012 },
                { "lat": -33.6272543, "lng": -71.6345502 },
                { "lat": -33.6272387, "lng": -71.6344738 },
                { "lat": -33.6272331, "lng": -71.6344026 },
                { "lat": -33.6272321, "lng": -71.6343597 },
                { "lat": -33.6272275, "lng": -71.634302 },
                { "lat": -33.6272186, "lng": -71.634247 },
                { "lat": -33.6272019, "lng": -71.6342148 },
                { "lat": -33.6271795, "lng": -71.6342082 },
                { "lat": -33.6270891, "lng": -71.6341881 },
                { "lat": -33.6270422, "lng": -71.6341801 },
                { "lat": -33.6270042, "lng": -71.6341545 },
                { "lat": -33.6269296, "lng": -71.6339613 },
                { "lat": -33.6268776, "lng": -71.6336543 },
                { "lat": -33.6268166, "lng": -71.6333579 },
                { "lat": -33.6266659, "lng": -71.6331124 },
                { "lat": -33.6265084, "lng": -71.6329649 },
                { "lat": -33.6262884, "lng": -71.6328054 },
                { "lat": -33.626007, "lng": -71.63258 },
                { "lat": -33.6255279, "lng": -71.6322809 },
                { "lat": -33.6251404, "lng": -71.6320584 },
                { "lat": -33.6247285, "lng": -71.631825 },
                { "lat": -33.6242606, "lng": -71.6316949 },
                { "lat": -33.6238105, "lng": -71.6316198 },
                { "lat": -33.6233348, "lng": -71.6315487 },
                { "lat": -33.6231437, "lng": -71.631546 },
                { "lat": -33.6228266, "lng": -71.6315648 },
                { "lat": -33.622285, "lng": -71.6315621 },
                { "lat": -33.6216976, "lng": -71.6315192 },
                { "lat": -33.6207751, "lng": -71.6313529 },
                { "lat": -33.6199822, "lng": -71.6311357 },
                { "lat": -33.6192474, "lng": -71.6309291 },
                { "lat": -33.6185393, "lng": -71.630736 },
                { "lat": -33.6181887, "lng": -71.6305777 },
                { "lat": -33.6178759, "lng": -71.630508 },
                { "lat": -33.6174181, "lng": -71.6304356 },
                { "lat": -33.6168306, "lng": -71.6303578 },
                { "lat": -33.6163192, "lng": -71.6302022 },
                { "lat": -33.6174804, "lng": -71.6294631 },
                { "lat": -33.6185113, "lng": -71.6280925 },
                { "lat": -33.6197382, "lng": -71.6253839 },
                { "lat": -33.6217593, "lng": -71.6204712 },
                { "lat": -33.6229848, "lng": -71.6154486 },
                { "lat": -33.6230938, "lng": -71.6134054 },
                { "lat": -33.6234001, "lng": -71.612035 },
                { "lat": -33.624347, "lng": -71.6109027 },
                { "lat": -33.6272016, "lng": -71.6093702 },
                { "lat": -33.6278004, "lng": -71.6088905 },
                { "lat": -33.6285564, "lng": -71.6081861 },
                { "lat": -33.6298748, "lng": -71.6068697 },
                { "lat": -33.6302139, "lng": -71.6060643 },
                { "lat": -33.630722, "lng": -71.604617 },
                { "lat": -33.6310809, "lng": -71.6020098 },
                { "lat": -33.6317359, "lng": -71.6002977 },
                { "lat": -33.6325882, "lng": -71.5995884 },
                { "lat": -33.6339061, "lng": -71.5987675 },
                { "lat": -33.6353676, "lng": -71.5984335 },
                { "lat": -33.6360126, "lng": -71.5981813 },
                { "lat": -33.6364781, "lng": -71.5978135 },
                { "lat": -33.6372136, "lng": -71.5971551 },
                { "lat": -33.6378789, "lng": -71.5965123 },
                { "lat": -33.6384639, "lng": -71.5954125 },
                { "lat": -33.6394489, "lng": -71.5942331 },
                { "lat": -33.6402886, "lng": -71.5929426 },
                { "lat": -33.6417649, "lng": -71.5916758 },
                { "lat": -33.642667, "lng": -71.5913325 },
                { "lat": -33.6442747, "lng": -71.59056 },
                { "lat": -33.6450867, "lng": -71.5897053 },
                { "lat": -33.6456668, "lng": -71.5889946 },
                { "lat": -33.6464802, "lng": -71.588061 },
                { "lat": -33.6468494, "lng": -71.5877427 },
                { "lat": -33.6472246, "lng": -71.5874748 },
                { "lat": -33.6476613, "lng": -71.5874455 },
                { "lat": -33.6494532, "lng": -71.5870688 },
                { "lat": -33.650418, "lng": -71.5868392 },
                { "lat": -33.6514828, "lng": -71.5864571 },
                { "lat": -33.6526148, "lng": -71.5860152 },
                { "lat": -33.6533678, "lng": -71.5858465 },
                { "lat": -33.6552197, "lng": -71.5854218 },
                { "lat": -33.6567862, "lng": -71.5857701 },
                { "lat": -33.6576384, "lng": -71.5857371 },
                { "lat": -33.6594431, "lng": -71.5854714 },
                { "lat": -33.66053, "lng": -71.5845831 },
                { "lat": -33.661148, "lng": -71.5841061 },
                { "lat": -33.6624408, "lng": -71.5839628 },
                { "lat": -33.662764, "lng": -71.5842247 },
                { "lat": -33.6642539, "lng": -71.5830557 },
                { "lat": -33.665305, "lng": -71.5827286 },
                { "lat": -33.6671228, "lng": -71.5814405 },
                { "lat": -33.669416, "lng": -71.5802493 },
                { "lat": -33.6706394, "lng": -71.5794413 },
                { "lat": -33.6716264, "lng": -71.5788663 },
                { "lat": -33.6723483, "lng": -71.5784535 },
                { "lat": -33.6726539, "lng": -71.5781987 },
                { "lat": -33.6730183, "lng": -71.5780137 },
                { "lat": -33.67329, "lng": -71.577749 },
                { "lat": -33.6740702, "lng": -71.5769878 },
                { "lat": -33.6754624, "lng": -71.575358 },
                { "lat": -33.6767963, "lng": -71.5743783 },
                { "lat": -33.6776937, "lng": -71.5740078 },
                { "lat": -33.6793393, "lng": -71.5738637 },
                { "lat": -33.6804329, "lng": -71.5740642 },
                { "lat": -33.6812518, "lng": -71.5743607 },
                { "lat": -33.68187, "lng": -71.5747341 },
                { "lat": -33.6830538, "lng": -71.5744568 },
                { "lat": -33.6845108, "lng": -71.5740577 },
                { "lat": -33.6858374, "lng": -71.5726029 },
                { "lat": -33.6860392, "lng": -71.5713562 },
                { "lat": -33.6857535, "lng": -71.5697038 },
                { "lat": -33.685675, "lng": -71.5685644 },
                { "lat": -33.6858249, "lng": -71.5676182 },
                { "lat": -33.6863659, "lng": -71.5669702 },
                { "lat": -33.6871605, "lng": -71.5665346 },
                { "lat": -33.6882692, "lng": -71.5666569 },
                { "lat": -33.6896029, "lng": -71.5672985 },
                { "lat": -33.690692, "lng": -71.5678307 },
                { "lat": -33.6927023, "lng": -71.5682684 },
                { "lat": -33.6943555, "lng": -71.5683263 },
                { "lat": -33.6960658, "lng": -71.5681783 },
                { "lat": -33.6981492, "lng": -71.5678714 },
                { "lat": -33.6997112, "lng": -71.5674144 },
                { "lat": -33.7011072, "lng": -71.5668522 },
                { "lat": -33.7024354, "lng": -71.5664145 },
                { "lat": -33.7031092, "lng": -71.5658342 },
                { "lat": -33.7031613, "lng": -71.565465 },
                { "lat": -33.7029037, "lng": -71.5646899 },
                { "lat": -33.7024473, "lng": -71.5636926 },
                { "lat": -33.7022711, "lng": -71.5627589 },
                { "lat": -33.7022581, "lng": -71.5619719 },
                { "lat": -33.7037864, "lng": -71.559092 },
                { "lat": -33.7048509, "lng": -71.5581205 },
                { "lat": -33.7059395, "lng": -71.5576168 },
                { "lat": -33.7067816, "lng": -71.5569343 },
                { "lat": -33.7075068, "lng": -71.5567156 },
                { "lat": -33.7089596, "lng": -71.5562845 },
                { "lat": -33.7100468, "lng": -71.5558745 },
                { "lat": -33.7112927, "lng": -71.5556234 },
                { "lat": -33.7125886, "lng": -71.5555075 },
                { "lat": -33.7142855, "lng": -71.5552532 },
                { "lat": -33.7155551, "lng": -71.5551835 },
                { "lat": -33.7172828, "lng": -71.5552887 },
                { "lat": -33.7185447, "lng": -71.5552629 },
                { "lat": -33.7205597, "lng": -71.5552672 },
                { "lat": -33.7220392, "lng": -71.5553144 },
                { "lat": -33.7233617, "lng": -71.5556556 },
                { "lat": -33.7245075, "lng": -71.5564023 },
                { "lat": -33.7255247, "lng": -71.5574495 },
                { "lat": -33.7265545, "lng": -71.5584344 },
                { "lat": -33.7274735, "lng": -71.5593249 },
                { "lat": -33.7285889, "lng": -71.559945 },
                { "lat": -33.7297596, "lng": -71.5604578 },
                { "lat": -33.7313621, "lng": -71.5608805 },
                { "lat": -33.732597, "lng": -71.561256 },
                { "lat": -33.7340656, "lng": -71.5613032 },
                { "lat": -33.7354504, "lng": -71.5611209 },
                { "lat": -33.7368708, "lng": -71.5607303 },
                { "lat": -33.7375421, "lng": -71.559536 },
                { "lat": -33.7383839, "lng": -71.5578745 },
                { "lat": -33.7390344, "lng": -71.5565889 },
                { "lat": -33.7395271, "lng": -71.5558808 },
                { "lat": -33.7399595, "lng": -71.5557779 },
                { "lat": -33.7414515, "lng": -71.5548793 },
                { "lat": -33.742274, "lng": -71.5540838 },
                { "lat": -33.7428769, "lng": -71.5532437 },
                { "lat": -33.7435728, "lng": -71.5523382 },
                { "lat": -33.7441973, "lng": -71.5511538 },
                { "lat": -33.744729, "lng": -71.5500058 },
                { "lat": -33.745093, "lng": -71.5488557 },
                { "lat": -33.745332, "lng": -71.5478128 },
                { "lat": -33.7457194, "lng": -71.5468542 },
                { "lat": -33.7466952, "lng": -71.5460855 },
                { "lat": -33.7470574, "lng": -71.5450061 },
                { "lat": -33.7475912, "lng": -71.5440569 },
                { "lat": -33.7459107, "lng": -71.5418651 },
                { "lat": -33.7447973, "lng": -71.5405409 },
                { "lat": -33.7451046, "lng": -71.5391027 },
                { "lat": -33.7459922, "lng": -71.5382942 },
                { "lat": -33.7467505, "lng": -71.5374788 },
                { "lat": -33.7471466, "lng": -71.5367356 },
                { "lat": -33.7476262, "lng": -71.5358508 },
                { "lat": -33.7484829, "lng": -71.5366527 },
                { "lat": -33.7494125, "lng": -71.5373329 },
                { "lat": -33.7511609, "lng": -71.5389916 },
                { "lat": -33.7525811, "lng": -71.5397855 },
                { "lat": -33.7539762, "lng": -71.5403069 },
                { "lat": -33.7548782, "lng": -71.5397991 },
                { "lat": -33.7560243, "lng": -71.5392641 },
                { "lat": -33.7576727, "lng": -71.538807 },
                { "lat": -33.7593728, "lng": -71.5388607 },
                { "lat": -33.7604664, "lng": -71.5385581 },
                { "lat": -33.7618132, "lng": -71.5381333 },
                { "lat": -33.7630191, "lng": -71.5372664 },
                { "lat": -33.7641018, "lng": -71.5356178 },
                { "lat": -33.764159, "lng": -71.5372019 },
                { "lat": -33.7642072, "lng": -71.5386546 },
                { "lat": -33.7640181, "lng": -71.5407447 },
                { "lat": -33.764316, "lng": -71.5419892 },
                { "lat": -33.7645176, "lng": -71.5438602 },
                { "lat": -33.7650724, "lng": -71.5451369 },
                { "lat": -33.7659732, "lng": -71.5467913 },
                { "lat": -33.767163, "lng": -71.5481474 },
                { "lat": -33.7682279, "lng": -71.5491173 },
                { "lat": -33.76864, "lng": -71.5503597 },
                { "lat": -33.7705414, "lng": -71.5518017 },
                { "lat": -33.7713049, "lng": -71.5526793 },
                { "lat": -33.7719184, "lng": -71.5525184 },
                { "lat": -33.7724945, "lng": -71.5526793 },
                { "lat": -33.7732671, "lng": -71.5532024 },
                { "lat": -33.7734621, "lng": -71.553365 },
                { "lat": -33.7746304, "lng": -71.553703 },
                { "lat": -33.7750852, "lng": -71.5538263 },
                { "lat": -33.7753795, "lng": -71.5539444 },
                { "lat": -33.7757229, "lng": -71.5540516 },
                { "lat": -33.7760038, "lng": -71.5541375 },
                { "lat": -33.776414, "lng": -71.5542394 },
                { "lat": -33.7772077, "lng": -71.5544057 },
                { "lat": -33.7778632, "lng": -71.5545827 },
                { "lat": -33.7786078, "lng": -71.554808 },
                { "lat": -33.7794416, "lng": -71.5550923 },
                { "lat": -33.7799098, "lng": -71.5552533 },
                { "lat": -33.7805162, "lng": -71.5554303 },
                { "lat": -33.7808952, "lng": -71.555543 },
                { "lat": -33.7811716, "lng": -71.555602 },
                { "lat": -33.7814703, "lng": -71.555661 },
                { "lat": -33.7817646, "lng": -71.5557146 },
                { "lat": -33.7824379, "lng": -71.5558487 },
                { "lat": -33.7828972, "lng": -71.5559023 },
                { "lat": -33.7833742, "lng": -71.5559721 },
                { "lat": -33.7839583, "lng": -71.5560311 },
                { "lat": -33.7844265, "lng": -71.5560633 },
                { "lat": -33.785015, "lng": -71.5560901 },
                { "lat": -33.7854296, "lng": -71.5560847 },
                { "lat": -33.786094, "lng": -71.5561062 },
                { "lat": -33.7865131, "lng": -71.5561223 },
                { "lat": -33.7869544, "lng": -71.556192 },
                { "lat": -33.7874226, "lng": -71.5562618 },
                { "lat": -33.787748, "lng": -71.5563261 },
                { "lat": -33.788185, "lng": -71.5563905 },
                { "lat": -33.7885684, "lng": -71.5564441 },
                { "lat": -33.7889651, "lng": -71.5564978 },
                { "lat": -33.7894198, "lng": -71.5565353 },
                { "lat": -33.7899191, "lng": -71.5565514 },
                { "lat": -33.7906949, "lng": -71.5565514 },
                { "lat": -33.7918229, "lng": -71.5565676 },
                { "lat": -33.7926253, "lng": -71.5566158 },
                { "lat": -33.793138, "lng": -71.556648 },
                { "lat": -33.7938825, "lng": -71.5566695 },
                { "lat": -33.7947474, "lng": -71.5566588 },
                { "lat": -33.7956791, "lng": -71.5566802 },
                { "lat": -33.7961828, "lng": -71.556707 },
                { "lat": -33.7967, "lng": -71.5567285 },
                { "lat": -33.7975782, "lng": -71.5567339 },
                { "lat": -33.7985723, "lng": -71.5567714 },
                { "lat": -33.7995708, "lng": -71.5567714 },
                { "lat": -33.800498, "lng": -71.5568143 },
                { "lat": -33.8017506, "lng": -71.5568733 },
                { "lat": -33.8026466, "lng": -71.5569055 },
                { "lat": -33.8044074, "lng": -71.5569431 },
                { "lat": -33.8052677, "lng": -71.5569806 },
                { "lat": -33.8071309, "lng": -71.5570396 },
                { "lat": -33.8092971, "lng": -71.5570933 },
                { "lat": -33.8123481, "lng": -71.5572676 },
                { "lat": -33.8138345, "lng": -71.5573293 },
                { "lat": -33.8152362, "lng": -71.5573561 },
                { "lat": -33.8159237, "lng": -71.5573535 },
                { "lat": -33.8159984, "lng": -71.5573482 },
                { "lat": -33.8160752, "lng": -71.5573444 },
                { "lat": -33.8166268, "lng": -71.557332 },
                { "lat": -33.8177744, "lng": -71.5573025 },
                { "lat": -33.8190736, "lng": -71.5572945 },
                { "lat": -33.8208785, "lng": -71.5572597 },
                { "lat": -33.8228639, "lng": -71.5572006 },
                { "lat": -33.8268048, "lng": -71.5571412 },
                { "lat": -33.830872, "lng": -71.5571147 },
                { "lat": -33.831605, "lng": -71.5571147 },
                { "lat": -33.8325408, "lng": -71.5571255 },
                { "lat": -33.8329708, "lng": -71.5571255 },
                { "lat": -33.8332329, "lng": -71.5571191 },
                { "lat": -33.8339935, "lng": -71.5570986 },
                { "lat": -33.8352189, "lng": -71.5570745 },
                { "lat": -33.8381285, "lng": -71.5569779 },
                { "lat": -33.8387234, "lng": -71.5569673 },
                { "lat": -33.8402049, "lng": -71.5569431 },
                { "lat": -33.8417777, "lng": -71.5569404 },
                { "lat": -33.8451301, "lng": -71.5569153 },
                { "lat": -33.845948, "lng": -71.5568867 },
                { "lat": -33.8467366, "lng": -71.5568572 },
                { "lat": -33.8476455, "lng": -71.5568379 },
                { "lat": -33.8479975, "lng": -71.5568304 },
                { "lat": -33.8517799, "lng": -71.5567955 },
                { "lat": -33.8529471, "lng": -71.5567982 },
                { "lat": -33.8542012, "lng": -71.5567955 },
                { "lat": -33.8546667, "lng": -71.5567875 },
                { "lat": -33.855012, "lng": -71.5567929 },
                { "lat": -33.8552659, "lng": -71.5568036 },
                { "lat": -33.8555421, "lng": -71.5567821 },
                { "lat": -33.8558896, "lng": -71.5567499 },
                { "lat": -33.8565052, "lng": -71.5567115 },
                { "lat": -33.8571948, "lng": -71.5567124 },
                { "lat": -33.8578675, "lng": -71.5567124 },
                { "lat": -33.8584377, "lng": -71.5567499 },
                { "lat": -33.8586203, "lng": -71.556766 },
                { "lat": -33.8589455, "lng": -71.5567607 },
                { "lat": -33.8596672, "lng": -71.5567285 },
                { "lat": -33.8603947, "lng": -71.5566857 },
                { "lat": -33.8653422, "lng": -71.556522 },
                { "lat": -33.8655226, "lng": -71.556522 },
                { "lat": -33.8656629, "lng": -71.5565139 },
                { "lat": -33.865968, "lng": -71.556522 },
                { "lat": -33.8662349, "lng": -71.5565322 },
                { "lat": -33.8664536, "lng": -71.5565273 },
                { "lat": -33.8666629, "lng": -71.5565166 },
                { "lat": -33.8674446, "lng": -71.5564871 },
                { "lat": -33.8686317, "lng": -71.5564629 },
                { "lat": -33.8695782, "lng": -71.556471 },
                { "lat": -33.8700659, "lng": -71.5564629 },
                { "lat": -33.8707674, "lng": -71.5564603 },
                { "lat": -33.8712574, "lng": -71.5564415 },
                { "lat": -33.8716649, "lng": -71.5564334 },
                { "lat": -33.8720079, "lng": -71.5564361 },
                { "lat": -33.8722484, "lng": -71.5564549 },
                { "lat": -33.8723931, "lng": -71.5564549 },
                { "lat": -33.8725513, "lng": -71.5564415 },
                { "lat": -33.873834, "lng": -71.5564442 },
                { "lat": -33.8737716, "lng": -71.5565783 },
                { "lat": -33.8736959, "lng": -71.5567124 },
                { "lat": -33.8736135, "lng": -71.5568385 },
                { "lat": -33.8735311, "lng": -71.5569323 },
                { "lat": -33.8734376, "lng": -71.5570155 },
                { "lat": -33.8733708, "lng": -71.5571067 },
                { "lat": -33.8733396, "lng": -71.5572059 },
                { "lat": -33.8733173, "lng": -71.5573159 },
                { "lat": -33.8732795, "lng": -71.557391 },
                { "lat": -33.8732127, "lng": -71.5575063 },
                { "lat": -33.8731815, "lng": -71.5576324 },
                { "lat": -33.8731592, "lng": -71.5577289 },
                { "lat": -33.8731681, "lng": -71.5578201 },
                { "lat": -33.8731748, "lng": -71.5579006 },
                { "lat": -33.8732015, "lng": -71.5579945 },
                { "lat": -33.8732171, "lng": -71.5580857 },
                { "lat": -33.8732082, "lng": -71.5581876 },
                { "lat": -33.8731815, "lng": -71.5582868 },
                { "lat": -33.8731058, "lng": -71.5584183 },
                { "lat": -33.8730434, "lng": -71.5585336 },
                { "lat": -33.8730234, "lng": -71.5586221 },
                { "lat": -33.8730412, "lng": -71.5587321 },
                { "lat": -33.8730635, "lng": -71.5588474 },
                { "lat": -33.8731503, "lng": -71.5590701 },
                { "lat": -33.8731904, "lng": -71.5591317 },
                { "lat": -33.8732327, "lng": -71.5591854 },
                { "lat": -33.8732862, "lng": -71.5592203 },
                { "lat": -33.8733396, "lng": -71.5592364 },
                { "lat": -33.8734131, "lng": -71.5592819 },
                { "lat": -33.8734844, "lng": -71.5593919 },
                { "lat": -33.8735734, "lng": -71.5595287 },
                { "lat": -33.8736247, "lng": -71.5596414 },
                { "lat": -33.8736803, "lng": -71.5597969 },
                { "lat": -33.8737293, "lng": -71.5599203 },
                { "lat": -33.8737694, "lng": -71.560033 },
                { "lat": -33.8737872, "lng": -71.5601322 },
                { "lat": -33.8738273, "lng": -71.5603253 },
                { "lat": -33.8738295, "lng": -71.5604568 },
                { "lat": -33.8738362, "lng": -71.560556 },
                { "lat": -33.8738162, "lng": -71.5606365 },
                { "lat": -33.8737828, "lng": -71.5606901 },
                { "lat": -33.8737382, "lng": -71.5607277 },
                { "lat": -33.873667, "lng": -71.5607491 },
                { "lat": -33.8735912, "lng": -71.5607464 },
                { "lat": -33.8735311, "lng": -71.5607196 },
                { "lat": -33.873442, "lng": -71.5606982 },
                { "lat": -33.8732862, "lng": -71.5606579 },
                { "lat": -33.8731815, "lng": -71.5606445 },
                { "lat": -33.8730746, "lng": -71.5606257 },
                { "lat": -33.8729833, "lng": -71.5605828 },
                { "lat": -33.8728853, "lng": -71.560556 },
                { "lat": -33.8728051, "lng": -71.560548 },
                { "lat": -33.872676, "lng": -71.560556 },
                { "lat": -33.872529, "lng": -71.5605801 },
                { "lat": -33.8724243, "lng": -71.560615 },
                { "lat": -33.8723219, "lng": -71.5606391 },
                { "lat": -33.8722484, "lng": -71.5606338 },
                { "lat": -33.8721793, "lng": -71.5606177 },
                { "lat": -33.8721125, "lng": -71.5606231 },
                { "lat": -33.8720724, "lng": -71.5606472 },
                { "lat": -33.8720324, "lng": -71.560674 },
                { "lat": -33.8719723, "lng": -71.5607491 },
                { "lat": -33.8719143, "lng": -71.5608618 },
                { "lat": -33.8718698, "lng": -71.5609449 },
                { "lat": -33.8718408, "lng": -71.5610442 },
                { "lat": -33.8718186, "lng": -71.5611461 },
                { "lat": -33.8717807, "lng": -71.5612346 },
                { "lat": -33.8717495, "lng": -71.5613043 },
                { "lat": -33.8716805, "lng": -71.5613607 },
                { "lat": -33.871627, "lng": -71.5614653 },
                { "lat": -33.8716026, "lng": -71.561535 },
                { "lat": -33.8715981, "lng": -71.5616128 },
                { "lat": -33.8716315, "lng": -71.5617335 },
                { "lat": -33.871656, "lng": -71.5617871 },
                { "lat": -33.8717028, "lng": -71.5618837 },
                { "lat": -33.8717874, "lng": -71.5621009 },
                { "lat": -33.8718698, "lng": -71.5622887 },
                { "lat": -33.8719166, "lng": -71.5624201 },
                { "lat": -33.8719544, "lng": -71.562573 },
                { "lat": -33.8719923, "lng": -71.5627018 },
                { "lat": -33.8720836, "lng": -71.5629298 },
                { "lat": -33.8722462, "lng": -71.5633428 },
                { "lat": -33.8723041, "lng": -71.5634394 },
                { "lat": -33.8723486, "lng": -71.5635306 },
                { "lat": -33.8723842, "lng": -71.5635547 },
                { "lat": -33.8724466, "lng": -71.5635654 },
                { "lat": -33.8725268, "lng": -71.5635547 },
                { "lat": -33.8725913, "lng": -71.563552 },
                { "lat": -33.8726403, "lng": -71.5635842 },
                { "lat": -33.8726849, "lng": -71.5636218 },
                { "lat": -33.8727383, "lng": -71.5637022 },
                { "lat": -33.8728096, "lng": -71.5637773 },
                { "lat": -33.872892, "lng": -71.5638363 },
                { "lat": -33.8729655, "lng": -71.5638793 },
                { "lat": -33.8730479, "lng": -71.5639302 },
                { "lat": -33.873079, "lng": -71.5639624 },
                { "lat": -33.873108, "lng": -71.5640134 },
                { "lat": -33.8731191, "lng": -71.5640616 },
                { "lat": -33.8731214, "lng": -71.5641394 },
                { "lat": -33.8731147, "lng": -71.5642735 },
                { "lat": -33.8730167, "lng": -71.5644962 },
                { "lat": -33.8729454, "lng": -71.5645713 },
                { "lat": -33.8728786, "lng": -71.5646464 },
                { "lat": -33.8728007, "lng": -71.5647322 },
                { "lat": -33.872725, "lng": -71.564877 },
                { "lat": -33.8726693, "lng": -71.5650272 },
                { "lat": -33.8726337, "lng": -71.5651533 },
                { "lat": -33.8726158, "lng": -71.5652418 },
                { "lat": -33.8725958, "lng": -71.5654054 },
                { "lat": -33.8725713, "lng": -71.5655503 },
                { "lat": -33.872529, "lng": -71.5656334 },
                { "lat": -33.8724911, "lng": -71.5657327 },
                { "lat": -33.8724444, "lng": -71.5658641 },
                { "lat": -33.8724354, "lng": -71.5659714 },
                { "lat": -33.872431, "lng": -71.5661082 },
                { "lat": -33.8724689, "lng": -71.5662423 },
                { "lat": -33.8725089, "lng": -71.5663363 },
                { "lat": -33.8725824, "lng": -71.5664275 },
                { "lat": -33.8726381, "lng": -71.566465 },
                { "lat": -33.8727405, "lng": -71.5665133 },
                { "lat": -33.8728385, "lng": -71.5665213 },
                { "lat": -33.8729521, "lng": -71.566516 },
                { "lat": -33.8730345, "lng": -71.5665508 },
                { "lat": -33.8730902, "lng": -71.566583 },
                { "lat": -33.8732706, "lng": -71.5667895 },
                { "lat": -33.8733507, "lng": -71.5668834 },
                { "lat": -33.8734665, "lng": -71.5669666 },
                { "lat": -33.8735512, "lng": -71.567047 },
                { "lat": -33.8736224, "lng": -71.5671543 },
                { "lat": -33.8736269, "lng": -71.5672348 },
                { "lat": -33.8735645, "lng": -71.5673233 },
                { "lat": -33.8735044, "lng": -71.5673662 },
                { "lat": -33.8734042, "lng": -71.5674815 },
                { "lat": -33.8733129, "lng": -71.5676452 },
                { "lat": -33.8732572, "lng": -71.5677471 },
                { "lat": -33.8731815, "lng": -71.5678866 },
                { "lat": -33.8731191, "lng": -71.5680153 },
                { "lat": -33.8730479, "lng": -71.5681548 },
                { "lat": -33.8730167, "lng": -71.5682835 },
                { "lat": -33.87299, "lng": -71.5684042 },
                { "lat": -33.8729877, "lng": -71.5685464 },
                { "lat": -33.8729588, "lng": -71.5686938 },
                { "lat": -33.8728898, "lng": -71.5688763 },
                { "lat": -33.8728385, "lng": -71.5690158 },
                { "lat": -33.8727984, "lng": -71.5691928 },
                { "lat": -33.8727472, "lng": -71.5693617 },
                { "lat": -33.8726982, "lng": -71.5695843 },
                { "lat": -33.8726715, "lng": -71.5697722 },
                { "lat": -33.8726448, "lng": -71.5698982 },
                { "lat": -33.872627, "lng": -71.5700431 },
                { "lat": -33.8726314, "lng": -71.5702147 },
                { "lat": -33.8726314, "lng": -71.5703193 },
                { "lat": -33.8726648, "lng": -71.5704481 },
                { "lat": -33.8727138, "lng": -71.5706305 },
                { "lat": -33.8728007, "lng": -71.5708692 },
                { "lat": -33.8728452, "lng": -71.570955 },
                { "lat": -33.8728987, "lng": -71.571073 },
                { "lat": -33.8729811, "lng": -71.5711669 },
                { "lat": -33.8730724, "lng": -71.5712286 },
                { "lat": -33.8732082, "lng": -71.5712982 },
                { "lat": -33.873304, "lng": -71.5713574 },
                { "lat": -33.8733797, "lng": -71.5714325 },
                { "lat": -33.873422, "lng": -71.5715102 },
                { "lat": -33.8734354, "lng": -71.5715853 },
                { "lat": -33.8734443, "lng": -71.5716819 },
                { "lat": -33.8734487, "lng": -71.5717865 },
                { "lat": -33.8734732, "lng": -71.5719339 },
                { "lat": -33.8735578, "lng": -71.5722237 },
                { "lat": -33.8736291, "lng": -71.5723954 },
                { "lat": -33.8736915, "lng": -71.5725831 },
                { "lat": -33.873879, "lng": -71.5729697 },
                { "lat": -33.8739364, "lng": -71.5731276 },
                { "lat": -33.8739765, "lng": -71.5732644 },
                { "lat": -33.8740121, "lng": -71.5733958 },
                { "lat": -33.8740233, "lng": -71.5735004 },
                { "lat": -33.8740233, "lng": -71.5735648 },
                { "lat": -33.8739943, "lng": -71.5736721 },
                { "lat": -33.873932, "lng": -71.5738276 },
                { "lat": -33.8738563, "lng": -71.5740074 },
                { "lat": -33.8737739, "lng": -71.5741737 },
                { "lat": -33.8737315, "lng": -71.5742515 },
                { "lat": -33.8736647, "lng": -71.5743319 },
                { "lat": -33.8735823, "lng": -71.574399 },
                { "lat": -33.8734866, "lng": -71.5744687 },
                { "lat": -33.8733819, "lng": -71.5745116 },
                { "lat": -33.8732149, "lng": -71.5745438 },
                { "lat": -33.8731191, "lng": -71.5745465 },
                { "lat": -33.8730612, "lng": -71.574568 },
                { "lat": -33.87299, "lng": -71.5746109 },
                { "lat": -33.8729477, "lng": -71.5746833 },
                { "lat": -33.8729187, "lng": -71.5747261 },
                { "lat": -33.8728452, "lng": -71.5747772 },
                { "lat": -33.8727873, "lng": -71.5748174 },
                { "lat": -33.8727049, "lng": -71.5748496 },
                { "lat": -33.8726648, "lng": -71.5748791 },
                { "lat": -33.8726136, "lng": -71.5749301 },
                { "lat": -33.8726002, "lng": -71.5749757 },
                { "lat": -33.8725869, "lng": -71.5750989 },
                { "lat": -33.8725735, "lng": -71.5752653 },
                { "lat": -33.8725579, "lng": -71.5754772 },
                { "lat": -33.8725334, "lng": -71.5756623 },
                { "lat": -33.8724889, "lng": -71.5758259 },
                { "lat": -33.8724354, "lng": -71.5760162 },
                { "lat": -33.8723597, "lng": -71.5761853 },
                { "lat": -33.8722951, "lng": -71.5763007 },
                { "lat": -33.8722551, "lng": -71.5763463 },
                { "lat": -33.8721615, "lng": -71.5763919 },
                { "lat": -33.8720947, "lng": -71.5764267 },
                { "lat": -33.8720056, "lng": -71.5764643 },
                { "lat": -33.8718987, "lng": -71.5764723 },
                { "lat": -33.8718497, "lng": -71.5764562 },
                { "lat": -33.871754, "lng": -71.5763919 },
                { "lat": -33.8716671, "lng": -71.576365 },
                { "lat": -33.8715758, "lng": -71.5763677 },
                { "lat": -33.8715202, "lng": -71.5763999 },
                { "lat": -33.8714043, "lng": -71.5764723 },
                { "lat": -33.8713598, "lng": -71.5765072 },
                { "lat": -33.8712418, "lng": -71.5765769 },
                { "lat": -33.8711237, "lng": -71.5766091 },
                { "lat": -33.8710235, "lng": -71.5766118 },
                { "lat": -33.8709233, "lng": -71.576593 },
                { "lat": -33.8708342, "lng": -71.5766064 },
                { "lat": -33.8707763, "lng": -71.5766172 },
                { "lat": -33.8706583, "lng": -71.5766789 },
                { "lat": -33.8705893, "lng": -71.5767486 },
                { "lat": -33.8705024, "lng": -71.5768505 },
                { "lat": -33.8704378, "lng": -71.5769471 },
                { "lat": -33.8703688, "lng": -71.5770544 },
                { "lat": -33.8703109, "lng": -71.5772153 },
                { "lat": -33.8702708, "lng": -71.577344 },
                { "lat": -33.8702307, "lng": -71.5774755 },
                { "lat": -33.8701839, "lng": -71.5775586 },
                { "lat": -33.8701104, "lng": -71.5776605 },
                { "lat": -33.8700659, "lng": -71.57769 },
                { "lat": -33.8699412, "lng": -71.5777544 },
                { "lat": -33.8698543, "lng": -71.5777759 },
                { "lat": -33.8697163, "lng": -71.5778778 },
                { "lat": -33.869527, "lng": -71.578028 },
                { "lat": -33.8694512, "lng": -71.5781219 },
                { "lat": -33.869302, "lng": -71.5782827 },
                { "lat": -33.869204, "lng": -71.5784276 },
                { "lat": -33.8691105, "lng": -71.5785402 },
                { "lat": -33.8689234, "lng": -71.5787602 },
                { "lat": -33.86887, "lng": -71.5788138 },
                { "lat": -33.8687141, "lng": -71.5789264 },
                { "lat": -33.8686072, "lng": -71.5789908 },
                { "lat": -33.8685136, "lng": -71.5790498 },
                { "lat": -33.8683934, "lng": -71.5791786 },
                { "lat": -33.8683756, "lng": -71.579302 },
                { "lat": -33.8682776, "lng": -71.5794843 },
                { "lat": -33.8681796, "lng": -71.5795916 },
                { "lat": -33.8680103, "lng": -71.5797365 },
                { "lat": -33.8679435, "lng": -71.5798062 },
                { "lat": -33.8678232, "lng": -71.5799671 },
                { "lat": -33.8676985, "lng": -71.5802354 },
                { "lat": -33.8675872, "lng": -71.5804821 },
                { "lat": -33.8674669, "lng": -71.5807557 },
                { "lat": -33.8673823, "lng": -71.5809596 },
                { "lat": -33.867262, "lng": -71.58126 },
                { "lat": -33.8671675, "lng": -71.5815843 },
                { "lat": -33.8672754, "lng": -71.5817937 },
                { "lat": -33.8674134, "lng": -71.5820378 },
                { "lat": -33.8676406, "lng": -71.5824294 },
                { "lat": -33.8679168, "lng": -71.5828747 },
                { "lat": -33.8683132, "lng": -71.5835667 },
                { "lat": -33.8685894, "lng": -71.5840602 },
                { "lat": -33.8688789, "lng": -71.5844947 },
                { "lat": -33.8691506, "lng": -71.5849185 },
                { "lat": -33.8694267, "lng": -71.5853638 },
                { "lat": -33.8699701, "lng": -71.5863133 },
                { "lat": -33.8703265, "lng": -71.5869302 },
                { "lat": -33.8708877, "lng": -71.5878582 },
                { "lat": -33.8717918, "lng": -71.5893173 },
                { "lat": -33.8732572, "lng": -71.5917152 },
                { "lat": -33.8746112, "lng": -71.5940197 },
                { "lat": -33.8757068, "lng": -71.5957761 },
                { "lat": -33.8763969, "lng": -71.5969036 },
                { "lat": -33.8773001, "lng": -71.5983018 },
                { "lat": -33.8778803, "lng": -71.5992147 },
                { "lat": -33.8786686, "lng": -71.6005343 },
                { "lat": -33.8791585, "lng": -71.6013765 },
                { "lat": -33.8805079, "lng": -71.6036081 },
                { "lat": -33.8816257, "lng": -71.6053355 },
                { "lat": -33.8824542, "lng": -71.6067202 },
                { "lat": -33.8832958, "lng": -71.6081035 },
                { "lat": -33.884006, "lng": -71.6093193 },
                { "lat": -33.8848616, "lng": -71.6107839 },
                { "lat": -33.8856917, "lng": -71.6120946 },
                { "lat": -33.8862796, "lng": -71.6130725 },
                { "lat": -33.8872637, "lng": -71.6146374 },
                { "lat": -33.8885693, "lng": -71.6167844 },
                { "lat": -33.8891696, "lng": -71.6177326 },
                { "lat": -33.8897311, "lng": -71.618656 },
                { "lat": -33.890559, "lng": -71.6199696 },
                { "lat": -33.8916009, "lng": -71.6216603 },
                { "lat": -33.8923179, "lng": -71.6228181 },
                { "lat": -33.8933287, "lng": -71.624524 },
                { "lat": -33.8938742, "lng": -71.6254815 },
                { "lat": -33.8939344, "lng": -71.6255863 },
                { "lat": -33.8940033, "lng": -71.6257337 },
                { "lat": -33.8946712, "lng": -71.6267744 },
                { "lat": -33.8954455, "lng": -71.6280607 },
                { "lat": -33.8968397, "lng": -71.6302747 },
                { "lat": -33.8976117, "lng": -71.6314923 },
                { "lat": -33.8985295, "lng": -71.6329756 },
                { "lat": -33.8989974, "lng": -71.6337635 },
                { "lat": -33.8995714, "lng": -71.634711 },
                { "lat": -33.9000295, "lng": -71.6354799 },
                { "lat": -33.9006511, "lng": -71.6365027 },
                { "lat": -33.9011722, "lng": -71.6373348 },
                { "lat": -33.9019441, "lng": -71.6386516 },
                { "lat": -33.90247, "lng": -71.6394773 },
                { "lat": -33.9027822, "lng": -71.6399595 },
                { "lat": -33.903425, "lng": -71.6410652 },
                { "lat": -33.9040283, "lng": -71.6420683 },
                { "lat": -33.904989, "lng": -71.6436266 },
                { "lat": -33.9076989, "lng": -71.6480246 },
                { "lat": -33.9118731, "lng": -71.654901 },
                { "lat": -33.9124478, "lng": -71.6557741 },
                { "lat": -33.9135806, "lng": -71.6576873 },
                { "lat": -33.915011, "lng": -71.6599784 },
                { "lat": -33.917032, "lng": -71.6633382 },
                { "lat": -33.9192529, "lng": -71.6669184 },
                { "lat": -33.9201266, "lng": -71.669011 },
                { "lat": -33.920893, "lng": -71.6722951 },
                { "lat": -33.9216844, "lng": -71.6749408 },
                { "lat": -33.9225961, "lng": -71.6762991 },
                { "lat": -33.9231374, "lng": -71.6775737 },
                { "lat": -33.9234347, "lng": -71.6788848 },
                { "lat": -33.9239564, "lng": -71.6796594 },
                { "lat": -33.9247345, "lng": -71.6807344 },
                { "lat": -33.9250051, "lng": -71.68167 },
                { "lat": -33.9250354, "lng": -71.6824038 },
                { "lat": -33.9253185, "lng": -71.6833994 },
                { "lat": -33.9262995, "lng": -71.6843608 },
                { "lat": -33.9265452, "lng": -71.685041 },
                { "lat": -33.9273714, "lng": -71.6859272 },
                { "lat": -33.9274123, "lng": -71.6878154 },
                { "lat": -33.9278147, "lng": -71.6893818 },
                { "lat": -33.9279812, "lng": -71.6908099 },
                { "lat": -33.9284111, "lng": -71.6914332 },
                { "lat": -33.9291535, "lng": -71.6922658 },
                { "lat": -33.9299832, "lng": -71.6933773 },
                { "lat": -33.9315304, "lng": -71.6937335 },
                { "lat": -33.9327285, "lng": -71.6940746 },
                { "lat": -33.9329867, "lng": -71.6945875 },
                { "lat": -33.9326805, "lng": -71.6951454 },
                { "lat": -33.9329564, "lng": -71.6955767 },
                { "lat": -33.933576, "lng": -71.6956818 },
                { "lat": -33.9332163, "lng": -71.6962762 },
                { "lat": -33.9331095, "lng": -71.6969199 },
                { "lat": -33.9333588, "lng": -71.6977546 },
                { "lat": -33.9338982, "lng": -71.6991086 },
                { "lat": -33.9349165, "lng": -71.6998124 },
                { "lat": -33.9352513, "lng": -71.7003553 },
                { "lat": -33.9363674, "lng": -71.7014368 },
                { "lat": -33.937003, "lng": -71.7019088 },
                { "lat": -33.9377899, "lng": -71.7016106 },
                { "lat": -33.9382224, "lng": -71.702117 },
                { "lat": -33.9387832, "lng": -71.702692 },
                { "lat": -33.9396306, "lng": -71.703177 },
                { "lat": -33.94051, "lng": -71.7037134 },
                { "lat": -33.940688, "lng": -71.7047048 },
                { "lat": -33.9407058, "lng": -71.7057154 },
                { "lat": -33.9409675, "lng": -71.7067733 },
                { "lat": -33.9407094, "lng": -71.7074664 },
                { "lat": -33.9405919, "lng": -71.7082217 },
                { "lat": -33.9409871, "lng": -71.7091487 },
                { "lat": -33.9412256, "lng": -71.7100821 },
                { "lat": -33.9418166, "lng": -71.7106872 },
                { "lat": -33.9427779, "lng": -71.7105112 },
                { "lat": -33.9437267, "lng": -71.7121527 },
                { "lat": -33.9442607, "lng": -71.7128179 },
                { "lat": -33.9449692, "lng": -71.712775 },
                { "lat": -33.9453946, "lng": -71.7129874 },
                { "lat": -33.9457898, "lng": -71.7138822 },
                { "lat": -33.9462918, "lng": -71.7143436 },
                { "lat": -33.9470287, "lng": -71.7142491 },
                { "lat": -33.947523, "lng": -71.7147445 },
                { "lat": -33.9484966, "lng": -71.7148503 },
                { "lat": -33.9486204, "lng": -71.7154666 },
                { "lat": -33.9487895, "lng": -71.7160245 },
                { "lat": -33.9491811, "lng": -71.7169793 },
                { "lat": -33.9493858, "lng": -71.7173656 },
                { "lat": -33.949643, "lng": -71.7177052 },
                { "lat": -33.9499554, "lng": -71.71802 },
                { "lat": -33.9505863, "lng": -71.7184991 },
                { "lat": -33.9512271, "lng": -71.7189175 },
                { "lat": -33.9516714, "lng": -71.7191326 },
                { "lat": -33.9521674, "lng": -71.7193064 },
                { "lat": -33.9526771, "lng": -71.7194368 },
                { "lat": -33.9532747, "lng": -71.7195417 },
                { "lat": -33.9538029, "lng": -71.7195647 },
                { "lat": -33.9544305, "lng": -71.7195433 },
                { "lat": -33.9548906, "lng": -71.7195199 },
                { "lat": -33.9552813, "lng": -71.7195272 },
                { "lat": -33.955504, "lng": -71.7195838 },
                { "lat": -33.9557116, "lng": -71.7196889 },
                { "lat": -33.9558649, "lng": -71.7199459 },
                { "lat": -33.9559643, "lng": -71.7202903 },
                { "lat": -33.9560871, "lng": -71.7209168 },
                { "lat": -33.9561939, "lng": -71.7218395 },
                { "lat": -33.9561939, "lng": -71.7225047 },
                { "lat": -33.9561583, "lng": -71.7230948 },
                { "lat": -33.9560595, "lng": -71.7237348 },
                { "lat": -33.955926, "lng": -71.7241103 },
                { "lat": -33.9555078, "lng": -71.7249257 },
                { "lat": -33.9550993, "lng": -71.7255517 },
                { "lat": -33.9546721, "lng": -71.7260667 },
                { "lat": -33.9542628, "lng": -71.7264636 },
                { "lat": -33.9538792, "lng": -71.726771 },
                { "lat": -33.9533372, "lng": -71.7271396 },
                { "lat": -33.9525087, "lng": -71.7276079 },
                { "lat": -33.9520687, "lng": -71.7278695 },
                { "lat": -33.9516682, "lng": -71.728127 },
                { "lat": -33.9511031, "lng": -71.7284006 },
                { "lat": -33.9507026, "lng": -71.728524 },
                { "lat": -33.9504267, "lng": -71.7285508 },
                { "lat": -33.9501641, "lng": -71.7284757 },
                { "lat": -33.9494744, "lng": -71.7279071 },
                { "lat": -33.9487869, "lng": -71.727258 },
                { "lat": -33.9482284, "lng": -71.7268074 },
                { "lat": -33.9477189, "lng": -71.7264023 },
                { "lat": -33.9474452, "lng": -71.7263004 },
                { "lat": -33.9471404, "lng": -71.7262709 },
                { "lat": -33.9466442, "lng": -71.7264158 },
                { "lat": -33.9461547, "lng": -71.7266411 },
                { "lat": -33.9455845, "lng": -71.7270285 },
                { "lat": -33.9453985, "lng": -71.7272576 },
                { "lat": -33.9452784, "lng": -71.7274077 },
                { "lat": -33.9448458, "lng": -71.7279727 },
                { "lat": -33.9442366, "lng": -71.7286849 },
                { "lat": -33.9436069, "lng": -71.7292187 },
                { "lat": -33.943222, "lng": -71.7295057 },
                { "lat": -33.9425633, "lng": -71.7299992 },
                { "lat": -33.9423097, "lng": -71.7302299 },
                { "lat": -33.9420916, "lng": -71.7305034 },
                { "lat": -33.9413217, "lng": -71.7317534 },
                { "lat": -33.9408455, "lng": -71.7327619 },
                { "lat": -33.9401374, "lng": -71.7347212 },
                { "lat": -33.9398525, "lng": -71.735515 },
                { "lat": -33.9396033, "lng": -71.7362983 },
                { "lat": -33.9394698, "lng": -71.736899 },
                { "lat": -33.939363, "lng": -71.7376286 },
                { "lat": -33.9393096, "lng": -71.7382187 },
                { "lat": -33.9392651, "lng": -71.7390126 },
                { "lat": -33.939274, "lng": -71.7399783 },
                { "lat": -33.9392967, "lng": -71.7410017 },
                { "lat": -33.9393986, "lng": -71.7421063 },
                { "lat": -33.9395499, "lng": -71.7436046 },
                { "lat": -33.9398258, "lng": -71.7457932 },
                { "lat": -33.9400039, "lng": -71.747424 },
                { "lat": -33.9400875, "lng": -71.7487903 },
                { "lat": -33.9401018, "lng": -71.7497855 },
                { "lat": -33.9400733, "lng": -71.7506073 },
                { "lat": -33.9400248, "lng": -71.7511614 },
                { "lat": -33.9399429, "lng": -71.751825 },
                { "lat": -33.9398543, "lng": -71.7523244 },
                { "lat": -33.9397101, "lng": -71.7527455 },
                { "lat": -33.9395054, "lng": -71.7531532 },
                { "lat": -33.9389001, "lng": -71.7542477 },
                { "lat": -33.9386537, "lng": -71.7545575 },
                { "lat": -33.9383466, "lng": -71.7548955 },
                { "lat": -33.9380417, "lng": -71.7552039 },
                { "lat": -33.9377702, "lng": -71.7554587 },
                { "lat": -33.9375432, "lng": -71.7556492 },
                { "lat": -33.9373942, "lng": -71.7557672 },
                { "lat": -33.9371939, "lng": -71.755802 },
                { "lat": -33.9370159, "lng": -71.7557645 },
                { "lat": -33.9368178, "lng": -71.7556572 },
                { "lat": -33.9363371, "lng": -71.7552254 },
                { "lat": -33.9360389, "lng": -71.7549679 },
                { "lat": -33.9355227, "lng": -71.7545253 },
                { "lat": -33.93441, "lng": -71.753844 },
                { "lat": -33.9331059, "lng": -71.7532861 },
                { "lat": -33.9323448, "lng": -71.7531413 },
                { "lat": -33.9318152, "lng": -71.7531493 },
                { "lat": -33.9315526, "lng": -71.7532432 },
                { "lat": -33.9312276, "lng": -71.7534632 },
                { "lat": -33.9308849, "lng": -71.7536992 },
                { "lat": -33.930698, "lng": -71.7537153 },
                { "lat": -33.9304888, "lng": -71.7536187 },
                { "lat": -33.9295096, "lng": -71.752857 },
                { "lat": -33.9289666, "lng": -71.7524278 },
                { "lat": -33.9284591, "lng": -71.7521381 },
                { "lat": -33.9278538, "lng": -71.7518377 },
                { "lat": -33.9272306, "lng": -71.7515588 },
                { "lat": -33.9264829, "lng": -71.7511726 },
                { "lat": -33.9258686, "lng": -71.7507863 },
                { "lat": -33.9253701, "lng": -71.7504537 },
                { "lat": -33.9247731, "lng": -71.7499453 },
                { "lat": -33.9243547, "lng": -71.7496127 },
                { "lat": -33.9240609, "lng": -71.7494625 },
                { "lat": -33.9236603, "lng": -71.749323 },
                { "lat": -33.9230549, "lng": -71.749205 },
                { "lat": -33.9221646, "lng": -71.7491192 },
                { "lat": -33.9216126, "lng": -71.7490978 },
                { "lat": -33.9211942, "lng": -71.7491407 },
                { "lat": -33.9209627, "lng": -71.7492157 },
                { "lat": -33.9208025, "lng": -71.7493338 },
                { "lat": -33.9207045, "lng": -71.7495376 },
                { "lat": -33.9206422, "lng": -71.7499238 },
                { "lat": -33.9204909, "lng": -71.7512328 },
                { "lat": -33.9204553, "lng": -71.7519516 },
                { "lat": -33.920482, "lng": -71.7525739 },
                { "lat": -33.9205799, "lng": -71.7532069 },
                { "lat": -33.920847, "lng": -71.7551059 },
                { "lat": -33.9211408, "lng": -71.756447 },
                { "lat": -33.9213367, "lng": -71.757123 },
                { "lat": -33.9215592, "lng": -71.7577988 },
                { "lat": -33.9218975, "lng": -71.7586035 },
                { "lat": -33.9222714, "lng": -71.7594725 },
                { "lat": -33.9230109, "lng": -71.7606729 },
                { "lat": -33.9234427, "lng": -71.7613435 },
                { "lat": -33.9239724, "lng": -71.7619765 },
                { "lat": -33.9243374, "lng": -71.7624056 },
                { "lat": -33.9245644, "lng": -71.7627758 },
                { "lat": -33.9250499, "lng": -71.7636498 },
                { "lat": -33.9255387, "lng": -71.7641181 },
                { "lat": -33.9264289, "lng": -71.7648584 },
                { "lat": -33.9269364, "lng": -71.7652232 },
                { "lat": -33.9275693, "lng": -71.7656024 },
                { "lat": -33.9280758, "lng": -71.7658669 },
                { "lat": -33.9307553, "lng": -71.7673046 },
                { "lat": -33.931236, "lng": -71.7675191 },
                { "lat": -33.9319402, "lng": -71.7677805 },
                { "lat": -33.933506, "lng": -71.7683454 },
                { "lat": -33.9343872, "lng": -71.7685814 },
                { "lat": -33.9348145, "lng": -71.7686672 },
                { "lat": -33.935402, "lng": -71.7688603 },
                { "lat": -33.9356868, "lng": -71.7690427 },
                { "lat": -33.9359182, "lng": -71.7692895 },
                { "lat": -33.9360607, "lng": -71.7695792 },
                { "lat": -33.9361408, "lng": -71.7699225 },
                { "lat": -33.936212, "lng": -71.770534 },
                { "lat": -33.9362921, "lng": -71.7710383 },
                { "lat": -33.9365235, "lng": -71.7721219 },
                { "lat": -33.9366125, "lng": -71.772712 },
                { "lat": -33.936666, "lng": -71.7735381 },
                { "lat": -33.9366571, "lng": -71.7739565 },
                { "lat": -33.9365769, "lng": -71.7744823 },
                { "lat": -33.9364434, "lng": -71.7750616 },
                { "lat": -33.9361586, "lng": -71.7764027 },
                { "lat": -33.9360349, "lng": -71.7770609 },
                { "lat": -33.935572, "lng": -71.7799255 },
                { "lat": -33.9355008, "lng": -71.7805585 },
                { "lat": -33.9354605, "lng": -71.7810845 },
                { "lat": -33.9354293, "lng": -71.781637 },
                { "lat": -33.9354242, "lng": -71.7821641 },
                { "lat": -33.9353842, "lng": -71.7824323 },
                { "lat": -33.9353003, "lng": -71.7827099 },
                { "lat": -33.935215, "lng": -71.7828615 },
                { "lat": -33.9350777, "lng": -71.7830103 },
                { "lat": -33.9348323, "lng": -71.7832048 },
                { "lat": -33.9345163, "lng": -71.7834247 },
                { "lat": -33.9338754, "lng": -71.7838646 },
                { "lat": -33.932865, "lng": -71.7846103 },
                { "lat": -33.9319755, "lng": -71.7852419 },
                { "lat": -33.9316239, "lng": -71.7854458 },
                { "lat": -33.9312449, "lng": -71.7855866 },
                { "lat": -33.930845, "lng": -71.7857033 },
                { "lat": -33.9304756, "lng": -71.7857676 },
                { "lat": -33.9301729, "lng": -71.7857837 },
                { "lat": -33.9297812, "lng": -71.7857408 },
                { "lat": -33.9294118, "lng": -71.7856228 },
                { "lat": -33.9290646, "lng": -71.7854619 },
                { "lat": -33.9287486, "lng": -71.7852634 },
                { "lat": -33.9285076, "lng": -71.7850663 },
                { "lat": -33.9281299, "lng": -71.7846679 },
                { "lat": -33.9273688, "lng": -71.7838579 },
                { "lat": -33.9268122, "lng": -71.7831203 },
                { "lat": -33.9263159, "lng": -71.7825866 },
                { "lat": -33.9259888, "lng": -71.7822191 },
                { "lat": -33.9257106, "lng": -71.7820823 },
                { "lat": -33.9255103, "lng": -71.7819831 },
                { "lat": -33.9252899, "lng": -71.7819589 },
                { "lat": -33.9251275, "lng": -71.7819724 },
                { "lat": -33.92496, "lng": -71.7820889 },
                { "lat": -33.9248354, "lng": -71.7822607 },
                { "lat": -33.9246796, "lng": -71.7824431 },
                { "lat": -33.924555, "lng": -71.7826254 },
                { "lat": -33.9241194, "lng": -71.7833322 },
                { "lat": -33.9239006, "lng": -71.7836554 },
                { "lat": -33.9236253, "lng": -71.7840135 },
                { "lat": -33.922356, "lng": -71.7855222 },
                { "lat": -33.9220222, "lng": -71.785946 },
                { "lat": -33.9216349, "lng": -71.7864664 },
                { "lat": -33.9211058, "lng": -71.7871892 },
                { "lat": -33.9208566, "lng": -71.787495 },
                { "lat": -33.9206607, "lng": -71.7877096 },
                { "lat": -33.9196102, "lng": -71.7886752 },
                { "lat": -33.918737, "lng": -71.7894758 },
                { "lat": -33.9185945, "lng": -71.7896528 },
                { "lat": -33.9184654, "lng": -71.7898674 },
                { "lat": -33.918412, "lng": -71.7900283 },
                { "lat": -33.9184031, "lng": -71.7902107 },
                { "lat": -33.9184343, "lng": -71.7904521 },
                { "lat": -33.9186079, "lng": -71.7916001 },
                { "lat": -33.9187281, "lng": -71.7921526 },
                { "lat": -33.9188572, "lng": -71.7925443 },
                { "lat": -33.9190041, "lng": -71.7929305 },
                { "lat": -33.919151, "lng": -71.7932416 },
                { "lat": -33.9193068, "lng": -71.7934938 },
                { "lat": -33.9196451, "lng": -71.7939765 },
                { "lat": -33.9201392, "lng": -71.794749 },
                { "lat": -33.9203128, "lng": -71.7951031 },
                { "lat": -33.9204419, "lng": -71.7954464 },
                { "lat": -33.9205354, "lng": -71.7958005 },
                { "lat": -33.92062, "lng": -71.7962296 },
                { "lat": -33.9206384, "lng": -71.7965823 },
                { "lat": -33.9206206, "lng": -71.7968076 },
                { "lat": -33.920571, "lng": -71.7970074 },
                { "lat": -33.920496, "lng": -71.7971724 },
                { "lat": -33.9202549, "lng": -71.79756 },
                { "lat": -33.9199879, "lng": -71.7979409 },
                { "lat": -33.919703, "lng": -71.7982895 },
                { "lat": -33.9193164, "lng": -71.7987013 },
                { "lat": -33.9189729, "lng": -71.7990513 },
                { "lat": -33.9184921, "lng": -71.7994429 },
                { "lat": -33.918012, "lng": -71.7997581 },
                { "lat": -33.9177537, "lng": -71.7998707 },
                { "lat": -33.9174198, "lng": -71.7999727 },
                { "lat": -33.9168411, "lng": -71.8000799 },
                { "lat": -33.9165518, "lng": -71.8001926 },
                { "lat": -33.9163336, "lng": -71.8003589 },
                { "lat": -33.9159554, "lng": -71.8006485 },
                { "lat": -33.9152959, "lng": -71.8013043 },
                { "lat": -33.9147171, "lng": -71.8018569 },
                { "lat": -33.9142319, "lng": -71.8024201 },
                { "lat": -33.9136888, "lng": -71.8030746 },
                { "lat": -33.9132258, "lng": -71.8035467 },
                { "lat": -33.9128429, "lng": -71.8039007 },
                { "lat": -33.9110622, "lng": -71.8052847 },
                { "lat": -33.9098423, "lng": -71.8062611 },
                { "lat": -33.9092992, "lng": -71.8067546 },
                { "lat": -33.9081238, "lng": -71.8078704 },
                { "lat": -33.9077053, "lng": -71.8083425 },
                { "lat": -33.9074204, "lng": -71.8087394 },
                { "lat": -33.9072067, "lng": -71.8092115 },
                { "lat": -33.9070464, "lng": -71.8097694 },
                { "lat": -33.9069663, "lng": -71.8102951 },
                { "lat": -33.9070197, "lng": -71.8107779 },
                { "lat": -33.9071711, "lng": -71.8112392 },
                { "lat": -33.9074115, "lng": -71.8118722 },
                { "lat": -33.9079992, "lng": -71.8131812 },
                { "lat": -33.9086225, "lng": -71.8146725 },
                { "lat": -33.9090409, "lng": -71.8156273 },
                { "lat": -33.9099403, "lng": -71.8176336 },
                { "lat": -33.9103676, "lng": -71.8184598 },
                { "lat": -33.9110176, "lng": -71.8195005 },
                { "lat": -33.9115875, "lng": -71.8203802 },
                { "lat": -33.9117745, "lng": -71.8207879 },
                { "lat": -33.9118813, "lng": -71.8212171 },
                { "lat": -33.9119169, "lng": -71.8217213 },
                { "lat": -33.9119704, "lng": -71.8225153 },
                { "lat": -33.9120772, "lng": -71.8230624 },
                { "lat": -33.9122553, "lng": -71.823513 },
                { "lat": -33.9124601, "lng": -71.8238778 },
                { "lat": -33.9127806, "lng": -71.8242426 },
                { "lat": -33.913573, "lng": -71.8249722 },
                { "lat": -33.9151044, "lng": -71.8262811 },
                { "lat": -33.9169831, "lng": -71.8279011 },
                { "lat": -33.9175974, "lng": -71.828459 },
                { "lat": -33.918372, "lng": -71.8292637 },
                { "lat": -33.9190486, "lng": -71.8300684 },
                { "lat": -33.9196896, "lng": -71.8310339 },
                { "lat": -33.9202149, "lng": -71.8317099 },
                { "lat": -33.92066, "lng": -71.8321819 },
                { "lat": -33.921123, "lng": -71.8326004 },
                { "lat": -33.9216304, "lng": -71.8330081 },
                { "lat": -33.922583, "lng": -71.8338449 },
                { "lat": -33.9230816, "lng": -71.8343062 },
                { "lat": -33.9235178, "lng": -71.8348641 },
                { "lat": -33.9238561, "lng": -71.8354328 },
                { "lat": -33.9241232, "lng": -71.836055 },
                { "lat": -33.9243101, "lng": -71.8366773 },
                { "lat": -33.9243814, "lng": -71.837203 },
                { "lat": -33.9243814, "lng": -71.8379219 },
                { "lat": -33.9242745, "lng": -71.8385549 },
                { "lat": -33.924052, "lng": -71.8391664 },
                { "lat": -33.923687, "lng": -71.8398316 },
                { "lat": -33.9232774, "lng": -71.840368 },
                { "lat": -33.9226008, "lng": -71.8410547 },
                { "lat": -33.9218441, "lng": -71.8417521 },
                { "lat": -33.9208203, "lng": -71.8425996 },
                { "lat": -33.920117, "lng": -71.8431039 },
                { "lat": -33.9197074, "lng": -71.8432648 },
                { "lat": -33.9192267, "lng": -71.8433399 },
                { "lat": -33.9186836, "lng": -71.8432863 },
                { "lat": -33.9182206, "lng": -71.8431361 },
                { "lat": -33.9175974, "lng": -71.8428571 },
                { "lat": -33.9169385, "lng": -71.8424065 },
                { "lat": -33.9161996, "lng": -71.8419023 },
                { "lat": -33.9153893, "lng": -71.8414195 },
                { "lat": -33.9146771, "lng": -71.8410761 },
                { "lat": -33.9143031, "lng": -71.8408616 },
                { "lat": -33.9138579, "lng": -71.8405612 },
                { "lat": -33.9134128, "lng": -71.840132 },
                { "lat": -33.9130121, "lng": -71.8396277 },
                { "lat": -33.9126203, "lng": -71.8390055 },
                { "lat": -33.911721, "lng": -71.8373318 },
                { "lat": -33.9110711, "lng": -71.8363125 },
                { "lat": -33.9102519, "lng": -71.8352182 },
                { "lat": -33.9094505, "lng": -71.8341775 },
                { "lat": -33.9087115, "lng": -71.8333085 },
                { "lat": -33.9079546, "lng": -71.8325574 },
                { "lat": -33.9073848, "lng": -71.8320961 },
                { "lat": -33.9066991, "lng": -71.8316991 },
                { "lat": -33.9060758, "lng": -71.8314846 },
                { "lat": -33.9055326, "lng": -71.8314416 },
                { "lat": -33.9051498, "lng": -71.8315167 },
                { "lat": -33.9048025, "lng": -71.8316991 },
                { "lat": -33.9045799, "lng": -71.8319674 },
                { "lat": -33.9043893, "lng": -71.8322883 },
            ]
        }
    ]
};

export default SantoDomingo;
