const Puchuncavi = {
    commune: "Puchuncavi",
    polygons: [

        {
            idService: 112,
            nameService: "Ventana / Quintero",
            coordinates: [
                //LAS VENTANAS
                { "lat": -32.738872281682646, "lng": -71.464246803916 },
                { "lat": -32.738617030008086, "lng": -71.46788829151747 },
                { "lat": -32.73800442300619, "lng": -71.46879866341784 },
                { "lat": -32.728610588333595, "lng": -71.47237945955929 },
                { "lat": -32.72079865676108, "lng": -71.47905552016202 },
                { "lat": -32.71584563976449, "lng": -71.48664195266511 },
                { "lat": -32.7133945603481, "lng": -71.48670264440894 },
                { "lat": -32.711709404315265, "lng": -71.48743094192923 },
                { "lat": -32.70992208282756, "lng": -71.48846269674964 },
                { "lat": -32.70854326749896, "lng": -71.48949445157007 },
                { "lat": -32.70930927864564, "lng": -71.49052620639048 },
                { "lat": -32.70941141296828, "lng": -71.49234695019122 },
                { "lat": -32.708936956839956, "lng": -71.49267586596677 },
                { "lat": -32.70883827099074, "lng": -71.4929645648311 },
                { "lat": -32.708610533998986, "lng": -71.49301869586816 },
                { "lat": -32.708458709014856, "lng": -71.49368631199191 },
                { "lat": -32.70831447504064, "lng": -71.49414642580695 },
                { "lat": -32.708420752728465, "lng": -71.49443512467126 },
                { "lat": -32.70862571648318, "lng": -71.49441708099224 },
                { "lat": -32.70876994995425, "lng": -71.4945614304244 },
                { "lat": -32.70892936562466, "lng": -71.49452534306636 },
                { "lat": -32.709673301675664, "lng": -71.49525611217665 },
                { "lat": -32.71013636076534, "lng": -71.49596883749795 },
                { "lat": -32.71042482258982, "lng": -71.49620340532522 },
                { "lat": -32.710933424060926, "lng": -71.49715069847379 },
                { "lat": -32.71117254166092, "lng": -71.49794010943093 },
                { "lat": -32.71116115512307, "lng": -71.49821978645575 },
                { "lat": -32.71089167329943, "lng": -71.4985671273418 },
                { "lat": -32.71078160302615, "lng": -71.49859870378008 },
                { "lat": -32.71080058066916, "lng": -71.49868892217519 },
                { "lat": -32.71074744325855, "lng": -71.49876109689127 },
                { "lat": -32.710728465604234, "lng": -71.49889642448392 },
                { "lat": -32.710622190665454, "lng": -71.4989189790827 },
                { "lat": -32.71059562191098, "lng": -71.49898664287902 },
                { "lat": -32.71068671475052, "lng": -71.4992212107063 },
                { "lat": -32.71055766653371, "lng": -71.49929338542238 },
                { "lat": -32.71042102704185, "lng": -71.4992212107063 },
                { "lat": -32.710341320574955, "lng": -71.49925729806432 },
                { "lat": -32.71035270721744, "lng": -71.49947382221258 },
                { "lat": -32.71038686713619, "lng": -71.49961366072499 },
                { "lat": -32.71064116834237, "lng": -71.4997129009596 },
                { "lat": -32.710580439762005, "lng": -71.49983018487322 },
                { "lat": -32.71073605666645, "lng": -71.49985725039176 },
                { "lat": -32.71076642090883, "lng": -71.49998806706465 },
                { "lat": -32.71046657356239, "lng": -71.50013241649683 },
                { "lat": -32.71053868883902, "lng": -71.50021361305241 },
                { "lat": -32.71070189688141, "lng": -71.50019556937337 },
                { "lat": -32.710728465604234, "lng": -71.50033089696603 },
                { "lat": -32.71069430581629, "lng": -71.50040307168211 },
                { "lat": -32.71078160303018, "lng": -71.50058801946365 },
                { "lat": -32.71053868884306, "lng": -71.50085416372919 },
                { "lat": -32.710599417451846, "lng": -71.50092633844527 },
                { "lat": -32.7106601460193, "lng": -71.50103008959964 },
                { "lat": -32.710842331473614, "lng": -71.50105715511818 },
                { "lat": -32.710872695679825, "lng": -71.50113384075401 },
                { "lat": -32.710941015106016, "lng": -71.50135938674175 },
                { "lat": -32.710941015106016, "lng": -71.50135938674175 },
                { "lat": -32.71092962853861, "lng": -71.50146764881589 },
                { "lat": -32.71100174344093, "lng": -71.50150824709368 },
                { "lat": -32.71102072103711, "lng": -71.50172477124192 },
                { "lat": -32.71097137927864, "lng": -71.50182401147654 },
                { "lat": -32.71086890015461, "lng": -71.50184205515556 },
                { "lat": -32.710811967257065, "lng": -71.5019277626309 },
                { "lat": -32.710842331473614, "lng": -71.50199091550748 },
                { "lat": -32.710830944893615, "lng": -71.50209015574208 },
                { "lat": -32.71077780750109, "lng": -71.50229765805084 },
                { "lat": -32.71075882985324, "lng": -71.50245102932249 },
                { "lat": -32.710618395133615, "lng": -71.50261793335343 },
                { "lat": -32.71055464517028, "lng": -71.50259083171844 },
                { "lat": -32.71054390976512, "lng": -71.50271204039248 },
                { "lat": -32.710651263758514, "lng": -71.50274393741196 },
                { "lat": -32.71019500839443, "lng": -71.50337549839769 },
                { "lat": -32.71017353749629, "lng": -71.50344567184057 },
                { "lat": -32.71014669886633, "lng": -71.50337549839769 },
                { "lat": -32.71014669886633, "lng": -71.5032032544925 },
                { "lat": -32.7099802991804, "lng": -71.5033627395899 },
                { "lat": -32.71006618292802, "lng": -71.50406447401852 },
                { "lat": -32.709964195968524, "lng": -71.5040772328263 },
                { "lat": -32.709690440921776, "lng": -71.50417292388475 },
                { "lat": -32.70992662179615, "lng": -71.50417292388475 },
                { "lat": -32.71006081520471, "lng": -71.50490017604018 },
                { "lat": -32.71028089194386, "lng": -71.50553173702593 },
                { "lat": -32.71023795018374, "lng": -71.50597829529869 },
                { "lat": -32.71032920139929, "lng": -71.50601019231816 },
                { "lat": -32.71026478878623, "lng": -71.50616329801167 },
                { "lat": -32.71013059569298, "lng": -71.50618243622337 },
                { "lat": -32.71021111157315, "lng": -71.5062972654935 },
                { "lat": -32.71021111157315, "lng": -71.50652054462988 },
                { "lat": -32.710098389320564, "lng": -71.50640571535973 },
                { "lat": -32.70984073792267, "lng": -71.50636743893637 },
                { "lat": -32.70980316369832, "lng": -71.50666089151561 },
                { "lat": -32.7098944153586, "lng": -71.50666089151561 },
                { "lat": -32.71030236281617, "lng": -71.50715210561563 },
                { "lat": -32.71028089194386, "lng": -71.50749021402214 },
                { "lat": -32.71036677540211, "lng": -71.50795591050657 },
                { "lat": -32.71039361396727, "lng": -71.50833229543862 },
                { "lat": -32.71030773053486, "lng": -71.5083578130542 },
                { "lat": -32.710356039975764, "lng": -71.50852367755552 },
                { "lat": -32.71034530454671, "lng": -71.50868316265291 },
                { "lat": -32.71046339419522, "lng": -71.50861936861396 },
                { "lat": -32.71090354514437, "lng": -71.50821108676458 },
                { "lat": -32.710710308409716, "lng": -71.50896385642436 },
                { "lat": -32.71084986831561, "lng": -71.50904678867502 },
                { "lat": -32.71112898747243, "lng": -71.50930834423477 },
                { "lat": -32.711166561138384, "lng": -71.50879799192306 },
                { "lat": -32.71127391438248, "lng": -71.50898299463606 },
                { "lat": -32.71122023777659, "lng": -71.50933386185037 },
                { "lat": -32.711628179171406, "lng": -71.50941041469711 },
                { "lat": -32.711649649719455, "lng": -71.50886178596203 },
                { "lat": -32.71215273874204, "lng": -71.50841667510397 },
                { "lat": -32.71227009228369, "lng": -71.50852396346133 },
                { "lat": -32.71249577173756, "lng": -71.50829865791087 },
                { "lat": -32.71293810181093, "lng": -71.50834157325382 },
                { "lat": -32.713922052679195, "lng": -71.5088458285334 },
                { "lat": -32.714274105858, "lng": -71.50858833647574 },
                { "lat": -32.71452686112947, "lng": -71.50933935497726 },
                { "lat": -32.71579965357628, "lng": -71.50916769360548 },
                { "lat": -32.715808680408216, "lng": -71.50901748990518 },
                { "lat": -32.71614267254714, "lng": -71.50898530339798 },
                { "lat": -32.71611559215004, "lng": -71.50937154148446 },
                { "lat": -32.71632320831772, "lng": -71.50924279545563 },
                { "lat": -32.71707318179601, "lng": -71.50933498758744 },
                { "lat": -32.71771407544112, "lng": -71.5092598857373 },
                { "lat": -32.71775018198831, "lng": -71.50885218997932 },
                { "lat": -32.71823761894472, "lng": -71.50848740956431 },
                { "lat": -32.718589655089765, "lng": -71.50786513709163 },
                { "lat": -32.71910416695597, "lng": -71.50789732359883 },
                { "lat": -32.71927567025175, "lng": -71.50783295058442 },
                { "lat": -32.719699913829565, "lng": -71.5080367984634 },
                { "lat": -32.719880442399464, "lng": -71.5079187812703 },
                { "lat": -32.71942009382396, "lng": -71.50763983154117 },
                { "lat": -32.719672834512544, "lng": -71.507199949276 },
                { "lat": -32.71978115173125, "lng": -71.50726432229041 },
                { "lat": -32.719889468818366, "lng": -71.50721067811173 },
                { "lat": -32.71986238955893, "lng": -71.5066956939964 },
                { "lat": -32.720494236803624, "lng": -71.50666350748921 },
                { "lat": -32.72099068512333, "lng": -71.50611633680357 },
                { "lat": -32.72133368412904, "lng": -71.50618070981797 },
                { "lat": -32.7214961568818, "lng": -71.50549406433089 },
                { "lat": -32.72154128814947, "lng": -71.50610560796784 },
                { "lat": -32.72189331125342, "lng": -71.50620216748946 },
                { "lat": -32.722371699397954, "lng": -71.50490397836542 },
                { "lat": -32.7225883271475, "lng": -71.50506491090145 },
                { "lat": -32.723842952559096, "lng": -71.50252217677053 },
                { "lat": -32.72555787953762, "lng": -71.5026616516351 },
                { "lat": -32.725666189667514, "lng": -71.50297278790347 },
                { "lat": -32.726307021557226, "lng": -71.50334829715423 },
                { "lat": -32.72631604732558, "lng": -71.50392765428397 },
                { "lat": -32.72683051461229, "lng": -71.50400275613413 },
                { "lat": -32.72692979743115, "lng": -71.50197500618003 },
                { "lat": -32.72924034825022, "lng": -71.50217885396744 },
                { "lat": -32.72934865384678, "lng": -71.50241488835363 },
                { "lat": -32.73173134368031, "lng": -71.50210375211728 },
                { "lat": -32.731740368899516, "lng": -71.5017818870452 },
                { "lat": -32.731451561431804, "lng": -71.50155658149474 },
                { "lat": -32.73286851415662, "lng": -71.50012964628978 },
                { "lat": -32.73339196868172, "lng": -71.50035495184022 },
                { "lat": -32.73326561787091, "lng": -71.50073046109098 },
                { "lat": -32.73335586846834, "lng": -71.50143856424955 },
                { "lat": -32.733446118974385, "lng": -71.5021359385724 },
                { "lat": -32.734032745036245, "lng": -71.50246853248021 },
                { "lat": -32.734059819992034, "lng": -71.50216812507959 },
                { "lat": -32.7345832675209, "lng": -71.50217885391532 },
                { "lat": -32.7345832675209, "lng": -71.50268310919492 },
                { "lat": -32.735088662218, "lng": -71.50284404173095 },
                { "lat": -32.735350383345555, "lng": -71.50254363433034 },
                { "lat": -32.73544965667565, "lng": -71.5027045668664 },
                { "lat": -32.73545213038546, "lng": -71.50267970655253 },
                { "lat": -32.73560005324754, "lng": -71.50266544823975 },
                { "lat": -32.73563203653678, "lng": -71.50238978752633 },
                { "lat": -32.73544813246686, "lng": -71.50214264343843 },
                { "lat": -32.73550010539465, "lng": -71.50192876874696 },
                { "lat": -32.73561604489359, "lng": -71.50192876874696 },
                { "lat": -32.73559605533557, "lng": -71.50140121117471 },
                { "lat": -32.73542014703164, "lng": -71.50067878999468 },
                { "lat": -32.73596786038096, "lng": -71.50012746856781 },
                { "lat": -32.73609979084099, "lng": -71.50012271577978 },
                { "lat": -32.736487585274595, "lng": -71.49971873025147 },
                { "lat": -32.73670347010442, "lng": -71.50015123240532 },
                { "lat": -32.73715522743411, "lng": -71.50047917360156 },
                { "lat": -32.737339127981485, "lng": -71.50023202951365 },
                { "lat": -32.737495043365726, "lng": -71.50033659047394 },
                { "lat": -32.73760698244771, "lng": -71.50068354275119 },
                { "lat": -32.73814268896496, "lng": -71.5009544506937 },
                { "lat": -32.738374560936535, "lng": -71.50067878998026 },
                { "lat": -32.73868239003564, "lng": -71.50072156491855 },
                { "lat": -32.739206096874334, "lng": -71.50004191863938 },
                { "lat": -32.73925806761059, "lng": -71.49950485552529 },
                { "lat": -32.7390102069037, "lng": -71.49960941648554 },
                { "lat": -32.738982222587026, "lng": -71.49938128348133 },
                { "lat": -32.73919410362321, "lng": -71.49869688446867 },
                { "lat": -32.739417977378224, "lng": -71.49871589555235 },
                { "lat": -32.73948593894257, "lng": -71.49889650084735 },
                { "lat": -32.739605870988505, "lng": -71.4989060063892 },
                { "lat": -32.739841736874645, "lng": -71.49881095097078 },
                { "lat": -32.740069606714094, "lng": -71.49889650084735 },
                { "lat": -32.74014156548971, "lng": -71.49907710614237 },
                { "lat": -32.7403214621745, "lng": -71.49903908397499 },
                { "lat": -32.74051734926268, "lng": -71.49871114278143 },
                { "lat": -32.74052534464488, "lng": -71.49898205072394 },
                { "lat": -32.7405807592153, "lng": -71.49905117758841 },
                { "lat": -32.740816073835994, "lng": -71.49880338913056 },
                { "lat": -32.740795904035714, "lng": -71.49880338913056 },
                { "lat": -32.74105138783528, "lng": -71.49899522535598 },
                { "lat": -32.74113206677768, "lng": -71.49848366208813 },
                { "lat": -32.741232915352974, "lng": -71.49870747101782 },
                { "lat": -32.7415690764463, "lng": -71.49888332089115 },
                { "lat": -32.74186489715934, "lng": -71.49854760749662 },
                { "lat": -32.742153993725495, "lng": -71.49845968255995 },
                { "lat": -32.742160716890304, "lng": -71.49828383268662 },
                { "lat": -32.74226156430137, "lng": -71.49825185998237 },
                { "lat": -32.742348965298646, "lng": -71.4983877439754 },
                { "lat": -32.74265822967752, "lng": -71.49846767573602 },
                { "lat": -32.74280613834883, "lng": -71.49808400328511 },
                { "lat": -32.74312212423315, "lng": -71.49799607834845 },
                { "lat": -32.742913708137394, "lng": -71.49841971667965 },
                { "lat": -32.74396922983123, "lng": -71.49839573714253 },
                { "lat": -32.74399612193949, "lng": -71.49824386679738 },
                { "lat": -32.743848215243595, "lng": -71.49809998962829 },
                { "lat": -32.743787707888146, "lng": -71.49772431035345 },
                { "lat": -32.74407007519537, "lng": -71.49720475390953 },
                { "lat": -32.74398267588637, "lng": -71.49709284944468 },
                { "lat": -32.74370703135029, "lng": -71.49732465155043 },
                { "lat": -32.74358601640654, "lng": -71.49725271296589 },
                { "lat": -32.74367341610461, "lng": -71.49650934759227 },
                { "lat": -32.74344483209365, "lng": -71.49626155903387 },
                { "lat": -32.743498616618616, "lng": -71.49591785246328 },
                { "lat": -32.74358601640244, "lng": -71.49562210494905 },
                { "lat": -32.74347172436019, "lng": -71.49553418001238 },
                { "lat": -32.74347172436019, "lng": -71.49529438473057 },
                { "lat": -32.743666693049875, "lng": -71.49515050756148 },
                { "lat": -32.74362635473529, "lng": -71.49497465768816 },
                { "lat": -32.743391047536214, "lng": -71.49481479416694 },
                { "lat": -32.74346500129432, "lng": -71.49462295794149 },
                { "lat": -32.74331709371673, "lng": -71.49431122407515 },
                { "lat": -32.74309523188998, "lng": -71.4942392854906 },
                { "lat": -32.74301455472511, "lng": -71.49409540832151 },
                { "lat": -32.74310195498376, "lng": -71.49387959256788 },
                { "lat": -32.74278596901779, "lng": -71.49343996782743 },
                { "lat": -32.742611167794834, "lng": -71.49345595417955 },
                { "lat": -32.74244308937245, "lng": -71.49315221348925 },
                { "lat": -32.74210693157649, "lng": -71.49294439091169 },
                { "lat": -32.74173043333938, "lng": -71.49255272528472 },
                { "lat": -32.74154890683522, "lng": -71.49254473210867 },
                { "lat": -32.74118585271748, "lng": -71.49222500506626 },
                { "lat": -32.74102449485701, "lng": -71.49189728484777 },
                { "lat": -32.74119257595356, "lng": -71.49061038336683 },
                { "lat": -32.742866646387895, "lng": -71.48794066256265 },
                { "lat": -32.7438213231121, "lng": -71.48700546088835 },
                { "lat": -32.74521970430023, "lng": -71.48623811598655 },
                { "lat": -32.74654411245393, "lng": -71.48573454586001 },
                { "lat": -32.74708062284147, "lng": -71.48555057229707 },
                { "lat": -32.74872753510303, "lng": -71.48530342820918 },
                { "lat": -32.750390405911794, "lng": -71.48499925087023 },
                { "lat": -32.75261284823358, "lng": -71.48509430628864 },
                { "lat": -32.7631487088122, "lng": -71.48699541478213 },
                { "lat": -32.76621810280586, "lng": -71.4882881684727 },
                { "lat": -32.768248320959295, "lng": -71.4804746130782 },
                { "lat": -32.765786475739155, "lng": -71.48209055519142 },
                { "lat": -32.76519498330215, "lng": -71.48214758844247 },
                { "lat": -32.76402797318439, "lng": -71.48161527809928 },
                { "lat": -32.762712950774905, "lng": -71.47965021339229 },
                { "lat": -32.75908320557168, "lng": -71.47801960547598 },
                { "lat": -32.75483487130399, "lng": -71.4796502134222 },
                { "lat": -32.75107035523009, "lng": -71.47885089581617 },
                { "lat": -32.746203137695375, "lng": -71.47526995291514 },
                { "lat": -32.74332570735915, "lng": -71.47296791820976 },
                { "lat": -32.73888836613648, "lng": -71.46420739688587 },
                { "lat": -32.738995940654654, "lng": -71.46436726040709 },
            ]
        },
        {
            idService: 31,
            nameService: "Maintencillo / Marbella",
            coordinates: [
                //Mitencillo
                {"lat":-32.683891407417676, "lng":-71.44694010886579},
                {"lat":-32.68808393410361, "lng":-71.42424558110217},
                {"lat":-32.64554951879764, "lng":-71.41739061994019},
                {"lat":-32.64268964872936, "lng":-71.43236136409249},
                {"lat":-32.643965909870154, "lng":-71.43355224689707},
                {"lat":-32.644421713006984, "lng":-71.43634901711992},
                {"lat":-32.647794584072805, "lng":-71.43827969089986},
                {"lat":-32.64869095635512,  "lng":-71.43999384045502},
                {"lat":-32.64901000193774,  "lng":-71.44143733476359},
                {"lat":-32.65062040514628,  "lng":-71.44318757165942},
                {"lat":-32.65136482704605,  "lng":-71.44324170273072},
                {"lat":-32.65230673969989,  "lng":-71.44268234868613},
                {"lat":-32.65382593277363,  "lng":-71.44333192113135},
                {"lat":-32.65788205179187,  "lng":-71.44331387747513},
                {"lat":-32.66503677937212,  "lng":-71.44154559690324},
                {"lat":-32.67603787365267,  "lng":-71.44310346081292},
            ]
        },
    ]
};

export default Puchuncavi;
