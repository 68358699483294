import React, {useRef, useState} from 'react';
import Map from "./Map";

import communes from "./Constants/Communes";

const SearchAddress = (props) => {
    const setVentaData = props.setVentaData;
    const allServices = props.allServices;
    const setPayType = props.setPayType;
    const setMoneyType = props.setMoneyType;
    const setDiscToPay = props.setDiscToPay;
    const setAddToPay = props.setAddToPay;
    const selecttarifa = props.selecttarifa;
    const setSelectedOptionService = props.setSelectedOptionService;
    const refDirection = props.refDirection;
    const coordinatesPolygon = props.coordinatesPolygon;
    const setCoordinatesPolygon = props.setCoordinatesPolygon;
    const initData = props.initData;
    const initPolygonData = props.initPolygonData;
    const initPayData = props.initPayData;
    const hidePayAndPreview = props.hidePayAndPreview;

    const [center, setCenter] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);

    const refPolygon = useRef(null);


    const onAutocompletePlaceChanged = () => {
        if (autocomplete !== null) {
            initData();
            initPolygonData();
            initPayData();
            hidePayAndPreview();
            const destinationAddress = autocomplete.getPlace();
            if (destinationAddress && destinationAddress.address_components) {
                //console.log("destinationAddress", destinationAddress);
                //console.log("nombre", getAddressName(destinationAddress));
                let service = getServiceByCommune(getAddressName(destinationAddress));
                if (!service) { //Consulta si es por comuna normal
                    const addressCommune = getAddressCommune(destinationAddress.address_components);
                    if (addressCommune) {
                        service = getAddressByPolygons(addressCommune, destinationAddress);
                    }
                    if (!service) {
                        service = getServiceByCommune(addressCommune.long_name);
                    }
                }
                if (!service) { // Consulta si es por Localidad
                    const addressLocality = getAddressLocality(destinationAddress.address_components);
                    if (addressLocality) {
                        service = getAddressByPolygons(addressLocality, destinationAddress);
                    }
                    if (!service) {
                        service = getServiceByCommune(addressLocality.long_name);
                    }
                }
                if (service) {
                    setAddressByService(service);
                } else {
                    console.log("Servicio no disponible");
                }
                const pos = {
                    lat: destinationAddress.geometry.location.lat(),
                    lng: destinationAddress.geometry.location.lng()
                }
                setCenter(pos);
            } else {
                console.log('Autocomplete is not loaded yet!')
            }
        }
    }

    const setAddressByService = (service) => {
        setSelectedOptionService(selecttarifa.find( option => option.value === service.idservicio));
        setVentaData({
            idservice: service.idservicio,
            servicename: service.comuna,
            valor: service.valor,
            valororig: service.valor,
            valorpesos: service.valor,
        });
        document.getElementById('step-pago').style.display = 'block';
        document.getElementById('step-previewventa').style.display = 'block';
        setPayType('EF');
        setMoneyType('CL');
        setDiscToPay(0);
        setAddToPay(0);
    };

    const getAddressName = (destinationAddress) => {
        return destinationAddress.name;
    }

    const getAddressLocality = (addressComponents) => {
        return addressComponents.find( addressComponent => {
            return addressComponent.types.includes("locality");
        });
    }

    const getAddressCommune = (addressComponents) => {
        return addressComponents.find( addressComponent => {
            return addressComponent.types.includes("administrative_area_level_3");
        });
        
    }

    const getServiceByCommune = (name) => {
        return allServices.find(service => service.comuna.trim() === name);
    }

    const getAddressByPolygons = (commune, destinationAddress) => {
        const infoCommune = communes.find(item => item.commune === commune.long_name);
        if (infoCommune) {
            const communeSelected = infoCommune.polygons.find(polygon => {
                setCoordinatesPolygon(polygon.coordinates);
                return window.google.maps.geometry.poly.containsLocation(destinationAddress.geometry.location,
                    refPolygon.current.state.polygon);
            });
            if (communeSelected) {
                return allServices.find(service => service.idservicio === communeSelected.idService );
            }
            setCoordinatesPolygon([]);
        }
    }

    return (
        <div className="col-md-5">
            <div className="card">
                <div className="card-body" id="addressSearch">
                    <h5 className="card-title">Dirección</h5>
                    <div className="col-md-12" id="map">
                        <Map
                            setAutocomplete={setAutocomplete}
                            center={center}
                            setCenter={setCenter}
                            onAutocompletePlaceChanged={onAutocompletePlaceChanged}
                            refPolygon={refPolygon}
                            refDirection={refDirection}
                            coordinatesPolygon={coordinatesPolygon}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchAddress;
