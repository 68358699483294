const Zapallar = {
    commune: "Zapallar",
    polygons: [
        {
            idService: 24,
            nameService: "Papudo / Zapallar",
            coordinates: [
                { "lng": -71.468301, "lat": -32.5513841 },
                { "lng": -71.4687451, "lat": -32.5512514 },
                { "lng": -71.4689903, "lat": -32.5512351 },
                { "lng": -71.4690623, "lat": -32.5510567 },
                { "lng": -71.4692979, "lat": -32.5511013 },
                { "lng": -71.4697643, "lat": -32.5509431 },
                { "lng": -71.4699566, "lat": -32.5509308 },
                { "lng": -71.4701393, "lat": -32.5509956 },
                { "lng": -71.4701874, "lat": -32.5511172 },
                { "lng": -71.4704134, "lat": -32.551186 },
                { "lng": -71.4705891, "lat": -32.5513927 },
                { "lng": -71.4706804, "lat": -32.5513643 },
                { "lng": -71.4707333, "lat": -32.5512832 },
                { "lng": -71.4708294, "lat": -32.5512953 },
                { "lng": -71.4709063, "lat": -32.5512386 },
                { "lng": -71.4710361, "lat": -32.5510278 },
                { "lng": -71.4712139, "lat": -32.5509061 },
                { "lng": -71.471387, "lat": -32.5509182 },
                { "lng": -71.4715794, "lat": -32.5511046 },
                { "lng": -71.4717285, "lat": -32.551137 },
                { "lng": -71.4719498, "lat": -32.5514368 },
                { "lng": -71.4719691, "lat": -32.5516232 },
                { "lng": -71.4719307, "lat": -32.5518624 },
                { "lng": -71.4716953, "lat": -32.5522718 },
                { "lng": -71.471705, "lat": -32.5525068 },
                { "lng": -71.4712388, "lat": -32.55278 },
                { "lng": -71.4709743, "lat": -32.5527842 },
                { "lng": -71.4707822, "lat": -32.5532624 },
                { "lng": -71.4705708, "lat": -32.5535867 },
                { "lng": -71.4702728, "lat": -32.5538138 },
                { "lng": -71.470066, "lat": -32.5537368 },
                { "lng": -71.4700082, "lat": -32.5535423 },
                { "lng": -71.4699313, "lat": -32.553591 },
                { "lng": -71.4698351, "lat": -32.5534978 },
                { "lng": -71.4698015, "lat": -32.5535343 },
                { "lng": -71.4697149, "lat": -32.5534857 },
                { "lng": -71.4694265, "lat": -32.5537168 },
                { "lng": -71.4688228, "lat": -32.5536575 },
                { "lng": -71.4686354, "lat": -32.5538156 },
                { "lng": -71.4689001, "lat": -32.5545247 },
                { "lng": -71.4687847, "lat": -32.554541 },
                { "lng": -71.4685876, "lat": -32.5546869 },
                { "lng": -71.4687031, "lat": -32.5549057 },
                { "lng": -71.4686768, "lat": -32.5550306 },
                { "lng": -71.4684461, "lat": -32.5553265 },
                { "lng": -71.4685375, "lat": -32.5553913 },
                { "lng": -71.4684559, "lat": -32.5558128 },
                { "lng": -71.4682203, "lat": -32.5558899 },
                { "lng": -71.468129, "lat": -32.5560115 },
                { "lng": -71.4680472, "lat": -32.5559224 },
                { "lng": -71.4679943, "lat": -32.5559264 },
                { "lng": -71.4680762, "lat": -32.5562101 },
                { "lng": -71.4679465, "lat": -32.556433 },
                { "lng": -71.4678168, "lat": -32.5568343 },
                { "lng": -71.4679583, "lat": -32.5569799 },
                { "lng": -71.46799, "lat": -32.5570126 },
                { "lng": -71.4679035, "lat": -32.5571099 },
                { "lng": -71.4679853, "lat": -32.5572314 },
                { "lng": -71.4678074, "lat": -32.5573085 },
                { "lng": -71.4676969, "lat": -32.5575206 },
                { "lng": -71.4674469, "lat": -32.5576382 },
                { "lng": -71.4674762, "lat": -32.558883 },
                { "lng": -71.4675965, "lat": -32.5591262 },
                { "lng": -71.4675966, "lat": -32.5592599 },
                { "lng": -71.4675004, "lat": -32.5592883 },
                { "lng": -71.4674717, "lat": -32.5594545 },
                { "lng": -71.467337, "lat": -32.5594545 },
                { "lng": -71.4672073, "lat": -32.5596896 },
                { "lng": -71.4670871, "lat": -32.5597099 },
                { "lng": -71.4669909, "lat": -32.5598112 },
                { "lng": -71.4668807, "lat": -32.5607069 },
                { "lng": -71.4666628, "lat": -32.5611089 },
                { "lng": -71.4665284, "lat": -32.561656 },
                { "lng": -71.4664082, "lat": -32.5617695 },
                { "lng": -71.4662062, "lat": -32.5616683 },
                { "lng": -71.4663554, "lat": -32.5618425 },
                { "lng": -71.4663554, "lat": -32.561956 },
                { "lng": -71.4662833, "lat": -32.5620249 },
                { "lng": -71.4661871, "lat": -32.5620654 },
                { "lng": -71.4659707, "lat": -32.5619399 },
                { "lng": -71.4658746, "lat": -32.5620453 },
                { "lng": -71.4660189, "lat": -32.5622357 },
                { "lng": -71.4660238, "lat": -32.5623857 },
                { "lng": -71.4659517, "lat": -32.5624424 },
                { "lng": -71.4659373, "lat": -32.5625599 },
                { "lng": -71.4657978, "lat": -32.5625762 },
                { "lng": -71.4656825, "lat": -32.5627505 },
                { "lng": -71.465567, "lat": -32.5626249 },
                { "lng": -71.4655141, "lat": -32.5626249 },
                { "lng": -71.4653651, "lat": -32.5627465 },
                { "lng": -71.4652175, "lat": -32.5630439 },
                { "lng": -71.4649531, "lat": -32.5630602 },
                { "lng": -71.4647848, "lat": -32.5632345 },
                { "lng": -71.4646117, "lat": -32.5632061 },
                { "lng": -71.4643327, "lat": -32.5628779 },
                { "lng": -71.4643855, "lat": -32.5627685 },
                { "lng": -71.4643758, "lat": -32.5625375 },
                { "lng": -71.4641402, "lat": -32.5626186 },
                { "lng": -71.4640827, "lat": -32.5629712 },
                { "lng": -71.4638711, "lat": -32.5630159 },
                { "lng": -71.4634865, "lat": -32.5633078 },
                { "lng": -71.4630828, "lat": -32.5640941 },
                { "lng": -71.4622267, "lat": -32.5651508 },
                { "lng": -71.4618613, "lat": -32.5653657 },
                { "lng": -71.4615103, "lat": -32.565463 },
                { "lng": -71.4613852, "lat": -32.565459 },
                { "lng": -71.4614044, "lat": -32.5653212 },
                { "lng": -71.461289, "lat": -32.565305 },
                { "lng": -71.4608564, "lat": -32.5658482 },
                { "lng": -71.460438, "lat": -32.5657915 },
                { "lng": -71.4602842, "lat": -32.5659537 },
                { "lng": -71.4601543, "lat": -32.5659983 },
                { "lng": -71.4600229, "lat": -32.5662518 },
                { "lng": -71.4597921, "lat": -32.5662802 },
                { "lng": -71.459845, "lat": -32.5663896 },
                { "lng": -71.4598306, "lat": -32.5665153 },
                { "lng": -71.459571, "lat": -32.5666288 },
                { "lng": -71.4596432, "lat": -32.5669611 },
                { "lng": -71.4595855, "lat": -32.56703 },
                { "lng": -71.4593691, "lat": -32.5670179 },
                { "lng": -71.4593018, "lat": -32.5670584 },
                { "lng": -71.4596385, "lat": -32.5672488 },
                { "lng": -71.4596578, "lat": -32.5673623 },
                { "lng": -71.4595568, "lat": -32.5674939 },
                { "lng": -71.4593356, "lat": -32.5675467 },
                { "lng": -71.4589221, "lat": -32.5677372 },
                { "lng": -71.4588981, "lat": -32.5678345 },
                { "lng": -71.4586336, "lat": -32.5678508 },
                { "lng": -71.4581775, "lat": -32.5675182 },
                { "lng": -71.4579283, "lat": -32.5676998 },
                { "lng": -71.4580758, "lat": -32.5679481 },
                { "lng": -71.4582538, "lat": -32.5681143 },
                { "lng": -71.4584029, "lat": -32.5681426 },
                { "lng": -71.4584365, "lat": -32.5681912 },
                { "lng": -71.4585857, "lat": -32.5684911 },
                { "lng": -71.4586194, "lat": -32.5687099 },
                { "lng": -71.4587734, "lat": -32.5688551 },
                { "lng": -71.4588408, "lat": -32.5690415 },
                { "lng": -71.4590716, "lat": -32.5691103 },
                { "lng": -71.4593063, "lat": -32.569353 },
                { "lng": -71.4593458, "lat": -32.569394 },
                { "lng": -71.4592978, "lat": -32.5697142 },
                { "lng": -71.4591055, "lat": -32.5699493 },
                { "lng": -71.4590959, "lat": -32.5700425 },
                { "lng": -71.4590527, "lat": -32.5700871 },
                { "lng": -71.458894, "lat": -32.5700871 },
                { "lng": -71.458894, "lat": -32.5702573 },
                { "lng": -71.4587113, "lat": -32.5703384 },
                { "lng": -71.4585238, "lat": -32.5706181 },
                { "lng": -71.4582157, "lat": -32.5707051 },
                { "lng": -71.4582061, "lat": -32.5708754 },
                { "lng": -71.4583023, "lat": -32.5709685 },
                { "lng": -71.458288, "lat": -32.5712239 },
                { "lng": -71.4586391, "lat": -32.571394 },
                { "lng": -71.4588027, "lat": -32.571706 },
                { "lng": -71.4587835, "lat": -32.5720424 },
                { "lng": -71.4588798, "lat": -32.5722248 },
                { "lng": -71.4588798, "lat": -32.5723747 },
                { "lng": -71.4590098, "lat": -32.5726989 },
                { "lng": -71.4592551, "lat": -32.5729258 },
                { "lng": -71.4591902, "lat": -32.5729869 },
                { "lng": -71.4591758, "lat": -32.5731814 },
                { "lng": -71.4593249, "lat": -32.5732462 },
                { "lng": -71.4593778, "lat": -32.5732341 },
                { "lng": -71.4594643, "lat": -32.573076 },
                { "lng": -71.4596134, "lat": -32.5731165 },
                { "lng": -71.4596519, "lat": -32.5731935 },
                { "lng": -71.4595317, "lat": -32.5733151 },
                { "lng": -71.4596953, "lat": -32.5735298 },
                { "lng": -71.4601523, "lat": -32.5737671 },
                { "lng": -71.4602581, "lat": -32.5737792 },
                { "lng": -71.4605227, "lat": -32.5739494 },
                { "lng": -71.4604987, "lat": -32.5742047 },
                { "lng": -71.4603833, "lat": -32.5742777 },
                { "lng": -71.4604315, "lat": -32.5744236 },
                { "lng": -71.4606046, "lat": -32.574456 },
                { "lng": -71.4604892, "lat": -32.5745938 },
                { "lng": -71.4603258, "lat": -32.5746789 },
                { "lng": -71.4599554, "lat": -32.5746587 },
                { "lng": -71.4597967, "lat": -32.574602 },
                { "lng": -71.459763, "lat": -32.5745088 },
                { "lng": -71.4597197, "lat": -32.574521 },
                { "lng": -71.4597006, "lat": -32.5747399 },
                { "lng": -71.4593111, "lat": -32.5750358 },
                { "lng": -71.4593063, "lat": -32.5750763 },
                { "lng": -71.459566, "lat": -32.575129 },
                { "lng": -71.4596478, "lat": -32.5752059 },
                { "lng": -71.4599799, "lat": -32.5758429 },
                { "lng": -71.4599895, "lat": -32.5759685 },
                { "lng": -71.4598597, "lat": -32.5761469 },
                { "lng": -71.4599233, "lat": -32.57633 },
                { "lng": -71.4599608, "lat": -32.5764386 },
                { "lng": -71.4599128, "lat": -32.5766251 },
                { "lng": -71.4598262, "lat": -32.5766697 },
                { "lng": -71.45973, "lat": -32.5766251 },
                { "lng": -71.459629, "lat": -32.5767224 },
                { "lng": -71.459528, "lat": -32.5767224 },
                { "lng": -71.4595328, "lat": -32.5764509 },
                { "lng": -71.4594317, "lat": -32.5763577 },
                { "lng": -71.4593211, "lat": -32.5763861 },
                { "lng": -71.4592779, "lat": -32.5764712 },
                { "lng": -71.4593308, "lat": -32.5765928 },
                { "lng": -71.4593068, "lat": -32.5766738 },
                { "lng": -71.4591769, "lat": -32.5766901 },
                { "lng": -71.4589701, "lat": -32.5765969 },
                { "lng": -71.458821, "lat": -32.5765929 },
                { "lng": -71.458761, "lat": -32.5766487 },
                { "lng": -71.4588716, "lat": -32.5767662 },
                { "lng": -71.4588813, "lat": -32.5769283 },
                { "lng": -71.4591891, "lat": -32.5770174 },
                { "lng": -71.4592324, "lat": -32.5770985 },
                { "lng": -71.4592228, "lat": -32.5772039 },
                { "lng": -71.4589728, "lat": -32.5775281 },
                { "lng": -71.4588382, "lat": -32.5774876 },
                { "lng": -71.4585062, "lat": -32.5772 },
                { "lng": -71.4583908, "lat": -32.5771757 },
                { "lng": -71.4585304, "lat": -32.5774755 },
                { "lng": -71.4578007, "lat": -32.577798 },
                { "lng": -71.4585256, "lat": -32.5776498 },
                { "lng": -71.4586459, "lat": -32.5777187 },
                { "lng": -71.4588239, "lat": -32.5779821 },
                { "lng": -71.458901, "lat": -32.5783678 },
                { "lng": -71.4586797, "lat": -32.5783435 },
                { "lng": -71.4589587, "lat": -32.5784286 },
                { "lng": -71.4589588, "lat": -32.5787893 },
                { "lng": -71.4589107, "lat": -32.5787974 },
                { "lng": -71.4587856, "lat": -32.5786758 },
                { "lng": -71.4587568, "lat": -32.5788015 },
                { "lng": -71.4583624, "lat": -32.5788137 },
                { "lng": -71.4583143, "lat": -32.5787935 },
                { "lng": -71.458372, "lat": -32.5786921 },
                { "lng": -71.458146, "lat": -32.5786719 },
                { "lng": -71.458021, "lat": -32.5788178 },
                { "lng": -71.4578623, "lat": -32.5788908 },
                { "lng": -71.4576025, "lat": -32.5788179 },
                { "lng": -71.4569759, "lat": -32.5786787 },
                { "lng": -71.4567622, "lat": -32.5786044 },
                { "lng": -71.4566366, "lat": -32.5785035 },
                { "lng": -71.4563866, "lat": -32.5785028 },
                { "lng": -71.4561472, "lat": -32.5782767 },
                { "lng": -71.4559749, "lat": -32.5781457 },
                { "lng": -71.4558306, "lat": -32.5781237 },
                { "lng": -71.4556509, "lat": -32.5781154 },
                { "lng": -71.455518, "lat": -32.5781788 },
                { "lng": -71.4554094, "lat": -32.5782737 },
                { "lng": -71.4553022, "lat": -32.5783958 },
                { "lng": -71.4552553, "lat": -32.5784941 },
                { "lng": -71.4552378, "lat": -32.5785981 },
                { "lng": -71.4552605, "lat": -32.5786919 },
                { "lng": -71.4553035, "lat": -32.5787652 },
                { "lng": -71.455417, "lat": -32.5788467 },
                { "lng": -71.4557441, "lat": -32.5789966 },
                { "lng": -71.4560183, "lat": -32.579187 },
                { "lng": -71.4562588, "lat": -32.5794707 },
                { "lng": -71.4565089, "lat": -32.5793936 },
                { "lng": -71.4565474, "lat": -32.5794179 },
                { "lng": -71.4565618, "lat": -32.5794909 },
                { "lng": -71.4564416, "lat": -32.579572 },
                { "lng": -71.4566965, "lat": -32.5796813 },
                { "lng": -71.4568454, "lat": -32.5799941 },
                { "lng": -71.4568454, "lat": -32.5801156 },
                { "lng": -71.4565854, "lat": -32.5802698 },
                { "lng": -71.4566943, "lat": -32.5804034 },
                { "lng": -71.4569128, "lat": -32.5804074 },
                { "lng": -71.4569802, "lat": -32.580452 },
                { "lng": -71.4570331, "lat": -32.5805452 },
                { "lng": -71.4570091, "lat": -32.5806425 },
                { "lng": -71.4566292, "lat": -32.5808087 },
                { "lng": -71.4566581, "lat": -32.5809019 },
                { "lng": -71.4568072, "lat": -32.5810235 },
                { "lng": -71.4568217, "lat": -32.5812091 },
                { "lng": -71.4569083, "lat": -32.5813388 },
                { "lng": -71.4572354, "lat": -32.5814643 },
                { "lng": -71.4573797, "lat": -32.5815737 },
                { "lng": -71.4575481, "lat": -32.5815818 },
                { "lng": -71.4576635, "lat": -32.5816507 },
                { "lng": -71.4577838, "lat": -32.5819708 },
                { "lng": -71.4577165, "lat": -32.5820397 },
                { "lng": -71.4574857, "lat": -32.5820195 },
                { "lng": -71.457452, "lat": -32.58206 },
                { "lng": -71.4577936, "lat": -32.5824004 },
                { "lng": -71.4577792, "lat": -32.5824896 },
                { "lng": -71.4576493, "lat": -32.5824896 },
                { "lng": -71.4575916, "lat": -32.5825342 },
                { "lng": -71.4577648, "lat": -32.5826436 },
                { "lng": -71.4576494, "lat": -32.5828422 },
                { "lng": -71.4576289, "lat": -32.5829982 },
                { "lng": -71.4575279, "lat": -32.5830307 },
                { "lng": -71.4574558, "lat": -32.5831401 },
                { "lng": -71.457124, "lat": -32.5833752 },
                { "lng": -71.4568883, "lat": -32.5833996 },
                { "lng": -71.4567585, "lat": -32.5835009 },
                { "lng": -71.4567489, "lat": -32.5836023 },
                { "lng": -71.4562102, "lat": -32.5837766 },
                { "lng": -71.4561622, "lat": -32.5838699 },
                { "lng": -71.4562632, "lat": -32.5839468 },
                { "lng": -71.4562921, "lat": -32.5842427 },
                { "lng": -71.456077, "lat": -32.5842862 },
                { "lng": -71.4560203, "lat": -32.5843601 },
                { "lng": -71.4557626, "lat": -32.584434 },
                { "lng": -71.4556233, "lat": -32.5844166 },
                { "lng": -71.4551908, "lat": -32.5841771 },
                { "lng": -71.454834, "lat": -32.5841353 },
                { "lng": -71.4544257, "lat": -32.5841076 },
                { "lng": -71.4536686, "lat": -32.5840934 },
                { "lng": -71.4533026, "lat": -32.5842968 },
                { "lng": -71.4528787, "lat": -32.5841104 },
                { "lng": -71.452577, "lat": -32.5840945 },
                { "lng": -71.4524967, "lat": -32.5841941 },
                { "lng": -71.4521589, "lat": -32.5841689 },
                { "lng": -71.4521106, "lat": -32.5841652 },
                { "lng": -71.4520298, "lat": -32.5841538 },
                { "lng": -71.4519667, "lat": -32.5841632 },
                { "lng": -71.451858, "lat": -32.5841577 },
                { "lng": -71.451776, "lat": -32.5841533 },
                { "lng": -71.4515349, "lat": -32.5839533 },
                { "lng": -71.4510254, "lat": -32.5839499 },
                { "lng": -71.449196, "lat": -32.5846968 },
                { "lng": -71.4475384, "lat": -32.5858743 },
                { "lng": -71.446479, "lat": -32.5866291 },
                { "lng": -71.4459292, "lat": -32.5870201 },
                { "lng": -71.445398, "lat": -32.5874269 },
                { "lng": -71.4449582, "lat": -32.5878088 },
                { "lng": -71.4443117, "lat": -32.5884145 },
                { "lng": -71.4438772, "lat": -32.5887896 },
                { "lng": -71.4433703, "lat": -32.5893072 },
                { "lng": -71.4426729, "lat": -32.5900416 },
                { "lng": -71.4421499, "lat": -32.5906066 },
                { "lng": -71.4417046, "lat": -32.5912574 },
                { "lng": -71.4412165, "lat": -32.5917049 },
                { "lng": -71.4409268, "lat": -32.5920891 },
                { "lng": -71.4407337, "lat": -32.5926314 },
                { "lng": -71.4403797, "lat": -32.5931648 },
                { "lng": -71.4400202, "lat": -32.5936257 },
                { "lng": -71.4397278, "lat": -32.5940664 },
                { "lng": -71.4394757, "lat": -32.59455 },
                { "lng": -71.4391995, "lat": -32.5949748 },
                { "lng": -71.4388964, "lat": -32.5956414 },
                { "lng": -71.4386549, "lat": -32.5963555 },
                { "lng": -71.4384377, "lat": -32.5967419 },
                { "lng": -71.4381292, "lat": -32.597447 },
                { "lng": -71.4378959, "lat": -32.5980051 },
                { "lng": -71.4375861, "lat": -32.5985666 },
                { "lng": -71.437173, "lat": -32.5994535 },
                { "lng": -71.4368096, "lat": -32.6002422 },
                { "lng": -71.4366246, "lat": -32.6006647 },
                { "lng": -71.4361176, "lat": -32.6019029 },
                { "lng": -71.4354739, "lat": -32.6033728 },
                { "lng": -71.4353183, "lat": -32.6040383 },
                { "lng": -71.4351708, "lat": -32.604593 },
                { "lng": -71.4349106, "lat": -32.605246 },
                { "lng": -71.4347832, "lat": -32.6056854 },
                { "lng": -71.434755, "lat": -32.6059543 },
                { "lng": -71.4347242, "lat": -32.6060453 },
                { "lng": -71.4355551, "lat": -32.6063655 },
                { "lng": -71.4355489, "lat": -32.6067562 },
                { "lng": -71.4357863, "lat": -32.6070572 },
                { "lng": -71.4357089, "lat": -32.607496 },
                { "lng": -71.4354923, "lat": -32.6078609 },
                { "lng": -71.4353943, "lat": -32.6078045 },
                { "lng": -71.4352499, "lat": -32.6079131 },
                { "lng": -71.4351674, "lat": -32.6081694 },
                { "lng": -71.4353273, "lat": -32.6086995 },
                { "lng": -71.4357503, "lat": -32.6087516 },
                { "lng": -71.4360133, "lat": -32.6088732 },
                { "lng": -71.4361393, "lat": -32.6097847 },
                { "lng": -71.436062, "lat": -32.6099671 },
                { "lng": -71.4358247, "lat": -32.6100888 },
                { "lng": -71.4360362, "lat": -32.6101018 },
                { "lng": -71.436129, "lat": -32.6102756 },
                { "lng": -71.4361084, "lat": -32.6103321 },
                { "lng": -71.4359691, "lat": -32.6103712 },
                { "lng": -71.4359227, "lat": -32.6105276 },
                { "lng": -71.4358041, "lat": -32.6105276 },
                { "lng": -71.4358029, "lat": -32.6107315 },
                { "lng": -71.4354211, "lat": -32.6108836 },
                { "lng": -71.4355913, "lat": -32.6108619 },
                { "lng": -71.4356739, "lat": -32.6109792 },
                { "lng": -71.435679, "lat": -32.61104 },
                { "lng": -71.4355656, "lat": -32.6110791 },
                { "lng": -71.4355243, "lat": -32.6111703 },
                { "lng": -71.4356171, "lat": -32.6113876 },
                { "lng": -71.4355862, "lat": -32.6115614 },
                { "lng": -71.4354727, "lat": -32.6116222 },
                { "lng": -71.4355295, "lat": -32.6117004 },
                { "lng": -71.4355243, "lat": -32.611822 },
                { "lng": -71.4354005, "lat": -32.6119133 },
                { "lng": -71.435318, "lat": -32.612061 },
                { "lng": -71.4349518, "lat": -32.6121609 },
                { "lng": -71.434957, "lat": -32.6123043 },
                { "lng": -71.4348796, "lat": -32.6123912 },
                { "lng": -71.4346166, "lat": -32.6124694 },
                { "lng": -71.4341862, "lat": -32.61269 },
                { "lng": -71.4343461, "lat": -32.6127508 },
                { "lng": -71.4343409, "lat": -32.612816 },
                { "lng": -71.4342481, "lat": -32.6129203 },
                { "lng": -71.4340521, "lat": -32.6129898 },
                { "lng": -71.4339283, "lat": -32.6132114 },
                { "lng": -71.4339644, "lat": -32.6135546 },
                { "lng": -71.4341862, "lat": -32.6138587 },
                { "lng": -71.4344854, "lat": -32.6139364 },
                { "lng": -71.4346762, "lat": -32.6138887 },
                { "lng": -71.4348603, "lat": -32.6139104 },
                { "lng": -71.4350563, "lat": -32.6138452 },
                { "lng": -71.4349532, "lat": -32.6139582 },
                { "lng": -71.4350666, "lat": -32.6140233 },
                { "lng": -71.4351079, "lat": -32.6141146 },
                { "lng": -71.4349841, "lat": -32.6141363 },
                { "lng": -71.43485, "lat": -32.6140451 },
                { "lng": -71.4347056, "lat": -32.6140624 },
                { "lng": -71.4345251, "lat": -32.6141841 },
                { "lng": -71.4343652, "lat": -32.614158 },
                { "lng": -71.4340712, "lat": -32.6142232 },
                { "lng": -71.4338236, "lat": -32.6144057 },
                { "lng": -71.433674, "lat": -32.61504 },
                { "lng": -71.4333906, "lat": -32.6156125 },
                { "lng": -71.433535, "lat": -32.6158167 },
                { "lng": -71.4333855, "lat": -32.6158341 },
                { "lng": -71.4334216, "lat": -32.6159558 },
                { "lng": -71.433597, "lat": -32.6161339 },
                { "lng": -71.4335196, "lat": -32.6163772 },
                { "lng": -71.4336021, "lat": -32.6165814 },
                { "lng": -71.4335454, "lat": -32.6167335 },
                { "lng": -71.433762, "lat": -32.6169507 },
                { "lng": -71.433664, "lat": -32.6170131 },
                { "lng": -71.4338136, "lat": -32.6171477 },
                { "lng": -71.4338188, "lat": -32.6172998 },
                { "lng": -71.4339116, "lat": -32.6174606 },
                { "lng": -71.4339271, "lat": -32.6175648 },
                { "lng": -71.4338394, "lat": -32.6177082 },
                { "lng": -71.4338704, "lat": -32.6178776 },
                { "lng": -71.4337879, "lat": -32.6181427 },
                { "lng": -71.4334934, "lat": -32.6185992 },
                { "lng": -71.4331841, "lat": -32.6188276 },
                { "lng": -71.4329281, "lat": -32.6189467 },
                { "lng": -71.4327448, "lat": -32.6189692 },
                { "lng": -71.4326722, "lat": -32.6192008 },
                { "lng": -71.4325614, "lat": -32.6193392 },
                { "lng": -71.4326035, "lat": -32.6196062 },
                { "lng": -71.4324392, "lat": -32.6197284 },
                { "lng": -71.4325997, "lat": -32.6202464 },
                { "lng": -71.4325386, "lat": -32.6203552 },
                { "lng": -71.4324355, "lat": -32.620397 },
                { "lng": -71.4324928, "lat": -32.6204742 },
                { "lng": -71.4324813, "lat": -32.6207026 },
                { "lng": -71.4323171, "lat": -32.6208603 },
                { "lng": -71.4323706, "lat": -32.6209246 },
                { "lng": -71.4323744, "lat": -32.621079 },
                { "lng": -71.4321681, "lat": -32.6213171 },
                { "lng": -71.4321462, "lat": -32.6215467 },
                { "lng": -71.4318999, "lat": -32.6217447 },
                { "lng": -71.4318351, "lat": -32.6219687 },
                { "lng": -71.4316796, "lat": -32.6222786 },
                { "lng": -71.4316238, "lat": -32.6227638 },
                { "lng": -71.4317369, "lat": -32.6228646 },
                { "lng": -71.4318663, "lat": -32.6231759 },
                { "lng": -71.4323661, "lat": -32.6230385 },
                { "lng": -71.432378, "lat": -32.6231587 },
                { "lng": -71.432378, "lat": -32.6234045 },
                { "lng": -71.4322921, "lat": -32.6234768 },
                { "lng": -71.4321119, "lat": -32.6235925 },
                { "lng": -71.432172, "lat": -32.6236503 },
                { "lng": -71.4322578, "lat": -32.6238021 },
                { "lng": -71.432129, "lat": -32.6238672 },
                { "lng": -71.4320003, "lat": -32.6238672 },
                { "lng": -71.4316661, "lat": -32.6238938 },
                { "lng": -71.4314613, "lat": -32.6239945 },
                { "lng": -71.4313621, "lat": -32.6242249 },
                { "lng": -71.4312601, "lat": -32.6246225 },
                { "lng": -71.4311636, "lat": -32.6251488 },
                { "lng": -71.4310349, "lat": -32.6258469 },
                { "lng": -71.4309329, "lat": -32.6265968 },
                { "lng": -71.4308095, "lat": -32.6273084 },
                { "lng": -71.4307532, "lat": -32.6277828 },
                { "lng": -71.4307022, "lat": -32.6281916 },
                { "lng": -71.4305976, "lat": -32.6286299 },
                { "lng": -71.4304635, "lat": -32.629172 },
                { "lng": -71.430442, "lat": -32.629486 },
                { "lng": -71.4304608, "lat": -32.6297548 },
                { "lng": -71.4304367, "lat": -32.630062 },
                { "lng": -71.4303643, "lat": -32.6303986 },
                { "lng": -71.4302704, "lat": -32.6308595 },
                { "lng": -71.4302436, "lat": -32.6312796 },
                { "lng": -71.4302409, "lat": -32.6320804 },
                { "lng": -71.4294129, "lat": -32.6321407 },
                { "lng": -71.429044, "lat": -32.6321774 },
                { "lng": -71.4278161, "lat": -32.6324643 },
                { "lng": -71.4264026, "lat": -32.6328835 },
                { "lng": -71.425217, "lat": -32.6330922 },
                { "lng": -71.4242133, "lat": -32.6331102 },
                { "lng": -71.4211926, "lat": -32.6318503 },
                { "lng": -71.4197367, "lat": -32.6314071 },
                { "lng": -71.4194047, "lat": -32.6308853 },
                { "lng": -71.4187046, "lat": -32.6290298 },
                { "lng": -71.4178699, "lat": -32.6277889 },
                { "lng": -71.4173732, "lat": -32.6270898 },
                { "lng": -71.4169048, "lat": -32.6266511 },
                { "lng": -71.4167171, "lat": -32.6264755 },
                { "lng": -71.4159806, "lat": -32.6268175 },
                { "lng": -71.4152623, "lat": -32.6277983 },
                { "lng": -71.4148503, "lat": -32.6291207 },
                { "lng": -71.4143498, "lat": -32.6297618 },
                { "lng": -71.4140016, "lat": -32.630026 },
                { "lng": -71.4132694, "lat": -32.630097 },
                { "lng": -71.4121101, "lat": -32.6299623 },
                { "lng": -71.4112647, "lat": -32.6297749 },
                { "lng": -71.410842, "lat": -32.6294957 },
                { "lng": -71.4104842, "lat": -32.6288934 },
                { "lng": -71.4099274, "lat": -32.6284701 },
                { "lng": -71.4095063, "lat": -32.6283197 },
                { "lng": -71.4091071, "lat": -32.6284096 },
                { "lng": -71.4088421, "lat": -32.6286069 },
                { "lng": -71.4086812, "lat": -32.6288762 },
                { "lng": -71.4078277, "lat": -32.6310163 },
                { "lng": -71.4073954, "lat": -32.6316805 },
                { "lng": -71.4067473, "lat": -32.6320645 },
                { "lng": -71.4059116, "lat": -32.6326762 },
                { "lng": -71.4054534, "lat": -32.6331342 },
                { "lng": -71.4053118, "lat": -32.633763 },
                { "lng": -71.4053483, "lat": -32.6344081 },
                { "lng": -71.4061508, "lat": -32.6348056 },
                { "lng": -71.406094, "lat": -32.6351697 },
                { "lng": -71.4057893, "lat": -32.6356767 },
                { "lng": -71.4052829, "lat": -32.6363054 },
                { "lng": -71.4045318, "lat": -32.6367771 },
                { "lng": -71.4036843, "lat": -32.6368756 },
                { "lng": -71.4022841, "lat": -32.6371937 },
                { "lng": -71.4010332, "lat": -32.6375035 },
                { "lng": -71.3998659, "lat": -32.6378279 },
                { "lng": -71.3995354, "lat": -32.6380483 },
                { "lng": -71.3995783, "lat": -32.63851 },
                { "lng": -71.3999603, "lat": -32.639221 },
                { "lng": -71.4001738, "lat": -32.6399212 },
                { "lng": -71.4003336, "lat": -32.6407379 },
                { "lng": -71.4004259, "lat": -32.6414923 },
                { "lng": -71.4001652, "lat": -32.6420344 },
                { "lng": -71.3996277, "lat": -32.6421689 },
                { "lng": -71.3991749, "lat": -32.6419287 },
                { "lng": -71.3989603, "lat": -32.6411707 },
                { "lng": -71.3986127, "lat": -32.6405193 },
                { "lng": -71.398308, "lat": -32.6402392 },
                { "lng": -71.3978714, "lat": -32.640269 },
                { "lng": -71.3975474, "lat": -32.6404823 },
                { "lng": -71.3975034, "lat": -32.6411382 },
                { "lng": -71.3975581, "lat": -32.6416902 },
                { "lng": -71.39735, "lat": -32.6421084 },
                { "lng": -71.3967513, "lat": -32.6422828 },
                { "lng": -71.3956323, "lat": -32.6424084 },
                { "lng": -71.3941592, "lat": -32.6427047 },
                { "lng": -71.3931442, "lat": -32.6427743 },
                { "lng": -71.3921486, "lat": -32.6433407 },
                { "lng": -71.3912957, "lat": -32.6437933 },
                { "lng": -71.3901412, "lat": -32.6439677 },
                { "lng": -71.3889428, "lat": -32.6441131 },
                { "lng": -71.3886478, "lat": -32.6442938 },
                { "lng": -71.3890265, "lat": -32.6447157 },
                { "lng": -71.3897271, "lat": -32.6448955 },
                { "lng": -71.3901509, "lat": -32.6453327 },
                { "lng": -71.3908601, "lat": -32.645507 },
                { "lng": -71.3914233, "lat": -32.6458449 },
                { "lng": -71.3913826, "lat": -32.6461819 },
                { "lng": -71.3907356, "lat": -32.6464963 },
                { "lng": -71.3899943, "lat": -32.646882 },
                { "lng": -71.3890898, "lat": -32.646948 },
                { "lng": -71.3882036, "lat": -32.6469733 },
                { "lng": -71.3872906, "lat": -32.6470473 },
                { "lng": -71.3861941, "lat": -32.6464827 },
                { "lng": -71.3848852, "lat": -32.6466995 },
                { "lng": -71.3839475, "lat": -32.6473256 },
                { "lng": -71.3843155, "lat": -32.648341 },
                { "lng": -71.3840955, "lat": -32.649388 },
                { "lng": -71.3836857, "lat": -32.6500429 },
                { "lng": -71.3835896, "lat": -32.6502537 },
                { "lng": -71.3836126, "lat": -32.6504377 },
                { "lng": -71.3836126, "lat": -32.6505349 },
                { "lng": -71.3835975, "lat": -32.6506425 },
                { "lng": -71.3834987, "lat": -32.6507111 },
                { "lng": -71.3832692, "lat": -32.6506628 },
                { "lng": -71.3831289, "lat": -32.6506038 },
                { "lng": -71.3829025, "lat": -32.6505163 },
                { "lng": -71.3827998, "lat": -32.6503767 },
                { "lng": -71.3827998, "lat": -32.6502179 },
                { "lng": -71.382865, "lat": -32.6499798 },
                { "lng": -71.3828461, "lat": -32.6497409 },
                { "lng": -71.3823413, "lat": -32.6497172 },
                { "lng": -71.3821271, "lat": -32.6497473 },
                { "lng": -71.3819649, "lat": -32.64979 },
                { "lng": -71.3818501, "lat": -32.64979 },
                { "lng": -71.3817844, "lat": -32.6497346 },
                { "lng": -71.3816457, "lat": -32.6494427 },
                { "lng": -71.3815939, "lat": -32.6492859 },
                { "lng": -71.3815419, "lat": -32.6491218 },
                { "lng": -71.3813907, "lat": -32.6490084 },
                { "lng": -71.3811561, "lat": -32.6489852 },
                { "lng": -71.3809558, "lat": -32.6490077 },
                { "lng": -71.380745, "lat": -32.6491142 },
                { "lng": -71.3806048, "lat": -32.6493394 },
                { "lng": -71.3805537, "lat": -32.6495116 },
                { "lng": -71.3805763, "lat": -32.6497499 },
                { "lng": -71.3805763, "lat": -32.6499431 },
                { "lng": -71.3806058, "lat": -32.6501084 },
                { "lng": -71.3805239, "lat": -32.6503307 },
                { "lng": -71.3804198, "lat": -32.6504423 },
                { "lng": -71.3803172, "lat": -32.6505502 },
                { "lng": -71.3802269, "lat": -32.650883 },
                { "lng": -71.3801619, "lat": -32.6510358 },
                { "lng": -71.3802818, "lat": -32.651349 },
                { "lng": -71.3803832, "lat": -32.651605 },
                { "lng": -71.3803832, "lat": -32.6517055 },
                { "lng": -71.3803251, "lat": -32.6519583 },
                { "lng": -71.3802188, "lat": -32.6521048 },
                { "lng": -71.380064, "lat": -32.6523112 },
                { "lng": -71.3801427, "lat": -32.6525985 },
                { "lng": -71.3802091, "lat": -32.6527104 },
                { "lng": -71.3802426, "lat": -32.6528006 },
                { "lng": -71.3800108, "lat": -32.6530852 },
                { "lng": -71.3799164, "lat": -32.6532783 },
                { "lng": -71.37995, "lat": -32.653401 },
                { "lng": -71.3799422, "lat": -32.6534232 },
                { "lng": -71.3797828, "lat": -32.653487 },
                { "lng": -71.3796134, "lat": -32.6535029 },
                { "lng": -71.3793341, "lat": -32.6535141 },
                { "lng": -71.3791245, "lat": -32.653761 },
                { "lng": -71.3790749, "lat": -32.65397 },
                { "lng": -71.3790633, "lat": -32.654156 },
                { "lng": -71.3790866, "lat": -32.6543723 },
                { "lng": -71.3791759, "lat": -32.6546165 },
                { "lng": -71.3792554, "lat": -32.654846 },
                { "lng": -71.3793865, "lat": -32.6550225 },
                { "lng": -71.3798167, "lat": -32.6552418 },
                { "lng": -71.3800818, "lat": -32.6553877 },
                { "lng": -71.3802151, "lat": -32.6555079 },
                { "lng": -71.3803431, "lat": -32.6556738 },
                { "lng": -71.3804534, "lat": -32.6558932 },
                { "lng": -71.3804991, "lat": -32.6560471 },
                { "lng": -71.3804384, "lat": -32.6563963 },
                { "lng": -71.3804073, "lat": -32.656545 },
                { "lng": -71.3803128, "lat": -32.656863 },
                { "lng": -71.3801472, "lat": -32.6572813 },
                { "lng": -71.3801472, "lat": -32.6574873 },
                { "lng": -71.3801472, "lat": -32.6576904 },
                { "lng": -71.380115, "lat": -32.6579885 },
                { "lng": -71.380115, "lat": -32.6581909 },
                { "lng": -71.3800072, "lat": -32.6584219 },
                { "lng": -71.3799069, "lat": -32.6585908 },
                { "lng": -71.3797671, "lat": -32.6587506 },
                { "lng": -71.3795903, "lat": -32.6589081 },
                { "lng": -71.3792319, "lat": -32.6592185 },
                { "lng": -71.3788754, "lat": -32.6593483 },
                { "lng": -71.378725, "lat": -32.6593568 },
                { "lng": -71.3782066, "lat": -32.6594611 },
                { "lng": -71.3779189, "lat": -32.6595914 },
                { "lng": -71.3776394, "lat": -32.6596911 },
                { "lng": -71.3772515, "lat": -32.6598715 },
                { "lng": -71.3768415, "lat": -32.659882 },
                { "lng": -71.3767435, "lat": -32.6601434 },
                { "lng": -71.3767815, "lat": -32.6603673 },
                { "lng": -71.3768937, "lat": -32.6606087 },
                { "lng": -71.377178, "lat": -32.6608182 },
                { "lng": -71.3774082, "lat": -32.6609537 },
                { "lng": -71.3777441, "lat": -32.6610738 },
                { "lng": -71.3778333, "lat": -32.6611738 },
                { "lng": -71.3778722, "lat": -32.6615429 },
                { "lng": -71.3778534, "lat": -32.6617249 },
                { "lng": -71.3777685, "lat": -32.6618839 },
                { "lng": -71.3775615, "lat": -32.6621646 },
                { "lng": -71.3775615, "lat": -32.6623118 },
                { "lng": -71.3776788, "lat": -32.6625587 },
                { "lng": -71.3778146, "lat": -32.6627977 },
                { "lng": -71.3780126, "lat": -32.6628929 },
                { "lng": -71.3782758, "lat": -32.6629433 },
                { "lng": -71.3784731, "lat": -32.6629525 },
                { "lng": -71.3786102, "lat": -32.6629702 },
                { "lng": -71.3788362, "lat": -32.6629884 },
                { "lng": -71.378967, "lat": -32.6630041 },
                { "lng": -71.3791696, "lat": -32.6630809 },
                { "lng": -71.3793587, "lat": -32.6631406 },
                { "lng": -71.3797468, "lat": -32.6631769 },
                { "lng": -71.380141, "lat": -32.6632903 },
                { "lng": -71.3802974, "lat": -32.6634565 },
                { "lng": -71.3802974, "lat": -32.6635098 },
                { "lng": -71.3800456, "lat": -32.663844 },
                { "lng": -71.3797959, "lat": -32.6640342 },
                { "lng": -71.379771, "lat": -32.6644231 },
                { "lng": -71.3798386, "lat": -32.6646884 },
                { "lng": -71.3798795, "lat": -32.6648604 },
                { "lng": -71.3798896, "lat": -32.6650831 },
                { "lng": -71.3798776, "lat": -32.6653065 },
                { "lng": -71.380043, "lat": -32.665553 },
                { "lng": -71.3802053, "lat": -32.6656791 },
                { "lng": -71.3803595, "lat": -32.665757 },
                { "lng": -71.3807089, "lat": -32.6657149 },
                { "lng": -71.3810033, "lat": -32.6656482 },
                { "lng": -71.3813974, "lat": -32.6655047 },
                { "lng": -71.3819293, "lat": -32.6654081 },
                { "lng": -71.3822836, "lat": -32.6653643 },
                { "lng": -71.3825627, "lat": -32.6653558 },
                { "lng": -71.3826945, "lat": -32.6653797 },
                { "lng": -71.3829749, "lat": -32.6654471 },
                { "lng": -71.3830987, "lat": -32.6655122 },
                { "lng": -71.3831614, "lat": -32.665648 },
                { "lng": -71.3832637, "lat": -32.6658204 },
                { "lng": -71.3834904, "lat": -32.6661064 },
                { "lng": -71.3835136, "lat": -32.6662111 },
                { "lng": -71.3834931, "lat": -32.6662921 },
                { "lng": -71.3833647, "lat": -32.6664145 },
                { "lng": -71.3831459, "lat": -32.6665267 },
                { "lng": -71.3827864, "lat": -32.6666218 },
                { "lng": -71.3824347, "lat": -32.6667025 },
                { "lng": -71.3821474, "lat": -32.6667677 },
                { "lng": -71.3818875, "lat": -32.6668628 },
                { "lng": -71.381671, "lat": -32.6669631 },
                { "lng": -71.3814161, "lat": -32.6670661 },
                { "lng": -71.381187, "lat": -32.6671163 },
                { "lng": -71.3809643, "lat": -32.6671432 },
                { "lng": -71.3808124, "lat": -32.6671706 },
                { "lng": -71.3805753, "lat": -32.6672053 },
                { "lng": -71.3803545, "lat": -32.6672141 },
                { "lng": -71.3801357, "lat": -32.6672316 },
                { "lng": -71.3799882, "lat": -32.6672239 },
                { "lng": -71.3797641, "lat": -32.6671638 },
                { "lng": -71.379559, "lat": -32.6671159 },
                { "lng": -71.3793188, "lat": -32.6670957 },
                { "lng": -71.3790171, "lat": -32.6671363 },
                { "lng": -71.3788648, "lat": -32.6672004 },
                { "lng": -71.3786379, "lat": -32.6673311 },
                { "lng": -71.3784811, "lat": -32.6675071 },
                { "lng": -71.378463, "lat": -32.6675832 },
                { "lng": -71.3781777, "lat": -32.66777 },
                { "lng": -71.3781216, "lat": -32.6679351 },
                { "lng": -71.3781022, "lat": -32.6680578 },
                { "lng": -71.3780704, "lat": -32.6681037 },
                { "lng": -71.3780197, "lat": -32.6681073 },
                { "lng": -71.3778324, "lat": -32.6680772 },
                { "lng": -71.3776876, "lat": -32.668001 },
                { "lng": -71.3775621, "lat": -32.6679095 },
                { "lng": -71.3775203, "lat": -32.6677687 },
                { "lng": -71.3775105, "lat": -32.6676275 },
                { "lng": -71.3775105, "lat": -32.6675174 },
                { "lng": -71.3774972, "lat": -32.6673947 },
                { "lng": -71.3774035, "lat": -32.6672369 },
                { "lng": -71.3771727, "lat": -32.6671519 },
                { "lng": -71.3769435, "lat": -32.6671406 },
                { "lng": -71.3766817, "lat": -32.6672245 },
                { "lng": -71.3762612, "lat": -32.6674605 },
                { "lng": -71.3760116, "lat": -32.6678233 },
                { "lng": -71.3757638, "lat": -32.668171 },
                { "lng": -71.3756696, "lat": -32.6682423 },
                { "lng": -71.3755374, "lat": -32.6683436 },
                { "lng": -71.3753993, "lat": -32.668547 },
                { "lng": -71.3751059, "lat": -32.6688414 },
                { "lng": -71.3747625, "lat": -32.6695639 },
                { "lng": -71.3746982, "lat": -32.6699613 },
                { "lng": -71.3748288, "lat": -32.6703492 },
                { "lng": -71.3751952, "lat": -32.6707244 },
                { "lng": -71.3752189, "lat": -32.671151 },
                { "lng": -71.3750826, "lat": -32.6715098 },
                { "lng": -71.3747584, "lat": -32.6718735 },
                { "lng": -71.3745115, "lat": -32.6720706 },
                { "lng": -71.3742631, "lat": -32.6724641 },
                { "lng": -71.374208, "lat": -32.6729503 },
                { "lng": -71.3740202, "lat": -32.67346 },
                { "lng": -71.3732983, "lat": -32.6739104 },
                { "lng": -71.3726246, "lat": -32.6742477 },
                { "lng": -71.3720846, "lat": -32.6744381 },
                { "lng": -71.3717376, "lat": -32.674795 },
                { "lng": -71.3715793, "lat": -32.6756042 },
                { "lng": -71.3711647, "lat": -32.676122 },
                { "lng": -71.3703611, "lat": -32.6764299 },
                { "lng": -71.3695964, "lat": -32.6764282 },
                { "lng": -71.3687635, "lat": -32.6763432 },
                { "lng": -71.3677906, "lat": -32.6764676 },
                { "lng": -71.3663709, "lat": -32.6765413 },
                { "lng": -71.3653052, "lat": -32.6764319 },
                { "lng": -71.3639296, "lat": -32.6767355 },
                { "lng": -71.3631578, "lat": -32.6767013 },
                { "lng": -71.3625408, "lat": -32.676458 },
                { "lng": -71.3621418, "lat": -32.6764041 },
                { "lng": -71.361638, "lat": -32.6765759 },
                { "lng": -71.3611343, "lat": -32.6769107 },
                { "lng": -71.3606824, "lat": -32.677417 },
                { "lng": -71.3603222, "lat": -32.6777408 },
                { "lng": -71.3600438, "lat": -32.6780001 },
                { "lng": -71.3597629, "lat": -32.6783978 },
                { "lng": -71.3596201, "lat": -32.6786839 },
                { "lng": -71.3593678, "lat": -32.6787444 },
                { "lng": -71.3591393, "lat": -32.6787447 },
                { "lng": -71.3589619, "lat": -32.6785139 },
                { "lng": -71.3587292, "lat": -32.6784692 },
                { "lng": -71.3583743, "lat": -32.6786591 },
                { "lng": -71.3581931, "lat": -32.679121 },
                { "lng": -71.358025, "lat": -32.6794893 },
                { "lng": -71.3577355, "lat": -32.6800111 },
                { "lng": -71.3574662, "lat": -32.6806383 },
                { "lng": -71.3570595, "lat": -32.6811654 },
                { "lng": -71.3565865, "lat": -32.681609 },
                { "lng": -71.3561541, "lat": -32.6817594 },
                { "lng": -71.3555947, "lat": -32.681724 },
                { "lng": -71.3551824, "lat": -32.6816498 },
                { "lng": -71.3549159, "lat": -32.681759 },
                { "lng": -71.3546059, "lat": -32.6819904 },
                { "lng": -71.3541687, "lat": -32.6821474 },
                { "lng": -71.3537337, "lat": -32.6821431 },
                { "lng": -71.353622, "lat": -32.682002 },
                { "lng": -71.3536872, "lat": -32.6816611 },
                { "lng": -71.3538935, "lat": -32.6812674 },
                { "lng": -71.3539083, "lat": -32.6809404 },
                { "lng": -71.3536836, "lat": -32.6806513 },
                { "lng": -71.3533999, "lat": -32.6805021 },
                { "lng": -71.3530112, "lat": -32.6805483 },
                { "lng": -71.3525817, "lat": -32.680814 },
                { "lng": -71.3521454, "lat": -32.6810218 },
                { "lng": -71.3517497, "lat": -32.6813392 },
                { "lng": -71.3514069, "lat": -32.6816503 },
                { "lng": -71.3509592, "lat": -32.6817336 },
                { "lng": -71.3504505, "lat": -32.6816582 },
                { "lng": -71.3500189, "lat": -32.6817774 },
                { "lng": -71.349682, "lat": -32.6820613 },
                { "lng": -71.3492923, "lat": -32.6824781 },
                { "lng": -71.3490992, "lat": -32.6827851 },
                { "lng": -71.3484554, "lat": -32.6833811 },
                { "lng": -71.3478975, "lat": -32.6835797 },
                { "lng": -71.3470392, "lat": -32.6836158 },
                { "lng": -71.3462882, "lat": -32.6834352 },
                { "lng": -71.3455372, "lat": -32.6828393 },
                { "lng": -71.344872, "lat": -32.6825142 },
                { "lng": -71.3439279, "lat": -32.6816473 },
                { "lng": -71.3432198, "lat": -32.6811416 },
                { "lng": -71.3426189, "lat": -32.6804733 },
                { "lng": -71.3419108, "lat": -32.6801302 },
                { "lng": -71.3412456, "lat": -32.6798772 },
                { "lng": -71.3408594, "lat": -32.6796064 },
                { "lng": -71.3406448, "lat": -32.6790646 },
                { "lng": -71.3400655, "lat": -32.6782518 },
                { "lng": -71.3393359, "lat": -32.6773849 },
                { "lng": -71.3389068, "lat": -32.6770417 },
                { "lng": -71.3383274, "lat": -32.6763554 },
                { "lng": -71.3374906, "lat": -32.675958 },
                { "lng": -71.3361602, "lat": -32.6751994 },
                { "lng": -71.3351517, "lat": -32.6744408 },
                { "lng": -71.3349586, "lat": -32.673917 },
                { "lng": -71.3346152, "lat": -32.6732126 },
                { "lng": -71.3340573, "lat": -32.6727791 },
                { "lng": -71.3327399, "lat": -32.6724174 },
                { "lng": -71.3287917, "lat": -32.6732843 },
                { "lng": -71.3250151, "lat": -32.6745848 },
                { "lng": -71.3210669, "lat": -32.6757408 },
                { "lng": -71.3184921, "lat": -32.6773302 },
                { "lng": -71.3172905, "lat": -32.6758853 },
                { "lng": -71.3160888, "lat": -32.6751628 },
                { "lng": -71.3156596, "lat": -32.6735733 },
                { "lng": -71.3145438, "lat": -32.6727063 },
                { "lng": -71.3126555, "lat": -32.6726341 },
                { "lng": -71.3111964, "lat": -32.6725619 },
                { "lng": -71.3091364, "lat": -32.6724174 },
                { "lng": -71.306819, "lat": -32.6706833 },
                { "lng": -71.3038149, "lat": -32.6687325 },
                { "lng": -71.3019267, "lat": -32.6670707 },
                { "lng": -71.3004675, "lat": -32.6656979 },
                { "lng": -71.2976351, "lat": -32.6652643 },
                { "lng": -71.295146, "lat": -32.664036 },
                { "lng": -71.2941161, "lat": -32.662085 },
                { "lng": -71.2933436, "lat": -32.6584721 },
                { "lng": -71.2928286, "lat": -32.6552925 },
                { "lng": -71.2906828, "lat": -32.6524019 },
                { "lng": -71.2890521, "lat": -32.6518238 },
                { "lng": -71.2866488, "lat": -32.6512457 },
                { "lng": -71.2837306, "lat": -32.6508121 },
                { "lng": -71.2814131, "lat": -32.6493667 },
                { "lng": -71.2792674, "lat": -32.6479936 },
                { "lng": -71.278409, "lat": -32.6470541 },
                { "lng": -71.2789689, "lat": -32.6459424 },
                { "lng": -71.2796555, "lat": -32.6430515 },
                { "lng": -71.2796555, "lat": -32.6404496 },
                { "lng": -71.2803421, "lat": -32.6385704 },
                { "lng": -71.2784539, "lat": -32.6364021 },
                { "lng": -71.2775588, "lat": -32.6354131 },
                { "lng": -71.2747095, "lat": -32.633529 },
                { "lng": -71.2724457, "lat": -32.6332218 },
                { "lng": -71.2691842, "lat": -32.6342337 },
                { "lng": -71.2660943, "lat": -32.6345228 },
                { "lng": -71.2630043, "lat": -32.6335109 },
                { "lng": -71.2606011, "lat": -32.6311979 },
                { "lng": -71.2559662, "lat": -32.6300413 },
                { "lng": -71.2523613, "lat": -32.6297522 },
                { "lng": -71.2487565, "lat": -32.6283065 },
                { "lng": -71.2475548, "lat": -32.6264271 },
                { "lng": -71.2453232, "lat": -32.6249813 },
                { "lng": -71.2441216, "lat": -32.6225235 },
                { "lng": -71.2422333, "lat": -32.619921 },
                { "lng": -71.2422333, "lat": -32.6183306 },
                { "lng": -71.2425766, "lat": -32.6167401 },
                { "lng": -71.2425766, "lat": -32.6141374 },
                { "lng": -71.2351952, "lat": -32.605606 },
                { "lng": -71.2331353, "lat": -32.6044491 },
                { "lng": -71.230217, "lat": -32.6043045 },
                { "lng": -71.2269555, "lat": -32.6044491 },
                { "lng": -71.2247239, "lat": -32.6044491 },
                { "lng": -71.2192307, "lat": -32.6032923 },
                { "lng": -71.2152825, "lat": -32.6032923 },
                { "lng": -71.2097893, "lat": -32.6044491 },
                { "lng": -71.2054978, "lat": -32.6047384 },
                { "lng": -71.2015496, "lat": -32.6040153 },
                { "lng": -71.2024079, "lat": -32.6006892 },
                { "lng": -71.2034379, "lat": -32.5979415 },
                { "lng": -71.2032662, "lat": -32.5960614 },
                { "lng": -71.2039528, "lat": -32.5940367 },
                { "lng": -71.2068711, "lat": -32.5921565 },
                { "lng": -71.2082444, "lat": -32.5904209 },
                { "lng": -71.2082444, "lat": -32.5868051 },
                { "lng": -71.2115059, "lat": -32.5833337 },
                { "lng": -71.2139092, "lat": -32.5792836 },
                { "lng": -71.2147675, "lat": -32.5756673 },
                { "lng": -71.2173424, "lat": -32.5724848 },
                { "lng": -71.219574, "lat": -32.5678555 },
                { "lng": -71.2226639, "lat": -32.5645281 },
                { "lng": -71.2255822, "lat": -32.5606218 },
                { "lng": -71.2264405, "lat": -32.5583069 },
                { "lng": -71.2281571, "lat": -32.5572941 },
                { "lng": -71.229702, "lat": -32.5557025 },
                { "lng": -71.2314187, "lat": -32.5536769 },
                { "lng": -71.2334786, "lat": -32.55223 },
                { "lng": -71.2369118, "lat": -32.5491913 },
                { "lng": -71.2394867, "lat": -32.5458632 },
                { "lng": -71.24189, "lat": -32.5436926 },
                { "lng": -71.2460099, "lat": -32.5392066 },
                { "lng": -71.2492714, "lat": -32.5354439 },
                { "lng": -71.2506139, "lat": -32.5293006 },
                { "lng": -71.2884102, "lat": -32.5148912 },
                { "lng": -71.2925301, "lat": -32.5128647 },
                { "lng": -71.2933884, "lat": -32.5104038 },
                { "lng": -71.2913285, "lat": -32.5070742 },
                { "lng": -71.2909852, "lat": -32.505337 },
                { "lng": -71.2937317, "lat": -32.5046131 },
                { "lng": -71.2964783, "lat": -32.5022968 },
                { "lng": -71.2980233, "lat": -32.4998356 },
                { "lng": -71.3008742, "lat": -32.4980532 },
                { "lng": -71.3036881, "lat": -32.4983878 },
                { "lng": -71.3093529, "lat": -32.4988221 },
                { "lng": -71.3127861, "lat": -32.4986774 },
                { "lng": -71.316906, "lat": -32.4996908 },
                { "lng": -71.3194809, "lat": -32.5002699 },
                { "lng": -71.3194809, "lat": -32.5025863 },
                { "lng": -71.3206826, "lat": -32.5056265 },
                { "lng": -71.3241158, "lat": -32.5073637 },
                { "lng": -71.3263474, "lat": -32.5073637 },
                { "lng": -71.3321839, "lat": -32.5082323 },
                { "lng": -71.3364754, "lat": -32.5104038 },
                { "lng": -71.339222, "lat": -32.510838 },
                { "lng": -71.3431702, "lat": -32.5109828 },
                { "lng": -71.3466034, "lat": -32.5132989 },
                { "lng": -71.3507233, "lat": -32.5143122 },
                { "lng": -71.3532982, "lat": -32.5153255 },
                { "lng": -71.3567315, "lat": -32.5174968 },
                { "lng": -71.359478, "lat": -32.51851 },
                { "lng": -71.3635979, "lat": -32.5203917 },
                { "lng": -71.3651429, "lat": -32.5224181 },
                { "lng": -71.3675461, "lat": -32.526905 },
                { "lng": -71.3692627, "lat": -32.5286418 },
                { "lng": -71.372181, "lat": -32.5313917 },
                { "lng": -71.3718377, "lat": -32.5342862 },
                { "lng": -71.3714943, "lat": -32.5381936 },
                { "lng": -71.3713227, "lat": -32.5400749 },
                { "lng": -71.3733826, "lat": -32.5422455 },
                { "lng": -71.3764725, "lat": -32.5431138 },
                { "lng": -71.3785325, "lat": -32.5455738 },
                { "lng": -71.3805924, "lat": -32.5477443 },
                { "lng": -71.3836823, "lat": -32.5499148 },
                { "lng": -71.3857422, "lat": -32.5506383 },
                { "lng": -71.3886605, "lat": -32.5513618 },
                { "lng": -71.3907204, "lat": -32.5509277 },
                { "lng": -71.394497, "lat": -32.55223 },
                { "lng": -71.3977585, "lat": -32.552664 },
                { "lng": -71.4005051, "lat": -32.5529534 },
                { "lng": -71.4034234, "lat": -32.5523746 },
                { "lng": -71.4058266, "lat": -32.5510724 },
                { "lng": -71.4080582, "lat": -32.5494807 },
                { "lng": -71.4094315, "lat": -32.5497701 },
                { "lng": -71.4121781, "lat": -32.5516512 },
                { "lng": -71.4159546, "lat": -32.5520853 },
                { "lng": -71.4200745, "lat": -32.5525193 },
                { "lng": -71.4238511, "lat": -32.5517959 },
                { "lng": -71.4260827, "lat": -32.5502042 },
                { "lng": -71.4302025, "lat": -32.5484678 },
                { "lng": -71.4332924, "lat": -32.547889 },
                { "lng": -71.4353524, "lat": -32.5462973 },
                { "lng": -71.437584, "lat": -32.543982 },
                { "lng": -71.4391289, "lat": -32.5410879 },
                { "lng": -71.4396439, "lat": -32.5402196 },
                { "lng": -71.4425621, "lat": -32.5396407 },
                { "lng": -71.446167, "lat": -32.5387724 },
                { "lng": -71.447722, "lat": -32.5382481 },
                { "lng": -71.4496003, "lat": -32.5376148 },
                { "lng": -71.4517054, "lat": -32.5370747 },
                { "lng": -71.4524427, "lat": -32.5368855 },
                { "lng": -71.4535485, "lat": -32.5366018 },
                { "lng": -71.4542351, "lat": -32.5339967 },
                { "lng": -71.4540635, "lat": -32.5313917 },
                { "lng": -71.4544068, "lat": -32.5284971 },
                { "lng": -71.4562951, "lat": -32.5251682 },
                { "lng": -71.4569817, "lat": -32.5216944 },
                { "lng": -71.4586983, "lat": -32.5195232 },
                { "lng": -71.4595566, "lat": -32.516773 },
                { "lng": -71.459385, "lat": -32.5148912 },
                { "lng": -71.4602433, "lat": -32.5124304 },
                { "lng": -71.4604149, "lat": -32.5105485 },
                { "lng": -71.4604149, "lat": -32.5066399 },
                { "lng": -71.4609299, "lat": -32.5035997 },
                { "lng": -71.4612732, "lat": -32.5025863 },
                { "lng": -71.4631269, "lat": -32.5003358 },
                { "lng": -71.463431, "lat": -32.5002539 },
                { "lng": -71.4637953, "lat": -32.5005369 },
                { "lng": -71.464354, "lat": -32.5002326 },
                { "lng": -71.4640974, "lat": -32.4998367 },
                { "lng": -71.4641935, "lat": -32.4996705 },
                { "lng": -71.4640684, "lat": -32.4994598 },
                { "lng": -71.4640587, "lat": -32.4991113 },
                { "lng": -71.4641883, "lat": -32.4987951 },
                { "lng": -71.464294, "lat": -32.4987748 },
                { "lng": -71.4644765, "lat": -32.4984141 },
                { "lng": -71.4645461, "lat": -32.4981419 },
                { "lng": -71.4646373, "lat": -32.4980973 },
                { "lng": -71.4646421, "lat": -32.4980041 },
                { "lng": -71.4648295, "lat": -32.4979473 },
                { "lng": -71.4651915, "lat": -32.4980462 },
                { "lng": -71.4653885, "lat": -32.4979975 },
                { "lng": -71.4654652, "lat": -32.4975111 },
                { "lng": -71.4657389, "lat": -32.4971422 },
                { "lng": -71.4658013, "lat": -32.4968261 },
                { "lng": -71.4658829, "lat": -32.4967248 },
                { "lng": -71.4659886, "lat": -32.4967369 },
                { "lng": -71.4662049, "lat": -32.4968827 },
                { "lng": -71.4662337, "lat": -32.4967206 },
                { "lng": -71.4661904, "lat": -32.4965909 },
                { "lng": -71.4662759, "lat": -32.496493 },
                { "lng": -71.4664249, "lat": -32.4964281 },
                { "lng": -71.466867, "lat": -32.4965901 },
                { "lng": -71.4669632, "lat": -32.4967765 },
                { "lng": -71.466896, "lat": -32.4969629 },
                { "lng": -71.4669922, "lat": -32.4973074 },
                { "lng": -71.466925, "lat": -32.4973885 },
                { "lng": -71.4669538, "lat": -32.4974695 },
                { "lng": -71.4668818, "lat": -32.4975587 },
                { "lng": -71.4668674, "lat": -32.4976884 },
                { "lng": -71.4666513, "lat": -32.4979154 },
                { "lng": -71.4665697, "lat": -32.4982904 },
                { "lng": -71.4663055, "lat": -32.4982985 },
                { "lng": -71.4662623, "lat": -32.4984647 },
                { "lng": -71.4660893, "lat": -32.4985296 },
                { "lng": -71.4660798, "lat": -32.498793 },
                { "lng": -71.4659741, "lat": -32.4988214 },
                { "lng": -71.4658877, "lat": -32.4989795 },
                { "lng": -71.4656811, "lat": -32.4990322 },
                { "lng": -71.4656667, "lat": -32.4990768 },
                { "lng": -71.4658637, "lat": -32.4992146 },
                { "lng": -71.4658301, "lat": -32.4992916 },
                { "lng": -71.4656668, "lat": -32.4993524 },
                { "lng": -71.4658735, "lat": -32.4995996 },
                { "lng": -71.4658497, "lat": -32.5001021 },
                { "lng": -71.4660323, "lat": -32.5002523 },
                { "lng": -71.4657345, "lat": -32.5004712 },
                { "lng": -71.4658499, "lat": -32.5005968 },
                { "lng": -71.4660134, "lat": -32.500998 },
                { "lng": -71.4660663, "lat": -32.5012168 },
                { "lng": -71.4659703, "lat": -32.5013911 },
                { "lng": -71.4661289, "lat": -32.5014437 },
                { "lng": -71.4662586, "lat": -32.5013748 },
                { "lng": -71.4662394, "lat": -32.5013262 },
                { "lng": -71.4663018, "lat": -32.5012613 },
                { "lng": -71.4664027, "lat": -32.5013018 },
                { "lng": -71.4663355, "lat": -32.5014194 },
                { "lng": -71.4664366, "lat": -32.5017838 },
                { "lng": -71.46623, "lat": -32.5020189 },
                { "lng": -71.4662679, "lat": -32.5021269 },
                { "lng": -71.4663993, "lat": -32.5022074 },
                { "lng": -71.4667633, "lat": -32.5021213 },
                { "lng": -71.466999, "lat": -32.5024443 },
                { "lng": -71.4671433, "lat": -32.502655 },
                { "lng": -71.4671778, "lat": -32.5027055 },
                { "lng": -71.467263, "lat": -32.5028292 },
                { "lng": -71.4675041, "lat": -32.5028563 },
                { "lng": -71.4676632, "lat": -32.5027843 },
                { "lng": -71.4675981, "lat": -32.5025591 },
                { "lng": -71.4676775, "lat": -32.5024637 },
                { "lng": -71.4677335, "lat": -32.5026548 },
                { "lng": -71.467868, "lat": -32.5025778 },
                { "lng": -71.4677862, "lat": -32.5023022 },
                { "lng": -71.4679928, "lat": -32.5021117 },
                { "lng": -71.4682571, "lat": -32.5021197 },
                { "lng": -71.4682283, "lat": -32.5021926 },
                { "lng": -71.4680889, "lat": -32.5022616 },
                { "lng": -71.4680746, "lat": -32.5024196 },
                { "lng": -71.4680128, "lat": -32.5025767 },
                { "lng": -71.468108, "lat": -32.5024989 },
                { "lng": -71.4684235, "lat": -32.5021339 },
                { "lng": -71.4686518, "lat": -32.5022895 },
                { "lng": -71.4688098, "lat": -32.5023951 },
                { "lng": -71.4688291, "lat": -32.5025086 },
                { "lng": -71.4686658, "lat": -32.5026505 },
                { "lng": -71.4685409, "lat": -32.5029464 },
                { "lng": -71.4680415, "lat": -32.5036844 },
                { "lng": -71.4681328, "lat": -32.5036803 },
                { "lng": -71.4682865, "lat": -32.5034816 },
                { "lng": -71.4684354, "lat": -32.5034127 },
                { "lng": -71.4685266, "lat": -32.5032263 },
                { "lng": -71.4686371, "lat": -32.5031492 },
                { "lng": -71.468642, "lat": -32.5032141 },
                { "lng": -71.4687717, "lat": -32.5032951 },
                { "lng": -71.4688535, "lat": -32.5034774 },
                { "lng": -71.4690585, "lat": -32.5035136 },
                { "lng": -71.4692266, "lat": -32.5036814 },
                { "lng": -71.4698375, "lat": -32.503718 },
                { "lng": -71.4701458, "lat": -32.5040029 },
                { "lng": -71.470461, "lat": -32.5041117 },
                { "lng": -71.470586, "lat": -32.5041928 },
                { "lng": -71.4706918, "lat": -32.5045048 },
                { "lng": -71.4707928, "lat": -32.5045858 },
                { "lng": -71.4709177, "lat": -32.5045371 },
                { "lng": -71.4711965, "lat": -32.5048694 },
                { "lng": -71.4712111, "lat": -32.5051044 },
                { "lng": -71.4711294, "lat": -32.5051612 },
                { "lng": -71.470999, "lat": -32.5049914 },
                { "lng": -71.4705123, "lat": -32.5046816 },
                { "lng": -71.4702519, "lat": -32.5046821 },
                { "lng": -71.4704021, "lat": -32.5048543 },
                { "lng": -71.4709678, "lat": -32.5051405 },
                { "lng": -71.4713027, "lat": -32.5053948 },
                { "lng": -71.4712353, "lat": -32.5056518 },
                { "lng": -71.4710959, "lat": -32.5056527 },
                { "lng": -71.4710961, "lat": -32.5059112 },
                { "lng": -71.4708313, "lat": -32.5059498 },
                { "lng": -71.4707336, "lat": -32.5060636 },
                { "lng": -71.4706294, "lat": -32.5061331 },
                { "lng": -71.4712277, "lat": -32.506126 },
                { "lng": -71.4706926, "lat": -32.5063449 },
                { "lng": -71.4707743, "lat": -32.5063976 },
                { "lng": -71.4711155, "lat": -32.5063408 },
                { "lng": -71.4712116, "lat": -32.5064096 },
                { "lng": -71.4710339, "lat": -32.5066609 },
                { "lng": -71.4706927, "lat": -32.5066286 },
                { "lng": -71.4707745, "lat": -32.5067259 },
                { "lng": -71.4707505, "lat": -32.5067786 },
                { "lng": -71.470861, "lat": -32.5068474 },
                { "lng": -71.470717, "lat": -32.5071068 },
                { "lng": -71.4708755, "lat": -32.5070136 },
                { "lng": -71.4709717, "lat": -32.5071149 },
                { "lng": -71.4716542, "lat": -32.5073984 },
                { "lng": -71.4716975, "lat": -32.5074713 },
                { "lng": -71.4718417, "lat": -32.5075118 },
                { "lng": -71.4719714, "lat": -32.507455 },
                { "lng": -71.4721108, "lat": -32.5076252 },
                { "lng": -71.4723368, "lat": -32.5077264 },
                { "lng": -71.4726284, "lat": -32.5081529 },
                { "lng": -71.4726236, "lat": -32.508234 },
                { "lng": -71.4724795, "lat": -32.5084042 },
                { "lng": -71.4725949, "lat": -32.5084285 },
                { "lng": -71.4726526, "lat": -32.508542 },
                { "lng": -71.4726671, "lat": -32.5088419 },
                { "lng": -71.472499, "lat": -32.5088743 },
                { "lng": -71.4723019, "lat": -32.5087933 },
                { "lng": -71.4721433, "lat": -32.5088907 },
                { "lng": -71.4722107, "lat": -32.5090568 },
                { "lng": -71.4723116, "lat": -32.5090851 },
                { "lng": -71.4723886, "lat": -32.5092067 },
                { "lng": -71.4723841, "lat": -32.509993 },
                { "lng": -71.4722881, "lat": -32.5101551 },
                { "lng": -71.4720911, "lat": -32.5101916 },
                { "lng": -71.4725572, "lat": -32.5102401 },
                { "lng": -71.4725813, "lat": -32.5103455 },
                { "lng": -71.4727447, "lat": -32.5103657 },
                { "lng": -71.4727784, "lat": -32.5104832 },
                { "lng": -71.472692, "lat": -32.5105967 },
                { "lng": -71.4728169, "lat": -32.5106331 },
                { "lng": -71.4728699, "lat": -32.5107466 },
                { "lng": -71.4729085, "lat": -32.5110627 },
                { "lng": -71.4731439, "lat": -32.5110505 },
                { "lng": -71.473269, "lat": -32.5112044 },
                { "lng": -71.473514, "lat": -32.5111922 },
                { "lng": -71.4735814, "lat": -32.511334 },
                { "lng": -71.4736727, "lat": -32.5113502 },
                { "lng": -71.4738075, "lat": -32.5117716 },
                { "lng": -71.4739998, "lat": -32.5120309 },
                { "lng": -71.4740192, "lat": -32.5122782 },
                { "lng": -71.4741154, "lat": -32.5125172 },
                { "lng": -71.4740514, "lat": -32.5127004 },
                { "lng": -71.4741428, "lat": -32.5128706 },
                { "lng": -71.474138, "lat": -32.5130125 },
                { "lng": -71.4739459, "lat": -32.5131868 },
                { "lng": -71.4741381, "lat": -32.5131989 },
                { "lng": -71.474215, "lat": -32.5132718 },
                { "lng": -71.4744696, "lat": -32.5130488 },
                { "lng": -71.474633, "lat": -32.5130163 },
                { "lng": -71.474907, "lat": -32.5131459 },
                { "lng": -71.4750994, "lat": -32.5134133 },
                { "lng": -71.4750274, "lat": -32.5135755 },
                { "lng": -71.4750708, "lat": -32.5138186 },
                { "lng": -71.4749651, "lat": -32.513924 },
                { "lng": -71.4750853, "lat": -32.5139929 },
                { "lng": -71.4751093, "lat": -32.513924 },
                { "lng": -71.4751573, "lat": -32.513924 },
                { "lng": -71.4751527, "lat": -32.5143373 },
                { "lng": -71.4749054, "lat": -32.51484 },
                { "lng": -71.474992, "lat": -32.51499 },
                { "lng": -71.474992, "lat": -32.5151115 },
                { "lng": -71.4749536, "lat": -32.5151642 },
                { "lng": -71.4747518, "lat": -32.5152251 },
                { "lng": -71.4748817, "lat": -32.5155371 },
                { "lng": -71.4748289, "lat": -32.5157073 },
                { "lng": -71.474853, "lat": -32.5158451 },
                { "lng": -71.4749155, "lat": -32.5158978 },
                { "lng": -71.4749635, "lat": -32.5157924 },
                { "lng": -71.4750068, "lat": -32.5158086 },
                { "lng": -71.4751607, "lat": -32.5160071 },
                { "lng": -71.47518, "lat": -32.5161003 },
                { "lng": -71.4750983, "lat": -32.5162057 },
                { "lng": -71.4752186, "lat": -32.5164124 },
                { "lng": -71.4753533, "lat": -32.5168622 },
                { "lng": -71.4751565, "lat": -32.517368 },
                { "lng": -71.4755988, "lat": -32.5175705 },
                { "lng": -71.4755748, "lat": -32.5176556 },
                { "lng": -71.4756614, "lat": -32.5177164 },
                { "lng": -71.4756758, "lat": -32.5178096 },
                { "lng": -71.4755749, "lat": -32.5179028 },
                { "lng": -71.4755462, "lat": -32.5180366 },
                { "lng": -71.4755846, "lat": -32.5180731 },
                { "lng": -71.4757336, "lat": -32.5180203 },
                { "lng": -71.475777, "lat": -32.5182554 },
                { "lng": -71.4756189, "lat": -32.5192483 },
                { "lng": -71.4755036, "lat": -32.5194226 },
                { "lng": -71.4754171, "lat": -32.5193699 },
                { "lng": -71.4753978, "lat": -32.5193983 },
                { "lng": -71.4754316, "lat": -32.5196212 },
                { "lng": -71.4753116, "lat": -32.5200224 },
                { "lng": -71.4749705, "lat": -32.5203468 },
                { "lng": -71.4750908, "lat": -32.5205413 },
                { "lng": -71.4751965, "lat": -32.5205574 },
                { "lng": -71.4754034, "lat": -32.5209829 },
                { "lng": -71.4755044, "lat": -32.5210396 },
                { "lng": -71.4758168, "lat": -32.5210679 },
                { "lng": -71.4759884, "lat": -32.5211626 },
                { "lng": -71.4760221, "lat": -32.521353 },
                { "lng": -71.4761807, "lat": -32.5213692 },
                { "lng": -71.4763154, "lat": -32.521511 },
                { "lng": -71.476325, "lat": -32.5215475 },
                { "lng": -71.4760847, "lat": -32.5215759 },
                { "lng": -71.4760799, "lat": -32.5216164 },
                { "lng": -71.4762964, "lat": -32.5219608 },
                { "lng": -71.4762916, "lat": -32.5220946 },
                { "lng": -71.4761427, "lat": -32.5222811 },
                { "lng": -71.4759409, "lat": -32.5223419 },
                { "lng": -71.4758785, "lat": -32.5225365 },
                { "lng": -71.4764888, "lat": -32.522285 },
                { "lng": -71.4765321, "lat": -32.5223458 },
                { "lng": -71.4764553, "lat": -32.5225241 },
                { "lng": -71.476489, "lat": -32.5226457 },
                { "lng": -71.4766908, "lat": -32.5226335 },
                { "lng": -71.4767438, "lat": -32.5227267 },
                { "lng": -71.4766521, "lat": -32.522805 },
                { "lng": -71.476681, "lat": -32.5229145 },
                { "lng": -71.4765226, "lat": -32.5233765 },
                { "lng": -71.4764939, "lat": -32.5236805 },
                { "lng": -71.4764074, "lat": -32.5238345 },
                { "lng": -71.4762633, "lat": -32.5239237 },
                { "lng": -71.4762057, "lat": -32.5240859 },
                { "lng": -71.4762491, "lat": -32.5243695 },
                { "lng": -71.4759703, "lat": -32.5244385 },
                { "lng": -71.4759223, "lat": -32.5245561 },
                { "lng": -71.4757686, "lat": -32.5246858 },
                { "lng": -71.4756533, "lat": -32.5247467 },
                { "lng": -71.4755042, "lat": -32.5247427 },
                { "lng": -71.4753986, "lat": -32.5248927 },
                { "lng": -71.4752208, "lat": -32.5250021 },
                { "lng": -71.4749996, "lat": -32.5249617 },
                { "lng": -71.4749501, "lat": -32.5248075 },
                { "lng": -71.4750701, "lat": -32.5244873 },
                { "lng": -71.4750124, "lat": -32.5244386 },
                { "lng": -71.4748971, "lat": -32.5244833 },
                { "lng": -71.4746714, "lat": -32.524917 },
                { "lng": -71.4742534, "lat": -32.5252495 },
                { "lng": -71.4739793, "lat": -32.5250631 },
                { "lng": -71.4738879, "lat": -32.5248362 },
                { "lng": -71.4737726, "lat": -32.5250389 },
                { "lng": -71.4736621, "lat": -32.5250592 },
                { "lng": -71.4736855, "lat": -32.5254238 },
                { "lng": -71.4735175, "lat": -32.5257521 },
                { "lng": -71.4732388, "lat": -32.5260116 },
                { "lng": -71.4730514, "lat": -32.526036 },
                { "lng": -71.4729649, "lat": -32.5260968 },
                { "lng": -71.4728785, "lat": -32.5264615 },
                { "lng": -71.4727584, "lat": -32.5265913 },
                { "lng": -71.4726046, "lat": -32.5266278 },
                { "lng": -71.4722921, "lat": -32.5264698 },
                { "lng": -71.4722489, "lat": -32.5266279 },
                { "lng": -71.4721384, "lat": -32.5266685 },
                { "lng": -71.4719654, "lat": -32.5268712 },
                { "lng": -71.4718597, "lat": -32.5268955 },
                { "lng": -71.4718597, "lat": -32.5269482 },
                { "lng": -71.471735, "lat": -32.5269161 },
                { "lng": -71.4717639, "lat": -32.527058 },
                { "lng": -71.4718456, "lat": -32.5270944 },
                { "lng": -71.4718601, "lat": -32.5273538 },
                { "lng": -71.4719755, "lat": -32.5273821 },
                { "lng": -71.4720236, "lat": -32.5275118 },
                { "lng": -71.4719708, "lat": -32.5276699 },
                { "lng": -71.4720766, "lat": -32.5277022 },
                { "lng": -71.4719662, "lat": -32.5279738 },
                { "lng": -71.4717787, "lat": -32.527986 },
                { "lng": -71.4717354, "lat": -32.5278199 },
                { "lng": -71.4716009, "lat": -32.5281036 },
                { "lng": -71.4715239, "lat": -32.5279375 },
                { "lng": -71.4714952, "lat": -32.5281604 },
                { "lng": -71.4712741, "lat": -32.5282131 },
                { "lng": -71.4712934, "lat": -32.5284239 },
                { "lng": -71.4711926, "lat": -32.5286549 },
                { "lng": -71.4709446, "lat": -32.5287815 },
                { "lng": -71.4709062, "lat": -32.5289152 },
                { "lng": -71.4707476, "lat": -32.5289234 },
                { "lng": -71.4704689, "lat": -32.5290775 },
                { "lng": -71.4704352, "lat": -32.5290491 },
                { "lng": -71.4704832, "lat": -32.5288627 },
                { "lng": -71.4704255, "lat": -32.5287168 },
                { "lng": -71.4703678, "lat": -32.5286925 },
                { "lng": -71.4702429, "lat": -32.5288709 },
                { "lng": -71.4701756, "lat": -32.528879 },
                { "lng": -71.4701419, "lat": -32.5287047 },
                { "lng": -71.4700938, "lat": -32.5286804 },
                { "lng": -71.4701035, "lat": -32.5288952 },
                { "lng": -71.4700314, "lat": -32.5290128 },
                { "lng": -71.4699305, "lat": -32.5290452 },
                { "lng": -71.4698631, "lat": -32.5288588 },
                { "lng": -71.4699209, "lat": -32.5290857 },
                { "lng": -71.469772, "lat": -32.5293614 },
                { "lng": -71.4696423, "lat": -32.5294303 },
                { "lng": -71.4695509, "lat": -32.529325 },
                { "lng": -71.4693875, "lat": -32.5294344 },
                { "lng": -71.469325, "lat": -32.5293494 },
                { "lng": -71.4689646, "lat": -32.5297669 },
                { "lng": -71.4688206, "lat": -32.5302573 },
                { "lng": -71.4687245, "lat": -32.5303749 },
                { "lng": -71.4683399, "lat": -32.5304582 },
                { "lng": -71.4680661, "lat": -32.5307095 },
                { "lng": -71.4680324, "lat": -32.530819 },
                { "lng": -71.4683405, "lat": -32.531824 },
                { "lng": -71.4684658, "lat": -32.532626 },
                { "lng": -71.4687591, "lat": -32.5327029 },
                { "lng": -71.4688937, "lat": -32.5328649 },
                { "lng": -71.4686343, "lat": -32.5331568 },
                { "lng": -71.4688651, "lat": -32.5332824 },
                { "lng": -71.4689852, "lat": -32.5332945 },
                { "lng": -71.4691055, "lat": -32.5334079 },
                { "lng": -71.4690959, "lat": -32.5335701 },
                { "lng": -71.4690335, "lat": -32.5336633 },
                { "lng": -71.4689854, "lat": -32.5336795 },
                { "lng": -71.4688988, "lat": -32.5335863 },
                { "lng": -71.4687909, "lat": -32.5337279 },
                { "lng": -71.468637, "lat": -32.5337645 },
                { "lng": -71.4686611, "lat": -32.5338577 },
                { "lng": -71.4687813, "lat": -32.5338941 },
                { "lng": -71.4687766, "lat": -32.5340481 },
                { "lng": -71.4690746, "lat": -32.5339467 },
                { "lng": -71.4691035, "lat": -32.5341331 },
                { "lng": -71.4689546, "lat": -32.5344412 },
                { "lng": -71.4689835, "lat": -32.5345871 },
                { "lng": -71.4692142, "lat": -32.5345505 },
                { "lng": -71.4692671, "lat": -32.5345829 },
                { "lng": -71.4693008, "lat": -32.5347896 },
                { "lng": -71.4693633, "lat": -32.5347734 },
                { "lng": -71.4696997, "lat": -32.5343842 },
                { "lng": -71.4698295, "lat": -32.5343761 },
                { "lng": -71.4699497, "lat": -32.5344936 },
                { "lng": -71.4698969, "lat": -32.5346354 },
                { "lng": -71.4700492, "lat": -32.534781 },
                { "lng": -71.4701695, "lat": -32.53502 },
                { "lng": -71.4701214, "lat": -32.5350849 },
                { "lng": -71.4700013, "lat": -32.5350728 },
                { "lng": -71.4699628, "lat": -32.5351255 },
                { "lng": -71.4700398, "lat": -32.5352794 },
                { "lng": -71.4700043, "lat": -32.5353681 },
                { "lng": -71.4699005, "lat": -32.535628 },
                { "lng": -71.4698189, "lat": -32.5357172 },
                { "lng": -71.4697035, "lat": -32.535697 },
                { "lng": -71.4695834, "lat": -32.5359118 },
                { "lng": -71.46942, "lat": -32.5359808 },
                { "lng": -71.4692806, "lat": -32.5361145 },
                { "lng": -71.4695258, "lat": -32.5361509 },
                { "lng": -71.4695451, "lat": -32.5364225 },
                { "lng": -71.4697086, "lat": -32.5363495 },
                { "lng": -71.4697759, "lat": -32.5364589 },
                { "lng": -71.4696735, "lat": -32.5368057 },
                { "lng": -71.4694236, "lat": -32.5370733 },
                { "lng": -71.4694285, "lat": -32.5371179 },
                { "lng": -71.4697602, "lat": -32.5371826 },
                { "lng": -71.4697891, "lat": -32.5372434 },
                { "lng": -71.4697939, "lat": -32.5373326 },
                { "lng": -71.4697122, "lat": -32.5374177 },
                { "lng": -71.4697652, "lat": -32.5376811 },
                { "lng": -71.4697076, "lat": -32.5378675 },
                { "lng": -71.4695778, "lat": -32.5379527 },
                { "lng": -71.4694528, "lat": -32.5379162 },
                { "lng": -71.4692077, "lat": -32.5379528 },
                { "lng": -71.4687225, "lat": -32.5387554 },
                { "lng": -71.4685494, "lat": -32.5388365 },
                { "lng": -71.4683723, "lat": -32.5390026 },
                { "lng": -71.4681368, "lat": -32.5390108 },
                { "lng": -71.4678052, "lat": -32.5392946 },
                { "lng": -71.4676995, "lat": -32.5395215 },
                { "lng": -71.4675985, "lat": -32.5395337 },
                { "lng": -71.4674638, "lat": -32.5392987 },
                { "lng": -71.4674928, "lat": -32.5396513 },
                { "lng": -71.4673968, "lat": -32.5399309 },
                { "lng": -71.4672863, "lat": -32.5400323 },
                { "lng": -71.4670363, "lat": -32.5400243 },
                { "lng": -71.4670459, "lat": -32.5401499 },
                { "lng": -71.466921, "lat": -32.5402837 },
                { "lng": -71.466873, "lat": -32.540458 },
                { "lng": -71.4669404, "lat": -32.5407133 },
                { "lng": -71.4668972, "lat": -32.5409281 },
                { "lng": -71.4665752, "lat": -32.5411774 },
                { "lng": -71.4664262, "lat": -32.5411977 },
                { "lng": -71.4663254, "lat": -32.5415301 },
                { "lng": -71.4661187, "lat": -32.5417935 },
                { "lng": -71.4661477, "lat": -32.5420853 },
                { "lng": -71.4660516, "lat": -32.5422232 },
                { "lng": -71.4660518, "lat": -32.542746 },
                { "lng": -71.4659557, "lat": -32.5427703 },
                { "lng": -71.4658501, "lat": -32.5433823 },
                { "lng": -71.4657465, "lat": -32.5436313 },
                { "lng": -71.4657882, "lat": -32.5437037 },
                { "lng": -71.4660521, "lat": -32.5436247 },
                { "lng": -71.4660571, "lat": -32.5440705 },
                { "lng": -71.4661726, "lat": -32.5442244 },
                { "lng": -71.4662783, "lat": -32.5442041 },
                { "lng": -71.4662734, "lat": -32.5439975 },
                { "lng": -71.4665715, "lat": -32.5439366 },
                { "lng": -71.4665667, "lat": -32.5440622 },
                { "lng": -71.4666918, "lat": -32.5441878 },
                { "lng": -71.4665621, "lat": -32.5446337 },
                { "lng": -71.4668651, "lat": -32.54497 },
                { "lng": -71.4670142, "lat": -32.5449415 },
                { "lng": -71.4670718, "lat": -32.5448848 },
                { "lng": -71.4673087, "lat": -32.5450742 },
                { "lng": -71.4673089, "lat": -32.5455119 },
                { "lng": -71.4671887, "lat": -32.5455241 },
                { "lng": -71.4670446, "lat": -32.5457997 },
                { "lng": -71.4668379, "lat": -32.5459497 },
                { "lng": -71.4665013, "lat": -32.5458525 },
                { "lng": -71.4665541, "lat": -32.5456418 },
                { "lng": -71.4665204, "lat": -32.5455932 },
                { "lng": -71.4661069, "lat": -32.5453623 },
                { "lng": -71.4658137, "lat": -32.5452813 },
                { "lng": -71.4655494, "lat": -32.5456623 },
                { "lng": -71.4650013, "lat": -32.5455166 },
                { "lng": -71.4648715, "lat": -32.5456787 },
                { "lng": -71.464684, "lat": -32.545695 },
                { "lng": -71.4646023, "lat": -32.5456342 },
                { "lng": -71.464559, "lat": -32.5456504 },
                { "lng": -71.4645158, "lat": -32.5457882 },
                { "lng": -71.4645831, "lat": -32.5458652 },
                { "lng": -71.4646457, "lat": -32.5461773 },
                { "lng": -71.4645593, "lat": -32.5464367 },
                { "lng": -71.4645805, "lat": -32.5466723 },
                { "lng": -71.4641432, "lat": -32.5470614 },
                { "lng": -71.4640038, "lat": -32.5471061 },
                { "lng": -71.4637489, "lat": -32.547094 },
                { "lng": -71.4637057, "lat": -32.5471305 },
                { "lng": -71.4638355, "lat": -32.5472723 },
                { "lng": -71.4633741, "lat": -32.5475723 },
                { "lng": -71.4634222, "lat": -32.5476412 },
                { "lng": -71.4632636, "lat": -32.547702 },
                { "lng": -71.4632876, "lat": -32.5478155 },
                { "lng": -71.4631723, "lat": -32.547856 },
                { "lng": -71.4631916, "lat": -32.5480222 },
                { "lng": -71.4629753, "lat": -32.5483303 },
                { "lng": -71.4627397, "lat": -32.5483789 },
                { "lng": -71.4627834, "lat": -32.5484394 },
                { "lng": -71.4625959, "lat": -32.5485124 },
                { "lng": -71.4626728, "lat": -32.5486015 },
                { "lng": -71.4627017, "lat": -32.5487515 },
                { "lng": -71.4626296, "lat": -32.5488123 },
                { "lng": -71.4625334, "lat": -32.5488396 },
                { "lng": -71.4623245, "lat": -32.5487157 },
                { "lng": -71.4622992, "lat": -32.5485613 },
                { "lng": -71.4621312, "lat": -32.5485744 },
                { "lng": -71.4621046, "lat": -32.5487776 },
                { "lng": -71.461841, "lat": -32.54889 },
                { "lng": -71.4615369, "lat": -32.5489921 },
                { "lng": -71.461277, "lat": -32.5490104 },
                { "lng": -71.4610433, "lat": -32.5490823 },
                { "lng": -71.46081, "lat": -32.5490403 },
                { "lng": -71.4607189, "lat": -32.5492257 },
                { "lng": -71.460582, "lat": -32.5495489 },
                { "lng": -71.4605231, "lat": -32.549913 },
                { "lng": -71.4605419, "lat": -32.5502589 },
                { "lng": -71.4606249, "lat": -32.5505709 },
                { "lng": -71.4608985, "lat": -32.5512243 },
                { "lng": -71.4611587, "lat": -32.5516515 },
                { "lng": -71.4614524, "lat": -32.5519862 },
                { "lng": -71.461773, "lat": -32.5522914 },
                { "lng": -71.4619875, "lat": -32.5524169 },
                { "lng": -71.4622182, "lat": -32.5524814 },
                { "lng": -71.4623863, "lat": -32.5524375 },
                { "lng": -71.4624775, "lat": -32.5525048 },
                { "lng": -71.4626667, "lat": -32.5524657 },
                { "lng": -71.4630273, "lat": -32.5524939 },
                { "lng": -71.4635047, "lat": -32.5526469 },
                { "lng": -71.4638192, "lat": -32.5528317 },
                { "lng": -71.4641969, "lat": -32.5533266 },
                { "lng": -71.4644883, "lat": -32.5533999 },
                { "lng": -71.4649239, "lat": -32.553254 },
                { "lng": -71.465089, "lat": -32.5531241 },
                { "lng": -71.4649535, "lat": -32.5529398 },
                { "lng": -71.465057, "lat": -32.5528881 },
                { "lng": -71.465262, "lat": -32.5529584 },
                { "lng": -71.4655876, "lat": -32.5528897 },
                { "lng": -71.4655176, "lat": -32.5528102 },
                { "lng": -71.4654634, "lat": -32.5527487 },
                { "lng": -71.4654843, "lat": -32.5525734 },
                { "lng": -71.4654908, "lat": -32.5525187 },
                { "lng": -71.4654402, "lat": -32.5522656 },
                { "lng": -71.4654951, "lat": -32.5521411 },
                { "lng": -71.4656539, "lat": -32.5520269 },
                { "lng": -71.4657147, "lat": -32.5519127 },
                { "lng": -71.4658139, "lat": -32.5519642 },
                { "lng": -71.4659083, "lat": -32.5518466 },
                { "lng": -71.46601, "lat": -32.5519611 },
                { "lng": -71.4660309, "lat": -32.5522451 },
                { "lng": -71.4660625, "lat": -32.5523986 },
                { "lng": -71.4661981, "lat": -32.5523487 },
                { "lng": -71.4662993, "lat": -32.5521217 },
                { "lng": -71.4663671, "lat": -32.5520394 },
                { "lng": -71.4664391, "lat": -32.552049 },
                { "lng": -71.4665015, "lat": -32.5519667 },
                { "lng": -71.4664063, "lat": -32.5518665 },
                { "lng": -71.4664836, "lat": -32.5518179 },
                { "lng": -71.4665109, "lat": -32.5518611 },
                { "lng": -71.4666786, "lat": -32.5517671 },
                { "lng": -71.4667882, "lat": -32.5517902 },
                { "lng": -71.4668181, "lat": -32.5518861 },
                { "lng": -71.4667252, "lat": -32.5519567 },
                { "lng": -71.4668764, "lat": -32.5519577 },
                { "lng": -71.4669826, "lat": -32.5520737 },
                { "lng": -71.4669618, "lat": -32.5522203 },
                { "lng": -71.4670786, "lat": -32.5522803 },
                { "lng": -71.4671621, "lat": -32.5523715 },
                { "lng": -71.4674013, "lat": -32.5524158 },
                { "lng": -71.467429, "lat": -32.5523577 },
                { "lng": -71.4675032, "lat": -32.5520794 },
                { "lng": -71.4676595, "lat": -32.5519755 },
                { "lng": -71.4678132, "lat": -32.551805 },
                { "lng": -71.4678822, "lat": -32.5516102 },
                { "lng": -71.4680508, "lat": -32.5515304 },
                { "lng": -71.468301, "lat": -32.5513841 }
              ]
        }
    ]
};

export default Zapallar;