import React from 'react';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import {
	checkSession,
	verifyAccess,
	setInitDate,
	setEndDate,
	setDateFromCalIni,
	setDateFromCalEnd,
	//setDateFormat,
} from './components/genscripts';

import DatePicker from 'react-datepicker';
//import { registerLocale, setDefaultLocale, Date } from "react-datepicker";
import es from 'date-fns/locale/es';
//registerLocale("es", es);
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'universal-cookie';
import axios from 'axios';

import error from '../pages/images/error.png';
import success from '../pages/images/success.png';

import './reportes.css';

import ReactExport from 'react-export-excel';
import {convertDateToUtc} from "./components/Utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//exportar a excel https://www.npmjs.com/package/react-export-excel -> ver ejemplos

const thispage = 'reportes';
const headTitle = 'Reportes';

class Reportes extends React.Component {
	constructor() {
		super();
		this.state = {
			startDate: new Date(),
			finDate: new Date(),
			iniciodate: setInitDate(),
			enddate: setEndDate(),
			dataTable: [],
			dataTableExport: [],
			ticketnrochange: 0,
			newticketstate: '',
			modalIsOpen: false,
			tiporeporte: 'reportegeneral',
			listaVendedores: [],
			listaConductores: [],
			rutvendedor: '',
			rutconductor: '',
			cambioestadoreport: false,
		};
		this.onChangeTimeIni = this.onChangeTimeIni.bind(this);
		this.onChangeTimeEnd = this.onChangeTimeEnd.bind(this);
		this.getGenReport = this.getGenReport.bind(this);
		this.peticionCambioEstadoModal = this.peticionCambioEstadoModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.cambiarEstadoTicket = this.cambiarEstadoTicket.bind(this);
		this.handleChangeTipoReporte = this.handleChangeTipoReporte.bind(this);
		this.getVendedores = this.getVendedores.bind(this);
		this.handleChangeSelectVendedores =
			this.handleChangeSelectVendedores.bind(this);
		this.handleChangeSelectConductores =
			this.handleChangeSelectConductores.bind(this);
		this.getReportByUser = this.getReportByUser.bind(this);
		this.getReportByConductor = this.getReportByConductor.bind(this);
	}

	componentDidMount() {
		//this.getListCounters();
		//this.getVendedores();
	}

	peticionCambioEstadoModal(ticketnro, newticketstate) {
		this.setState({
			modalIsOpen: true,
			ticketnrochange: ticketnro,
			newticketstate: newticketstate,
		});
	}

	closeModal() {
		document.getElementById('msgErrorPetAnul').innerHTML = '';
		this.setState({ modalIsOpen: false });
	}

	cambiarEstadoTicket() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketState`,
			data: JSON.stringify({
				ticketstate: this.state.newticketstate,
				idticket: this.state.ticketnrochange,
			}),
			headers: headers,
		})
			.then((response) => {
				if (parseInt(response.data) > 0) {
					this.setState({ cambioestadoreport: true });
					if (this.state.tiporeporte === 'reporteusuario') {
						this.getReportByUser();
					} else {
						this.getGenReport();
					}
				} else {
					this.setState({ cambioestadoreport: false });
				}
				document.getElementById('CambioEstadoTicketReport').style.display =
					'none';
				document.getElementById('CambioEstadoTicketReportFinal').style.display =
					'block';
				setTimeout(() => {
					this.closeModal();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleChange = (date) => {
		var iniDate = setDateFromCalIni(date);
		this.setState({
			startDate: date,
			iniciodate: iniDate,
		});
	};

	handleChangeEnd = (date) => {
		var endDate = setDateFromCalEnd(date);
		this.setState({
			finDate: date,
			enddate: endDate,
		});
	};

	onChangeTimeIni(eventoini) {
		var fechahoraini =
			document.getElementById('dateini').value.replace(/\//g, '-') +
			' ' +
			eventoini.target.value;
		this.setState({
			iniciodate: fechahoraini,
		});
	}

	onChangeTimeEnd(eventoend) {
		var fechahoraini =
			document.getElementById('dateend').value.replace(/\//g, '-') +
			' ' +
			eventoend.target.value;
		this.setState({
			enddate: fechahoraini,
		});
	}

	handleChangeTipoReporte(event) {
		this.setState({
			tiporeporte: event.target.value,
		});

		if (event.target.value === 'reporteusuario') {
			this.getVendedores();
			document.getElementById('vendedoresListContainer').style.display =
				'block';
			document.getElementById('conductoresListContainer').style.display =
				'none';
		} else if (event.target.value === 'reporteconductor') {
			//this.getDrivers();
			document.getElementById('vendedoresListContainer').style.display = 'none';
			document.getElementById('conductoresListContainer').style.display =
				'block';
		} else {
			document.getElementById('vendedoresListContainer').style.display = 'none';
			document.getElementById('conductoresListContainer').style.display =
				'none';
		}
	}

	handleChangeSelectVendedores(event) {
		this.setState({ rutvendedor: event.target.value });
	}

	handleChangeSelectConductores(event) {
		this.setState({ rutconductor: event.target.value });
	}

	getVendedores() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const ListSellers = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getListVendedores`,
			headers: headers,
		})
			.then((response) => {
				ListSellers.push(<option value="0">Selecciona un vendedor</option>);
				//console.log(response.data);
				for (const vendedor of response.data) {
					ListSellers.push(
						<option key={vendedor.idusuario} value={vendedor.rut}>
							{vendedor.nombre + ' ' + vendedor.apellido}
						</option>
					);
				}
				this.setState({ listaVendedores: ListSellers });
			})
			.catch((error) => {
				console.log('allcounters: ' + error);
			});
	}

	/* getDrivers() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const ListDrivers = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getUsuarioAndCuentasBanco`,
			headers: headers,
		})
			.then((response) => {
				ListDrivers.push(<option value="0">Selecciona un vendedor</option>);
				console.log(response.data);
				for (const conductor of response.data) {
					ListDrivers.push(
						<option key={conductor[0]} value={conductor[3]}>
							{conductor[1] + ' ' + conductor[2] + ' - ' + conductor[3]}
						</option>
					);
				}
				this.setState({ listaConductores: ListDrivers });
			})
			.catch((error) => {
				console.log('allcounters: ' + error);
			});
	} */

	getGenReport() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		let rut = cookies.get('user');
		const profileid = cookies.get('pid');
		const dataTableTickets = [];
		const dataTableTicketsExport = [];

		if (parseInt(profileid) !== 4) {
			rut = '';
		}

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getTicketsBySupervisorAndDate`,
			data: JSON.stringify({
				fechaini: this.state.iniciodate,
				fechaend: this.state.enddate,
				rutsupervisor: rut,
			}),
			headers: headers,
		})
			.then((response) => {
				for (const datoTicket of response.data) {
					const valor = datoTicket[2];

					//console.log('fecha original:', datoTicket[3]);
					const options = {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
					};
					const dateTicket = convertDateToUtc(new Date(datoTicket[3]));
					const newDateFormated_tz = dateTicket.toLocaleDateString(
						'es-CL',
						options
					);

					/* const newDateFormated_tz = datoTicket[3]
						.replace('T', ' ')
						.replace('.000+0000', ''); */

					//console.log('fecha nueva:', newDateFormated_tz);

					dataTableTickets.push(
						<tr>
							<td>{datoTicket[0]}</td>
							<td>{datoTicket[1]}</td>
							<td>{valor}</td>
							<td>{newDateFormated_tz}</td>
							<td>{datoTicket[5]}</td>
							<td>{datoTicket[4]}</td>
							<td>{datoTicket[6]}</td>
							<td>{datoTicket[7]}</td>
							<td>{datoTicket[13]}</td>
							<td>{datoTicket[8] ? 'Si' : 'No'}</td>
							<td>{datoTicket[9]}</td>
							<td>{datoTicket[10] ? 'Si' : 'No'}</td>
							<td>{datoTicket[11]}</td>
							<td>
								{datoTicket[7] !== 'ANULADO' ? (
									<button
										className="btnBoucherClass"
										onClick={() =>
											this.peticionCambioEstadoModal(datoTicket[0], 'ANULADO')
										}
									>
										Anular ticket
									</button>
								) : (
									<button
										className="btnBoucherClass"
										onClick={() =>
											this.peticionCambioEstadoModal(datoTicket[0], 'ACTIVO')
										}
									>
										Activar ticket
									</button>
								)}
							</td>
						</tr>
					);
					dataTableTicketsExport.push({
						nroticket: datoTicket[0],
						destino: datoTicket[1],
						valor: valor,
						fecha: newDateFormated_tz,
						moneda: datoTicket[5],
						fpago: datoTicket[4],
						rutvendedor: datoTicket[6],
						ticketestado: datoTicket[7],
						counter: datoTicket[13],
						ventasup: datoTicket[8] ? 'Si' : 'No',
						rutrempsup: datoTicket[9],
						ventarempl: datoTicket[10] ? 'Si' : 'No',
						rutrempvend: datoTicket[11],
					});
				}
				this.setState({
					dataTable: dataTableTickets,
					dataTableExport: dataTableTicketsExport,
				});
				document.getElementById('reportsTableResults').style.display = 'block';
			})
			.catch((error) => {
				console.log('getTicketsBySupervisorAndDate: ' + error);
			});
	}

	getReportByUser() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const dataTableTickets = [];
		const dataTableTicketsExport = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getTicketsByVendedorDate`,
			data: JSON.stringify({
				fechaini: this.state.iniciodate,
				fechaend: this.state.enddate,
				rutvendedor: this.state.rutvendedor,
			}),
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				for (const datoTicket of response.data) {
					const valor = datoTicket[2];

					const options = {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
					};

					const newDateFormated_tz = new Date(datoTicket[3]).toLocaleDateString(
						'es-CL',
						options
					);

					//console.log('newDateFormated_tz', newDateFormated_tz);

					/* const newDateFormated_tz = datoTicket[3]
						.replace('T', ' ')
						.replace('.000+0000', ''); */

					let countername = '';
					if (datoTicket[12] === 1) {
						countername = 'Counter Nacional';
					} else if (datoTicket[12] === 2) {
						countername = 'Counter Internacional';
					} else {
						countername = 'Counter Tres';
					}

					dataTableTickets.push(
						<tr>
							<td>{datoTicket[0]}</td>
							<td>{datoTicket[1]}</td>
							<td>{valor}</td>
							<td>{newDateFormated_tz}</td>
							<td>{datoTicket[5]}</td>
							<td>{datoTicket[4]}</td>
							<td>{datoTicket[6]}</td>
							<td>{datoTicket[7]}</td>
							<td>{countername}</td>
							<td>{datoTicket[8] ? 'Si' : 'No'}</td>
							<td>{datoTicket[9]}</td>
							<td>{datoTicket[10] ? 'Si' : 'No'}</td>
							<td>{datoTicket[11]}</td>
							<td>
								{datoTicket[7] !== 'ANULADO' ? (
									<button
										className="btnBoucherClass"
										onClick={() =>
											this.peticionCambioEstadoModal(datoTicket[0], 'ANULADO')
										}
									>
										Anular ticket
									</button>
								) : (
									<button
										className="btnBoucherClass"
										onClick={() =>
											this.peticionCambioEstadoModal(datoTicket[0], 'ACTIVO')
										}
									>
										Activar ticket
									</button>
								)}
							</td>
						</tr>
					);
					dataTableTicketsExport.push({
						nroticket: datoTicket[0],
						destino: datoTicket[1],
						valor: valor,
						fecha: newDateFormated_tz,
						moneda: datoTicket[5],
						fpago: datoTicket[4],
						rutvendedor: datoTicket[6],
						ticketestado: datoTicket[7],
						counter: countername,
						ventasup: datoTicket[8] ? 'Si' : 'No',
						rutrempsup: datoTicket[9],
						ventarempl: datoTicket[10] ? 'Si' : 'No',
						rutrempvend: datoTicket[11],
					});
				}
				this.setState({
					dataTable: dataTableTickets,
					dataTableExport: dataTableTicketsExport,
				});
				document.getElementById('reportsTableResults').style.display = 'block';
			})
			.catch((error) => {
				console.log('getTicketsBySupervisorAndDate: ' + error);
			});
	}

	getReportByConductor() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const dataTableTickets = [];
		const dataTableTicketsExport = [];
		console.log(
			this.state.iniciodate,
			this.state.enddate,
			this.state.rutconductor
		);
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getTicketsAsocToDriver`,
			data: JSON.stringify({
				fechaini: this.state.iniciodate,
				fechaend: this.state.enddate,
				rutconductor: this.state.rutconductor,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				for (const datoTicket of response.data) {
					const valor = datoTicket[3];

					const options = {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
					};

					const newDateFormated_tz = new Date(datoTicket[4]).toLocaleDateString(
						'es-CL',
						options
					);

					//console.log('newDateFormated_tz: ', newDateFormated_tz);
					/* const newDateFormated_tz = datoTicket[4]
						.replace('T', ' ')
						.replace('.000+0000', ''); */

					dataTableTickets.push(
						<tr>
							<td>{datoTicket[0]}</td>
							<td>{datoTicket[1]}</td>
							<td>{valor}</td>
							<td>{newDateFormated_tz}</td>
							<td>{datoTicket[7]}</td>
							<td>{datoTicket[5]}</td>
							<td>{datoTicket[12]}</td>
							<td>{datoTicket[14]}</td>
							<td>{datoTicket[17]}</td>
							<td>{'No'}</td>
							<td>{''}</td>
							<td>{'No'}</td>
							<td>{''}</td>
							<td>
								{datoTicket[7] !== 'ANULADO' ? (
									<button
										className="btnBoucherClass"
										onClick={() =>
											this.peticionCambioEstadoModal(datoTicket[0], 'ANULADO')
										}
									>
										Anular ticket
									</button>
								) : (
									<button
										className="btnBoucherClass"
										onClick={() =>
											this.peticionCambioEstadoModal(datoTicket[0], 'ACTIVO')
										}
									>
										Activar ticket
									</button>
								)}
							</td>
						</tr>
					);
					dataTableTicketsExport.push({
						nroticket: datoTicket[0],
						destino: datoTicket[1],
						valor: valor,
						fecha: newDateFormated_tz,
						moneda: datoTicket[7],
						fpago: datoTicket[5],
						rutvendedor: datoTicket[12],
						ticketestado: datoTicket[14],
						counter: datoTicket[17],
						ventasup: 'No',
						rutrempsup: '',
						ventarempl: 'No',
						rutrempvend: '',
					});
				}
				this.setState({
					dataTable: dataTableTickets,
					dataTableExport: dataTableTicketsExport,
				});
				document.getElementById('reportsTableResults').style.display = 'block';
			})
			.catch((error) => {
				console.log('getTicketsBySupervisorAndDate: ' + error);
			});
	}

	render() {
		const session = checkSession();

		let VerBtn = null;

		if (this.state.tiporeporte === 'reporteusuario') {
			VerBtn = (
				<button
					className="btn btn-inactivo btn-round"
					onClick={this.getReportByUser}
				>
					Ver
				</button>
			);
		} else if (this.state.tiporeporte === 'reporteconductor') {
			VerBtn = (
				<button
					className="btn btn-inactivo btn-round"
					onClick={this.getReportByConductor}
				>
					Ver
				</button>
			);
		} else {
			VerBtn = (
				<button
					className="btn btn-inactivo btn-round"
					onClick={this.getGenReport}
				>
					Ver
				</button>
			);
		}

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-body">
													<h5 className="card-title">Tipos de reporte:</h5>
													<div className="reportsTypeContainer">
														<fieldset className="form-group">
															<div className="row">
																<div className="reportsTypeContainer">
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="tipoReporteRadio"
																			id="reporteGeneral"
																			value="reportegeneral"
																			onChange={this.handleChangeTipoReporte}
																		/>
																		<label className="form-check-label">
																			Reporte general
																		</label>
																	</div>
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="tipoReporteRadio"
																			id="reporteusuario"
																			value="reporteusuario"
																			onChange={this.handleChangeTipoReporte}
																		/>
																		<label className="form-check-label">
																			Reporte por usuario
																		</label>
																	</div>
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="tipoReporteRadio"
																			id="reporteconductor"
																			value="reporteconductor"
																			onChange={this.handleChangeTipoReporte}
																		/>
																		<label className="form-check-label">
																			Reporte por conductor (tickets asociados a
																			conductor)
																		</label>
																	</div>
																</div>
															</div>
														</fieldset>
													</div>
													<div
														id="vendedoresListContainer"
														className="form-group col-md-12"
													>
														<div className="col-md-4">
															<label>Vendedor:</label>
															<select
																onChange={this.handleChangeSelectVendedores}
																id="selectVendedores"
																className="form-control"
															>
																{this.state.listaVendedores}
															</select>
														</div>
													</div>

													<div
														id="conductoresListContainer"
														className="form-group col-md-12"
													>
														<div className="col-md-4">
															<label>Conductor:</label>
															{/* <select
																onChange={this.handleChangeSelectConductores}
																id="selectConductores"
																className="form-control"
															>
																{this.state.listaConductores}
															</select> */}
															<input
																type="text"
																className="form-control"
																onChange={(e) =>
																	this.handleChangeSelectConductores(e)
																}
															/>
														</div>
													</div>

													<div
														className="form-group pull-left"
														style={{ marginRight: 20, marginTop: 12 }}
													>
														<span
															className="pull-left"
															style={{ lineHeight: 3, marginRight: 10 }}
														>
															Inicio de turno:
														</span>
														<div style={{ float: 'left', marginRight: 10 }}>
															<DatePicker
																id="dateini"
																selected={this.state.startDate}
																onChange={this.handleChange}
																className="form-control"
																dateFormat="yyyy/MM/dd"
																locale={es}
															/>
														</div>
														<div style={{ float: 'left' }}>
															<select
																id="timeini"
																className="form-control"
																style={{ width: 100, height: 45 }}
																onChange={this.onChangeTimeIni}
															>
																{/* <option>08:00:00</option>
																<option>20:00:00</option> */}
																<option>00:00:00</option>
																<option>01:00:00</option>
																<option>02:00:00</option>
																<option>03:00:00</option>
																<option>04:00:00</option>
																<option>05:00:00</option>
																<option>06:00:00</option>
																<option>07:00:00</option>
																<option>08:00:00</option>
																<option>09:00:00</option>
																<option>10:00:00</option>
																<option>11:00:00</option>
																<option>12:00:00</option>
																<option>13:00:00</option>
																<option>14:00:00</option>
																<option>15:00:00</option>
																<option>16:00:00</option>
																<option>17:00:00</option>
																<option>18:00:00</option>
																<option>19:00:00</option>
																<option>20:00:00</option>
																<option>21:00:00</option>
																<option>22:00:00</option>
																<option>23:00:00</option>
																<option>23:59:59</option>
															</select>
														</div>
													</div>

													<div
														className="form-group pull-left"
														style={{ marginTop: 12 }}
													>
														<span
															className="pull-left"
															style={{ lineHeight: 3, marginRight: 10 }}
														>
															fin de turno:
														</span>
														<div style={{ float: 'left', marginRight: 10 }}>
															<DatePicker
																id="dateend"
																selected={this.state.finDate}
																onChange={this.handleChangeEnd}
																className="form-control"
																dateFormat="yyyy/MM/dd"
																locale={es}
															/>
														</div>
														<div style={{ float: 'left' }}>
															<select
																id="timeend"
																className="form-control"
																style={{ width: 100, height: 45 }}
																onChange={this.onChangeTimeEnd}
															>
																{/* <option>20:00:00</option>
																<option>08:00:00</option> */}
																<option>00:00:00</option>
																<option>01:00:00</option>
																<option>02:00:00</option>
																<option>03:00:00</option>
																<option>04:00:00</option>
																<option>05:00:00</option>
																<option>06:00:00</option>
																<option>07:00:00</option>
																<option>08:00:00</option>
																<option>09:00:00</option>
																<option>10:00:00</option>
																<option>11:00:00</option>
																<option>12:00:00</option>
																<option>13:00:00</option>
																<option>14:00:00</option>
																<option>15:00:00</option>
																<option>16:00:00</option>
																<option>17:00:00</option>
																<option>18:00:00</option>
																<option>19:00:00</option>
																<option>20:00:00</option>
																<option>21:00:00</option>
																<option>22:00:00</option>
																<option>23:00:00</option>
																<option>23:59:59</option>
															</select>
														</div>
													</div>

													<div
														className="form-group pull-left"
														style={{ marginLeft: 30 }}
													>
														{/* {this.state.tiporeporte === 'reporteusuario' ? (
															<button
																className="btn btn-inactivo btn-round"
																onClick={this.getReportByUser}
															>
																Ver
															</button>
														) : (
															<button
																className="btn btn-inactivo btn-round"
																onClick={this.getGenReport}
															>
																Ver
															</button>
														)} */}
														{VerBtn}
													</div>

													<div id="reportsTableResults">
														<div className="exportExcelContainer">
															<ExcelFile
																element={
																	<button className="btn btn-inactivo btn-round">
																		Exportar a Excel
																	</button>
																}
																filename="TicketsReport"
															>
																<ExcelSheet
																	data={this.state.dataTableExport}
																	name="Tickets"
																>
																	<ExcelColumn
																		label="Nro. Ticket"
																		value="nroticket"
																	/>
																	<ExcelColumn
																		label="Destino"
																		value="destino"
																	/>
																	<ExcelColumn label="Valor" value="valor" />
																	<ExcelColumn label="Fecha" value="fecha" />

																	<ExcelColumn label="Moneda" value="moneda" />
																	<ExcelColumn
																		label="Forma de pago"
																		value="fpago"
																	/>
																	<ExcelColumn
																		label="Rut vendedor"
																		value="rutvendedor"
																	/>
																	<ExcelColumn
																		label="Estado del ticket"
																		value="ticketestado"
																	/>
																	<ExcelColumn
																		label="Counter"
																		value="counter"
																	/>
																	<ExcelColumn
																		label="Es venta de supervisor?"
																		value="ventasup"
																	/>
																	<ExcelColumn
																		label="Reemplazo supervisor"
																		value="rutrempsup"
																	/>
																	<ExcelColumn
																		label="Es venta de un reemplazante?"
																		value="ventarempl"
																	/>
																	<ExcelColumn
																		label="Reemplazo venta"
																		value="rutrempvend"
																	/>
																</ExcelSheet>
															</ExcelFile>
														</div>

														<table className="tableResult">
															<thead>
																<tr>
																	<th>Nro. Ticket</th>
																	<th>Destino</th>
																	<th className="minWidthValTicket">Valor</th>
																	<th>Fecha</th>
																	<th>Moneda</th>
																	<th>Forma de pago</th>
																	<th>Rut vendedor</th>
																	<th>Estado del ticket</th>
																	<th>Counter</th>
																	<th>Es venta de supervidor?</th>
																	<th>Reemplazo supervisor</th>
																	<th>Es venta de un reemplazante?</th>
																	<th>Reemplazo venta</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>{this.state.dataTable}</tbody>
														</table>
													</div>
												</div>

												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i>{' '}
														Reportes
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* MODAL */}

							<Modal
								isOpen={this.state.modalIsOpen}
								onRequestClose={this.closeModal}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel="Imprimir ticket"
							>
								<div id="CambioEstadoTicketReport">
									<div className="headermodal">
										<button onClick={this.closeModal}>
											<i className="nc-icon nc-simple-remove"></i>
										</button>
									</div>
									<div className="containerAnulacionModal">
										<h4>
											Estás seguro que quieres cambia el estado del ticket nro.{' '}
											<span className="spanBlack">
												{this.state.ticketnrochange}
											</span>{' '}
											?
										</h4>
										<div id="msgErrorPetAnul"></div>
										<br />
										<input
											type="button"
											className="btn btn-inactivo btn-round"
											onClick={this.cambiarEstadoTicket}
											value="Si"
										/>
										<input
											type="button"
											className="btn btn-inactivo btn-round"
											onClick={this.closeModal}
											value="No"
										/>
									</div>
								</div>
								<div id="CambioEstadoTicketReportFinal">
									{this.state.cambioestadoreport ? (
										<div className="cuadraturaResponse">
											<img src={success} alt="success" />
											<p>Estado actualizado.</p>
										</div>
									) : (
										<div className="cuadraturaResponse">
											<img src={error} alt="error" />
											<p>Error al actualizar el estado</p>
										</div>
									)}
								</div>
							</Modal>

							{/* FIN MODAL */}

							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

Modal.setAppElement('body');

export default Reportes;
