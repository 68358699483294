import React from 'react';
import { Redirect } from 'react-router-dom';
import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import {
	checkSession,
	verifyAccess,
	setInitDate,
	setEndDate,
	setDateFromCalIni,
	setDateFromCalEnd,
	setDateFormat,
	getDateTimeNow,
	openPaso2CierreCaja,
	openPaso3CierreCaja,
} from './components/genscripts';

import DatePicker from 'react-datepicker';
//import { registerLocale, setDefaultLocale, Date } from "react-datepicker";
import es from 'date-fns/locale/es';
//registerLocale("es", es);
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Modal from 'react-modal';
import './cajas.css';
import error from './images/error.png';
import success from './images/success.png';

const thispage = 'cajas';
const headTitle = 'Cajas';

class Cajas extends React.Component {
	constructor() {
		super();
		this.state = {
			listcounters: null,
			startDate: new Date(),
			finDate: new Date(),
			iniciodate: setInitDate(),
			enddate: setEndDate(),
			modalIsOpenCerrarCaja: false,
			modalIsOpenObservaciones: false,
			boxtoclose: 0,
			rutvendedorbox: '',
			boxtoObs: 0,
			addobssupresp: false,
		};
		this.onChangeTimeIni = this.onChangeTimeIni.bind(this);
		this.onChangeTimeEnd = this.onChangeTimeEnd.bind(this);
		this.getListCounters = this.getListCounters.bind(this);
		this.openCerrarCaja = this.openCerrarCaja.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.openObservaciones = this.openObservaciones.bind(this);
		this.closeModalObservaciones = this.closeModalObservaciones.bind(this);
		this.closeSupMoneyBox = this.closeSupMoneyBox.bind(this);
		this.ingresarCloseMoneyBox = this.ingresarCloseMoneyBox.bind(this);
		this.updateObsSup = this.updateObsSup.bind(this);
	}

	componentDidMount() {
		this.getListCounters();
	}

	openCerrarCaja(boxtoclose, rutvendedor) {
		console.log(boxtoclose);
		this.setState({
			modalIsOpenCerrarCaja: true,
			boxtoclose: boxtoclose,
			rutvendedorbox: rutvendedor,
		});
	}

	closeModal() {
		this.setState({ modalIsOpenCerrarCaja: false });
	}

	openObservaciones(boxtoObs) {
		this.setState({ modalIsOpenObservaciones: true, boxtoObs: boxtoObs });
	}

	closeModalObservaciones() {
		document.getElementById('msgErrorPetAnul').innerHTML = '';
		this.setState({ modalIsOpenObservaciones: false });
	}

	handleChange = (date) => {
		var iniDate = setDateFromCalIni(date);
		this.setState({
			startDate: date,
			iniciodate: iniDate,
		});
		console.log(iniDate);
	};

	handleChangeEnd = (date) => {
		var endDate = setDateFromCalEnd(date);
		this.setState({
			finDate: date,
			enddate: endDate,
		});
		console.log(endDate);
	};

	onChangeTimeIni(eventoini) {
		var fechahoraini =
			document.getElementById('dateini').value.replace(/\//g, '-') +
			' ' +
			eventoini.target.value;
		this.setState({
			iniciodate: fechahoraini,
		});
		console.log(fechahoraini);
	}

	onChangeTimeEnd(eventoend) {
		var fechahoraini =
			document.getElementById('dateend').value.replace(/\//g, '-') +
			' ' +
			eventoend.target.value;
		console.log(eventoend.target.value);
		this.setState({
			enddate: fechahoraini,
		});
		console.log(fechahoraini);
	}

	getListCounters() {
		let ListCounters = [];
		let DataBoxbyCounter = [];
		const cookies = new Cookies();
		const token = cookies.get('token');
		const rut = cookies.get('user');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/allcounters`,
			headers: headers,
		})
			.then((response) => {
				console.log(this.state.iniciodate);
				for (const counterdata of response.data) {
					axios({
						method: 'POST',
						url: `${process.env.REACT_APP_BACKEND_URL}/data/getBoxesInfoByCounterId`,
						data: JSON.stringify({
							iniciodate: this.state.iniciodate,
							findate: this.state.enddate,
							idcounter: counterdata.idcounter,
							rutsupervisor: rut,
						}),
						headers: headers,
					})
						// eslint-disable-next-line no-loop-func
						.then((response) => {
							console.log(response.data);
							DataBoxbyCounter = [];
							for (const infoBox of response.data) {
								const name = infoBox[2] + ' ' + infoBox[3];
								var date = setDateFormat(
									new Date(infoBox[5].toString().replace('+0000', 'Z'))
								);
								DataBoxbyCounter.push(
									<table className="boxData">
										<tr>
											<td className="tdTableCajas titleTable">
												Nombre vendedor:
											</td>
											<td className="tdTableCajas tableContentTD">{name}</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">Rut:</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[4]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Fecha de inicio caja:
											</td>
											<td className="tdTableCajas tableContentTD">{date}</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Monto inicial CL:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[6]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Monto inicial USD:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[7]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Monto inicial EU:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[8]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Monto de cierre CL:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[9]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Monto de cierre US:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[10]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Monto de cierre EU:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[11]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Monto de cierre Débito:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[12]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Monto de cierre Crédito:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[13]}
											</td>
										</tr>
										<tr>
											<td className="tdTableCajas titleTable">
												Caja abierta?{' '}
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[14] ? 'Si' : 'No'}
											</td>
										</tr>

										<tr>
											<td className="tdTableCajas titleTable">Caja cuadra?</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[15] ? 'Si' : 'No'}
											</td>
										</tr>

										<tr>
											<td className="tdTableCajas titleTable">
												Comentario cuadratura:
											</td>
											<td className="tdTableCajas tableContentTD">
												{infoBox[21]}
											</td>
										</tr>

										<tr className="TableBotonera">
											<td colSpan="2" className="tableBottomTools">
												{!infoBox[15] ? (
													<button
														onClick={() =>
															this.openCerrarCaja(infoBox[0], infoBox[4])
														}
														className="btn btn-inactivo btn-round"
													>
														Cerrar Caja
													</button>
												) : (
													''
												)}

												{infoBox[15] ? (
													<button
														onClick={() => this.openObservaciones(infoBox[0])}
														className="btn btn-inactivo btn-round"
													>
														Agregar observaciones
													</button>
												) : (
													''
												)}
											</td>
										</tr>
									</table>
								);
							}

							ListCounters.push(
								<div className="counterContainer">
									<h4>{counterdata.countername}</h4>
									<div className="boxesContainer">{DataBoxbyCounter}</div>
								</div>
							);
							this.setState({ listcounters: ListCounters });
						})
						.catch((error) => {
							console.log(error);
						});
				}
			})
			.catch((error) => {
				console.log('allcounters: ' + error);
			});
	}

	ingresarCloseMoneyBox() {
		let efectivoPesosBox = document.getElementById('efectivoPesosBox').value;
		let efectivoDolarBox = document.getElementById('efectivoDolarBox').value;
		let efectivoEuroBox = document.getElementById('efectivoEuroBox').value;
		let transbankDebito = document.getElementById('transbankDebito').value;
		let transbankCredito = document.getElementById('transbankCredito').value;

		if (efectivoPesosBox === '') {
			efectivoPesosBox = 0;
		}

		if (efectivoDolarBox === '') {
			efectivoDolarBox = 0;
		}

		if (efectivoEuroBox === '') {
			efectivoEuroBox = 0;
		}

		if (transbankDebito === '') {
			transbankDebito = 0;
		}

		if (transbankCredito === '') {
			transbankCredito = 0;
		}

		const cookies = new Cookies();
		const token = cookies.get('token');
		let cuadraparcial = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getInicioCajaById`,
			data: JSON.stringify({
				idcajasiniciofin: this.state.boxtoclose,
			}),
			headers: headers,
		})
			.then((response) => {
				let iniciodate = response.data.iniciodate;
				let iniciomontos = [];
				iniciomontos.push({
					monto: response.data.iniciomontopesos,
					moneda: 'CL',
				});
				iniciomontos.push({
					monto: response.data.iniciomontodolar,
					moneda: 'US',
				});
				iniciomontos.push({
					monto: response.data.iniciomontoeuro,
					moneda: 'EU',
				});

				let iniciomontosindxCL = 0;
				let iniciomontosindxUS = 0;
				let iniciomontosindxEU = 0;

				if (iniciomontos.length > 0) {
					const iniciomontosindx = iniciomontos.reduce(
						(acc, el) => ({
							...acc,
							[el.moneda]: el,
						}),
						{}
					);

					if (iniciomontosindx['CL']) {
						iniciomontosindxCL = iniciomontosindx['CL'].monto;
					} else {
						iniciomontosindxCL = 0;
					}

					if (iniciomontosindx['US']) {
						iniciomontosindxUS = iniciomontosindx['US'].monto;
					} else {
						iniciomontosindxUS = 0;
					}

					if (iniciomontosindx['EU']) {
						iniciomontosindxEU = iniciomontosindx['EU'].monto;
					} else {
						iniciomontosindxEU = 0;
					}
				}

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_BACKEND_URL}/data/getcuadraturaparcial`,
					data: JSON.stringify({
						fechaini: iniciodate,
						fechaend: getDateTimeNow(),
						rutvendedor: this.state.rutvendedorbox,
					}),
					headers: headers,
				})
					.then((responsecuadratura) => {
						console.log(responsecuadratura);
						let cuadrap = [];
						const cuadraturaparcial = responsecuadratura.data;
						for (let cp in cuadraturaparcial) {
							cuadrap.push({
								fpago: cuadraturaparcial[cp][0],
								moneda: cuadraturaparcial[cp][1],
								total: cuadraturaparcial[cp][2],
							});
						}

						const cuadrapEF = cuadrap.filter((x) => x.fpago === 'EF');
						const cuadrapDB = cuadrap.filter((x) => x.fpago === 'DB');
						const cuadrapTC = cuadrap.filter((x) => x.fpago === 'TC');

						console.log(cuadrapEF);
						console.log(cuadrapDB);
						console.log(cuadrapTC);

						let cuadrapEFindxCL = 0;
						let cuadrapEFindxUS = 0;
						let cuadrapEFindxEU = 0;

						let cuadrapDBindxDB = 0;
						let cuadrapTCindxTC = 0;

						if (cuadrapEF.length > 0) {
							const cuadrapEFindx = cuadrapEF.reduce(
								(acc, el) => ({
									...acc,
									[el.moneda]: el,
								}),
								{}
							);

							if (cuadrapEFindx['CL']) {
								cuadrapEFindxCL = cuadrapEFindx['CL'].total;
							} else {
								cuadrapEFindxCL = 0;
							}

							if (cuadrapEFindx['US']) {
								cuadrapEFindxUS = cuadrapEFindx['US'].total;
							} else {
								cuadrapEFindxUS = 0;
							}

							if (cuadrapEFindx['EU']) {
								cuadrapEFindxEU = cuadrapEFindx['EU'].total;
							} else {
								cuadrapEFindxEU = 0;
							}
						}

						if (cuadrapDB.length > 0) {
							const cuadrapDBindx = cuadrapDB.reduce(
								(acc, el) => ({
									...acc,
									[el.fpago]: el,
								}),
								{}
							);
							if (cuadrapDBindx['DB'].length > 0) {
								cuadrapDBindxDB = cuadrapDBindx['DB'].total;
							} else {
								cuadrapDBindxDB = 0;
							}
						}

						if (cuadrapTC.length > 0) {
							const cuadrapTCindx = cuadrapTC.reduce(
								(acc, el) => ({
									...acc,
									[el.fpago]: el,
								}),
								{}
							);
							if (cuadrapTCindx['TC'].length) {
								cuadrapTCindxTC = cuadrapTCindx['TC'].total;
							} else {
								cuadrapTCindxTC = 0;
							}
						}

						let cierrePesos = iniciomontosindxCL + cuadrapEFindxCL;
						let cierreDolar = iniciomontosindxUS + cuadrapEFindxUS;
						let cierreEuro = iniciomontosindxEU + cuadrapEFindxEU;

						cuadraparcial.push({ CL: cierrePesos });
						cuadraparcial.push({ US: cierreDolar });
						cuadraparcial.push({ EU: cierreEuro });
						cuadraparcial.push({ DB: cuadrapDBindxDB });
						cuadraparcial.push({ TC: cuadrapTCindxTC });

						const diffPesos = parseInt(efectivoPesosBox) - cierrePesos;
						const diffUSD = parseInt(efectivoDolarBox) - cierreDolar;
						const diffEuro = parseInt(efectivoEuroBox) - cierreEuro;
						const diffDB = parseInt(transbankDebito) - cuadrapDBindxDB;
						const diffTC = parseInt(transbankCredito) - cuadrapTCindxTC;

						console.log(cuadraparcial);

						document.getElementById('montoEFCL').innerHTML = cierrePesos;
						document.getElementById(
							'montoIngresadoEFCL'
						).innerHTML = efectivoPesosBox;
						document.getElementById('DiffEFCL').innerHTML = diffPesos;
						document.getElementById('montoEFUSD').innerHTML = cierreDolar;
						document.getElementById(
							'montoIngresadoEFUSD'
						).innerHTML = efectivoDolarBox;
						document.getElementById('DiffEFUSD').innerHTML = diffUSD;
						document.getElementById('montoEFEU').innerHTML = cierreEuro;
						document.getElementById(
							'montoIngresadoEFEU'
						).innerHTML = efectivoEuroBox;
						document.getElementById('DiffEFEU').innerHTML = diffEuro;
						document.getElementById('montoDB').innerHTML = cuadrapDBindxDB;
						document.getElementById(
							'montoIngresadoDB'
						).innerHTML = transbankDebito;
						document.getElementById('DiffDB').innerHTML = diffDB;
						document.getElementById('montoTC').innerHTML = cuadrapTCindxTC;
						document.getElementById(
							'montoIngresadoTC'
						).innerHTML = transbankCredito;
						document.getElementById('DiffTC').innerHTML = diffTC;

						if (
							diffPesos === 0 &&
							diffUSD === 0 &&
							(diffEuro === 0) & (diffDB === 0) &&
							diffTC === 0
						) {
							document.getElementById('msgCuadratura').innerHTML =
								'<p>La caja está OK</p>';
							document.getElementById('cajaNOCuadra').style.display = 'none';
							document.getElementById('comentariosCajaNoCuadra').value = '';
						} else {
							document.getElementById('msgCuadratura').innerHTML =
								'<p>La caja no cuadra, favor ingresa tus comentarios en el siguiente recuadro:</p>';
							document.getElementById('cajaNOCuadra').style.display = 'block';
							document.getElementById('comentariosCajaNoCuadra').value = '';
						}

						openPaso2CierreCaja();
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	closeSupMoneyBox() {
		const montoEFCL = parseInt(document.getElementById('montoEFCL').innerHTML);
		const montoIngresadoEFCL = parseInt(
			document.getElementById('montoIngresadoEFCL').innerHTML
		);
		const DiffEFCL = parseInt(document.getElementById('DiffEFCL').innerHTML);
		const montoEFUSD = parseInt(
			document.getElementById('montoEFUSD').innerHTML
		);
		const montoIngresadoEFUSD = parseInt(
			document.getElementById('montoIngresadoEFUSD').innerHTML
		);
		const DiffEFUSD = parseInt(document.getElementById('DiffEFUSD').innerHTML);
		const montoEFEU = parseInt(document.getElementById('montoEFEU').innerHTML);
		const montoIngresadoEFEU = parseInt(
			document.getElementById('montoIngresadoEFEU').innerHTML
		);
		const DiffEFEU = parseInt(document.getElementById('DiffEFEU').innerHTML);
		const montoDB = parseInt(document.getElementById('montoDB').innerHTML);
		const montoIngresadoDB = parseInt(
			document.getElementById('montoIngresadoDB').innerHTML
		);
		const DiffDB = parseInt(document.getElementById('DiffDB').innerHTML);
		const montoTC = parseInt(document.getElementById('montoTC').innerHTML);
		const montoIngresadoTC = parseInt(
			document.getElementById('montoIngresadoTC').innerHTML
		);
		const DiffTC = parseInt(document.getElementById('DiffTC').innerHTML);
		let cuadratura = null;
		if (
			DiffEFCL === 0 &&
			DiffEFUSD === 0 &&
			(DiffEFEU === 0) & (DiffDB === 0) &&
			DiffTC === 0
		) {
			cuadratura = true;
		} else {
			cuadratura = false;
		}

		const cookies = new Cookies();
		const token = cookies.get('token');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/closeMoneyBoxSup`,
			data: JSON.stringify({
				idcajasiniciofin: this.state.boxtoclose, //sacar dato
				finmontopesos: montoIngresadoEFCL,
				finmontodolar: montoIngresadoEFUSD,
				finmontoeuro: montoIngresadoEFEU,
				finmontodebito: montoIngresadoDB,
				finmontocredito: montoIngresadoTC,
				cuadratura: cuadratura,
				resumemontopesos: montoEFCL,
				resumemontodolar: montoEFUSD,
				resumemontoeuro: montoEFEU,
				resumemontodebito: montoDB,
				resumemontocredito: montoTC,
				checksupervisordate: getDateTimeNow(),
				comentariosupervisor: document.getElementById('comentariosCajaNoCuadra')
					.value,
			}),
			headers: headers,
		})
			.then((response) => {
				if (response.data > 0) {
					this.setState({ ingresocuadratura: true });
					this.getListCounters();
				} else {
					this.setState({ ingresocuadratura: false });
				}
				document.getElementById('headerModalCierreCaja').style.display = 'none';
				document.getElementById('tituloCierreCaja').style.display = 'none';
				openPaso3CierreCaja();
				setTimeout(() => {
					this.closeModal();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	updateObsSup() {
		const ObservacionSup = document.getElementById('observacionesCaja').value;
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/closeMoneyBoxObsSup`,
			data: JSON.stringify({
				checksupervisordate: getDateTimeNow(),
				comentariosupervisor: ObservacionSup,
				idcajasiniciofin: this.state.boxtoObs,
			}),
			headers: headers,
		})
			.then((response) => {
				if (parseInt(response.data) > 0) {
					this.setState({ addobssupresp: true });
					this.getListCounters();
				} else {
					this.setState({ addobssupresp: false });
				}

				document.getElementById('AddSupObsModalPaso1').style.display = 'none';
				document.getElementById('AddSupObsModalFinal').style.display = 'block';
				setTimeout(() => {
					this.closeModalObservaciones();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		const session = checkSession();
		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-header">
													<div
														className="form-group pull-left"
														style={{ marginRight: 20, marginTop: 12 }}
													>
														<span
															className="pull-left"
															style={{ lineHeight: 3, marginRight: 10 }}
														>
															Inicio de turno:
														</span>
														<div style={{ float: 'left', marginRight: 10 }}>
															<DatePicker
																id="dateini"
																selected={this.state.startDate}
																onChange={this.handleChange}
																className="form-control"
																dateFormat="yyyy/MM/dd"
																locale={es}
															/>
														</div>
														<div style={{ float: 'left' }}>
															<select
																id="timeini"
																className="form-control"
																style={{ width: 100, height: 45 }}
																onChange={this.onChangeTimeIni}
															>
																<option>08:00:00</option>
																<option>20:00:00</option>
															</select>
														</div>
													</div>

													<div
														className="form-group pull-left"
														style={{ marginTop: 12 }}
													>
														<span
															className="pull-left"
															style={{ lineHeight: 3, marginRight: 10 }}
														>
															fin de turno:
														</span>
														<div style={{ float: 'left', marginRight: 10 }}>
															<DatePicker
																id="dateend"
																selected={this.state.finDate}
																onChange={this.handleChangeEnd}
																className="form-control"
																dateFormat="yyyy/MM/dd"
																locale={es}
															/>
														</div>
														<div style={{ float: 'left' }}>
															<select
																id="timeend"
																className="form-control"
																style={{ width: 100, height: 45 }}
																onChange={this.onChangeTimeEnd}
															>
																<option>20:00:00</option>
																<option>08:00:00</option>
															</select>
														</div>
													</div>

													<div
														className="form-group pull-left"
														style={{ marginLeft: 30 }}
													>
														<button
															className="btn btn-inactivo btn-round"
															onClick={this.getListCounters}
														>
															Ver
														</button>
													</div>
												</div>

												<div className="containerGenCounters">
													{this.state.listcounters}
												</div>

												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i> Cajas
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* MODAL */}

							{/*cierre caja */}
							<Modal
								isOpen={this.state.modalIsOpenCerrarCaja}
								onAfterOpen={this.afterOpenModalCloseBox}
								onRequestClose={this.closeModal}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel="Imprimir ticket"
							>
								<div className="headermodal" id="headerModalCierreCaja">
									<button onClick={this.closeModal}>
										<i className="nc-icon nc-simple-remove"></i>
									</button>
								</div>
								<div className="containerModalForm">
									<h4 id="tituloCierreCaja">Cierre de caja:</h4>
									<div id="paso1cierrecaja">
										<h5>Montos en efectivo:</h5>
										<div className="msgLoginVenta">{this.state.msg}</div>
										<div className="form-group">
											<label for="usuarioReemplazoTemp">Monto en pesos:</label>
											<input
												type="text"
												className="form-control"
												id="efectivoPesosBox"
											/>
										</div>
										<div className="form-group">
											<label for="usuarioReemplazoTemp">
												Monto en dolares:
											</label>
											<input
												type="text"
												className="form-control"
												id="efectivoDolarBox"
											/>
										</div>
										<div className="form-group">
											<label for="usuarioReemplazoTemp">Monto en euros:</label>
											<input
												type="text"
												className="form-control"
												id="efectivoEuroBox"
											/>
										</div>

										<h5>Transbank:</h5>
										<div className="msgLoginVenta">{this.state.msg}</div>
										<div className="form-group">
											<label for="usuarioReemplazoTemp">Debito:</label>
											<input
												type="text"
												className="form-control"
												id="transbankDebito"
											/>
										</div>
										<div className="form-group">
											<label for="usuarioReemplazoTemp">Crédito:</label>
											<input
												type="text"
												className="form-control"
												id="transbankCredito"
											/>
										</div>

										<div className="form-group buttonModalForm">
											<input
												type="button"
												className="btn btn-inactivo btn-round"
												onClick={this.ingresarCloseMoneyBox}
												value="Ingresar"
											/>
										</div>
									</div>
									<div id="paso2cierrecaja">
										<h5>Resumen:</h5>
										<table className="table table-striped">
											<thead>
												<tr>
													<th></th>
													<th>Tickets</th>
													<th>Ingresado</th>
													<th>Diferencia</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Efectivo CLP</td>
													<td id="montoEFCL"></td>
													<td id="montoIngresadoEFCL"></td>
													<td id="DiffEFCL"></td>
												</tr>
												<tr>
													<td>Efectivo USD</td>
													<td id="montoEFUSD"></td>
													<td id="montoIngresadoEFUSD"></td>
													<td id="DiffEFUSD"></td>
												</tr>
												<tr>
													<td>Efectivo EU</td>
													<td id="montoEFEU"></td>
													<td id="montoIngresadoEFEU"></td>
													<td id="DiffEFEU"></td>
												</tr>
												<tr>
													<td>Débito</td>
													<td id="montoDB"></td>
													<td id="montoIngresadoDB"></td>
													<td id="DiffDB"></td>
												</tr>
												<tr>
													<td>Crédito</td>
													<td id="montoTC"></td>
													<td id="montoIngresadoTC"></td>
													<td id="DiffTC"></td>
												</tr>
											</tbody>
										</table>
										<div id="msgCuadratura"></div>
										<div id="cajaNOCuadra" className="form-group">
											<textarea
												className="form-control"
												id="comentariosCajaNoCuadra"
												rows="3"
											></textarea>
										</div>
										<p className="p_comentario">
											O puedes cerrar esta ventana y revisar tus ventas en la
											sección "Mis ventas" antes de cerrar la caja.
										</p>
										<div className="form-group buttonModalForm">
											<input
												type="button"
												className="btn btn-inactivo btn-round"
												onClick={this.closeSupMoneyBox}
												value="Cerrar caja"
											/>
										</div>
									</div>
									<div id="paso3cierrecaja">
										{this.state.ingresocuadratura ? (
											<div className="cuadraturaResponse">
												<img src={success} alt="success" />
												<p>Cuadratura ingresada.</p>
											</div>
										) : (
											<div className="cuadraturaResponse">
												<img src={error} alt="error" />
												<p>Error al ingresar la cuadratura</p>
											</div>
										)}
									</div>
								</div>
							</Modal>
							{/*fin cierre caja */}

							<Modal
								isOpen={this.state.modalIsOpenObservaciones}
								onRequestClose={this.closeModalObservaciones}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel="Imprimir ticket"
							>
								<div id="AddSupObsModalPaso1">
									<div className="headermodal">
										<button onClick={this.closeModalObservaciones}>
											<i className="nc-icon nc-simple-remove"></i>
										</button>
									</div>
									<div className="containerCajasModal">
										<h4>Agrega una observación a esta caja:</h4>
										<div id="msgErrorPetAnul"></div>
										<br />
										<div className="form-group">
											<textarea
												className="form-control"
												id="observacionesCaja"
												rows="3"
											></textarea>
											<div className="botoneraModalCaja">
												<input
													type="button"
													className="btn btn-inactivo btn-round"
													onClick={this.updateObsSup}
													value="Guardar"
												/>
											</div>
										</div>
									</div>
								</div>
								<div id="AddSupObsModalFinal">
									{this.state.addobssupresp ? (
										<div className="cuadraturaResponse">
											<img src={success} alt="success" />
											<p>Observación ingresada.</p>
										</div>
									) : (
										<div className="cuadraturaResponse">
											<img src={error} alt="error" />
											<p>Error al ingresar la observación</p>
										</div>
									)}
								</div>
							</Modal>

							{/* FIN MODAL */}

							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

Modal.setAppElement('body');

export default Cajas;
