import React from 'react';
import './login.css';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import logoTaxiOficial from './images/logoTaxiOficial.png';

class LoginPage extends React.Component {
	constructor() {
		super();
		this.state = {
			rut: null,
			password: null,
			pages: null,
			loginstate: false,
			msg: '',
		};
		this.login = this.login.bind(this);
	}

	componentDidMount() {
		const cookies = new Cookies();
		this.setState({ loginstate: cookies.get('session') });
		//const { suggestions } = this.props;
		//console.log(suggestions);
		//this.setState({ loginstate: this.updateLoginState()});
	}

	login() {
		fetch(`${process.env.REACT_APP_BACKEND_URL}/authenticate`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: this.state.rut,
				password: this.state.password,
			}),
		})
			.then((response) => {
				response.json().then((result) => {
					if (response.status === 200) {
						if (result.token != null) {
							const headers = {
								Authorization: 'Bearer ' + result.token,
								'Content-Type': 'application/json',
							};
							axios({
								method: 'POST',
								url: `${process.env.REACT_APP_BACKEND_URL}/data/pagesbyrut`,
								data: JSON.stringify({
									rut: this.state.rut,
								}),
								headers: headers,
							})
								.then((resp) => {
									axios({
										method: 'POST',
										url: `${process.env.REACT_APP_BACKEND_URL}/data/rutuser`,
										data: JSON.stringify({
											rut: this.state.rut,
										}),
										headers: headers,
									})
										.then((respuser) => {
											const cookies = new Cookies();
											var now = new Date();
											now.setTime(now.getTime() + 15 * 3600 * 1000); //12 horas
											cookies.set('token', result.token, {
												path: '/',
												expires: now,
											});
											cookies.set('session', true, { path: '/', expires: now });
											cookies.set('user', this.state.rut, {
												path: '/',
												expires: now,
											});
											cookies.set(
												'name',
												respuser.data.nombre + ' ' + respuser.data.apellido,
												{ path: '/', expires: now }
											);
											cookies.set('pid', respuser.data.idperfil, {
												path: '/',
												expires: now,
											});
											cookies.set('pages', resp.data, {
												path: '/',
												expires: now,
											});
											cookies.set('expdat', now, { path: '/', expires: now });
											console.log(resp.data);
											this.setState({
												loginstate: true,
												pages: resp.data,
												profileid: respuser.data.idperfil,
												msg: 'Login ok',
											});
										})
										.catch((error) => {
											console.log(error);
										});
								})
								.catch((error) => {
									console.log(error);
								});
						}
					} // status 200
					else if (response.status === 401) {
						this.setState({
							loginstate: false,
							msg: 'usuario o clave incorrectos',
						});
					} else {
						//console.log("ELSE");
						this.setState({
							loginstate: false,
							msg: 'Error de conexión',
						});
					}
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}
	/*
    
*/

	render() {
		//const loginstate = this.state.loginstate;
		return (
			<div className="container-scroller">
				{!this.state.loginstate ? (
					<div className="container-fluid page-body-wrapper full-page-wrapper">
						<div className="content-wrapper d-flex align-items-center auth">
							<div className="row flex-grow">
								<div className="col-lg-4 mx-auto" id="root">
									<div className="auth-form-light text-left p-5">
										<div className="brand-logo">
											<img src={logoTaxiOficial} alt="logo" />
										</div>
										<h4>Bienvenido</h4>
										<div id="resultado">{this.state.msg}</div>
										<form className="pt-3">
											<div className="form-group">
												<input
													type="text"
													className="form-control form-control-lg"
													id="username"
													placeholder="Usuario"
													onChange={(event) => {
														this.setState({ rut: event.target.value });
													}}
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control form-control-lg"
													id="password"
													placeholder="Clave"
													onChange={(event) => {
														this.setState({ password: event.target.value });
													}}
												/>
											</div>
											<div className="mt-3">
												<button
													type="button"
													className="btn btn-block btn-dark btn-lg font-weight-medium auth-form-btn"
													onClick={() => {
														this.login();
													}}
												>
													Entrar
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<Redirect to="/redirect" />
				)}
			</div>
		);
	}
}

export default LoginPage;
