const Penalolen = {
    commune: "Peñalolén",
    polygons: [
        {
            idService: 20,
            nameService: "Peñalolen 1 / Hasta tobalaba",
            coordinates: [
                //limite sur tobalaba
                {"lat":-33.511259756381655, "lng":-70.56056833574205},
                //limites Tobalaba
                {"lat":-33.50914661926856, "lng":-70.56133454485638},
                {"lat":-33.5024628679316, "lng":-70.56121666654343},
                {"lat":-33.499022500597015, "lng":-70.5605093966657},
                {"lat":-33.484374835395194, "lng":-70.55850546534543},
                {"lat":-33.47837742545563, "lng":-70.55803395209361},
                {"lat":-33.47306789641665, "lng":-70.5559121424604},
                {"lat":-33.46681012943326, "lng":-70.55049754073563},
                // Limite norte con la Reina por tobalaba
                {"lat":-33.463270548160004, "lng":-70.55137859460336},
                //limite norte peñalolen con la reina
                {"lat":-33.46290304241407, "lng":-70.5623222115051},
                {"lat":-33.459498704602424, "lng":-70.57206017569733},
                {"lat":-33.468782947378116, "lng":-70.57576987634198},
                {"lat":-33.469866044259135, "lng":-70.57539890627751},
                {"lat":-33.47048495068515, "lng":-70.57679004401926},
                {"lat":-33.48835396714355, "lng":-70.58003603208334},
                {"lat":-33.48843131418051, "lng":-70.58142716982509},
                {"lat":-33.490055585998846, "lng":-70.58049974466392},
                {"lat":-33.50034193375289, "lng":-70.58662075077683},
                {"lat":-33.50784323590913, "lng":-70.58782640348635},
                //Limite sur av macul // av departamental
                {"lat":-33.510549731707776, "lng":-70.57660455903626},
                {"lat":-33.50931248698904, "lng":-70.56937064277918},
                {"lat":-33.51147765363749, "lng":-70.56668110981178},
                {"lat":-33.51132300067358, "lng":-70.56491900200558},
            ]
        },
        {
            idService: 47,
            nameService: "Peñalolen 2 / Desde Tobalaba hasta Las Pircas",
            coordinates: [
                //limite sur tobalaba
                {"lat":-33.511259756381655, "lng":-70.56056833574205},
                //limites Tobalaba
                {"lat":-33.50914661926856, "lng":-70.56133454485638},
                {"lat":-33.5024628679316, "lng":-70.56121666654343},
                {"lat":-33.499022500597015, "lng":-70.5605093966657},
                {"lat":-33.484374835395194, "lng":-70.55850546534543},
                {"lat":-33.47837742545563, "lng":-70.55803395209361},
                {"lat":-33.47306789641665, "lng":-70.5559121424604},
                {"lat":-33.46681012943326, "lng":-70.55049754073563},
                // Limite norte con la Reina por tobalaba
                {"lat":-33.463270548160004, "lng":-70.55137859460336},
                // Limite norte con la Reina sobre tobalaba
                {"lat":-33.46364146352969, "lng":-70.54738669694548},
                {"lat":-33.46382894092036, "lng":-70.54419721005007},
                {"lat":-33.46556192323905, "lng":-70.53156419458428},
                {"lat":-33.460884200455666, "lng":-70.5269028145461},
                {"lat":-33.45970064022209, "lng":-70.52122809102136},
                {"lat":-33.45979467873303, "lng":-70.51958160920154},
                {"lat":-33.459834328081485, "lng":-70.51768058343487},
                {"lat":-33.4599741023566, "lng":-70.51605579762362},
                {"lat":-33.461108549837306, "lng":-70.51663857570286},
                {"lat":-33.462999262649284, "lng":-70.51644431634311},
                {"lat":-33.46407965145569, "lng":-70.5124943760283},
                {"lat":-33.465754227494294, "lng":-70.51398369778634},
                {"lat":-33.46645645943082, "lng":-70.51521400706471},
                {"lat":-33.468347055596574, "lng":-70.514242710266},
                {"lat":-33.46910328251551, "lng":-70.51139357298976},
                {"lat":-33.47364050545598, "lng":-70.50951573251223},
                {"lat":-33.47223615231217, "lng":-70.51851641618039},
                {"lat":-33.473370439311296, "lng":-70.51884018177995},
                {"lat":-33.47326241261775, "lng":-70.5181278974609},
                {"lat":-33.47396458371854, "lng":-70.51735086002192},
                {"lat":-33.47574699249662, "lng":-70.5137894384266},
                {"lat":-33.47931170005305, "lng":-70.51197635106898},
                {"lat":-33.48195813049127, "lng":-70.5198762316986},
                {"lat":-33.487844798469304, "lng":-70.51799839126976},
                {"lat":-33.489086888208824, "lng":-70.5129476479164},
                {"lat":-33.49140900819575, "lng":-70.51197635111768},
                {"lat":-33.49799700965284, "lng":-70.50860918888212},
                {"lat":-33.49913095933334, "lng":-70.50964523880073},
                {"lat":-33.49999491102502, "lng":-70.51391894471513},
                {"lat":-33.50105267742386, "lng":-70.52192131806312},
                {"lat":-33.50455964037833, "lng":-70.52183548737547},
                {"lat":-33.506921392499684, "lng":-70.52913109582565},
                {"lat":-33.503700805445916, "lng":-70.53050438682804},
                {"lat":-33.50284196199144, "lng":-70.53402344502165},
                {"lat":-33.506992959739684, "lng":-70.53608338152524},
                {"lat":-33.509354645456725, "lng":-70.54209152966068},
                {"lat":-33.508231495277386, "lng":-70.54058773612692},
                {"lat":-33.51218270674574, "lng":-70.54158539322675},
                //Limite con la florida
                {"lat": -33.5122378, "lng": -70.5418795},
                {"lat": -33.5122525, "lng": -70.541892},
                {"lat": -33.5122673, "lng": -70.5419139},
                {"lat": -33.5122956, "lng": -70.5419631},
                {"lat": -33.512349, "lng": -70.5420668},
                {"lat": -33.5123954, "lng": -70.5422657},
                {"lat": -33.5124329, "lng": -70.5424714},
                {"lat": -33.5124368, "lng": -70.5426785},
                {"lat": -33.5124261, "lng": -70.542887},
                {"lat": -33.5123937, "lng": -70.543308},
                {"lat": -33.5123794, "lng": -70.5434303},
                {"lat": -33.5123618, "lng": -70.5438678},
                {"lat": -33.5123708, "lng": -70.5442378},
                {"lat": -33.5124302, "lng": -70.5448099},
                {"lat": -33.5124477, "lng": -70.5450315},
                {"lat": -33.512448, "lng": -70.5457814},
                {"lat": -33.5124437, "lng": -70.5459525},
                {"lat": -33.5124249, "lng": -70.5462717},
                {"lat": -33.5123626, "lng": -70.5468169},
                {"lat": -33.5123351, "lng": -70.5473043},
                {"lat": -33.5122744, "lng": -70.5485367},
                {"lat": -33.5122451, "lng": -70.5491033},
                {"lat": -33.5122248, "lng": -70.5497838},
                {"lat": -33.5121945, "lng": -70.5503967},
                {"lat": -33.5121812, "lng": -70.5506498},
                {"lat": -33.5121703, "lng": -70.5511202},
                {"lat": -33.5121531, "lng": -70.5516934},
                {"lat": -33.5121281, "lng": -70.5522681},
                {"lat": -33.5120748, "lng": -70.5534146},
                {"lat": -33.5120641, "lng": -70.5536166},
                {"lat": -33.5120613, "lng": -70.5538186},
                {"lat": -33.5120444, "lng": -70.5546021},
                {"lat": -33.5120035, "lng": -70.5553762},
                {"lat": -33.5119592, "lng": -70.5562562},
                {"lat": -33.5118854, "lng": -70.5572859},
                {"lat": -33.5118082, "lng": -70.5582365},
                {"lat": -33.5117779, "lng": -70.5586481},
                {"lat": -33.5117483, "lng": -70.559045},
                {"lat": -33.51176, "lng": -70.559116},
                {"lat": -33.5116879, "lng": -70.5598073},
                {"lat": -33.5116345, "lng": -70.5601277},
                {"lat": -33.5115834, "lng": -70.560347},
            ]
        },
    ]
};

export default Penalolen;
