const LaReina = {
    commune: "La Reina",
    polygons: [
        {
            idService: 52,
            nameService : "La Reina 1 / Hasta Vicente Perez Rosales",
            coordinates: [
                {"lat": -33.4317749, "lng": -70.5846367},
                {"lat": -33.4317348, "lng": -70.5843877},
                {"lat": -33.4317143, "lng": -70.5840702},
                {"lat": -33.4316715, "lng": -70.583216},
                {"lat": -33.4315963, "lng": -70.5822819},
                {"lat": -33.4315622, "lng": -70.581649},
                {"lat": -33.43151, "lng": -70.580946},
                {"lat": -33.4314762, "lng": -70.5802668},
                {"lat": -33.4313916, "lng": -70.5787866},
                {"lat": -33.4313778, "lng": -70.5785774},
                {"lat": -33.4313296, "lng": -70.5777255},
                {"lat": -33.4312912, "lng": -70.5767011},
                {"lat": -33.431236, "lng": -70.5743377},
                {"lat": -33.4311734, "lng": -70.5721244},
                {"lat": -33.4311288, "lng": -70.5702504},
                {"lat": -33.4310352, "lng": -70.5671253},
                {"lat": -33.4310102, "lng": -70.5658573},
                {"lat": -33.4309853, "lng": -70.5649755},
                {"lat": -33.4309204, "lng": -70.5632964},
                {"lat": -33.4308376, "lng": -70.5616817},
                {"lat": -33.4307783, "lng": -70.5608636},
                {"lat": -33.4307368, "lng": -70.5600456},
                {"lat": -33.4306954, "lng": -70.5591631},
                {"lat": -33.4306381, "lng": -70.5580976},
                {"lat": -33.4305628, "lng": -70.5569677},
                {"lat": -33.4305234, "lng": -70.5558808},
                {"lat": -33.4305023, "lng": -70.5553032},
                {"lat": -33.4304756, "lng": -70.554603},
                {"lat": -33.4305228, "lng": -70.5546016},
                {"lat": -33.4305463, "lng": -70.5545942},
                {"lat": -33.4305575, "lng": -70.5545878},
                {"lat": -33.4305799, "lng": -70.5545774},
                {"lat": -33.4306088, "lng": -70.5545493},
                {"lat": -33.4306272, "lng": -70.5545145},
                {"lat": -33.4306466, "lng": -70.5544683},
                {"lat": -33.4306565, "lng": -70.5544336},
                {"lat": -33.4306642, "lng": -70.5543694},
                {"lat": -33.430666, "lng": -70.5543341},
                {"lat": -33.4306644, "lng": -70.5543001},
                {"lat": -33.430656, "lng": -70.5542465},
                {"lat": -33.4306425, "lng": -70.5542029},
                {"lat": -33.4306231, "lng": -70.5541584},
                {"lat": -33.43061, "lng": -70.5541354},
                {"lat": -33.4306211, "lng": -70.5539402},
                {"lat": -33.4306188, "lng": -70.5538998},
                {"lat": -33.4306043, "lng": -70.5537575},
                {"lat": -33.4305304, "lng": -70.5530623},
                {"lat": -33.4304487, "lng": -70.5522899},
                {"lat": -33.4304056, "lng": -70.5518903},
                {"lat": -33.4303784, "lng": -70.5516475},
                {"lat": -33.4303636, "lng": -70.5515027},
                //Lmites con Vicente Perez Rosales
                {"lat": -33.4303636, "lng": -70.5515027},
                {"lat": -33.4304279, "lng": -70.5514989},
                {"lat": -33.4304855, "lng": -70.5514965},
                {"lat": -33.4305996, "lng": -70.5514903},
                {"lat": -33.4308399, "lng": -70.5514841},
                {"lat": -33.4308978, "lng": -70.5514833},
                {"lat": -33.4309804, "lng": -70.5514867},
                {"lat": -33.4310367, "lng": -70.5514893},
                {"lat": -33.431153, "lng": -70.5514913},
                {"lat": -33.4312089, "lng": -70.5514963},
                {"lat": -33.4312982, "lng": -70.5514996},
                {"lat": -33.4313348, "lng": -70.5514995},
                {"lat": -33.431407, "lng": -70.5515057},
                {"lat": -33.4314378, "lng": -70.5515025},
                {"lat": -33.4315418, "lng": -70.5514974},
                {"lat": -33.4316577, "lng": -70.5514883},
                {"lat": -33.4317525, "lng": -70.551479},
                {"lat": -33.4318976, "lng": -70.5514399},
                {"lat": -33.4320402, "lng": -70.5513944},
                {"lat": -33.4321492, "lng": -70.5513382},
                {"lat": -33.4322225, "lng": -70.5512973},
                {"lat": -33.4323259, "lng": -70.5512384},
                {"lat": -33.4323905, "lng": -70.5511971},
                {"lat": -33.432454, "lng": -70.5511492},
                {"lat": -33.4325427, "lng": -70.5510845},
                {"lat": -33.432582, "lng": -70.5510562},
                {"lat": -33.4328529, "lng": -70.5507819},
                {"lat": -33.4331403, "lng": -70.5505237},
                {"lat": -33.4334135, "lng": -70.5503024},
                {"lat": -33.4337729, "lng": -70.5501495},
                {"lat": -33.4339383, "lng": -70.5500969},
                {"lat": -33.4340736, "lng": -70.5500702},
                {"lat": -33.4342073, "lng": -70.5500518},
                {"lat": -33.4343579, "lng": -70.5500514},
                {"lat": -33.4345086, "lng": -70.5500618},
                {"lat": -33.4346626, "lng": -70.5500781},
                {"lat": -33.4348101, "lng": -70.5501071},
                {"lat": -33.4348489, "lng": -70.5501194},
                {"lat": -33.4349056, "lng": -70.5501439},
                {"lat": -33.4350011, "lng": -70.550186},
                {"lat": -33.4350954, "lng": -70.5502274},
                {"lat": -33.4351821, "lng": -70.5502745},
                {"lat": -33.4352554, "lng": -70.5502974},
                {"lat": -33.435337, "lng": -70.5503224},
                {"lat": -33.4354196, "lng": -70.5503426},
                {"lat": -33.4354484, "lng": -70.5503521},
                {"lat": -33.4355586, "lng": -70.5503926},
                {"lat": -33.435778, "lng": -70.5504763},
                {"lat": -33.4359967, "lng": -70.5505593},
                {"lat": -33.4361073, "lng": -70.5506002},
                {"lat": -33.4361446, "lng": -70.5506157},
                {"lat": -33.4361633, "lng": -70.5506241},
                {"lat": -33.4361842, "lng": -70.5506339},
                {"lat": -33.4362146, "lng": -70.5506463},
                {"lat": -33.4362232, "lng": -70.5506172},
                {"lat": -33.4362596, "lng": -70.5504895},
                {"lat": -33.4364729, "lng": -70.5497442},
                {"lat": -33.4365717, "lng": -70.5493675},
                {"lat": -33.4366784, "lng": -70.5489649},
                {"lat": -33.4367069, "lng": -70.5489637},
                {"lat": -33.4365404, "lng": -70.5495888},
                {"lat": -33.436471, "lng": -70.5498575},
                {"lat": -33.4363938, "lng": -70.5501223},
                {"lat": -33.4362406, "lng": -70.5506557},
                {"lat": -33.4364221, "lng": -70.5507284},
                {"lat": -33.4366014, "lng": -70.5508064},
                {"lat": -33.4369611, "lng": -70.5509638},
                {"lat": -33.4376672, "lng": -70.5512728},
                {"lat": -33.4385143, "lng": -70.5516278},
                {"lat": -33.4387261, "lng": -70.5517159},
                {"lat": -33.4389367, "lng": -70.551812},
                {"lat": -33.4393449, "lng": -70.551996},
                {"lat": -33.4400638, "lng": -70.5522861},
                {"lat": -33.440421, "lng": -70.5524392},
                {"lat": -33.4406007, "lng": -70.5525158},
                {"lat": -33.4407163, "lng": -70.5525669},
                {"lat": -33.4407741, "lng": -70.5525932},
                {"lat": -33.4408319, "lng": -70.5526248},
                {"lat": -33.4413489, "lng": -70.5529123},
                {"lat": -33.441411, "lng": -70.5526229},
                {"lat": -33.4413882, "lng": -70.5526084},
                {"lat": -33.4413675, "lng": -70.5525913},
                {"lat": -33.4413463, "lng": -70.5525721},
                {"lat": -33.4413274, "lng": -70.5525462},
                {"lat": -33.4413219, "lng": -70.5524962},
                {"lat": -33.4413247, "lng": -70.5524442},
                {"lat": -33.4413283, "lng": -70.5524172},
                {"lat": -33.4413352, "lng": -70.5523916},
                {"lat": -33.4413439, "lng": -70.5523659},
                {"lat": -33.4413525, "lng": -70.5523456},
                {"lat": -33.4413714, "lng": -70.5522956},
                {"lat": -33.4413835, "lng": -70.5522764},
                {"lat": -33.441399, "lng": -70.5522612},
                {"lat": -33.4414177, "lng": -70.5522563},
                {"lat": -33.4414352, "lng": -70.5522608},
                {"lat": -33.4414714, "lng": -70.5522643},
                {"lat": -33.4414964, "lng": -70.5522763},
                {"lat": -33.4415169, "lng": -70.552295},
                {"lat": -33.4415268, "lng": -70.5523103},
                {"lat": -33.4415348, "lng": -70.5523454},
                {"lat": -33.4415317, "lng": -70.5523806},
                {"lat": -33.4414893, "lng": -70.5524995},
                {"lat": -33.4414665, "lng": -70.5525583},
                {"lat": -33.4414404, "lng": -70.552617},
                {"lat": -33.4413756, "lng": -70.5529252},
                {"lat": -33.441599, "lng": -70.5529911},
                {"lat": -33.4416919, "lng": -70.5530169},
                {"lat": -33.4417849, "lng": -70.5530508},
                {"lat": -33.4419697, "lng": -70.5531173},
                {"lat": -33.4421551, "lng": -70.553179},
                {"lat": -33.4422483, "lng": -70.5532079},
                {"lat": -33.4422722, "lng": -70.5532154},
                {"lat": -33.442296, "lng": -70.5532216},
                {"lat": -33.4423109, "lng": -70.5532276},
                {"lat": -33.4423286, "lng": -70.5532302},
                {"lat": -33.4423704, "lng": -70.5532244},
                {"lat": -33.4423909, "lng": -70.5532173},
                {"lat": -33.4424555, "lng": -70.5531911},
                {"lat": -33.4425801, "lng": -70.5531184},
                {"lat": -33.4428259, "lng": -70.5529875},
                {"lat": -33.442941, "lng": -70.5529562},
                {"lat": -33.4430582, "lng": -70.5529241},
                {"lat": -33.4433757, "lng": -70.5527551},
                {"lat": -33.4439444, "lng": -70.5524531},
                {"lat": -33.4442282, "lng": -70.5523021},
                {"lat": -33.444433, "lng": -70.5521934},
                {"lat": -33.4444006, "lng": -70.5520388},
                {"lat": -33.4446359, "lng": -70.5519304},
                {"lat": -33.4447524, "lng": -70.5518742},
                {"lat": -33.4448123, "lng": -70.5518481},
                {"lat": -33.4448406, "lng": -70.5518317},
                {"lat": -33.4448712, "lng": -70.5518314},
                {"lat": -33.4448945, "lng": -70.5518436},
                {"lat": -33.4449076, "lng": -70.5518633},
                {"lat": -33.4449174, "lng": -70.5518869},
                {"lat": -33.4449347, "lng": -70.5519343},
                {"lat": -33.445254, "lng": -70.5517623},
                {"lat": -33.4455878, "lng": -70.5515843},
                {"lat": -33.4457466, "lng": -70.5515032},
                {"lat": -33.4458366, "lng": -70.5514607},
                {"lat": -33.4459267, "lng": -70.5514248},
                {"lat": -33.4459815, "lng": -70.5514077},
                {"lat": -33.4460363, "lng": -70.5513973},
                {"lat": -33.4460928, "lng": -70.5513903},
                {"lat": -33.4461482, "lng": -70.5513926},
                {"lat": -33.4462236, "lng": -70.5513893},
                {"lat": -33.446293, "lng": -70.5513833},
                {"lat": -33.4469844, "lng": -70.5513559},
                {"lat": -33.4473324, "lng": -70.5513409},
                {"lat": -33.4475058, "lng": -70.5513367},
                {"lat": -33.447684, "lng": -70.5513326},
                {"lat": -33.4477182, "lng": -70.5513334},
                {"lat": -33.4477647, "lng": -70.5513235},
                {"lat": -33.4479027, "lng": -70.551322},
                {"lat": -33.4480071, "lng": -70.5513205},
                {"lat": -33.4481725, "lng": -70.5513153},
                {"lat": -33.448875, "lng": -70.5512955},
                {"lat": -33.4491563, "lng": -70.5513051},
                {"lat": -33.4494364, "lng": -70.5513053},
                {"lat": -33.4498758, "lng": -70.5513057},
                {"lat": -33.450152, "lng": -70.5512912},
                {"lat": -33.450428, "lng": -70.5512876},
                {"lat": -33.4505458, "lng": -70.5512456},
                {"lat": -33.4506697, "lng": -70.551238},
                {"lat": -33.4507321, "lng": -70.5512349},
                {"lat": -33.4508182, "lng": -70.5512306},
                {"lat": -33.4508803, "lng": -70.5512454},
                {"lat": -33.450948, "lng": -70.551248},
                {"lat": -33.4510369, "lng": -70.5512413},
                {"lat": -33.4510702, "lng": -70.5512312},
                {"lat": -33.4510923, "lng": -70.5512171},
                {"lat": -33.4511653, "lng": -70.5511685},
                {"lat": -33.4512383, "lng": -70.5511225},
                {"lat": -33.4512798, "lng": -70.5511119},
                {"lat": -33.4513214, "lng": -70.5510973},
                {"lat": -33.4513788, "lng": -70.5510885},
                {"lat": -33.4514363, "lng": -70.551085},
                {"lat": -33.4515545, "lng": -70.5511035},
                {"lat": -33.4517899, "lng": -70.5511673},
                {"lat": -33.4522438, "lng": -70.5513111},
                {"lat": -33.4527061, "lng": -70.5514367},
                {"lat": -33.4531498, "lng": -70.5515681},
                {"lat": -33.4536024, "lng": -70.5516995},
                {"lat": -33.4537682, "lng": -70.5517445},
                {"lat": -33.4543256, "lng": -70.5518969},
                {"lat": -33.4546056, "lng": -70.5519803},
                {"lat": -33.454927, "lng": -70.5520598},
                {"lat": -33.4552132, "lng": -70.5521561},
                {"lat": -33.4553624, "lng": -70.5522029},
                {"lat": -33.4555139, "lng": -70.5522497},
                {"lat": -33.455818, "lng": -70.5523232},
                {"lat": -33.4558298, "lng": -70.5522719},
                {"lat": -33.4558506, "lng": -70.5522259},
                {"lat": -33.4558633, "lng": -70.5522099},
                {"lat": -33.4558804, "lng": -70.5522007},
                {"lat": -33.4559037, "lng": -70.5521988},
                {"lat": -33.4559258, "lng": -70.5522103},
                {"lat": -33.4560325, "lng": -70.5522483},
                {"lat": -33.4561732, "lng": -70.5522826},
                {"lat": -33.4562461, "lng": -70.5523138},
                {"lat": -33.4563111, "lng": -70.5523665},
                {"lat": -33.4563965, "lng": -70.5525014},
                {"lat": -33.4564597, "lng": -70.5525231},
                {"lat": -33.4566356, "lng": -70.5522094},
                {"lat": -33.4566565, "lng": -70.5521813},
                {"lat": -33.4569298, "lng": -70.5518591},
                {"lat": -33.4571171, "lng": -70.5518838},
                {"lat": -33.4572068, "lng": -70.5519009},
                {"lat": -33.4572965, "lng": -70.55193},
                {"lat": -33.4573779, "lng": -70.5519706},
                {"lat": -33.4574581, "lng": -70.5520259},
                {"lat": -33.4575366, "lng": -70.5520825},
                {"lat": -33.4576117, "lng": -70.5521459},
                {"lat": -33.4576897, "lng": -70.5522052},
                {"lat": -33.4577483, "lng": -70.5522274},
                {"lat": -33.457779, "lng": -70.5522322},
                {"lat": -33.45778, "lng": -70.5523014},
                {"lat": -33.4577805, "lng": -70.5526155},
                {"lat": -33.4577798, "lng": -70.5527753},
                {"lat": -33.4577797, "lng": -70.5528597},
                {"lat": -33.4577811, "lng": -70.552964},
                {"lat": -33.4579761, "lng": -70.5530268},
                {"lat": -33.4580741, "lng": -70.5530589},
                {"lat": -33.458171, "lng": -70.5530882},
                {"lat": -33.4582646, "lng": -70.5530973},
                {"lat": -33.458336, "lng": -70.5530985},
                {"lat": -33.4583626, "lng": -70.553097},
                {"lat": -33.4588015, "lng": -70.5530763},
                {"lat": -33.459114, "lng": -70.5530717},
                {"lat": -33.4591605, "lng": -70.5530689},
                {"lat": -33.4591891, "lng": -70.5530714},
                {"lat": -33.4592495, "lng": -70.5530844},
                {"lat": -33.4593089, "lng": -70.5531041},
                {"lat": -33.4593637, "lng": -70.5531365},
                {"lat": -33.459418, "lng": -70.5531767},
                {"lat": -33.4595853, "lng": -70.55306},
                {"lat": -33.4597934, "lng": -70.5529097},
                {"lat": -33.4602074, "lng": -70.5526144},
                {"lat": -33.4606191, "lng": -70.5523205},
                {"lat": -33.4608244, "lng": -70.5521762},
                {"lat": -33.4610308, "lng": -70.5520373},
                {"lat": -33.4618599, "lng": -70.5514669},
                {"lat": -33.462268, "lng": -70.5511837},
                //Limites con peñalolen
                {"lat": -33.4626881, "lng": -70.55089},
                {"lat": -33.462681, "lng": -70.5509327},
                {"lat": -33.4626634, "lng": -70.5516635},
                {"lat": -33.4626502, "lng": -70.5524906},
                {"lat": -33.4626337, "lng": -70.5533177},
                {"lat": -33.4626309, "lng": -70.5535424},
                {"lat": -33.4626304, "lng": -70.5535847},
                {"lat": -33.4626283, "lng": -70.5541441},
                {"lat": -33.4626292, "lng": -70.5545466},
                {"lat": -33.46263, "lng": -70.5545831},
                {"lat": -33.4626315, "lng": -70.5549692},
                {"lat": -33.4625597, "lng": -70.555635},
                {"lat": -33.4625605, "lng": -70.5563814},
                {"lat": -33.4625692, "lng": -70.5572473},
                {"lat": -33.4625743, "lng": -70.5576185},
                {"lat": -33.4625717, "lng": -70.5580541},
                {"lat": -33.4625734, "lng": -70.5584456},
                {"lat": -33.4625673, "lng": -70.5588357},
                {"lat": -33.4625794, "lng": -70.5598032},
                {"lat": -33.4625776, "lng": -70.5599062},
                {"lat": -33.4625703, "lng": -70.5602278},
                {"lat": -33.4625657, "lng": -70.5604401},
                {"lat": -33.4625667, "lng": -70.560651},
                {"lat": -33.4625681, "lng": -70.5610754},
                {"lat": -33.4625683, "lng": -70.5613747},
                {"lat": -33.4625707, "lng": -70.5614984},
                {"lat": -33.4625674, "lng": -70.562018},
                {"lat": -33.4625663, "lng": -70.5620538},
                {"lat": -33.4625618, "lng": -70.5621488},
                {"lat": -33.4625572, "lng": -70.5621942},
                {"lat": -33.4625504, "lng": -70.5622396},
                {"lat": -33.4625255, "lng": -70.5623586},
                {"lat": -33.4624687, "lng": -70.5625936},
                {"lat": -33.4623788, "lng": -70.5628459},
                {"lat": -33.4623321, "lng": -70.562968},
                {"lat": -33.4622879, "lng": -70.563135},
                {"lat": -33.4621351, "lng": -70.5636091},
                {"lat": -33.4619964, "lng": -70.564023},
                {"lat": -33.4619853, "lng": -70.5640549},
                {"lat": -33.4619327, "lng": -70.564221},
                {"lat": -33.4618299, "lng": -70.5645017},
                {"lat": -33.4618199, "lng": -70.5645309},
                {"lat": -33.4617741, "lng": -70.5646929},
                {"lat": -33.4616937, "lng": -70.5649675},
                {"lat": -33.4616557, "lng": -70.5651055},
                {"lat": -33.4616065, "lng": -70.5652381},
                {"lat": -33.4615406, "lng": -70.5654016},
                {"lat": -33.4614724, "lng": -70.565565},
                {"lat": -33.4613953, "lng": -70.5657659},
                {"lat": -33.4610192, "lng": -70.5668403},
                {"lat": -33.4608312, "lng": -70.5673768},
                {"lat": -33.4606375, "lng": -70.567912},
                {"lat": -33.4603904, "lng": -70.568594},
                {"lat": -33.4603284, "lng": -70.5687519},
                {"lat": -33.4601209, "lng": -70.5692665},
                {"lat": -33.4599612, "lng": -70.5697198},
                {"lat": -33.4597156, "lng": -70.5703528},
                {"lat": -33.4596619, "lng": -70.5704896},
                {"lat": -33.459616, "lng": -70.5706291},
                {"lat": -33.4594314, "lng": -70.571183},
                {"lat": -33.4592372, "lng": -70.5717355},
                {"lat": -33.4591486, "lng": -70.5720172},
                {"lat": -33.4590819, "lng": -70.572223},
                {"lat": -33.4590548, "lng": -70.5722936},
                {"lat": -33.4590401, "lng": -70.5723347},
                {"lat": -33.4590207, "lng": -70.5723954},
                //Limites con ñuñoa
                {"lat": -33.4589875, "lng": -70.572383},
                {"lat": -33.4586816, "lng": -70.5722463},
                {"lat": -33.4585535, "lng": -70.5721967},
                {"lat": -33.4582968, "lng": -70.5721056},
                {"lat": -33.4581687, "lng": -70.5720615},
                {"lat": -33.4580203, "lng": -70.5720082},
                {"lat": -33.4578719, "lng": -70.5719617},
                {"lat": -33.4575786, "lng": -70.5718754},
                {"lat": -33.4572779, "lng": -70.5718078},
                {"lat": -33.4569784, "lng": -70.5717523},
                {"lat": -33.4563961, "lng": -70.5715944},
                {"lat": -33.4561049, "lng": -70.5715235},
                {"lat": -33.4559593, "lng": -70.5714813},
                {"lat": -33.4558093, "lng": -70.5714459},
                {"lat": -33.4546603, "lng": -70.571124},
                {"lat": -33.4540937, "lng": -70.5709443},
                {"lat": -33.4538045, "lng": -70.5708578},
                {"lat": -33.4535075, "lng": -70.5707766},
                {"lat": -33.4532684, "lng": -70.5707479},
                {"lat": -33.4530316, "lng": -70.5707526},
                {"lat": -33.4525469, "lng": -70.5707971},
                {"lat": -33.4520694, "lng": -70.5708616},
                {"lat": -33.4518273, "lng": -70.5709113},
                {"lat": -33.4515851, "lng": -70.5709516},
                {"lat": -33.4506262, "lng": -70.5711538},
                {"lat": -33.4501439, "lng": -70.5712388},
                {"lat": -33.4499034, "lng": -70.5712739},
                {"lat": -33.4496628, "lng": -70.571307},
                {"lat": -33.447744, "lng": -70.5716272},
                {"lat": -33.4467897, "lng": -70.571802},
                {"lat": -33.4463046, "lng": -70.5718887},
                {"lat": -33.4460481, "lng": -70.5719375},
                {"lat": -33.4457575, "lng": -70.5719949},
                {"lat": -33.4421058, "lng": -70.5726021},
                {"lat": -33.4402878, "lng": -70.5729117},
                {"lat": -33.4393748, "lng": -70.5730551},
                {"lat": -33.4389127, "lng": -70.5731422},
                {"lat": -33.4386598, "lng": -70.5731972},
                {"lat": -33.438429, "lng": -70.5732756},
                {"lat": -33.4379606, "lng": -70.5734677},
                {"lat": -33.4377375, "lng": -70.5735665},
                {"lat": -33.4375458, "lng": -70.5737215},
                {"lat": -33.4376207, "lng": -70.5740117},
                {"lat": -33.4370024, "lng": -70.5752565},
                {"lat": -33.4364467, "lng": -70.5765388},
                {"lat": -33.4361075, "lng": -70.5779368},
                {"lat": -33.4354461, "lng": -70.5794151},
                {"lat": -33.4346682, "lng": -70.5808104},
                {"lat": -33.4338321, "lng": -70.5821895},
                {"lat": -33.4333805, "lng": -70.5828697},
                {"lat": -33.4328975, "lng": -70.5835177},
                {"lat": -33.4323273, "lng": -70.5840745},
                {"lat": -33.432013, "lng": -70.5843342},
                {"lat": -33.4318761, "lng": -70.5845069},
            ]
        },
        {
            idService: 119,
            nameService : "La Reina 2 / Hasta Padre Hurtado",
            coordinates: [
                //Limites Padre Hurtado / Las Perdices
                {"lat": -33.4591271, "lng": -70.5294078},
                {"lat": -33.4589944, "lng": -70.5293337},
                {"lat": -33.458861, "lng": -70.5292609},
                {"lat": -33.458963, "lng": -70.5283986},
                {"lat": -33.4581388, "lng": -70.528168},
                {"lat": -33.4579903, "lng": -70.5292087},
                {"lat": -33.4577479, "lng": -70.5292409},
                {"lat": -33.4573791, "lng": -70.529268},
                {"lat": -33.4569852, "lng": -70.5292952},
                {"lat": -33.4566741, "lng": -70.5293264},
                {"lat": -33.4565169, "lng": -70.5293406},
                {"lat": -33.4564349, "lng": -70.5293491},
                {"lat": -33.456363, "lng": -70.5293455},
                {"lat": -33.4562481, "lng": -70.5293079},
                {"lat": -33.4561579, "lng": -70.5292703},
                {"lat": -33.4559539, "lng": -70.5291829},
                {"lat": -33.4557393, "lng": -70.5291224},
                {"lat": -33.4556314, "lng": -70.5291036},
                {"lat": -33.4555202, "lng": -70.5291035},
                {"lat": -33.455449, "lng": -70.5291137},
                {"lat": -33.4553777, "lng": -70.5291273},
                {"lat": -33.4552409, "lng": -70.5291558},
                {"lat": -33.4550842, "lng": -70.5291819},
                {"lat": -33.4550204, "lng": -70.5291808},
                {"lat": -33.4549913, "lng": -70.5291771},
                {"lat": -33.4548956, "lng": -70.5291515},
                {"lat": -33.4547608, "lng": -70.5291084},
                {"lat": -33.4546707, "lng": -70.529064},
                {"lat": -33.4549587, "lng": -70.5274612},
                {"lat": -33.4548848, "lng": -70.5274013},
                {"lat": -33.4547647, "lng": -70.5281789},
                {"lat": -33.4546451, "lng": -70.5290483},
                {"lat": -33.4542821, "lng": -70.5287913},
                {"lat": -33.4540889, "lng": -70.5286983},
                {"lat": -33.4538923, "lng": -70.5286187},
                {"lat": -33.4537191, "lng": -70.5285548},
                {"lat": -33.453284, "lng": -70.5284078},
                {"lat": -33.4531155, "lng": -70.5283518},
                {"lat": -33.4530323, "lng": -70.5283326},
                {"lat": -33.4529491, "lng": -70.5283281},
                {"lat": -33.4526422, "lng": -70.5283216},
                {"lat": -33.4507866, "lng": -70.5265512},
                {"lat": -33.4497084, "lng": -70.5260392},
                {"lat": -33.4481401, "lng": -70.5269943},
                {"lat": -33.4475788, "lng": -70.5285204},
                {"lat": -33.4471729, "lng": -70.5286911},
                {"lat": -33.4470943, "lng": -70.5287284},
                {"lat": -33.4470446, "lng": -70.5287547},
                {"lat": -33.4470157, "lng": -70.5287684},
                {"lat": -33.4468824, "lng": -70.5288373},
                {"lat": -33.4468095, "lng": -70.5288832},
                {"lat": -33.4467776, "lng": -70.5289065},
                {"lat": -33.4467512, "lng": -70.5289391},
                {"lat": -33.4457065, "lng": -70.5285498},
                {"lat": -33.4440676, "lng": -70.5281827},
                {"lat": -33.4439784, "lng": -70.5289852},
                {"lat": -33.4438447, "lng": -70.5294045},
                {"lat": -33.4435375, "lng": -70.530123},
                {"lat": -33.4435124, "lng": -70.5307181},
                {"lat": -33.4433099, "lng": -70.5307686},
                {"lat": -33.4432171, "lng": -70.5308446},
                {"lat": -33.4429864, "lng": -70.5309731},
                {"lat": -33.442684, "lng": -70.5311405},
                {"lat": -33.4424165, "lng": -70.5314484},
                {"lat": -33.4420998, "lng": -70.5319387},
                {"lat": -33.4429784, "lng": -70.5325034},
                {"lat": -33.442908, "lng": -70.532562},
                {"lat": -33.4428388, "lng": -70.5326299},
                {"lat": -33.4428053, "lng": -70.5326625},
                {"lat": -33.442774, "lng": -70.5326965},
                {"lat": -33.4427092, "lng": -70.5327711},
                {"lat": -33.4424568, "lng": -70.5330629},
                {"lat": -33.4423647, "lng": -70.5331673},
                {"lat": -33.4423315, "lng": -70.5332111},
                {"lat": -33.4423017, "lng": -70.5332575},
                {"lat": -33.4422125, "lng": -70.5333939},
                {"lat": -33.4422106, "lng": -70.5334028},
                {"lat": -33.4421819, "lng": -70.5334392},
                {"lat": -33.4421555, "lng": -70.5334769},
                {"lat": -33.4420969, "lng": -70.5335511},
                {"lat": -33.4420003, "lng": -70.5336628},
                {"lat": -33.4419177, "lng": -70.533743},
                {"lat": -33.4418339, "lng": -70.5338165},
                {"lat": -33.4417637, "lng": -70.5338673},
                {"lat": -33.4416868, "lng": -70.533914},
                {"lat": -33.4416126, "lng": -70.5339513},
                {"lat": -33.4415396, "lng": -70.5339819},
                {"lat": -33.4412649, "lng": -70.5340842},
                {"lat": -33.4411792, "lng": -70.5341877},
                {"lat": -33.4414794, "lng": -70.5343689},
                {"lat": -33.4418198, "lng": -70.5345809},
                {"lat": -33.4415432, "lng": -70.5351568},
                {"lat": -33.4413214, "lng": -70.5356434},
                {"lat": -33.4412871, "lng": -70.5357219},
                {"lat": -33.4411778, "lng": -70.5357949},
                {"lat": -33.4410837, "lng": -70.5358311},
                {"lat": -33.4409884, "lng": -70.5358525},
                {"lat": -33.4409199, "lng": -70.5358566},
                {"lat": -33.4408515, "lng": -70.5358539},
                {"lat": -33.4408119, "lng": -70.5358483},
                {"lat": -33.4407735, "lng": -70.5358372},
                {"lat": -33.4407345, "lng": -70.5358262},
                {"lat": -33.4406955, "lng": -70.5358124},
                {"lat": -33.4406366, "lng": -70.5357844},
                {"lat": -33.4405197, "lng": -70.535719},
                {"lat": -33.440404, "lng": -70.5356563},
                {"lat": -33.4403333, "lng": -70.5356175},
                {"lat": -33.4402872, "lng": -70.5355962},
                {"lat": -33.4401935, "lng": -70.5355619},
                {"lat": -33.4400965, "lng": -70.5355397},
                {"lat": -33.4400025, "lng": -70.5355278},
                {"lat": -33.4399075, "lng": -70.5355213},
                {"lat": -33.4398118, "lng": -70.5355202},
                {"lat": -33.4397151, "lng": -70.5355271},
                {"lat": -33.4396438, "lng": -70.5355398},
                {"lat": -33.4395724, "lng": -70.5355538},
                {"lat": -33.4395311, "lng": -70.5355651},
                {"lat": -33.4393966, "lng": -70.5356398},
                {"lat": -33.4392649, "lng": -70.5357118},
                {"lat": -33.4389937, "lng": -70.5358519},
                {"lat": -33.4389217, "lng": -70.535896},
                {"lat": -33.4388508, "lng": -70.5359469},
                {"lat": -33.4387716, "lng": -70.5360124},
                {"lat": -33.4387238, "lng": -70.5360578},
                {"lat": -33.4386483, "lng": -70.5361304},
                {"lat": -33.4385762, "lng": -70.5362096},
                {"lat": -33.4385251, "lng": -70.5362496},
                {"lat": -33.4385031, "lng": -70.5362646},
                {"lat": -33.43847, "lng": -70.5362756},
                {"lat": -33.4382005, "lng": -70.5364089},
                {"lat": -33.4381272, "lng": -70.5364345},
                {"lat": -33.4380517, "lng": -70.5364534},
                {"lat": -33.4379198, "lng": -70.5364751},
                {"lat": -33.4378174, "lng": -70.5365002},
                {"lat": -33.4377328, "lng": -70.5365306},
                {"lat": -33.4375, "lng": -70.5366383},
                {"lat": -33.4371933, "lng": -70.5368096},
                {"lat": -33.4370972, "lng": -70.5368628},
                {"lat": -33.4369998, "lng": -70.536912},
                {"lat": -33.4367873, "lng": -70.5369929},
                {"lat": -33.4365776, "lng": -70.5370617},
                {"lat": -33.4363656, "lng": -70.5371212},
                {"lat": -33.4362038, "lng": -70.537143},
                {"lat": -33.4359248, "lng": -70.5371251},
                {"lat": -33.4358572, "lng": -70.5371242},
                {"lat": -33.4357895, "lng": -70.5371341},
                {"lat": -33.4357554, "lng": -70.5371438},
                {"lat": -33.435669, "lng": -70.5371534},
                {"lat": -33.4356396, "lng": -70.5371616},
                {"lat": -33.4355463, "lng": -70.5372393},
                {"lat": -33.4355103, "lng": -70.5372594},
                {"lat": -33.4354731, "lng": -70.5372715},
                {"lat": -33.4354492, "lng": -70.5372798},
                {"lat": -33.4354252, "lng": -70.5372855},
                {"lat": -33.4353717, "lng": -70.5372887},
                {"lat": -33.4351991, "lng": -70.5372678},
                {"lat": -33.4351234, "lng": -70.537258},
                {"lat": -33.4350476, "lng": -70.537251},
                {"lat": -33.4349137, "lng": -70.5372485},
                {"lat": -33.4347832, "lng": -70.5372461},
                {"lat": -33.4346515, "lng": -70.5372558},
                {"lat": -33.4344622, "lng": -70.5372716},
                {"lat": -33.4343798, "lng": -70.5372827},
                {"lat": -33.4342985, "lng": -70.5373099},
                {"lat": -33.4342449, "lng": -70.5373327},
                {"lat": -33.4341924, "lng": -70.5373636},
                {"lat": -33.4341681, "lng": -70.5373777},
                {"lat": -33.4341439, "lng": -70.5373972},
                {"lat": -33.4340964, "lng": -70.5374348},
                {"lat": -33.4338076, "lng": -70.5376766},
                {"lat": -33.433554, "lng": -70.5378165},
                {"lat": -33.4333981, "lng": -70.5378924},
                {"lat": -33.4333604, "lng": -70.537911},
                {"lat": -33.4332993, "lng": -70.5379469},
                {"lat": -33.4329978, "lng": -70.5381183},
                {"lat": -33.4326852, "lng": -70.5382803},
                {"lat": -33.4325303, "lng": -70.5383633},
                {"lat": -33.4323754, "lng": -70.5384409},
                {"lat": -33.4322205, "lng": -70.5385166},
                {"lat": -33.4320926, "lng": -70.5385779},
                {"lat": -33.4320644, "lng": -70.5385909},
                {"lat": -33.431936, "lng": -70.5386496},
                {"lat": -33.4318054, "lng": -70.5386989},
                {"lat": -33.4315452, "lng": -70.5387989},
                {"lat": -33.4313766, "lng": -70.5388574},
                {"lat": -33.4312917, "lng": -70.5388859},
                {"lat": -33.4312068, "lng": -70.5389078},
                {"lat": -33.430522, "lng": -70.5390437},
                {"lat": -33.4302749, "lng": -70.5390675},
                {"lat": -33.4300546, "lng": -70.5390752},
                {"lat": -33.429698, "lng": -70.5390833},
                {"lat": -33.4295073, "lng": -70.5390869},
                {"lat": -33.4293217, "lng": -70.5390831},
                {"lat": -33.4291451, "lng": -70.5390745},
                {"lat": -33.4290568, "lng": -70.5390656},
                //Limites con las condes
                {"lat": -33.4289634, "lng": -70.5389842},
                {"lat": -33.4290303, "lng": -70.539669},
                {"lat": -33.4290737, "lng": -70.5399515},
                {"lat": -33.4291067, "lng": -70.54042},
                {"lat": -33.4291273, "lng": -70.540631},
                {"lat": -33.4291592, "lng": -70.5408447},
                {"lat": -33.4292252, "lng": -70.5413646},
                {"lat": -33.4292845, "lng": -70.5418845},
                {"lat": -33.4294545, "lng": -70.543336},
                {"lat": -33.4295351, "lng": -70.5440658},
                {"lat": -33.4295764, "lng": -70.54442},
                {"lat": -33.4296156, "lng": -70.5447876},
                {"lat": -33.4299187, "lng": -70.5474345},
                {"lat": -33.429958, "lng": -70.5478193},
                {"lat": -33.4299973, "lng": -70.5482229},
                {"lat": -33.4300366, "lng": -70.5486198},
                {"lat": -33.4300737, "lng": -70.5490114},
                {"lat": -33.4301696, "lng": -70.5498005},
                {"lat": -33.4302198, "lng": -70.5501964},
                {"lat": -33.4302543, "lng": -70.5505896},
                {"lat": -33.4303689, "lng": -70.5515025},
                //Limites con Vicente Perez Rosales
                {"lat": -33.4303636, "lng": -70.5515027},
                {"lat": -33.4304279, "lng": -70.5514989},
                {"lat": -33.4304855, "lng": -70.5514965},
                {"lat": -33.4305996, "lng": -70.5514903},
                {"lat": -33.4308399, "lng": -70.5514841},
                {"lat": -33.4308978, "lng": -70.5514833},
                {"lat": -33.4309804, "lng": -70.5514867},
                {"lat": -33.4310367, "lng": -70.5514893},
                {"lat": -33.431153, "lng": -70.5514913},
                {"lat": -33.4312089, "lng": -70.5514963},
                {"lat": -33.4312982, "lng": -70.5514996},
                {"lat": -33.4313348, "lng": -70.5514995},
                {"lat": -33.431407, "lng": -70.5515057},
                {"lat": -33.4314378, "lng": -70.5515025},
                {"lat": -33.4315418, "lng": -70.5514974},
                {"lat": -33.4316577, "lng": -70.5514883},
                {"lat": -33.4317525, "lng": -70.551479},
                {"lat": -33.4318976, "lng": -70.5514399},
                {"lat": -33.4320402, "lng": -70.5513944},
                {"lat": -33.4321492, "lng": -70.5513382},
                {"lat": -33.4322225, "lng": -70.5512973},
                {"lat": -33.4323259, "lng": -70.5512384},
                {"lat": -33.4323905, "lng": -70.5511971},
                {"lat": -33.432454, "lng": -70.5511492},
                {"lat": -33.4325427, "lng": -70.5510845},
                {"lat": -33.432582, "lng": -70.5510562},
                {"lat": -33.4328529, "lng": -70.5507819},
                {"lat": -33.4331403, "lng": -70.5505237},
                {"lat": -33.4334135, "lng": -70.5503024},
                {"lat": -33.4337729, "lng": -70.5501495},
                {"lat": -33.4339383, "lng": -70.5500969},
                {"lat": -33.4340736, "lng": -70.5500702},
                {"lat": -33.4342073, "lng": -70.5500518},
                {"lat": -33.4343579, "lng": -70.5500514},
                {"lat": -33.4345086, "lng": -70.5500618},
                {"lat": -33.4346626, "lng": -70.5500781},
                {"lat": -33.4348101, "lng": -70.5501071},
                {"lat": -33.4348489, "lng": -70.5501194},
                {"lat": -33.4349056, "lng": -70.5501439},
                {"lat": -33.4350011, "lng": -70.550186},
                {"lat": -33.4350954, "lng": -70.5502274},
                {"lat": -33.4351821, "lng": -70.5502745},
                {"lat": -33.4352554, "lng": -70.5502974},
                {"lat": -33.435337, "lng": -70.5503224},
                {"lat": -33.4354196, "lng": -70.5503426},
                {"lat": -33.4354484, "lng": -70.5503521},
                {"lat": -33.4355586, "lng": -70.5503926},
                {"lat": -33.435778, "lng": -70.5504763},
                {"lat": -33.4359967, "lng": -70.5505593},
                {"lat": -33.4361073, "lng": -70.5506002},
                {"lat": -33.4361446, "lng": -70.5506157},
                {"lat": -33.4361633, "lng": -70.5506241},
                {"lat": -33.4361842, "lng": -70.5506339},
                {"lat": -33.4362146, "lng": -70.5506463},
                {"lat": -33.4362232, "lng": -70.5506172},
                {"lat": -33.4362596, "lng": -70.5504895},
                {"lat": -33.4364729, "lng": -70.5497442},
                {"lat": -33.4365717, "lng": -70.5493675},
                {"lat": -33.4366784, "lng": -70.5489649},
                {"lat": -33.4367069, "lng": -70.5489637},
                {"lat": -33.4365404, "lng": -70.5495888},
                {"lat": -33.436471, "lng": -70.5498575},
                {"lat": -33.4363938, "lng": -70.5501223},
                {"lat": -33.4362406, "lng": -70.5506557},
                {"lat": -33.4364221, "lng": -70.5507284},
                {"lat": -33.4366014, "lng": -70.5508064},
                {"lat": -33.4369611, "lng": -70.5509638},
                {"lat": -33.4376672, "lng": -70.5512728},
                {"lat": -33.4385143, "lng": -70.5516278},
                {"lat": -33.4387261, "lng": -70.5517159},
                {"lat": -33.4389367, "lng": -70.551812},
                {"lat": -33.4393449, "lng": -70.551996},
                {"lat": -33.4400638, "lng": -70.5522861},
                {"lat": -33.440421, "lng": -70.5524392},
                {"lat": -33.4406007, "lng": -70.5525158},
                {"lat": -33.4407163, "lng": -70.5525669},
                {"lat": -33.4407741, "lng": -70.5525932},
                {"lat": -33.4408319, "lng": -70.5526248},
                {"lat": -33.4413489, "lng": -70.5529123},
                {"lat": -33.441411, "lng": -70.5526229},
                {"lat": -33.4413882, "lng": -70.5526084},
                {"lat": -33.4413675, "lng": -70.5525913},
                {"lat": -33.4413463, "lng": -70.5525721},
                {"lat": -33.4413274, "lng": -70.5525462},
                {"lat": -33.4413219, "lng": -70.5524962},
                {"lat": -33.4413247, "lng": -70.5524442},
                {"lat": -33.4413283, "lng": -70.5524172},
                {"lat": -33.4413352, "lng": -70.5523916},
                {"lat": -33.4413439, "lng": -70.5523659},
                {"lat": -33.4413525, "lng": -70.5523456},
                {"lat": -33.4413714, "lng": -70.5522956},
                {"lat": -33.4413835, "lng": -70.5522764},
                {"lat": -33.441399, "lng": -70.5522612},
                {"lat": -33.4414177, "lng": -70.5522563},
                {"lat": -33.4414352, "lng": -70.5522608},
                {"lat": -33.4414714, "lng": -70.5522643},
                {"lat": -33.4414964, "lng": -70.5522763},
                {"lat": -33.4415169, "lng": -70.552295},
                {"lat": -33.4415268, "lng": -70.5523103},
                {"lat": -33.4415348, "lng": -70.5523454},
                {"lat": -33.4415317, "lng": -70.5523806},
                {"lat": -33.4414893, "lng": -70.5524995},
                {"lat": -33.4414665, "lng": -70.5525583},
                {"lat": -33.4414404, "lng": -70.552617},
                {"lat": -33.4413756, "lng": -70.5529252},
                {"lat": -33.441599, "lng": -70.5529911},
                {"lat": -33.4416919, "lng": -70.5530169},
                {"lat": -33.4417849, "lng": -70.5530508},
                {"lat": -33.4419697, "lng": -70.5531173},
                {"lat": -33.4421551, "lng": -70.553179},
                {"lat": -33.4422483, "lng": -70.5532079},
                {"lat": -33.4422722, "lng": -70.5532154},
                {"lat": -33.442296, "lng": -70.5532216},
                {"lat": -33.4423109, "lng": -70.5532276},
                {"lat": -33.4423286, "lng": -70.5532302},
                {"lat": -33.4423704, "lng": -70.5532244},
                {"lat": -33.4423909, "lng": -70.5532173},
                {"lat": -33.4424555, "lng": -70.5531911},
                {"lat": -33.4425801, "lng": -70.5531184},
                {"lat": -33.4428259, "lng": -70.5529875},
                {"lat": -33.442941, "lng": -70.5529562},
                {"lat": -33.4430582, "lng": -70.5529241},
                {"lat": -33.4433757, "lng": -70.5527551},
                {"lat": -33.4439444, "lng": -70.5524531},
                {"lat": -33.4442282, "lng": -70.5523021},
                {"lat": -33.444433, "lng": -70.5521934},
                {"lat": -33.4444006, "lng": -70.5520388},
                {"lat": -33.4446359, "lng": -70.5519304},
                {"lat": -33.4447524, "lng": -70.5518742},
                {"lat": -33.4448123, "lng": -70.5518481},
                {"lat": -33.4448406, "lng": -70.5518317},
                {"lat": -33.4448712, "lng": -70.5518314},
                {"lat": -33.4448945, "lng": -70.5518436},
                {"lat": -33.4449076, "lng": -70.5518633},
                {"lat": -33.4449174, "lng": -70.5518869},
                {"lat": -33.4449347, "lng": -70.5519343},
                {"lat": -33.445254, "lng": -70.5517623},
                {"lat": -33.4455878, "lng": -70.5515843},
                {"lat": -33.4457466, "lng": -70.5515032},
                {"lat": -33.4458366, "lng": -70.5514607},
                {"lat": -33.4459267, "lng": -70.5514248},
                {"lat": -33.4459815, "lng": -70.5514077},
                {"lat": -33.4460363, "lng": -70.5513973},
                {"lat": -33.4460928, "lng": -70.5513903},
                {"lat": -33.4461482, "lng": -70.5513926},
                {"lat": -33.4462236, "lng": -70.5513893},
                {"lat": -33.446293, "lng": -70.5513833},
                {"lat": -33.4469844, "lng": -70.5513559},
                {"lat": -33.4473324, "lng": -70.5513409},
                {"lat": -33.4475058, "lng": -70.5513367},
                {"lat": -33.447684, "lng": -70.5513326},
                {"lat": -33.4477182, "lng": -70.5513334},
                {"lat": -33.4477647, "lng": -70.5513235},
                {"lat": -33.4479027, "lng": -70.551322},
                {"lat": -33.4480071, "lng": -70.5513205},
                {"lat": -33.4481725, "lng": -70.5513153},
                {"lat": -33.448875, "lng": -70.5512955},
                {"lat": -33.4491563, "lng": -70.5513051},
                {"lat": -33.4494364, "lng": -70.5513053},
                {"lat": -33.4498758, "lng": -70.5513057},
                {"lat": -33.450152, "lng": -70.5512912},
                {"lat": -33.450428, "lng": -70.5512876},
                {"lat": -33.4505458, "lng": -70.5512456},
                {"lat": -33.4506697, "lng": -70.551238},
                {"lat": -33.4507321, "lng": -70.5512349},
                {"lat": -33.4508182, "lng": -70.5512306},
                {"lat": -33.4508803, "lng": -70.5512454},
                {"lat": -33.450948, "lng": -70.551248},
                {"lat": -33.4510369, "lng": -70.5512413},
                {"lat": -33.4510702, "lng": -70.5512312},
                {"lat": -33.4510923, "lng": -70.5512171},
                {"lat": -33.4511653, "lng": -70.5511685},
                {"lat": -33.4512383, "lng": -70.5511225},
                {"lat": -33.4512798, "lng": -70.5511119},
                {"lat": -33.4513214, "lng": -70.5510973},
                {"lat": -33.4513788, "lng": -70.5510885},
                {"lat": -33.4514363, "lng": -70.551085},
                {"lat": -33.4515545, "lng": -70.5511035},
                {"lat": -33.4517899, "lng": -70.5511673},
                {"lat": -33.4522438, "lng": -70.5513111},
                {"lat": -33.4527061, "lng": -70.5514367},
                {"lat": -33.4531498, "lng": -70.5515681},
                {"lat": -33.4536024, "lng": -70.5516995},
                {"lat": -33.4537682, "lng": -70.5517445},
                {"lat": -33.4543256, "lng": -70.5518969},
                {"lat": -33.4546056, "lng": -70.5519803},
                {"lat": -33.454927, "lng": -70.5520598},
                {"lat": -33.4552132, "lng": -70.5521561},
                {"lat": -33.4553624, "lng": -70.5522029},
                {"lat": -33.4555139, "lng": -70.5522497},
                {"lat": -33.455818, "lng": -70.5523232},
                {"lat": -33.4558298, "lng": -70.5522719},
                {"lat": -33.4558506, "lng": -70.5522259},
                {"lat": -33.4558633, "lng": -70.5522099},
                {"lat": -33.4558804, "lng": -70.5522007},
                {"lat": -33.4559037, "lng": -70.5521988},
                {"lat": -33.4559258, "lng": -70.5522103},
                {"lat": -33.4560325, "lng": -70.5522483},
                {"lat": -33.4561732, "lng": -70.5522826},
                {"lat": -33.4562461, "lng": -70.5523138},
                {"lat": -33.4563111, "lng": -70.5523665},
                {"lat": -33.4563965, "lng": -70.5525014},
                {"lat": -33.4564597, "lng": -70.5525231},
                {"lat": -33.4566356, "lng": -70.5522094},
                {"lat": -33.4566565, "lng": -70.5521813},
                {"lat": -33.4569298, "lng": -70.5518591},
                {"lat": -33.4571171, "lng": -70.5518838},
                {"lat": -33.4572068, "lng": -70.5519009},
                {"lat": -33.4572965, "lng": -70.55193},
                {"lat": -33.4573779, "lng": -70.5519706},
                {"lat": -33.4574581, "lng": -70.5520259},
                {"lat": -33.4575366, "lng": -70.5520825},
                {"lat": -33.4576117, "lng": -70.5521459},
                {"lat": -33.4576897, "lng": -70.5522052},
                {"lat": -33.4577483, "lng": -70.5522274},
                {"lat": -33.457779, "lng": -70.5522322},
                {"lat": -33.45778, "lng": -70.5523014},
                {"lat": -33.4577805, "lng": -70.5526155},
                {"lat": -33.4577798, "lng": -70.5527753},
                {"lat": -33.4577797, "lng": -70.5528597},
                {"lat": -33.4577811, "lng": -70.552964},
                {"lat": -33.4579761, "lng": -70.5530268},
                {"lat": -33.4580741, "lng": -70.5530589},
                {"lat": -33.458171, "lng": -70.5530882},
                {"lat": -33.4582646, "lng": -70.5530973},
                {"lat": -33.458336, "lng": -70.5530985},
                {"lat": -33.4583626, "lng": -70.553097},
                {"lat": -33.4588015, "lng": -70.5530763},
                {"lat": -33.459114, "lng": -70.5530717},
                {"lat": -33.4591605, "lng": -70.5530689},
                {"lat": -33.4591891, "lng": -70.5530714},
                {"lat": -33.4592495, "lng": -70.5530844},
                {"lat": -33.4593089, "lng": -70.5531041},
                {"lat": -33.4593637, "lng": -70.5531365},
                {"lat": -33.459418, "lng": -70.5531767},
                {"lat": -33.4595853, "lng": -70.55306},
                {"lat": -33.4597934, "lng": -70.5529097},
                {"lat": -33.4602074, "lng": -70.5526144},
                {"lat": -33.4606191, "lng": -70.5523205},
                {"lat": -33.4608244, "lng": -70.5521762},
                {"lat": -33.4610308, "lng": -70.5520373},
                {"lat": -33.4618599, "lng": -70.5514669},
                {"lat": -33.462268, "lng": -70.5511837},
                //Limites con peñalolen
                {"lat": -33.4626881, "lng": -70.55089},
                {"lat": -33.4631371, "lng": -70.5477504},
                {"lat": -33.4631937, "lng": -70.5476846},
                {"lat": -33.4632063, "lng": -70.5476464},
                {"lat": -33.4632042, "lng": -70.5475897},
                {"lat": -33.4632099, "lng": -70.547542},
                {"lat": -33.4632536, "lng": -70.547335},
                {"lat": -33.4633476, "lng": -70.5467432},
                {"lat": -33.463419, "lng": -70.5463049},
                {"lat": -33.4634697, "lng": -70.5459249},
                {"lat": -33.4635205, "lng": -70.5455449},
                {"lat": -33.463607, "lng": -70.5449041},
                {"lat": -33.4636722, "lng": -70.5443968},
                {"lat": -33.4636701, "lng": -70.5442628},
                {"lat": -33.4636703, "lng": -70.54413},
                {"lat": -33.463653, "lng": -70.5440447},
                {"lat": -33.4635898, "lng": -70.5438494},
                {"lat": -33.4634825, "lng": -70.5435822},
                {"lat": -33.4633796, "lng": -70.5433647},
                {"lat": -33.4633216, "lng": -70.5432866},
                {"lat": -33.4632395, "lng": -70.5430847},
                {"lat": -33.4631016, "lng": -70.5427917},
                {"lat": -33.4629827, "lng": -70.542545},
                {"lat": -33.4629288, "lng": -70.5424216},
                {"lat": -33.4628783, "lng": -70.5422996},
                {"lat": -33.4628572, "lng": -70.5421987},
                {"lat": -33.4628485, "lng": -70.5421058},
                {"lat": -33.4628487, "lng": -70.5420135},
                {"lat": -33.4628533, "lng": -70.5419587},
                {"lat": -33.4628545, "lng": -70.5418448},
                {"lat": -33.4628592, "lng": -70.5416996},
                {"lat": -33.4628594, "lng": -70.5415838},
                {"lat": -33.4628707, "lng": -70.5413829},
                {"lat": -33.4628866, "lng": -70.5411914},
                {"lat": -33.4629131, "lng": -70.5408751},
                {"lat": -33.4629625, "lng": -70.5403195},
                {"lat": -33.4629885, "lng": -70.5400979},
                {"lat": -33.4630157, "lng": -70.5399353},
                {"lat": -33.4630555, "lng": -70.5397603},
                {"lat": -33.4630958, "lng": -70.5395914},
                {"lat": -33.4632593, "lng": -70.5391234},
                {"lat": -33.4633109, "lng": -70.5389099},
                {"lat": -33.4634284, "lng": -70.5385168},
                {"lat": -33.4635459, "lng": -70.5380754},
                {"lat": -33.4636904, "lng": -70.537605},
                {"lat": -33.4638263, "lng": -70.5370956},
                {"lat": -33.4639508, "lng": -70.5366618},
                {"lat": -33.464152, "lng": -70.5359927},
                {"lat": -33.4642155, "lng": -70.5358217},
                {"lat": -33.4643294, "lng": -70.5354012},
                {"lat": -33.4644922, "lng": -70.5347989},
                {"lat": -33.4646288, "lng": -70.5342871},
                {"lat": -33.4648337, "lng": -70.5336104},
                {"lat": -33.4650018, "lng": -70.5330068},
                {"lat": -33.46516, "lng": -70.5324031},
                {"lat": -33.4652767, "lng": -70.5320335},
                {"lat": -33.4653848, "lng": -70.5317683},
                {"lat": -33.4654781, "lng": -70.5314814},
                {"lat": -33.4655071, "lng": -70.5313913},
                {"lat": -33.4655316, "lng": -70.5313012},
                {"lat": -33.4652367, "lng": -70.5311614},
                {"lat": -33.4649408, "lng": -70.5310189},
                {"lat": -33.4646387, "lng": -70.5308724},
                {"lat": -33.4644876, "lng": -70.5308039},
                {"lat": -33.4644042, "lng": -70.530771},
                {"lat": -33.464327, "lng": -70.5307427},
                {"lat": -33.4641853, "lng": -70.5306978},
                {"lat": -33.4640392, "lng": -70.5306663},
                {"lat": -33.4638931, "lng": -70.5306475},
                {"lat": -33.4637469, "lng": -70.5306314},
                {"lat": -33.4634552, "lng": -70.5305979},
                {"lat": -33.4632819, "lng": -70.5305832},
                {"lat": -33.4628211, "lng": -70.5305456},
                {"lat": -33.4622681, "lng": -70.530487},
                {"lat": -33.4621189, "lng": -70.5304679},
                {"lat": -33.4619742, "lng": -70.5304382},
                {"lat": -33.461663, "lng": -70.5303727},
                {"lat": -33.4614807, "lng": -70.5303757},
                {"lat": -33.46106, "lng": -70.5302446},
                {"lat": -33.4607081, "lng": -70.530139},
                {"lat": -33.4605299, "lng": -70.5300902},
                {"lat": -33.4602124, "lng": -70.5300019},
                {"lat": -33.4599365, "lng": -70.5299249},
                {"lat": -33.4597023, "lng": -70.5298583},
                {"lat": -33.4593428, "lng": -70.5297038},
                {"lat": -33.4592321, "lng": -70.529655},
                {"lat": -33.4591214, "lng": -70.5296115},
                {"lat": -33.4591186, "lng": -70.5295023},
                {"lat": -33.4591229, "lng": -70.529447},
            ]
        },
        {
            idService: 127,
            nameService : "La Reina 3 Alta / Alvaro Casanova",
            coordinates: [
                //Limites Padre Hurtado / Las Perdices
                {"lat": -33.4591271, "lng": -70.5294078},
                {"lat": -33.4589944, "lng": -70.5293337},
                {"lat": -33.458861, "lng": -70.5292609},
                {"lat": -33.458963, "lng": -70.5283986},
                {"lat": -33.4581388, "lng": -70.528168},
                {"lat": -33.4579903, "lng": -70.5292087},
                {"lat": -33.4577479, "lng": -70.5292409},
                {"lat": -33.4573791, "lng": -70.529268},
                {"lat": -33.4569852, "lng": -70.5292952},
                {"lat": -33.4566741, "lng": -70.5293264},
                {"lat": -33.4565169, "lng": -70.5293406},
                {"lat": -33.4564349, "lng": -70.5293491},
                {"lat": -33.456363, "lng": -70.5293455},
                {"lat": -33.4562481, "lng": -70.5293079},
                {"lat": -33.4561579, "lng": -70.5292703},
                {"lat": -33.4559539, "lng": -70.5291829},
                {"lat": -33.4557393, "lng": -70.5291224},
                {"lat": -33.4556314, "lng": -70.5291036},
                {"lat": -33.4555202, "lng": -70.5291035},
                {"lat": -33.455449, "lng": -70.5291137},
                {"lat": -33.4553777, "lng": -70.5291273},
                {"lat": -33.4552409, "lng": -70.5291558},
                {"lat": -33.4550842, "lng": -70.5291819},
                {"lat": -33.4550204, "lng": -70.5291808},
                {"lat": -33.4549913, "lng": -70.5291771},
                {"lat": -33.4548956, "lng": -70.5291515},
                {"lat": -33.4547608, "lng": -70.5291084},
                {"lat": -33.4546707, "lng": -70.529064},
                {"lat": -33.4549587, "lng": -70.5274612},
                {"lat": -33.4548848, "lng": -70.5274013},
                {"lat": -33.4547647, "lng": -70.5281789},
                {"lat": -33.4546451, "lng": -70.5290483},
                {"lat": -33.4542821, "lng": -70.5287913},
                {"lat": -33.4540889, "lng": -70.5286983},
                {"lat": -33.4538923, "lng": -70.5286187},
                {"lat": -33.4537191, "lng": -70.5285548},
                {"lat": -33.453284, "lng": -70.5284078},
                {"lat": -33.4531155, "lng": -70.5283518},
                {"lat": -33.4530323, "lng": -70.5283326},
                {"lat": -33.4529491, "lng": -70.5283281},
                {"lat": -33.4526422, "lng": -70.5283216},
                {"lat": -33.4507866, "lng": -70.5265512},
                {"lat": -33.4497084, "lng": -70.5260392},
                {"lat": -33.4481401, "lng": -70.5269943},
                {"lat": -33.4475788, "lng": -70.5285204},
                {"lat": -33.4471729, "lng": -70.5286911},
                {"lat": -33.4470943, "lng": -70.5287284},
                {"lat": -33.4470446, "lng": -70.5287547},
                {"lat": -33.4470157, "lng": -70.5287684},
                {"lat": -33.4468824, "lng": -70.5288373},
                {"lat": -33.4468095, "lng": -70.5288832},
                {"lat": -33.4467776, "lng": -70.5289065},
                {"lat": -33.4467512, "lng": -70.5289391},
                {"lat": -33.4457065, "lng": -70.5285498},
                {"lat": -33.4440676, "lng": -70.5281827},
                {"lat": -33.4439784, "lng": -70.5289852},
                {"lat": -33.4438447, "lng": -70.5294045},
                {"lat": -33.4435375, "lng": -70.530123},
                {"lat": -33.4435124, "lng": -70.5307181},
                {"lat": -33.4433099, "lng": -70.5307686},
                {"lat": -33.4432171, "lng": -70.5308446},
                {"lat": -33.4429864, "lng": -70.5309731},
                {"lat": -33.442684, "lng": -70.5311405},
                {"lat": -33.4424165, "lng": -70.5314484},
                {"lat": -33.4420998, "lng": -70.5319387},
                {"lat": -33.4429784, "lng": -70.5325034},
                {"lat": -33.442908, "lng": -70.532562},
                {"lat": -33.4428388, "lng": -70.5326299},
                {"lat": -33.4428053, "lng": -70.5326625},
                {"lat": -33.442774, "lng": -70.5326965},
                {"lat": -33.4427092, "lng": -70.5327711},
                {"lat": -33.4424568, "lng": -70.5330629},
                {"lat": -33.4423647, "lng": -70.5331673},
                {"lat": -33.4423315, "lng": -70.5332111},
                {"lat": -33.4423017, "lng": -70.5332575},
                {"lat": -33.4422125, "lng": -70.5333939},
                {"lat": -33.4422106, "lng": -70.5334028},
                {"lat": -33.4421819, "lng": -70.5334392},
                {"lat": -33.4421555, "lng": -70.5334769},
                {"lat": -33.4420969, "lng": -70.5335511},
                {"lat": -33.4420003, "lng": -70.5336628},
                {"lat": -33.4419177, "lng": -70.533743},
                {"lat": -33.4418339, "lng": -70.5338165},
                {"lat": -33.4417637, "lng": -70.5338673},
                {"lat": -33.4416868, "lng": -70.533914},
                {"lat": -33.4416126, "lng": -70.5339513},
                {"lat": -33.4415396, "lng": -70.5339819},
                {"lat": -33.4412649, "lng": -70.5340842},
                {"lat": -33.4411792, "lng": -70.5341877},
                {"lat": -33.4414794, "lng": -70.5343689},
                {"lat": -33.4418198, "lng": -70.5345809},
                {"lat": -33.4415432, "lng": -70.5351568},
                {"lat": -33.4413214, "lng": -70.5356434},
                {"lat": -33.4412871, "lng": -70.5357219},
                {"lat": -33.4411778, "lng": -70.5357949},
                {"lat": -33.4410837, "lng": -70.5358311},
                {"lat": -33.4409884, "lng": -70.5358525},
                {"lat": -33.4409199, "lng": -70.5358566},
                {"lat": -33.4408515, "lng": -70.5358539},
                {"lat": -33.4408119, "lng": -70.5358483},
                {"lat": -33.4407735, "lng": -70.5358372},
                {"lat": -33.4407345, "lng": -70.5358262},
                {"lat": -33.4406955, "lng": -70.5358124},
                {"lat": -33.4406366, "lng": -70.5357844},
                {"lat": -33.4405197, "lng": -70.535719},
                {"lat": -33.440404, "lng": -70.5356563},
                {"lat": -33.4403333, "lng": -70.5356175},
                {"lat": -33.4402872, "lng": -70.5355962},
                {"lat": -33.4401935, "lng": -70.5355619},
                {"lat": -33.4400965, "lng": -70.5355397},
                {"lat": -33.4400025, "lng": -70.5355278},
                {"lat": -33.4399075, "lng": -70.5355213},
                {"lat": -33.4398118, "lng": -70.5355202},
                {"lat": -33.4397151, "lng": -70.5355271},
                {"lat": -33.4396438, "lng": -70.5355398},
                {"lat": -33.4395724, "lng": -70.5355538},
                {"lat": -33.4395311, "lng": -70.5355651},
                {"lat": -33.4393966, "lng": -70.5356398},
                {"lat": -33.4392649, "lng": -70.5357118},
                {"lat": -33.4389937, "lng": -70.5358519},
                {"lat": -33.4389217, "lng": -70.535896},
                {"lat": -33.4388508, "lng": -70.5359469},
                {"lat": -33.4387716, "lng": -70.5360124},
                {"lat": -33.4387238, "lng": -70.5360578},
                {"lat": -33.4386483, "lng": -70.5361304},
                {"lat": -33.4385762, "lng": -70.5362096},
                {"lat": -33.4385251, "lng": -70.5362496},
                {"lat": -33.4385031, "lng": -70.5362646},
                {"lat": -33.43847, "lng": -70.5362756},
                {"lat": -33.4382005, "lng": -70.5364089},
                {"lat": -33.4381272, "lng": -70.5364345},
                {"lat": -33.4380517, "lng": -70.5364534},
                {"lat": -33.4379198, "lng": -70.5364751},
                {"lat": -33.4378174, "lng": -70.5365002},
                {"lat": -33.4377328, "lng": -70.5365306},
                {"lat": -33.4375, "lng": -70.5366383},
                {"lat": -33.4371933, "lng": -70.5368096},
                {"lat": -33.4370972, "lng": -70.5368628},
                {"lat": -33.4369998, "lng": -70.536912},
                {"lat": -33.4367873, "lng": -70.5369929},
                {"lat": -33.4365776, "lng": -70.5370617},
                {"lat": -33.4363656, "lng": -70.5371212},
                {"lat": -33.4362038, "lng": -70.537143},
                {"lat": -33.4359248, "lng": -70.5371251},
                {"lat": -33.4358572, "lng": -70.5371242},
                {"lat": -33.4357895, "lng": -70.5371341},
                {"lat": -33.4357554, "lng": -70.5371438},
                {"lat": -33.435669, "lng": -70.5371534},
                {"lat": -33.4356396, "lng": -70.5371616},
                {"lat": -33.4355463, "lng": -70.5372393},
                {"lat": -33.4355103, "lng": -70.5372594},
                {"lat": -33.4354731, "lng": -70.5372715},
                {"lat": -33.4354492, "lng": -70.5372798},
                {"lat": -33.4354252, "lng": -70.5372855},
                {"lat": -33.4353717, "lng": -70.5372887},
                {"lat": -33.4351991, "lng": -70.5372678},
                {"lat": -33.4351234, "lng": -70.537258},
                {"lat": -33.4350476, "lng": -70.537251},
                {"lat": -33.4349137, "lng": -70.5372485},
                {"lat": -33.4347832, "lng": -70.5372461},
                {"lat": -33.4346515, "lng": -70.5372558},
                {"lat": -33.4344622, "lng": -70.5372716},
                {"lat": -33.4343798, "lng": -70.5372827},
                {"lat": -33.4342985, "lng": -70.5373099},
                {"lat": -33.4342449, "lng": -70.5373327},
                {"lat": -33.4341924, "lng": -70.5373636},
                {"lat": -33.4341681, "lng": -70.5373777},
                {"lat": -33.4341439, "lng": -70.5373972},
                {"lat": -33.4340964, "lng": -70.5374348},
                {"lat": -33.4338076, "lng": -70.5376766},
                {"lat": -33.433554, "lng": -70.5378165},
                {"lat": -33.4333981, "lng": -70.5378924},
                //Limites las condes
                {"lat":-33.4333625,"lng":-70.5379086},
                {"lat":-33.4333334,"lng":-70.537595},
                {"lat":-33.4332976,"lng":-70.537282},
                {"lat":-33.4331528,"lng":-70.536071},
                {"lat":-33.4329268,"lng":-70.534249},
                {"lat":-33.432816,"lng":-70.533335},
                {"lat":-33.432727,"lng":-70.532579},
                {"lat":-33.4327017,"lng":-70.532431},
                {"lat":-33.43262,"lng":-70.53166},
                {"lat":-33.4325584,"lng":-70.531099},
                {"lat":-33.4325337,"lng":-70.530928},
                {"lat":-33.4324578,"lng":-70.530407},
                {"lat":-33.4324193,"lng":-70.530057},
                {"lat":-33.4323703,"lng":-70.529618},
                {"lat":-33.4323225,"lng":-70.529198},
                {"lat":-33.4322668,"lng":-70.528763},
                {"lat":-33.4322128,"lng":-70.52833},
                {"lat":-33.4321622,"lng":-70.527914},
                {"lat":-33.4321087,"lng":-70.527474},
                {"lat":-33.4320721,"lng":-70.527062},
                {"lat":-33.4320263,"lng":-70.526552},
                {"lat":-33.4320084,"lng":-70.526427},
                {"lat":-33.4319774,"lng":-70.526111},
                {"lat":-33.4319553,"lng":-70.525935},
                {"lat":-33.4318559,"lng":-70.525188},
                {"lat":-33.4318116,"lng":-70.524814},
                {"lat":-33.4317728,"lng":-70.524439},
                {"lat":-33.4317357,"lng":-70.52407},
                {"lat":-33.431703,"lng":-70.523701},
                {"lat":-33.4316223,"lng":-70.523},
                //Limites Valenzuela Puelma
                {"lat": -33.4315931, "lng": -70.52299},
                {"lat": -33.4324426, "lng": -70.520919},
                {"lat": -33.4327571, "lng": -70.519952},
                {"lat": -33.4334488, "lng": -70.5183},
                {"lat": -33.4340045, "lng": -70.517892},
                {"lat": -33.434211, "lng": -70.518373},
                {"lat": -33.4344517, "lng": -70.518291},
                {"lat": -33.434688, "lng": -70.51824},
                {"lat": -33.4359664, "lng": -70.51722},
                {"lat": -33.4363475, "lng": -70.517211},
                {"lat": -33.4367616, "lng": -70.51705},
                {"lat": -33.4370168, "lng": -70.516718},
                {"lat": -33.4371332, "lng": -70.516261},
                {"lat": -33.4379905, "lng": -70.517325},
                {"lat": -33.438418, "lng": -70.517403},
                {"lat": -33.4387537, "lng": -70.517000},
                {"lat": -33.4388522, "lng": -70.513425},
                {"lat": -33.4395327, "lng": -70.513444},
                {"lat": -33.4405751, "lng": -70.515966},
                {"lat": -33.4425665, "lng": -70.514444},
                {"lat": -33.4429378, "lng": -70.514184},
                {"lat": -33.4432329, "lng": -70.514035},
                {"lat": -33.4433671, "lng": -70.5139},
                {"lat": -33.4435012, "lng": -70.513912},
                {"lat": -33.4437684, "lng": -70.513794},
                {"lat": -33.4442194, "lng": -70.513599},
                {"lat": -33.4443601, "lng": -70.513541},
                {"lat": -33.4444393, "lng": -70.51348},
                {"lat": -33.4446564, "lng": -70.513334},
                {"lat": -33.4449531, "lng": -70.513363},
                {"lat": -33.445726, "lng": -70.513513},
                {"lat": -33.4460031, "lng": -70.513552},
                {"lat": -33.4460914, "lng": -70.513542},
                {"lat": -33.4461729, "lng": -70.513486},
                {"lat": -33.4462369, "lng": -70.513410},
                {"lat": -33.4463066, "lng": -70.513354},
                {"lat": -33.4463807, "lng": -70.513319},
                {"lat": -33.4464559, "lng": -70.513359},
                {"lat": -33.4465376, "lng": -70.51342},
                {"lat": -33.4466153, "lng": -70.513504},
                {"lat": -33.4466874, "lng": -70.513587},
                {"lat": -33.4471875, "lng": -70.513808},
                {"lat": -33.4486929, "lng": -70.512190},
                {"lat": -33.4507938, "lng": -70.510744},
                {"lat": -33.4503164, "lng": -70.516036},
                {"lat": -33.4510448, "lng": -70.516706},
                {"lat": -33.4537827, "lng": -70.517206},
                {"lat": -33.4536506, "lng": -70.515407},
                {"lat": -33.4542066, "lng": -70.515406},
                {"lat": -33.4541817, "lng": -70.514774},
                {"lat": -33.4550631, "lng": -70.51468},
                {"lat": -33.4550763, "lng": -70.514406},
                {"lat": -33.4560066, "lng": -70.514938},
                {"lat": -33.4560234, "lng": -70.515263},
                {"lat": -33.4559776, "lng": -70.515923},
                //Limites con peñalolen
                {"lat": -33.4593521, "lng": -70.515412},
                {"lat": -33.4597867, "lng": -70.516384},
                {"lat": -33.4597057, "lng": -70.516798},
                {"lat": -33.4598377, "lng": -70.51720},
                {"lat": -33.4598828, "lng": -70.517428},
                {"lat": -33.4598987, "lng": -70.517433},
                {"lat": -33.4598988, "lng": -70.517448},
                {"lat": -33.4598093, "lng": -70.517594},
                {"lat": -33.4597567, "lng": -70.51773},
                {"lat": -33.4597312, "lng": -70.517808},
                {"lat": -33.4597125, "lng": -70.51787},
                {"lat": -33.4596441, "lng": -70.518048},
                {"lat": -33.4595658, "lng": -70.518299},
                {"lat": -33.4595232, "lng": -70.518461},
                {"lat": -33.4595083, "lng": -70.518528},
                {"lat": -33.4594946, "lng": -70.518610},
                {"lat": -33.4594616, "lng": -70.518754},
                {"lat": -33.4594504, "lng": -70.518838},
                {"lat": -33.4594207, "lng": -70.519068},
                {"lat": -33.4594031, "lng": -70.519133},
                {"lat": -33.4593671, "lng": -70.5192},
                {"lat": -33.4593579, "lng": -70.519284},
                {"lat": -33.4593532, "lng": -70.519352},
                {"lat": -33.4593617, "lng": -70.519430},
                {"lat": -33.4593792, "lng": -70.519505},
                {"lat": -33.459413, "lng": -70.519576},
                {"lat": -33.4594646, "lng": -70.519657},
                {"lat": -33.4594907, "lng": -70.519713},
                {"lat": -33.4595129, "lng": -70.519877},
                {"lat": -33.4595105, "lng": -70.520080},
                {"lat": -33.4595055, "lng": -70.520161},
                {"lat": -33.4594961, "lng": -70.520249},
                {"lat": -33.4594845, "lng": -70.520340},
                {"lat": -33.4594672, "lng": -70.52041},
                {"lat": -33.4594213, "lng": -70.520574},
                {"lat": -33.4593775, "lng": -70.520735},
                {"lat": -33.4593562, "lng": -70.520843},
                {"lat": -33.4593393, "lng": -70.520951},
                {"lat": -33.4593332, "lng": -70.521112},
                {"lat": -33.4593337, "lng": -70.521272},
                {"lat": -33.4594473, "lng": -70.521328},
                {"lat": -33.4596174, "lng": -70.521400},
                {"lat": -33.4596545, "lng": -70.521408},
                {"lat": -33.4596345, "lng": -70.521725},
                {"lat": -33.4596235, "lng": -70.521923},
                {"lat": -33.459618, "lng": -70.522006},
                {"lat": -33.4595949, "lng": -70.522289},
                {"lat": -33.4595558, "lng": -70.523009},
                {"lat": -33.4595068, "lng": -70.523890},
                {"lat": -33.4594651, "lng": -70.524768},
                {"lat": -33.4594626, "lng": -70.525019},
                {"lat": -33.4594588, "lng": -70.525523},
                {"lat": -33.459394, "lng": -70.52644},
                {"lat": -33.4592994, "lng": -70.527024},
                {"lat": -33.4592607, "lng": -70.527537},
                {"lat": -33.4592136, "lng": -70.528191},
                {"lat": -33.4591586, "lng": -70.528865},
                {"lat": -33.4591456, "lng": -70.529136},
                {"lat": -33.4591375, "lng": -70.5292722},
            ]
        }
    ]
};

export default LaReina;
