//ReporteCierres
import React from 'react';
import { Redirect } from 'react-router-dom';
import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import {
	checkSession,
	verifyAccess,
	setInitDate,
	setEndDate,
	setDateFromCalIni,
	setDateFromCalEnd,
	setDateFormat,
} from './components/genscripts';

import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';

import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'universal-cookie';
import axios from 'axios';

import './reportes.css';

import ReactExport from 'react-export-excel';
//import { tr } from "date-fns/locale";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//exportar a excel https://www.npmjs.com/package/react-export-excel -> ver ejemplos

const thispage = 'reportecierres';
const headTitle = 'Cierres de caja';

class ReporteCierres extends React.Component {
	constructor() {
		super();
		this.state = {
			startDate: new Date(),
			finDate: new Date(),
			iniciodate: setInitDate(),
			enddate: setEndDate(),
			ticketnrochange: 0,
			newticketstate: '',
			modalIsOpen: false,
			tiporeporte: 'reportegeneral',
			listaConductores: [],
			rutsupervisor: '',
			cambioestadoreport: false,
			columnDefs: [
				{
					headerName: 'Id sesión caja',
					field: 'idcajasiniciofin',
					filter: true,
					width: 152,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Nombre Turno',
					field: 'nombreturno',
					filter: true,
					width: 102,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Vendedor',
					field: 'vendedor',
					filter: true,
					width: 102,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Rut Vendedor',
					field: 'rutusuario',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Rut Supervisor',
					field: 'rutsupervisor',
					width: 110,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Fecha Apertura',
					field: 'iniciodate',
					width: 230,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto CLP apertura',
					field: 'iniciomontopesos',
					width: 250,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto USD apertura',
					field: 'iniciomontodolar',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto Euro apertura',
					field: 'iniciomontoeuro',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Fecha de cierre',
					field: 'findate',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto ingresado CLP',
					field: 'finmontopesos',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto ingresado USD',
					field: 'finmontodolar',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto ingresado EU',
					field: 'finmontoeuro',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto ingresado DB',
					field: 'finmontodebito',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto ingresado TC',
					field: 'finmontocredito',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Caja abierta?',
					field: 'isopen',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Cuadra?',
					field: 'cuadratura',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Cálculo de ventas CLP',
					field: 'resumemontopesos',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Cálculo de ventas USD',
					field: 'resumemontodolar',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Cálculo de ventas EU',
					field: 'resumemontoeuro',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Cálculo de ventas DB',
					field: 'resumemontodebito',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Cálculo de ventas TC',
					field: 'resumemontocredito',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Comentario vendedor',
					field: 'iniciomontoeuro',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Revisión Supervisor?',
					field: 'supervisorcheck',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Fecha rev. supervisor',
					field: 'checksupervisordate',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Comentario supervisor',
					field: 'comentariosupervisor',
					width: 180,
					suppressSizeToFit: true,
				},
			],

			defaultColDef: { resizable: true },
			rowSelection: 'single',
			rowData: [],
			context: { componentParent: this },
			frameworkComponents: {
				getData: this.refreshResults,
			},
		};
		this.onChangeTimeIni = this.onChangeTimeIni.bind(this);
		this.onChangeTimeEnd = this.onChangeTimeEnd.bind(this);
		this.getGenReport = this.getGenReport.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleChangeTipoReporte = this.handleChangeTipoReporte.bind(this);
		this.getSupervisor = this.getSupervisor.bind(this);
		this.handleChangeSelectSupervisor = this.handleChangeSelectSupervisor.bind(
			this
		);
		this.getReportByUser = this.getReportByUser.bind(this);
		this.onclickExcelDownload = this.onclickExcelDownload.bind(this);
	}

	componentDidMount() {}

	closeModal() {
		document.getElementById('msgErrorPetAnul').innerHTML = '';
		this.setState({ modalIsOpen: false });
	}

	handleChange = (date) => {
		var iniDate = setDateFromCalIni(date);
		this.setState({
			startDate: date,
			iniciodate: iniDate,
		});
		console.log(iniDate);
	};

	handleChangeEnd = (date) => {
		var endDate = setDateFromCalEnd(date);
		this.setState({
			finDate: date,
			enddate: endDate,
		});
		console.log(endDate);
	};

	onChangeTimeIni(eventoini) {
		var fechahoraini =
			document.getElementById('dateini').value.replace(/\//g, '-') +
			' ' +
			eventoini.target.value;
		this.setState({
			iniciodate: fechahoraini,
		});
		console.log(fechahoraini);
	}

	onChangeTimeEnd(eventoend) {
		var fechahoraini =
			document.getElementById('dateend').value.replace(/\//g, '-') +
			' ' +
			eventoend.target.value;
		console.log(eventoend.target.value);
		this.setState({
			enddate: fechahoraini,
		});
		console.log(fechahoraini);
	}

	handleChangeTipoReporte(event) {
		this.setState({
			tiporeporte: event.target.value,
		});

		if (event.target.value === 'reporteusuario') {
			this.getSupervisor();
			document.getElementById('vendedoresListContainer').style.display =
				'block';
		} else {
			document.getElementById('vendedoresListContainer').style.display = 'none';
		}
	}

	handleChangeSelectSupervisor(event) {
		this.setState({ rutsupervisor: event.target.value });
	}

	refreshResults() {
		if (this.state.tiporeporte === 'reportegeneral') {
			this.getGenReport();
		} else {
			this.getReportByUser();
		}
	}

	getSupervisor() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const ListSellers = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getListSupervisors`,
			headers: headers,
		})
			.then((response) => {
				ListSellers.push(<option value="0">Selecciona un supervisor</option>);
				for (const supervisor of response.data) {
					ListSellers.push(
						<option value={supervisor.rut}>
							{supervisor.nombre + ' ' + supervisor.apellido}
						</option>
					);
				}
				this.setState({ listaConductores: ListSellers });
			})
			.catch((error) => {
				console.log('getListConductores: ' + error);
			});
	}

	getGenReport() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const dataCajas = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getBoxesBetweenDates`,
			data: JSON.stringify({
				fechaini: this.state.iniciodate,
				fechaend: this.state.enddate,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				for (const caja of response.data) {
					/*
          var dateini = setDateFormat(
            new Date(caja[6].toString().replace("+0000", "Z"))
          );
          */
					var dateini = caja[6]
						.toString()
						.replace('+0000', 'Z')
						.replace('T', ' ')
						.replace('.000Z', '');

					/*
          var datefin = setDateFormat(
            new Date(caja[10].toString().replace("+0000", "Z"))
          );
          */
					var datefin = caja[10]
						.toString()
						.replace('+0000', 'Z')
						.replace('T', ' ')
						.replace('.000Z', '');

					/*
          var datecheck = setDateFormat(
            new Date(caja[25].toString().replace("+0000", "Z"))
          );
          */
					var datecheck = caja[25]
						.toString()
						.replace('+0000', 'Z')
						.replace('T', ' ')
						.replace('.000Z', '');
					/*
          console.log(
            "dateini salida: " +
              caja[6]
                .toString()
                .replace("+0000", "Z")
                .replace("T", " ")
                .replace(".000Z", "")
          );
          */
					dataCajas.push({
						idcajasiniciofin: caja[0],
						nombreturno: caja[1],
						vendedor: caja[2] + ' ' + caja[3],
						rutusuario: caja[4],
						rutsupervisor: caja[5],
						iniciodate:
							dateini === '1900-01-01 01:00:00' ? 'Sin fecha' : dateini,
						iniciomontopesos: caja[7],
						iniciomontodolar: caja[8],
						iniciomontoeuro: caja[9],
						findate: datefin === '1900-01-01 01:00:00' ? 'Sin fecha' : datefin,
						finmontopesos: caja[11],
						finmontodolar: caja[12],
						finmontoeuro: caja[13],
						finmontodebito: caja[14],
						finmontocredito: caja[15],
						isopen: caja[16] ? 'Si' : 'No',
						cuadratura: caja[17] ? 'Si' : 'No',
						resumemontopesos: caja[18],
						resumemontodolar: caja[19],
						resumemontoeuro: caja[20],
						resumemontodebito: caja[21],
						resumemontocredito: caja[22],
						comantariocuadratura: caja[23],
						supervisorcheck: caja[24] ? 'Si' : 'No',
						checksupervisordate:
							datecheck === '1900-01-01 00:00:00' ? '' : datecheck,
						comentariosupervisor: caja[26],
					});
				}
				this.setState({ rowData: dataCajas });
				document.getElementById('exportExcelNomina').style.display = 'block';
				document.getElementById('tableCajasReportContainer').style.display =
					'block';
			})
			.catch((error) => {
				console.log('getBoxesBetweenDates: ' + error);
			});
	}

	getReportByUser() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const dataCajas = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getBoxesBetweenDatesRutSup`,
			data: JSON.stringify({
				fechaini: this.state.iniciodate,
				fechaend: this.state.enddate,
				rutsupervisor: this.state.rutsupervisor,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				for (const caja of response.data) {
					var dateini = setDateFormat(
						new Date(caja[6].toString().replace('+0000', 'Z'))
					);

					var datefin = setDateFormat(
						new Date(caja[10].toString().replace('+0000', 'Z'))
					);

					var datecheck = setDateFormat(
						new Date(caja[25].toString().replace('+0000', 'Z'))
					);

					dataCajas.push({
						idcajasiniciofin: caja[0],
						nombreturno: caja[1],
						vendedor: caja[2] + ' ' + caja[3],
						rutusuario: caja[4],
						rutsupervisor: caja[5],
						iniciodate: dateini === '1900-01-01 00:00:00' ? '' : dateini,
						iniciomontopesos: caja[7],
						iniciomontodolar: caja[8],
						iniciomontoeuro: caja[9],
						findate: datefin === '1900-01-01 00:00:00' ? '' : datefin,
						finmontopesos: caja[11],
						finmontodolar: caja[12],
						finmontoeuro: caja[13],
						finmontodebito: caja[14],
						finmontocredito: caja[15],
						isopen: caja[16] ? 'Si' : 'No',
						cuadratura: caja[17] ? 'Si' : 'No',
						resumemontopesos: caja[18],
						resumemontodolar: caja[19],
						resumemontoeuro: caja[20],
						resumemontodebito: caja[21],
						resumemontocredito: caja[22],
						comantariocuadratura: caja[23],
						supervisorcheck: caja[24] ? 'Si' : 'No',
						checksupervisordate:
							datecheck === '1900-01-01 00:00:00' ? '' : datecheck,
						comentariosupervisor: caja[26],
					});
				}
				this.setState({ rowData: dataCajas });
				document.getElementById('exportExcelNomina').style.display = 'block';
				document.getElementById('tableCajasReportContainer').style.display =
					'block';
			})
			.catch((error) => {
				console.log('getBoxesBetweenDatesRutSup: ' + error);
			});
	}

	onclickExcelDownload() {
		setTimeout(() => {
			document.getElementById('exportExcelNomina').style.display = 'none';
			document.getElementById('tableCajasReportContainer').style.display =
				'none';
			document.getElementById('timeini').value = '08:00:00';
			document.getElementById('timeend').value = '20:00:00';
			this.setState({
				startDate: new Date(),
				finDate: new Date(),
				iniciodate: setInitDate(),
				enddate: setEndDate(),
			});
		}, 2000);
	}

	onGridReady = (params) => {};

	render() {
		const session = checkSession();

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-body">
													<h5 className="card-title">Tipos de reporte:</h5>
													<div className="reportsTypeContainer">
														<fieldset className="form-group">
															<div className="row">
																<div className="reportsTypeContainer">
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="tipoReporteRadio"
																			id="reporteGeneral"
																			value="reportegeneral"
																			onChange={this.handleChangeTipoReporte}
																		/>
																		<label
																			className="form-check-label"
																			for="reporteGeneral"
																		>
																			Reporte general
																		</label>
																	</div>
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="tipoReporteRadio"
																			id="reporteusuario"
																			value="reporteusuario"
																			onChange={this.handleChangeTipoReporte}
																		/>
																		<label
																			className="form-check-label"
																			for="reporteusuario"
																		>
																			Reporte por supervisor
																		</label>
																	</div>
																</div>
															</div>
														</fieldset>
													</div>
													<div
														id="vendedoresListContainer"
														className="form-group col-md-12"
													>
														<div className="col-md-4">
															<label for="inputSselectVendedorestate">
																Conductores:
															</label>
															<select
																onChange={this.handleChangeSelectSupervisor}
																id="selectVendedores"
																className="form-control"
															>
																{this.state.listaConductores}
															</select>
														</div>
													</div>
													<div
														className="form-group pull-left"
														style={{ marginRight: 20, marginTop: 12 }}
													>
														<span
															className="pull-left"
															style={{ lineHeight: 3, marginRight: 10 }}
														>
															Inicio de turno:
														</span>
														<div style={{ float: 'left', marginRight: 10 }}>
															<DatePicker
																id="dateini"
																selected={this.state.startDate}
																onChange={this.handleChange}
																className="form-control"
																dateFormat="yyyy/MM/dd"
																locale={es}
															/>
														</div>
														<div style={{ float: 'left' }}>
															<select
																id="timeini"
																className="form-control"
																style={{ width: 100, height: 45 }}
																onChange={this.onChangeTimeIni}
															>
																<option>08:00:00</option>
																<option>20:00:00</option>
															</select>
														</div>
													</div>

													<div
														className="form-group pull-left"
														style={{ marginTop: 12 }}
													>
														<span
															className="pull-left"
															style={{ lineHeight: 3, marginRight: 10 }}
														>
															fin de turno:
														</span>
														<div style={{ float: 'left', marginRight: 10 }}>
															<DatePicker
																id="dateend"
																selected={this.state.finDate}
																onChange={this.handleChangeEnd}
																className="form-control"
																dateFormat="yyyy/MM/dd"
																locale={es}
															/>
														</div>
														<div style={{ float: 'left' }}>
															<select
																id="timeend"
																className="form-control"
																style={{ width: 100, height: 45 }}
																onChange={this.onChangeTimeEnd}
															>
																<option>20:00:00</option>
																<option>08:00:00</option>
															</select>
														</div>
													</div>

													<div
														className="form-group pull-left"
														style={{ marginLeft: 30 }}
													>
														{this.state.tiporeporte === 'reporteusuario' ? (
															<button
																className="btn btn-inactivo btn-round"
																onClick={this.getReportByUser}
															>
																Ver
															</button>
														) : (
															<button
																className="btn btn-inactivo btn-round"
																onClick={this.getGenReport}
															>
																Ver
															</button>
														)}
													</div>

													<div
														className="exportExcelContainerNominaTable"
														id="exportExcelNomina"
													>
														<ExcelFile
															element={
																<button
																	onClick={this.onclickExcelDownload}
																	className="btn btn-inactivo btn-round"
																>
																	Exportar a Excel
																</button>
															}
															filename="ReporteCajas"
														>
															<ExcelSheet
																data={this.state.rowData}
																name="ReporteCajas"
															>
																<ExcelColumn
																	label="IDCaja"
																	value="idcajasiniciofin"
																/>
																<ExcelColumn
																	label="Turno"
																	value="nombreturno"
																/>
																<ExcelColumn
																	label="Vendedor"
																	value="vendedor"
																/>
																<ExcelColumn
																	label="Rut Vendedor"
																	value="rutusuario"
																/>

																<ExcelColumn
																	label="Rut Supervisor"
																	value="rutsupervisor"
																/>
																<ExcelColumn
																	label="Fecha apertura caja"
																	value="iniciodate"
																/>
																<ExcelColumn
																	label="Monto CLP apertura"
																	value="iniciomontopesos"
																/>
																<ExcelColumn
																	label="Monto USD apertura"
																	value="iniciomontodolar"
																/>
																<ExcelColumn
																	label="Monto EU apertura"
																	value="iniciomontoeuro"
																/>
																<ExcelColumn
																	label="Fecha de cierre"
																	value="findate"
																/>
																<ExcelColumn
																	label="Monto ingresado CLP"
																	value="finmontopesos"
																/>
																<ExcelColumn
																	label="Monto ingresado USD"
																	value="finmontodolar"
																/>
																<ExcelColumn
																	label="Monto ingresado EU"
																	value="finmontoeuro"
																/>
																<ExcelColumn
																	label="Monto ingresado DB"
																	value="finmontodebito"
																/>
																<ExcelColumn
																	label="Monto ingresado TC"
																	value="finmontocredito"
																/>
																<ExcelColumn
																	label="Caja abierta?"
																	value="isopen"
																/>
																<ExcelColumn
																	label="Cuadra?"
																	value="cuadratura"
																/>
																<ExcelColumn
																	label="Cálculo de ventas CLP"
																	value="resumemontopesos"
																/>
																<ExcelColumn
																	label="Cálculo de ventas USD"
																	value="resumemontodolar"
																/>
																<ExcelColumn
																	label="Cálculo de ventas EU"
																	value="resumemontoeuro"
																/>
																<ExcelColumn
																	label="Cálculo de ventas DB"
																	value="resumemontodebito"
																/>
																<ExcelColumn
																	label="Cálculo de ventas TC"
																	value="resumemontocredito"
																/>
																<ExcelColumn
																	label="Comentario supervisor"
																	value="comantariocuadratura"
																/>
																<ExcelColumn
																	label="Rev. Supervisor"
																	value="supervisorcheck"
																/>
																<ExcelColumn
																	label="Fecha Rev. Supervisor"
																	value="checksupervisordate"
																/>
																<ExcelColumn
																	label="Comentario Rev. Supervisor"
																	value="comentariosupervisor"
																/>
															</ExcelSheet>
														</ExcelFile>
													</div>
													<div
														className="tableCajasReport"
														id="tableCajasReportContainer"
													>
														<h4>Cajas:</h4>
														<div
															className="ag-theme-balham tableContainer"
															style={{
																height: '800px',
																width: '1350px',
																margin: '20px auto 20px auto',
															}}
														>
															<AgGridReact
																onGridReady={this.onGridReady}
																rowSelection={this.state.rowSelection}
																frameworkComponents={
																	this.state.frameworkComponents
																}
																paginationAutoPageSize={true}
																pagination={true}
																columnDefs={this.state.columnDefs}
																rowData={this.state.rowData}
															></AgGridReact>
														</div>
													</div>
												</div>

												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i>{' '}
														Generar nómina de pagos
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

export default ReporteCierres;
