import React from 'react';

import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import error from '../../images/error.png';
import success from '../../images/success.png';

import './BtnUser.css';

class BtnUpdatePass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			idusuario: this.props.data.idusuario,
			newpass: '',
			modalIsOpen: false,
			updatePassAction: false,
		};
		this.cambioPassModal = this.cambioPassModal.bind(this);
		this.handleNewPass = this.handleNewPass.bind(this);
		this.updatePassword = this.updatePassword.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	cambioPassModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	handleNewPass(event) {
		this.setState({ newpass: event.target.value });
	}

	updatePassword() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updatePassword`,
			data: JSON.stringify({
				idusuario: this.state.idusuario,
				clave: this.state.newpass,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				if (parseInt(response.data) > 0) {
					this.setState({ updatePassAction: true });
				} else {
					this.setState({ updatePassAction: false });
				}
				document.getElementById('UpdatePassUserPaso1').style.display = 'none';
				document.getElementById('UpdatePassUserPasoFinal').style.display =
					'block';
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		return (
			<div>
				<button className="btnClass" onClick={this.cambioPassModal}>
					Cambiar clave
				</button>
				{/* MODAL */}

				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Imprimir ticket"
				>
					<div id="UpdatePassUserPaso1">
						<div className="headermodal">
							<button onClick={this.closeModal}>
								<i class="nc-icon nc-simple-remove"></i>
							</button>
						</div>
						<div className="containerModalUsuarios">
							<h4>Ingresa la nueva clave:</h4>
							<br />
							<div class="form-group">
								<label for="NewPassModal">Nueva clave:</label>
								<input
									type="text"
									class="form-control"
									id="NewPassModal"
									onChange={this.handleNewPass}
									value={this.state.newpass}
								/>
							</div>
							<div className="containerButtonsModalUsuarios">
								<input
									type="button"
									className="btn btn-inactivo btn-round"
									onClick={this.updatePassword}
									value="Guardar"
								/>
							</div>
						</div>
					</div>
					<div id="UpdatePassUserPasoFinal">
						{this.state.updatePassAction ? (
							<div className="cuadraturaResponse">
								<img src={success} alt="success" />
								<p>Usuario desactivado con éxito.</p>
							</div>
						) : (
							<div className="cuadraturaResponse">
								<img src={error} alt="error" />
								<p>Error al desactivar el usuario.</p>
							</div>
						)}
					</div>
				</Modal>

				{/* FIN MODAL */}
			</div>
		);
	}
}

Modal.setAppElement('body');

export default BtnUpdatePass;
