const Quilpue = {
    commune: "Quilpue",
    polygons: [
        {
            idService: 113,
            nameService: "V.Alemana / Quilpue/ Olmué",
            coordinates: [
                {"lat":-33.02571642676358, "lng":-71.43205938362463},
                {"lat":-33.030508694345464, "lng":-71.43144696083985},
                {"lat":-33.029995249568714, "lng":-71.42807863552353},
                {"lat":-33.03521513206824, "lng":-71.42522066252786},
                {"lat":-33.03453057493142, "lng":-71.42246475999632},
                {"lat":-33.03572854643053, "lng":-71.41848401189522},
                {"lat":-33.03341815824075, "lng":-71.41817780050282},
                {"lat":-33.032562443537515, "lng":-71.41531982750715},
                {"lat":-33.03102213613075, "lng":-71.41358462961692},
                {"lat":-33.030936562707616, "lng":-71.41042044522887},
                {"lat":-33.02931065187964, "lng":-71.41082872708539},
                {"lat":-33.02922507679458, "lng":-71.40858317687452},
                {"lat":-33.02828374537467, "lng":-71.40858317687452},
                {"lat":-33.028369321373766, "lng":-71.40715419037667},
                {"lat":-33.02725682690419, "lng":-71.4072562608408},
                {"lat":-33.027513557643566, "lng":-71.40572520387883},
                {"lat":-33.02922507679458, "lng":-71.40174445577773},
                {"lat":-33.047450693617115, "lng":-71.40215273763427},
                {"lat":-33.047621808300654, "lng":-71.40051961020816},
                {"lat":-33.06567253944033, "lng":-71.40245894902665},
                {"lat":-33.06558699968059, "lng":-71.40939974058756},
                {"lat":-33.06225088420141, "lng":-71.42246475999632},
                {"lat":-33.06413281104447, "lng":-71.42256683046045},
                {"lat":-33.06729777907825, "lng":-71.41797365957457},
                {"lat":-33.06473159751045, "lng":-71.41776951864631},
                {"lat":-33.065244839810234, "lng":-71.41429912286584},
                {"lat":-33.06832423075326, "lng":-71.41399291147346},
                {"lat":-33.06943620655676, "lng":-71.41123700894191},
                {"lat":-33.075252466383574, "lng":-71.41297220683214},
                {"lat":-33.07662094222922, "lng":-71.41623846168434},
                {"lat":-33.07610776628176, "lng":-71.41858608235935},
                {"lat":-33.07730517216912, "lng":-71.41960678700066},
                {"lat":-33.076792000213146, "lng":-71.42256683046045},
                {"lat":-33.07816045210987, "lng":-71.42297511231698},
                {"lat":-33.0749958747926, "lng":-71.42726207181047},
                {"lat":-33.07319971270124, "lng":-71.4325697359453},
                {"lat":-33.07328524506093, "lng":-71.43512149754856},
                {"lat":-33.07277204965556, "lng":-71.43675462497465},
                {"lat":-33.07114691112365, "lng":-71.43471321569203},
                {"lat":-33.07029156299759, "lng":-71.42848691738006},
                {"lat":-33.06866637865097, "lng":-71.43001797434202},
                {"lat":-33.0677254687312, "lng":-71.4293034810931},
                {"lat":-33.06584361871038, "lng":-71.4325697359453},
                {"lat":-33.06721224089822, "lng":-71.43675462497465},
                {"lat":-33.0700349569384, "lng":-71.4375711886877},
                {"lat":-33.07046263328798, "lng":-71.44022502075511},
                {"lat":-33.069949421419025, "lng":-71.44185814818121},
                {"lat":-33.063020768228085, "lng":-71.44073537307577},
                {"lat":-33.06344847866641, "lng":-71.45114656041713},
                {"lat":-33.06284968347079, "lng":-71.4536983220204},
                {"lat":-33.05891464227019, "lng":-71.45288175830736},
                {"lat":-33.056262667458384, "lng":-71.45992462033239},
                {"lat":-33.05917128075415, "lng":-71.46135360683022},
                {"lat":-33.059599009898115, "lng":-71.46538539016339},
                {"lat":-33.05626266745838, "lng":-71.47217307602808},
                {"lat":-33.05455167357052, "lng":-71.46921303256829},
                {"lat":-33.052669541888996, "lng":-71.47023373720961},
                {"lat":-33.05065903860661, "lng":-71.46921303256829},
                {"lat":-33.04933293684968, "lng":-71.47273446358082},
                {"lat":-33.04988904646938, "lng":-71.47533726041617},
                {"lat":-33.047921258196794, "lng":-71.47533726041617},
                {"lat":-33.047835701187495, "lng":-71.47691935261018},
                {"lat":-33.04295881426584, "lng":-71.47727659923464},
                {"lat":-33.04287325227854, "lng":-71.48406428529242},
                {"lat":-33.03671258210531, "lng":-71.48559534225438},
                {"lat":-33.03555740848485, "lng":-71.48498291946959},
                {"lat":-33.033503729096694, "lng":-71.48248219309839},
                {"lat":-33.03457335975097, "lng":-71.47957318487065},
                {"lat":-33.034145509047406, "lng":-71.47794005744456},
                {"lat":-33.03410477221691, "lng":-71.47258580888703},
                {"lat":-33.03795535553647, "lng":-71.47360651352835},
                {"lat":-33.03911049771623, "lng":-71.47651552175608},
                {"lat":-33.04009449577449, "lng":-71.47396376015281},
                {"lat":-33.04047953550103, "lng":-71.47085061099682},
                {"lat":-33.03881101786473, "lng":-71.46993197681964},
                {"lat":-33.03932441127256, "lng":-71.46891127217832},
                {"lat":-33.041616159190156, "lng":-71.46468497860624},
                {"lat":-33.041400309832035, "lng":-71.4622817193593},
                {"lat":-33.04276734683393, "lng":-71.45756103155279},
                {"lat":-33.04147225967684, "lng":-71.45678855536626},
                {"lat":-33.041184459945015, "lng":-71.45301200512107},
                {"lat":-33.041400309832035, "lng":-71.4506945765615},
                {"lat":-33.03952959323969, "lng":-71.44683219562891},
                {"lat":-33.03550022257617, "lng":-71.44914962418846},
                {"lat":-33.032693944862594, "lng":-71.44588805806761},
                {"lat":-33.03031933237463, "lng":-71.45043708449933},
                {"lat":-33.029239941908905, "lng":-71.4498362696876},
                {"lat":-33.032693944862594, "lng":-71.44142486232327},
                {"lat":-33.034636762029834, "lng":-71.44056655544937},
                {"lat":-33.031110876980456, "lng":-71.43352843908329},
            ]
        },
    ]
};

export default Quilpue;
