import React from 'react';
import Cookies from 'universal-cookie';
import './PreviewVenta.css';
import { numberFormat, getDateTimeNow } from '../../components/genscripts';

import logoTaxiOficialGris from '../../images/logoTaxiOficialGris.png';

const PreviewVenta = (props) => {
	const ventaData = props.ventaData;
	const payType = props.payType;
	const moneyType = props.moneyType;
	const refDirection = props.refDirection;

	const getDirection = () => {
		const direction = refDirection && refDirection.current && refDirection.current.value !== '' ?
				refDirection.current.value : ventaData.servicename;
		return direction.substring(0, 50);
	};

	const cookies = new Cookies();

	return (
		<div className="col-md-6 float-left " id="step-previewventa">
			<div className="card ">
				<div className="card-header ">
					<h5 className="card-title">Previsualización:</h5>
				</div>
				<div className="card-body ">
					<div className="row padding-20">
						<div className="col-md-12 pl-1">
							<div id="previewTicket" className="TicketPaper">
								<div id="printTicket">
									<div className="ticketTitle">
										<img src={logoTaxiOficialGris} alt="logogris" />
										<input type="hidden" id="nro_ticket_tmp" />
										<input type="hidden" id="fechahoraventaticket" />
									</div>
									<div id="QRView" className="qrviewstyle"></div>
									<div className="TicketDetail">
										<table border="0">
											<tbody>
												<tr>
													<td className="labelPreviewTicket">
														Número de ticket:
													</td>
													<td
														className="valuePreviewTicket"
														id="TicketNumberPreview"
													>
														XXXXXX
													</td>
												</tr>
												<tr>
													<td className="labelPreviewTicket">Valor: </td>
													<td className="valuePreviewTicket" id="MontoPreview">
														$ {numberFormat(ventaData.valor + '')}
													</td>
												</tr>
												<tr>
													<td className="labelPreviewTicket">Moneda: </td>
													<td
														className="valuePreviewTicket"
														id="TipomonedaPreview"
													>
														{moneyType}
													</td>
												</tr>
												<tr>
													<td className="labelPreviewTicket">
														Forma de pago:{' '}
													</td>
													<td className="valuePreviewTicket" id="FPagoPreview">
														{payType}
													</td>
												</tr>
												<tr>
													<td className="labelPreviewTicket">Destino: </td>
													<td
														className="valuePreviewTicket"
														id="DestinoPreview"
													>
															{getDirection()}
													</td>
												</tr>
												<tr>
													<td className="labelPreviewTicket">
														Fecha de emisión:{' '}
													</td>
													<td
														className="valuePreviewTicket"
														id="FechaEmisionPreview"
													>
														{getDateTimeNow()}
													</td>
												</tr>
												<tr>
													<td className="labelPreviewTicket">Vendedor: </td>
													<td
														className="valuePreviewTicket"
														id="SellerNamePreview"
													>
														{cookies.get('name')}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviewVenta;
