import React, { useState } from 'react';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import Footer from './components/Footer';
import { checkSession, verifyAccess } from './components/genscripts';
import HeadMenu from './components/HeadMenu';
import SideBar from './components/SideBar';
import Cookies from 'universal-cookie';
import axios from 'axios';

const thispage = 'ticketsaescaneado';
const headTitle = 'Pasar de Nómina a Escaneado';

const TicketsaEscaneado = () => {
	const session = checkSession();
	const [ticketsList, setTicketsList] = useState('');
	const [modalAsingIsOpen, setModalAsingIsOpen] = useState(false);
	const [asingning, setAsasingning] = useState(false);
	const [asingningFinish, setAsasingningFinish] = useState(false);
	const [assingsuccess, setAsingsuccess] = useState(true);

	const closeModalAsign = () => {
		setModalAsingIsOpen(false);
	};

	const openModalAsign = () => {
		setModalAsingIsOpen(true);
	};

	const TicketsToScanAction = () => {
		setAsasingning(true);
		let listTicketstoAsing = [];
		if (ticketsList !== '') {
			listTicketstoAsing = ticketsList
				.replace(/ /g, '')
				.split(',')
				.map((x) => +x);
		}
		openModalAsign();
		console.log('listtiockets arr: ', listTicketstoAsing);

		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/backtoescaneado`,
			data: JSON.stringify({
				listtiskets: listTicketstoAsing,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log('data: ', response.data);
				setAsasingning(false);
				setAsasingningFinish(true);

				if (response.data > 0) {
					setAsingsuccess(true);
				} else {
					setAsingsuccess(false);
				}

				setTimeout(() => {
					closeModalAsign();
					setAsasingning(false);
					setAsasingningFinish(false);
					setAsingsuccess(true);
				}, 3000);
			})
			.catch((error) => {
				console.log('backtoescaneado: ' + error);
			});
	};

	return session ? (
		[
			verifyAccess(thispage) ? (
				<div className="wrapper ">
					<div className="sidebar" data-color="white" data-active-color="info">
						<SideBar activeitem={thispage} />
					</div>
					<div className="main-panel">
						<HeadMenu pagetitle={headTitle} />
						<div className="content">
							<div className="row-100">
								<div className="row">
									<div className="col-md-12">
										<div className="card">
											<div className="card-body">
												{/* Contenido */}
												<h4>
													Vulve a escaneados tickets que tuvieron problemas en
													la etapa de pago.
												</h4>
												<small>
													<b>Nota:</b> Para agregar más de un ticket deben estar
													separados por coma.
												</small>
												<div className="row ticketsasignform">
													<div className="form-check col-md-8">
														<label htmlFor="ticketstoasign">
															Ingresa los tickets que deseas asignar:
														</label>
														<textarea
															name="ticketstoasign"
															className="form-control listticketasign"
															value={ticketsList}
															onChange={(e) => setTicketsList(e.target.value)}
														></textarea>
													</div>
													<div className="form-check col-md-8 centerContent">
														<button
															onClick={() => openModalAsign()}
															className="btn btn-inactivo btn-round"
														>
															Asignar
														</button>
													</div>
												</div>
												{/* Fin contenido */}
											</div>

											<div className="card-footer ">
												<hr />
												<div className="stats">
													<i className="nc-icon nc-single-copy-04"></i> Volver a
													escaneado
												</div>
											</div>

											{/* MODAL */}

											<Modal
												isOpen={modalAsingIsOpen}
												onRequestClose={closeModalAsign}
												overlayClassName="Overlay"
												className="Modal"
												contentLabel="Imprimir ticket"
											>
												<div id="CambioEstadoTicketReport">
													<div className="headermodal">
														<button onClick={closeModalAsign}>
															<i className="nc-icon nc-simple-remove"></i>
														</button>
													</div>
													<div className="containerAnulacionModal">
														{asingning ? (
															<h4>
																Asignando tickets <br />
																<img
																	src="img/loadingpagado.gif"
																	alt="loading..."
																/>
															</h4>
														) : (
															<div>
																{!asingningFinish ? (
																	<div>
																		<div>
																			¿Estás segur@ que deseas realizar esta
																			acción? NOTA: Esta acción puede generar
																			pagos dobles en caso de error.
																		</div>
																		<div>
																			<button
																				className="btn btn-inactivo btn-round"
																				onClick={() => TicketsToScanAction()}
																			>
																				Aceptar
																			</button>
																			<button
																				className="btn btn-inactivo btn-round"
																				onClick={() => closeModalAsign()}
																			>
																				Cancelar
																			</button>
																		</div>
																	</div>
																) : (
																	<div>
																		<div id="msgErrorPetAnul">
																			{assingsuccess
																				? 'Asignación realizada con éxito.'
																				: 'Error al asignar tickets'}
																		</div>
																		<div>
																			<button
																				className="btn btn-inactivo btn-round"
																				onClick={() => closeModalAsign()}
																			>
																				Aceptar
																			</button>
																		</div>
																	</div>
																)}
															</div>
														)}
													</div>
												</div>
											</Modal>

											{/* FIN MODAL */}
										</div>
									</div>
								</div>
							</div>
						</div>

						<Footer />
					</div>
				</div>
			) : (
				<Redirect to="/noaccess" />
			),
		]
	) : (
		<Redirect to="/" />
	);
};

export default TicketsaEscaneado;
