import React from 'react';

import { Redirect } from 'react-router-dom';
import axios from 'axios';

import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import './bankaccounts.css';

import { checkSession, verifyAccess } from './components/genscripts';
import Cookies from 'universal-cookie';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import EditBackAccount from './components/BankAccounts/EditBankAccount';

const thispage = 'bankaccounts';
const headTitle = 'Cuentas de banco';

class BackAccounts extends React.Component {
	constructor() {
		super();
		this.state = {
			columnDefs: [
				{
					headerName: 'Nombre',
					field: 'nombre',
					filter: true,
					width: 102,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Apellido',
					field: 'apellido',
					filter: true,
					width: 102,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Rut',
					field: 'rut',
					filter: true,
					width: 102,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Email',
					field: 'email',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Teléfono',
					field: 'telefono',
					width: 110,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Banco',
					field: 'banconombre',
					width: 230,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Tipo de cuenta',
					field: 'tipocuenta',
					width: 250,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Número de cuenta',
					field: 'bankaccount_nro',
					width: 180,
					suppressSizeToFit: true,
				},
				{
					headerName: '',
					field: 'tools',
					width: 90,
					cellRenderer: 'editBankAccount',
					suppressSizeToFit: true,
				},
			],

			defaultColDef: { resizable: true },
			rowSelection: 'single',
			rowData: [],
			context: { componentParent: this },
			frameworkComponents: {
				editBankAccount: EditBackAccount,
				getData: this.getCuentasBanco,
			},
		};
		this.getCuentasBanco = this.getCuentasBanco.bind(this);
	}

	componentDidMount() {
		this.getCuentasBanco();
	}

	getCuentasBanco() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const bankAcc = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getUsuarioAndCuentasBanco`,
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				for (const dato of response.data) {
					bankAcc.push({
						nombre: dato[1],
						apellido: dato[2],
						rut: dato[3],
						email: dato[4],
						telefono: dato[5],
						bankaccount_nro: dato[6],
						bankaccount_type: dato[7],
						bankaccount_bankcode: dato[8],
						banconombre: dato[9],
						tipocuenta: dato[10],
					});
				}
				this.setState({
					rowData: bankAcc,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	onGridReady = (params) => {};

	render() {
		const session = checkSession();
		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-body marginTop30">
													<h4>Cuentas de banco de conductores inscritos:</h4>
													<div
														className="ag-theme-balham tableContainer"
														style={{
															height: '800px',
															width: '1350px',
															margin: '20px auto 20px auto',
														}}
													>
														<AgGridReact
															onGridReady={this.onGridReady}
															rowSelection={this.state.rowSelection}
															frameworkComponents={
																this.state.frameworkComponents
															}
															paginationAutoPageSize={true}
															pagination={true}
															columnDefs={this.state.columnDefs}
															rowData={this.state.rowData}
														></AgGridReact>
													</div>
												</div>

												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i>{' '}
														Reportes
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

export default BackAccounts;
