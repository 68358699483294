const Recoleta = {
    commune: "Recoleta",
    polygons: [
        {
            idService: 92,
            nameService: "Recoleta 1 / Desde el Centro - Dorsal",
            coordinates: [
                //Limite Recoleta sobre mapocho
                {"lat":-33.432139720978114, "lng":-70.65104629011464},
                {"lat":-33.41800723601955, "lng":-70.64962816153803},
                {"lat":-33.4170223321296, "lng":-70.65266639061134},
                {"lat":-33.41100590472954, "lng":-70.65242963877678},
                {"lat":-33.41134181689806, "lng":-70.65275197217107},
                {"lat":-33.40945460178877, "lng":-70.65323688819623},
                {"lat":-33.40667902364704, "lng":-70.6529129564543},
                {"lat":-33.40533286735745, "lng":-70.6562702970514},
                {"lat":-33.396113767975265, "lng":-70.65763362318003},
                //Limite dorsal de oeste a este
                {"lat":-33.39600210432929, "lng":-70.65710746064049},
                {"lat":-33.39559724834927, "lng":-70.65363819127754},
                {"lat":-33.39516478061052, "lng":-70.65318776319874},
                {"lat":-33.39601091112914, "lng":-70.64834566135168},
                {"lat":-33.396443374657395, "lng":-70.64321078125343},
                {"lat":-33.398397821640216, "lng":-70.63807766859809},
                {"lat":-33.39995311008347, "lng":-70.63016539522114},
                {"lat":-33.40088347824442, "lng":-70.6303384760183},
                {"lat":-33.399938777894945, "lng":-70.6296930198884},
                {"lat":-33.3980181006769, "lng":-70.62837838880974},
                {"lat":-33.394588214342775, "lng":-70.62476315334344},
                //Limite cerro San Cristóbal
                {"lat":-33.39600456684692, "lng":-70.6226390834051},
                {"lat":-33.401107614144074, "lng":-70.61416744759856},
                {"lat":-33.40316665363349, "lng":-70.61030695533229},
                {"lat":-33.40719506802492, "lng":-70.61212996556915},
                {"lat":-33.41328209562712, "lng":-70.62145948853149},
                {"lat":-33.422322334144624, "lng":-70.63218307816001},
                {"lat":-33.424917872274214, "lng":-70.63389885250058},
                {"lat":-33.429124447424385, "lng":-70.63588604362515},
                {"lat":-33.431450619684306, "lng":-70.63631575316171},
                {"lat":-33.43456166794109, "lng":-70.6358856106299},
                {"lat": -33.43589131626075, "lng": -70.63566945966782},
                //{"lat":-33.43515993366616, "lng":-70.63574222978596},
                //{"lat":-33.43336267809341, "lng":-70.64496428483157},
            ]
        },
        {
            idService: 135,
            nameService: "Recoleta 2 / Dorsal - Ciudad Empresarial",
            coordinates: [
                //Limite dorsal de oeste a este
                {"lat":-33.39600210432929, "lng":-70.65710746064049},
                {"lat":-33.39559724834927, "lng":-70.65363819127754},
                {"lat":-33.39516478061052, "lng":-70.65318776319874},
                {"lat":-33.39601091112914, "lng":-70.64834566135168},
                {"lat":-33.396443374657395, "lng":-70.64321078125343},
                {"lat":-33.398397821640216, "lng":-70.63807766859809},
                {"lat":-33.39995311008347, "lng":-70.63016539522114},
                {"lat":-33.40088347824442, "lng":-70.6303384760183},
                {"lat":-33.399938777894945, "lng":-70.6296930198884},
                {"lat":-33.3980181006769, "lng":-70.62837838880974},
                {"lat":-33.394588214342775, "lng":-70.62476315334344},
                //limite Vespucio
                {"lat":-33.394118811629, "lng":-70.6257142607518},
                {"lat":-33.384127124919466, "lng":-70.64060149855683},
                {"lat":-33.38222381629385, "lng":-70.64316580746106},
                {"lat":-33.37831480746702, "lng":-70.65262450813783},
                {"lat":-33.37701385300256, "lng":-70.65688585813206},
                {"lat":-33.38644167385239, "lng":-70.65681227373061},
                {"lat":-33.39517628996298, "lng":-70.65713581450261},
            ]
        },
    ]
};

export default Recoleta;
