const SanFelipe = {
    commune: "San Felipe",
    polygons: [
        {
            idService: 96,
            nameService: "San Felipe",
            coordinates: [
                {"lat":-32.732659266028485, "lng":-70.7355962889096},
                {"lat":-32.734405057467114, "lng":-70.73418149913896},
                {"lat":-32.735242191147925, "lng":-70.73415598152333},
                {"lat":-32.735263656010716, "lng":-70.73288010074177},
                {"lat":-32.73296688637682, "lng":-70.73229319558224},
                {"lat":-32.73378256835098,"lng": -70.72805727138746},
                {"lat":-32.73633689255883,"lng": -70.7293076345534},
                {"lat":-32.73670179004112, "lng":-70.72652621444958},
                {"lat":-32.73571441693505, "lng":-70.72624552067764},
                {"lat":-32.7368091125457, "lng":-70.72320892441752},
                {"lat":-32.736465680076115, "lng":-70.72218821979226},
                {"lat":-32.73144282895715, "lng":-70.72453584042383},
                {"lat":-32.731485760507496,"lng":-70.72318340679536},
                {"lat":-32.73599974508548, "lng":-70.72151728784276},
                {"lat":-32.73558453701912, "lng":-70.72079524895872},
                {"lat":-32.734013611191486, "lng":-70.7206508995263},
                {"lat":-32.73426405049745, "lng":-70.71863000747241},
                {"lat":-32.733749890221745, "lng":-70.71858038723691},
                {"lat":-32.73384854850607, "lng":-70.7178857055934},
                {"lat":-32.73433424923672, "lng":-70.71795788030961},
                {"lat":-32.734744057169486, "lng":-70.7150438261426},
                {"lat":-32.737126977120404, "lng":-70.71556709278747},
                {"lat":-32.73680824630196, "lng":-70.71461979963719},
                {"lat":-32.737248398097634, "lng":-70.71199444420878},
                {"lat":-32.741331081790804, "lng":-70.71179596374046},
                {"lat":-32.74146008547372, "lng":-70.70884582221537},
                {"lat":-32.7428411720507, "lng":-70.70867440720879},
                {"lat":-32.74495070253484, "lng":-70.70740232780507},
                {"lat":-32.74617997421801, "lng":-70.70731210940981},
                {"lat":-32.74544393086491, "lng":-70.71240944874218},
                {"lat":-32.74305131076818, "lng":-70.71247333494577},
                {"lat":-32.742726912560336, "lng":-70.71371964528355},
                {"lat":-32.74390737318355, "lng":-70.7140130978633},
                {"lat":-32.74372495966881, "lng":-70.7149789706841},
                {"lat":-32.74845738626909, "lng":-70.71645899239071},
                {"lat":-32.750163573708726, "lng":-70.7092757834818},
                {"lat":-32.748811503371634, "lng":-70.70896957209425},
                {"lat":-32.74907977292766, "lng":-70.70743851515635},
                {"lat":-32.75058206754347, "lng":-70.70732368575747},
                {"lat":-32.75159073683248, "lng":-70.70371294314566},
                {"lat":-32.751836815735295, "lng":-70.70157496721251},
                {"lat":-32.75114202789146, "lng":-70.69879619862098},
                {"lat":-32.75079914358611,"lng": -70.699364826916},
                {"lat":-32.74954489764582, "lng":-70.69860307957738},
                {"lat":-32.749987043913656, "lng":-70.6976482131952},
                {"lat":-32.748335751746986, "lng":-70.69646804126214},
                {"lat":-32.74804699805987, "lng":-70.69508402144973},
                {"lat":-32.74952685081807, "lng":-70.69442956233462},
                {"lat":-32.749734389177526, "lng":-70.69261638909202},
                {"lat":-32.75058258440272, "lng":-70.69240181237691},
                {"lat":-32.75230602003468, "lng":-70.69315283087977},
                {"lat":-32.75132249279047, "lng":-70.69423644329105},
                {"lat":-32.75208946453435, "lng":-70.69808809542758},
                {"lat":-32.75287447580764, "lng":-70.6999227263417},
                {"lat":-32.76006557902943, "lng":-70.69949357295776},
                {"lat":-32.758522743858094, "lng":-70.70315210595024},
                {"lat":-32.768392818554766, "lng":-70.70685355443366},
                {"lat":-32.762348204339105, "lng":-70.72393386144502},
                {"lat":-32.765334475477005, "lng":-70.7254895426624},
                {"lat":-32.765415671782335, "lng":-70.7265409685664},
                {"lat":-32.76474805550265, "lng":-70.7304248072854},
                {"lat":-32.763692489841056, "lng":-70.73008148454126},
                {"lat":-32.763214323499525, "lng":-70.73205559032019},
                {"lat":-32.762257983112065, "lng":-70.7316908099045},
                {"lat":-32.76166252070045, "lng":-70.73358981383316},
                {"lat":-32.759722729167386, "lng":-70.73282806649455},
                {"lat":-32.757990414425414, "lng":-70.73622910760373},
                {"lat":-32.75857687889438, "lng":-70.73761312741614},
                {"lat":-32.75729567459514, "lng":-70.73810665386087},
                {"lat":-32.75669115630691, "lng":-70.73911516442185},
                {"lat":-32.75614077041589, "lng":-70.73888985887099},
                {"lat":-32.7568625872831, "lng":-70.73762385625189},
                {"lat":-32.75762048869694, "lng":-70.73719470282168},
                {"lat":-32.75713326709996, "lng":-70.73634712479704},
                {"lat":-32.75656483853431, "lng":-70.73663680336242},
                {"lat":-32.7548685538573, "lng":-70.73615400577293},
                {"lat":-32.754489592246415, "lng":-70.73759166976411},
                {"lat":-32.75027579134474, "lng":-70.73766677164333},
                {"lat":-32.748570363461205, "lng":-70.73896496076969},
                {"lat":-32.74629436131037, "lng":-70.73893936183893},
                {"lat":-32.74287209188332, "lng":-70.74149254246198},
                {"lat":-32.74227261209757, "lng":-70.74148352062245},
                {"lat":-32.7416807167065, "lng":-70.74275559999566},
                {"lat":-32.736763279800066, "lng":-70.74682444973756},
                {"lat":-32.7380609630701, "lng":-70.74023850674742},
                {"lat":-32.73789401071057, "lng":-70.73461790059268},
                {"lat":-32.73312814814061, "lng":-70.73654857430198},
            ]
        },
    ]
};

export default SanFelipe;
