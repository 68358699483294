//UsersPage

import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import SideBar from './components/SideBar';
import HeadMenu from './components/HeadMenu';
import Footer from './components/Footer';
import { checkSession, verifyAccess, checkRut } from './components/genscripts';
import './buscartickets.css';
import Cookies from 'universal-cookie';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import './ticketsporanular.css';

import error from '../pages/images/error.png';
import success from '../pages/images/success.png';
import BtnEditUser from './components/Users/BtnEditUser';
import BtnDelUser from './components/Users/BtnDelUser';
import BtnUpdatePass from './components/Users/BtnUpdatePass';

const thispage = 'users';
const headTitle = 'Usuarios';

class UsersPage extends React.Component {
	constructor() {
		super();
		this.state = {
			usersData: [],
			ticketnroinvalidate: 0,
			modalIsOpen: false,
			cambioestadopeticion: false,
			columnDefs: [
				{
					headerName: 'Id Usuario',
					field: 'idusuario',
					sortable: true,
					filter: true,
					width: 102,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Nombre',
					field: 'nombre',
					sortable: true,
					filter: true,
					width: 150,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Apellido',
					field: 'apellido',
					sortable: true,
					filter: true,
					width: 150,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Rut',
					field: 'rut',
					sortable: true,
					filter: true,
					width: 100,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Email',
					field: 'email',
					width: 165,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Teléfono',
					field: 'telefono',
					width: 126,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Perfil',
					field: 'nombreperfil',
					width: 120,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Activo?',
					field: 'active',
					width: 100,
					suppressSizeToFit: true,
				},
				{
					headerName: '',
					field: 'tools',
					width: 90,
					cellRenderer: 'btnEditUser',
					suppressSizeToFit: true,
				},
				{
					headerName: '',
					field: 'tools',
					width: 110,
					cellRenderer: 'btnDelUser',
					suppressSizeToFit: true,
				},
				{
					headerName: '',
					field: 'tools',
					width: 135,
					cellRenderer: 'btnUpdatePass',
					suppressSizeToFit: true,
				},
			],

			defaultColDef: { resizable: true },
			rowSelection: 'single',
			rowData: [],
			context: { componentParent: this },
			frameworkComponents: {
				btnEditUser: BtnEditUser,
				btnDelUser: BtnDelUser,
				btnUpdatePass: BtnUpdatePass,
				getData: this.getListUsers,
			},
			nombre: '',
			apellido: '',
			rut: '',
			email: '',
			telefono: '',
			idperfil: '',
			nombreperfil: '',
			active: true,
			clave: '',
			perfilesData: [],
			addUserAction: false,
			addUserExist: '',
		};
		this.getListUsers = this.getListUsers.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.saveUser = this.saveUser.bind(this);

		this.handleNameForm = this.handleNameForm.bind(this);
		this.handleLastNameForm = this.handleLastNameForm.bind(this);
		this.handleRutForm = this.handleRutForm.bind(this);
		this.handleEmailForm = this.handleEmailForm.bind(this);
		this.handleTelefonoForm = this.handleTelefonoForm.bind(this);
		this.handlePerfilForm = this.handlePerfilForm.bind(this);
		this.handlePassForm = this.handlePassForm.bind(this);
	}

	componentDidMount() {
		this.getListUsers();
	}

	openModal() {
		this.getPerfiles();
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	getPerfiles() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const perfilesData = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getPerfiles`,
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				for (const perfil of response.data) {
					perfilesData.push(
						<option value={perfil.idperfil}>{perfil.nombreperfil}</option>
					);
				}
				this.setState({ perfilesData: perfilesData });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getListUsers() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const usersDataJson = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getListUsersProfile`,
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				for (const usuario of response.data) {
					usersDataJson.push({
						idusuario: usuario[0],
						nombre: usuario[1],
						apellido: usuario[2],
						rut: usuario[3],
						email: usuario[4],
						telefono: usuario[5],
						idperfil: usuario[6],
						nombreperfil: usuario[7],
						active: usuario[8],
					});
				}
				this.setState({ usersData: usersDataJson });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	onGridReady = (params) => {};

	handleNameForm(event) {
		this.setState({ nombre: event.target.value });
	}

	handleLastNameForm(event) {
		this.setState({ apellido: event.target.value });
	}

	handleRutForm(event) {
		this.setState({ rut: event.target.value });
	}

	handleEmailForm(event) {
		this.setState({ email: event.target.value });
	}

	handleTelefonoForm(event) {
		this.setState({ telefono: event.target.value });
	}

	handlePerfilForm(event) {
		this.setState({ idperfil: event.target.value });
	}

	handlePassForm(event) {
		this.setState({ clave: event.target.value });
	}

	saveUser() {
		const salidacheckRut = checkRut(this.state.rut);
		if (salidacheckRut) {
			const cookies = new Cookies();
			const token = cookies.get('token');
			const headers = {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			};
			axios({
				method: 'POST',
				url: `${process.env.REACT_APP_BACKEND_URL}/data/adduser`,
				data: JSON.stringify({
					nombre: this.state.nombre,
					apellido: this.state.apellido,
					rut: this.state.rut,
					email: this.state.email,
					telefono: this.state.telefono,
					idperfil: this.state.idperfil,
					clave: this.state.clave,
					active: true,
				}),
				headers: headers,
			})
				.then((response) => {
					//console.log(response.data);
					if (response.data === 'Saved') {
						this.setState({ addUserAction: true });
					} else if (response.data === 'exists') {
						console.log('usuario existe');
						this.setState({
							addUserAction: false,
							addUserExist: 'Usuario existe',
						});
					} else {
						this.setState({ addUserAction: false });
					}
					document.getElementById('addUserPaso1').style.display = 'none';
					document.getElementById('addUserPasoFinal').style.display = 'block';
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			document.getElementById('mensajevalidacionusuario').innerHTML =
				'<p>El rut no es válido.</p>';
		}
	}

	render() {
		const session = checkSession();

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-header">
													<h4>Usuarios</h4>
												</div>
												<div className="card-body">
													<div>
														<button
															className="btn btn-inactivo btn-round"
															onClick={this.openModal}
														>
															+ Usuario
														</button>
													</div>
													<div
														className="ag-theme-balham tableContainer"
														style={{
															height: '700px',
															width: '1350px',
															margin: '0px auto 20px auto',
														}}
													>
														<AgGridReact
															onGridReady={this.onGridReady}
															rowSelection={this.state.rowSelection}
															frameworkComponents={
																this.state.frameworkComponents
															}
															paginationAutoPageSize={true}
															pagination={true}
															columnDefs={this.state.columnDefs}
															rowData={this.state.usersData}
														></AgGridReact>
													</div>
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i> Lista
														de usuarios
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* MODAL */}

							<Modal
								isOpen={this.state.modalIsOpen}
								onRequestClose={this.closeModal}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel="Imprimir ticket"
							>
								<div id="addUserPaso1">
									<div className="headermodal">
										<button onClick={this.closeModal}>
											<i class="nc-icon nc-simple-remove"></i>
										</button>
									</div>
									<div className="containerModalUsuarios">
										<h4>Creación de usuario</h4>
										<div
											className="validaUsuarioCreado"
											id="mensajevalidacionusuario"
										></div>
										<br />
										<div class="form-group">
											<label for="modalNombre">Nombre</label>
											<input
												type="text"
												class="form-control"
												id="modalNombre"
												onChange={this.handleNameForm}
												value={this.state.nombre}
											/>
										</div>
										<div class="form-group">
											<label for="modalApellido">Apellido</label>
											<input
												type="text"
												class="form-control"
												id="modalApellido"
												onChange={this.handleLastNameForm}
												value={this.state.apellido}
											/>
										</div>
										<div class="form-group">
											<label for="modalRut">Rut</label>
											<input
												type="text"
												class="form-control"
												id="modalRut"
												onChange={this.handleRutForm}
												value={this.state.rut}
											/>
										</div>
										<div class="form-group">
											<label for="modalEmail">E-Mail</label>
											<input
												type="email"
												class="form-control"
												id="modalEmail"
												onChange={this.handleEmailForm}
												value={this.state.email}
											/>
										</div>
										<div class="form-group">
											<label for="modalTelefono">Teléfono</label>
											<input
												type="text"
												class="form-control"
												id="modalTelefono"
												onChange={this.handleTelefonoForm}
												value={this.state.telefono}
											/>
										</div>
										<div class="form-group">
											<label for="modalProfile">Perfil</label>
											<select
												id="modalProfile"
												value={this.state.idperfil}
												onChange={this.handlePerfilForm}
												class="form-control"
											>
												<option value="0">Selecciona.</option>
												{this.state.perfilesData}
											</select>
										</div>
										<div class="form-group">
											<label for="modalClave">Clave</label>
											<input
												type="password"
												class="form-control"
												id="modalClave"
												onChange={this.handlePassForm}
												value={this.state.clave}
											/>
										</div>
										<div className="containerButtonsModalUsuarios">
											<input
												type="button"
												className="btn btn-inactivo btn-round"
												onClick={this.saveUser}
												value="Guardar"
											/>
										</div>
									</div>
								</div>
								<div id="addUserPasoFinal">
									{this.state.addUserAction ? (
										<div className="cuadraturaResponse">
											<img src={success} alt="success" />
											<p>Usuario creado con éxito.</p>
										</div>
									) : (
										<div className="cuadraturaResponse">
											<img src={error} alt="error" />
											<p>Error al crear el usuario.</p>
											<p>{this.state.addUserExist}</p>
										</div>
									)}
								</div>
							</Modal>

							{/* FIN MODAL */}

							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

Modal.setAppElement('body');

export default UsersPage;
