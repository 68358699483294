import React, { useState } from 'react';
import Modal from 'react-modal';
import './SelectReemplazo.css';
import error from '../../images/error.png';
import success from '../../images/success.png';

import axios from 'axios';
import Cookies from 'universal-cookie';

import {
	getDateTimeNow,
	openPaso2CierreCaja,
	openPaso3CierreCaja,
	PrintCierre,
} from '../../components/genscripts';

const SelectReemplazoNew = () => {
	const [ingresocuadratura, setIngresoCuadratura] = useState(false);
	const [modalIsOpenCloseBox, setModalIsOpenCloseBox] = useState(false);

	const [efectivoPesosBox, setEfectivoPesosBox] = useState(0);
	const [efectivoDolarBox, setEfectivoDolarBox] = useState(0);
	const [efectivoEuroBox, setEfectivoEuroBox] = useState(0);
	const [transbankDebito, setTransbankDebito] = useState(0);
	const [transbankCredito, setTransbankCredito] = useState(0);
	const [cierrePesos, setCierrePesos] = useState(0);
	const [cierreDolar, setCierreDolar] = useState(0);
	const [cierreEuro, setCierreEuro] = useState(0);
	const [cierreTransbankDB, setCierreTransbankDB] = useState(0);
	const [cierreTransbankTC, setCierreTransbankTC] = useState(0);

	const ingresarCloseMoneyBox = () => {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const copenid = cookies.get('copenid');
		const rutvendedor = cookies.get('user');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getInicioCajaById`,
			data: JSON.stringify({
				idcajasiniciofin: copenid,
			}),
			headers: headers,
		})
			.then((response) => {
				let iniciodate = response.data.iniciodate;
				let iniciomontos = [];
				iniciomontos.push({
					monto: response.data.iniciomontopesos,
					moneda: 'CL',
				});
				iniciomontos.push({
					monto: response.data.iniciomontodolar,
					moneda: 'US',
				});
				iniciomontos.push({
					monto: response.data.iniciomontoeuro,
					moneda: 'EU',
				});

				let iniciomontosindxCL = 0;
				let iniciomontosindxUS = 0;
				let iniciomontosindxEU = 0;

				if (iniciomontos.length > 0) {
					const iniciomontosindx = iniciomontos.reduce(
						(acc, el) => ({
							...acc,
							[el.moneda]: el,
						}),
						{}
					);

					if (iniciomontosindx['CL']) {
						iniciomontosindxCL = iniciomontosindx['CL'].monto;
					} else {
						iniciomontosindxCL = 0;
					}

					if (iniciomontosindx['US']) {
						iniciomontosindxUS = iniciomontosindx['US'].monto;
					} else {
						iniciomontosindxUS = 0;
					}

					if (iniciomontosindx['EU']) {
						iniciomontosindxEU = iniciomontosindx['EU'].monto;
					} else {
						iniciomontosindxEU = 0;
					}
					document.getElementById('montosaperturacaja').innerHTML =
						'La caja fue abierta con $' +
						iniciomontosindxCL +
						' CLP, ' +
						iniciomontosindxUS +
						'USD y ' +
						iniciomontosindxEU +
						'EU';
				}

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_BACKEND_URL}/data/getcuadraturaparcial`,
					data: JSON.stringify({
						fechaini: iniciodate,
						fechaend: getDateTimeNow(),
						rutvendedor: rutvendedor,
					}),
					headers: headers,
				})
					.then((responsecuadratura) => {
						let cuadrap = [];
						const cuadraturaparcial = responsecuadratura.data;
						for (let cp in cuadraturaparcial) {
							cuadrap.push({
								fpago: cuadraturaparcial[cp][0],
								moneda: cuadraturaparcial[cp][1],
								total: cuadraturaparcial[cp][2],
							});
						}

						const cuadrapEF = cuadrap.filter((x) => x.fpago === 'EF');
						const cuadrapDB = cuadrap.filter((x) => x.fpago === 'DB');
						const cuadrapTC = cuadrap.filter((x) => x.fpago === 'TC');

						let cuadrapEFindxCL = 0;
						let cuadrapEFindxUS = 0;
						let cuadrapEFindxEU = 0;

						let cuadrapDBindxDB = 0;
						let cuadrapTCindxTC = 0;

						if (cuadrapEF.length > 0) {
							const cuadrapEFindx = cuadrapEF.reduce(
								(acc, el) => ({
									...acc,
									[el.moneda]: el,
								}),
								{}
							);

							if (cuadrapEFindx['CL']) {
								cuadrapEFindxCL = cuadrapEFindx['CL'].total;
							} else {
								cuadrapEFindxCL = 0;
							}

							if (cuadrapEFindx['US']) {
								cuadrapEFindxUS = cuadrapEFindx['US'].total;
							} else {
								cuadrapEFindxUS = 0;
							}

							if (cuadrapEFindx['EU']) {
								cuadrapEFindxEU = cuadrapEFindx['EU'].total;
							} else {
								cuadrapEFindxEU = 0;
							}
						}

						if (cuadrapDB) {
							const cuadrapDBindx = cuadrapDB.reduce(
								(acc, el) => ({
									...acc,
									[el.fpago]: el,
								}),
								{}
							);
							if (cuadrapDBindx['DB']) {
								cuadrapDBindxDB = cuadrapDBindx['DB'].total;
							} else {
								cuadrapDBindxDB = 0;
							}
						}

						if (cuadrapTC) {
							const cuadrapTCindx = cuadrapTC.reduce(
								(acc, el) => ({
									...acc,
									[el.fpago]: el,
								}),
								{}
							);
							if (cuadrapTCindx['TC']) {
								cuadrapTCindxTC = cuadrapTCindx['TC'].total;
							} else {
								cuadrapTCindxTC = 0;
							}
						}

						setCierrePesos(cuadrapEFindxCL);
						setCierreDolar(cuadrapEFindxUS);
						setCierreEuro(cuadrapEFindxEU);
						setCierreTransbankDB(cuadrapDBindxDB);
						setCierreTransbankTC(cuadrapTCindxTC);

						if (
							efectivoPesosBox - cierrePesos === 0 &&
							efectivoDolarBox - cierreDolar === 0 &&
							efectivoEuroBox - cierreEuro === 0 &&
							transbankDebito - cierreTransbankDB === 0 &&
							transbankCredito - cierreTransbankTC === 0
						) {
							document.getElementById('msgCuadratura').innerHTML =
								'<p>La caja está OK</p>';
							document.getElementById('cajaNOCuadra').style.display = 'none';
							document.getElementById('comentariosCajaNoCuadra').value = '';
						} else {
							document.getElementById('msgCuadratura').innerHTML =
								'<p>La caja no cuadra, favor ingresa tus comentarios en el siguiente recuadro:</p>';
							document.getElementById('cajaNOCuadra').style.display = 'block';
							document.getElementById('comentariosCajaNoCuadra').value = '';
						}

						openPaso2CierreCaja();
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const closeMoneyBox = () => {
		const DiffEFCL = efectivoPesosBox - cierrePesos;
		const DiffEFUSD = efectivoDolarBox - cierreDolar;
		const DiffEFEU = efectivoEuroBox - cierreEuro;
		const DiffDB = transbankDebito - cierreTransbankDB;
		const DiffTC = transbankCredito - cierreTransbankTC;

		let cuadratura = null;

		if (
			DiffEFCL === 0 &&
			DiffEFUSD === 0 &&
			(DiffEFEU === 0) & (DiffDB === 0) &&
			DiffTC === 0
		) {
			cuadratura = true;
		} else {
			cuadratura = false;
		}

		const cookies = new Cookies();
		const token = cookies.get('token');
		const copenid = cookies.get('copenid');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/closeMoneyBox`,
			data: JSON.stringify({
				idcajasiniciofin: copenid,
				findate: getDateTimeNow(),
				finmontopesos: efectivoPesosBox,
				finmontodolar: efectivoDolarBox,
				finmontoeuro: efectivoEuroBox,
				finmontodebito: transbankDebito,
				finmontocredito: transbankCredito,
				cuadratura: cuadratura,
				resumemontopesos: cierrePesos,
				resumemontodolar: cierreDolar,
				resumemontoeuro: cierreEuro,
				resumemontodebito: cierreTransbankDB,
				resumemontocredito: cierreTransbankTC,
				comantariocuadratura: document.getElementById('comentariosCajaNoCuadra')
					.value,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				if (response.data > 0) {
					setIngresoCuadratura(true);
				} else {
					setIngresoCuadratura(false);
				}

				document.getElementById('headerModalCierreCaja').style.display = 'none';
				document.getElementById('tituloCierreCaja').style.display = 'none';

				openPaso3CierreCaja();

				setTimeout(function () {
					cookies.remove('copen', { path: '/' });
					cookies.remove('copenid', { path: '/' });
					window.location.reload();
				}, 3000);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div className="toolsSellContainer">
			<div className="toolSell"></div>
			<div className="toolSell divNone"></div>

			<div className="toolsSellCloseBox">
				<button
					className="btnsel btn btn-inactivo btn-round"
					onClick={() => setModalIsOpenCloseBox(true)}
				>
					Cerrar Caja
				</button>
			</div>

			{/* MODAL */}
			<Modal
				isOpen={modalIsOpenCloseBox}
				onRequestClose={() => setModalIsOpenCloseBox(false)}
				overlayClassName="Overlay"
				className="Modal"
				contentLabel="Imprimir ticket"
			>
				<div className="headermodal" id="headerModalCierreCaja">
					<button onClick={() => setModalIsOpenCloseBox(false)}>
						<i class="nc-icon nc-simple-remove"></i>
					</button>
				</div>
				<div className="containerModalForm">
					<h4 id="tituloCierreCaja">Cierre de caja:</h4>
					<div id="paso1cierrecaja">
						<h5>Montos en efectivo:</h5>
						<div className="msgLoginVenta">{/* this.state.msg */}</div>
						<div className="form-group">
							<label for="usuarioReemplazoTemp">Monto en pesos:</label>
							<input
								type="text"
								className="form-control"
								onChange={(e) => setEfectivoPesosBox(e.target.value)}
								id="efectivoPesosBox"
							/>
						</div>
						<div className="form-group">
							<label for="usuarioReemplazoTemp">Monto en dolares:</label>
							<input
								type="text"
								className="form-control"
								onChange={(e) => setEfectivoDolarBox(e.target.value)}
								id="efectivoDolarBox"
							/>
						</div>
						<div className="form-group">
							<label for="usuarioReemplazoTemp">Monto en euros:</label>
							<input
								type="text"
								className="form-control"
								onChange={(e) => setEfectivoEuroBox(e.target.value)}
								id="efectivoEuroBox"
							/>
						</div>

						<h5>Transbank:</h5>
						<div className="msgLoginVenta">{/* this.state.msg */}</div>
						<div className="form-group">
							<label for="usuarioReemplazoTemp">Debito:</label>
							<input
								type="text"
								className="form-control"
								id="transbankDebito"
								onChange={(e) => setTransbankDebito(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label for="usuarioReemplazoTemp">Crédito:</label>
							<input
								type="text"
								className="form-control"
								id="transbankCredito"
								onChange={(e) => setTransbankCredito(e.target.value)}
							/>
						</div>

						<div className="form-group buttonModalForm">
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								onClick={() => ingresarCloseMoneyBox()}
								value="Ingresar"
							/>
						</div>
					</div>
					<div id="paso2cierrecaja">
						<h5>Resumen:</h5>
						<div id="montosaperturacaja"></div>
						<div id="tablacierrecaja">
							<table class="table table-striped">
								<thead>
									<tr>
										<th></th>
										<th>Tickets</th>
										<th>Ingresado</th>
										<th>Diferencia</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Efectivo CLP</td>
										<td id="montoEFCL">{cierrePesos}</td>
										<td id="montoIngresadoEFCL">{efectivoPesosBox}</td>
										<td id="DiffEFCL">{efectivoPesosBox - cierrePesos}</td>
									</tr>
									<tr>
										<td>Efectivo USD</td>
										<td id="montoEFUSD">{cierreDolar}</td>
										<td id="montoIngresadoEFUSD">{efectivoDolarBox}</td>
										<td id="DiffEFUSD">{efectivoDolarBox - cierreDolar}</td>
									</tr>
									<tr>
										<td>Efectivo EU</td>
										<td id="montoEFEU">{cierreEuro}</td>
										<td id="montoIngresadoEFEU">{efectivoEuroBox}</td>
										<td id="DiffEFEU">{efectivoEuroBox - cierreEuro}</td>
									</tr>
									<tr>
										<td>Débito</td>
										<td id="montoDB">{cierreTransbankDB}</td>
										<td id="montoIngresadoDB">{transbankDebito}</td>
										<td id="DiffDB">{transbankDebito - cierreTransbankDB}</td>
									</tr>
									<tr>
										<td>Crédito</td>
										<td id="montoTC">{cierreTransbankTC}</td>
										<td id="montoIngresadoTC">{transbankCredito}</td>
										<td id="DiffTC">{transbankCredito - cierreTransbankTC}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div id="msgCuadratura"></div>
						<div id="cajaNOCuadra" class="form-group">
							<textarea
								class="form-control"
								id="comentariosCajaNoCuadra"
								rows="3"
							></textarea>
						</div>
						<p className="p_comentario">
							O puedes cerrar esta ventana y revisar tus ventas en la sección
							"Mis ventas" antes de cerrar la caja.
						</p>
						{/* print cierre */}
						<div className="form-group buttonModalForm">
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								onClick={PrintCierre}
								value="Imprimir Cierre"
							/>
						</div>
						{/* print cierre*/}
						<div className="form-group buttonModalForm">
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								onClick={() => closeMoneyBox()}
								value="Cerrar caja"
							/>
						</div>
					</div>
					<div id="paso3cierrecaja">
						{ingresocuadratura ? (
							<div className="cuadraturaResponse">
								<img src={success} alt="success" />
								<p>Cuadratura ingresada.</p>
							</div>
						) : (
							<div className="cuadraturaResponse">
								<img src={error} alt="error" />
								<p>Error al ingresar la cuadratura</p>
							</div>
						)}
					</div>
				</div>
			</Modal>

			{/* FIN MODAL */}
		</div>
	);
};

export default SelectReemplazoNew;
