import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import SideBar from './components/SideBar';
import HeadMenu from './components/HeadMenu';
import Footer from './components/Footer';
import {
	checkSession,
	verifyAccess,
	numberFormat,
} from './components/genscripts';
import './buscartickets.css';
import Cookies from 'universal-cookie';

import './ticketsporanular.css';

import error from '../pages/images/error.png';
import success from '../pages/images/success.png';

const thispage = 'ticketsporanular';
const headTitle = 'Tickets por anular';

class TicketsPorAnular extends React.Component {
	constructor() {
		super();
		this.state = {
			dataTable: [],
			ticketnroinvalidate: 0,
			modalIsOpen: false,
			cambioestadopeticion: false,
		};
		this.getDataFromTickets = this.getDataFromTickets.bind(this);
		this.anularTicket = this.anularTicket.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {
		this.getDataFromTickets();
	}

	peticionAnulacionModal(ticketnro) {
		this.setState({ modalIsOpen: true, ticketnroinvalidate: ticketnro });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		//document.getElementById("msgErrorPetAnul").innerHTML = "";
		this.setState({ modalIsOpen: false });
	}

	anularTicket() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketState`,
			data: JSON.stringify({
				ticketstate: 'ANULADO',
				idticket: this.state.ticketnroinvalidate,
			}),
			headers: headers,
		})
			.then((response) => {
				if (parseInt(response.data) > 0) {
					this.setState({ cambioestadopeticion: true });
					this.getDataFromTickets();
				} else {
					this.setState({ cambioestadopeticion: false });
				}
				document.getElementById('AnularTicketPeticionPaso1').style.display =
					'none';
				document.getElementById('AnularTicketPeticionFinal').style.display =
					'block';
				setTimeout(() => {
					this.closeModal();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getDataFromTickets() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const dataTableTickets = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getTicketsToInvalidate`,
			headers: headers,
		})
			.then((response) => {
				for (const datoTicket of response.data) {
					const valor = '$ ' + numberFormat(datoTicket.valorticket.toString());
					dataTableTickets.push(
						<tr>
							<td>{datoTicket.idticket}</td>
							<td>{datoTicket.destino}</td>
							<td>{valor}</td>
							<td>{datoTicket.fechahora}</td>
							<td>{datoTicket.moneda}</td>
							<td>{datoTicket.formapago}</td>
							<td>{datoTicket.rut_vendedor}</td>
							<td>{datoTicket.ticketstate}</td>
							<td>{datoTicket.venta_supervisor ? 'Si' : 'No'}</td>
							<td>{datoTicket.rut_supervisor}</td>
							<td>{datoTicket.venta_reemplazo ? 'Si' : 'No'}</td>
							<td>{datoTicket.rut_reemplazo}</td>
							<td>
								<button
									className="btnBoucherClass"
									onClick={() =>
										this.peticionAnulacionModal(datoTicket.idticket)
									}
								>
									Anular ticket
								</button>
							</td>
						</tr>
					);
				}
				console.log(dataTableTickets);
				this.setState({
					dataTable: dataTableTickets,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	onGridReady = (params) => {};

	render() {
		const session = checkSession();

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-header"></div>
												<div className="card-body">
													<div class="form-group col-md-12 pull-right align-right">
														<button
															class="btn btn-inactivo btn-round"
															onClick={this.getDataFromTickets}
														>
															Actualizar
														</button>
													</div>

													<div>
														<table className="tableResult">
															<thead>
																<tr>
																	<th>Nro. Ticket</th>
																	<th>Destino</th>
																	<th className="minWidthValTicket">Valor</th>
																	<th>Fecha</th>
																	<th>Moneda</th>
																	<th>Forma de pago</th>
																	<th>Rut vendedor</th>
																	<th>Estado del ticket</th>
																	<th>Es venta de supervidor?</th>
																	<th>Reemplazo supervisor</th>
																	<th>Es venta de un reemplazante?</th>
																	<th>Reemplazo venta</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>{this.state.dataTable}</tbody>
														</table>
													</div>
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i> Buscar
														tickets
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* MODAL */}

							<Modal
								isOpen={this.state.modalIsOpen}
								onRequestClose={this.closeModal}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel=""
							>
								<div id="AnularTicketPeticionPaso1">
									<div className="headermodal">
										<button onClick={this.closeModal}>
											<i class="nc-icon nc-simple-remove"></i>
										</button>
									</div>
									<div className="containerAnulacionModal">
										<h4>
											Estás seguro que quieres anular el ticket nro.{' '}
											<span className="spanBlack">
												{this.state.ticketnroinvalidate}
											</span>{' '}
											?
										</h4>
										<div id="msgErrorPetAnul"></div>
										<br />
										<input
											type="button"
											className="btn btn-inactivo btn-round"
											onClick={this.anularTicket}
											value="Si"
										/>
										<input
											type="button"
											className="btn btn-inactivo btn-round"
											onClick={this.closeModal}
											value="No"
										/>
									</div>
								</div>
								<div id="AnularTicketPeticionFinal">
									{this.state.cambioestadopeticion ? (
										<div className="cuadraturaResponse">
											<img src={success} alt="success" />
											<p>Estado actualizado.</p>
										</div>
									) : (
										<div className="cuadraturaResponse">
											<img src={error} alt="error" />
											<p>Error al actualizar el estado</p>
										</div>
									)}
								</div>
							</Modal>

							{/* FIN MODAL */}
							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

Modal.setAppElement('body');

export default TicketsPorAnular;
