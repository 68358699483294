const LasCondes = {
    commune: "Las Condes",
    polygons: [
        {
            idService: 50,
            nameService: "Las Condes 1 / Hasta Manquehue",
            coordinates: [
                { "lat": -33.416154166091836, "lng": -70.60778820225781 }, // Tajamar (Rio Mapocho)
                { "lat": -33.41541606207833 , "lng": -70.60743702377549 },
                { "lat": -33.41284940178161 , "lng": -70.60670738530463 },
                { "lat": -33.40922114576478 , "lng": -70.60537318920389 }, // Costanera Norte
                { "lat": -33.40813350953036 , "lng": -70.60188134795048 }, // Kennedy
                { "lat": -33.40800299225436 , "lng": -70.59896279401885 }, // Kennedy
                { "lat": -33.40480960876507 , "lng": -70.5898214661455  }, // Kannedy
                { "lat": -33.4041308908543  , "lng": -70.58632962472936 }, // vespucio
                { "lat": -33.40378282834178 , "lng": -70.58522474356414 },
                { "lat": -33.39792728605065 , "lng": -70.57126815532833 }, // Kennydy con Manquehue
                { "lat": -33.408739211743146, "lng": -70.56713383319446 }, // manquehue con Apoquindo
                { "lat": -33.41658956860022 , "lng": -70.56495217650955 }, // Manquehue con Colon
                { "lat": -33.41778305604416 , "lng": -70.56450994878665 }, // manquehue
                { "lat": -33.42510357348291 , "lng": -70.5643625395605  }, // Manquehue con Isbel la catolica
                { "lat": -33.42608779767814 , "lng": -70.56397927554039 },
                { "lat": -33.43093493894062 , "lng": -70.56523225402854 }, // manquehue con Bilbao
                { "lat": -33.43172226627319 , "lng": -70.58439545433502 }, // Bilbao con Tobalaba
                { "lat": -33.41818913458276 , "lng": -70.6014493854185  }, // Metro Tobalaba
                { "lat": -33.4179616708366  , "lng": -70.60154455173209 },
                { "lat": -33.41758855744713 , "lng": -70.60242105225541 },
                { "lat": -33.41735810425367 , "lng": -70.60339396791987 },
                { "lat": -33.417197152457696, "lng": -70.60470871870486 },
                { "lat": -33.4162643577476  , "lng": -70.60665454993531 },
                { "lat": -33.416154166091836, "lng": -70.60778820225781 }, // Tajamar (Rio Mapocho)
            ]
        },
        {
            idService: 49,
            nameService: "Las Condes 2 / Hasta Estoril",
            coordinates: [
                { "lat": -33.397898932213344, "lng": -70.5711868699909  }, // Kennedy con Manquehue
                { "lat": -33.40221622258063,  "lng": -70.56959163894417 }, // Manquehue con Presidente Riesco
                { "lat": -33.405165028116514, "lng": -70.5685661333092  }, // Manquehue con Los Militares
                { "lat": -33.40677093122854,  "lng": -70.56798764292772 }, // Manquehue con San Olav
                { "lat": -33.406795542163565, "lng": -70.56790656785144 },
                { "lat": -33.40795708036496,  "lng": -70.56741889518493 }, // Manquehue con Dra. Cora Meyers
                { "lat": -33.41023970921139,  "lng": -70.56663851068514 }, // Manquehue con Los Milagros
                { "lat": -33.411135986100504, "lng": -70.56636241301211 }, // Manquehue con Maria Teresa
                { "lat": -33.41325408941709,  "lng": -70.565726950109   }, // Manquehue con Roncesvalles
                { "lat": -33.416571981605664, "lng": -70.5647189744831  }, // Manquehue con Av. Cristobal Colon
                { "lat": -33.417694988072384, "lng": -70.56425881168124 }, // Manquehue Sur
                { "lat": -33.41903745556091,  "lng": -70.56425004666437 }, // Manquehue con Cabildo
                { "lat": -33.419909314115934, "lng": -70.56425639833195 }, // Manquehue con la calesa
                { "lat": -33.42083150547327,  "lng": -70.56427463929094 }, // Manquehue con Alonso de Camargo
                { "lat": -33.4219429070415,   "lng": -70.56422773396106 }, // Manquehue con Dra. Eloisa Diaz
                { "lat": -33.423019495783485, "lng": -70.56418864618306 }, // Manquehue con Roberto Peragallo
                { "lat": -33.423819860176444, "lng": -70.56414434670353 }, // Manquehue Sur
                { "lat": -33.424085196750504, "lng": -70.56407138284676 }, // Manquehue Sur con Ing. Pedro Blanquier
                { "lat": -33.424778982786194, "lng": -70.5636700816843  }, // Manquehue Sur
                { "lat": -33.42522196233965,  "lng": -70.56359678606294 }, // Manquehue Sur con Av. Isabel la catolica
                { "lat": -33.42721386110771,  "lng": -70.56409584813017 }, // Manquehue Sur con "lat"adia
                { "lat": -33.42905975110808,  "lng": -70.56454673037948 }, // manquehue Sur con Carlos Alvarado
                { "lat": -33.43094015600258,  "lng": -70.56501708919444 }, // manquehue Sur con Bilbao
                { "lat": -33.43082463477514,  "lng": -70.5637117348376  }, // Bilbao con hernando de Magallanes
                { "lat": -33.430783481515626, "lng": -70.56238790235138 }, // Bilbao con El toqui
                { "lat": -33.43041943258229,  "lng": -70.55457766985397 }, // Bilbao con Tomas Moro
                { "lat": -33.430492242484576, "lng": -70.55435007679803 }, // Bilbao
                { "lat":-33.427758990276146, "lng":-70.53069369411081 }, // Bilbao con Padre Hurtado Sur
               {"lat":-33.41336098534528, "lng":-70.53055093424034},
               {"lat":-33.410495156165105, "lng":-70.53271815896869},
               {"lat":-33.40669778695319, "lng":-70.53402707691664},
                {"lat":-33.40094751267754, "lng":-70.52857467472431},
               { "lat": -33.39102259530155,  "lng": -70.52593633843084 }, // Estoril con las Lavandulas
               { "lat": -33.390635537491065, "lng": -70.52599488305368 }, // Estoril
               { "lat": -33.38948033921338,  "lng": -70.52710364658193 }, // estoril con Charles Hamilton
               { "lat": -33.386237920064154, "lng": -70.53107499825857 }, // Estoril
                { "lat": -33.38440337397255,  "lng": -70.53347024262753 }, // Estoril
                { "lat": -33.38395706927449,  "lng": -70.53414539645165 }, // Kennedy con Estoril  --> Revisar
                { "lat": -33.38971030133213,  "lng": -70.54817520775285 }, // Padre Hurtado Norte con Kennedy
                { "lat": -33.3901876530353,   "lng": -70.54946118904525 },
                { "lat": -33.39791394691468,  "lng": -70.57118060691083 }, // Kennedy con Manquehue
            ]
        },
        {
            idService: 36,
            nameService: "Las Condes 3 / Hasta Camino Otoñal",
            coordinates: [
                { "lat": -33.376236132806625, "lng": -70.52570335254171 }, // las Condes con San Damian
                { "lat":-33.38105239001418,   "lng":-70.52221607412692},
                { "lat": -33.38533463805954, "lng": -70.51914762698445 },
                { "lat": -33.38768679247613,  "lng": -70.51916420226456 }, // Las Lavandulas con Cam. La fuente
                { "lat": -33.387842824909335, "lng": -70.51863573363808 }, // Camino Otoñal
                { "lat": -33.38799046823851,  "lng": -70.51831624122148 }, // Cam. Otoñal
                { "lat": -33.38819012189411,  "lng": -70.51799273001436 }, // Cam. Otoñal
                { "lat": -33.38840487488916,  "lng": -70.5177315097933  }, // Cam. Otoñal
                { "lat": -33.38852231771541,  "lng": -70.51761697475634 },
                { "lat": -33.38880082433619,  "lng": -70.51744818630984 },
                { "lat": -33.38924542643617,  "lng": -70.51726734154025 },
                { "lat": -33.38964304978726,  "lng": -70.51714677835672 }, // Cam. Otoñal con Rep de Honduras
                { "lat": -33.38992490827805,  "lng": -70.5171266844996  }, // Cam. Otoñal
                { "lat": -33.39006919262397,  "lng": -70.51713673143094 },
                { "lat": -33.39020341040722,  "lng": -70.51716687222627 },
                { "lat": -33.394163856021805, "lng": -70.51896475113747 },
                { "lat": -33.394541325220644, "lng": -70.51911143633502 },
                { "lat": -33.39519224604209,  "lng": -70.51921190565153 }, // Cam. Otoñal con Cam. Las Flores
                { "lat": -33.39874424733955,  "lng": -70.51959829422265 }, // Cam. Otoñal con Los Andes Otoñal
                { "lat": -33.39996743767485,  "lng": -70.51974760503258 },
                { "lat": -33.401044406871435, "lng": -70.51975966135001 },
                { "lat": -33.40151247721111,  "lng": -70.51968982146386 }, // Cam. Otoñal con Cam. El Alba
                { "lat": -33.401827670549046, "lng": -70.5196587569611  },
                { "lat": -33.40229447375835,  "lng": -70.51954644686812 },
                { "lat": -33.40303656602001,  "lng": -70.51943413676936 },
                { "lat": -33.403488212631004, "lng": -70.51940029504728 }, // cam. Otoñal con Gral. Blanche
                { "lat": -33.403999440296836, "lng": -70.51930083549836 },
                { "lat": -33.40514507679189,  "lng": -70.51888221504014 },
                { "lat": -33.40654268059319,  "lng": -70.51805979169707 }, // Cam. Otoñal con Quebrada Onda
                { "lat": -33.408021740759736, "lng": -70.51708935066003 }, // Cam. Otoñal con La Rienda
                { "lat": -33.4101116160844,   "lng": -70.51587067985105 }, // Cam. Otoñal con La Viguela
                { "lat": -33.41024919848969,  "lng": -70.51582095007447 },
                { "lat": -33.410591967490404, "lng": -70.51573569902993 },
                { "lat": -33.41083036312632,  "lng": -70.51570728201511 }, // Cam. Otoñal con La Quincha
                { "lat": -33.411394919332515, "lng": -70.51571722796945 },
                { "lat": -33.41146252351182,  "lng": -70.51570017775491 },
                { "lat": -33.411591801520174, "lng": -70.51559787650736 },
                { "lat": -33.41165347536469,  "lng": -70.51544584548716 },
                { "lat": -33.41168431227207,  "lng": -70.51519151320609 },
                { "lat": -33.4116918132078,   "lng": -70.5138421789764  }, // Cam. Otoñal con Francisco Bulnes Correa
                { "lat": -33.411622508243376, "lng": -70.51355892806868 },
                { "lat": -33.41251541008972,  "lng": -70.51288373558201 },
                { "lat": -33.413881293038294, "lng": -70.51389624413345 },
                { "lat": -33.412908690468235, "lng": -70.51772648141139 },
                { "lat": -33.41259828309875,  "lng": -70.52200295971129 },
                { "lat": -33.41436759027137,  "lng": -70.5243581217139  },
                { "lat": -33.419210320703776, "lng": -70.5239759434392  },
                { "lat":-33.42180067019968,   "lng": -70.52238809965516 },
                { "lat": -33.42530956690055,  "lng": -70.52029503279452 },
                { "lat":-33.427758990276146, "lng":-70.53069369411081 }, // Bilbao con Padre Hurtado Sur
                { "lat":-33.41336098534528, "lng":-70.53055093424034},
                { "lat":-33.410495156165105, "lng":-70.53271815896869},
                { "lat":-33.40669778695319, "lng":-70.53402707691664},
                { "lat":-33.40094751267754, "lng":-70.52857467472431},
                { "lat": -33.39102259530155,  "lng": -70.52593633843084 }, // Estoril con las Lavandulas
                { "lat": -33.390635537491065, "lng": -70.52599488305368 }, // Estoril
                { "lat": -33.38948033921338,  "lng": -70.52710364658193 }, // estoril con Charles Hamilton
                { "lat": -33.386237920064154, "lng": -70.53107499825857 }, // Estoril
                { "lat": -33.38440337397255,  "lng": -70.53347024262753 }, // Estoril
                { "lat": -33.38395706927449,  "lng": -70.53414539645165 }, // Kennedy con Estoril  --> Revisar
            ]
        },
        {
            idService: 150,
            nameService: "Las Condes 4 / San Carlos de Apoquindo y el Remanso",
            coordinates: [
                { "lat": -33.38533463805954,  "lng": -70.51914762698445 },
                { "lat":-33.38105239001418,   "lng":-70.52221607412692},
                { "lat": -33.376236132806625, "lng": -70.52570335254171 }, // las Condes con San Damian
                { "lat": -33.3726838798242,   "lng": -70.51760311271347 },   // Las Condes con San Francisco de Asis
                { "lat": -33.372029054021795, "lng": -70.51597471650322 },
                { "lat": -33.37111876259912,  "lng": -70.51302314900181 },  // Las Condes con La Cabaña
                { "lat": -33.37074038564973,  "lng": -70.51155557315083 },  // Las Condes con Camino San Antonio
                { "lat": -33.37011249563003,  "lng": -70.50596762220786 },
                { "lat": -33.36998362681134,  "lng": -70.50544231539298 },
                { "lat": -33.36960798683308,  "lng": -70.50444423251002 },  // Las Condes Con San Jose de la Cierra
                { "lat": -33.3682904739634,   "lng": -70.5016733852939 }, //las condes con quinchamali   
                { "lat": -33.36357449951662,  "lng": -70.49415052023419 }, // Plaza san enrique
                { "lat": -33.37286790108932,  "lng": -70.49002239649114 }, //FIN QUINCHAMALI
                { "lat": -33.375255128008774, "lng": -70.49067972384906 },
                { "lat": -33.38285244845618,  "lng": -70.49514291948324 },
                { "lat": -33.38554002085851,  "lng": -70.49042223164062 },
                { "lat": -33.3971852077146,   "lng": -70.49419878194199 },
                { "lat": -33.40198615363272,  "lng": -70.50046442211159 },
                { "lat": -33.409115427067675, "lng": -70.49994943803122 },
                { "lat": -33.41989778899429,  "lng": -70.50703046984404 },
                { "lat": -33.42503448141818,  "lng": -70.51917814649252 },
                { "lat": -33.42530956690055,  "lng": -70.52029503279452 },
                { "lat":-33.42180067019968,   "lng": -70.52238809965516 },
                { "lat": -33.419210320703776, "lng": -70.5239759434392  },
                { "lat": -33.41436759027137,  "lng": -70.5243581217139  },
                { "lat": -33.41259828309875,  "lng": -70.52200295971129 },
                { "lat": -33.412908690468235, "lng": -70.51772648141139 },
                { "lat": -33.413881293038294, "lng": -70.51389624413345 },
                { "lat": -33.41251541008972,  "lng": -70.51288373558201 },
                { "lat": -33.411622508243376, "lng": -70.51355892806868 },
                { "lat": -33.4116918132078,   "lng": -70.5138421789764  }, // Cam. Otoñal con Francisco Bulnes Correa
                { "lat": -33.41168431227207,  "lng": -70.51519151320609 },
                { "lat": -33.41165347536469,  "lng": -70.51544584548716 },
                { "lat": -33.411591801520174, "lng": -70.51559787650736 },
                { "lat": -33.41146252351182,  "lng": -70.51570017775491 },
                { "lat": -33.411394919332515, "lng": -70.51571722796945 },
                { "lat": -33.41083036312632,  "lng": -70.51570728201511 }, // Cam. Otoñal con La Quincha
                { "lat": -33.410591967490404, "lng": -70.51573569902993 },
                { "lat": -33.41024919848969,  "lng": -70.51582095007447 },
                { "lat": -33.4101116160844,   "lng": -70.51587067985105 }, // Cam. Otoñal con La Viguela
                { "lat": -33.408021740759736, "lng": -70.51708935066003 }, // Cam. Otoñal con La Rienda
                { "lat": -33.40654268059319,  "lng": -70.51805979169707 }, // Cam. Otoñal con Quebrada Onda
                { "lat": -33.40514507679189,  "lng": -70.51888221504014 },
                { "lat": -33.403999440296836, "lng": -70.51930083549836 },
                { "lat": -33.403488212631004, "lng": -70.51940029504728 }, // cam. Otoñal con Gral. Blanche
                { "lat": -33.40303656602001,  "lng": -70.51943413676936 },
                { "lat": -33.40229447375835,  "lng": -70.51954644686812 },
                { "lat": -33.401827670549046, "lng": -70.5196587569611  },
                { "lat": -33.40151247721111,  "lng": -70.51968982146386 }, // Cam. Otoñal con Cam. El Alba
                { "lat": -33.401044406871435, "lng": -70.51975966135001 },
                { "lat": -33.39996743767485,  "lng": -70.51974760503258 },
                { "lat": -33.39874424733955,  "lng": -70.51959829422265 }, // Cam. Otoñal con Los Andes Otoñal
                { "lat": -33.39519224604209,  "lng": -70.51921190565153 }, // Cam. Otoñal con Cam. Las Flores
                { "lat": -33.394541325220644, "lng": -70.51911143633502 },
                { "lat": -33.394163856021805, "lng": -70.51896475113747 },
                { "lat": -33.39020341040722,  "lng": -70.51716687222627 },
                { "lat": -33.39006919262397,  "lng": -70.51713673143094 },
                { "lat": -33.38992490827805,  "lng": -70.5171266844996  }, // Cam. Otoñal
                { "lat": -33.38964304978726,  "lng": -70.51714677835672 }, // Cam. Otoñal con Rep de Honduras
                { "lat": -33.38924542643617,  "lng": -70.51726734154025 },
                { "lat": -33.38880082433619,  "lng": -70.51744818630984 },
                { "lat": -33.38852231771541,  "lng": -70.51761697475634 },
                { "lat": -33.38840487488916,  "lng": -70.5177315097933  }, // Cam. Otoñal
                { "lat": -33.38819012189411,  "lng": -70.51799273001436 }, // Cam. Otoñal
                { "lat": -33.38799046823851,  "lng": -70.51831624122148 }, // Cam. Otoñal
                { "lat": -33.387842824909335, "lng": -70.51863573363808 }, // Camino Otoñal
                { "lat": -33.38768679247613,  "lng": -70.51916420226456 }, // Las Lavandulas con Cam. La fuente
            ]
        },
        {
            idService: 72,
            nameService: "El Arrayan",
            coordinates: [

                { "lat": -33.36357449951662,  "lng": -70.49415052023419 }, // Plaza san enrique
                { "lat": -33.36220015961394,  "lng": -70.49334649053843 },
                { "lat": -33.361028437694515, "lng": -70.49240821919524 }, // cerro 1
                { "lat": -33.360662977788735, "lng": -70.49174962489256 },
                { "lat": -33.36034272815833,  "lng": -70.49165940650111 },
                { "lat": -33.3599358210385,   "lng": -70.49134364210148 },
                { "lat": -33.359268941388486, "lng": -70.49098276852435 },
                { "lat": -33.358831887534365, "lng": -70.49100983403764 },
                { "lat": -33.3584626506702,   "lng": -70.49083390815626 },
                { "lat": -33.35760737151692,  "lng": -70.49056776387812 },
                { "lat": -33.35685758243986,  "lng": -70.49059482940012 },
                { "lat": -33.356107786902996, "lng": -70.48998134430887 },
                { "lat": -33.35273996429692,  "lng": -70.48883419145243 },
                { "lat": -33.34972560449563,  "lng": -70.49100993433798 },
                { "lat": -33.33866564032407,  "lng": -70.49038766183558 },
                { "lat": -33.32632607131169,  "lng": -70.48032989714798 },
                { "lat": -33.32808536518135,  "lng": -70.47137441299978 },
                { "lat": -33.334567882339336, "lng": -70.47101353942263 },
                { "lat": -33.33113070072197,  "lng": -70.45971819603356 },
                { "lat": -33.34456352240545,  "lng": -70.46124120625288 },
                { "lat": -33.34848250025667,  "lng": -70.46925259986081 },
                { "lat": -33.370998130042686, "lng": -70.46369514684643 }, // clinica monteblanco
                { "lat": -33.37240595498719,  "lng": -70.48022569280994 },

            ]
        },
    ]
};

export default LasCondes;
