const Papudo = {
    commune: "Papudo",
    polygons: [
        {
            idService: 24,
            nameService: "Papudo / Zapallar",
            coordinates: [
                {"lng" : -71.4631269, "lat" : -32.5003358},
                {"lng" : -71.4612732, "lat" : -32.5025863},
                {"lng" : -71.4609299, "lat" : -32.5035997},
                {"lng" : -71.4604149, "lat" : -32.5066399},
                {"lng" : -71.4604149, "lat" : -32.5105485},
                {"lng" : -71.4602433, "lat" : -32.5124304},
                {"lng" : -71.459385, "lat" : -32.5148912},
                {"lng" : -71.4595566, "lat" : -32.516773},
                {"lng" : -71.4586983, "lat" : -32.5195232},
                {"lng" : -71.4569817, "lat" : -32.5216944},
                {"lng" : -71.4562951, "lat" : -32.5251682},
                {"lng" : -71.4544068, "lat" : -32.5284971},
                {"lng" : -71.4540635, "lat" : -32.5313917},
                {"lng" : -71.4542351, "lat" : -32.5339967},
                {"lng" : -71.4535485, "lat" : -32.5366018},
                {"lng" : -71.4524427, "lat" : -32.5368855},
                {"lng" : -71.4517054, "lat" : -32.5370747},
                {"lng" : -71.4496003, "lat" : -32.5376148},
                {"lng" : -71.447722, "lat" : -32.5382481},
                {"lng" : -71.446167, "lat" : -32.5387724},
                {"lng" : -71.4425621, "lat" : -32.5396407},
                {"lng" : -71.4396439, "lat" : -32.5402196},
                {"lng" : -71.4391289, "lat" : -32.5410879},
                {"lng" : -71.437584, "lat" : -32.543982},
                {"lng" : -71.4353524, "lat" : -32.5462973},
                {"lng" : -71.4332924, "lat" : -32.547889},
                {"lng" : -71.4302025, "lat" : -32.5484678},
                {"lng" : -71.4260827, "lat" : -32.5502042},
                {"lng" : -71.4238511, "lat" : -32.5517959},
                {"lng" : -71.4200745, "lat" : -32.5525193},
                {"lng" : -71.4159546, "lat" : -32.5520853},
                {"lng" : -71.4121781, "lat" : -32.5516512},
                {"lng" : -71.4094315, "lat" : -32.5497701},
                {"lng" : -71.4080582, "lat" : -32.5494807},
                {"lng" : -71.4058266, "lat" : -32.5510724},
                {"lng" : -71.4034234, "lat" : -32.5523746},
                {"lng" : -71.4005051, "lat" : -32.5529534},
                {"lng" : -71.3977585, "lat" : -32.552664},
                {"lng" : -71.394497, "lat" : -32.55223},
                {"lng" : -71.3907204, "lat" : -32.5509277},
                {"lng" : -71.3886605, "lat" : -32.5513618},
                {"lng" : -71.3857422, "lat" : -32.5506383},
                {"lng" : -71.3836823, "lat" : -32.5499148},
                {"lng" : -71.3805924, "lat" : -32.5477443},
                {"lng" : -71.3785325, "lat" : -32.5455738},
                {"lng" : -71.3764725, "lat" : -32.5431138},
                {"lng" : -71.3733826, "lat" : -32.5422455},
                {"lng" : -71.3713227, "lat" : -32.5400749},
                {"lng" : -71.3714943, "lat" : -32.5381936},
                {"lng" : -71.3718377, "lat" : -32.5342862},
                {"lng" : -71.372181, "lat" : -32.5313917},
                {"lng" : -71.3692627, "lat" : -32.5286418},
                {"lng" : -71.3675461, "lat" : -32.526905},
                {"lng" : -71.3651429, "lat" : -32.5224181},
                {"lng" : -71.3635979, "lat" : -32.5203917},
                {"lng" : -71.359478, "lat" : -32.51851},
                {"lng" : -71.3567315, "lat" : -32.5174968},
                {"lng" : -71.3532982, "lat" : -32.5153255},
                {"lng" : -71.3507233, "lat" : -32.5143122},
                {"lng" : -71.3466034, "lat" : -32.5132989},
                {"lng" : -71.3431702, "lat" : -32.5109828},
                {"lng" : -71.339222, "lat" : -32.510838},
                {"lng" : -71.3364754, "lat" : -32.5104038},
                {"lng" : -71.3321839, "lat" : -32.5082323},
                {"lng" : -71.3263474, "lat" : -32.5073637},
                {"lng" : -71.3241158, "lat" : -32.5073637},
                {"lng" : -71.3206826, "lat" : -32.5056265},
                {"lng" : -71.3194809, "lat" : -32.5025863},
                {"lng" : -71.3194809, "lat" : -32.5002699},
                {"lng" : -71.316906, "lat" : -32.4996908},
                {"lng" : -71.3127861, "lat" : -32.4986774},
                {"lng" : -71.3093529, "lat" : -32.4988221},
                {"lng" : -71.3036881, "lat" : -32.4983878},
                {"lng" : -71.3008742, "lat" : -32.4980532},
                {"lng" : -71.3008141, "lat" : -32.4957747},
                {"lng" : -71.2996468, "lat" : -32.4934626},
                {"lng" : -71.2978516, "lat" : -32.4918724},
                {"lng" : -71.2987099, "lat" : -32.4895558},
                {"lng" : -71.3007699, "lat" : -32.486515},
                {"lng" : -71.3021006, "lat" : -32.485251},
                {"lng" : -71.3033032, "lat" : -32.484564},
                {"lng" : -71.3042281, "lat" : -32.4837795},
                {"lng" : -71.304433, "lat" : -32.4830953},
                {"lng" : -71.3042913, "lat" : -32.4823865},
                {"lng" : -71.3041541, "lat" : -32.4814526},
                {"lng" : -71.3044866, "lat" : -32.4806452},
                {"lng" : -71.3043525, "lat" : -32.4787772},
                {"lng" : -71.3052151, "lat" : -32.4767154},
                {"lng" : -71.3073663, "lat" : -32.4751768},
                {"lng" : -71.3083844, "lat" : -32.4738507},
                {"lng" : -71.3102112, "lat" : -32.4729029},
                {"lng" : -71.3120995, "lat" : -32.4715995},
                {"lng" : -71.3143861, "lat" : -32.4713525},
                {"lng" : -71.3166059, "lat" : -32.4698446},
                {"lng" : -71.3196464, "lat" : -32.4672096},
                {"lng" : -71.3201238, "lat" : -32.4666022},
                {"lng" : -71.3196958, "lat" : -32.4627514},
                {"lng" : -71.3197473, "lat" : -32.462134},
                {"lng" : -71.3220559, "lat" : -32.4584196},
                {"lng" : -71.3237725, "lat" : -32.4556675},
                {"lng" : -71.3241922, "lat" : -32.4537086},
                {"lng" : -71.3237244, "lat" : -32.4517186},
                {"lng" : -71.3232095, "lat" : -32.4496662},
                {"lng" : -71.3223705, "lat" : -32.4472579},
                {"lng" : -71.3222664, "lat" : -32.4462909},
                {"lng" : -71.3225133, "lat" : -32.4452434},
                {"lng" : -71.3212666, "lat" : -32.4418181},
                {"lng" : -71.3207573, "lat" : -32.4420348},
                {"lng" : -71.3206532, "lat" : -32.4420873},
                {"lng" : -71.3195905, "lat" : -32.4426225},
                {"lng" : -71.3193435, "lat" : -32.4429374},
                {"lng" : -71.3189044, "lat" : -32.4438808},
                {"lng" : -71.3173207, "lat" : -32.445423},
                {"lng" : -71.3169058, "lat" : -32.4456748},
                {"lng" : -71.3162448, "lat" : -32.4460053},
                {"lng" : -71.3158794, "lat" : -32.4461264},
                {"lng" : -71.3146008, "lat" : -32.4462427},
                {"lng" : -71.314148, "lat" : -32.4464193},
                {"lng" : -71.3137096, "lat" : -32.4465047},
                {"lng" : -71.3134728, "lat" : -32.4465234},
                {"lng" : -71.3132233, "lat" : -32.4464667},
                {"lng" : -71.3128663, "lat" : -32.4462378},
                {"lng" : -71.3117248, "lat" : -32.4447986},
                {"lng" : -71.3112945, "lat" : -32.4444692},
                {"lng" : -71.3110575, "lat" : -32.444301},
                {"lng" : -71.3104894, "lat" : -32.4440792},
                {"lng" : -71.3090767, "lat" : -32.4433332},
                {"lng" : -71.3086019, "lat" : -32.4429848},
                {"lng" : -71.3082241, "lat" : -32.442573},
                {"lng" : -71.3078809, "lat" : -32.4424065},
                {"lng" : -71.3076655, "lat" : -32.4423163},
                {"lng" : -71.3071002, "lat" : -32.4422102},
                {"lng" : -71.3061435, "lat" : -32.4422761},
                {"lng" : -71.3044527, "lat" : -32.4424752},
                {"lng" : -71.3041157, "lat" : -32.4424584},
                {"lng" : -71.3037913, "lat" : -32.4423317},
                {"lng" : -71.3034555, "lat" : -32.4420334},
                {"lng" : -71.3029282, "lat" : -32.4416703},
                {"lng" : -71.3022892, "lat" : -32.4413236},
                {"lng" : -71.3017258, "lat" : -32.441126},
                {"lng" : -71.3012649, "lat" : -32.4411422},
                {"lng" : -71.3004002, "lat" : -32.4413554},
                {"lng" : -71.2996136, "lat" : -32.4413301},
                {"lng" : -71.299287, "lat" : -32.4411476},
                {"lng" : -71.2988314, "lat" : -32.4406658},
                {"lng" : -71.2984405, "lat" : -32.4401579},
                {"lng" : -71.2980839, "lat" : -32.4395449},
                {"lng" : -71.2978003, "lat" : -32.439377},
                {"lng" : -71.2976268, "lat" : -32.4393531},
                {"lng" : -71.2974694, "lat" : -32.439414},
                {"lng" : -71.2973431, "lat" : -32.4395479},
                {"lng" : -71.296714, "lat" : -32.4409609},
                {"lng" : -71.2963445, "lat" : -32.4412561},
                {"lng" : -71.2955692, "lat" : -32.4413098},
                {"lng" : -71.2950884, "lat" : -32.4412604},
                {"lng" : -71.294778, "lat" : -32.4411739},
                {"lng" : -71.2944122, "lat" : -32.4409746},
                {"lng" : -71.2937536, "lat" : -32.4410055},
                {"lng" : -71.2931596, "lat" : -32.4414029},
                {"lng" : -71.292221, "lat" : -32.4423912},
                {"lng" : -71.2921624, "lat" : -32.4424909},
                {"lng" : -71.2922033, "lat" : -32.4432193},
                {"lng" : -71.2920602, "lat" : -32.4434424},
                {"lng" : -71.2918409, "lat" : -32.4435459},
                {"lng" : -71.2903629, "lat" : -32.4436469},
                {"lng" : -71.2894772, "lat" : -32.4439869},
                {"lng" : -71.2886023, "lat" : -32.4439555},
                {"lng" : -71.2879483, "lat" : -32.4438568},
                {"lng" : -71.28762, "lat" : -32.4437193},
                {"lng" : -71.2871775, "lat" : -32.4434494},
                {"lng" : -71.286905, "lat" : -32.4431391},
                {"lng" : -71.2860537, "lat" : -32.4424884},
                {"lng" : -71.2858558, "lat" : -32.4423677},
                {"lng" : -71.2852795, "lat" : -32.4421425},
                {"lng" : -71.2848121, "lat" : -32.4418788},
                {"lng" : -71.2837489, "lat" : -32.4416388},
                {"lng" : -71.2860213, "lat" : -32.4412566},
                {"lng" : -71.2894931, "lat" : -32.4399926},
                {"lng" : -71.2912698, "lat" : -32.4388046},
                {"lng" : -71.2938362, "lat" : -32.4371639},
                {"lng" : -71.2958703, "lat" : -32.4368958},
                {"lng" : -71.2990546, "lat" : -32.4354144},
                {"lng" : -71.3010587, "lat" : -32.4365373},
                {"lng" : -71.3030543, "lat" : -32.4368777},
                {"lng" : -71.3045907, "lat" : -32.4360121},
                {"lng" : -71.3061056, "lat" : -32.4300283},
                {"lng" : -71.3082858, "lat" : -32.4273985},
                {"lng" : -71.314487, "lat" : -32.41819},
                {"lng" : -71.3181263, "lat" : -32.4140056},
                {"lng" : -71.3194609, "lat" : -32.411216},
                {"lng" : -71.3217827, "lat" : -32.4074298},
                {"lng" : -71.3228082, "lat" : -32.4055639},
                {"lng" : -71.3243919, "lat" : -32.4045494},
                {"lng" : -71.3256535, "lat" : -32.4033935},
                {"lng" : -71.3331036, "lat" : -32.4085204},
                {"lng" : -71.3388114, "lat" : -32.4115239},
                {"lng" : -71.3429312, "lat" : -32.4144839},
                {"lng" : -71.3482699, "lat" : -32.4100131},
                {"lng" : -71.3693875, "lat" : -32.4023252},
                {"lng" : -71.3713042, "lat" : -32.4027994},
                {"lng" : -71.3742262, "lat" : -32.403317},
                {"lng" : -71.3755072, "lat" : -32.4035557},
                {"lng" : -71.3777581, "lat" : -32.4047533},
                {"lng" : -71.3801276, "lat" : -32.40564},
                {"lng" : -71.3820913, "lat" : -32.404671},
                {"lng" : -71.3847442, "lat" : -32.403669},
                {"lng" : -71.3864731, "lat" : -32.4030535},
                {"lng" : -71.3870353, "lat" : -32.4024924},
                {"lng" : -71.3877477, "lat" : -32.4014543},
                {"lng" : -71.3894783, "lat" : -32.4023342},
                {"lng" : -71.391652, "lat" : -32.4025753},
                {"lng" : -71.3958394, "lat" : -32.4021867},
                {"lng" : -71.4005349, "lat" : -32.4009071},
                {"lng" : -71.4036563, "lat" : -32.4001667},
                {"lng" : -71.4041676, "lat" : -32.400261},
                {"lng" : -71.4047651, "lat" : -32.4006721},
                {"lng" : -71.4050877, "lat" : -32.4013166},
                {"lng" : -71.4051156, "lat" : -32.4018304},
                {"lng" : -71.4050632, "lat" : -32.4025682},
                {"lng" : -71.4055493, "lat" : -32.4025779},
                {"lng" : -71.407689, "lat" : -32.4027597},
                {"lng" : -71.4079142, "lat" : -32.4036973},
                {"lng" : -71.4080752, "lat" : -32.4046846},
                {"lng" : -71.4084453, "lat" : -32.4056946},
                {"lng" : -71.4090247, "lat" : -32.407076},
                {"lng" : -71.4096792, "lat" : -32.4082308},
                {"lng" : -71.4112992, "lat" : -32.4108622},
                {"lng" : -71.4122057, "lat" : -32.4116729},
                {"lng" : -71.4127582, "lat" : -32.4121891},
                {"lng" : -71.4131069, "lat" : -32.4125514},
                {"lng" : -71.4132625, "lat" : -32.4126918},
                {"lng" : -71.4134127, "lat" : -32.412805},
                {"lng" : -71.4146149, "lat" : -32.4116346},
                {"lng" : -71.4147071, "lat" : -32.4111855},
                {"lng" : -71.4148035, "lat" : -32.4109101},
                {"lng" : -71.41516, "lat" : -32.4105376},
                {"lng" : -71.4156342, "lat" : -32.4106205},
                {"lng" : -71.4158783, "lat" : -32.4109302},
                {"lng" : -71.4159622, "lat" : -32.4113448},
                {"lng" : -71.4160413, "lat" : -32.4115097},
                {"lng" : -71.4161351, "lat" : -32.4113331},
                {"lng" : -71.4163464, "lat" : -32.4108226},
                {"lng" : -71.4166287, "lat" : -32.410878},
                {"lng" : -71.4169264, "lat" : -32.4110251},
                {"lng" : -71.4172214, "lat" : -32.4112878},
                {"lng" : -71.4172456, "lat" : -32.4115437},
                {"lng" : -71.4175084, "lat" : -32.4117248},
                {"lng" : -71.4178687, "lat" : -32.4117809},
                {"lng" : -71.4182809, "lat" : -32.4117158},
                {"lng" : -71.418798, "lat" : -32.4114939},
                {"lng" : -71.4188656, "lat" : -32.4112606},
                {"lng" : -71.4190426, "lat" : -32.4111519},
                {"lng" : -71.419158, "lat" : -32.4110297},
                {"lng" : -71.4192948, "lat" : -32.4109482},
                {"lng" : -71.419394, "lat" : -32.410887},
                {"lng" : -71.4194906, "lat" : -32.4108802},
                {"lng" : -71.4195818, "lat" : -32.4108078},
                {"lng" : -71.4196247, "lat" : -32.4107421},
                {"lng" : -71.4196917, "lat" : -32.4106923},
                {"lng" : -71.4197944, "lat" : -32.4106424},
                {"lng" : -71.420031, "lat" : -32.4106637},
                {"lng" : -71.4199857, "lat" : -32.4108674},
                {"lng" : -71.419825, "lat" : -32.4110539},
                {"lng" : -71.4198707, "lat" : -32.4111775},
                {"lng" : -71.4200428, "lat" : -32.4112721},
                {"lng" : -71.4202799, "lat" : -32.41119},
                {"lng" : -71.4204405, "lat" : -32.4108589},
                {"lng" : -71.4204086, "lat" : -32.410754},
                {"lng" : -71.4206171, "lat" : -32.4104568},
                {"lng" : -71.4209336, "lat" : -32.4103051},
                {"lng" : -71.4210328, "lat" : -32.4103798},
                {"lng" : -71.4213601, "lat" : -32.4104772},
                {"lng" : -71.4215425, "lat" : -32.410536},
                {"lng" : -71.4217597, "lat" : -32.4104568},
                {"lng" : -71.4218938, "lat" : -32.4100764},
                {"lng" : -71.4220414, "lat" : -32.409791},
                {"lng" : -71.4221647, "lat" : -32.4097027},
                {"lng" : -71.4224169, "lat" : -32.4096982},
                {"lng" : -71.4227575, "lat" : -32.4096439},
                {"lng" : -71.4231437, "lat" : -32.4094265},
                {"lng" : -71.4234495, "lat" : -32.4095284},
                {"lng" : -71.4235112, "lat" : -32.4095759},
                {"lng" : -71.4237097, "lat" : -32.40932},
                {"lng" : -71.423876, "lat" : -32.4092951},
                {"lng" : -71.4239833, "lat" : -32.4092725},
                {"lng" : -71.4241853, "lat" : -32.40905},
                {"lng" : -71.4240885, "lat" : -32.4094697},
                {"lng" : -71.4243266, "lat" : -32.4093382},
                {"lng" : -71.4244021, "lat" : -32.4092426},
                {"lng" : -71.4245009, "lat" : -32.4093336},
                {"lng" : -71.4245126, "lat" : -32.4094446},
                {"lng" : -71.4247188, "lat" : -32.409414},
                {"lng" : -71.424441, "lat" : -32.4097865},
                {"lng" : -71.4242873, "lat" : -32.409897},
                {"lng" : -71.4245603, "lat" : -32.4099158},
                {"lng" : -71.4243482, "lat" : -32.4101051},
                {"lng" : -71.4241551, "lat" : -32.4101047},
                {"lng" : -71.4239246, "lat" : -32.4101862},
                {"lng" : -71.424048, "lat" : -32.4103836},
                {"lng" : -71.4240825, "lat" : -32.4105632},
                {"lng" : -71.4239296, "lat" : -32.4108236},
                {"lng" : -71.4239457, "lat" : -32.4108417},
                {"lng" : -71.424045, "lat" : -32.4108372},
                {"lng" : -71.4244339, "lat" : -32.4107285},
                {"lng" : -71.4245573, "lat" : -32.4106742},
                {"lng" : -71.4248299, "lat" : -32.4108295},
                {"lng" : -71.4249538, "lat" : -32.4110942},
                {"lng" : -71.424863, "lat" : -32.4113399},
                {"lng" : -71.4249757, "lat" : -32.4115505},
                {"lng" : -71.424973, "lat" : -32.4116886},
                {"lng" : -71.4250132, "lat" : -32.4118901},
                {"lng" : -71.4255846, "lat" : -32.4118335},
                {"lng" : -71.4258099, "lat" : -32.4117633},
                {"lng" : -71.4259011, "lat" : -32.4119535},
                {"lng" : -71.4261237, "lat" : -32.4119898},
                {"lng" : -71.425955, "lat" : -32.4123171},
                {"lng" : -71.425495, "lat" : -32.4126708},
                {"lng" : -71.4255321, "lat" : -32.4128821},
                {"lng" : -71.4254934, "lat" : -32.4130178},
                {"lng" : -71.4256382, "lat" : -32.4132125},
                {"lng" : -71.4257656, "lat" : -32.4136102},
                {"lng" : -71.4263141, "lat" : -32.4134095},
                {"lng" : -71.4266923, "lat" : -32.4133461},
                {"lng" : -71.4266727, "lat" : -32.4132201},
                {"lng" : -71.4268021, "lat" : -32.413609},
                {"lng" : -71.4267758, "lat" : -32.4139244},
                {"lng" : -71.4267678, "lat" : -32.4142668},
                {"lng" : -71.4266521, "lat" : -32.4145394},
                {"lng" : -71.426695, "lat" : -32.4146119},
                {"lng" : -71.4267755, "lat" : -32.4146119},
                {"lng" : -71.4267996, "lat" : -32.4146028},
                {"lng" : -71.4268552, "lat" : -32.4143988},
                {"lng" : -71.4271154, "lat" : -32.4142745},
                {"lng" : -71.427165, "lat" : -32.4144773},
                {"lng" : -71.4269799, "lat" : -32.4148462},
                {"lng" : -71.4271878, "lat" : -32.41493},
                {"lng" : -71.4272998, "lat" : -32.4152401},
                {"lng" : -71.4272721, "lat" : -32.4155851},
                {"lng" : -71.4271909, "lat" : -32.4158244},
                {"lng" : -71.427307, "lat" : -32.4161678},
                {"lng" : -71.4275184, "lat" : -32.4160135},
                {"lng" : -71.4274594, "lat" : -32.4163599},
                {"lng" : -71.4270785, "lat" : -32.4164708},
                {"lng" : -71.4273647, "lat" : -32.4166674},
                {"lng" : -71.4276754, "lat" : -32.4167766},
                {"lng" : -71.4277766, "lat" : -32.4170731},
                {"lng" : -71.4281858, "lat" : -32.4168703},
                {"lng" : -71.428324, "lat" : -32.4169379},
                {"lng" : -71.4284186, "lat" : -32.4172398},
                {"lng" : -71.4278135, "lat" : -32.4178747},
                {"lng" : -71.4278215, "lat" : -32.4178724},
                {"lng" : -71.4277196, "lat" : -32.4183547},
                {"lng" : -71.4275801, "lat" : -32.4186083},
                {"lng" : -71.4274004, "lat" : -32.4190679},
                {"lng" : -71.4273414, "lat" : -32.4192219},
                {"lng" : -71.427277, "lat" : -32.4194279},
                {"lng" : -71.4276364, "lat" : -32.4194369},
                {"lng" : -71.427564, "lat" : -32.4196339},
                {"lng" : -71.4274111, "lat" : -32.4198603},
                {"lng" : -71.4270866, "lat" : -32.4202271},
                {"lng" : -71.4269605, "lat" : -32.4205305},
                {"lng" : -71.4261498, "lat" : -32.4216304},
                {"lng" : -71.4258143, "lat" : -32.4219465},
                {"lng" : -71.4259965, "lat" : -32.422096},
                {"lng" : -71.425917, "lat" : -32.4223119},
                {"lng" : -71.4254183, "lat" : -32.4225818},
                {"lng" : -71.425311, "lat" : -32.4226498},
                {"lng" : -71.4252037, "lat" : -32.4227675},
                {"lng" : -71.4251876, "lat" : -32.4228309},
                {"lng" : -71.4254585, "lat" : -32.4228762},
                {"lng" : -71.425775, "lat" : -32.4230301},
                {"lng" : -71.4259949, "lat" : -32.4236686},
                {"lng" : -71.4262491, "lat" : -32.4239982},
                {"lng" : -71.4264607, "lat" : -32.4241207},
                {"lng" : -71.4265423, "lat" : -32.4239481},
                {"lng" : -71.4266253, "lat" : -32.4237614},
                {"lng" : -71.4267889, "lat" : -32.4236075},
                {"lng" : -71.4269072, "lat" : -32.4236986},
                {"lng" : -71.4271255, "lat" : -32.4238002},
                {"lng" : -71.427123, "lat" : -32.4241544},
                {"lng" : -71.4272318, "lat" : -32.4237646},
                {"lng" : -71.4273325, "lat" : -32.4236014},
                {"lng" : -71.427482, "lat" : -32.4235479},
                {"lng" : -71.4275935, "lat" : -32.423415},
                {"lng" : -71.4278138, "lat" : -32.4236562},
                {"lng" : -71.4278891, "lat" : -32.4238273},
                {"lng" : -71.4280723, "lat" : -32.4236134},
                {"lng" : -71.428345, "lat" : -32.4236961},
                {"lng" : -71.4285364, "lat" : -32.4237032},
                {"lng" : -71.4285488, "lat" : -32.4239437},
                {"lng" : -71.4286426, "lat" : -32.4239783},
                {"lng" : -71.4288389, "lat" : -32.4248714},
                {"lng" : -71.4287659, "lat" : -32.4251399},
                {"lng" : -71.4287254, "lat" : -32.4252984},
                {"lng" : -71.4285629, "lat" : -32.4253334},
                {"lng" : -71.4286524, "lat" : -32.4255705},
                {"lng" : -71.4285143, "lat" : -32.4256978},
                {"lng" : -71.4281734, "lat" : -32.4259886},
                {"lng" : -71.4284947, "lat" : -32.4259101},
                {"lng" : -71.4286514, "lat" : -32.4263298},
                {"lng" : -71.4288695, "lat" : -32.4263523},
                {"lng" : -71.429055, "lat" : -32.426265},
                {"lng" : -71.4290942, "lat" : -32.4263836},
                {"lng" : -71.4289105, "lat" : -32.4264292},
                {"lng" : -71.4290095, "lat" : -32.4266339},
                {"lng" : -71.4288971, "lat" : -32.4268249},
                {"lng" : -71.4291277, "lat" : -32.426754},
                {"lng" : -71.4291171, "lat" : -32.4268523},
                {"lng" : -71.4289427, "lat" : -32.4270392},
                {"lng" : -71.4290684, "lat" : -32.4272027},
                {"lng" : -71.4292858, "lat" : -32.4268795},
                {"lng" : -71.4293335, "lat" : -32.4271125},
                {"lng" : -71.4294706, "lat" : -32.4270298},
                {"lng" : -71.4295457, "lat" : -32.4272282},
                {"lng" : -71.4296207, "lat" : -32.4274265},
                {"lng" : -71.4296501, "lat" : -32.4275789},
                {"lng" : -71.4294681, "lat" : -32.4278685},
                {"lng" : -71.4296601, "lat" : -32.4278768},
                {"lng" : -71.429722, "lat" : -32.428187},
                {"lng" : -71.4295514, "lat" : -32.4284077},
                {"lng" : -71.4293807, "lat" : -32.4284088},
                {"lng" : -71.4292509, "lat" : -32.4284934},
                {"lng" : -71.4292713, "lat" : -32.4287106},
                {"lng" : -71.4294056, "lat" : -32.4288044},
                {"lng" : -71.4293977, "lat" : -32.4288683},
                {"lng" : -71.4293172, "lat" : -32.4289869},
                {"lng" : -71.4293343, "lat" : -32.4291604},
                {"lng" : -71.4294323, "lat" : -32.4291545},
                {"lng" : -71.4295547, "lat" : -32.4292447},
                {"lng" : -71.4293741, "lat" : -32.4294276},
                {"lng" : -71.4292423, "lat" : -32.4296106},
                {"lng" : -71.4295293, "lat" : -32.429447},
                {"lng" : -71.4296292, "lat" : -32.4294069},
                {"lng" : -71.4294679, "lat" : -32.4297408},
                {"lng" : -71.4291779, "lat" : -32.4300378},
                {"lng" : -71.4291344, "lat" : -32.4303025},
                {"lng" : -71.4295027, "lat" : -32.4300633},
                {"lng" : -71.4296703, "lat" : -32.4300926},
                {"lng" : -71.4296476, "lat" : -32.4302611},
                {"lng" : -71.4291935, "lat" : -32.4304812},
                {"lng" : -71.4289833, "lat" : -32.4307149},
                {"lng" : -71.428693, "lat" : -32.4307706},
                {"lng" : -71.4283077, "lat" : -32.4311427},
                {"lng" : -71.4289944, "lat" : -32.4310748},
                {"lng" : -71.42899, "lat" : -32.4311577},
                {"lng" : -71.4288655, "lat" : -32.4313225},
                {"lng" : -71.4286597, "lat" : -32.4314185},
                {"lng" : -71.428474, "lat" : -32.4316503},
                {"lng" : -71.4287111, "lat" : -32.4317724},
                {"lng" : -71.4285999, "lat" : -32.4317969},
                {"lng" : -71.428345, "lat" : -32.4316949},
                {"lng" : -71.4283719, "lat" : -32.4320125},
                {"lng" : -71.4281654, "lat" : -32.4320713},
                {"lng" : -71.4280289, "lat" : -32.4319967},
                {"lng" : -71.4278697, "lat" : -32.432067},
                {"lng" : -71.4279904, "lat" : -32.4321665},
                {"lng" : -71.4284201, "lat" : -32.432163},
                {"lng" : -71.4285082, "lat" : -32.4322831},
                {"lng" : -71.4286281, "lat" : -32.4325573},
                {"lng" : -71.4285102, "lat" : -32.4327901},
                {"lng" : -71.4279815, "lat" : -32.4328164},
                {"lng" : -71.4280748, "lat" : -32.433006},
                {"lng" : -71.428392, "lat" : -32.4332138},
                {"lng" : -71.4284531, "lat" : -32.4334344},
                {"lng" : -71.4281146, "lat" : -32.4337263},
                {"lng" : -71.428417, "lat" : -32.4335702},
                {"lng" : -71.4285519, "lat" : -32.4335677},
                {"lng" : -71.4287682, "lat" : -32.433524},
                {"lng" : -71.4290833, "lat" : -32.4335999},
                {"lng" : -71.4289316, "lat" : -32.4338916},
                {"lng" : -71.4288124, "lat" : -32.4340322},
                {"lng" : -71.4284899, "lat" : -32.4339257},
                {"lng" : -71.428411, "lat" : -32.434005},
                {"lng" : -71.4284947, "lat" : -32.4342216},
                {"lng" : -71.4283368, "lat" : -32.4343117},
                {"lng" : -71.4286065, "lat" : -32.4344369},
                {"lng" : -71.4284819, "lat" : -32.4346599},
                {"lng" : -71.4285984, "lat" : -32.4346547},
                {"lng" : -71.4287258, "lat" : -32.4348477},
                {"lng" : -71.4286011, "lat" : -32.4350476},
                {"lng" : -71.4287259, "lat" : -32.4350886},
                {"lng" : -71.4286988, "lat" : -32.435231},
                {"lng" : -71.4287368, "lat" : -32.4354008},
                {"lng" : -71.4289022, "lat" : -32.4354864},
                {"lng" : -71.4287445, "lat" : -32.435683},
                {"lng" : -71.428904, "lat" : -32.43566},
                {"lng" : -71.4287839, "lat" : -32.4358403},
                {"lng" : -71.42898, "lat" : -32.4358464},
                {"lng" : -71.4288508, "lat" : -32.4360378},
                {"lng" : -71.4288689, "lat" : -32.4361598},
                {"lng" : -71.4286325, "lat" : -32.4364695},
                {"lng" : -71.4284034, "lat" : -32.4366221},
                {"lng" : -71.4282218, "lat" : -32.4364744},
                {"lng" : -71.4280862, "lat" : -32.4365496},
                {"lng" : -71.4281078, "lat" : -32.4366588},
                {"lng" : -71.4280533, "lat" : -32.4367126},
                {"lng" : -71.4277655, "lat" : -32.4367241},
                {"lng" : -71.42732, "lat" : -32.4364999},
                {"lng" : -71.427754, "lat" : -32.4368063},
                {"lng" : -71.4277406, "lat" : -32.4369995},
                {"lng" : -71.4275808, "lat" : -32.4371927},
                {"lng" : -71.4274602, "lat" : -32.4372928},
                {"lng" : -71.4274535, "lat" : -32.4375026},
                {"lng" : -71.4276778, "lat" : -32.4376624},
                {"lng" : -71.4281233, "lat" : -32.4377835},
                {"lng" : -71.428146, "lat" : -32.4379046},
                {"lng" : -71.4280467, "lat" : -32.4379708},
                {"lng" : -71.4281589, "lat" : -32.4380919},
                {"lng" : -71.4278848, "lat" : -32.4382644},
                {"lng" : -71.4280498, "lat" : -32.4385193},
                {"lng" : -71.4278311, "lat" : -32.4386885},
                {"lng" : -71.4275875, "lat" : -32.4387217},
                {"lng" : -71.4275877, "lat" : -32.438848},
                {"lng" : -71.4279212, "lat" : -32.4387602},
                {"lng" : -71.4278846, "lat" : -32.4389359},
                {"lng" : -71.4275798, "lat" : -32.4390567},
                {"lng" : -71.4278204, "lat" : -32.4390818},
                {"lng" : -71.4277131, "lat" : -32.4392454},
                {"lng" : -71.4277521, "lat" : -32.4394089},
                {"lng" : -71.4274609, "lat" : -32.4396512},
                {"lng" : -71.4272909, "lat" : -32.4395185},
                {"lng" : -71.4270108, "lat" : -32.4396374},
                {"lng" : -71.4269746, "lat" : -32.440017},
                {"lng" : -71.4272217, "lat" : -32.4401287},
                {"lng" : -71.42742, "lat" : -32.44035},
                {"lng" : -71.4273908, "lat" : -32.4407546},
                {"lng" : -71.4272542, "lat" : -32.4408607},
                {"lng" : -71.4273355, "lat" : -32.4409471},
                {"lng" : -71.4272204, "lat" : -32.4410262},
                {"lng" : -71.4270643, "lat" : -32.4412281},
                {"lng" : -71.4269223, "lat" : -32.4415257},
                {"lng" : -71.4270494, "lat" : -32.4414773},
                {"lng" : -71.4271201, "lat" : -32.4413337},
                {"lng" : -71.4272332, "lat" : -32.4412498},
                {"lng" : -71.4273177, "lat" : -32.4412725},
                {"lng" : -71.427373, "lat" : -32.4414675},
                {"lng" : -71.4273681, "lat" : -32.441721},
                {"lng" : -71.4270983, "lat" : -32.4421388},
                {"lng" : -71.4267471, "lat" : -32.4423644},
                {"lng" : -71.4266656, "lat" : -32.442629},
                {"lng" : -71.4265354, "lat" : -32.4428936},
                {"lng" : -71.4267304, "lat" : -32.442966},
                {"lng" : -71.4267628, "lat" : -32.4431737},
                {"lng" : -71.4266001, "lat" : -32.44345},
                {"lng" : -71.4264516, "lat" : -32.4439792},
                {"lng" : -71.4265146, "lat" : -32.4442339},
                {"lng" : -71.4263965, "lat" : -32.4445373},
                {"lng" : -71.4266642, "lat" : -32.4446889},
                {"lng" : -71.4269969, "lat" : -32.4451148},
                {"lng" : -71.4271752, "lat" : -32.4457123},
                {"lng" : -71.427557, "lat" : -32.4462237},
                {"lng" : -71.4278575, "lat" : -32.4467488},
                {"lng" : -71.4283746, "lat" : -32.4471292},
                {"lng" : -71.4289405, "lat" : -32.447441},
                {"lng" : -71.4296034, "lat" : -32.4477634},
                {"lng" : -71.4301534, "lat" : -32.4479481},
                {"lng" : -71.4307232, "lat" : -32.4480016},
                {"lng" : -71.4306436, "lat" : -32.4477932},
                {"lng" : -71.4309137, "lat" : -32.4476603},
                {"lng" : -71.4310148, "lat" : -32.4474835},
                {"lng" : -71.4312658, "lat" : -32.4474732},
                {"lng" : -71.4316052, "lat" : -32.447274},
                {"lng" : -71.4318778, "lat" : -32.4475327},
                {"lng" : -71.432006, "lat" : -32.4473602},
                {"lng" : -71.432248, "lat" : -32.4473523},
                {"lng" : -71.432273, "lat" : -32.4471288},
                {"lng" : -71.4325189, "lat" : -32.4472175},
                {"lng" : -71.4326511, "lat" : -32.4473063},
                {"lng" : -71.4326878, "lat" : -32.4471957},
                {"lng" : -71.4329401, "lat" : -32.4472764},
                {"lng" : -71.4331519, "lat" : -32.4475613},
                {"lng" : -71.4331197, "lat" : -32.4473641},
                {"lng" : -71.4331362, "lat" : -32.4472492},
                {"lng" : -71.4333643, "lat" : -32.4473556},
                {"lng" : -71.4332201, "lat" : -32.4469749},
                {"lng" : -71.4334773, "lat" : -32.4471585},
                {"lng" : -71.4336208, "lat" : -32.4471087},
                {"lng" : -71.4340121, "lat" : -32.4474668},
                {"lng" : -71.4341466, "lat" : -32.4476558},
                {"lng" : -71.4340371, "lat" : -32.4477487},
                {"lng" : -71.4338705, "lat" : -32.4477935},
                {"lng" : -71.4339723, "lat" : -32.4479549},
                {"lng" : -71.4340923, "lat" : -32.4482003},
                {"lng" : -71.4342774, "lat" : -32.4483359},
                {"lng" : -71.4343222, "lat" : -32.4484424},
                {"lng" : -71.4340388, "lat" : -32.4485671},
                {"lng" : -71.4339667, "lat" : -32.4486918},
                {"lng" : -71.4340836, "lat" : -32.4487927},
                {"lng" : -71.4342248, "lat" : -32.4487495},
                {"lng" : -71.4344585, "lat" : -32.4488504},
                {"lng" : -71.434544, "lat" : -32.4487507},
                {"lng" : -71.4347193, "lat" : -32.4488492},
                {"lng" : -71.4347469, "lat" : -32.4489997},
                {"lng" : -71.4349006, "lat" : -32.4488639},
                {"lng" : -71.4351958, "lat" : -32.4490915},
                {"lng" : -71.4351888, "lat" : -32.4493798},
                {"lng" : -71.4350957, "lat" : -32.4493976},
                {"lng" : -71.4348417, "lat" : -32.4496361},
                {"lng" : -71.4352634, "lat" : -32.4494762},
                {"lng" : -71.4353696, "lat" : -32.4496094},
                {"lng" : -71.4353402, "lat" : -32.4497374},
                {"lng" : -71.4355834, "lat" : -32.4496607},
                {"lng" : -71.4357034, "lat" : -32.4497348},
                {"lng" : -71.4358206, "lat" : -32.4501942},
                {"lng" : -71.4356219, "lat" : -32.4502856},
                {"lng" : -71.435417, "lat" : -32.4502003},
                {"lng" : -71.4354402, "lat" : -32.4504269},
                {"lng" : -71.4355681, "lat" : -32.4504664},
                {"lng" : -71.4359135, "lat" : -32.4508496},
                {"lng" : -71.4357289, "lat" : -32.4510924},
                {"lng" : -71.4356675, "lat" : -32.4510686},
                {"lng" : -71.4355141, "lat" : -32.4511823},
                {"lng" : -71.4354368, "lat" : -32.4511605},
                {"lng" : -71.4353596, "lat" : -32.4512104},
                {"lng" : -71.4355125, "lat" : -32.4512578},
                {"lng" : -71.4354722, "lat" : -32.4513513},
                {"lng" : -71.4354992, "lat" : -32.4514478},
                {"lng" : -71.4353876, "lat" : -32.4515265},
                {"lng" : -71.4353622, "lat" : -32.4516572},
                {"lng" : -71.4352842, "lat" : -32.451701},
                {"lng" : -71.4351637, "lat" : -32.451721},
                {"lng" : -71.4350608, "lat" : -32.4516268},
                {"lng" : -71.4349932, "lat" : -32.4514848},
                {"lng" : -71.4349753, "lat" : -32.451537},
                {"lng" : -71.4349148, "lat" : -32.4514876},
                {"lng" : -71.4348507, "lat" : -32.4513789},
                {"lng" : -71.4346563, "lat" : -32.4510898},
                {"lng" : -71.4347188, "lat" : -32.4512635},
                {"lng" : -71.4347956, "lat" : -32.4513954},
                {"lng" : -71.4348672, "lat" : -32.4515605},
                {"lng" : -71.4348979, "lat" : -32.4516552},
                {"lng" : -71.4348934, "lat" : -32.4517141},
                {"lng" : -71.4349479, "lat" : -32.4518808},
                {"lng" : -71.4348458, "lat" : -32.4520266},
                {"lng" : -71.4348841, "lat" : -32.4520994},
                {"lng" : -71.4348115, "lat" : -32.4522659},
                {"lng" : -71.4349278, "lat" : -32.4521622},
                {"lng" : -71.4350075, "lat" : -32.4522924},
                {"lng" : -71.4349886, "lat" : -32.4524484},
                {"lng" : -71.4350568, "lat" : -32.4525761},
                {"lng" : -71.4350263, "lat" : -32.4526985},
                {"lng" : -71.4348665, "lat" : -32.4526908},
                {"lng" : -71.434756, "lat" : -32.4528808},
                {"lng" : -71.4349662, "lat" : -32.4528708},
                {"lng" : -71.4350806, "lat" : -32.4530426},
                {"lng" : -71.4350717, "lat" : -32.4531207},
                {"lng" : -71.4348915, "lat" : -32.4531033},
                {"lng" : -71.4347791, "lat" : -32.453169},
                {"lng" : -71.4348724, "lat" : -32.4533455},
                {"lng" : -71.434802, "lat" : -32.4534805},
                {"lng" : -71.4349616, "lat" : -32.4535272},
                {"lng" : -71.434838, "lat" : -32.453665},
                {"lng" : -71.4347453, "lat" : -32.4536936},
                {"lng" : -71.4345716, "lat" : -32.4535323},
                {"lng" : -71.4346184, "lat" : -32.4536914},
                {"lng" : -71.4347097, "lat" : -32.4537502},
                {"lng" : -71.4347184, "lat" : -32.4539511},
                {"lng" : -71.4345454, "lat" : -32.4540146},
                {"lng" : -71.4346869, "lat" : -32.4540879},
                {"lng" : -71.4346036, "lat" : -32.4541518},
                {"lng" : -71.4344896, "lat" : -32.4541688},
                {"lng" : -71.4345202, "lat" : -32.4542861},
                {"lng" : -71.4343714, "lat" : -32.4543298},
                {"lng" : -71.4341724, "lat" : -32.4540428},
                {"lng" : -71.4341627, "lat" : -32.4541979},
                {"lng" : -71.434188, "lat" : -32.4543068},
                {"lng" : -71.4341763, "lat" : -32.4544678},
                {"lng" : -71.434047, "lat" : -32.4543364},
                {"lng" : -71.4339424, "lat" : -32.4544079},
                {"lng" : -71.4339181, "lat" : -32.4541919},
                {"lng" : -71.4338751, "lat" : -32.454292},
                {"lng" : -71.4337952, "lat" : -32.4543816},
                {"lng" : -71.4336107, "lat" : -32.4544153},
                {"lng" : -71.4335864, "lat" : -32.4546114},
                {"lng" : -71.4334512, "lat" : -32.4548074},
                {"lng" : -71.4333243, "lat" : -32.4551844},
                {"lng" : -71.4334578, "lat" : -32.455304},
                {"lng" : -71.4334312, "lat" : -32.4554964},
                {"lng" : -71.4336328, "lat" : -32.4555669},
                {"lng" : -71.4337645, "lat" : -32.455545},
                {"lng" : -71.4338962, "lat" : -32.4555231},
                {"lng" : -71.4339559, "lat" : -32.4556328},
                {"lng" : -71.4339294, "lat" : -32.4557322},
                {"lng" : -71.4341186, "lat" : -32.4557587},
                {"lng" : -71.4342339, "lat" : -32.4557021},
                {"lng" : -71.4342228, "lat" : -32.4558586},
                {"lng" : -71.4340638, "lat" : -32.4559528},
                {"lng" : -71.4341575, "lat" : -32.4560157},
                {"lng" : -71.4341033, "lat" : -32.4561618},
                {"lng" : -71.4338494, "lat" : -32.4564552},
                {"lng" : -71.4337083, "lat" : -32.4565452},
                {"lng" : -71.4335426, "lat" : -32.4565104},
                {"lng" : -71.4334452, "lat" : -32.4565863},
                {"lng" : -71.4333369, "lat" : -32.456551},
                {"lng" : -71.4336092, "lat" : -32.4567348},
                {"lng" : -71.4335634, "lat" : -32.4568722},
                {"lng" : -71.4335798, "lat" : -32.4569611},
                {"lng" : -71.4333989, "lat" : -32.4570397},
                {"lng" : -71.4335917, "lat" : -32.457124},
                {"lng" : -71.433543, "lat" : -32.4572691},
                {"lng" : -71.4334573, "lat" : -32.4574143},
                {"lng" : -71.4332495, "lat" : -32.4576025},
                {"lng" : -71.43298, "lat" : -32.4575982},
                {"lng" : -71.4327168, "lat" : -32.457594},
                {"lng" : -71.4327063, "lat" : -32.4576314},
                {"lng" : -71.4332018, "lat" : -32.4576568},
                {"lng" : -71.4334526, "lat" : -32.4577007},
                {"lng" : -71.4336049, "lat" : -32.4579109},
                {"lng" : -71.4334331, "lat" : -32.4581073},
                {"lng" : -71.4331996, "lat" : -32.4581269},
                {"lng" : -71.4332626, "lat" : -32.4582493},
                {"lng" : -71.4331175, "lat" : -32.4583796},
                {"lng" : -71.4330171, "lat" : -32.4583019},
                {"lng" : -71.4330276, "lat" : -32.4581826},
                {"lng" : -71.432884, "lat" : -32.4582296},
                {"lng" : -71.4327651, "lat" : -32.4581622},
                {"lng" : -71.4326626, "lat" : -32.4581303},
                {"lng" : -71.4325393, "lat" : -32.4580825},
                {"lng" : -71.432307, "lat" : -32.4584787},
                {"lng" : -71.4321975, "lat" : -32.4590203},
                {"lng" : -71.4319387, "lat" : -32.4595117},
                {"lng" : -71.4316368, "lat" : -32.4603657},
                {"lng" : -71.4314242, "lat" : -32.4609071},
                {"lng" : -71.4313101, "lat" : -32.4614381},
                {"lng" : -71.431331, "lat" : -32.4614817},
                {"lng" : -71.4315562, "lat" : -32.4617286},
                {"lng" : -71.4315726, "lat" : -32.4618006},
                {"lng" : -71.4313651, "lat" : -32.4617404},
                {"lng" : -71.4314462, "lat" : -32.4618767},
                {"lng" : -71.4314409, "lat" : -32.4620235},
                {"lng" : -71.431525, "lat" : -32.4621189},
                {"lng" : -71.4316644, "lat" : -32.4620637},
                {"lng" : -71.4317048, "lat" : -32.4621428},
                {"lng" : -71.4316572, "lat" : -32.4623279},
                {"lng" : -71.4318189, "lat" : -32.462404},
                {"lng" : -71.4318252, "lat" : -32.4625009},
                {"lng" : -71.4316496, "lat" : -32.462643},
                {"lng" : -71.4318005, "lat" : -32.4627176},
                {"lng" : -71.4318099, "lat" : -32.4628171},
                {"lng" : -71.4315356, "lat" : -32.4627504},
                {"lng" : -71.4316405, "lat" : -32.4628844},
                {"lng" : -71.4316343, "lat" : -32.4629407},
                {"lng" : -71.4315082, "lat" : -32.4628476},
                {"lng" : -71.4313758, "lat" : -32.4628378},
                {"lng" : -71.4313063, "lat" : -32.4628955},
                {"lng" : -71.4315088, "lat" : -32.4629217},
                {"lng" : -71.4317179, "lat" : -32.463057},
                {"lng" : -71.4316485, "lat" : -32.4632702},
                {"lng" : -71.4315768, "lat" : -32.463304},
                {"lng" : -71.4315743, "lat" : -32.4634398},
                {"lng" : -71.4314732, "lat" : -32.4635756},
                {"lng" : -71.4314928, "lat" : -32.4636705},
                {"lng" : -71.4314727, "lat" : -32.4638629},
                {"lng" : -71.4313664, "lat" : -32.4639929},
                {"lng" : -71.4312708, "lat" : -32.4642113},
                {"lng" : -71.4310272, "lat" : -32.4642841},
                {"lng" : -71.4311619, "lat" : -32.464339},
                {"lng" : -71.4310937, "lat" : -32.4645068},
                {"lng" : -71.431161, "lat" : -32.4646435},
                {"lng" : -71.4313141, "lat" : -32.4647608},
                {"lng" : -71.4314276, "lat" : -32.4648142},
                {"lng" : -71.4314906, "lat" : -32.4647266},
                {"lng" : -71.4315535, "lat" : -32.4647429},
                {"lng" : -71.4315743, "lat" : -32.4648139},
                {"lng" : -71.4314904, "lat" : -32.4649473},
                {"lng" : -71.4313657, "lat" : -32.4648658},
                {"lng" : -71.431392, "lat" : -32.4650183},
                {"lng" : -71.4312888, "lat" : -32.4652071},
                {"lng" : -71.4313783, "lat" : -32.4651428},
                {"lng" : -71.4315155, "lat" : -32.4650249},
                {"lng" : -71.4316835, "lat" : -32.4649902},
                {"lng" : -71.4317639, "lat" : -32.4651398},
                {"lng" : -71.4316593, "lat" : -32.4651746},
                {"lng" : -71.4315176, "lat" : -32.465199},
                {"lng" : -71.4316594, "lat" : -32.465265},
                {"lng" : -71.431869, "lat" : -32.465311},
                {"lng" : -71.4318752, "lat" : -32.4654454},
                {"lng" : -71.4319872, "lat" : -32.4654929},
                {"lng" : -71.4319561, "lat" : -32.4656463},
                {"lng" : -71.4318879, "lat" : -32.4657581},
                {"lng" : -71.4318502, "lat" : -32.4658882},
                {"lng" : -71.43175, "lat" : -32.4660132},
                {"lng" : -71.4316483, "lat" : -32.4660656},
                {"lng" : -71.4314203, "lat" : -32.4662016},
                {"lng" : -71.4316359, "lat" : -32.4661556},
                {"lng" : -71.431793, "lat" : -32.4660442},
                {"lng" : -71.4319024, "lat" : -32.4660197},
                {"lng" : -71.4318708, "lat" : -32.4661219},
                {"lng" : -71.4317934, "lat" : -32.4661989},
                {"lng" : -71.4318453, "lat" : -32.466328},
                {"lng" : -71.4317557, "lat" : -32.4664901},
                {"lng" : -71.4316523, "lat" : -32.4665764},
                {"lng" : -71.4316413, "lat" : -32.4666574},
                {"lng" : -71.4315239, "lat" : -32.4667597},
                {"lng" : -71.4313694, "lat" : -32.4667685},
                {"lng" : -71.4311708, "lat" : -32.4669126},
                {"lng" : -71.4309505, "lat" : -32.4669189},
                {"lng" : -71.4308411, "lat" : -32.4669149},
                {"lng" : -71.4309184, "lat" : -32.4670434},
                {"lng" : -71.4310031, "lat" : -32.4670972},
                {"lng" : -71.4310948, "lat" : -32.4669994},
                {"lng" : -71.4312947, "lat" : -32.4669817},
                {"lng" : -71.4314686, "lat" : -32.4670092},
                {"lng" : -71.4313953, "lat" : -32.4670801},
                {"lng" : -71.4315066, "lat" : -32.4671156},
                {"lng" : -71.4314267, "lat" : -32.4672425},
                {"lng" : -71.4316147, "lat" : -32.4671656},
                {"lng" : -71.4315978, "lat" : -32.4672987},
                {"lng" : -71.4315597, "lat" : -32.4674152},
                {"lng" : -71.4313983, "lat" : -32.4674485},
                {"lng" : -71.4313109, "lat" : -32.4673882},
                {"lng" : -71.4312918, "lat" : -32.4675192},
                {"lng" : -71.4312357, "lat" : -32.4676398},
                {"lng" : -71.4313921, "lat" : -32.4676921},
                {"lng" : -71.4314651, "lat" : -32.4678481},
                {"lng" : -71.4314025, "lat" : -32.4680457},
                {"lng" : -71.4313143, "lat" : -32.4682538},
                {"lng" : -71.4310207, "lat" : -32.4685088},
                {"lng" : -71.430875, "lat" : -32.4686079},
                {"lng" : -71.4309009, "lat" : -32.4687793},
                {"lng" : -71.4309882, "lat" : -32.4689507},
                {"lng" : -71.4310818, "lat" : -32.4691117},
                {"lng" : -71.4312148, "lat" : -32.4692285},
                {"lng" : -71.4313306, "lat" : -32.4690998},
                {"lng" : -71.4315398, "lat" : -32.4691339},
                {"lng" : -71.4315765, "lat" : -32.4689705},
                {"lng" : -71.4316744, "lat" : -32.4689451},
                {"lng" : -71.4317585, "lat" : -32.4690824},
                {"lng" : -71.4316393, "lat" : -32.4691416},
                {"lng" : -71.4318382, "lat" : -32.469255},
                {"lng" : -71.4320486, "lat" : -32.469452},
                {"lng" : -71.4323946, "lat" : -32.4696283},
                {"lng" : -71.4324456, "lat" : -32.4697988},
                {"lng" : -71.4325593, "lat" : -32.4699253},
                {"lng" : -71.432482, "lat" : -32.4700933},
                {"lng" : -71.4326234, "lat" : -32.4699625},
                {"lng" : -71.4327894, "lat" : -32.4701123},
                {"lng" : -71.4329483, "lat" : -32.4703998},
                {"lng" : -71.4331551, "lat" : -32.4704446},
                {"lng" : -71.4331963, "lat" : -32.4706256},
                {"lng" : -71.4330875, "lat" : -32.4708201},
                {"lng" : -71.4332251, "lat" : -32.4709626},
                {"lng" : -71.4331121, "lat" : -32.4711228},
                {"lng" : -71.4331101, "lat" : -32.4712207},
                {"lng" : -71.4329417, "lat" : -32.4712482},
                {"lng" : -71.4328267, "lat" : -32.4713344},
                {"lng" : -71.4330075, "lat" : -32.4714414},
                {"lng" : -71.4329685, "lat" : -32.4716353},
                {"lng" : -71.4331143, "lat" : -32.471746},
                {"lng" : -71.4330256, "lat" : -32.4718568},
                {"lng" : -71.4330367, "lat" : -32.4719694},
                {"lng" : -71.4328759, "lat" : -32.4720985},
                {"lng" : -71.4326968, "lat" : -32.4721422},
                {"lng" : -71.4326751, "lat" : -32.4722525},
                {"lng" : -71.4327628, "lat" : -32.472292},
                {"lng" : -71.4329115, "lat" : -32.4722338},
                {"lng" : -71.4329611, "lat" : -32.4723479},
                {"lng" : -71.4330477, "lat" : -32.472358},
                {"lng" : -71.4329251, "lat" : -32.4724823},
                {"lng" : -71.4330621, "lat" : -32.4724813},
                {"lng" : -71.4329539, "lat" : -32.4726664},
                {"lng" : -71.4328545, "lat" : -32.4726623},
                {"lng" : -71.4329208, "lat" : -32.4727685},
                {"lng" : -71.4329425, "lat" : -32.4729081},
                {"lng" : -71.4331337, "lat" : -32.4727817},
                {"lng" : -71.433405, "lat" : -32.4727646},
                {"lng" : -71.4334914, "lat" : -32.472789},
                {"lng" : -71.4334248, "lat" : -32.4728651},
                {"lng" : -71.4333655, "lat" : -32.4729966},
                {"lng" : -71.4334317, "lat" : -32.4731243},
                {"lng" : -71.4333053, "lat" : -32.4734109},
                {"lng" : -71.4329661, "lat" : -32.473589},
                {"lng" : -71.4326503, "lat" : -32.4735413},
                {"lng" : -71.4324576, "lat" : -32.4736496},
                {"lng" : -71.4324819, "lat" : -32.473823},
                {"lng" : -71.4324658, "lat" : -32.4739215},
                {"lng" : -71.4324322, "lat" : -32.4740176},
                {"lng" : -71.4323558, "lat" : -32.4741557},
                {"lng" : -71.4322726, "lat" : -32.4741783},
                {"lng" : -71.4321211, "lat" : -32.4743276},
                {"lng" : -71.4318717, "lat" : -32.4745381},
                {"lng" : -71.4316088, "lat" : -32.4748153},
                {"lng" : -71.4315498, "lat" : -32.475123},
                {"lng" : -71.4314559, "lat" : -32.4752475},
                {"lng" : -71.4314774, "lat" : -32.4753855},
                {"lng" : -71.4314184, "lat" : -32.4755507},
                {"lng" : -71.4312628, "lat" : -32.4757},
                {"lng" : -71.431185, "lat" : -32.4759127},
                {"lng" : -71.4311475, "lat" : -32.4759851},
                {"lng" : -71.4310375, "lat" : -32.4763856},
                {"lng" : -71.4308712, "lat" : -32.47663},
                {"lng" : -71.4307398, "lat" : -32.4767907},
                {"lng" : -71.4305646, "lat" : -32.4775468},
                {"lng" : -71.4305735, "lat" : -32.4778179},
                {"lng" : -71.4306781, "lat" : -32.4779175},
                {"lng" : -71.430957, "lat" : -32.4778813},
                {"lng" : -71.4311072, "lat" : -32.4778722},
                {"lng" : -71.4312574, "lat" : -32.4779695},
                {"lng" : -71.4314023, "lat" : -32.4780917},
                {"lng" : -71.4316142, "lat" : -32.4781641},
                {"lng" : -71.4317536, "lat" : -32.4782275},
                {"lng" : -71.4319655, "lat" : -32.4782003},
                {"lng" : -71.4321774, "lat" : -32.478189},
                {"lng" : -71.4322096, "lat" : -32.4781573},
                {"lng" : -71.4322874, "lat" : -32.4782207},
                {"lng" : -71.4322954, "lat" : -32.4782863},
                {"lng" : -71.4324135, "lat" : -32.4784176},
                {"lng" : -71.4326012, "lat" : -32.4786303},
                {"lng" : -71.4326254, "lat" : -32.4787456},
                {"lng" : -71.4327621, "lat" : -32.4789764},
                {"lng" : -71.432569, "lat" : -32.4791303},
                {"lng" : -71.4325288, "lat" : -32.4792615},
                {"lng" : -71.4326978, "lat" : -32.4794833},
                {"lng" : -71.4329124, "lat" : -32.4797978},
                {"lng" : -71.4328882, "lat" : -32.4799856},
                {"lng" : -71.4324778, "lat" : -32.4801689},
                {"lng" : -71.4322981, "lat" : -32.4801892},
                {"lng" : -71.4320728, "lat" : -32.4802028},
                {"lng" : -71.4319924, "lat" : -32.4803024},
                {"lng" : -71.4319977, "lat" : -32.480377},
                {"lng" : -71.4321694, "lat" : -32.4804879},
                {"lng" : -71.4321855, "lat" : -32.4803702},
                {"lng" : -71.4321855, "lat" : -32.4803386},
                {"lng" : -71.432333, "lat" : -32.480282},
                {"lng" : -71.4323679, "lat" : -32.4802978},
                {"lng" : -71.4324322, "lat" : -32.480377},
                {"lng" : -71.4324725, "lat" : -32.4804698},
                {"lng" : -71.4322257, "lat" : -32.4806078},
                {"lng" : -71.4321318, "lat" : -32.4806395},
                {"lng" : -71.4321479, "lat" : -32.4807617},
                {"lng" : -71.4321401, "lat" : -32.4808599},
                {"lng" : -71.4319897, "lat" : -32.4808884},
                {"lng" : -71.4319977, "lat" : -32.4808952},
                {"lng" : -71.4320916, "lat" : -32.4809563},
                {"lng" : -71.4321318, "lat" : -32.4810241},
                {"lng" : -71.432097, "lat" : -32.4811282},
                {"lng" : -71.4319548, "lat" : -32.4811531},
                {"lng" : -71.4315122, "lat" : -32.4811441},
                {"lng" : -71.4314613, "lat" : -32.4811146},
                {"lng" : -71.4314452, "lat" : -32.4811192},
                {"lng" : -71.4312501, "lat" : -32.48114},
                {"lng" : -71.431185, "lat" : -32.4815287},
                {"lng" : -71.4312628, "lat" : -32.4818409},
                {"lng" : -71.4310616, "lat" : -32.4823432},
                {"lng" : -71.4310429, "lat" : -32.4825559},
                {"lng" : -71.4311099, "lat" : -32.4827505},
                {"lng" : -71.4312172, "lat" : -32.4829994},
                {"lng" : -71.4312413, "lat" : -32.4831917},
                {"lng" : -71.4312816, "lat" : -32.4834655},
                {"lng" : -71.4312387, "lat" : -32.4838003},
                {"lng" : -71.4311019, "lat" : -32.4839519},
                {"lng" : -71.4309704, "lat" : -32.4840198},
                {"lng" : -71.4308766, "lat" : -32.4840967},
                {"lng" : -71.4307763, "lat" : -32.4844752},
                {"lng" : -71.4305949, "lat" : -32.4846986},
                {"lng" : -71.4304259, "lat" : -32.4846895},
                {"lng" : -71.4303669, "lat" : -32.4847823},
                {"lng" : -71.4303133, "lat" : -32.4849588},
                {"lng" : -71.4302757, "lat" : -32.4851036},
                {"lng" : -71.4302409, "lat" : -32.485228},
                {"lng" : -71.4302355, "lat" : -32.4853479},
                {"lng" : -71.4303803, "lat" : -32.4854113},
                {"lng" : -71.4304528, "lat" : -32.4857054},
                {"lng" : -71.4305574, "lat" : -32.4858864},
                {"lng" : -71.4307076, "lat" : -32.4860063},
                {"lng" : -71.4308846, "lat" : -32.4861262},
                {"lng" : -71.4310187, "lat" : -32.4864588},
                {"lng" : -71.4310282, "lat" : -32.4867032},
                {"lng" : -71.4309704, "lat" : -32.4870109},
                {"lng" : -71.4310542, "lat" : -32.4871558},
                {"lng" : -71.4314056, "lat" : -32.4874125},
                {"lng" : -71.4316323, "lat" : -32.4874963},
                {"lng" : -71.4317945, "lat" : -32.4875721},
                {"lng" : -71.432274, "lat" : -32.4875742},
                {"lng" : -71.432482, "lat" : -32.4876059},
                {"lng" : -71.4326708, "lat" : -32.4877796},
                {"lng" : -71.4332202, "lat" : -32.4879751},
                {"lng" : -71.4336407, "lat" : -32.4879968},
                {"lng" : -71.4339411, "lat" : -32.4878665},
                {"lng" : -71.4340012, "lat" : -32.4879751},
                {"lng" : -71.4337781, "lat" : -32.4885905},
                {"lng" : -71.4338123, "lat" : -32.4887715},
                {"lng" : -71.4340098, "lat" : -32.4890104},
                {"lng" : -71.4337695, "lat" : -32.4891914},
                {"lng" : -71.4335319, "lat" : -32.4894272},
                {"lng" : -71.433489, "lat" : -32.489495},
                {"lng" : -71.4334604, "lat" : -32.4897199},
                {"lng" : -71.4335205, "lat" : -32.489785},
                {"lng" : -71.4334261, "lat" : -32.4899733},
                {"lng" : -71.4333281, "lat" : -32.4898095},
                {"lng" : -71.4329633, "lat" : -32.4900561},
                {"lng" : -71.4327917, "lat" : -32.4903185},
                {"lng" : -71.4327222, "lat" : -32.4911171},
                {"lng" : -71.4328253, "lat" : -32.4916311},
                {"lng" : -71.4330056, "lat" : -32.4919569},
                {"lng" : -71.433203, "lat" : -32.4920945},
                {"lng" : -71.4332888, "lat" : -32.4923189},
                {"lng" : -71.4335891, "lat" : -32.4925071},
                {"lng" : -71.4337351, "lat" : -32.4924999},
                {"lng" : -71.4337865, "lat" : -32.4927243},
                {"lng" : -71.4340356, "lat" : -32.4928981},
                {"lng" : -71.4337695, "lat" : -32.4932528},
                {"lng" : -71.4337952, "lat" : -32.4937016},
                {"lng" : -71.4340441, "lat" : -32.494469},
                {"lng" : -71.4346792, "lat" : -32.4957142},
                {"lng" : -71.4354087, "lat" : -32.4969231},
                {"lng" : -71.4364044, "lat" : -32.498161},
                {"lng" : -71.4374344, "lat" : -32.4992396},
                {"lng" : -71.4385931, "lat" : -32.500253},
                {"lng" : -71.4399149, "lat" : -32.5013389},
                {"lng" : -71.4413396, "lat" : -32.5023451},
                {"lng" : -71.4433934, "lat" : -32.5036534},
                {"lng" : -71.4451555, "lat" : -32.5047975},
                {"lng" : -71.4453372, "lat" : -32.5048202},
                {"lng" : -71.4454923, "lat" : -32.5047757},
                {"lng" : -71.4456445, "lat" : -32.504776},
                {"lng" : -71.4457525, "lat" : -32.5047092},
                {"lng" : -71.4458448, "lat" : -32.5046427},
                {"lng" : -71.4459587, "lat" : -32.5046661},
                {"lng" : -71.4459472, "lat" : -32.5047804},
                {"lng" : -71.4459904, "lat" : -32.5048264},
                {"lng" : -71.4460771, "lat" : -32.5048026},
                {"lng" : -71.4462438, "lat" : -32.5046915},
                {"lng" : -71.4463537, "lat" : -32.5047104},
                {"lng" : -71.4464352, "lat" : -32.5048205},
                {"lng" : -71.4463929, "lat" : -32.5048933},
                {"lng" : -71.4464923, "lat" : -32.5049543},
                {"lng" : -71.4466012, "lat" : -32.50495},
                {"lng" : -71.4467036, "lat" : -32.5049723},
                {"lng" : -71.446733, "lat" : -32.5050618},
                {"lng" : -71.4467724, "lat" : -32.5051499},
                {"lng" : -71.4468735, "lat" : -32.5051668},
                {"lng" : -71.4469624, "lat" : -32.5050682},
                {"lng" : -71.4470514, "lat" : -32.5050664},
                {"lng" : -71.4471617, "lat" : -32.5051866},
                {"lng" : -71.4474574, "lat" : -32.5053144},
                {"lng" : -71.4479358, "lat" : -32.505477},
                {"lng" : -71.448423, "lat" : -32.5056023},
                {"lng" : -71.4488802, "lat" : -32.5056688},
                {"lng" : -71.4492275, "lat" : -32.5056727},
                {"lng" : -71.4497485, "lat" : -32.5056336},
                {"lng" : -71.4504152, "lat" : -32.505438},
                {"lng" : -71.4505982, "lat" : -32.5053939},
                {"lng" : -71.4509168, "lat" : -32.5051654},
                {"lng" : -71.4509937, "lat" : -32.5049616},
                {"lng" : -71.4509922, "lat" : -32.5048544},
                {"lng" : -71.4509453, "lat" : -32.5047798},
                {"lng" : -71.4509117, "lat" : -32.5046194},
                {"lng" : -71.4508021, "lat" : -32.5046023},
                {"lng" : -71.4507, "lat" : -32.5043061},
                {"lng" : -71.450647, "lat" : -32.5042054},
                {"lng" : -71.4507874, "lat" : -32.5035673},
                {"lng" : -71.4508482, "lat" : -32.5035211},
                {"lng" : -71.4508959, "lat" : -32.5034465},
                {"lng" : -71.4509733, "lat" : -32.5033966},
                {"lng" : -71.451063, "lat" : -32.5033888},
                {"lng" : -71.4511509, "lat" : -32.5033705},
                {"lng" : -71.4512634, "lat" : -32.5033635},
                {"lng" : -71.4514987, "lat" : -32.5032594},
                {"lng" : -71.4517388, "lat" : -32.5032517},
                {"lng" : -71.4519214, "lat" : -32.5030611},
                {"lng" : -71.4522607, "lat" : -32.5028559},
                {"lng" : -71.4522943, "lat" : -32.5026614},
                {"lng" : -71.4526595, "lat" : -32.5025114},
                {"lng" : -71.4525778, "lat" : -32.5024263},
                {"lng" : -71.452818, "lat" : -32.5021628},
                {"lng" : -71.4529958, "lat" : -32.5020898},
                {"lng" : -71.4532072, "lat" : -32.5021303},
                {"lng" : -71.4534715, "lat" : -32.5020898},
                {"lng" : -71.4539014, "lat" : -32.5021363},
                {"lng" : -71.4541465, "lat" : -32.501958},
                {"lng" : -71.4543147, "lat" : -32.501962},
                {"lng" : -71.4544204, "lat" : -32.5021038},
                {"lng" : -71.4546078, "lat" : -32.5020835},
                {"lng" : -71.4547808, "lat" : -32.5019822},
                {"lng" : -71.4548576, "lat" : -32.5017714},
                {"lng" : -71.4552612, "lat" : -32.5016173},
                {"lng" : -71.4553477, "lat" : -32.5015363},
                {"lng" : -71.4553573, "lat" : -32.5014471},
                {"lng" : -71.4555687, "lat" : -32.5012931},
                {"lng" : -71.4559506, "lat" : -32.5012037},
                {"lng" : -71.4560852, "lat" : -32.5011023},
                {"lng" : -71.4563062, "lat" : -32.5010415},
                {"lng" : -71.4565628, "lat" : -32.5010565},
                {"lng" : -71.4567248, "lat" : -32.5009967},
                {"lng" : -71.4565849, "lat" : -32.5009268},
                {"lng" : -71.4565597, "lat" : -32.5008502},
                {"lng" : -71.456549, "lat" : -32.5006364},
                {"lng" : -71.4567385, "lat" : -32.5004781},
                {"lng" : -71.4574689, "lat" : -32.5004779},
                {"lng" : -71.4576227, "lat" : -32.5005387},
                {"lng" : -71.457821, "lat" : -32.5007663},
                {"lng" : -71.4577845, "lat" : -32.5009896},
                {"lng" : -71.4577423, "lat" : -32.5010482},
                {"lng" : -71.4579078, "lat" : -32.5011444},
                {"lng" : -71.4581175, "lat" : -32.5012378},
                {"lng" : -71.458749, "lat" : -32.5013071},
                {"lng" : -71.4590378, "lat" : -32.5012153},
                {"lng" : -71.459155, "lat" : -32.5007271},
                {"lng" : -71.4590215, "lat" : -32.5003087},
                {"lng" : -71.4591607, "lat" : -32.5000777},
                {"lng" : -71.4592088, "lat" : -32.5000615},
                {"lng" : -71.4593193, "lat" : -32.5001263},
                {"lng" : -71.4594154, "lat" : -32.5000939},
                {"lng" : -71.4595413, "lat" : -32.4997119},
                {"lng" : -71.4596565, "lat" : -32.4995741},
                {"lng" : -71.4597189, "lat" : -32.4992215},
                {"lng" : -71.4599736, "lat" : -32.4993349},
                {"lng" : -71.4601417, "lat" : -32.4990917},
                {"lng" : -71.4603579, "lat" : -32.4990268},
                {"lng" : -71.4607761, "lat" : -32.4992901},
                {"lng" : -71.4610452, "lat" : -32.499363},
                {"lng" : -71.4611269, "lat" : -32.4994279},
                {"lng" : -71.461127, "lat" : -32.4997075},
                {"lng" : -71.4614105, "lat" : -32.4996304},
                {"lng" : -71.4617133, "lat" : -32.4997925},
                {"lng" : -71.4618911, "lat" : -32.4998086},
                {"lng" : -71.4620688, "lat" : -32.4997113},
                {"lng" : -71.4624232, "lat" : -32.4996545},
                {"lng" : -71.4628551, "lat" : -32.4997246},
                {"lng" : -71.4628908, "lat" : -32.5000222},
                {"lng" : -71.4631269, "lat" : -32.5003358}
                ]
        },
    ]
};

export default Papudo;
