import React from "react";
import SideMenu from "./SideMenu";
import { generateSideMenu } from "../components/genscripts";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeitem: this.props.activeitem,
    };
  }

  render() {
    return (
      <div>
        <div className="logo">
          <a
            href="/"
            className="simple-text logo-normal"
          >
            <img src="img/logo.png" alt="logo" />
          </a>
        </div>
        <div className="sidebar-wrapper">
          <div id="sidemenu">
            <SideMenu
              activeitem={this.state.activeitem}
              listitemsmenu={generateSideMenu()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SideBar;
