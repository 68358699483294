import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import PreviewVenta from './PreviewVenta';
import './FormaPago.css';
import { numberFormat, getDateTimeNow } from '../genscripts';

import printerpos from '../../images/printerpos.png';

import {removeAccents} from "../Utils";

const FormaPagoNew = (props) => {
	const ventaData = props.ventaData;
	const setVentaData = props.setVentaData;
	const payType = props.payType;
	const setPayType = props.setPayType;
	const moneyType = props.moneyType;
	const setMoneyType = props.setMoneyType;
	const discToPay = props.discToPay;
	const setDiscToPay = props.setDiscToPay;
	const addToPay = props.addToPay;
	const setAddToPay = props.setAddToPay;
	const setSelectedOptionService = props.setSelectedOptionService;
	const setCoordinatesPolygon = props.setCoordinatesPolygon;
	const refDirection = props.refDirection;
	const initData = props.initData;
	const initPolygonData = props.initPolygonData;
	const initPayData = props.initPayData;
	const hidePayAndPreview = props.hidePayAndPreview;
	const initSearchAddressData = props.initSearchAddressData;

	const [monedas, setMonedas] = useState({});
	const [discountranges, setDiscountranges] = useState({});

	const [modalIsOpenPrint, setModalIsOpenPrint] = useState(false);

	const getMonedas = () => {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getMonedas`,
			headers: headers,
		})
			.then((response) => {
				const monedas = response.data.reduce(
					(acc, el) => ({
						...acc,
						[el.nombremoneda]: el,
					}),
					{}
				);
				setMonedas({
					dolar: monedas['dolar'].valorpesos,
					euro: monedas['euro'].valorpesos,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getDescuentos = () => {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getDescuentos`,
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				setDiscountranges(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const checkDiscount = (monto, descuento) => {
		const desc = discountranges.filter(
			(x) => x.montomin <= monto && x.montomax >= monto
		);

		console.log('DESCUENTO:', desc);
		let montodesc = desc[0].descuento;

		if (moneyType === 'US') {
			montodesc = Math.round(montodesc / monedas.dolar);
		} else if (moneyType === 'EU') {
			montodesc = Math.round(montodesc / monedas.euro);
		}

		let flagdisc = false;

		if (montodesc < descuento) {
			flagdisc = true;
		}

		console.log('montodesc: ', montodesc);

		return { montodesc: montodesc, flagdisc: flagdisc }; //montodesc
	};

	const handleDiscount = (event) => {
		const discount = event.target.value !== '' ? event.target.value : 0;
		setDiscToPay(discount === 0 ? '' : discount);

		let valorenpesos = 0;
		let montoapago = 0;

		let valorOrig = ventaData.valororig;
		if (moneyType === 'US') {
			montoapago =
				Math.round(valorOrig / monedas.dolar) -
				parseInt(discount) +
				parseInt(addToPay === '' ? 0 : addToPay);
			valorenpesos = montoapago * monedas.dolar;
		} else if (moneyType === 'EU') {
			montoapago =
				Math.round(valorOrig / monedas.euro) -
				parseInt(discount) +
				parseInt(addToPay === '' ? 0 : addToPay);
			valorenpesos = montoapago * monedas.euro;
		} else {
			montoapago =
				valorOrig -
				parseInt(discount) +
				parseInt(addToPay === '' ? 0 : addToPay);
			valorenpesos = montoapago;
		}

		const montoDisc = checkDiscount(ventaData.valororig, discount);

		if (montoDisc.flagdisc) {
			document.getElementById('nc-pago-btn').disabled = true;
			document.getElementById('discountMsg').innerHTML =
				'(Superas el descuento máximo de $ ' +
				numberFormat(montoDisc.montodesc.toString()) +
				')';
		} else {
			document.getElementById('nc-pago-btn').disabled = false;
			document.getElementById('discountMsg').innerHTML = '';
		}

		setVentaData({
			idservice: ventaData.idservice,
			servicename: ventaData.servicename,
			valor: montoapago,
			valororig: ventaData.valororig,
			valorpesos: valorenpesos,
		});
	};

	const handleOverPrice = (event) => {
		const overprice = event.target.value !== '' ? event.target.value : 0;
		setAddToPay(overprice === 0 ? '' : overprice);

		let valorenpesos = 0;
		let montoapago = 0;

		let valorOrig = ventaData.valororig;
		if (moneyType === 'US') {
			montoapago =
				Math.round(valorOrig / monedas.dolar) -
				parseInt(discToPay === '' ? 0 : discToPay) +
				parseInt(overprice);
			valorenpesos = montoapago * monedas.dolar;
		} else if (moneyType === 'EU') {
			montoapago =
				Math.round(valorOrig / monedas.euro) -
				parseInt(discToPay === '' ? 0 : discToPay) +
				parseInt(overprice);
			valorenpesos = montoapago * monedas.euro;
		} else {
			//pesos
			montoapago =
				valorOrig -
				parseInt(discToPay === '' ? 0 : discToPay) +
				parseInt(overprice);
			valorenpesos = montoapago;
		}

		setVentaData({
			idservice: ventaData.idservice,
			servicename: ventaData.servicename,
			valor: montoapago,
			valororig: ventaData.valororig,
			valorpesos: valorenpesos,
		});
	};

	const handleSetMoneyType = (moneyType) => {
		setMoneyType(moneyType);
		let factor = 1;
		if (moneyType === 'US') {
			setPayType('EF');
			factor = monedas.dolar;
		} else if (moneyType === 'EU') {
			setPayType('EF');
			factor = monedas.euro;
		}

		setDiscToPay(0);
		setAddToPay(0);

		setVentaData({
			idservice: ventaData.idservice,
			servicename: ventaData.servicename,
			valor: Math.round(ventaData.valororig / factor),
			valororig: ventaData.valororig,
			valorpesos: ventaData.valororig,
		});
	};

	const clickOnDiscount = () => {
		console.log('clickondiscount discToPay: ', discToPay);
		if (Number(discToPay) === 0) {
			setDiscToPay('');
		}
	};

	const clickOnOverPrice = () => {
		console.log('clickOnOverPrice addToPay: ', addToPay);
		if (Number(addToPay) === 0) {
			setAddToPay('');
		}
	};

	const imprimirTicket = (
		idticket,
		valor,
		moneytype,
		paytype,
		servicename,
		fechahora,
		sellername
	) => {
		var tokenPrint = btoa(
			'YWRtaW50bzppbzg4amtrMw==' + //0
				'_&_' +
				idticket + //1
				'_&_' +
				valor + //2
				'_&_' +
				moneytype + //3
				'_&_' +
				paytype + //4
				'_&_' +
				servicename + //5
				'_&_' +
				fechahora + //6
				'_&_' +
				sellername
		); //7

		var url =
			'http://localhost/printservice/index.php?action=printTicket&tokenprint=' +
			tokenPrint;

		window.open(url, '_blank');
		setModalIsOpenPrint(false);

		document.getElementById('nc-pago-btn').disabled = false; //Habilito botón pagar

		//reseteo la pantalla y los valores
		initData();
		initSearchAddressData();
		initPolygonData();
		initPayData();
		hidePayAndPreview();

		document.getElementById('navtitle').innerHTML =
			'Ingresar nueva carrera - Último ticket vendido: ' + idticket;
	};

	const pagarTicket = () => {
		//desactivo botón pagar por un rato
		document.getElementById('nc-pago-btn').disabled = true;

		const cookies = new Cookies();
		const token = cookies.get('token');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/newticket`,
			data: JSON.stringify({
				destino: ventaData.servicename,
				valorticket: ventaData.valor,
				valorpesos: ventaData.valorpesos,
				fechahora: getDateTimeNow(),
				formapago: payType,
				transbankcode: 1,
				moneda: moneyType,
				ingresoefectivo: 0,
				salidaefectivo: 0,
				descuento: parseInt(discToPay),
				aumento: parseInt(addToPay),
				rut_vendedor: cookies.get('user'),
				idservicio: ventaData.idservice,
				ticketstate: 'ACTIVO',
				venta_supervisor: false,
				rut_supervisor: '',
				venta_reemplazo: false,
				rut_reemplazo: '',
				idcajasiniciofin: cookies.get('copenid'),
				idturno: cookies.get('idturno'),
				idcounter: cookies.get('idcounter'),
				rut_sup_anula: '',
			}),
			headers: headers,
		})
			.then((response) => {
				setModalIsOpenPrint(true);
				const direction = refDirection && refDirection.current && refDirection.current.value !== '' ?
					refDirection.current.value : ventaData.servicename;
				setTimeout(() => {
					imprimirTicket(
						response.data.idticket,
						ventaData.valor,
						moneyType,
						payType,
						removeAccents(direction.substring(0, 50)),
						response.data.fechahora,
						cookies.get('name')
					);
				}, 500);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		getMonedas();
		getDescuentos();
	}, []);

	return (
		<div className="col-without-padding">
			<div className="col-md-6 float-left" id="step-pago">
				<div className="card ">
					<div className="card-header ">
						<h5 className="card-title">Pago: </h5>
					</div>
					<div className="card-body pagoContainer">
						<div className="row padding-20">
							<div className="col-md-12 pl-1">
								<div className="form-group" style={{ padding: 10 }}>
									{/*TEST botones */}
									<div className="centerContent">
										<div className="form-check-inline blackLabel">
											Forma de pago:
										</div>
										<div className="form-check-inline">
											<button
												type="button"
												className={
													payType === 'EF'
														? 'form-check-input radioBTNSelected'
														: 'form-check-input radioBTNInactive'
												}
												id="efectivobtn"
												onClick={() => setPayType('EF')}
											>
												Efectivo
											</button>
										</div>
										<div className="form-check-inline">
											<button
												type="button"
												className={
													payType === 'DB'
														? 'form-check-input radioBTNSelected'
														: 'form-check-input radioBTNInactive'
												}
												id="debitobtn"
												onClick={() => setPayType('DB')}
												disabled={
													moneyType === 'US' || moneyType === 'EU'
														? true
														: false
												}
											>
												Débito
											</button>
										</div>
										<div className="form-check-inline">
											<button
												type="button"
												className={
													payType === 'TC'
														? 'form-check-input radioBTNSelected'
														: 'form-check-input radioBTNInactive'
												}
												id="creditobtn"
												onClick={() => setPayType('TC')}
												disabled={
													moneyType === 'US' || moneyType === 'EU'
														? true
														: false
												}
											>
												Crédito
											</button>
										</div>
									</div>
									{/*FIN TEST botones */}
								</div>
							</div>
							<div className="col-md-12 pl-1 paddingExtra upDiv10">
								<div className="form-group row">
									<label className="blackLabel paddingTopLabel pagoLabelMinWithChico">
										Descuento:
									</label>
									<div className="col-sm-4">
										<input
											type="text"
											className="form-control"
											placeholder="Ingresar descuento"
											value={discToPay}
											onChange={(e) => handleDiscount(e)}
											onClick={() => clickOnDiscount()}
										/>
										<small id="discountMsg" className="warningMsg"></small>
									</div>

									<label className="blackLabel paddingTopLabel pagoLabelMinWithChico paddingLabel">
										Aumento:
									</label>
									<div className="col-sm-4">
										<input
											type="text"
											className="form-control"
											placeholder="Ingresar aumento"
											value={addToPay}
											onChange={(e) => handleOverPrice(e)}
											onClick={() => clickOnOverPrice('')}
										/>
										<small id="discountMsg" className="warningMsg"></small>
									</div>
								</div>
							</div>

							<div className="col-md-12 pl-1">
								<div className="form-group">
									<div className="centerContent">
										<div className="form-check-inline blackLabel">Moneda:</div>
										<div className="form-check-inline">
											<button
												className={
													moneyType === 'CL'
														? 'form-check-input radioBTNSelected'
														: 'form-check-input radioBTNInactive'
												}
												id="clpesosbtn"
												onClick={() => handleSetMoneyType('CL')}
											>
												Pesos Chilenos
											</button>
										</div>
										<div className="form-check-inline">
											<button
												className={
													moneyType === 'US'
														? 'form-check-input radioBTNSelected'
														: 'form-check-input radioBTNInactive'
												}
												id="usdbtn"
												onClick={() => handleSetMoneyType('US')}
											>
												Dólares
											</button>
										</div>
										<div className="form-check-inline">
											<button
												className={
													moneyType === 'EU'
														? 'form-check-input radioBTNSelected'
														: 'form-check-input radioBTNInactive'
												}
												id="eurobtn"
												onClick={() => handleSetMoneyType('EU')}
											>
												Euros
											</button>
										</div>
									</div>

									{/* Fin Test botones moneda */}
								</div>
							</div>
						</div>

						<div className="row">
							<div className="update ml-auto mr-auto padding-top-30 upDiv20">
								<button
									type="button"
									id="nc-pago-btn"
									className="btn btn-inactivo btn-round btnPago btnPagarLittle"
									onClick={() => pagarTicket()}
								>
									Pagar
								</button>
							</div>
						</div>

						{/* MODAL */}

						<Modal
							isOpen={modalIsOpenPrint}
							overlayClassName="Overlay"
							className="Modal"
							contentLabel="Imprimir ticket"
						>
							<div className="headermodal"></div>
							<div className="containerModal">
								<img src={printerpos} alt="printIcon" />
								<br />
							</div>
						</Modal>

						{/* FIN MODAL */}
					</div>
				</div>
			</div>

			<PreviewVenta
				refDirection={refDirection}
				ventaData={ventaData}
				payType={payType}
				moneyType={moneyType}
			/>
		</div>
	);
};

export default FormaPagoNew;
