import React, {useRef, useState} from 'react';
import './VentaDir.css';
import SelectReemplazoNew from './SelectReemplazoNew';
import FastAccessNew from './FastAccessNew';
import SelectTarifaNew from './SelectTarifaNew';
import FormaPagoNew from './FormaPagoNew';

import { numberFormat } from '../genscripts';
import SearchAddress from "./Georeference/SearchAddress";

const VentaDirNew = () => {
	const [ventaData, setVentaData] = useState({
		idservice: 0,
		servicename: '',
		valor: 0,
		valororig: 0,
		valorpesos: 0,
	});

	const [allServices, setAllServices] = useState([]);
	const [selecttarifa, setSelectTarifa] = useState([]);
	const [selectedOptionService, setSelectedOptionService] = useState({});

	//Estados de forma de pago
	const [payType, setPayType] = useState('EF');
	const [moneyType, setMoneyType] = useState('CL');
	const [discToPay, setDiscToPay] = useState(0);
	const [addToPay, setAddToPay] = useState(0);

	/**@type React.MutableRefObject<HTMLInputElement> */
	const refDirection = useRef();

	const [coordinatesPolygon, setCoordinatesPolygon] = useState([]);

	const initVentaData = () => {
		setVentaData({
			idservice: 0,
			servicename: '',
			valor: 0,
			valororig: 0,
		});

		setSelectedOptionService({});
	};

	const initPayData = () => {
		setPayType('EF');
		setMoneyType('CL');
		setDiscToPay(0);
		setAddToPay(0);
	};

	const initPolygonData = () => {
		setCoordinatesPolygon([]);
	};

	const hidePayAndPreview = () => {
		document.getElementById('step-pago').style.display = 'none';
		document.getElementById('step-previewventa').style.display = 'none';
	};

	const initSearchAddressData = () => {
		refDirection.current.value = '';
	}

	return (
		<div className="content">
			<div className="row-100" id="VentaTicket">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<SelectReemplazoNew />
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<FastAccessNew
								ventaData={ventaData}
								setVentaData={setVentaData}
								setPayType={setPayType}
								setMoneyType={setMoneyType}
								setDiscToPay={setDiscToPay}
								setAddToPay={setAddToPay}
								selecttarifa={selecttarifa}
								setSelectedOptionService={setSelectedOptionService}
								refDirection={refDirection}
								setCoordinatesPolygon={setCoordinatesPolygon}
							/>
						</div>
					</div>
					<div className="col-md-4">
						{' '}
						<div className="card">
							<div className="card-body" id="selectTarifaDir">
								<form>
									<div id="OpcionTarifario">
										<SelectTarifaNew
											ventaData={ventaData}
											setVentaData={setVentaData}
											setPayType={setPayType}
											setMoneyType={setMoneyType}
											setDiscToPay={setDiscToPay}
											setAddToPay={setAddToPay}
											setAllServices={setAllServices}
											selecttarifa={selecttarifa}
											setSelectTarifa={setSelectTarifa}
											selectedOptionService={selectedOptionService}
											setSelectedOptionService={setSelectedOptionService}
											refDirection={refDirection}
											setCoordinatesPolygon={setCoordinatesPolygon}
										/>
									</div>
								</form>
								{/* inicio precio */}
								<input type="hidden" id="montocarreraorig" />
								<input type="hidden" id="montoapagomem" />
								<div id="montocontainer">
									<p className="montotext">
										${' '}
										<span id="montoapago">
											{numberFormat(ventaData.valor + '')}
										</span>
									</p>
								</div>
								{/* fin precio */}
							</div>
						</div>
					</div>
					<SearchAddress
						allServices={allServices}
						setVentaData={setVentaData}
						setPayType={setPayType}
						setMoneyType={setMoneyType}
						setDiscToPay={setDiscToPay}
						setAddToPay={setAddToPay}
						selecttarifa={selecttarifa}
						setSelectedOptionService={setSelectedOptionService}
						refDirection={refDirection}
						coordinatesPolygon={coordinatesPolygon}
						setCoordinatesPolygon={setCoordinatesPolygon}
						initData={initVentaData}
						initPolygonData={initPolygonData}
						initPayData={initPayData}
						hidePayAndPreview={hidePayAndPreview}
					/>
				</div>
			</div>

			<div className="row">
				<FormaPagoNew
					ventaData={ventaData}
					setVentaData={setVentaData}
					payType={payType}
					setPayType={setPayType}
					moneyType={moneyType}
					setMoneyType={setMoneyType}
					discToPay={discToPay}
					setDiscToPay={setDiscToPay}
					addToPay={addToPay}
					setAddToPay={setAddToPay}
					setSelectedOptionService={setSelectedOptionService}
					refDirection={refDirection}
					setCoordinatesPolygon={setCoordinatesPolygon}
					initData={initVentaData}
					initPolygonData={initPolygonData}
					initPayData={initPayData}
					hidePayAndPreview={hidePayAndPreview}
					initSearchAddressData={initSearchAddressData}
				/>
			</div>
		</div>
	);
};

export default VentaDirNew;
