const SanBernardo = {

    commune: "San Bernardo",
    polygons: [

        {

            idService: 94,
            nameService: "San Bernardo 1 / Hasta El Mariscal",
            coordinates: [

                // San Bernado 1
                { "lat": -33.52395704933409,  "lng": -70.72639629963706 }, // lonquen con lo espejo
                { "lat": -33.539120644354426, "lng": -70.68543607474922 }, // lo espejo con autopista central
                { "lat": -33.54096040686961,  "lng": -70.68650781453604 },
                { "lat": -33.54121007249548,  "lng": -70.68626742716219 },
                { "lat": -33.55613465651696,  "lng": -70.6931203743669  },// Ochagavia
                { "lat": -33.555859555040826, "lng": -70.6954827522332  },
                { "lat": -33.563702018162594, "lng": -70.69713297516232 },
                { "lat": -33.56391062936608,  "lng": -70.69670054851355 },
                { "lat": -33.572984575223295, "lng": -70.70083499576818 }, // Ochagavia con lo blanco
                { "lat": -33.57634898156779,  "lng": -70.69220803889621 }, // Lo Blanco con Gran Avenida
                { "lat": -33.58440971979935,  "lng": -70.69804693733148 },
                { "lat": -33.58476680153226,  "lng": -70.69835039464834 },
                { "lat": -33.585051201826715, "lng": -70.69879040774373 },
                { "lat": -33.585262921432225, "lng": -70.69898386177081 },
                { "lat": -33.58548096048297,  "lng": -70.69908627860868 },
                { "lat": -33.58576535842984,  "lng": -70.69910903790166 },
                { "lat": -33.58590439707855,  "lng": -70.69905213965839 }, // PINTO
                { "lat": -33.586413150233675, "lng": -70.69875247555825 }, // PINTO
                { "lat": -33.58659642703037,  "lng": -70.69868799088255 },
                { "lat": -33.59041039207082,  "lng": -70.69841108610113 },
                { "lat": -33.59110870543091,  "lng": -70.69842246579493 }, // Pinto con colon Norte
                { "lat": -33.590097903728655, "lng": -70.70290287522215 }, // Colon con freire
                { "lat": -33.588271512348385, "lng": -70.7102996471688  },// Colon con Av Central
                { "lat": -33.587748165985246, "lng": -70.71326707152333 }, // Fin Colon
                { "lat": -33.572078293108596, "lng": -70.76751815658481 }, // Lonquen
                { "lat": -33.57154137578329,  "lng": -70.76710683350171 },
                { "lat": -33.56749670951874,  "lng": -70.76425775985977 },
                { "lat": -33.56651332845906,  "lng": -70.7634794725599  },
                { "lat": -33.56024249668675,  "lng": -70.75791239335653 },
                { "lat": -33.553671563214756, "lng": -70.75206173024239 },
                { "lat": -33.5509911705127,   "lng": -70.74965289901803 },
                { "lat": -33.54857009918012,  "lng":-70.74745608104146  },
                { "lat": -33.545769241035174, "lng": -70.7449389877582  },
                { "lat": -33.541372209315284, "lng": -70.74108104604957 },
                { "lat": -33.532677889041324, "lng": -70.73350869886343 },
                { "lat": -33.523952702584126, "lng": -70.72641435246021 }, //lonquen con lo espejo

            ]
        },

        {

            idService: 139,
            nameService: "San Bernardo 2 / Desde el Mariscal",
            coordinates: [

                { "lat": -33.59110870543091,  "lng": -70.69842246579493 }, // Pinto con colon Norte
                { "lat": -33.59041039207082,  "lng": -70.69841108610113 },
                { "lat": -33.58659642703037,  "lng": -70.69868799088255 },
                { "lat": -33.586413150233675, "lng": -70.69875247555825 }, // PINTO
                { "lat": -33.58590439707855,  "lng": -70.69905213965839 }, // PINTO
                { "lat": -33.58576535842984,  "lng": -70.69910903790166 },
                { "lat": -33.58548096048297,  "lng": -70.69908627860868 },
                { "lat": -33.585262921432225, "lng": -70.69898386177081 },
                { "lat": -33.585051201826715, "lng": -70.69879040774373 },
                { "lat": -33.58476680153226,  "lng": -70.69835039464834 },
                { "lat": -33.58440971979935,  "lng": -70.69804693733148 },
                { "lat": -33.57634898156779,  "lng": -70.69220803889621 }, // Lo Blanco con Gran Avenida
                { "lat": -33.58247567203665,  "lng": -70.67677725051716 }, // lo blanco con padre hurtado
                { "lat": -33.585210235745826, "lng": -70.66325162702708 }, // Lo Blanco con San Francisco
                { "lat": -33.59074584751979,  "lng": -70.66498974844464 }, // San Francisco con Santa Teresa
                { "lat": -33.5965752306175,   "lng": -70.66687167261793 }, // San Francisco con paicavi
                { "lat": -33.607498097671645, "lng": -70.67066588740582 }, // San francisco con el Mariscal
                { "lat": -33.60982341363686,  "lng": -70.6432770581922  }, // El mariscal con los granados
                { "lat": -33.621815830411364, "lng": -70.64692465410879 }, // limite
                { "lat": -33.62772279041414,  "lng": -70.64457703349613 }, // limite
                { "lat": -33.62801814704991,  "lng": -70.6288681517603  }, // limite
                { "lat": -33.64401761984557,  "lng": -70.63206085959051 }, // limite
                { "lat": -33.65121718692114,  "lng": -70.65315114237607 }, // limite
                { "lat": -33.65879502792799,  "lng": -70.66975025695687 }, // limite
                { "lat": -33.66649848221162,  "lng": -70.70367678370226 }, // limite
                { "lat": -33.67966244293415,  "lng": -70.71670832308227 },
                { "lat": -33.684519144089,    "lng": -70.71782412198871 },
                { "lat": -33.69137519625054,  "lng": -70.72297396309537 },
                { "lat": -33.69915900954009,  "lng": -70.74692072565814 },
                { "lat": -33.658062896671034, "lng": -70.77238013208448 },
                { "lat": -33.663254454214034, "lng": -70.74195711973405 }, // El barrancon
                { "lat": -33.64688994174893,  "lng": -70.73601886546811 },
                { "lat": -33.6187913238745,   "lng": -70.74880021475853 }, // Camino Catemito
                { "lat": -33.594555342954514, "lng": -70.74382169157705 },
                { "lat": -33.58781201995637,  "lng": -70.71536688479637 }, // Inicio Colon Sur
                { "lat": -33.58783656952849,  "lng": -70.71353580105838 }, // Colon 1
                { "lat": -33.58782788642643,  "lng": -70.71323873395694 }, // Colon 2
                { "lat": -33.5880290787789,   "lng": -70.7115995505928  }, // colon con buenos aires
                { "lat": -33.588271512348385, "lng": -70.7102996471688  },// Colon con Av Central
                { "lat": -33.590097903728655, "lng": -70.70290287522215 }, // Colon con freire
                { "lat": -33.59110870543091,  "lng": -70.69842246579493 }, // Pinto con colon Norte


            ]
        },

    ]

}

export default SanBernardo;