const Vitacura = {
    commune: "Vitacura",
    polygons: [
        {
            idService: 115,
            nameService: "Vitacura 1 / Hasta  Manquehue",
            coordinates: [
                //Limites manquehue norte
                {"lat": -33.38431568587696, "lng": -70.57943104468265},
                {"lat": -33.38685865079074, "lng": -70.57663568423267},
                {"lat": -33.38681249415164, "lng": -70.57647309861888},
                {"lat": -33.38683750651396, "lng": -70.57631216149132},
                {"lat": -33.38690333813004, "lng": -70.57621755023911},
                {"lat": -33.386900046550416, "lng": -70.57621360810359},
                {"lat": -33.38700866861207, "lng": -70.5761268811224},
                {"lat": -33.38713374839374, "lng": -70.57609534403835},
                {"lat": -33.387202871353765, "lng": -70.57611505471588},
                {"lat": -33.387285160527135, "lng": -70.57616236033974},
                {"lat": -33.387410239911, "lng": -70.57607957549406},
                {"lat": -33.39020105286317, "lng": -70.57279861467696},
                {"lat": -33.39017972305534, "lng": -70.57267939791069},
                {"lat": -33.39015128330344, "lng": -70.57250908824459},
                {"lat": -33.39023660253125, "lng": -70.57224510826215},
                {"lat": -33.39032192167534, "lng": -70.57210886052927},
                {"lat": -33.390442790319426, "lng": -70.57201519021292},
                {"lat": -33.39058498850907, "lng": -70.57198112827969},
                {"lat": -33.39076984580784, "lng": -70.5719981592463},
                {"lat": -33.39090493358526, "lng": -70.57209182956267},
                {"lat": -33.39103662708964, "lng": -70.57227204362013},
                {"lat": -33.39782449060978, "lng": -70.57108318665607},
                //Limite con las condes
                {"lat": -33.40354387926125, "lng": -70.58502944734977},
                {"lat": -33.40805787849391, "lng": -70.59901984843515},
                {"lat": -33.407914580535795, "lng": -70.6014231075173},
                {"lat": -33.40898930945971, "lng": -70.60442718136999},
                {"lat": -33.408971217420905, "lng": -70.60442388848354},
                //Limites con mapocho
                {"lat": -33.408130798594385, "lng": -70.60520699148657},
                {"lat": -33.40306853841807, "lng": -70.60365794893991},
                {"lat": -33.40187965516113, "lng": -70.60348607332824},
                {"lat": -33.40141381664954, "lng": -70.60360263186526},
                {"lat": -33.396482976514335, "lng": -70.60364638151006},
                {"lat": -33.39369900341763, "lng": -70.60233962981611},
                {"lat": -33.390894352561766, "lng": -70.59988391146273},
                {"lat": -33.38374386821614, "lng": -70.59060903248887},
                {"lat": -33.38252900907341, "lng": -70.58811525700862},
                {"lat": -33.38136269663421, "lng": -70.58046841727305}
            ]
        },{
            idService: 116,
            nameService: "Vitacura 2 / Manquehue Hasta Tabancura",
            coordinates: [
                //Limites con mapocho
                {"lat": -33.37720438587701, "lng": -70.5402080009409},
                {"lat": -33.376347766785145, "lng": -70.54239340941868},
                {"lat": -33.376467752773, "lng": -70.54296997860881},
                {"lat": -33.375365415262756, "lng": -70.54483624133712},
                {"lat": -33.37492194220849, "lng": -70.54595903354766},
                {"lat": -33.37499796632124, "lng": -70.54674802266857},
                {"lat": -33.374934612898564, "lng": -70.54733976450926},
                {"lat": -33.37499796632124, "lng": -70.54902395282505},
                {"lat": -33.375276720832794, "lng": -70.55016191790331},
                {"lat": -33.375340074006274, "lng": -70.55070814114084},
                {"lat": -33.37635371850606, "lng": -70.55407651777244},
                {"lat": -33.376277695578366, "lng": -70.5546530867454},
                {"lat": -33.37655644598837, "lng": -70.55723247425608},
                {"lat": -33.37733539195929, "lng": -70.56317030453951},
                {"lat": -33.37820987212191, "lng": -70.5694280982626},
                {"lat": -33.37817337940118, "lng": -70.56992723019313},
                {"lat": -33.379607250336896, "lng": -70.57355687505714},
                {"lat": -33.37968546911765, "lng": -70.57394007182245},
                {"lat": -33.38033254905984, "lng": -70.57578793177964},
                {"lat": -33.380474763786324, "lng": -70.57605191177352},
                {"lat": -33.381000956252045, "lng": -70.57773797754092},
                {"lat": -33.38136269663421, "lng": -70.58046841727305},
                //Limites manquehue norte
                {"lat": -33.38431568587696, "lng": -70.57943104468265},
                {"lat": -33.38685865079074, "lng": -70.57663568423267},
                {"lat": -33.38681249415164, "lng": -70.57647309861888},
                {"lat": -33.38683750651396, "lng": -70.57631216149132},
                {"lat": -33.38690333813004, "lng": -70.57621755023911},
                {"lat": -33.386900046550416, "lng": -70.57621360810359},
                {"lat": -33.38700866861207, "lng": -70.5761268811224},
                {"lat": -33.38713374839374, "lng": -70.57609534403835},
                {"lat": -33.387202871353765, "lng": -70.57611505471588},
                {"lat": -33.387285160527135, "lng": -70.57616236033974},
                {"lat": -33.387410239911, "lng": -70.57607957549406},
                {"lat": -33.39020105286317, "lng": -70.57279861467696},
                {"lat": -33.39017972305534, "lng": -70.57267939791069},
                {"lat": -33.39015128330344, "lng": -70.57250908824459},
                {"lat": -33.39023660253125, "lng": -70.57224510826215},
                {"lat": -33.39032192167534, "lng": -70.57210886052927},
                {"lat": -33.390442790319426, "lng": -70.57201519021292},
                {"lat": -33.39058498850907, "lng": -70.57198112827969},
                {"lat": -33.39076984580784, "lng": -70.5719981592463},
                {"lat": -33.39090493358526, "lng": -70.57209182956267},
                {"lat": -33.39103662708964, "lng": -70.57227204362013},
                {"lat": -33.39782449060978, "lng": -70.57108318665607},
                //Limites con las condes
                {"lat": -33.396536572516894, "lng": -70.56746202741007},
                {"lat": -33.39460028721773, "lng": -70.56168630537252},
                {"lat": -33.39097874269389, "lng": -70.55160308542948},
                {"lat": -33.389402000712444, "lng": -70.54758881184132},
                {"lat": -33.388477189446135, "lng": -70.54484252301276},
                {"lat": -33.386917316962425, "lng": -70.54080388434289},
                {"lat": -33.385473586749114, "lng": -70.5376952141261},
                {"lat": -33.38456140294252, "lng": -70.53532824685728},
                {"lat": -33.38387725883403, "lng": -70.53417510900661},
                //Limites con tabancura
                {"lat": -33.38388743374257, "lng": -70.53410151274247},
                {"lat": -33.38375368775181, "lng": -70.53461629473787},
                {"lat": -33.38329034042065, "lng": -70.53482701738292},
                {"lat": -33.380005782290745, "lng": -70.53725032745469},
                {"lat": -33.37940898456232, "lng": -70.53719198850496},
                {"lat": -33.37912966028093, "lng": -70.53730348892829},
                {"lat": -33.37868273956455, "lng": -70.53786099104492},
                {"lat": -33.377006766422355, "lng": -70.53790559121425},
            ]
        },{
            idService: 117,
            nameService: "Vitacura 3 / Santa Maria de Manquehue",
            coordinates: [
                //Limites comuna - norte
                {"lat": -33.380065657213486, "lng": -70.59441341204916},
                {"lat": -33.381839221395616, "lng": -70.59250185665934},
                {"lat": -33.379297101500754, "lng": -70.58981151944404},
                {"lat": -33.37758260658791, "lng": -70.5903779062262},
                {"lat": -33.37640017660961, "lng": -70.59002391448735},
                {"lat": -33.37557246605952, "lng": -70.58846635083637},
                {"lat": -33.3757498332691, "lng": -70.58811235909752},
                {"lat": -33.37504036226054, "lng": -70.58528042518668},
                {"lat": -33.3740352684251, "lng": -70.5852096268389},
                {"lat": -33.37379877407, "lng": -70.5845016433612},
                {"lat": -33.36960089226709, "lng": -70.58478483675228},
                {"lat": -33.36966001876944, "lng": -70.58889114092301},
                {"lat": -33.3694235125189, "lng": -70.58903273761854},
                {"lat": -33.36930525915251, "lng": -70.58959912440072},
                {"lat": -33.36824097162159, "lng": -70.5893867293574},
                {"lat": -33.367294927330384, "lng": -70.58499723179558},
                {"lat": -33.36646713013678, "lng": -70.58485563510006},
                {"lat": -33.36605322858645, "lng": -70.58117412101595},
                {"lat": -33.36439760269511, "lng": -70.58067853258154},
                {"lat": -33.360849726846055, "lng": -70.57798819536625},
                {"lat": -33.36256455156817, "lng": -70.57451907632544},
                {"lat": -33.36368803909773, "lng": -70.57388189119551},
                {"lat": -33.36617148637319, "lng": -70.57466067302099},
                //limites con LO CURRO
                {"lat": -33.3667627728958, "lng": -70.57182873911013},
                {"lat": -33.36705796734529, "lng": -70.57068640907002},
                {"lat": -33.37010988393797, "lng": -70.56992675161669},
                {"lat": -33.37286433816078, "lng": -70.5709540805698},
                {"lat": -33.376702366630276, "lng": -70.5697104718371},
                {"lat": -33.37820987212191, "lng": -70.5694280982626},
                //Limites con mapocho
                {"lat": -33.38136269663421, "lng": -70.58046841727305},
                {"lat": -33.38252900907341, "lng": -70.58811525700862},
                {"lat": -33.38374386821614, "lng": -70.59060903248887},
                {"lat": -33.390894352561766, "lng": -70.59988391146273},
                //Limites con Huechuraba
                {"lat": -33.40327395525945, "lng": -70.6101689218196},
                {"lat": -33.395522001316884, "lng": -70.60748257831595},
                {"lat": -33.395522001316884, "lng": -70.60748257831595},
                {"lat": -33.39362047303867, "lng": -70.60555541884594},
                {"lat": -33.39215773066888, "lng": -70.60502982989958},
                {"lat": -33.391922792117505, "lng":-70.6038989986302},
                {"lat": -33.38907204918524, "lng": -70.60197283654928},
                {"lat": -33.38670748561816, "lng": -70.60176044150597},
                {"lat": -33.38558596281644, "lng": -70.6026188221276},
                {"lat": -33.38558596281644, "lng": -70.6019764356808},
                {"lat": -33.381839221395616, "lng": -70.60106845673965},
                {"lat": -33.38142539298865, "lng": -70.60135165013074},

            ]
        },{
            idService: 43,
            nameService: "Vitacura 4 / Desde rtda Lo curro hasta Lo curro",
            coordinates: [
                //Limites con norte y lobarnechea
                {"lat": -33.36617148637319, "lng": -70.5709791589369},
                {"lat": -33.36451586273211, "lng": -70.56857201511266},
                {"lat": -33.362091499847644, "lng": -70.56857201511266},
                {"lat": -33.36067232925645, "lng": -70.56913840189483},
                {"lat": -33.36037666580351, "lng": -70.5680056283305},
                {"lat": -33.36286020758758, "lng": -70.56736844320055},
                {"lat": -33.36262368285241, "lng": -70.56637726633174},
                {"lat": -33.363451516612756, "lng": -70.56637726633174},
                {"lat": -33.36386543053954, "lng": -70.56616487128844},
                {"lat": -33.36439760269511, "lng": -70.56142138198777},
                {"lat": -33.36268281409647, "lng": -70.56220016381324},
                {"lat": -33.362505420243735, "lng": -70.56460730763747},
                {"lat": -33.359548802791274, "lng": -70.56418251755085},
                {"lat": -33.35925313552121, "lng": -70.56566928285405},
                {"lat": -33.358484395918005, "lng": -70.56559848450628},
                {"lat": -33.358602663991235, "lng": -70.56269575224765},
                {"lat": -33.35937140254977, "lng": -70.56064260016228},
                {"lat": -33.35883919965556, "lng": -70.5558991108616},
                {"lat": -33.36138191744509, "lng": -70.5556867158183},
                {"lat": -33.360317532992376, "lng": -70.5541999505151},
                {"lat": -33.36019926724957, "lng": -70.55292558025522},
                {"lat": -33.3612636531488, "lng": -70.55087242816985},
                {"lat": -33.36351064729428, "lng": -70.54910246947557},
                {"lat": -33.363451516612756, "lng": -70.54874847773672},
                {"lat": -33.36392456093978, "lng": -70.548040494259},
                {"lat": -33.36658538736094, "lng": -70.54598734217365},
                {"lat": -33.36664451591274, "lng": -70.5453501570437},
                {"lat": -33.36717667107065, "lng": -70.5441465851316},
                {"lat": -33.36871398990942, "lng": -70.54280141652394},
                {"lat": -33.370369533663215, "lng": -70.54280141652394},
                {"lat": -33.371315544519774, "lng": -70.54110225617742},
                {"lat": -33.37238314196971, "lng": -70.54013737863117},
                {"lat": -33.37443202751056, "lng": -70.54127676489493},
                {"lat": -33.37567360661027, "lng": -70.53448012378969},
                //Limites con tabancura
                {"lat": -33.375774591403434, "lng": -70.534979412254},
                {"lat": -33.377006766422355, "lng": -70.53790559121425},
                //Limites con mapocho
                {"lat": -33.37720438587701, "lng": -70.5402080009409},
                {"lat": -33.376347766785145, "lng": -70.54239340941868},
                {"lat": -33.376467752773, "lng": -70.54296997860881},
                {"lat": -33.375365415262756, "lng": -70.54483624133712},
                {"lat": -33.37492194220849, "lng": -70.54595903354766},
                {"lat": -33.37499796632124, "lng": -70.54674802266857},
                {"lat": -33.374934612898564, "lng": -70.54733976450926},
                {"lat": -33.37499796632124, "lng": -70.54902395282505},
                {"lat": -33.375276720832794, "lng": -70.55016191790331},
                {"lat": -33.375340074006274, "lng": -70.55070814114084},
                {"lat": -33.37635371850606, "lng": -70.55407651777244},
                {"lat": -33.376277695578366, "lng": -70.5546530867454},
                {"lat": -33.37655644598837, "lng": -70.55723247425608},
                {"lat": -33.37733539195929, "lng": -70.56317030453951},
                {"lat": -33.37820987212191, "lng": -70.5694280982626},
                //limites con LO CURRO
                {"lat": -33.37820987212191, "lng": -70.5694280982626},
                {"lat": -33.376702366630276, "lng": -70.5697104718371},
                {"lat": -33.37286433816078, "lng": -70.5709540805698},
                {"lat": -33.37010988393797, "lng": -70.56992675161669},
                {"lat": -33.3667627728958, "lng": -70.57182873911013},
                {"lat": -33.36705796734529, "lng": -70.57068640907002},
            ]
        },{
            idService: 138,
            nameService: "Vitacura 5 / Tabancura hasta Puente Nuevo",
            coordinates: [
                //Limites con tabancura
                {"lat": -33.38388743374257, "lng": -70.53410151274247},
                {"lat": -33.38375368775181, "lng": -70.53461629473787},
                {"lat": -33.38329034042065, "lng": -70.53482701738292},
                {"lat": -33.380005782290745, "lng": -70.53725032745469},
                {"lat": -33.37940898456232, "lng": -70.53719198850496},
                {"lat": -33.37912966028093, "lng": -70.53730348892829},
                {"lat": -33.37868273956455, "lng": -70.53786099104492},
                {"lat": -33.377006766422355, "lng": -70.53790559121425},
                //Limites con lobarnechea
                {"lat": -33.37567360661027, "lng": -70.53448012378969},
                {"lat": -33.37342692668689, "lng": -70.52881625620199},
                {"lat": -33.37141669019642, "lng": -70.52619671744267},
                {"lat": -33.369879419181196, "lng": -70.52109923661375},
                {"lat": -33.369110773486334, "lng": -70.52067444654467},
                {"lat": -33.368933392746136, "lng": -70.52017885813075},
                {"lat": -33.36970204000825, "lng": -70.52017885813075},
                //Limites con las condes
                {"lat": -33.37242181424879, "lng": -70.5174885210266},
                {"lat": -33.37644219431391, "lng": -70.52619671744267},
                {"lat": -33.38376549930338, "lng": -70.53404602253558},
            ]
        },
    ]
};

export default Vitacura;
