const Buin = {
    commune: "Buin",
    polygons: [
        {
            idService: 124,
            nameService: "Buin Maipo",
            coordinates: [

                { "lat": -33.76094051147603, "lng": -70.74153029467007 }, // Ruta 5 Limite Quin Maipo
                { "lat": -33.74425691341874, "lng": -70.73827845510525 }, // Ruta 5 con lo que marca google Limite con Buin Maipo
                { "lat": -33.74458011131812, "lng": -70.73880505582974 },  // limite buin maipo sur
                { "lat": -33.744788625445565, "lng": -70.73949465201655 }, // Limite buin maipo sur
                { "lat": -33.74476777403139, "lng": -70.74030962939361 },
                { "lat": -33.74455925985324, "lng": -70.74044754863097 },
                { "lat": -33.74471564553438, "lng": -70.74119983538021 },
                { "lat": -33.745507995304315, "lng": -70.74586401379332 }, // limite sur Quin maipo párroco Damian
                { "lat": -33.74644629473417, "lng": -70.7456884802185 }, // condominio terranoble buen
                { "lat": -33.74690501516367, "lng": -70.74705513447964 }, // Simon reyes
                { "lat": -33.75017853977693, "lng": -70.74928691866447 }, //Manuel acosta
                { "lat": -33.74863562020438, "lng": -70.75232114188645 },
                { "lat": -33.74704054572598, "lng": -70.75133063099989 },
                { "lat": -33.745195226542336, "lng": -70.75107986875014 },
                { "lat": -33.745070118719816, "lng": -70.75133063099989 },
                { "lat": -33.74434031945117, "lng": -70.7511425593126 }, //Villaseca
                { "lat": -33.74335933533574, "lng": -70.76332307840367 }, // Fundo el Carmelo
                { "lat": -33.73959407415199, "lng": -70.76672766276297 }, // elisa gonzales cavieres
                { "lat": -33.734192995196324, "lng": -70.7665233402127 },
                { "lat": -33.73372791707366, "lng": -70.7663288219229 },
                { "lat": -33.733606591931405, "lng": -70.7665800747139 },
                { "lat": -33.732831455028915, "lng": -70.76637745149534 }, //camino buin maipo
                { "lat": -33.73228080413252, "lng": -70.76805170006921 },//
                { "lat": -33.730842853808696, "lng": -70.7670298519528 },
                { "lat": -33.72562029712518, "lng": -70.76402797527385 },
                { "lat": -33.72355246354438, "lng": -70.75358925449096 },
                { "lat": -33.715353811497735, "lng": -70.77216837572374 },
                { "lat": -33.754554794184635, "lng": -70.81828004967828 },// LIMITE PUENTE Buin vilcuo
                { "lat": -33.76001259750268, "lng": -70.81003774722475 }, //Limite buin biluco terpel
                { "lat": -33.7650420608245, "lng": -70.80574440965705 }, //Limite Buin viluco las tortolas
                { "lat": -33.758976833690035, "lng": -70.79604554210377 },
                { "lat": -33.759267228350105, "lng": -70.79014823242078 }, //Las tortolas continuación g94
                { "lat": -33.763550947253236, "lng": -70.77243286094537 }, //g94
                { "lat": -33.76511011537729, "lng": -70.77289571384492 }, //Limite Buin Viluco
                { "lat": -33.77008970565869, "lng": -70.76426754401491 }, //Limite bUIN Biluco
            ]
        },
        {
            idService: 123,
            nameService: "Buin Viluco",
            coordinates: [
                { "lat": -33.754554794184635, "lng": -70.81828004967828 },// LIMITE PUENTE Buin vilcuo
                { "lat": -33.76001259750268, "lng": -70.81003774722475 }, //Limite buin biluco terpel
                { "lat": -33.7650420608245, "lng": -70.80574440965705 }, //Limite Buin viluco las tortolas
                { "lat": -33.758976833690035, "lng": -70.79604554210377 },
                { "lat": -33.759267228350105, "lng": -70.79014823242078 }, //Las tortolas continuación g94
                { "lat": -33.763550947253236, "lng": -70.77243286094537 }, //g94
                { "lat": -33.76511011537729, "lng": -70.77289571384492 }, //Limite Buin Viluco
                { "lat": -33.77008970565869, "lng": -70.76426754401491 }, //Limite bUIN Biluco
                { "lat": -33.76172507451597, "lng": -70.74218312154966 },
                { "lat": -33.76172507451597, "lng": -70.74218312154966 },
                { "lat": -33.79846860274943, "lng": -70.75374010418146 },
                { "lat": -33.79884281904586, "lng": -70.75810452111853 },
                { "lat": -33.798363141688434, "lng": -70.7617227705173 },
                { "lat": -33.80140979551686, "lng": -70.76886277048372 },
                { "lat": -33.8191291763004, "lng": -70.77015467794844 },
                { "lat": -33.82426413986799, "lng": -70.79451861197717 }, //Limite buin Paine
                { "lat": -33.82913141029265, "lng": -70.78280049010314 },
                { "lat": -33.82149852169166, "lng": -70.79638286111725 },
                { "lat": -33.825259740277914, "lng": -70.84432064007285 },
                { "lat": -33.81388350184245, "lng": -70.85258795829901 },
                { "lat": -33.810973624224225, "lng": -70.8500851352501 },
                { "lat": -33.80799861573806, "lng": -70.84384943671812 },
                { "lat": -33.802182008018164, "lng": -70.8344757729465 },
                { "lat": -33.79710051007421, "lng": -70.82968836568647 },
                { "lat": -33.80324780860195, "lng": -70.8518094957667 },
                { "lat": -33.79861272800665, "lng": -70.8643793635997 },
                { "lat": -33.789668271473076, "lng": -70.8562875111822 },
                { "lat": -33.772823767657826, "lng": -70.83293680755762 },
                { "lat": -33.76840016383707, "lng": -70.82701449052816 },
                { "lat": -33.760194487594504, "lng": -70.82306627917517 },
            ]
        },
        {
            idService: 84,
            nameService: "Buin Centro",
            coordinates: [
                { "lat": -33.70199604651649, "lng": -70.74799518896207 },
                { "lat": -33.69729384310226, "lng": -70.738158124049 },
                { "lat": -33.69285045427009, "lng": -70.7238495155626 }, //Ribera sur (limte San bernardo).
                { "lat": -33.69430840679546, "lng": -70.72387101179713 }, // Bajé de inmediato a ruta 5
                { "lat": -33.68984163528711, "lng": -70.7191821226538 }, // Nos devolvemos ribera sur pegado al maipo
                { "lat": -33.6559857018831, "lng": -70.66310374625553 }, //Hasta Acceso sur
                { "lat": -33.65467704101728, "lng": -70.66016143249274 }, // AV Virgina con padre hurtado , bajamos por av Virginia
                { "lat": -33.66633916261037, "lng": -70.66107970532848 },
                { "lat": -33.68652020971836, "lng": -70.65561180818229 },
                { "lat": -33.71475021833174, "lng": -70.6815438896808 }, //Padre hurtado con los guindos
                { "lat": -33.711124455236956, "lng": -70.69989449073307 }, // los guindos por acceso sur y bajamos
                { "lat": -33.72659063933456, "lng": -70.70328662696377 },
                { "lat": -33.74578959720624, "lng": -70.70445448299003 },// 5 sur con teniente merino
                { "lat": -33.762928260907515, "lng": -70.73592980403404 }, // Fco Javier con hermanos carrera
                { "lat": -33.76094051147603, "lng": -70.74153029467007 }, // Ruta 5 Limite Quin Maipo
                { "lat": -33.74425691341874, "lng": -70.73827845510525 }, // Ruta 5 con lo que marca google Limite con Buin Maipo
                { "lat": -33.74458011131812, "lng": -70.73880505582974 }, // limite buin maipo sur
                { "lat": -33.744788625445565, "lng": -70.73949465201655 }, // Limite buin maipo sur
                { "lat": -33.74476777403139, "lng": -70.74030962939361 },
                { "lat": -33.74455925985324, "lng": -70.74044754863097 },
                { "lat": -33.74471564553438, "lng": -70.74119983538021 },
                { "lat": -33.745507995304315, "lng": -70.74586401379332 }, // limite sur Quin maipo párroco Damian
                { "lat": -33.74644629473417, "lng": -70.7456884802185 }, // condominio terranoble buen
                { "lat": -33.74690501516367, "lng": -70.74705513447964 }, // Simon reyes
                { "lat": -33.75017853977693, "lng": -70.74928691866447 }, //Manuel acosta
                { "lat": -33.74863562020438, "lng": -70.75232114188645 },
                { "lat": -33.74704054572598, "lng": -70.75133063099989 },
                { "lat": -33.745195226542336, "lng": -70.75107986875014 },
                { "lat": -33.745070118719816, "lng": -70.75133063099989 },
                { "lat": -33.74434031945117, "lng": -70.7511425593126 }, //Villaseca
                { "lat": -33.74335933533574, "lng": -70.76332307840367 }, // Fundo el Carmelo
                { "lat": -33.73959407415199, "lng": -70.76672766276297 }, // elisa gonzales cavieres
                { "lat": -33.734192995196324, "lng": -70.7665233402127 },
                { "lat": -33.73372791707366, "lng": -70.7663288219229 },
                { "lat": -33.733606591931405, "lng": -70.7665800747139 },
                { "lat": -33.732831455028915, "lng": -70.76637745149534 }, //camino buin maipo
                { "lat": -33.73228080413252, "lng": -70.76805170006921 },//
                { "lat": -33.730842853808696, "lng": -70.7670298519528 },
                { "lat": -33.72562029712518, "lng": -70.76402797527385 },
                { "lat": -33.72355246354438, "lng": -70.75358925449096 },
                { "lat": -33.709704344763175, "lng": -70.75501439474684 }
            ]
        },
        {
            idService: 141,
            nameService: "Buin alto Jahuel",
            coordinates: [
                { "lat": -33.687717866893436, "lng": -70.68643475338561 },
                { "lat": -33.691464155311614, "lng": -70.67577099354988 },
                { "lat": -33.701671179479604, "lng": -70.64113124697643 },
                { "lat": -33.71245079761688, "lng": -70.64274736401406 },
                { "lat": -33.71763769044906, "lng": -70.6363817722073 },
                { "lat": -33.7249852522313, "lng": -70.63326393132236 },
                { "lat": -33.73018062250567, "lng": -70.63650901772685 },
                { "lat": -33.74588324776978, "lng": -70.6353073863795 },
                { "lat": -33.751939909895306, "lng": -70.67284067129656 },
                { "lat": -33.75568393744618, "lng": -70.67910594902064 },
                { "lat": -33.75686844285752, "lng": -70.68611070801317 },
                { "lat": -33.758052931902576, "lng": -70.69311546700573 },
                { "lat": -33.75874387628735, "lng": -70.70237599584333 },
            ]
        },
        {
            idService: 161,
            nameService: "Buin Linderos",
            coordinates: [
                { "lat":-33.77578330248173 , "lng":-70.70397047727606},
                { "lat":-33.772138362146066, "lng":-70.70252905498198},
                { "lat":-33.770662684767814, "lng":-70.70208904188354},
                { "lat":-33.76436239169189 , "lng":-70.70151247299583},
                { "lat":-33.76324607519934 , "lng":-70.70152764584405},
                { "lat":-33.761410743692814, "lng":-70.7018007574111},
                { "lat":-33.75833915592108 , "lng":-70.70247594990637},
                { "lat":-33.75618204080499 , "lng":-70.7029538951763 },
                { "lat":-33.754321327730125, "lng":-70.70328011178852},
                { "lat":-33.752290007823184, "lng":-70.70350932220809},
                { "lat":-33.74965397051688 , "lng":-70.70365416150268},
                { "lat":-33.74596653905417 , "lng":-70.70397862371689},
                { "lat":-33.76294704537693 , "lng":-70.73591557430683},
                { "lat":-33.760973         , "lng":-70.741547        },
                { "lat":-33.774409771760396, "lng":-70.74384445940927},
                { "lat":-33.77590672114569 , "lng":-70.7440308909095 },
            ]
        },
    ]
};

export default Buin;
