import React, { useState } from 'react';

const Checkbox = ({label, children, setter, list}) => {
  const [ isChecked, setChecked ] = useState(false)

  const handleChange = () => {
    if (!isChecked) {
      setter([...list, children])
    } else {
      setter(list.filter(el => el !== children))
    }
    setChecked(!isChecked)
  }

  return (
    <label>
      {label}
      <input 
        type='checkbox' 
        value={children} 
        checked={isChecked} 
        onChange={handleChange}/>
    </label>
  )
}

export default Checkbox;