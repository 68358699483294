const SanJoseDelMaipo = {
    commune: "San José de Maipo",
    polygons: [
        {
            idService: 99,
            nameService: "San José de Maipo / Pueblo",
            coordinates: [
                {"lat":-33.597722219529444, "lng":-70.49724062253179},
                {"lat":-33.58385857498279, "lng":-70.48089331000214},
                {"lat":-33.55201847032041, "lng":-70.47275137146225},
                {"lat":-33.54600583019014, "lng":-70.3531841043216},
                {"lat":-33.54466928451156, "lng":-70.23676040841698}, //Mirador los condores limte
                {"lat":-33.6186827385111, "lng":-70.29288753383487}, // Rodeo de alfaro
                {"lat":-33.64983684250147, "lng":-70.35124161051841}, //Pasado el pueblo
                {"lat":-33.64769776415842, "lng":-70.36063196206256}, //Camino el toyo
                {"lat":-33.64534602917279, "lng":-70.39723007839328}, // el tolyo
                {"lat":-33.58087167672359, "lng":-70.44460861868038}, // aridos sirena
                {"lat":-33.58132310046554, "lng":-70.44299164328815}, //camino el toyo
                {"lat":-33.58147358290199, "lng":-70.45111996435968}, // el canelo
                {"lat":-33.59103674920826, "lng":-70.46954415937616}, // las vertientes
            ]
        },{
            idService: 136,
            nameService: "San José de Maipo / San Gabriel",
            coordinates: [
                {"lat":-33.650647313097956, "lng":-70.35112509820897},
                {"lat":-33.65458210732293, "lng":-70.07540705216933},
                {"lat":-33.837451581969205, "lng":-70.06942045071291},
                {"lat":-33.79702587372515, "lng":-70.30561338805137},
            ]
        },
    ]
};

export default SanJoseDelMaipo;
