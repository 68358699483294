const Pudahuel = {
    commune: "Pudahuel",
    polygons: [
        {
            idService: 15,
            nameService: "Aerouerto / Hoteles ENEA",
            coordinates: [
                { "lat": -33.43691328819923, "lng": -70.82512567480325 },//Limite pudahuel 1 , con la 68n, ciudad de los vales
                {"lat":-33.42592748310448, "lng":-70.80330716421969},
                {"lat":-33.36601381988593, "lng":-70.81139540060303},
             	 {"lat":-33.355152255378, "lng":-70.8139631229332},
               {"lat":-33.34904635249009, "lng": -70.80550277794703},
               {"lat":-33.349890411459235, "lng":-70.80473592156905},
               {"lat":-33.350237076157335, "lng":-70.80424874223992},
               {"lat":-33.35079475128126, "lng":-70.8031119903743},
               {"lat":-33.35175937004058, "lng":-70.80156023399259},
               {"lat":-33.353522786124174, "lng":-70.79914238092525},
               { "lat":- 33.3691389336668, "lng": -70.77881265152071},
               { "lat":- 33.373649403748985, "lng": -70.7727762759031},
               { "lat":- 33.378297889637246, "lng": -70.76397384540749},
               { "lat":- 33.383576757559865, "lng": -70.75736320320954}, // Americo vespucio , ultima coordenada limite quilicura
               { "lat":- 33.38839005830072, "lng": -70.76393652269586}, // vespucio , pudahuel renca
               { "lat":- 33.392382259534656, "lng": -70.76871794439106},
               { "lat":- 33.4033438173632, "lng": -70.78085389146415}, // Memorial 3 sillas por vespucio
               { "lat": - 33.40641643896533, "lng": -70.78288643770112},
               {"lat":-33.41591638877982, "lng":-70.78213998242097},
               {"lat":-33.42523325950964, "lng":-70.77041879913831},
               {"lat":-33.444429249646404, "lng":-70.78346506343001},
            ]
        },
        {
            idService: 157,
            nameService: "Pudahuel 1",
            coordinates: [
                { "lat": -33.46245748840184, "lng": -70.83030585309848 },
               { "lat": -33.454679838884786, "lng": -70.83023090800756 },
                { "lat": -33.43754348413218, "lng": -70.82159832233596 }, // Rut 68 limite poniente ciudad de los valles con resto pudahuel, comenzar por acá hacia el oriente
                {"lat":-33.444429249646404, "lng":-70.78346506343001},
                {"lat":-33.42523325950964, "lng":-70.77041879913831},
               { "lat":- 33.42537818457009, "lng": -70.76827501253595},// Limite cerro navia
               { "lat":- 33.42594185821958, "lng": -70.76700749071168}, // JJ perez
               { "lat": - 33.42629440051409, "lng": -70.76616267012166},
               { "lat":- 33.42778193225935, "lng": -70.76283304069139},
               { "lat":- 33.42783030870699, "lng": -70.76151640758432},
               { "lat":- 33.42785104146201, "lng": -70.76157437256387},
               { "lat":- 33.42759533713732, "lng": -70.76002587953857},
               { "lat":- 33.42747785110787, "lng": -70.75733464829754},
               { "lat":- 33.42760915900587, "lng": -70.75571990958133},
               { "lat":- 33.42808601233341, "lng": -70.75447780287655},
               { "lat":- 33.42838318048023, "lng": -70.75239934430338},
               { "lat":- 33.42921939234508, "lng": -70.75069351776214},
               { "lat":- 33.42942080593773, "lng": -70.74953373586523},
               { "lat":- 33.42985683652856, "lng": -70.7478336488375},
               { "lat":- 33.43002051355027, "lng": -70.74688521756046},
               { "lat":- 33.430014205878955, "lng": -70.74093103703979}, // jj perez con teniente cruz
               { "lat":- 33.43647584325604, "lng": -70.74119664733739}, // teniente cruz con san francisco
               { "lat":- 33.4402726859113, "lng": -70.7409182149197},
               { "lat":- 33.44445224374983, "lng": -70.74062345508075}, // teniente cruz con san pablo
               { "lat":- 33.44584820792828, "lng": -70.74074646889493},
               { "lat":- 33.44660776723505, "lng": -70.73983616666993},
               { "lat":- 33.45323207757269, "lng": -70.73920003166367},
               { "lat":- 33.45363671235843, "lng": -70.73903981507844},
               { "lat":- 33.45524318468214, "lng": -70.73141014319762}, // av las torres
               { "lat":-33.45574615355892, "lng":-70.73120187835785},
               { "lat":-33.46291251530337,  "lng":-70.73124840270388},
               { "lat":-33.467834, "lng":-70.731459},
               { "lat":-33.469434, "lng":-70.734139}, // Pajaritos
  			    { "lat":-33.468965, "lng":-70.735584},
               { "lat":- 33.46855798740376, "lng": -70.7371677236485},// marta ossa ruiz
               { "lat":- 33.467151108064584, "lng": -70.74364174843922},
               { "lat":- 33.46526812464653, "lng": -70.75329282006015},
               { "lat":- 33.46400360734424, "lng": -70.76013152965297},
               { "lat":- 33.46350367681101, "lng": -70.76171782826987},
               { "lat":- 33.462999697298606, "lng": -70.76309574808681},// Pudahuel vespucio maipu
               { "lat":- 33.45873769649231, "lng": -70.76457362823488}, // vespucio sur con laguna sur
               { "lat":- 33.458125755649405, "lng": -70.77541974506632},
               { "lat":- 33.4617406967083, "lng": -70.77767394744217},
                { "lat":- 33.460662952315744, "lng": -70.7878100617185},
                { "lat":- 33.45991681373371, "lng": -70.79665431829295},
                { "lat":- 33.462403917367205, "lng": -70.79675369196231},
                { "lat":- 33.46157489075076, "lng": -70.80003302305171},
                { "lat":- 33.461491987653076, "lng": -70.80420671716548},
                { "lat":- 33.46008262286245, "lng": -70.80659168523052},
                { "lat":- 33.46472514966977, "lng": -70.81344846911385},
            ]
        },
        {
            idService: 122,
            nameService: "Pudahuel 2 / Ciudad de los valles",
            coordinates: [
                { "lat":-33.42789906900312, "lng":-70.86915326245915},
                { "lat":-33.406217245199095, "lng":-70.85243962907298},
                { "lat":-33.40185998779928, "lng":-70.80643550110074},
                {"lat":-33.42592748310448, "lng":-70.80330716421969},
                { "lat": -33.43691328819923, "lng": -70.82512567480325 },//Limite pudahuel 1 , con la 68n, ciudad de los vales
                { "lat": -33.43754348413218, "lng": -70.82159832233596 },
                { "lat": -33.454679838884786, "lng": -70.83023090800756 },
                { "lat": -33.46483220694446, "lng": -70.84016702472798 },
                { "lat": -33.46978584244965, "lng": -70.84360492002274 },
                { "lat": -33.47618152935279, "lng": -70.84825836143746 },
            ]
        },
        {
            idService: 79,
            nameService: "Pudahuel 3 / Praderas de lo Aguirre",
            coordinates: [
                { "lat":-33.44595303442577, "lng": -70.86142807410121 },
                { "lat": -33.47618152935279, "lng": -70.84825836143746 },
                { "lat": -33.478935578675284, "lng": -70.85969162402952 },
                { "lat": -33.47743216713806, "lng": -70.8644981425379 },
                { "lat": -33.47764694181209, "lng": -70.87694359224703 },
                { "lat": -33.48043896412325, "lng": -70.88363838588367 },
                { "lat": -33.47779012463232, "lng": -70.88964653401912 },
                { "lat": -33.47685943223794, "lng": -70.89531135997728 },
                { "lat": -33.48301613578755, "lng": -70.91187668269359 },
                { "lat": -33.48503734872189, "lng": -70.91381876725919 },
                { "lat": -33.4859141816087, "lng": -70.91358513926373 },
                { "lat": -33.48747297371154, "lng": -70.91989309514102 },
                { "lat": -33.473345395391185, "lng": -70.93227537890017 },//cuesta lo prado
                { "lat": -33.474806975849475, "lng": -70.93542935683881 },
                { "lat": -33.47071448844044, "lng": -70.94138687072294 },
                { "lat": -33.469252838954425, "lng": -70.94664350062068 },
                { "lat": -33.45874489220858, "lng": -70.94793275104476 }, // Ruta 68 limite con el noviciado, ideal partir el noviciado desde acá hasta arriba
                { "lat": -33.45652853909435, "lng": -70.92681548907429 }, // Limite el noviciado con pudahuel por la 68
                { "lat": -33.45732324928036, "lng": -70.90555607150532 },
                { "lat": -33.45563912848216, "lng": -70.89730227402043 }, //Peaje lo prado
                { "lat": -33.44961345030173, "lng": -70.87617434648024 },

            ]
        },
        {
            idService: 156,
            nameService: "Pudahuel 4 / Noviciado",
            coordinates: [
                { "lat":-33.42789906900312, "lng":-70.86915326245915},
                { "lat":-33.406217245199095, "lng":-70.85243962907298},
                { "lat":-33.40185998779928, "lng":-70.80643550110074},
                { "lat":-33.36601381988593, "lng":-70.81139540060303},
                { "lat":-33.35519823881793, "lng":-70.81395645491294},
                { "lat":-33.38021529696739, "lng":-70.84757963913805},
                { "lat":-33.37271350597742, "lng":-70.86066396238571},
                { "lat":-33.36330938244142, "lng":-70.94847149630844},
                { "lat": -33.399952792025594, "lng": -70.95204445511806 },
                { "lat": -33.403257386296886, "lng": -70.94931452939768 },
                { "lat": -33.41277163701306, "lng": -70.95067949226775 },
                { "lat": -33.41550607908189, "lng": -70.94822255911942 },
                { "lat": -33.41881008170971, "lng": -70.94760832583235 },
                { "lat": -33.420990772073985, "lng": -70.94556683106954 },
                { "lat": -33.4314670595877, "lng": -70.94760237630494 },
                { "lat": -33.43896956949746, "lng": -70.9535393832415 },
                { "lat": -33.44420678626266, "lng": -70.95489641339844 },
                { "lat": -33.448311410820274, "lng": -70.95370901201113 },
                { "lat": -33.452840426292845, "lng": -70.9491290352315 },
                { "lat": -33.45874489220858, "lng": -70.94793275104476 }, // Ruta 68 limite con el noviciado, ideal partir el noviciado desde acá hasta arriba
                { "lat": -33.45652853909435, "lng": -70.92681548907429 }, // Limite el noviciado con pudahuel por la 68
                { "lat": -33.45732324928036, "lng": -70.90555607150532 },
                { "lat": -33.45563912848216, "lng": -70.89730227402043 }, //Peaje lo prado
                { "lat": -33.44961345030173, "lng": -70.87617434648024 }, // Sala 68 espacio
                { "lat":-33.44595303442577, "lng": -70.86142807410121 },
            ]
        }
    ]
};

export default Pudahuel;