import React from 'react';
import { Redirect } from 'react-router-dom';
import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import { checkSession, verifyAccess } from './components/genscripts';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Modal from 'react-modal';
import error from '../pages/images/error.png';
import success from '../pages/images/success.png';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BtnDelPermiso from './components/Permisos/BtnDelPermiso';

import './permisos.css';

const thispage = 'permisos';
const headTitle = 'Permisos';

class Permisos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			perfilesData: [],
			paginasData: [],
			permisosData: [],
			columnDefs: [
				{
					headerName: 'Id Permiso',
					field: 'idpermiso',
					sortable: true,
					filter: true,
					width: 145,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Nombre página',
					field: 'pagetitle',
					width: 500,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Perfil',
					field: 'nombreperfil',
					width: 500,
					suppressSizeToFit: true,
				},
				{
					headerName: '',
					field: 'tools',
					width: 200,
					cellRenderer: 'btnDelPermiso',
					suppressSizeToFit: true,
				},
			],

			defaultColDef: { resizable: true },
			rowSelection: 'single',
			rowData: [],
			context: { componentParent: this },
			frameworkComponents: {
				btnDelPermiso: BtnDelPermiso,
				getData: this.getPermisosPages,
			},
			modalIsOpenAddPermiso: false,
			selectPerfilNew: 0,
			selectPaginaNew: 0,
			addPermisoAction: false,
		};

		this.handleSelectPerfil = this.handleSelectPerfil.bind(this);
		this.getPermisosPages = this.getPermisosPages.bind(this);
		this.addPermisoModal = this.addPermisoModal.bind(this);
		this.closeModalAddPermiso = this.closeModalAddPermiso.bind(this);
		this.handleSelectPerfilAddPermiso = this.handleSelectPerfilAddPermiso.bind(
			this
		);
		this.handleSelectPaginaAddPermiso = this.handleSelectPaginaAddPermiso.bind(
			this
		);
		this.addPermiso = this.addPermiso.bind(this);
	}

	componentDidMount() {
		this.getPerfiles();
		//this.getPermisosPages();
	}

	closeModalAddPermiso() {
		this.setState({ modalIsOpenAddPermiso: false });
	}

	addPermisoModal() {
		this.getAllPages();
		this.setState({ modalIsOpenAddPermiso: true });
	}

	addPermiso() {
		const cookies = new Cookies();
		const token = cookies.get('token');

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/addPermiso`,
			data: JSON.stringify({
				idpagina: this.state.selectPaginaNew,
				idperfil: this.state.selectPerfilNew,
			}),
			headers: headers,
		})
			.then((response) => {
				if (parseInt(response.data.idpermiso) !== 0) {
					this.setState({ addPermisoAction: true });
				} else {
					this.setState({ addPermisoAction: false });
				}
				document.getElementById('AddPermisoPaso1').style.display = 'none';
				document.getElementById('AddPermisoPasoFinal').style.display = 'block';
				setTimeout(() => {
					this.closeModalAddPermiso();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getPerfiles() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const perfilesData = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getPerfiles`,
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				for (const perfil of response.data) {
					if (perfil.idperfil !== 3) {
						perfilesData.push(
							<option key={perfil.idperfil} value={perfil.idperfil}>
								{perfil.nombreperfil}
							</option>
						);
					}
				}
				this.setState({ perfilesData });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getAllPages() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const paginasData = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getAllPages`,
			headers: headers,
		})
			.then((response) => {
				for (const pagina of response.data) {
					paginasData.push(
						<option value={pagina.idpagina}>{pagina.pagetitle}</option>
					);
				}
				this.setState({ paginasData });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getPermisosPages(idprofile) {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const permisosData = [];

		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getPermisosList`,
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				for (const permiso of response.data) {
					permisosData.push({
						idpermiso: permiso[0],
						idpagina: permiso[1],
						pagetitle: permiso[2],
						idperfil: permiso[3],
						nombreperfil: permiso[4],
					});
				}

				const permisosDataPerfil = permisosData.reduce(
					(
						permisosSoFar,
						{ idpermiso, idpagina, pagetitle, idperfil, nombreperfil }
					) => {
						if (!permisosSoFar[idperfil]) permisosSoFar[idperfil] = [];

						permisosSoFar[idperfil].push({
							idpermiso,
							idpagina,
							pagetitle,
							idperfil,
							nombreperfil,
						});
						return permisosSoFar;
					},
					{}
				);

				console.log(permisosDataPerfil[idprofile]);

				this.setState({ permisosData: permisosDataPerfil[idprofile] });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleSelectPerfil(event) {
		this.getPermisosPages(event.target.value);
	}

	handleSelectPerfilAddPermiso(event) {
		this.setState({ selectPerfilNew: event.target.value });
	}

	handleSelectPaginaAddPermiso(event) {
		this.setState({ selectPaginaNew: event.target.value });
	}

	render() {
		const session = checkSession();

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-header">
													<h4>Asignación de permisos:</h4>
												</div>
												<div className="card-body">
													<div className="form-group">
														<label>Perfiles</label>
														<select
															className="form-control col-md-4"
															id="selectPerfil"
															onChange={this.handleSelectPerfil}
														>
															<option value="0">Selecciona un perfil</option>
															{this.state.perfilesData}
														</select>
													</div>
													<div>
														<button
															className="btn btn-inactivo btn-round"
															onClick={this.addPermisoModal}
														>
															+ Permiso
														</button>
													</div>
													<div
														className="ag-theme-balham tableContainer"
														style={{
															height: '400px',
															width: '1350px',
															margin: '0px auto 20px auto',
														}}
													>
														<AgGridReact
															onGridReady={this.onGridReady}
															rowSelection={this.state.rowSelection}
															frameworkComponents={
																this.state.frameworkComponents
															}
															paginationAutoPageSize={true}
															pagination={true}
															columnDefs={this.state.columnDefs}
															rowData={this.state.permisosData}
														></AgGridReact>
													</div>
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i>{' '}
														Permisos de perfiles
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* MODAL */}

							<Modal
								isOpen={this.state.modalIsOpenAddPermiso}
								onRequestClose={this.closeModalAddPermiso}
								overlayClassName="Overlay"
								className="Modal"
								contentLabel="Imprimir ticket"
							>
								<div id="AddPermisoPaso1">
									<div className="headermodal">
										<button onClick={this.closeModalAddPermiso}>
											<i className="nc-icon nc-simple-remove"></i>
										</button>
									</div>
									<div className="containerAnulacionModal ModalAlignLeft">
										<h4>Nuevo permiso:</h4>
										<br />
										<div className="form-group">
											<label>Perfiles</label>
											<select
												className="form-control"
												id="selectPerfilAddPermiso"
												onChange={this.handleSelectPerfilAddPermiso}
											>
												<option value="0">Selecciona un perfil</option>
												{this.state.perfilesData}
											</select>
										</div>
										<div className="form-group">
											<label>Páginas</label>
											<select
												className="form-control"
												id="selectPerfilAddPermiso"
												onChange={this.handleSelectPaginaAddPermiso}
											>
												<option value="0">Selecciona un página</option>
												{this.state.paginasData}
											</select>
										</div>
										<br />
										<input
											type="button"
											className="btn btn-inactivo btn-round"
											onClick={this.addPermiso}
											value="Guardar"
										/>
									</div>
								</div>
								<div id="AddPermisoPasoFinal">
									{this.state.addPermisoAction ? (
										<div className="cuadraturaResponse">
											<img src={success} alt="success" />
											<p>Permiso agregado con éxito.</p>
										</div>
									) : (
										<div className="cuadraturaResponse">
											<img src={error} alt="error" />
											<p>Error al agregar el permiso.</p>
										</div>
									)}
								</div>
							</Modal>

							{/* FIN MODAL */}
							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

Modal.setAppElement('body');

export default Permisos;
