import React from 'react';
import { Redirect } from 'react-router-dom';

import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import {
	checkSession,
	verifyAccess,
	setInitDate,
	setEndDate,
	setDateFromCalIni,
	setDateFromCalEnd,
} from './components/genscripts';

import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import MediosDePago from "./components/ReportesPago/MediosDePago"

import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'universal-cookie';
import axios from 'axios';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import './reportes.css';

import ReactExport from 'react-export-excel';

import loadingpagado from './images/loadingpagado.gif';
import {convertDateToUtc} from "./components/Utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//exportar a excel https://www.npmjs.com/package/react-export-excel -> ver ejemplos

const thispage = 'reportepagos';
const headTitle = 'Reporte pagos';

class ReportePagos extends React.Component {
	constructor() {
		super();
		this.state = {
			startDate: new Date(),
			finDate: new Date(),
			iniciodate: setInitDate(),
			enddate: setEndDate(),
			comisionADM: [],
			comisionDB: [],
			comisionTC: [],
			dataTable: [],
			ticketnrochange: 0,
			newticketstate: '',
			modalIsOpen: false,
			tiporeporte: 'reportegeneral',
			listaConductores: [],
			rutconductor: '',
			cambioestadoreport: false,
			ticketstoNomina: [],
			columnDefs: [
				{
					headerName: 'Nro Ticket',
					field: 'nroticket',
					filter: true,
					width: 200,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Monto',
					field: 'valorpesos',
					filter: true,
					width: 100,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Forma de pago',
					field: 'fpago',
					filter: true,
					width: 150,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Conductor',
					field: 'nombre',
					width: 200,
					suppressSizeToFit: true,
				},
				{
					headerName: 'E-Mail conductor',
					field: 'email',
					width: 200,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Rut Conductor',
					field: 'rutconductor',
					width: 150,
					suppressSizeToFit: true,
				},
				{
					headerName: 'Fecha',
					field: 'fecha',
					width: 200,
					suppressSizeToFit: true,
				},
			],

			defaultColDef: { resizable: true },
			rowSelection: 'single',
			rowData: [],
			context: { componentParent: this },
			frameworkComponents: {
				getData: this.getGenReport,
			},
			mediosDePago : [],
		};
		this.onChangeTimeIni = this.onChangeTimeIni.bind(this);
		this.onChangeTimeEnd = this.onChangeTimeEnd.bind(this);
		this.getGenReport = this.getGenReport.bind(this);
		this.peticionCambioEstadoModal = this.peticionCambioEstadoModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.cambiarEstadoTicket = this.cambiarEstadoTicket.bind(this);
		this.handleChangeTipoReporte = this.handleChangeTipoReporte.bind(this);
		this.getConductores = this.getConductores.bind(this);
		this.handleChangeSelectConductores =
			this.handleChangeSelectConductores.bind(this);
		this.getReportByUser = this.getReportByUser.bind(this);
		this.getComisiones = this.getComisiones.bind(this);
		this.onclickExcelDownload = this.onclickExcelDownload.bind(this);
		this.setMediosDePago = this.setMediosDePago.bind(this);
	}

	componentDidMount() {
		//this.getListCounters();
		//this.getConductores();
		this.getComisiones();
	}

	peticionCambioEstadoModal(ticketnro, newticketstate) {
		this.setState({
			modalIsOpen: true,
			ticketnrochange: ticketnro,
			newticketstate: newticketstate,
		});
	}

	closeModal() {
		document.getElementById('msgErrorPetAnul').innerHTML = '';
		this.setState({ modalIsOpen: false });
	}

	cambiarEstadoTicket() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketState`,
			data: JSON.stringify({
				ticketstate: this.state.newticketstate,
				idticket: this.state.ticketnrochange,
			}),
			headers: headers,
		})
			.then((response) => {
				if (parseInt(response.data) > 0) {
					this.setState({ cambioestadoreport: true });
					if (this.state.tiporeporte === 'reporteusuario') {
						this.getReportByUser();
					} else {
						this.getGenReport();
					}
				} else {
					this.setState({ cambioestadoreport: false });
				}
				document.getElementById('CambioEstadoTicketReport').style.display =
					'none';
				document.getElementById('CambioEstadoTicketReportFinal').style.display =
					'block';
				setTimeout(() => {
					this.closeModal();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleChange = (date) => {
		var iniDate = setDateFromCalIni(date);
		this.setState({
			startDate: date,
			iniciodate: iniDate,
		});
		console.log(iniDate);
	};

	handleChangeEnd = (date) => {
		var endDate = setDateFromCalEnd(date);
		this.setState({
			finDate: date,
			enddate: endDate,
		});
		console.log(endDate);
	};

	onChangeTimeIni(eventoini) {
		var fechahoraini =
			document.getElementById('dateini').value.replace(/\//g, '-') +
			' ' +
			eventoini.target.value;
		this.setState({
			iniciodate: fechahoraini,
		});
		console.log(fechahoraini);
	}

	onChangeTimeEnd(eventoend) {
		var fechahoraini =
			document.getElementById('dateend').value.replace(/\//g, '-') +
			' ' +
			eventoend.target.value;
		console.log(eventoend.target.value);
		this.setState({
			enddate: fechahoraini,
		});
		console.log(fechahoraini);
	}

	handleChangeTipoReporte(event) {
		//pasaraPagoContainer"datefilter reportegeneral
		this.setState({
			tiporeporte: event.target.value,
		});

		if (event.target.value === 'reporteusuario') {
			this.getConductores();
			document.getElementById('vendedoresListContainer').style.display =
				'block';
			document.getElementById('datefilter').style.display = 'block';
			document.getElementById('pasaraPagoContainer').style.display = 'none';
		} else if (event.target.value === 'reportegeneral') {
			document.getElementById('vendedoresListContainer').style.display = 'none';
			document.getElementById('datefilter').style.display = 'block';
			document.getElementById('pasaraPagoContainer').style.display = 'none';
		} else if (event.target.value === 'pasaraPago') {
			document.getElementById('vendedoresListContainer').style.display = 'none';
			document.getElementById('datefilter').style.display = 'none';
			document.getElementById('pasaraPagoContainer').style.display = 'block';
		}
	}

	handleChangeSelectConductores(event) {
		this.setState({ rutconductor: event.target.value });
	}

	getComisiones() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getComisiones`,
			headers: headers,
		})
			.then((response) => {
				this.setState({ comisiones: response.data });
				const comiIndx = response.data.reduce(
					(acc, el) => ({
						...acc,
						[el.comisionnickname]: el,
					}),
					{}
				);

				this.setState({
					comisionADM: comiIndx['ADM'].comisionvalue,
					comisionDB: comiIndx['DB'].comisionvalue,
					comisionTC: comiIndx['TC'].comisionvalue,
				});
			})
			.catch((error) => {
				console.log('getComisiones: ' + error);
			});
	}

	getConductores() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const ListSellers = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getListConductores`,
			headers: headers,
		})
			.then((response) => {
				ListSellers.push(<option value="0">Selecciona un conductor</option>);
				for (const vendedor of response.data) {
					ListSellers.push(
						<option value={vendedor.rut}>
							{vendedor.nombre + ' ' + vendedor.apellido}
						</option>
					);
				}
				this.setState({ listaConductores: ListSellers });
			})
			.catch((error) => {
				console.log('getListConductores: ' + error);
			});
	}

	checkMedioDePago (medio, mediosDePago) {
		// medio = "EF" o "TC" o "DB" // mediosDePago = ["EF"; "TC"; "DB"] o [] 
		// Sí­ mediosDePago es un array vací­o (false), significa que son todos los medios de pago (true)
		// Sí­ mediosDePago es no es un array vacío (true), verificar si el medio ingresado se encuentra en la lista de medios solicitados  
		return mediosDePago.length ? !(mediosDePago.includes(medio)) : false
	}

	getGenReport() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const dataPagos = [];
		const ticketstoNominaData = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getNominaPagosGeneral`,
			data: JSON.stringify({
				fechaini: this.state.iniciodate,
				fechaend: this.state.enddate,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				for (const datoTicket of response.data) {
					if (this.checkMedioDePago(datoTicket[2], this.state.mediosDePago)) continue;
					const nombreapellido = datoTicket[3] + ' ' + datoTicket[4];

					const options = {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
					};

					const dateTicket = convertDateToUtc(new Date(datoTicket[10]));
					const newDateFormated_tz = dateTicket.toLocaleDateString(
						'es-CL',
						options
					);

					/* const newDateFormated_tz = datoTicket[10]
						.replace('T', ' ')
						.replace('.000+0000', ''); */

					dataPagos.push({
						nroticket: datoTicket[0],
						valorpesos: datoTicket[1],
						fpago: datoTicket[2],
						nombre: nombreapellido,
						email: datoTicket[5],
						rutconductor: datoTicket[6].trim(), //CORRECCION_LT
						bancocode: datoTicket[7],
						tipocuenta: datoTicket[8],
						nrocuenta: datoTicket[9],
						fecha: newDateFormated_tz,
					});
					ticketstoNominaData.push(datoTicket[0]);
				}

				const pagosRuts = [...new Set(dataPagos.map((x) => x.rutconductor))];

				const pagosGroupFpago = dataPagos.reduce(
					(
						pagosSoFar,
						{
							valorpesos,
							fpago,
							nombre,
							email,
							rutconductor,
							bancocode,
							tipocuenta,
							nrocuenta,
						}
					) => {
						if (!pagosSoFar[rutconductor]) pagosSoFar[rutconductor] = [];

						let montopago = 0;
						if (fpago === 'EF') {
							montopago = Math.trunc(valorpesos - this.state.comisionADM);
						} else if (fpago === 'DB') {
							montopago = Math.trunc(
								valorpesos -
									this.state.comisionADM -
									(this.state.comisionDB / 100) * valorpesos
							);
						} else if (fpago === 'TC') {
							montopago = Math.trunc(
								valorpesos -
									this.state.comisionADM -
									(this.state.comisionTC / 100) * valorpesos
							);
						}

						pagosSoFar[rutconductor].push({
							montopago,
							nombre,
							email,
							rutconductor,
							bancocode,
							tipocuenta,
							nrocuenta,
						});
						return pagosSoFar;
					},
					{}
				);

				const nominaPagos = [];

				for (const rutc of pagosRuts) {
					const pagosaunrut = pagosGroupFpago[rutc];
					const montos = pagosaunrut.map((x) => x.montopago);
					const sumar = (accumulator, currentValue) =>
						accumulator + currentValue;
					const suma = montos.reduce(sumar);

					nominaPagos.push({
						rut: rutc,
						nombre: pagosaunrut[0].nombre,
						email: pagosaunrut[0].email,
						banco: pagosaunrut[0].bancocode,
						tipocuenta: pagosaunrut[0].tipocuenta,
						nrocuenta: pagosaunrut[0].nrocuenta,
						monto: suma,
					});
				}
				console.log(nominaPagos);
				this.setState({
					dataTable: nominaPagos,
					ticketstoNomina: ticketstoNominaData,
					rowData: dataPagos,
				});
				document.getElementById('exportExcelNomina').style.display = 'block';
				document.getElementById('ticketsParaNominaTable').style.display =
					'block';
			})
			.catch((error) => {
				console.log('getNominaPagosGeneral: ' + error);
			});
	}

	getReportByUser() {
		console.log(
			'Reporte por usuarios fechaini: ' +
				this.state.iniciodate +
				' enddate: ' +
				this.state.enddate +
				' rutconductor: ' +
				this.state.rutconductor
		);

		const cookies = new Cookies();
		const token = cookies.get('token');
		const dataPagos = [];
		const ticketstoNominaData = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getNominaPagosByConductor`,
			data: JSON.stringify({
				fechaini: this.state.iniciodate,
				fechaend: this.state.enddate,
				rutconductor: this.state.rutconductor,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				for (const datoTicket of response.data) {
					if (this.checkMedioDePago(datoTicket[4], this.state.mediosDePago)) continue;
					const nombreapellido = datoTicket[3] + ' ' + datoTicket[4];

					const options = {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
					};

					const newDateFormated_tz = new Date(
						datoTicket[10]
					).toLocaleDateString('es-CL', options);

					/* const newDateFormated_tz = datoTicket[10]
						.replace('T', ' ')
						.replace('.000+0000', ''); */

					dataPagos.push({
						nroticket: datoTicket[0],
						valorpesos: datoTicket[1],
						fpago: datoTicket[2],
						nombre: nombreapellido,
						email: datoTicket[5],
						rutconductor: datoTicket[6],
						bancocode: datoTicket[7],
						tipocuenta: datoTicket[8],
						nrocuenta: datoTicket[9],
						fecha: newDateFormated_tz,
					});
					ticketstoNominaData.push(datoTicket[0]);
				}
				console.log(dataPagos);

				let montopago = 0;
				for (const pago of dataPagos) {
					if (pago.fpago === 'EF') {
						montopago += Math.trunc(pago.valorpesos - this.state.comisionADM);
					} else if (pago.fpago === 'DB') {
						montopago += Math.trunc(
							pago.valorpesos -
								this.state.comisionADM -
								(this.state.comisionDB / 100) * pago.valorpesos
						);
					} else if (pago.fpago === 'TC') {
						montopago += Math.trunc(
							pago.valorpesos -
								this.state.comisionADM -
								(this.state.comisionTC / 100) * pago.valorpesos
						);
					}
				}

				const nominaPagosRut = [];

				nominaPagosRut.push({
					rut: dataPagos[0].rutconductor,
					nombre: dataPagos[0].nombre,
					email: dataPagos[0].email,
					banco: dataPagos[0].bancocode,
					tipocuenta: dataPagos[0].tipocuenta,
					nrocuenta: dataPagos[0].nrocuenta,
					monto: montopago,
				});

				console.log(nominaPagosRut);

				this.setState({
					dataTable: nominaPagosRut,
					ticketstoNomina: ticketstoNominaData,
					rowData: dataPagos,
				});
				document.getElementById('exportExcelNomina').style.display = 'block';
				document.getElementById('ticketsParaNominaTable').style.display =
					'block';
			})
			.catch((error) => {
				console.log('getNominaPagosByConductor: ' + error);
			});
	}

	updateTicketsOnNomina() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketStateToEnNomina`,
			data: this.state.ticketstoNomina,
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				console.log('updateTicketStateToEnNomina: ' + error);
			});
	}

	onclickExcelDownload() {
		this.updateTicketsOnNomina();
		setTimeout(() => {
			document.getElementById('exportExcelNomina').style.display = 'none';
			document.getElementById('ticketsParaNominaTable').style.display = 'none';
			document.getElementById('timeini').value = '08:00:00';
			document.getElementById('timeend').value = '20:00:00';
			this.setState({
				startDate: new Date(),
				finDate: new Date(),
				iniciodate: setInitDate(),
				enddate: setEndDate(),
			});
		}, 2000);
	}

	updateTicketToPagado() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getTicketsEnNomina`,
			headers: headers,
		})
			.then((response) => {
				//console.log(response.data);
				//ventana con preloader indicando cuantos tickets se están pasando a pago y luego se cambia el mensaje por exito o error
				document.getElementById('pasarapagomsg').style.display = 'block';
				document.getElementById('transparent').style.display = 'block';

				if (response.data.length > 0) {
					document.getElementById('pasarapagomsg').innerHTML =
						'Pasando a pago ' +
						response.data.length +
						' tickets<br /><img src="img/loadingpagado.gif" alt="loading..." />';
					setTimeout(() => {
						axios({
							method: 'POST',
							url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketStatePagados`,
							data: response.data,
							headers: headers,
						})
							.then((responseupdate) => {
								console.log('modificados a pago: ', responseupdate.data);
								if (responseupdate.data > 0) {
									document.getElementById('pasarapagomsg').innerHTML =
										'Proceso finalizado con éxito';
								} else {
									document.getElementById('pasarapagomsg').innerHTML =
										'Ocurrió un error al actualizar los tickets';
								}
							})
							.catch((error) => {
								console.log('updateTicketStateToEnNomina: ' + error);
							});
					}, 1000);
				} else {
					document.getElementById('pasarapagomsg').innerHTML =
						'No existen tickets "EN NOMINA" ';
				}
			})
			.catch((error) => {
				console.log('updateTicketStateToEnNomina: ' + error);
			});

		setTimeout(() => {
			document.getElementById('pasarapagomsg').style.display = 'none';
			document.getElementById('transparent').style.display = 'none';
		}, 3000);
	}

	setMediosDePago (mediosDePago) {
		this.setState({mediosDePago : mediosDePago})
	}
	/*
  Usar updateTicketStatePagados para cambiar los tickets que están "en nomina" a "Pagados", considerar traer 
  todos los tickets en estado "En nomina" para paasrlos a pagodos, pero revisar con Cristian y Felipe si hacemos eso o poder seleccionarlos y con eso pasar a pagados
  Además considerar listar los tickets que se encuentran "En nomina" y mostrarlo en la tabla, cosa de poder seleccionar los pagados o exportalos a excel

  */

	render() {
		const session = checkSession();

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-body">
													<h5 className="card-title">Tipos de reporte:</h5>
													<div className="reportsTypeContainer">
														<fieldset className="form-group">
															<div className="row">
																<div className="reportsTypeContainer">
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="tipoReporteRadio"
																			id="reporteGeneral"
																			value="reportegeneral"
																			onChange={this.handleChangeTipoReporte}
																		/>
																		<label className="form-check-label">
																			Reporte general
																		</label>
																	</div>
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="tipoReporteRadio"
																			id="reporteusuario"
																			value="reporteusuario"
																			onChange={this.handleChangeTipoReporte}
																		/>
																		<label className="form-check-label">
																			Reporte por conductor
																		</label>
																	</div>

																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="tipoReporteRadio"
																			id="pasaraPago"
																			value="pasaraPago"
																			onChange={this.handleChangeTipoReporte}
																		/>
																		<label className="form-check-label">
																			Pasar a pago tickets "EN NOMINA"
																		</label>
																	</div>
																</div>
															</div>
														</fieldset>
													</div>
													<div
														id="vendedoresListContainer"
														className="form-group col-md-12"
													>
														<div className="col-md-4">
															<label>Conductores:</label>
															<select
																onChange={this.handleChangeSelectConductores}
																id="selectVendedores"
																className="form-control"
															>
																{this.state.listaConductores}
															</select>
														</div>
													</div>

													<div
														id="pasaraPagoContainer"
														className="form-group col-md-12"
													>
														<div className="col-md-12 centerContent">
															<button
																className="btn btn-inactivo btn-round"
																onClick={this.updateTicketToPagado}
															>
																Pasar a "PAGADO"
															</button>
														</div>
													</div>
													<div className='form-group'>
														<h5 className='card-title'>Filtrar Medio de Pago:</h5>
														<MediosDePago setMediosDePago={this.setMediosDePago} mediosDePago={this.state.mediosDePago}/>
													</div>
													<div id="datefilter">
														<div
															className="form-group pull-left"
															style={{ marginRight: 20, marginTop: 12 }}
														>
															<span
																className="pull-left"
																style={{ lineHeight: 3, marginRight: 10 }}
															>
																Inicio de turno:
															</span>
															<div style={{ float: 'left', marginRight: 10 }}>
																<DatePicker
																	id="dateini"
																	selected={this.state.startDate}
																	onChange={this.handleChange}
																	className="form-control"
																	dateFormat="yyyy/MM/dd"
																	locale={es}
																/>
															</div>
															<div style={{ float: 'left' }}>
																<select
																	id="timeini"
																	className="form-control"
																	style={{ width: 100, height: 45 }}
																	onChange={this.onChangeTimeIni}
																>
																	<option>00:00:00</option>
																	<option>01:00:00</option>
																	<option>02:00:00</option>
																	<option>03:00:00</option>
																	<option>04:00:00</option>
																	<option>05:00:00</option>
																	<option>06:00:00</option>
																	<option>07:00:00</option>
																	<option>08:00:00</option>
																	<option>09:00:00</option>
																	<option>10:00:00</option>
																	<option>11:00:00</option>
																	<option>12:00:00</option>
																	<option>13:00:00</option>
																	<option>14:00:00</option>
																	<option>15:00:00</option>
																	<option>16:00:00</option>
																	<option>17:00:00</option>
																	<option>18:00:00</option>
																	<option>19:00:00</option>
																	<option>20:00:00</option>
																	<option>21:00:00</option>
																	<option>22:00:00</option>
																	<option>23:00:00</option>
																	<option>23:59:59</option>
																</select>
															</div>
														</div>

														<div
															className="form-group pull-left"
															style={{ marginTop: 12 }}
														>
															<span
																className="pull-left"
																style={{ lineHeight: 3, marginRight: 10 }}
															>
																fin de turno:
															</span>
															<div style={{ float: 'left', marginRight: 10 }}>
																<DatePicker
																	id="dateend"
																	selected={this.state.finDate}
																	onChange={this.handleChangeEnd}
																	className="form-control"
																	dateFormat="yyyy/MM/dd"
																	locale={es}
																/>
															</div>
															<div style={{ float: 'left' }}>
																<select
																	id="timeend"
																	className="form-control"
																	style={{ width: 100, height: 45 }}
																	onChange={this.onChangeTimeEnd}
																>
																	<option>00:00:00</option>
																	<option>01:00:00</option>
																	<option>02:00:00</option>
																	<option>03:00:00</option>
																	<option>04:00:00</option>
																	<option>05:00:00</option>
																	<option>06:00:00</option>
																	<option>07:00:00</option>
																	<option>08:00:00</option>
																	<option>09:00:00</option>
																	<option>10:00:00</option>
																	<option>11:00:00</option>
																	<option>12:00:00</option>
																	<option>13:00:00</option>
																	<option>14:00:00</option>
																	<option>15:00:00</option>
																	<option>16:00:00</option>
																	<option>17:00:00</option>
																	<option>18:00:00</option>
																	<option>19:00:00</option>
																	<option>20:00:00</option>
																	<option>21:00:00</option>
																	<option>22:00:00</option>
																	<option>23:00:00</option>
																	<option>23:59:59</option>
																</select>
															</div>
														</div>

														<div
															className="form-group pull-left"
															style={{ marginLeft: 30 }}
														>
															{this.state.tiporeporte === 'reporteusuario' ? (
																<button
																	className="btn btn-inactivo btn-round"
																	onClick={this.getReportByUser}
																>
																	Ver
																</button>
															) : (
																<button
																	className="btn btn-inactivo btn-round"
																	onClick={this.getGenReport}
																>
																	Ver
																</button>
															)}
														</div>
													</div>
													<div
														className="exportExcelContainerNomina"
														id="exportExcelNomina"
													>
														<ExcelFile
															element={
																<button
																	onClick={this.onclickExcelDownload}
																	className="btn btn-inactivo btn-round"
																>
																	Descargar nómina de pagos
																</button>
															}
															filename="NominaPagos"
														>
															<ExcelSheet
																data={this.state.dataTable}
																name="NominaPagos"
															>
																<ExcelColumn label="RUT" value="rut" />
																<ExcelColumn
																	label="NOMBRE Y APELLIDO"
																	value="nombre"
																/>
																<ExcelColumn label="EMAIL" value="email" />
																<ExcelColumn label="BANCO" value="banco" />
																<ExcelColumn
																	label="FORMA DE PAGO"
																	value="tipocuenta"
																/>
																<ExcelColumn
																	label="N DE CUENTA"
																	value="nrocuenta"
																/>
																<ExcelColumn
																	label="MONTO DEL PAGO"
																	value="monto"
																/>
															</ExcelSheet>
														</ExcelFile>
													</div>
													<div
														className="TableTicketsParaNomina"
														id="ticketsParaNominaTable"
													>
														<h4>
															Tickets que se ingresarán a la nómina de pagos:
														</h4>

														<div className="listaTicketsParaNomina">
															<ExcelFile
																element={
																	<button className="btn btn-inactivo btn-round">
																		Exportar tickets
																	</button>
																}
																filename="TicketsEnNomina"
															>
																<ExcelSheet
																	data={this.state.rowData}
																	name="Tickets en nomina"
																>
																	<ExcelColumn
																		label="Nro Ticket"
																		value="nroticket"
																	/>
																	<ExcelColumn
																		label="Valor"
																		value="valorpesos"
																	/>
																	<ExcelColumn
																		label="Forma de pago"
																		value="fpago"
																	/>
																	<ExcelColumn label="Nombre" value="nombre" />
																	<ExcelColumn label="E-Mail" value="email" />
																	<ExcelColumn
																		label="Rut Conductor"
																		value="rutconductor"
																	/>
																	<ExcelColumn label="Fecha" value="fecha" />
																</ExcelSheet>
															</ExcelFile>
														</div>
														<div
															className="ag-theme-balham tableContainer"
															style={{
																height: '800px',
																width: '1205px',
																margin: '0px auto 20px auto',
															}}
														>
															<AgGridReact
																onGridReady={this.onGridReady}
																rowSelection={this.state.rowSelection}
																frameworkComponents={
																	this.state.frameworkComponents
																}
																paginationAutoPageSize={true}
																pagination={true}
																columnDefs={this.state.columnDefs}
																rowData={this.state.rowData}
															></AgGridReact>
														</div>
													</div>
													<div id="transparent"></div>
													<div
														id="pasarapagomsg"
														className="pasarapagocontainer"
													>
														Pasando a pago 100 tickets
														<br />
														<img src={loadingpagado} alt="loading..." />
													</div>
													{/* tabla aqui */}
												</div>

												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i>{' '}
														Generar nómina de pagos
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

export default ReportePagos;