import React from 'react';
import { Redirect } from 'react-router-dom';
import SideBar from '../pages/components/SideBar';
import HeadMenu from '../pages/components/HeadMenu';
import Footer from '../pages/components/Footer';
import {
	checkSession,
	verifyAccess,
	getDateNow,
} from './components/genscripts';

import { Line, Bar } from 'react-chartjs-2'; //https://dzone.com/articles/create-charts-in-reactjs-using-chartjs

import './dashboard.css';
import Cookies from 'universal-cookie';
import axios from 'axios';

const thispage = 'dashboard';
const headTitle = 'Dashboard';

const configLine = {
	title: {
		visible: true,
		text: 'Ventas últimos 30 días',
	},
	description: {
		visible: true,
		text: 'Cantidad / Fecha',
	},
	padding: 'auto',
	forceFit: true,
	data: [],
	xField: 'date',
	yField: 'value',
	yAxis: {
		label: {
			formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
		},
	},
	legend: {
		position: 'right-top',
	},
	seriesField: 'type',
	responsive: true,
};

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cantidadTicketsNow: 0,
			sellsCountDate: [],
			sellsSellersMonth: [],
			titleLineChart: 'Ventas por vendedor últimos 30 días',
			Data: {},
			DataBar: {},
		};
		this.getCantidadTicketsByDate = this.getCantidadTicketsByDate.bind(this);
		this.getLastSevenDaysSellsCount =
			this.getLastSevenDaysSellsCount.bind(this);
		this.getSellsBySellerLastMonth = this.getSellsBySellerLastMonth.bind(this);
		this.handleSelectLineChartData = this.handleSelectLineChartData.bind(this);
	}

	componentDidMount() {
		this.getCantidadTicketsByDate();
		setInterval(() => {
			this.getCantidadTicketsByDate();
		}, 300000); //cada 5 minutos actualiza el valor
		this.getLastSevenDaysSellsCount();
		this.getSellsBySellerLastMonth();
	}

	getCantidadTicketsByDate() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getCantidadTicketsByDate`,
			data: JSON.stringify({
				fechaini: getDateNow() + ' 00:00:00',
				fechaend: getDateNow() + ' 23:59:59',
			}),
			headers: headers,
		})
			.then((response) => {
				this.setState({ cantidadTicketsNow: response.data });
			})
			.catch((error) => {
				console.log('getCantidadTicketsByDate: ' + error);
			});
	}

	getLastSevenDaysSellsCount() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const Last7DSells = [];
		const Last7DSellsLabels = [];
		const Last7DSellsValues = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getLastSevenDaysSellsCount`,
			headers: headers,
		})
			.then((response) => {
				response.data.sort((a, b) => {
					a = a[1].split('/').reverse().join('');
					b = b[1].split('/').reverse().join('');
					return a > b ? 1 : a < b ? -1 : 0;
				});

				for (const info of response.data) {
					Last7DSells.push({
						date: info[1],
						type: 'ventas',
						value: info[0],
					});
					Last7DSellsLabels.push(info[1]);
					Last7DSellsValues.push(info[0]);
				}

				this.setState({
					sellsCountDate: Last7DSells,
					Data: {
						labels: Last7DSellsLabels,
						datasets: [
							{
								label: this.state.titleLineChart,
								fill: false,
								data: Last7DSellsValues,
								borderColor: '#FFBF00',
								backgroundColor: '#0000FF',
							},
						],
					},
				});
			})
			.catch((error) => {
				console.log('getLastSevenDaysSellsCount: ' + error);
			});
	}

	getLastTwelveMonthsSellsCount() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const Last12MSells = [];
		const Last12MSellsLabels = [];
		const Last12MSellsValues = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getLastTwelveMonthsSellsCount`,
			headers: headers,
		})
			.then((response) => {
				const sorterSells = (a, b) => {
					if (a[1] !== b[1]) {
						return a[1] - b[1];
					} else {
						return a[2] - b[2];
					}
				};

				//console.log('salida ventas fecha: ', response.data);
				const offset = response.data.length - 13; //11 para considerar el mismo mes en ambos extremos del gráfico
				const datoslast12 = response.data.slice(offset);

				for (const info of datoslast12.sort(sorterSells)) {
					Last12MSells.push({
						date: info[1] + '-' + info[2],
						type: 'ventas',
						value: info[0],
					});
					Last12MSellsLabels.push(info[1] + '-' + info[2]);
					Last12MSellsValues.push(info[0]);
				}

				this.setState({
					sellsCountDate: Last12MSells,
					Data: {
						labels: Last12MSellsLabels,
						datasets: [
							{
								label: this.state.titleLineChart,
								fill: false,
								data: Last12MSellsValues,
								borderColor: '#0000FF',
								backgroundColor: '#FFBF00',
							},
						],
					},
				});
			})
			.catch((error) => {
				console.log('getLastTwelveMonthsSellsCount: ' + error);
			});
	}

	getSellsBySellerLastMonth() {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const SellerLastMonth = [];
		const SellerLastMonthLabels = [];
		const SellerLastMonthValues = [];
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/getSellsBySellerLastMonth`,
			headers: headers,
		})
			.then((response) => {
				for (const info of response.data) {
					SellerLastMonth.push({
						type: info[1],
						value: info[0],
					});
					SellerLastMonthLabels.push(info[1]);
					SellerLastMonthValues.push(info[0]);
				}

				this.setState({
					sellsSellersMonth: SellerLastMonth,
					DataBar: {
						labels: SellerLastMonthLabels,
						datasets: [
							{
								label: this.state.titleLineChart,
								fill: false,
								data: SellerLastMonthValues,
								borderColor: '#0000FF',
								backgroundColor: '#FF4000',
							},
						],
					},
				});
			})
			.catch((error) => {
				console.log('getSellsBySellerLastMonth: ' + error);
			});
	}

	handleSelectLineChartData(event) {
		if (event.target.value === '1') {
			this.setState({ titleLineChart: 'Ventas últimos 30 días' });
			this.getLastSevenDaysSellsCount();
		} else if (event.target.value === '2') {
			this.setState({ titleLineChart: 'Ventas totales por mes (12 meses)' });
			this.getLastTwelveMonthsSellsCount();
		}
	}

	render() {
		const session = checkSession();
		configLine.data = this.state.sellsCountDate;
		configLine.title.text = this.state.titleLineChart;

		return session ? (
			[
				verifyAccess(thispage) ? (
					<div className="wrapper ">
						<div
							className="sidebar"
							data-color="white"
							data-active-color="info"
						>
							<SideBar activeitem={thispage} />
						</div>

						<div className="main-panel">
							<HeadMenu pagetitle={headTitle} />
							<div className="content">
								<div className="row-100">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<div className="card-header">
													<h4>Dashboard:</h4>
												</div>
												<div className="card-body">
													<div className="col-md-12 paddingBigChart">
														<div className="TicketsCounterDash">
															<div className="form-group">
																<label>Selecciona reporte:</label>
																<select
																	className="form-control"
																	id="selectLineChart"
																	onChange={this.handleSelectLineChartData}
																>
																	<option value="1">
																		Ventas últimos 30 días
																	</option>
																	<option value="2">
																		Ventas totales por mes (12 meses)
																	</option>
																</select>
															</div>
															<Line
																data={this.state.Data}
																options={{ maintainAspectRatio: true }}
															/>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6 paddingLados">
															<div className="TicketsCounterDash">
																<Bar
																	data={this.state.DataBar}
																	options={{ maintainAspectRatio: true }}
																></Bar>
															</div>
														</div>
														<div className="col-md-6 paddingLados">
															<div className="TicketsCounterDash">
																<h4>Cantidad de tickets en este momento</h4>
																<div className="col-md-12">
																	<p className="contadorTickets">
																		{this.state.cantidadTicketsNow}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="card-footer ">
													<hr />
													<div className="stats">
														<i className="nc-icon nc-single-copy-04"></i>{' '}
														Dashboard
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Footer />
						</div>
					</div>
				) : (
					<Redirect to="/noaccess" />
				),
			]
		) : (
			<Redirect to="/" />
		);
	}
}

export default Dashboard;
