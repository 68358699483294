import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import './BtnAnular.css';

import error from '../../images/error.png';
import success from '../../images/success.png';
import Cookies from 'universal-cookie';

class AddTransbankCode extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			nroticket: this.props.data.nroticket,
			fpago: this.props.data.fpago,
			tcodeact: this.props.data.transbankcode,
			modalIsOpen: false,
			ingresocode: false,
		};
		this.addCode = this.addCode.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.checkFormaPago = this.checkFormaPago.bind(this);
	}

	checkFormaPago() {
		let disable = false;
		if (this.state.fpago === 'EF') {
			disable = false;
		} else {
			disable = true;
		}
		return disable;
	}

	addCode() {
		const tcode = document.getElementById('newTransbankCode').value;

		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketTransbankCode`,
			data: JSON.stringify({
				idticket: this.state.nroticket,
				transbankcode: tcode,
			}),
			headers: headers,
		})
			.then((response) => {
				if (parseInt(response.data) > 0) {
					this.setState({ ingresocode: true });
				} else {
					this.setState({ ingresocode: false });
				}
				document.getElementById('ingresoTransbank').style.display = 'none';
				document.getElementById('finalStepTranbank').style.display = 'block';
				setTimeout(function () {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	render() {
		let btnstate = this.checkFormaPago();
		//console.log("tcodeact: " + this.state.tcodeact);
		return (
			<div>
				{btnstate ? (
					<button onClick={this.openModal} className="btnAnularClass">
						Boucher
					</button>
				) : (
					<button className="btnAnularClassDisable" disabled>
						Boucher
					</button>
				)}
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					overlayClassName="Overlay"
					className="Modal"
					contentLabel="Imprimir ticket"
				>
					<div id="ingresoTransbank">
						<div className="headermodal">
							<button onClick={this.closeModal}>
								<i className="nc-icon nc-simple-remove"></i>
							</button>
						</div>
						<div className="containerAnulacionModal">
							<h4 style={{ textAlign: 'left' }}>
								Agregar código de transbank al ticket nro.{' '}
								<span className="spanBlack">{this.state.nroticket}</span>
							</h4>
							<div id="msgErrorPetAnul"></div>
							<br />
							<div className="form-group" style={{ textAlign: 'left' }}>
								<label for="newTransbankCode">Código transbank:</label>
								<input
									type="text"
									className="form-control"
									id="newTransbankCode"
									placeholder=""
								/>
							</div>
							<input
								type="button"
								className="btn btn-inactivo btn-round"
								onClick={this.addCode}
								value="Guardar"
							/>
						</div>
					</div>
					<div id="finalStepTranbank">
						{this.state.ingresocode ? (
							<div className="cuadraturaResponse">
								<img src={success} alt="success" />
								<p>Código ingresado.</p>
							</div>
						) : (
							<div className="cuadraturaResponse">
								<img src={error} alt="error" />
								<p>Error al ingresar el código</p>
							</div>
						)}
					</div>
				</Modal>
			</div>
		);
	}
}

Modal.setAppElement('body');

export default AddTransbankCode;
