const Lonquen = {
    commune: "Isla de Maipo",
    polygons: [
        {
            idService: 39,
            nameService: "Lonquen / Hasta Santa Ana de Chena",
            coordinates: [
                {"lat":-33.70620949244164, "lng":-70.8564142725673},
                {"lat":-33.7062053602186, "lng":-70.85651868035431},
                {"lat":-33.70561949047111, "lng":-70.85639543526928},
                {"lat":-33.70561216707397, "lng":-70.8562809934046},
            ]
        },
    ]
};

export default Lonquen;
