import React from "react";

const NoAccess = () => {
    return(
        <div> 
            <h3>No tienes acceso a esta página</h3>
        </div>
    );
};

export default NoAccess;