const Quintero  = {

    commune: "Quintero",
    polygons: [

    {
            idService: 112,
            nameService: "Ventana / Quintero",
            coordinates: [
                //Quintero
                {"lat":-32.79852326709147, "lng":-71.52607375938862},
                {"lat":-32.79751319485299, "lng":-71.52609521706013},
                {"lat":-32.7966293722315, "lng":-71.52697498159205},
                {"lat":-32.790875291833316, "lng":-71.52532274088577},
                {"lat":-32.7909113687323, "lng":-71.52465755306895},
                {"lat":-32.784760045827504, "lng":-71.52352029646187},
                {"lat":-32.78441729099322, "lng":-71.52596647101402},
                {"lat":-32.78228857350573, "lng":-71.52558023292683},
                {"lat":-32.78219837248731, "lng":-71.52579480964194},
                {"lat":-32.78207209090787, "lng":-71.52564460594135},
                {"lat":-32.781788296276105, "lng":-71.52599023745579},
                {"lat":-32.781616666923846, "lng":-71.52574782010728},
                {"lat":-32.78158448638343, "lng":-71.52578609653075},
                {"lat":-32.781756115797776, "lng":-71.52601575507143},
                {"lat":-32.781605940078336, "lng":-71.5263347252668},
                {"lat":-32.781691754806204, "lng":-71.52637300169026},
                {"lat":-32.78101596158358, "lng":-71.52687059519506},
                {"lat":-32.78096232698097, "lng":-71.52687059519506},
                {"lat":-32.78097305390408, "lng":-71.52676852473255},
                {"lat":-32.78027580121191, "lng":-71.52660266023094},
                {"lat":-32.78023289317546, "lng":-71.5266536954622},
                {"lat":-32.78015780406187, "lng":-71.52664093665439},
                {"lat":-32.78013635001778, "lng":-71.52658990142314},
                {"lat":-32.77967508681849, "lng":-71.52647507215279},
                {"lat":-32.77963217849234, "lng":-71.52658990142314},
                {"lat":-32.77946054498081, "lng":-71.52657714261531},
                {"lat":-32.77946054498081, "lng":-71.52650058976842},
                {"lat":-32.77917091267933, "lng":-71.52643679572934},
                {"lat":-32.77896709679089, "lng":-71.52623265480429},
                {"lat":-32.778902733781784, "lng":-71.5261561019574},
                {"lat":-32.778248373879514, "lng":-71.52578609653075},
                {"lat":-32.77704691358809, "lng":-71.52558195559773},
                {"lat":-32.77658563437306, "lng":-71.52568402606025},
                {"lat":-32.77659636182383, "lng":-71.52578609652278},
                {"lat":-32.77646763232932, "lng":-71.52579885533059},
                {"lat":-32.776424722456426, "lng":-71.52565850844462},
                {"lat":-32.775941984960696, "lng":-71.52578609652278},
                {"lat":-32.776253082757975, "lng":-71.52628369002758},
                {"lat":-32.77600635011143, "lng":-71.52647507214482},
                {"lat":-32.775952712489044, "lng":-71.52655162499173},
                {"lat":-32.77601707763203, "lng":-71.52665369545424},
                {"lat":-32.77558797579946, "lng":-71.52708749491998},
                {"lat":-32.77529833089277, "lng":-71.52715128895906},
                {"lat":-32.77430065788703, "lng":-71.52702370088089},
                {"lat":-32.77415188672648, "lng":-71.52674403367283},
                {"lat":-32.77337893695533, "lng":-71.5264813802862},
                {"lat":-32.77230783831856, "lng":-71.52646824761688},
                {"lat":-32.771347151255945, "lng":-71.52666523765683},
                {"lat":-32.771159429584344, "lng":-71.52721680976875},
                {"lat":-32.7712588116951, "lng":-71.52721680976875},
                {"lat":-32.77091649501741, "lng":-71.52797850462314},
                {"lat":-32.77051896437964, "lng":-71.52771585123651},
                {"lat":-32.76953617268235, "lng":-71.52858260741236},
                {"lat":-32.76934844719122, "lng":-71.52938370024155},
                {"lat":-32.769635556605614, "lng":-71.52944936358821},
                {"lat":-32.769569300669104, "lng":-71.52985647633747},
                {"lat":-32.769171764014374, "lng":-71.52985647633747},
                {"lat":-32.768950909550114, "lng":-71.5301585277321},
                {"lat":-32.7691496785926, "lng":-71.53022419107874},
                {"lat":-32.76921593484146, "lng":-71.53051310980403},
                {"lat":-32.76921593484146, "lng":-71.53051310980403},
                {"lat":-32.76825521433072, "lng":-71.53065756920391},
                {"lat":-32.767868714678976, "lng":-71.53109094729183},
                {"lat":-32.76779141454724, "lng":-71.53082829390522},
                {"lat":-32.76708466737321, "lng":-71.53158998872642},
                {"lat":-32.76695215165339, "lng":-71.53208903016099},
                {"lat":-32.76717301107682, "lng":-71.53202336681434},
                {"lat":-32.76729448352614, "lng":-71.53262746960357},
                {"lat":-32.76676442071264, "lng":-71.53355988912608},
                {"lat":-32.765660113047204, "lng":-71.53396700187534},
                {"lat":-32.76540612034645, "lng":-71.53521460546179},
                {"lat":-32.76572637189344, "lng":-71.53509641143782},
                {"lat":-32.765847846316795, "lng":-71.53516207478447},
                {"lat":-32.76601349299058, "lng":-71.53570051422705},
                {"lat":-32.765880975676204, "lng":-71.53623895366962},
                {"lat":-32.766079751573805, "lng":-71.53629148434695},
                {"lat":-32.76812270035346, "lng":-71.53610762697632},
                {"lat":-32.768431899326885, "lng":-71.53721077120012},
                {"lat":-32.77091649494218, "lng":-71.53689558713617},
                {"lat":-32.7712367266962, "lng":-71.53894428377532},
                {"lat":-32.77447210675718, "lng":-71.53861596703459},
                {"lat":-32.77606214802621, "lng":-71.5371845060775},
                {"lat":-32.77670257328904, "lng":-71.53577931045906},
                {"lat":-32.77687924151595, "lng":-71.53451857420328},
                {"lat":-32.77780674402873, "lng":-71.53438724742976},
                {"lat":-32.777994451678936, "lng":-71.53425592073646},
                {"lat":-32.77824840845777, "lng":-71.53421652272846},
                {"lat":-32.778292574780096, "lng":-71.53430845141378},
                {"lat":-32.77933047704387, "lng":-71.53420339005913},
                {"lat":-32.779937754668985, "lng":-71.53374374663254},
                {"lat":-32.78161602769758, "lng":-71.53331036854723},
                {"lat":-32.782311618399355, "lng":-71.5333366338859},
                {"lat":-32.7833825966245, "lng":-71.53407206336844},
                {"lat":-32.783283228053456, "lng":-71.53458423747234},
                {"lat":-32.78327218709426, "lng":-71.53539846297087},
                {"lat":-32.78341323618161, "lng":-71.53618243430847},
                {"lat":-32.78353393185491, "lng":-71.53640329859974},
                {"lat":-32.78393315483848, "lng":-71.53706589147353},
                {"lat":-32.784230249881425, "lng":-71.53728675583848},
                {"lat":-32.78450877557716, "lng":-71.53765118192439},
                {"lat":-32.78467589056165, "lng":-71.53818125622342},
                {"lat":-32.784285955109496, "lng":-71.53853463908943},
                {"lat":-32.78409098674247, "lng":-71.53916410231955},
                {"lat":-32.78406313408372, "lng":-71.54093101673203},
                {"lat":-32.784276670911225, "lng":-71.54190281961358},
                {"lat":-32.79174085361655, "lng":-71.54410041935303},
                {"lat":-32.7949250096607, "lng":-71.54413354900046},
                {"lat":-32.79455368539426, "lng":-71.54284149289656},
                {"lat":-32.79486002802601, "lng":-71.54047824498004},
                {"lat":-32.79710650841148, "lng":-71.54077641177324},
                {"lat":-32.79738499379542, "lng":-71.53952852840277},
                {"lat":-32.799529301826034, "lng":-71.53957270125129},
                {"lat":-32.79974280152114, "lng":-71.53756283620078},
                {"lat":-32.802564662427656, "lng":-71.5379272622698},
                {"lat":-32.802870977463215, "lng":-71.53409526668224},
                {"lat":-32.80042971017559, "lng":-71.53373084060165},
                {"lat":-32.800513252749504, "lng":-71.53218479056281},
                {"lat":-32.79809976892607, "lng":-71.53187558052565},
                {"lat":-32.79831327205397, "lng":-71.52960067832562},
                {"lat":-32.80082885725646, "lng":-71.53002032047903},
                {"lat":-32.80060607767576, "lng":-71.53105838264797},
                {"lat":-32.802156240685385, "lng":-71.53135654944117},
                {"lat":-32.80243471028048, "lng":-71.52977736969201},
                {"lat":-32.80624037319946, "lng":-71.53105838261895},
                {"lat":-32.80716855895361, "lng":-71.52703865238342},
                {"lat":-32.80012338673394, "lng":-71.52767915884685},
                {"lat":-32.79842466477994, "lng":-71.52718221419806},
        ]
    },

]

}

export default Quintero;