const Quilicura = {
    commune: "Quilicura",
    polygons: [
        {
            idService: 12,
            nameService: "Quilicura 1 / Desde Matta hasta Valle Lo Campino",
            coordinates: [

                { "lat": -33.366132495852   , "lng": -70.70031733546664 }, // inicio
                { "lat": -33.3655580433653  , "lng": -70.70217503967153 }, // mata 1
                { "lat": -33.3652981886364  , "lng": -70.70450856148948 }, // mata con ferrocarril
                { "lat": -33.36585373916816 , "lng": -70.70724977909784 },
                { "lat": -33.365858219406284, "lng": -70.70847286635988 }, // mata/zañartu
                { "lat": -33.36633312268289 , "lng": -70.7117719835234  }, // mata / el litre
                { "lat": -33.367439726858066, "lng": -70.7164926713255  }, // mata inspeccion
                { "lat": -33.36744420700611 , "lng": -70.71709348620146 }, // mata / psj 4
                { "lat": -33.36642272678372 , "lng": -70.72215213236325 }, // mata / altamirano
                { "lat": -33.36640411214658 , "lng": -70.72357219696087 }, // mata / luis torterolo
                { "lat": -33.36555963746843 , "lng": -70.72762949796399 }, // matta / del sauce
                { "lat": -33.36559160511278 , "lng": -70.72793251964633 }, // mata / del estero
                { "lat": -33.36580738640922 , "lng": -70.72906167417219 }, // mata / del partidor
                { "lat": -33.365815378298656, "lng": -70.72927857390272 }, // mata / del arado
                { "lat": -33.3657567710926  , "lng": -70.73023548453472 }, // mata / ohiggins 
                { "lat": -33.365660868306335, "lng": -70.73079049266869 }, // mata / reimundo romo
                { "lat": -33.36415119475414 , "lng": -70.73397343407882 }, // mata / los paltos
                { "lat": -33.36389581616198 , "lng": -70.73477273233392 }, // mata / las higueras
                { "lat": -33.36385549314869 , "lng": -70.73557739508828 }, // mata / las lucumas
                { "lat": -33.3632013617917  , "lng": -70.73672001610655 }, // mata / san martin
                {"lat": -33.3631356765093, "lng":-70.73694283872257},
                {"lat":-33.361869777670385, "lng":-70.73624815713575},
                {"lat": -33.338647879467 , "lng": -70.74301403100321 },
                {"lat": -33.34777200506744 , "lng": -70.76097410195428 },
                { "lat": -33.367250937536596, "lng": -70.77912669574184 },
                { "lat": -33.37138184826826 , "lng":-70.77399826731616  },
                { "lat": -33.37401741891745 , "lng": -70.77182854752627 },
                { "lat": -33.37400970658224 , "lng": -70.77182253643501 },
                { "lat": -33.3777616152832  , "lng": -70.76453207113977 },
                { "lat": -33.37815079910204 , "lng": -70.76391018580294 },
                { "lat": -33.38362390130402 , "lng": -70.75680548700511 },
                { "lat": -33.38369170082168 , "lng": -70.75683706344311 },
                { "lat": -33.38310410324557 , "lng": -70.7561378708874  },
                { "lat": -33.38317943649007 , "lng": -70.75612884904797 },
                { "lat": -33.383228403063995, "lng": -70.75615591456626 },
                { "lat": -33.385680463198874, "lng": -70.75187505158699 },
                { "lat": -33.389303811019246, "lng": -70.75097286757284 },
                { "lat": -33.389735010769904, "lng": -70.75158452543913 },
                { "lat": -33.3900239059076  , "lng": -70.75177764448064 },
                { "lat": -33.39062408810329 , "lng": -70.74992960249368 },
                { "lat": -33.390348631867376, "lng": -70.74839001452717 },
                { "lat": -33.38940356399758 , "lng": -70.7454529956697  },
                { "lat": -33.389289348937005, "lng": -70.741153414627   },
                { "lat": -33.390191867765   , "lng": -70.73914980449763 },
                { "lat": -33.39331589904001 , "lng": -70.73664730346007 },
                { "lat": -33.39246923124037 , "lng": -70.73355081208817 },
                { "lat": -33.39194968180519 , "lng": -70.73076399689927 },
                { "lat": -33.39217138646406 , "lng": -70.73006125816492 },
                { "lat": -33.39240876659262 , "lng": -70.72847607264373 },
                { "lat": -33.39181979396175 , "lng": -70.7268318785112  },
                { "lat": -33.391730216023724, "lng": -70.72337182888825 },
                { "lat": -33.39276235594589 , "lng": -70.72237285941058 },
                { "lat": -33.39216218852039 , "lng": -70.7213938531307  },
                { "lat": -33.39282953860533 , "lng": -70.71773532004555 },
                { "lat": -33.391567709577664, "lng": -70.71576233431375 },
                { "lat": -33.39031770520607 , "lng": -70.71290896972592 },
                { "lat": -33.39057748518761 , "lng": -70.71101801238677 },
                { "lat": -33.38815725857741 , "lng": -70.70823890510569 },
                { "lat": -33.38737565504621 , "lng": -70.70795727315985 },
                { "lat": -33.38653357575716 , "lng": -70.70736986938321 },
                { "lat": -33.386110293454145, "lng": -70.70640159192449 },
                { "lat": -33.38335999188966 , "lng": -70.70396263260422 },
                { "lat": -33.38324128954661 , "lng": -70.70259738819847 },
                { "lat": -33.38333087622827 , "lng": -70.70192147155322 },
                { "lat": -33.383028520803464, "lng": -70.70036310808405 },
                { "lat": -33.383017322434355, "lng": -70.69990176815159 },
                { "lat": -33.382477219580956, "lng": -70.69567574605618 },
                { "lat": -33.38279362104499 , "lng": -70.69555846203427 },
                { "lat": -33.38257515348676 , "lng": -70.69452095049998 },
                { "lat": -33.381083532731815, "lng": -70.69509834822341 },
                { "lat": -33.38069932324168 , "lng": -70.69382626886397 },

            ]

        },

        {
            idService: 134,
            nameService: "Quilicura 2 / Desde Matta Hasta BuenaVentura",
            coordinates: [

                { "lat": -33.36590409761962 , "lng": -70.69933021738693 },
                { "lat": -33.36587395834004 , "lng": -70.69220296399493 },
                { "lat": -33.36607739824755 , "lng": -70.68924380066237 },
                { "lat": -33.365217409955065, "lng": -70.689286715912   },
                { "lat": -33.36382851679812 , "lng": -70.6894744705401  },
                { "lat": -33.36321470854236 , "lng": -70.68964613191474 },
                { "lat": -33.361471827991416, "lng": -70.69022548904262 },
                { "lat": -33.35622505392504 , "lng": -70.69200647580665 },
                { "lat": -33.3551810380905  , "lng": -70.69224787461145 },
                { "lat": -33.35363963560189 , "lng": -70.69253218875801 },
                { "lat": -33.352774825156665, "lng": -70.69278431640922 },
                { "lat": -33.35075839437579 , "lng": -70.69153977144056 },
                { "lat": -33.34836102147234 , "lng": -70.68987143743583 },
                { "lat": -33.34773814180985 , "lng": -70.68846059550803 },
                { "lat": -33.34641618820387 , "lng": -70.68797779788581 },
                { "lat": -33.34536757343892 , "lng": -70.68796170463443 },
                { "lat": -33.34305071744429 , "lng": -70.68813336600843 },
                { "lat": -33.33956310144832 , "lng": -70.6843797683432  },
                { "lat": -33.33546145473594 , "lng": -70.6808376082381  },
                { "lat": -33.33024144388212 , "lng": -70.67938577452551 },
                { "lat": -33.32601644131629 , "lng": -70.68172657227508 },
                { "lat": -33.32001577165715 , "lng": -70.6949525892128  },
                { "lat": -33.32252154831407 , "lng": -70.70815159786159 },
                { "lat": -33.338647879467 , "lng": -70.74301403100321 },
                { "lat":-33.361869777670385, "lng":-70.73624815713575},
             	{ "lat": -33.3631356765093, "lng":-70.73694283872257},
                { "lat": -33.3632013617917  , "lng": -70.73672001610658 }, // mata / san martin
                { "lat": -33.36385549314869 , "lng": -70.73557739508830 }, // mata / las lucumas
                { "lat": -33.36389581616198 , "lng": -70.73477273233395 }, // mata / las higueras
                { "lat": -33.36415119475414 , "lng": -70.73397343407885 }, // mata / los paltos
                { "lat": -33.365660868306335, "lng": -70.73079049266872 }, // mata / reimundo romo
                { "lat": -33.3657567710926  , "lng": -70.73023548453475 }, // mata / ohiggins 
                { "lat": -33.365815378298656, "lng": -70.72927857390275 }, // mata / del arado
                { "lat": -33.36580738640922 , "lng": -70.72906167417222 }, // mata / del partidor
                { "lat": -33.36559160511278 , "lng": -70.72793251964636 }, // mata / del estero
                { "lat": -33.36555963746843 , "lng": -70.72762949796405 }, // matta / del sauce
                { "lat": -33.36640411214658 , "lng": -70.72357219696089 }, // mata / luis torterolo
                { "lat": -33.36642272678372 , "lng": -70.72215213236328 }, // mata / altamirano
                { "lat": -33.36744420700611 , "lng": -70.71709348620148 }, // mata / psj 4
                { "lat": -33.367439726858066, "lng": -70.7164926713258  }, // mata inspeccion
                { "lat": -33.36633312268289 , "lng": -70.7117719835238  }, // mata / el litre
                { "lat": -33.365858219406284, "lng": -70.70847286635991 }, // mata/zañartu
                { "lat": -33.36585373916816 , "lng": -70.70724977909787 },
                { "lat": -33.3652981886364  , "lng": -70.70450856148950 }, // mata con ferrocarril
                { "lat": -33.3655580433653  , "lng": -70.70217503967155 }, // mata 1
                { "lat": -33.366132495852   , "lng": -70.70031733546667 }, //inicio
            ]

        }
    ]
};

export default Quilicura;