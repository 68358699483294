const Llaillay = {
    commune: "Llaillay",
    polygons: [
        {
            idService: 37,
            nameService: "Llaillay",
            coordinates: [

                {"lat":-32.92445043831342, "lng":-70.72891988998894},
                {"lat":-32.91999513663694, "lng":-70.72891988998894},
                {"lat":-32.915539610653695, "lng":-70.73055301738643},
                {"lat":-32.90851313320128, "lng":-70.73545239957888},
                {"lat":-32.90028630463263, "lng":-70.73524825865418},
                {"lat":-32.89034453377119, "lng":-70.73831037252447},
                {"lat":-32.8838303547989, "lng":-70.74320975471691},
                {"lat":-32.88005163203193, "lng":-70.74880963781749},
                {"lat":-32.855744282952486, "lng":-70.75699919951755},
                {"lat":-32.84943858162589, "lng":-70.76219577907541},
                {"lat":-32.83803867667852, "lng":-70.76450536999002},
                {"lat":-32.82857806959868, "lng":-70.76623756317596},
                {"lat":-32.82736509835756, "lng":-70.77143414273382},
                {"lat":-32.83173171729165, "lng":-70.78558038708576},
                {"lat":-32.83173171729165, "lng":-70.79943793257337},
                {"lat":-32.834400100939106, "lng":-70.81791465989019},
                {"lat":-32.83124654801316, "lng":-70.8418766656292},
                {"lat":-32.833187209222025, "lng":-70.86872566001145},
                {"lat":-32.83318720897202, "lng":-70.88893458384128},
                {"lat":-32.835855548879245, "lng":-70.90250343046456},
                {"lat":-32.83409691313622, "lng":-70.9104885017499},
                {"lat":-32.831037121976266, "lng":-70.92044190119394},
                {"lat":-32.830017168159316, "lng":-70.9330657248791},
                {"lat":-32.82512122677073, "lng":-70.94787444112514},
                {"lat":-32.82410120501036, "lng":-70.957342308889},
                {"lat":-32.81573658475001, "lng":-70.96486804993206},
                {"lat":-32.80814562534991, "lng":-70.96648193513998},
                {"lat":-32.80171120537907, "lng":-70.96597158282827},
                {"lat":-32.800681654961245, "lng":-70.96903369669855},
                {"lat":-32.803083920722415, "lng":-70.97495378351442},
                {"lat":-32.80471399266121, "lng":-70.98607946390977},
                {"lat":-32.8094847233294, "lng":-70.99030476552439},
                {"lat":-32.81312431981332, "lng":-70.99232565757467},
                {"lat":-32.817976883243695, "lng":-70.99708918883601},
                {"lat":-32.82319309334392, "lng":-70.9991100808863},
                {"lat":-32.827074725331634, "lng":-71.00156402123307},
                {"lat":-32.83496910014164, "lng":-71.00263646294867},
                {"lat":-32.838502812709876, "lng":-71.00538304489707},
                {"lat":-32.83778165830286, "lng":-71.00658467449949},
                {"lat":-32.84059412758171, "lng":-71.01182034709473},
                {"lat":-32.84196427253605, "lng":-71.00821545828747},
                {"lat":-32.8429017279507, "lng":-71.01190617778062},
                {"lat":-32.84282961634724, "lng":-71.0167985268762},
                {"lat":-32.84254116934754, "lng":-71.02289250557418},
                {"lat":-32.84124314624903, "lng":-71.0288148229004},
                {"lat":-32.840666240950746, "lng":-71.03791287693271},
                {"lat":-32.84355072969487, "lng":-71.03851369173393},
                {"lat":-32.84686777586587, "lng":-71.03928616790691},
                {"lat":-32.84924731957369, "lng":-71.0373978928174},
                {"lat":-32.851987321166256, "lng":-71.0352521256702},
                {"lat":-32.85479934008422, "lng":-71.0339646653819},
                {"lat":-32.85840436252415, "lng":-71.03370717330611},
                {"lat":-32.86056730545212, "lng":-71.03362134262024},
                {"lat":-32.86489303307978, "lng":-71.03267720507547},
                {"lat":-32.86756045994857, "lng":-71.0331921891908},
                {"lat":-32.87239046116408, "lng":-71.03396466536378},
                {"lat":-32.87592268389484, "lng":-71.03422215760526},
                {"lat":-32.88147303469691, "lng":-71.03688290886775},
                {"lat":-32.886086048785934, "lng":-71.03490880309235},
                {"lat":-32.89163576342165, "lng":-71.03199055876499},
                {"lat":-32.90302240039804, "lng":-71.02838566995774},
                {"lat":-32.9110209981423, "lng":-71.02924397676219},
                {"lat":-32.917649918548285, "lng":-71.03027394499284},
                {"lat":-32.92334174749654, "lng":-71.02975896087752},
                {"lat":-32.92651172134294, "lng":-71.02632573344204},
                {"lat":-32.9298977043186, "lng":-71.02229169120533},
                {"lat":-32.932058902288716, "lng":-71.02014592405816},
                {"lat":-32.93666928190911, "lng":-71.01748517255739},
                {"lat":-32.939622679788584, "lng":-71.01439526786544},
                {"lat":-32.94308019100002, "lng":-71.00924542671221},
                {"lat":-32.94531309513961, "lng":-71.00435307761664},
                {"lat":-32.951147191389865, "lng":-71.00306561732833},
                {"lat":-32.95690888382476, "lng":-71.0127644848336},
                {"lat":-32.95899740489498, "lng":-71.01207783953551},
                {"lat":-32.96317429830791, "lng":-71.0107045485613},
                {"lat":-32.967711045016294, "lng":-71.01053288718954},
                {"lat":-32.97260758953314, "lng":-71.01087620993309},
                {"lat":-32.97555978666903, "lng":-71.01096204061896},
                {"lat":-32.97498375618916, "lng":-71.00177815645226},
                {"lat":-32.975271772016924, "lng":-70.99825909833088},
                {"lat":-32.97689864766535, "lng":-70.99294686444108},
                {"lat":-32.97705001417338, "lng":-70.99249577246557},
                {"lat":-32.97844257387322, "lng":-70.98791267799442},
                {"lat":-32.978972346296906, "lng":-70.98684810068883},
                {"lat":-32.980031881521484, "lng":-70.98650527078745},
                {"lat":-32.97997133699402, "lng":-70.98632483399724},
                {"lat":-32.97854852864715, "lng":-70.98551286844133},
                {"lat":-32.97685323770599, "lng":-70.98341980167497},
                {"lat":-32.975672569431616, "lng":-70.98083955557506},
                {"lat":-32.97570284313293, "lng":-70.97943214838554},
                {"lat":-32.97611153764339, "lng":-70.97827735292825},
                {"lat":-32.9761418112356, "lng":-70.97616624248286},
                {"lat":-32.976247768726566, "lng":-70.973622083741},
                {"lat":-32.97480976330021, "lng":-70.96824506710492},
                {"lat":-32.97217588222719, "lng":-70.96200195416388},
                {"lat":-32.97008688612372, "lng":-70.95895257218353},
                {"lat":-32.96972357742566, "lng":-70.95770755833112},
                {"lat":-32.96557569713352, "lng":-70.95361164303674},
                {"lat":-32.96557569713352, "lng":-70.95328685681437},
                {"lat":-32.96824005138121, "lng":-70.9496239898251},
                {"lat":-32.97173689435088, "lng":-70.94700765632143},
                {"lat":-32.97225156950036, "lng":-70.94626786548162},
                {"lat":-32.97240294397354, "lng":-70.94477024012292},
                {"lat":-32.97229698186956, "lng":-70.94392218720897},
                {"lat":-32.972875301305784, "lng":-70.94007682430723},
                {"lat":-32.97272392764217, "lng":-70.93888594149189},
                {"lat":-32.972148705354336, "lng":-70.93789353914576},
                {"lat":-32.971467516221054, "lng":-70.93728005405907},
                {"lat":-32.97089228575047, "lng":-70.9362515643549},
                {"lat":-32.9698326408286, "lng":-70.93370740541499},
                {"lat":-32.96900005378742, "lng":-70.93249847892062},
                {"lat":-32.96834911663451, "lng":-70.9299904075368},
                {"lat":-32.96744082416454, "lng":-70.92876343719298},
                {"lat":-32.96350478244528, "lng":-70.92585840487071},
                {"lat":-32.96265699666078, "lng":-70.92553361864834},
                {"lat":-32.96138530273115, "lng":-70.92544340025324},
                {"lat":-32.95644974567197, "lng":-70.92326011499375},
                {"lat":-32.95470860911306, "lng":-70.92172640222363},
                {"lat":-32.95320969024083, "lng":-70.92132944128518},
                {"lat":-32.95219525597468, "lng":-70.92044530101319},
                {"lat":-32.950423753189, "lng":-70.9197055100682},
                {"lat":-32.949469852424706, "lng":-70.91894767554935},
                {"lat":-32.94803141117313, "lng":-70.91739591915359},
                {"lat":-32.94657780465806, "lng":-70.91618699265923},
                {"lat":-32.94459419879337, "lng":-70.91597046845436},
                {"lat":-32.94445791889965, "lng":-70.91559155119492},
                {"lat":-32.944942468678526, "lng":-70.9144187120586},
                {"lat":-32.94483647362708, "lng":-70.90988974839141},
                {"lat":-32.94424592752212, "lng":-70.90878908397116},
                {"lat":-32.943049681017115, "lng":-70.90808538048937},
                {"lat":-32.939551702721495, "lng":-70.90770646323104},
                {"lat":-32.93617473535865, "lng":-70.90705689078631},
                {"lat":-32.935750712407575, "lng":-70.90647949305766},
                {"lat":-32.935432693777, "lng":-70.90453077543982},
                {"lat":-32.93494809188696, "lng":-70.89942441427706},
                {"lat":-32.933918304054735, "lng":-70.89707873600443},
                {"lat":-32.933267108502356, "lng":-70.89655546931283},
                {"lat":-32.932782494747315, "lng":-70.89653742563381},
                {"lat":-32.93246446552766, "lng":-70.89606828997927},
                {"lat":-32.93248255120165, "lng":-70.89533862702514},
                {"lat":-32.934208981439305, "lng":-70.89497775344473},
                {"lat":-32.93470873127173, "lng":-70.89441839939511},
                {"lat":-32.93605652737357, "lng":-70.89144119226641},
                {"lat":-32.93814632786848, "lng":-70.88822941740078},
                {"lat":-32.938434050156104, "lng":-70.88694831619034},
                {"lat":-32.93830034694781, "lng":-70.88537858933226},
                {"lat":-32.93677086278993, "lng":-70.88324943520786},
                {"lat":-32.93592282054985, "lng":-70.88016396587985},
                {"lat":-32.93486275634119, "lng":-70.87807089911348},
                {"lat":-32.934287287592966, "lng":-70.87776415657014},
                {"lat":-32.930864685520746, "lng":-70.8715390871334},
                {"lat":-32.93081925185474, "lng":-70.87034820418033},
                {"lat":-32.93200051944834, "lng":-70.8681107879818},
                {"lat":-32.932136818540215, "lng":-70.86691990516647},
                {"lat":-32.93200051944834, "lng":-70.8658733717833},
                {"lat":-32.931743065035114, "lng":-70.86520575565955},
                {"lat":-32.93187936452073, "lng":-70.86392465433259},
                {"lat":-32.931788498218346, "lng":-70.86358182443121},
                {"lat":-32.929956007865506, "lng":-70.86213833010957},
                {"lat":-32.92983485012381, "lng":-70.8569237066174},
                {"lat":-32.927154192956515, "lng":-70.84864165769835},
                {"lat":-32.92339810731919, "lng":-70.84324659745567},
                {"lat":-32.922292454981154, "lng":-70.84171288473895},
                {"lat":-32.92235303902865, "lng":-70.84093700654107},
                {"lat":-32.922655958644015, "lng":-70.84044982720752},
                {"lat":-32.92597286058652, "lng":-70.83790566846565},
                {"lat":-32.927835723647185, "lng":-70.83285343800351},
                {"lat":-32.92766912774009, "lng":-70.83200538508956},
                {"lat":-32.9277448531913, "lng":-70.83112124481757},
                {"lat":-32.92762369243826, "lng":-70.83016492982948},
                {"lat":-32.92810833446534, "lng":-70.82287528320798},
                {"lat":-32.929516810256324, "lng":-70.8191582853298},
                {"lat":-32.930152888747166, "lng":-70.81737196110677},
                {"lat":-32.93045578165913, "lng":-70.81690282545226},
                {"lat":-32.93062237231866, "lng":-70.81614499093341},
                {"lat":-32.930258901391646, "lng":-70.81264451694376},
                {"lat":-32.92950166548193, "lng":-70.81242799279553},
                {"lat":-32.92882014762131, "lng":-70.81248212383258},
                {"lat":-32.92792659402755, "lng":-70.81277082269692},
                {"lat":-32.92692701490607, "lng":-70.81325800203045},


            ]
        },
    ]
};

export default Llaillay;