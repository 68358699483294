import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

import Select from 'react-select';

import './SelectDestType.css';

const SelectTarifaNew = (props) => {
	const setVentaData = props.setVentaData;
	const ventaData = props.ventaData;
	const setPayType = props.setPayType;
	const setMoneyType = props.setMoneyType;
	const setDiscToPay = props.setDiscToPay;
	const setAddToPay = props.setAddToPay;
	const setAllServices = props.setAllServices;
	const selecttarifa = props.selecttarifa;
	const setSelectTarifa = props.setSelectTarifa;
	const selectedOptionService = props.selectedOptionService;
	const setSelectedOptionService = props.setSelectedOptionService;
	const refDirection = props.refDirection;
	const setCoordinatesPolygon = props.setCoordinatesPolygon;

	const [tarifasIndexed, setTarifasIndexed] = useState([]);

	const handleChangeInput = (event) => {
		setVentaData({
			idservice: Number(event.target.value),
			valor: 0,
			valororig: 0,
			valorpesos: 0,
		});
		const selectedValue = Number(event.target.value);
		// Ordenar selecttarifa por el valor de la propiedad "value"
		const sortedOptions = selecttarifa.sort((a, b) => a.comuna.charAt(0).localeCompare(b.comuna.charAt(0)));
		// Encontrar la opción seleccionada
		const selectedOption = sortedOptions.find(option => option.value === selectedValue);
		// Asignar la opción seleccionada
		setSelectedOptionService(selectedOption);

		//setSelectedOptionService(selecttarifa.find( option => option.value === Number(event.target.value)));
		
		refDirection.current.value = '';
		setCoordinatesPolygon([]);
		try {
			setVentaData({
				idservice: tarifasIndexed[event.target.value].idservicio,
				servicename: tarifasIndexed[event.target.value].comuna,
				valor: tarifasIndexed[event.target.value].valor,
				valororig: tarifasIndexed[event.target.value].valor,
				valorpesos: tarifasIndexed[event.target.value].valor,
			});
			document.getElementById('step-pago').style.display = 'block';
			document.getElementById('step-previewventa').style.display = 'block';
			setPayType('EF');
			setMoneyType('CL');
			setDiscToPay(0);
			setAddToPay(0);
		} catch (error) {
			document.getElementById('step-pago').style.display = 'none';
			document.getElementById('step-previewventa').style.display = 'none';
		}
	};

	const handleChange = (event) => {
		setSelectedOptionService(event);
		refDirection.current.value = '';
		setCoordinatesPolygon([]);
		const idservice = event.value;
		setVentaData({
			idservice: tarifasIndexed[idservice].idservicio,
			servicename: tarifasIndexed[idservice].comuna,
			valor: tarifasIndexed[idservice].valor,
			valororig: tarifasIndexed[idservice].valor,
			valorpesos: tarifasIndexed[idservice].valor,
		});
		document.getElementById('step-pago').style.display = 'block';
		document.getElementById('step-previewventa').style.display = 'block';
		setPayType('EF');
		setMoneyType('CL');
		setDiscToPay(0);
		setAddToPay(0);
	};

	useEffect(() => {
		const cookies = new Cookies();
		const headers = { Authorization: 'Bearer ' + cookies.get('token') };
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/allservices`,
			headers: headers,
		})
			.then((response) => {
				setSelectTarifa(response.data.filter((x) => x.idservicio !== 1)
					.map((dato, index) => {
						return {
							label: dato.comuna,
							value: dato.idservicio,
							key: index,
						};
					}));
				setAllServices(response.data);
				setTarifasIndexed(
					response.data.reduce(
						(acc, el) => ({
							...acc,
							[el.idservicio]: el,
						}),
						{}
					)
				);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [setSelectTarifa, setAllServices]);

	const customStyles = {};

	return (
		<div className="row">
			<div className="col-md-12">
				<h5 className="card-title align-rigth padding-20 margintop10">
					Desde tarifario:
				</h5>
			</div>
			<div className="col-md-3">
				<div className="form-group">
					<label className="labelNotSelectSearch">Código:</label>
					<input
						type="text"
						id="nc-codetarifa"
						className="form-control"
						placeholder="código"
						value={ventaData.idservice}
						onChange={(e) => handleChangeInput(e)}
					/>
				</div>
			</div>
			<div className="col-md-9">
				<div id="content-nc-nametarifa">
					<label>Tarifa:</label>
					{/* <select
						id="nc-nametarifa"
						data-live-search="true"
						className="selectpicker select_ta form-control blackSelect"
						value={ventaData.idservice}
						onChange={(e) => handleChange(e)}
					>
						<option key={'seltarif'} value="0">
							Selecciona tarifa
						</option>
						{selecttarifa
							.filter((x) => x.idservicio !== 1)
							.map((dato, index) => (
								<option
									key={index}
									data-tokens={dato.comuna}
									value={dato.idservicio}
								>
									{dato.comuna}
								</option>
							))}
					</select> */
					}
					<Select
						value={selectedOptionService}
						onChange={handleChange}
						style={customStyles}
						height="100"
						options={selecttarifa.filter(option => !option.label.includes('(NO USAR)'))}
					/>
				</div>
			</div>
		</div>
	);
};

export default SelectTarifaNew;
