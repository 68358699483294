const Lampa = {

    commune: "Lampa",
    polygons: [

        {

            idService: 51,
            nameService: "Lampa / Pueblo",
            coordinates: [

                // Lampa Pueblo

                { "lat": -33.3793271032392, "lng": -70.84711637086339 }, //Lampa Camino el noviciado limite Puahiel al norte
                { "lat": -33.37243901694649, "lng": -70.85936856439231 }, //Lampa limite puedahuel desde camino el noviciado a la costa
                { "lat": -33.36226010535, "lng": -70.94794583493984 }, //Lampa hacia la costa, ya limite con pudahuel
                { "lat": -33.333007027675855, "lng": -70.95240903058016 }, // Lampa La palma (usar como limite  surlampa chicauma)
                { "lat": -33.26571637270223, "lng": -70.89696240623462 }, // lampa el molino (Usar como limite sur de lampa chicauma)
                { "lat": -33.25710370869936, "lng": -70.87694891718654 }, // lampa comunidad terapeutica (Usar como limite sur de lampa chicauma)
                { "lat": -33.2725102308729, "lng": -70.85035516519845 }, // Lampa zoo , limite lampa valle grande (norponiente, partir por acá)
                { "lat": -33.29736029051869, "lng": -70.83318888711749 }, // Ranchi esmeralda , limite lampa valle grande (poniente)
                { "lat": -33.34828909924603, "lng": -70.804662591357 }, // Lampa Cerrillo , limite lampa valle grande (Limte quilicura al sur), cerrar acá lampra valle grande



            ]
        },
        {

            idService: 128,
            nameService: "Lampa / Valle Grande",
            coordinates: [

                { "lat": -33.31460015502053,  "lng": -70.74965201510797 },                
                { "lat": -33.3048427285678,   "lng": -70.72765077007783 }, //PanemericanaNorte\r\n                        
                { "lat": -33.32486693818219,  "lng": -70.7186650175498  },           
                { "lat": -33.33244092266367,  "lng": -70.75157637006849 }, // Lampa Valle Grande\r\n                
                { "lat": -33.34828909924603,  "lng": -70.804662591357   }, // Lampa Cerrillo , limite lampa valle grande (Limte quilicura al sur), \r\n                
                { "lat": -33.29736029051869,  "lng": -70.83318888711749 },
            ]
        },

        {

            idService: 140,
            nameService: "Lampa / Chicauma",
            coordinates: [


                {"lat":-33.333007027675855, "lng":-70.95240903058016}, // Lampa La palma (usar como limite  surlampa chicauma)
                {"lat":-33.23744354271398, "lng":-70.94727034020855}, // Oasis de chicauma
                {"lat":-33.179993223025654, "lng":-70.97937101711972}, // Laguna del inca chicauma
                {"lat":-33.18904414943192, "lng":-70.89645857280581}, // camino chicauma, referencia polpaico
                {"lat":-33.195364876975056, "lng":-70.89319700667514}, // idem
                {"lat":-33.2136062363754, "lng":-70.90212339819067}, // altos de chicauma
                { "lat": -33.26571637270223, "lng": -70.89696240623462 }, // lampa el molino (Usar como limite sur de lampa chicauma)
                { "lat": -33.25710370869936, "lng": -70.87694891718654 }, // lampa comunidad terapeutica (Usar como limite sur de lampa chicauma)
                    

            ]
        },
        {
            idService: 155,
            nameService: "Lampa / Condominio Chicauma",
            coordinates: [
                { "lat":-33.29297488286981, "lng":-70.73323226730838 },
                { "lat":-33.30419535867575, "lng":-70.76073083460837 },
                { "lat":-33.31460015502053, "lng":-70.74965201510797 },
                { "lat":-33.3048427285678,  "lng":-70.72765077007783 },
            ]
        },
        {
            idService: 158,
            nameService: "Lampa / Batuco",
            coordinates: [
                { "lat":-33.24000641885501,   "lng":-70.75685958463514  },                
                //{ "lat": -33.240196,          "lng": -70.756915         },                
                { "lat": -33.179914,          "lng": -70.751223         },                
                { "lat": -33.212371,          "lng": -70.900041         },                
                { "lat": -33.265424,          "lng": -70.896411         },                
                { "lat": -33.256790,          "lng": -70.877060         },                
                { "lat": -33.27211287017069,  "lng": -70.8498107613058  },                
                { "lat": -33.297209079980504, "lng": -70.83287792922987 },                
                { "lat": -33.297209079980504, "lng": -70.83287792922987 },                
                { "lat": -33.310285,          "lng": -70.770470         },                
                { "lat": -33.30419535867575,  "lng": -70.76073083460837 },                
                //{ "lat": -33.29297488286981,  "lng": -70.73323226730838 },                
                { "lat":-33.29288607112307,   "lng":-70.7331762413264   },
            ]
        }

    ]

}

export default Lampa;