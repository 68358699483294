const Quillota = {
    commune: "Quillota",
        polygons: [
        {
            idService: 89,
            nameService: "Quillota / Limache / Olmué",
            coordinates: [
                {"lat":-32.839747866549644, "lng":-71.22999687299985},
                {"lat":-32.84017665873433, "lng":-71.22652647713828},
                {"lat":-32.83846147756826,"lng": -71.22601612480568},
                {"lat":-32.83880451645262, "lng":-71.22479127920748},
                {"lat":-32.84226212063555, "lng":-71.22530163154005},
                {"lat":-32.84262268037223, "lng":-71.22418583257787},
                {"lat":-32.84356012883303, "lng":-71.22452915533547},
                {"lat":-32.84291112710702, "lng":-71.22710407601738},
                {"lat":-32.84593976122745, "lng":-71.22650326119161},
                {"lat":-32.85156409325131, "lng":-71.22770489084317},
                {"lat":-32.852068823565766, "lng":-71.22427166326727},
                {"lat":-32.856899668459214, "lng":-71.22401417119909},
                {"lat":-32.85769276710925, "lng":-71.21955097535042},
                {"lat":-32.860216215640655, "lng":-71.2199801287974},
                {"lat":-32.85956733573176, "lng":-71.22461498602487},
                {"lat":-32.87376951069292, "lng":-71.23010815014631},
                {"lat":-32.87412994238814, "lng":-71.22650326119161},
                {"lat":-32.87773417874343, "lng":-71.22676075325981},
                {"lat":-32.877950428264676, "lng":-71.23053730359331},
                {"lat":-32.88352568827724, "lng":-71.23168069764883},
                {"lat":-32.88419240232196, "lng":-71.22937110663976},
                {"lat":-32.886677383207775, "lng":-71.229876329673},
                {"lat":-32.886495557701, "lng":-71.23168069764883},
                {"lat":-32.89219257960478, "lng":-71.2323302701201},
                {"lat":-32.89443492392319, "lng":-71.23341289090564},
                {"lat":-32.901040419387535, "lng":-71.24149645933123},
                {"lat":-32.9013434119397, "lng":-71.24445562281157},
                {"lat":-32.90304015107495, "lng":-71.24582694247322},
                {"lat":-32.903343136785345, "lng":-71.24539389415902},
                {"lat":-32.90413089478081, "lng":-71.24597129191127},
                {"lat":-32.903343136785345, "lng":-71.24683738853967},
                {"lat":-32.90394910509617, "lng":-71.24763131044905},
                {"lat":-32.902797761760255, "lng":-71.25095134752456},
                {"lat":-32.903343136785345, "lng":-71.25232266718619},
                {"lat":-32.90049503018138, "lng":-71.25196179359104},
                {"lat":-32.89910124250692, "lng":-71.2553540053856},
                {"lat":-32.89843464064809, "lng":-71.25528183066656},
                {"lat":-32.899404241693325, "lng":-71.251889618872},
                {"lat":-32.898252839270405, "lng":-71.2514565705578},
                {"lat":-32.89643480496978, "lng":-71.25513748122849},
                {"lat":-32.89649540671432, "lng":-71.25881839189918},
                {"lat":-32.90067682695662, "lng":-71.26076710931308},
                {"lat":-32.901404010325734, "lng":-71.25845751830401},
                {"lat":-32.90358552460727, "lng":-71.25975666324662},
                {"lat":-32.90255537178211, "lng":-71.26249930256986},
                {"lat":-32.90352492771398, "lng":-71.26329322447924},
                {"lat":-32.90243417654425, "lng":-71.26762370762123},
                {"lat":-32.90122221502902, "lng":-71.2685619789191},
                {"lat":-32.89485914504433, "lng":-71.26452019465324},
                {"lat":-32.892131975119035, "lng":-71.26971677442363},
                {"lat":-32.89128350513076, "lng":-71.26928372610944},
                {"lat":-32.89004151167807, "lng":-71.27109956756449},
                {"lat":-32.88931423502557, "lng":-71.27102739284547},
                {"lat":-32.8911930208353, "lng":-71.267851705208},
                {"lat":-32.890586965249724, "lng":-71.26734648217477},
                {"lat":-32.89088999356074, "lng":-71.26669690970347},
                {"lat":-32.88864755951647, "lng":-71.26532559004183},
                {"lat":-32.88695054467641, "lng":-71.26770735576991},
                {"lat":-32.886465677323926, "lng":-71.26749083161283},
                {"lat":-32.88761723295399, "lng":-71.26554211419894},
                {"lat":-32.88695054467641, "lng":-71.26510906588474},
                {"lat":-32.8857383713206, "lng":-71.26676908442249},
                {"lat":-32.88513227842449, "lng":-71.26619168667024},
                {"lat":-32.88422313130768, "lng":-71.26734648217477},
                {"lat":-32.88579898038221, "lng":-71.26900650071252},
                {"lat":-32.88537471608039, "lng":-71.26951172374577},
                {"lat":-32.87972357637918, "lng":-71.26620528164278},
                {"lat":-32.87748089356403, "lng":-71.27081783279331},
                {"lat":-32.8776847761647, "lng":-71.27306341690607},
                {"lat":-32.878653212113825, "lng":-71.27452001200623},
                {"lat":-32.87824545089854, "lng":-71.27500554370629},
                {"lat":-32.87493232148898, "lng":-71.27239581081851},
                {"lat":-32.87355606210277, "lng":-71.27476277785627},
                {"lat":-32.87462648791619, "lng":-71.276765596119},
                {"lat":-32.87396384488901, "lng":-71.27694767050652},
                {"lat":-32.87294438440655, "lng":-71.27591591564389},
                {"lat":-32.87197588608999, "lng":-71.27700836196902},
                {"lat":-32.87126224898168, "lng":-71.27628006441894},
                {"lat":-32.87233270249013, "lng":-71.27421655469371},
                {"lat":-32.87141517170273, "lng":-71.26917916330564},
                {"lat":-32.86927422960654, "lng":-71.26656943041782},
                {"lat":-32.87054860612535, "lng":-71.26444522940585},
                {"lat":-32.87406579028849, "lng":-71.26632666474356},
                {"lat":-32.87559495727312, "lng":-71.26268517699314},
                {"lat":-32.87579884421147, "lng":-71.26262448553065},
                {"lat":-32.872332702486105, "lng":-71.25843677461766},
                {"lat":-32.86942715575235, "lng":-71.24860475769155},
                {"lat":-32.86601174224371, "lng":-71.24435635531606},
                {"lat":-32.86703128243726, "lng":-71.24132178219071},
                {"lat":-32.857804016262996, "lng":-71.2388941238395},
                {"lat":-32.85831383500109, "lng":-71.23683061411427},
                {"lat":-32.85612159363911, "lng":-71.23610231656417},
                {"lat":-32.85525487862309, "lng":-71.23840859213944},
                {"lat":-32.85443913910846, "lng":-71.23840859213944},
                {"lat":-32.85499996083048, "lng":-71.23567747632663},
                {"lat":-32.84944257092411, "lng":-71.23397811537643},
                {"lat":-32.84872875250057, "lng":-71.2363450824142},
                {"lat":-32.84801492833506, "lng":-71.23604162510166},
                {"lat":-32.8484228285613, "lng":-71.2336746580639},
                {"lat":-32.846740228043764, "lng":-71.23318912636385},
                {"lat":-32.846281331456765, "lng":-71.23391742391392},
                {"lat":-32.845057595624176, "lng":-71.23349258367638},
                {"lat":-32.84449671106178, "lng":-71.23695199703928},
                {"lat":-32.84378285285595, "lng":-71.23683061411427},
                {"lat":-32.84408879279006, "lng":-71.23337120075136},
                {"lat":-32.83934660516737, "lng":-71.2347671044086},
                {"lat":-32.84006131387736, "lng":-71.23039788687652},
            ]
        },
    ]
};

export default Quillota;
