import React, { useState } from 'react';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import Footer from './components/Footer';
import { checkSession, verifyAccess } from './components/genscripts';
import HeadMenu from './components/HeadMenu';
import SideBar from './components/SideBar';
import Cookies from 'universal-cookie';
import axios from 'axios';

const thispage = 'asignaciontickets';
const headTitle = 'Asignación de Tickets';

const Asignaciontickets = () => {
	const session = checkSession();
	const [ticketsList, setTicketsList] = useState('');
	const [rutconductor, setRutconductor] = useState('');
	const [ticketsState, setTicketsState] = useState('');
	const [modalAsingIsOpen, setModalAsingIsOpen] = useState(false);
	const [asingning, setAsasingning] = useState(true);
	const [assingsuccess, setAsingsuccess] = useState(false);

	const closeModalAsign = () => {
		setModalAsingIsOpen(false);
	};

	const openModalAsign = () => {
		setModalAsingIsOpen(true);
	};

	const AsignTicketsAction = () => {
		let listTicketstoAsing = [];
		if (ticketsList !== '') {
			listTicketstoAsing = ticketsList
				.replace(/ /g, '')
				.split(',')
				.map((x) => +x);
		}
		openModalAsign();
		console.log('listtiockets arr: ', listTicketstoAsing);
		console.log('rutconductor: ', rutconductor);
		console.log('ticketsState: ', ticketsState);

		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		};

		const options = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
		};

		let datenow = new Date().toLocaleDateString('es-CL', options);

		const dateSplit = datenow.split(' ');
		const dateDMY = dateSplit[0].split('-');

		datenow =
			dateDMY[2] + '-' + dateDMY[1] + '-' + dateDMY[0] + ' ' + dateSplit[1];

		console.log('datenow: ', datenow);

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/updateTicketMassiveState`,
			data: JSON.stringify({
				rutconductor: rutconductor,
				listtiskets: listTicketstoAsing,
				ticketState: ticketsState,
				datescanned: datenow,
			}),
			headers: headers,
		})
			.then((response) => {
				console.log(response.data);
				setAsasingning(false);
				if (response.data.length === 0) {
					setAsingsuccess(true);
				} else {
					setAsingsuccess(false);
					//algunos ticket
					const ticketstocheck = response.data.join(', ');
					document.getElementById('extramsgasign').innerHTML =
						'Favor revisar los siguientes tickets: <br/>' + ticketstocheck;
				}
				setTimeout(() => {
					closeModalAsign();
				}, 10000);
			})
			.catch((error) => {
				console.log('updateTicketMassiveState: ' + error);
			});
	};

	return session ? (
		[
			verifyAccess(thispage) ? (
				<div className="wrapper ">
					<div className="sidebar" data-color="white" data-active-color="info">
						<SideBar activeitem={thispage} />
					</div>
					<div className="main-panel">
						<HeadMenu pagetitle={headTitle} />
						<div className="content">
							<div className="row-100">
								<div className="row">
									<div className="col-md-12">
										<div className="card">
											<div className="card-body">
												{/* Contenido */}
												<h4>
													Asigna uno o más tickets a un rut (rut sin puntos pero
													con guión Ej: 12345678-9)
												</h4>
												<small>
													<b>Nota:</b> Para agregar más de un ticket deben estar
													separados por coma.
												</small>
												<div className="row ticketsasignform">
													<div className="form-check col-md-8">
														<label htmlFor="ticketstoasign">
															Ingresa los tickets que deseas asignar:
														</label>
														<textarea
															name="ticketstoasign"
															className="form-control listticketasign"
															value={ticketsList}
															onChange={(e) => setTicketsList(e.target.value)}
														></textarea>
													</div>
													<div className="form-check col-md-8">
														<label htmlFor="ticketstoasign">
															Ingresa el rut del conductor:
														</label>
														<input
															type="text"
															name="ruttoasign"
															className="form-control"
															value={rutconductor}
															onChange={(e) =>
																setRutconductor(e.target.value.trim())
															}
														></input>
													</div>
													<div className="form-check col-md-8">
														<label htmlFor="">
															Elije el estado en que quedarán los tickets
														</label>
														<select
															value={ticketsState}
															onChange={(e) => setTicketsState(e.target.value)}
															className="form-control"
														>
															<option>Selecciona</option>
															<option value="ESCANEADO">ESCANEADO</option>
															<option value="PAGADO">PAGADO</option>
														</select>
													</div>
													<div className="form-check col-md-8 centerContent">
														<button
															onClick={() => AsignTicketsAction()}
															className="btn btn-inactivo btn-round"
														>
															Asignar
														</button>
													</div>
												</div>
												{/* Fin contenido */}
											</div>

											<div className="card-footer ">
												<hr />
												<div className="stats">
													<i className="nc-icon nc-single-copy-04"></i>{' '}
													Asignación de tickets
												</div>
											</div>

											{/* MODAL */}

											<Modal
												isOpen={modalAsingIsOpen}
												onRequestClose={closeModalAsign}
												overlayClassName="Overlay"
												className="Modal"
												contentLabel="Imprimir ticket"
											>
												<div id="CambioEstadoTicketReport">
													<div className="headermodal">
														<button onClick={closeModalAsign}>
															<i className="nc-icon nc-simple-remove"></i>
														</button>
													</div>
													<div className="containerAnulacionModal">
														{asingning ? (
															<h4>
																Asignando tickets <br />
																<img
																	src="img/loadingpagado.gif"
																	alt="loading..."
																/>
															</h4>
														) : (
															<div>
																<div id="msgErrorPetAnul">
																	{assingsuccess
																		? 'Asignación realizada con éxito.'
																		: 'Error al asignar tickets'}
																</div>
																<div id="extramsgasign"></div>
															</div>
														)}
													</div>
												</div>
											</Modal>

											{/* FIN MODAL */}
										</div>
									</div>
								</div>
							</div>
						</div>

						<Footer />
					</div>
				</div>
			) : (
				<Redirect to="/noaccess" />
			),
		]
	) : (
		<Redirect to="/" />
	);
};

export default Asignaciontickets;
