import React from 'react';
import LoginPage from './pages/login';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Dashboard from './pages/dashboard';
import UsersPage from './pages/users';
import NoAccess from './pages/noaccess';
import Redirection from './pages/redir';
import MisVentas from './pages/misventas';
import Cajas from './pages/cajas';
import BuscarTickets from './pages/buscarticket';
import TicketsPorAnular from './pages/ticketsporanular';
import Reportes from './pages/reportes';
import ReportePagos from './pages/reportepagos';
import BackAccounts from './pages/bankaccounts';
import ReporteCierres from './pages/reportecierrecajas';
import Permisos from './pages/permisos';
import Settings from './pages/settings';
import Asignaciontickets from './pages/asignaciontickets';
import VentaTicket from './pages/ventaticket';
import TicketsaEscaneado from './pages/ticketsaescaneado';

class App extends React.Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route exact path="/" component={LoginPage} />
					<Route exact path="/redirect" component={Redirection} />
					<Route exact path="/dashboard" component={Dashboard} />
					<Route exact path="/users" component={UsersPage} />
					{/* <Route exact path="/venta" component={Venta} /> */}
					<Route exact path="/ventaticket" component={VentaTicket} />
					<Route exact path="/misventas" component={MisVentas} />
					<Route exact path="/cajas" component={Cajas} />
					<Route exact path="/buscartickets" component={BuscarTickets} />
					<Route exact path="/ticketsporanular" component={TicketsPorAnular} />
					<Route exact path="/reportes" component={Reportes} />
					<Route exact path="/reportepagos" component={ReportePagos} />
					<Route
						exact
						path="/asignaciontickets"
						component={Asignaciontickets}
					/>
					<Route
						exact
						path="/ticketsaescaneado"
						component={TicketsaEscaneado}
					/>
					<Route exact path="/bankaccounts" component={BackAccounts} />
					<Route exact path="/reportecierres" component={ReporteCierres} />
					<Route exact path="/permisos" component={Permisos} />
					<Route exact path="/settings" component={Settings} />
					<Route exact path="/noaccess" component={NoAccess} />
				</Switch>
			</Router>
		);
	}
}

export default App;
