import React, {useState} from 'react';
import {Autocomplete, GoogleMap, Marker, Polygon, useJsApiLoader} from "@react-google-maps/api";

import './Map.css';

const Map = (props) => {

    const center = props.center;
    const setCenter = props.setCenter;
    const setAutocomplete = props.setAutocomplete;
    const onAutocompletePlaceChanged = props.onAutocompletePlaceChanged;
    const refPolygon = props.refPolygon;
    const refDirection = props.refDirection;
    const coordinatesPolygon = props.coordinatesPolygon;

    const autocompleteRestrictions = {
        country: ["CHL"],
    };

    const [ libraries ] = useState(['geometry', 'places']);
    const [map, setMap] = useState(/** @type google.maps.Map */ (null));
    const [marker, setMarker] = useState(/** @type google.maps.Marker */ (null));

    const { isLoaded } = useJsApiLoader ({
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
        libraries: libraries
    });

    if (!isLoaded) {
        return <div>
            Cargando
        </div>
    } else if (!center) {
        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                setCenter(pos);
                console.log("current position", center);
            }
        );
    }

    const onLoadMap = (map) => {
        setMap(map);
    }

    const onLoadMarker = (marker) => {
        setMarker(marker);
    }

    const onLoadAutocomplete = (autocomplete) => {
        setAutocomplete(autocomplete);
    }

    const polygonOptions = {
        fillColor: "red",
        fillOpacity: 0.1,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 1,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }

    return (
        <div className="row">
            <div className="col-md-12 autocompleteContainer">
                <Autocomplete
                    onLoad={onLoadAutocomplete}
                    onPlaceChanged={onAutocompletePlaceChanged}
                    fields={[
                        "address_component",
                        "adr_address",
                        "formatted_phone_number",
                        "international_phone_number",
                        "reviews",
                        "type",
                        "url",
                        "utc_offset_minutes",
                        "vicinity",
                        "website",
                        "geometry.location",
                        "formatted_address",
                        "name"
                    ]}
                    restrictions={autocompleteRestrictions}
                >
                    <input
                        ref={refDirection}
                        type="text"
                        placeholder="Ingresar dirección"
                        className="form-control"
                    />
                </Autocomplete>
            </div>
            <div className="col-md-12">
                <GoogleMap
                    mapContainerClassName="mapContainer"
                    center={center}
                    zoom={12}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false
                    }
                    }
                    onLoad={(map) => onLoadMap(map)}
                >
                    <Marker
                        onLoad={onLoadMarker}
                        position={center}
                    />
                    <Polygon
                        ref={refPolygon}
                        paths={coordinatesPolygon}
                        options={polygonOptions}
                    />
                </GoogleMap>
            </div>
        </div>
    )
}

export default Map;
