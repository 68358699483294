import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const CountersSelectNew = (props) => {
	const setCounterBox = props.setCounterBox;

	const [selectcounter, setSelectCounter] = useState([]);

	const handleSelectCounter = (event) => {
		setCounterBox(event.target.value);
	};

	useEffect(() => {
		const cookies = new Cookies();
		const token = cookies.get('token');
		const headers = { Authorization: 'Bearer ' + token };
		axios({
			method: 'GET',
			url: `${process.env.REACT_APP_BACKEND_URL}/data/allcounters`,
			headers: headers,
		})
			.then((response) => {
				setSelectCounter(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<div>
			<label>Counter:</label>
			<select
				id="nc-namecounter"
				className="selectpicker select_ta form-control "
				onChange={(e) => handleSelectCounter(e)}
			>
				<option key={'selcounter'} value="0">
					Seleciona Counter
				</option>
				{selectcounter.map((counter) => (
					<option key={counter.idcounter} value={counter.idcounter}>
						{counter.countername}
					</option>
				))}
			</select>
		</div>
	);
};

export default CountersSelectNew;
