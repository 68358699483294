import React from 'react';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';

class Redirection extends React.Component {
	checkProfileHome() {
		const cookies = new Cookies();
		const profileid = cookies.get('pid');
		let salida = '/';
		if (profileid === '1') {
			//administrador
			salida = '/dashboard';
		} else if (profileid === '2') {
			//venta
			salida = '/ventaticket';
		} else if (profileid === '4') {
			//supervisor
			salida = '/cajas';
		} else if (profileid === '5') {
			//pagos
			salida = '/reportepagos';
		} else if (profileid === '6') {
			//tesorero
			salida = '/reportecierres';
		}
		return salida;
	}

	render() {
		const redirurl = this.checkProfileHome();
		return <Redirect to={redirurl} />;
	}
}

export default Redirection;
